import React, { useState } from "react";
import alaune from "assets/img/alaune.jpg";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";

import { Link as LinkScroll } from "react-scroll";
import { useSelector } from "react-redux";

export default ({}) => {
  const lang = useSelector((state) => state.customization.lang);
  return (
    <div className="flex flex-wrap items-center mt-32">
      <div
        className="w-full md:w-5/12 px-4 mr-auto ml-auto mb-5"
        style={lang === "ar" ? { direction: "rtl" } : {}}
      >
        <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
          <i className="fas fa-user-friends text-xl"></i>
        </div>
        <h3 className="text-3xl font-semibold mb-3">
          {lang === "fr" ? "À la Une" : lang === "en" ? "In the headlines" : "في العناوين الرئيسية"}
        </h3>
        <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
          {lang === "fr"
            ? "Découvrez la Success Story de notre client en vedette dans notre vidéo publicitaire exclusive."
            : lang === "en"
            ? "Discover our customer's Success Story in our exclusive advertising video."
            : "اكتشف قصة نجاح عميلنا في الفيديو الإعلاني الحصري الخاص بنا."}
        </p>
        <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-blueGray-600">
          {lang === "fr"
            ? "Vous souhaitez être à la une? Contactez-nous pour réserver cet espace publicitaire et donnez à votre entreprise l'exposition qu'elle mérite. Inspirez et soyez inspiré par les histoires de réussite qui façonnent notre communauté dynamique."
            : lang === "en"
            ? "Want to be on the front page? Contact us to reserve this advertising space and give your company the exposure it deserves. Inspire and be inspired by the success stories that shape our vibrant community."
            : "هل تريد أن تكون في الصفحة الأولى؟ اتصل بنا لحجز هذه المساحة الإعلانية ومنح عملك التجاري الظهور الذي يستحقه. ألهم واستلهم من قصص النجاح التي تشكل مجتمعنا النابض بالحياة."}
        </p>
        <LinkScroll to="contactUs" className="font-bold text-blueGray-700 mt-8">
          {lang === "fr" ? "Contactez nous" : lang === "en" ? "Contact us" : "اتصل بنا"}
        </LinkScroll>
      </div>

      <div className="w-full md:w-6/12 p-0 mr-auto ml-auto m-0">
        <div
          className="w-100 p-0 m-0"
          style={{
            overflow: "hidden",
            // boxShadow: "#3f3f3f 0px 0px 10px 1px",
          }}
        >
          <ReactPlayer
            url={"https://www.youtube.com/watch?v=ZmoNXXHdthQ"}
            light={alaune}
            controls
            width={"100%"}
          />
        </div>
      </div>
    </div>
  );
};
