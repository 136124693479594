import { useEffect, useRef, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";

// Data
import data from "layouts/PubsList/components/data";
import { Divider, FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import MDButton from "components/MDButton";
import tw from "twin.macro";
import styled from "styled-components";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import MDInput from "components/MDInput";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Pagination } from "react-bootstrap";
import { BASE_URL_Image } from "services/server";
import { useMutation } from "@tanstack/react-query";
import dayjs from "dayjs";
import { getDemandePubs } from "services/adminService";
import { updateDemandePub } from "services/adminService";
import { inscriptionAbonnementsList } from "services/adminService";
import AbonnementItem from "layouts/PubsList/components/abonnement";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import abonnement from "./abonnement";
import { getEntreprises } from "services/adminService";
import { getGroupes } from "services/adminService";

const CardPub = tw.div`h-full flex! flex-col sm:border max-w-sm  relative focus:outline-none bg-white m-0`;
const CardImage = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full h-56 sm:h-64 bg-cover bg-center `,
]);

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-2xl font-bold`;

const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4`;

const PrimaryButton = tw(
  PrimaryButtonBase
)`mt-auto sm:text-lg rounded-none w-full  py-3 sm:py-6 border-none`;

function List() {
  const dateToDateJS = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();

    const dateString = `${year}/${month}/${day}`;
    console.log("init date debut");
    console.log(dateString);
    return dayjs(dateString);
  };

  const [publireportage, setPublireportage] = useState(null);
  const [modePaiment, setModePaiment] = useState(1); // dahabia : 1 , virement : 2
  const [forWho, setforWho] = useState(1); // dahabia : 1 , virement : 2

  const [selectedEntreprise, setSelectedEntreprise] = useState("");

  const [entreprises, setEntreprises] = useState([]);

  const [selectedGroupe, setSelectedGroupe] = useState("");

  const [groupes, setGroupes] = useState([]);

  const { mutate: mutateEntreprises, isLoading: isLoadingEntreprises } = useMutation(
    (userInfo) => getEntreprises(userInfo),
    {
      async onSuccess(data) {
        console.log(data);
        if (data && data.data) {
          setEntreprises(
            data.data.map((el) => {
              return {
                imageSrc: BASE_URL_Image + el.profile_pic,
                id: el.id,
                title: el.nom,
              };
            })
          );

          if (data.data.length > 0) {
            let el = data.data[0];
            // setSelectedEntreprise(el.id) ;
          }
        }
      },
      onError(error) {},
    }
  );

  const { mutate: mutateGroupes, isLoading: isLoadingGroupes } = useMutation(
    (userInfo) => getGroupes(userInfo),
    {
      async onSuccess(data) {
        console.log(data);
        if (data && data.data) {
          setGroupes(
            data.data.map((el) => {
              return {
                imageSrc: BASE_URL_Image + el.profile_pic,
                id: el.id,
                title: el.nom,
              };
            })
          );

          if (data.data.length > 0) {
            let el = data.data[0];
            // setSelectedGroupe(el.id) ;
          }
        }
      },
      onError(error) {},
    }
  );
  const [page, setPage] = useState({
    index: 1,
    limit: 5,
    totalItems: 0,
  });
  const [search, setSearch] = useState("");
  const [dateDebut, setDateDebut] = useState(dateToDateJS(new Date()));
  const [dateFin, setDateFin] = useState(null);

  const [paginationItems, setPaginationItems] = useState([]);

  const [items, setItems] = useState([]);

  const [open, setOpen] = useState(false);
  const handleOpen = (publireportage) => {
    console.log(publireportage);
    setPublireportage(publireportage);
    setOpen(true);
  };
  const handleClose = () => {
    setPublireportage(null);
    setOpen(false);
  };

  const [openArchivage, setOpenArchivage] = useState(false);
  const handleOpenArchivage = (publireportage) => {
    console.log(publireportage);
    setPublireportage(publireportage);
    setOpenArchivage(true);
  };
  const handleCloseArchivage = () => {
    setPublireportage(null);
    setOpenArchivage(false);
  };

  const { mutate, isLoading } = useMutation((userInfo) => getDemandePubs(userInfo), {
    async onSuccess(data) {
      console.log(data);
      if (data && data.data) {
        setItems(
          data.data.map((it) => {
            return { ...it };
          })
        );
        setPage((oldValue) => {
          return { ...oldValue, totalItems: parseInt(data.total) };
        });
        let table_items = [];
        if (parseInt(data.total) !== 0) {
          for (
            let number = 1;
            number <= parseInt(parseInt(data.total) / 5) + (parseInt(data.total) % 5 > 0 ? 1 : 0);
            number++
          ) {
            table_items.push(
              <Pagination.Item
                key={number}
                active={number === page.index}
                onClick={() => {
                  setPage((oldValue) => {
                    return { ...oldValue, index: number };
                  });
                }}
              >
                {number}
              </Pagination.Item>
            );
          }
        }
        setPaginationItems(table_items);
      } else {
      }
    },
    onError(error) {},
  });

  useEffect(() => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    setDateFin(dateToDateJS(tomorrow));
    mutate({ filter: search, limit: 5, offset: (page.index - 1) * 5 });
    mutateEntreprises({ checkbox: 1 });
    mutateGroupes({ checkbox: 1 });
  }, []);

  const [openValidation, setOpenValidation] = useState(false);
  const handleOpenValidation = (publireportage) => {
    console.log(dateDebut);
    console.log(dateFin);
    setPublireportage(publireportage);
    setOpenValidation(true);
    console.log(publireportage);
  };
  const handleCloseValidation = () => {
    setDateDebut(dateToDateJS(new Date()));
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    setDateFin(dateToDateJS(tomorrow));
    setPublireportage(null);
    setOpenValidation(false);
  };

  const [openAffichage, setOpenAffichage] = useState(false);
  const handleOpenAffichage = (publireportage) => {
    setPublireportage(publireportage);
    setOpenAffichage(true);
    console.log(publireportage);
  };
  const handleCloseAffichage = () => {
    setOpenAffichage(false);
  };

  const { columns, rows } = data({
    handleOpen,
    handleOpenValidation,
    handleOpenAffichage,
    handleOpenArchivage,
    items: items,
  });

  const rechercher = () => {
    mutate({ filter: search, limit: 5, offset: (page.index - 1) * 5 });
  };

  const { mutate: updateDemandes, isLoading: isLoadingupdateDemandes } = useMutation(
    (userInfos) => updateDemandePub(userInfos),
    {
      async onSuccess(data) {
        console.log(data);
        if (data) {
          handleCloseValidation();
          mutate();
          // setOpenValidation((oldValue) => {return {id : null , value : false}});
        } else {
        }
      },
      onError(error) {},
    }
  );

  const annulerItem = () => {
    updateDemandes({ id: publireportage.id, status: -1 });
    handleClose();
  };

  const validerItem = () => {
    console.log(publireportage);
    let today = dateToDateJS(new Date());
    let tomorrow = new Date();

    if (abonnement && abonnement.id === 2) {
      tomorrow.setDate(tomorrow.getDate() + 30);
    } else {
      tomorrow.setDate(tomorrow.getDate() + 365);
    }

    tomorrow = dateToDateJS(tomorrow);
    let data = {
      id: publireportage.id,
      status: 1,
      pub: modePaiment === 1,
      banniere: modePaiment === 2,
      groupe_id: forWho === 2 ? selectedGroupe : null,
      entreprise_id: forWho === 3 ? selectedEntreprise : null,
      date_debut: dateDebut.format("YYYY/MM/DD"),
      date_fin: dateFin.format("YYYY/MM/DD"),
    };

    updateDemandes(data);
    // handleCloseValidation() ;
  };

  const archiverItem = () => {
    updateDemandes({ id: publireportage.id, archive: 1 });
    handleCloseArchivage();
  };

  const [pictureUrl, setPictureUrl] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    console.log(selectedFile);
    if (selectedFile) {
      let url = URL.createObjectURL(selectedFile);
      if (url) {
        console.log(url);
        setPictureUrl(url);
      }
    }
  }, [selectedFile]);

  const [pictureUrl2, setPictureUrl2] = useState("");

  const [selectedFile2, setSelectedFile2] = useState(null);
  const fileInputRef2 = useRef(null);

  const handleFileChange2 = (event) => {
    setSelectedFile2(event.target.files[0]);
  };

  const handleButtonClick2 = () => {
    fileInputRef2.current.click();
  };

  useEffect(() => {
    console.log(selectedFile2);
    if (selectedFile2) {
      let url = URL.createObjectURL(selectedFile2);
      if (url) {
        console.log(url);
        setPictureUrl2(url);
      }
    }
  }, [selectedFile2]);

  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox style={{ width: "100%" }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <MDTypography variant="h6" gutterBottom>
                Liste des demandes des bannieres et pubs
              </MDTypography>
              <MDBox display="flex" alignItems="center" lineHeight={0}>
                <Icon
                  sx={{
                    fontWeight: "bold",
                    color: ({ palette: { info } }) => info.main,
                    mt: -0.5,
                  }}
                >
                  ads_click
                </Icon>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;
                  <strong>
                    {items != null ? items.length : 0}{" "}
                    {items != null && items.length > 1 ? "Demandes" : "Demande"}
                  </strong>{" "}
                  en total
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <MDBox>
                <div className="px-2 w-100">
                  <Grid container spacing={3} className=" w-100 m-0">
                    <Grid item style={{ flex: 1 }} className="px-2">
                      <MDInput
                        style={{ width: "100%" }}
                        label="Recherche"
                        onChange={(e) => setSearch(e.currentTarget.value)}
                      />
                    </Grid>
                    <Grid item className="d-flex justify-content-end">
                      <MDButton color="light" onClick={rechercher}>
                        Filtrer
                      </MDButton>
                    </Grid>
                  </Grid>
                </div>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <MDBox>
        <DataTable
          table={{ columns, rows }}
          showTotalEntries={false}
          isSorted={false}
          noEndBorder
          entriesPerPage={false}
        />
        <div className="w-100 d-flex justify-content-center align-items-center">
          <Pagination>{paginationItems}</Pagination>
        </div>
        {page.totalItems === 0 && (
          <div className="w-100 d-flex justify-content-center align-items-center">
            <MDTypography variant="h6" color="light">
              Aucune Demande
            </MDTypography>
          </div>
        )}
      </MDBox>
      <Modal
        show={open || openArchivage}
        onHide={open ? handleClose : handleCloseAffichage}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {open ? "Refu d'une demande" : "Archivage d'un publireportage"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MDBox
            mx={2}
            my={1}
            py={3}
            px={2}
            variant="gradient"
            bgColor="error"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              {open
                ? "Est-ce-que vous voulez vraiment réfuser cette demande ?"
                : "Est-ce-que vous voulez vraiment archiver ce publireportage ?"}
            </MDTypography>
          </MDBox>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button onClick={handleClose}>Confirmer</Button> */}
          <MDButton color="error" onClick={open ? annulerItem : archiverItem}>
            Confirmer
          </MDButton>
        </Modal.Footer>
      </Modal>

      <Modal
        show={openValidation}
        onHide={handleCloseValidation}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Validation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MDBox
            mx={2}
            my={1}
            py={3}
            px={2}
            variant="gradient"
            bgColor="light"
            borderRadius="lg"
            coloredShadow="light"
            className=" mt-5"
          >
            <div className="row w-100 ml-0">
              {publireportage && publireportage.alreadyUser != null && (
                <>
                  <div className="col-6 d-flex justify-content-start align-items-center">
                    <MDTypography color="primary" style={{ fontSize: "14px", fontWeight: "bold" }}>
                      Utilisateur
                    </MDTypography>
                  </div>
                  <div className="col-6 d-flex justify-content-end align-items-center">
                    <MDTypography color="primary" style={{ fontSize: "14px", fontWeight: "bold" }}>
                      Déja inscrit
                    </MDTypography>
                  </div>
                  <Divider></Divider>
                </>
              )}
              <div className="col-6 d-flex justify-content-start align-items-center">
                <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Nom
                </MDTypography>
              </div>
              <div className="col-6 d-flex justify-content-end align-items-center">
                <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                  {publireportage != null && publireportage.nom != null ? publireportage.nom : ""}
                </MDTypography>
              </div>
              <Divider></Divider>
              <div className="col-6 d-flex justify-content-start align-items-center">
                <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Email
                </MDTypography>
              </div>
              <div className="col-6 d-flex justify-content-end align-items-center">
                <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                  {publireportage != null && publireportage.email != null
                    ? publireportage.email
                    : ""}
                </MDTypography>
              </div>
              <Divider></Divider>
              <div className="col-6 d-flex justify-content-start align-items-center">
                <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Phone
                </MDTypography>
              </div>
              <div className="col-6 d-flex justify-content-end align-items-center">
                <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                  {publireportage != null && publireportage.phone != null
                    ? publireportage.phone
                    : ""}
                </MDTypography>
              </div>
              <Divider></Divider>
              <div className="col-6 d-flex justify-content-start align-items-center">
                <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Message
                </MDTypography>
              </div>
              <div className="col-6 d-flex justify-content-end align-items-center">
                <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                  {publireportage != null && publireportage.message != null
                    ? publireportage.message
                    : ""}
                </MDTypography>
              </div>
            </div>
          </MDBox>
          <div className="row w-100 ml-0">
            <div className="col-12">
              <Divider></Divider>
              <MDTypography>Type :</MDTypography>
            </div>
            <div className="col-12">
              <MDBox
                mx={2}
                my={1}
                py={3}
                px={2}
                variant="gradient"
                bgColor={modePaiment == 1 ? "info" : "light"}
                borderRadius="lg"
                coloredShadow={modePaiment == 1 ? "info" : "light"}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setModePaiment(1);
                }}
              >
                <div className="w-100 d-flex justify-content-around align-items-center">
                  <div
                    className="rounded-circle  mx-2"
                    style={{
                      height: "20px",
                      width: "20px",
                      backgroundColor: modePaiment == 1 ? "#1f4394" : "white",
                    }}
                  ></div>

                  <div
                    className="mx-2"
                    style={{
                      flex: 1,
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: modePaiment == 1 ? "white" : "black",
                    }}
                  >
                    Publireporatage
                  </div>
                </div>
              </MDBox>

              <MDBox
                mx={2}
                my={1}
                py={3}
                px={2}
                variant="gradient"
                bgColor={modePaiment === 2 ? "info" : "light"}
                borderRadius="lg"
                coloredShadow={modePaiment === 2 ? "info" : "light"}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setModePaiment(2);
                }}
              >
                <div className="w-100 d-flex justify-content-around align-items-center">
                  <div
                    className="rounded-circle  mx-2"
                    style={{
                      height: "20px",
                      width: "20px",
                      backgroundColor: modePaiment == 2 ? "#1f4394" : "white",
                    }}
                  ></div>

                  <div
                    className="mx-2"
                    style={{
                      flex: 1,
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: modePaiment == 2 ? "white" : "black",
                    }}
                  >
                    Banniere
                  </div>
                </div>
              </MDBox>
            </div>
            {/* {modePaiment == 1 &&
            <div className="col-12 d-flex justify-content-center" >
              <MDBox style={{maxWidth : "300px" , width : "100%" }}>
                  <div 
                    style={{width : "300px"  , height : "300px" , border : pictureUrl ? "" :  "3px dashed  #1A73E8" , margin : "20px auto" , display : "flex" , justifyContent : "center" ,alignItems : "center" , cursor : "pointer" , overflow : "hidden" }}
                    onClick={handleButtonClick}
                    >
                    <input  accept="image/*" ref={fileInputRef} onClick={handleFileChange}  onChange={handleFileChange} type="file" style={{display : "none"}}/>
                    
                    {pictureUrl && pictureUrl != "" && <img style={{height : "100%" , width : "auto"}} src={pictureUrl} />}
                    {(!pictureUrl || pictureUrl === "") && 
                      <Icon fontSize="large" color="info">add_a_photo</Icon>
                    }
                  </div>
                  <MDInput style={{width : "100%" , marginTop : "20px"}} label="Titre" />
                  <MDInput style={{width : "100%" , marginTop : "20px"}} label="Description du publireportage"  multiline rows={4} />
            </MDBox>

            </div>
          } */}
            {/* {modePaiment == 2 &&
            <div className="col-12 d-flex justify-content-center" >
              <MDBox style={{ width : "100%" }}>
              <div 
                style={{width : "100%"  , height : "300px" , border : pictureUrl2 ? "" :  "3px dashed  #1A73E8" , margin : "20px auto" , display : "flex" , justifyContent : "center" ,alignItems : "center" , cursor : "pointer" , overflow : "hidden" }}
                onClick={handleButtonClick2}
                >
                <input  accept="image/*" ref={fileInputRef2} onClick={handleFileChange2}  onChange={handleFileChange2} type="file" style={{display : "none"}}/>
                
                {pictureUrl2 && pictureUrl2 != "" && <img style={{height : "auto" , width : "100%"}} src={pictureUrl2} />}
                {(!pictureUrl2 || pictureUrl2 === "") && 
                  <Icon fontSize="large" color="info">add_a_photo</Icon>
                }
              </div>
                  <MDInput style={{width : "100%" , marginTop : "20px"}} label="link" />
            </MDBox>

            </div>
          } */}
            <div className="col-12">
              <Divider></Divider>
              <MDTypography>Pour :</MDTypography>
            </div>
            <div className="col-12">
              {/* <MDBox
                    mx={2}
                    my={1}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor={ forWho == 1 ? "info" : "light"}
                    borderRadius="lg"
                    coloredShadow={ forWho == 1 ? "info" : "light"}
                    style={{cursor : "pointer"}}
                    onClick={()=>{setforWho(1) ; }}
                  >
                    <div className="w-100 d-flex justify-content-around align-items-center">
                        <div className="rounded-circle  mx-2" style={{height : "20px" , width : "20px" , backgroundColor :  forWho == 1 ?  "#1f4394" : "white"}}></div>
                        
                        <div className="mx-2" style={{flex : 1 , fontSize : "14px" , fontWeight : "bold" , color : forWho == 1 ? "white" :  "black"}}>
                          Annonyme
                        </div>
                    </div>
                  </MDBox> */}

              <MDBox
                mx={2}
                my={1}
                py={3}
                px={2}
                variant="gradient"
                bgColor={forWho === 2 ? "info" : "light"}
                borderRadius="lg"
                coloredShadow={forWho === 2 ? "info" : "light"}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setforWho(2);
                }}
              >
                <div className="w-100 d-flex justify-content-around align-items-center">
                  <div
                    className="rounded-circle  mx-2"
                    style={{
                      height: "20px",
                      width: "20px",
                      backgroundColor: forWho == 2 ? "#1f4394" : "white",
                    }}
                  ></div>

                  <div
                    className="mx-2"
                    style={{
                      flex: 1,
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: forWho == 2 ? "white" : "black",
                    }}
                  >
                    Groupe
                  </div>
                </div>
              </MDBox>

              <MDBox
                mx={2}
                my={1}
                py={3}
                px={2}
                variant="gradient"
                bgColor={forWho === 3 ? "info" : "light"}
                borderRadius="lg"
                coloredShadow={forWho === 3 ? "info" : "light"}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setforWho(3);
                }}
              >
                <div className="w-100 d-flex justify-content-around align-items-center">
                  <div
                    className="rounded-circle  mx-2"
                    style={{
                      height: "20px",
                      width: "20px",
                      backgroundColor: forWho == 3 ? "#1f4394" : "white",
                    }}
                  ></div>

                  <div
                    className="mx-2"
                    style={{
                      flex: 1,
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: forWho == 3 ? "white" : "black",
                    }}
                  >
                    Entreprise
                  </div>
                </div>
              </MDBox>
            </div>
            {forWho === 2 && (
              <div className="col-12  d-flex justify-content-center mt-4">
                <MDBox style={{ maxWidth: "300px", width: "100%" }}>
                  <FormControl fullWidth style={{ marginBottom: "15px" }}>
                    <InputLabel id="Groupe-simple-select-label">Groupe</InputLabel>
                    <Select
                      labelId="Groupe-simple-select-label"
                      id="Groupe-simple-select"
                      value={selectedGroupe}
                      style={{ height: "50px" }}
                      label="Groupe"
                      onChange={(event) => {
                        console.log(event.target.value);
                        setSelectedGroupe(event.target.value);
                      }}
                    >
                      {groupes != null &&
                        groupes.length > 0 &&
                        groupes.map((el) => {
                          return <MenuItem value={el.id}>{el.title}</MenuItem>;
                        })}
                    </Select>
                  </FormControl>
                </MDBox>
              </div>
            )}
            {forWho === 3 && (
              <div className="col-12  d-flex justify-content-center mt-4">
                <MDBox style={{ maxWidth: "300px", width: "100%" }}>
                  <FormControl fullWidth style={{ marginBottom: "15px" }}>
                    <InputLabel id="entreprise-simple-select-label">Entreprise</InputLabel>
                    <Select
                      labelId="entreprise-simple-select-label"
                      id="entreprise-simple-select"
                      value={selectedEntreprise}
                      style={{ height: "50px" }}
                      label="Entreprise"
                      onChange={(event) => {
                        console.log(event.target.value);
                        setSelectedEntreprise(event.target.value);
                      }}
                    >
                      {entreprises != null &&
                        entreprises.length > 0 &&
                        entreprises.map((el) => {
                          return <MenuItem value={el.id}>{el.title}</MenuItem>;
                        })}
                    </Select>
                  </FormControl>
                </MDBox>
              </div>
            )}

            <div className="col-12">
              <Divider></Divider>
              <MDTypography>Durée :</MDTypography>
            </div>
            <div
              className="col-12 col-md-6"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Début"
                  defaultValue={dateDebut}
                  mode="date"
                  format="YYYY-MM-DD"
                  onChange={(date) => {
                    setDateDebut(date);
                  }}
                />
              </LocalizationProvider>
            </div>
            <div
              className="col-12 col-md-6"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Fin"
                  defaultValue={dateFin}
                  mode="date"
                  format="YYYY-MM-DD"
                  onChange={(date) => {
                    setDateFin(date);
                  }}
                />
              </LocalizationProvider>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button onClick={handleCloseValidation}>Confirmer</Button> */}
          <MDButton color="info" onClick={validerItem}>
            Confirmer
          </MDButton>
        </Modal.Footer>
      </Modal>

      <Modal
        show={openAffichage}
        onHide={handleCloseAffichage}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MDBox
            mx={2}
            my={1}
            py={3}
            px={2}
            variant="gradient"
            bgColor="light"
            borderRadius="lg"
            coloredShadow="light"
            className=" mt-5"
          >
            <div className="row w-100 ml-0">
              {publireportage && publireportage.groupe_id != null && (
                <>
                  <div className="col-6 d-flex justify-content-start align-items-center">
                    <MDTypography color="primary" style={{ fontSize: "14px", fontWeight: "bold" }}>
                      Groupe
                    </MDTypography>
                  </div>
                  <div className="col-6 d-flex justify-content-end align-items-center">
                    <MDTypography color="primary" style={{ fontSize: "14px", fontWeight: "bold" }}>
                      {publireportage != null && publireportage.groupe_name != null
                        ? publireportage.groupe_name
                        : ""}
                    </MDTypography>
                  </div>
                  <Divider></Divider>
                </>
              )}
              {publireportage && publireportage.entreprise_id != null && (
                <>
                  <div className="col-6 d-flex justify-content-start align-items-center">
                    <MDTypography color="primary" style={{ fontSize: "14px", fontWeight: "bold" }}>
                      Entreprise
                    </MDTypography>
                  </div>
                  <div className="col-6 d-flex justify-content-end align-items-center">
                    <MDTypography color="primary" style={{ fontSize: "14px", fontWeight: "bold" }}>
                      {publireportage != null && publireportage.entreprise_name != null
                        ? publireportage.entreprise_name
                        : ""}
                    </MDTypography>
                  </div>
                  <Divider></Divider>
                </>
              )}
              {publireportage && (publireportage.pub === true || publireportage.pub === 1) && (
                <>
                  <div className="col-6 d-flex justify-content-start align-items-center">
                    <MDTypography color="primary" style={{ fontSize: "14px", fontWeight: "bold" }}>
                      Publireportage
                    </MDTypography>
                  </div>
                  <div className="col-6 d-flex justify-content-end align-items-center">
                    <MDTypography color="primary" style={{ fontSize: "14px", fontWeight: "bold" }}>
                      {publireportage.pub_defined === true || publireportage.pub_defined === 1
                        ? "défini"
                        : "non défini"}
                    </MDTypography>
                  </div>
                  <Divider></Divider>
                </>
              )}
              {publireportage &&
                (publireportage.banniere === true || publireportage.banniere === 1) && (
                  <>
                    <div className="col-6 d-flex justify-content-start align-items-center">
                      <MDTypography
                        color="primary"
                        style={{ fontSize: "14px", fontWeight: "bold" }}
                      >
                        Banniere
                      </MDTypography>
                    </div>
                    <div className="col-6 d-flex justify-content-end align-items-center">
                      <MDTypography
                        color="primary"
                        style={{ fontSize: "14px", fontWeight: "bold" }}
                      >
                        {publireportage.banniere_defined === true ||
                        publireportage.banniere_defined === 1
                          ? "défini"
                          : "non défini"}
                      </MDTypography>
                    </div>
                    <Divider></Divider>
                  </>
                )}
              <div className="col-6 d-flex justify-content-start align-items-center">
                <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Nom
                </MDTypography>
              </div>
              <div className="col-6 d-flex justify-content-end align-items-center">
                <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                  {publireportage != null && publireportage.nom != null ? publireportage.nom : ""}
                </MDTypography>
              </div>
              <Divider></Divider>
              <div className="col-6 d-flex justify-content-start align-items-center">
                <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Email
                </MDTypography>
              </div>
              <div className="col-6 d-flex justify-content-end align-items-center">
                <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                  {publireportage != null && publireportage.email != null
                    ? publireportage.email
                    : "--"}
                </MDTypography>
              </div>
              <Divider></Divider>
              <div className="col-6 d-flex justify-content-start align-items-center">
                <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Phone
                </MDTypography>
              </div>
              <div className="col-6 d-flex justify-content-end align-items-center">
                <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                  {publireportage != null && publireportage.phone != null
                    ? publireportage.phone
                    : "--"}
                </MDTypography>
              </div>
              <Divider></Divider>
              <div className="col-6 d-flex justify-content-start align-items-center">
                <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Message
                </MDTypography>
              </div>
              <div className="col-6 d-flex justify-content-end align-items-center">
                <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                  {publireportage != null && publireportage.message != null
                    ? publireportage.message
                    : ""}
                </MDTypography>
              </div>
              <Divider></Divider>
              <div className="col-6 d-flex justify-content-start align-items-center">
                <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Date Demande
                </MDTypography>
              </div>
              <div className="col-6 d-flex justify-content-end align-items-center">
                <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                  {publireportage != null && publireportage.created_at != null
                    ? publireportage.created_at
                    : "--"}
                </MDTypography>
              </div>
              <Divider></Divider>
              <div className="col-6 d-flex justify-content-start align-items-center">
                <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Date Début
                </MDTypography>
              </div>
              <div className="col-6 d-flex justify-content-end align-items-center">
                <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                  {publireportage != null && publireportage.date_debut != null
                    ? publireportage.date_debut
                    : "--"}
                </MDTypography>
              </div>
              <Divider></Divider>
              <div className="col-6 d-flex justify-content-start align-items-center">
                <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Date Fin
                </MDTypography>
              </div>
              <div className="col-6 d-flex justify-content-end align-items-center">
                <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                  {publireportage != null && publireportage.date_fin != null
                    ? publireportage.date_fin
                    : "--"}
                </MDTypography>
              </div>
            </div>
          </MDBox>
        </Modal.Body>
      </Modal>
      <Modal
        show={isLoading || isLoadingupdateDemandes}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <MDBox
            mx={2}
            my={1}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              {isLoading ? "Loading ..." : "Updating ..."}
            </MDTypography>
          </MDBox>
        </Modal.Body>
      </Modal>
    </Card>
  );
}

export default List;
