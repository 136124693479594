// project imports
import NavGroup from "./NavGroup";
import menuItem from "menu-items";
import routes from "routes/routes";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// assets
import { IconLogout, IconDashboard } from "@tabler/icons";
import { useDispatch, useSelector } from "react-redux";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Chip,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@mui/material";

// constant
const icons = { IconLogout, IconDashboard };
// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = ({ dashboard }) => {
  const theme = useTheme();
  const lang = useSelector((state) => state.customization.lang);
  const customization = useSelector((state) => state.customization);
  const [role, setRole] = useState(null);
  const [routesRole, setRoutes] = useState([]);
  const [navItems, setnavItems] = useState(
    <Typography key={""} variant="h6" color="error" align="center">
      Menu Items Error
    </Typography>
  );
  const navigate = useNavigate();

  useEffect(() => {
    let rolefirst = localStorage.getItem("role");
    setRole(rolefirst);
    let data_routes = routes
      .filter(
        (el) =>
          el.role === rolefirst &&
          (!dashboard || (dashboard && el.name !== "Dashboard" && el.name !== "Profil"))
      )
      .map((el) => {
        return {
          id: el.id,
          title: el.name,
          title_en: el.name_en,
          title_ar: el.name_ar,
          type: "item",
          url: el.route,
          icon: el.icon,
          breadcrumbs: false,
          group: el.group,
          group_en: el.group_en,
          group_ar: el.group_ar,
        };
      });

    // Grouping elements by their group attribute
    const groupedElements = data_routes.reduce((acc, element) => {
      // Check if there's already an array for this group
      if (!acc[element.group]) {
        // If not, create a new array for this group
        acc[element.group] = [];
      }
      // Push the current element into the array for its group
      acc[element.group].push(element);
      return acc;
    }, {});

    // Converting the object of arrays into an array of arrays
    const groupedArrays = Object.values(groupedElements).map((el) => {
      return {
        id: el[0].group,
        title: el[0].group,
        title_en: el[0].group_en,
        title_ar: el[0].group_ar,
        type: "group",
        children: [...el],
      };
    });
    console.log(groupedArrays);

    setRoutes(groupedArrays);
  }, []);

  useEffect(() => {
    setnavItems(
      routesRole.map((item) => {
        switch (item.type) {
          case "group":
            return <NavGroup key={item.key} item={item} />;
          default:
            return (
              <Typography key={item.key} variant="h6" color="error" align="center">
                Menu Items Error
              </Typography>
            );
        }
      })
    );
  }, [routesRole]);

  const Icon = icons.IconLogout;
  const itemIcon = <Icon stroke={1.5} size="1.3rem" />;
  return (
    <>
      {navItems}
      {!dashboard ? (
        <>
          <ListItemButton
            sx={{
              borderRadius: `${customization.borderRadius}px`,
              mb: 0.5,
              alignItems: "flex-start",
              backgroundColor: role === "acheteur" ? theme.palette.secondary.light : "#ffca04",
              py: 1.25,
              pl: `${1 * 24}px`,
            }}
            selected={false}
            onClick={() => {
              localStorage.removeItem("token");
              localStorage.removeItem("name");
              localStorage.removeItem("id");
              localStorage.removeItem("role");
              localStorage.removeItem("email");
              localStorage.removeItem("entreprise_id");
              localStorage.removeItem("groupe_id");
              localStorage.removeItem("activated");
              navigate("/authentication/sign-in");
            }}
          >
            <ListItemIcon sx={{ my: "auto", minWidth: 36 }}>{itemIcon}</ListItemIcon>
            <ListItemText
              primary={
                <Typography variant={"h5"}>
                  {/* : 'body1' */}
                  {lang === "fr" ? "Se déconnecter" : lang === "en" ? "Sign out" : "تسجيل الخروج"}
                </Typography>
              }
            />
          </ListItemButton>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default MenuList;
