// material-ui
import { useTheme, styled } from "@mui/material/styles";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";

// assets
import {
  IconBrandTelegram,
  IconBuildingStore,
  IconHeart,
  IconMailbox,
  IconMessage,
  IconPackage,
  IconPhoto,
  IconUser,
} from "@tabler/icons";
import User1 from "assets/images/users/user-round.svg";
import { useSelector } from "react-redux";

// styles
const ListItemWrapper = styled("div")(({ theme }) => ({
  cursor: "pointer",
  padding: 16,
  "&:hover": {
    background: theme.palette.primary.light,
  },
  "& .MuiListItem-root": {
    padding: 0,
  },
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationItem = ({ notif, handleOpen }) => {
  const theme = useTheme();
  const lang = useSelector((state) => state.customization.lang);

  const chipSX = {
    height: 24,
    padding: "0 6px",
  };
  const chipErrorSX = {
    ...chipSX,
    color: theme.palette.orange.dark,
    backgroundColor: theme.palette.orange.light,
    height: 28,
    marginRight: "5px",
    marginLeft: "5px",
  };

  const chipWarningSX = {
    ...chipSX,
    color: theme.palette.warning.dark,
    backgroundColor: theme.palette.warning.light,
    height: 28,
    marginRight: "5px",
    marginLeft: "5px",
  };

  const chipSuccessSX = {
    ...chipSX,
    color: theme.palette.success.dark,
    backgroundColor: theme.palette.success.light,
    height: 28,
    marginRight: "5px",
    marginLeft: "5px",
  };
  return (
    <>
      <ListItemWrapper
        key={notif.id}
        onClick={() => {
          handleOpen(notif);
        }}
      >
        <ListItem alignItems="center">
          <ListItemText>
            <Grid container justifyContent="flex-end">
              <Grid item xs={12}>
                <Typography variant="caption" display="block" gutterBottom>
                  2 min ago
                </Typography>
              </Grid>
            </Grid>
          </ListItemText>
        </ListItem>

        <ListItem alignItems="center">
          <ListItemAvatar>
            <Avatar
              sx={{
                color: notif.success
                  ? theme.palette.success.dark
                  : notif.error
                  ? theme.palette.error.dark
                  : notif.warning
                  ? theme.palette.warning.dark
                  : theme.palette.primary.dark,
                backgroundColor: notif.success
                  ? theme.palette.success.light
                  : notif.error
                  ? theme.palette.error.light
                  : notif.warning
                  ? theme.palette.warning.light
                  : theme.palette.primary.light,
                border: "none",
                borderColor: notif.success
                  ? theme.palette.success.main
                  : notif.error
                  ? theme.palette.error.main
                  : notif.warning
                  ? theme.palette.warning.main
                  : theme.palette.primary.main,
              }}
            >
              {notif.concerne === "user" ? (
                <IconUser stroke={1.5} size="1.3rem" />
              ) : notif.concerne === "message" ? (
                <IconMessage stroke={1.5} size="1.3rem" />
              ) : notif.concerne === "product" ? (
                <IconPackage stroke={1.5} size="1.3rem" />
              ) : notif.concerne === "annonce" ? (
                <IconBuildingStore stroke={1.5} size="1.3rem" />
              ) : (
                <IconUser stroke={1.5} size="1.3rem" />
              )}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant="subtitle1">
                {lang === "fr" ? notif.titre : lang === "en" ? notif.titre_en : notif.titre_ar}
              </Typography>
            }
          />
        </ListItem>
        <Grid container direction="column" className="list-container">
          <Grid item xs={12} sx={{ pb: 2 }}>
            <Typography variant="subtitle2">
              {lang === "fr"
                ? notif.description
                : lang === "en"
                ? notif.description_en
                : notif.description_ar}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item>
                {notif.new ? (
                  <>
                    <Chip
                      label={lang === "fr" ? "Nouveau" : lang === "en" ? "New" : "جديد"}
                      sx={chipSuccessSX}
                    />
                  </>
                ) : (
                  <></>
                )}
                {!notif.readed ? (
                  <>
                    <Chip
                      label={lang === "fr" ? "Non lu" : lang === "en" ? "Unread" : "لم يقرأ"}
                      sx={chipErrorSX}
                    />
                  </>
                ) : (
                  <></>
                )}
              </Grid>
              <Grid item></Grid>
            </Grid>
          </Grid>
        </Grid>
      </ListItemWrapper>
      <Divider />
    </>
  );
};

export default NotificationItem;
