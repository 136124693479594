import { authApi } from "./server";

export const getPublications = async (userInfo) => {
    authApi.defaults.headers.common['Authorization'] = "Bearer "+ localStorage.getItem("token");
    const response = await authApi.post('publications' , userInfo);
    return response.data;
};


export const uploaderPublication = async (userInfo) => {
    console.log(userInfo) ;
    const formData = new FormData();
    formData.append('image', userInfo.file);
    formData.append('attachement', userInfo.file2);
    formData.append('titre', userInfo.titre);
    formData.append('description', userInfo.description);
    authApi.defaults.headers.common['Authorization'] = "Bearer "+ localStorage.getItem("token");
    const response = await authApi.post('publications/create' , formData);
    return response.data;
};

export const updatePublication = async (userinfo) => {
    let id = userinfo.id
    delete userinfo.id;

    let toUpload = {} ; 

    if(userinfo.file) {
        const formData = new FormData();
        formData.append('image', userinfo.file);
        formData.append('nom', userinfo.nom);
        toUpload = formData ; 

    }else {
        toUpload = userinfo ; 
    }
    
    authApi.defaults.headers.common['Authorization'] = "Bearer "+ localStorage.getItem("token");
    const response = await authApi.post('publications/update/'+id , toUpload);
    return response.data;
};

