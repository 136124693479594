import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Assets
import CloseIcon from "assets/img/CloseIcon";
// import LogoIcon from "assets/img/Logo2.svg";
import Flag1 from "assets/img/flag_fr.png";
import Flag2 from "assets/img/flag_ar.png";
import Flag3 from "assets/img/flag_en.png";
import { Avatar, ButtonBase } from "@mui/material";
import { shouldForwardProp } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useTheme, styled as styledM } from "@mui/material/styles";
import { SET_LANG } from "store/actions";
import NotificationSection from "layouts/MainLayout/Header/NotificationSection";
import LangueSection from "layouts/MainLayout/Header/LangueSection";
import ProfileSection from "layouts/MainLayout/Header/ProfileSection";
import WarningMessage from "./WarningMessage";

const HeaderAvatarStyle = styledM(Avatar, { shouldForwardProp })(({ theme }) => ({
  ...theme.typography.commonAvatar,
  ...theme.typography.mediumAvatar,
  background: theme.palette.secondary.dark,
  color: theme.palette.secondary.light,
  width: "100%",
  paddingLeft: "15px",
  paddingRight: "15px",
  minWidth: "160px",
  "&:hover": {
    background: theme.palette.secondary.light,
    color: theme.palette.secondary.dark,
  },
}));

export default function Sidebar({ sidebarOpen, toggleSidebar }) {
  const navigate = useNavigate();
  const [auth, setAuth] = useState(false);

  const lang = useSelector((state) => state.customization.lang);
  const dispatch = useDispatch();
  const theme = useTheme();
  useEffect(() => {
    let token = localStorage.getItem("token");
    setAuth(token && token != "");
  }, []);
  return (
    <Wrapper className="animate " sidebarOpen={sidebarOpen}>
      <SidebarHeader className="flexSpaceCenter">
        <div className="flexNullCenter">
          {/* <LogoIcon /> */}
          <h1 className="whiteColor font20" style={{ marginLeft: "15px" }}>
            TARGETS.ZONE
          </h1>
        </div>
        <CloseBtn onClick={() => toggleSidebar(!sidebarOpen)} className="animate pointer">
          <CloseIcon />
        </CloseBtn>
      </SidebarHeader>

      {auth && (
        <ul className="flexCenter w-100">
          <li className="semiBold font11 pointer">
            <NotificationSection />
          </li>
          <li className="semiBold font11 pointer">
            <ProfileSection />
          </li>
        </ul>
      )}

      <UlStyle className="flexNullCenter p-0">
        <li className="semiBold font11 pointer flexCenter w-100">
          <div
            className="radius8"
            style={{
              padding: "10px 15px",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              backgroundColor: lang === "fr" ? "#8d8d8d6e" : "",
            }}
            onClick={() => {
              dispatch({ type: SET_LANG, lang: "fr" });
            }}
          >
            <ImgFlag src={Flag1} alt="fr" />
          </div>

          <div
            className="radius8"
            style={{
              padding: "10px 8px",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              backgroundColor: lang === "ar" ? "#8d8d8d6e" : "",
            }}
            onClick={() => {
              dispatch({ type: SET_LANG, lang: "ar" });
            }}
          >
            <ImgFlag src={Flag2} alt="ar" />
          </div>

          <div
            className="radius8 "
            style={{
              padding: "10px 8px",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              backgroundColor: lang === "en" ? "#8d8d8d6e" : "",
            }}
            onClick={() => {
              dispatch({ type: SET_LANG, lang: "en" });
            }}
          >
            <ImgFlag src={Flag3} alt="en" />
          </div>
        </li>
      </UlStyle>
      <UlStyle className="flexNullCenter flexColumn pt-0">
        <WarningMessage></WarningMessage>
        <li className="semiBold font11 pointer">
          <Link
            onClick={() => {
              navigate("/");
              toggleSidebar(!sidebarOpen);
            }}
            activeClass="active"
            style={{
              padding: "10px 15px",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              color: "white",
            }}
            to="home"
            spy={true}
            smooth={true}
            offset={-80}
          >
            {lang === "fr" ? "Accueil" : lang === "en" ? "Home" : "الرئيسية"}
          </Link>
        </li>
        {/* <li className="semiBold font11 pointer">
              <Link activeClass="active" style={{ padding: "10px 15px" , color : "white"}} to="services" spy={true} smooth={true} offset={-80}>
                Services
              </Link>
            </li>
            <li className="semiBold font11 pointer">
              <Link activeClass="active" style={{ padding: "10px 15px" , color : "white"}} to="projects" spy={true} smooth={true} offset={-80}>
                Projects
              </Link>
            </li>
            <li className="semiBold font11 pointer">
              <Link activeClass="active" style={{ padding: "10px 15px" , color : "white"}} to="blog" spy={true} smooth={true} offset={-80}>
                Blog
              </Link>
            </li> */}
        <li className="semiBold font11 pointer">
          <Link
            activeClass="active"
            style={{
              padding: "10px 15px",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              color: "white",
            }}
            onClick={() => {
              toggleSidebar(!sidebarOpen);
            }}
            to="aboutUs"
            spy={true}
            smooth={true}
            offset={-80}
          >
            {lang === "fr" ? "Qui Somme Nous ?" : lang === "en" ? "Who Are We ?" : "من نحن؟"}
          </Link>
        </li>
        <li className="semiBold font11 pointer">
          <Link
            activeClass="active"
            style={{
              padding: "10px 15px",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              color: "white",
            }}
            onClick={() => {
              toggleSidebar(!sidebarOpen);
            }}
            to="products"
            spy={true}
            smooth={true}
            offset={-80}
          >
            {lang === "fr" ? "Produits" : lang === "en" ? "Products" : "المنتجات"}
          </Link>
        </li>
        <li className="semiBold font11 pointer">
          <Link
            activeClass="active"
            style={{
              padding: "10px 15px",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              color: "white",
            }}
            to="annonces"
            spy={true}
            smooth={true}
            offset={-80}
            onClick={() => {
              navigate("/annonces");
              toggleSidebar(!sidebarOpen);
            }}
          >
            {lang === "fr" ? "Annonces" : lang === "en" ? "Announcements" : "الإعلانات"}
          </Link>
        </li>
        <li className="semiBold font11 pointer">
          <Link
            activeClass="active"
            style={{
              padding: "10px 15px",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              color: "white",
            }}
            onClick={() => {
              toggleSidebar(!sidebarOpen);
            }}
            to="partenaires"
            spy={true}
            smooth={true}
            offset={-80}
          >
            {lang === "fr" ? "Partenaires" : lang === "en" ? "Partners" : "الشركاء"}
          </Link>
        </li>
        <li className="semiBold font11 pointer">
          <Link
            activeClass="active"
            style={{
              padding: "10px 15px",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              color: "white",
            }}
            onClick={() => {
              toggleSidebar(!sidebarOpen);
            }}
            to="contactUs"
            spy={true}
            smooth={true}
            offset={-80}
          >
            {lang === "fr" ? "Contactez nous" : lang === "en" ? "Contact us" : "اتصل بنا"}
          </Link>
        </li>
        <li className="semiBold font11 pointer">
          <Link
            activeClass="active"
            style={{
              padding: "10px 15px",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              color: "white",
            }}
            onClick={() => {
              toggleSidebar(!sidebarOpen);
              navigate("/services");
            }}
            to="services"
            spy={true}
            smooth={true}
            offset={-80}
          >
            {lang === "fr" ? "Services" : lang === "en" ? "Services" : "الخدمات"}
          </Link>
        </li>
      </UlStyle>

      <UlStyle className="flexNullCenter p-0 w-100 flexCenter">
        <li className="semiBold font15  text-white">
          {!auth && (
            <ButtonBase
              sx={{ borderRadius: "12px" }}
              onClick={() => {
                navigate("/authentication/sign-in");
                toggleSidebar(!sidebarOpen);
              }}
            >
              <HeaderAvatarStyle variant="rounded">
                <h6 style={{ fontSize: "13px" }}>
                  {lang === "fr" ? "Se connecter" : lang === "en" ? "Login" : "تسجيل الدخول"}
                </h6>
                {/* <IconAdjustmentsHorizontal stroke={1.5} size="1.3rem" /> */}
              </HeaderAvatarStyle>
            </ButtonBase>
          )}
        </li>
      </UlStyle>
      {/* <UlStyle className="flexSpaceCenter">
        <li className="semiBold font15 pointer">
          <a href="/" style={{ padding: "10px 30px 10px 0" }} className="whiteColor">
            Se Connecter
          </a>
        </li>
        <li className="semiBold font15 pointer flexCenter">
          <a href="/" className="radius8 lightBg" style={{ padding: "10px 15px" }}>
            Get Started
          </a>
        </li>
      </UlStyle> */}
    </Wrapper>
  );
}

const Wrapper = styled.nav`
  width: 400px;
  height: 100vh;
  position: fixed;
  overflow-y: scroll;
  top: 0;
  padding: 0 30px;
  background-color: #1e293b;
  right: ${(props) => (props.sidebarOpen ? "0px" : "-400px")};
  z-index: 9999;
  opacity: 0.99;
  @media (max-width: 400px) {
    width: 100%;
  }
`;
const SidebarHeader = styled.div`
  padding: 20px 0;
`;
const CloseBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  padding: 10px;
`;
const UlStyle = styled.ul`
  padding: 40px;
  li {
    margin: 20px 0;
  }
`;

const UlWrapperRight = styled.ul`
  width: 100%;
  @media (max-width: 760px) {
    display: none;
  }
`;

const ImgFlag = styled.img`
  width: auto;
  height: 25px;
`;
