/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "layouts/Entreprise/components/ProfileInfoCard";
import ProfilesList from "layouts/Entreprise/components/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "layouts/Entreprise/components/Header";
import PlatformSettings from "layouts/Entreprise/components/PlatformSettings";

// Data
import profilesListData from "layouts/Entreprise/data/profilesListData";

// Images
import homeDecor1 from "assets/images/home-decor-1.jpg";
import homeDecor2 from "assets/images/home-decor-2.jpg";
import homeDecor3 from "assets/images/home-decor-3.jpg";
import homeDecor4 from "assets/images/home-decor-4.jpeg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { Card, Icon } from "@mui/material";
import MDBadge from "components/MDBadge";
import { useState } from "react";

// @mui material components
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";

// Data
import data from "layouts/PartenaireList/components/List/data";
import { Box  } from "@mui/material";
import  Button  from "react-bootstrap/Button";
import Modal from 'react-bootstrap/Modal';
import MDButton from "components/MDButton";
import tw from "twin.macro";
import styled from "styled-components";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import MDInput from "components/MDInput";
import React  from "react";
import Slider from "react-slick";
import { SectionHeading } from "components/misc/Headings";
import { ReactComponent as PriceIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { ReactComponent as StarIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";
import { useEffect } from "react";
import { Row , Col , Pagination, Dropdown  } from 'react-bootstrap';
import AnchorLink from "react-anchor-link-smooth-scroll";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import PerfectScrollbar from 'react-perfect-scrollbar'

const ActionButton = tw(
  AnchorLink
)`px-8 py-3 font-bold rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300 mt-12 inline-block tracking-wide text-center px-10 py-4 font-semibold tracking-normal`;
const SecondaryButton = tw(
  ActionButton
)`mt-6 sm:mt-12 sm:ml-8 bg-gray-300 text-gray-800 hocus:bg-gray-400 hocus:text-gray-900`;
const Container = tw.div`relative w-full`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const HeadingWithControl = tw.div`flex flex-col items-center sm:items-stretch sm:flex-row justify-between`;
const Heading = tw(SectionHeading)``;
const Controls = tw.div`flex items-center`;
const ControlButton = styled(PrimaryButtonBase)`
${tw`mt-4 sm:mt-0 first:ml-0 ml-6 rounded-full p-2  border-none bg-gray-500 text-gray-100 border-none `}
svg {
    ${tw`w-6 h-6`}
}

`;
const PrevButton = tw(ControlButton)``;
const NextButton = tw(ControlButton)``;
const PrimaryButton = tw(PrimaryButtonBase)`mt-auto sm:text-lg rounded-none w-full py-3 sm:py-6 bg-gray-500 text-gray-100 border-none`;

const CardSlider = styled(Slider)`
  ${tw`mt-16`}
  .slick-track { 
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1`}
  }
`;
const CardTW = tw.div`h-full flex! flex-col sm:border max-w-sm  relative focus:outline-none`;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");height : 100% ; `,
  tw`w-full  bg-cover bg-center rounded sm:rounded-none`
]);

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-2xl font-bold`;

const RatingsInfo = styled.div`
  ${tw`flex items-center sm:ml-4 mt-2 sm:mt-0`}
  svg {
    ${tw`w-6 h-6 text-yellow-500 fill-current`}
  }
`;
const Rating = tw.span`ml-2 font-bold`;

const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4`;

const SecondaryInfoContainer = tw.div`flex flex-col sm:flex-row mt-2 sm:mt-4`;
const IconWithText = tw.div`flex items-center mr-6 my-2 sm:my-0`;
const IconContainer = styled.div`
  ${tw`inline-block rounded-full p-2  border-none bg-gray-700 text-gray-100`}
  svg {
    ${tw`w-3 h-3`}
  }
`;
const Text = tw.div`ml-2 text-sm font-semibold text-gray-800`;

const Actions = styled.div`
  ${tw`relative  text-center mx-auto lg:mx-0`}
  input {
    ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-primary-500 hover:border-gray-500`}
  }
  button {
    ${tw`w-full sm:absolute right-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
  }
`;
const TabsControl = tw.div`flex flex-wrap bg-gray-200 px-2 py-2 rounded leading-none mt-12 xl:mt-0`;

const TabControl = styled.div`
  ${tw`cursor-pointer px-6 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${props => props.active && tw`bg-primary-500! text-gray-100!`}
  }
`;



function Overview() {
  
  
  const [services, setServices] = useState([
    {
      id : 1 ,
      imageSrc: "https://i.ytimg.com/vi/i7AJwAAK0IU/maxresdefault.jpg",
      title: "L’assemblage des fenêtres et portes fenêtres en PVC",
      description: "Oxxo Algérie Spa est active dans le domaine de  l’assemblage des fenêtres et portes fenêtres en PVC à hautes performances thermiques",
      locationText: "Ilot D N° 6 Zhun Garidi - Kouba 16050 Alger - Algérie",
      entrepriseText: "OXXO - Cevital",
      rating: "4.8",
    },
    {
      id : 2 ,
      imageSrc: "https://prototechasia.com/wp-content/uploads/extrusion-plastique.jpg",
      title: "L’extrusion du profilé PVC",
      description: "Oxxo Algérie Spa est active dans le domaine de l’extrusion du profilé PVC",
      locationText: "Ilot D N° 6 Zhun Garidi - Kouba 16050 Alger - Algérie",
      entrepriseText: "OXXO - Cevital",
      rating: 4.9,
    },]) ;
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} xl={4} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              <ProfileInfoCard
                title="Description de l'entreprise"
                description="Oxxo est une marque française qui existe depuis 1923, avec une expertise et un savoir-faire reconnu comme fournisseur de solutions performantes et durables sur le marché de la menuiserie. Oxxo fabrique et pose des fenêtres, des portes et des fermetures à hautes performances thermiques, acoustiques et d’étanchéité.

                Acquise en 2013 par Cevital, Oxxo est présente sur le marché Algérien depuis 2014. Oxxo Algérie Spa est active dans le domaine de l’extrusion du profilé PVC et l’assemblage des fenêtres et portes fenêtres en PVC à hautes performances thermiques. L’usine de production, implantée dans la localité d’Ain Taghrout, sur une surface de 35 hectares, est équipée de technologies ultra-modernes en robotique et automatisation.
                
                Les sites de production sont répartis comme suit : un site en Algérie, avec une capacité de production annuelle de 2,1 millions de fenêtres par an, soit l’équivalent de 400 000 logements ; et un site en  France, avec une capacité de production annuelle de 200 000 fenêtres, soit l’équivalent de 40 000 logements par an, situé à Cluny près de Lyon. Ce site de production dispose également de moyens de production modernes et puissants, permettant de garantir la fabrication de menuiseries de qualité.
                
                Les activités ont été réparties en 2 réseaux : Oxxo Bâtiment destiné aux professionnels du bâtiment, et Oxxo Baies destiné aux particuliers, en neuf et en rénovation."
                info={{
                  location: "Ilot D N° 6 Zhun Garidi  - Kouba 16050 Alger - Algérie",
                }}
                social={[
                  {
                    link: "https://www.facebook.com/GroupeCevital/",
                    icon: <FacebookIcon />,
                    color: "facebook",
                  },
                  {
                    link: "https://twitter.com/creativetim",
                    icon: <TwitterIcon />,
                    color: "twitter",
                  },
                  {
                    link: "https://www.instagram.com/creativetimofficial/",
                    icon: <InstagramIcon />,
                    color: "instagram", 
                  },
                  {
                    link: " http://www.oxxo.fr",
                    icon: <Icon fontSize="small">language</Icon>,
                    color: "facebook", 
                  },
                ]}
                action={{ route: "", tooltip: "Modifier les informaitons" }}
                shadow={false}
              />
              <Divider orientation="vertical" sx={{ mx: 0 }} />
            </Grid>
            <Grid item xs={12} md={12} xl={4}>
              <Card sx={{ height: "100%" , boxShadow :"none" }}>
                <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
                  <MDTypography variant="h6" style={{fontSize : "13px"}} fontWeight="medium" textTransform="capitalize">
                    Abonnement
                  </MDTypography>
                </MDBox>
                <MDBox p={2}>
                  <MDBox mb={2} lineHeight={1}>
                    <Grid container spacing={3} style={{width : "100%"}}>
                          <Grid item xs={12} container>
                              <MDBox
                                mx={2}
                                my={1}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                                style={{width : "100%" , textAlign : "center"}}
                              >
                                <MDTypography variant="h6" color="white">
                                  Utilise l'abonnement du groupe
                                </MDTypography>
                              </MDBox> 
                          </Grid>
                          <Grid item xs={12}>
                            <MDBox opacity={0.3}>
                              <Divider />
                            </MDBox>
                          </Grid>
                          
                          <Grid item xs={6}>
                            <MDTypography variant="h6" style={{fontSize : "13px"}} fontWeight="light" textTransform="capitalize">
                              Secteurs
                            </MDTypography>
                          </Grid>
                          
                          <Grid item xs={6} container>
                              <Grid item xs={12}>
                                <MDBox style={{marginBottom : "10px"}}>
                                  <MDBadge badgeContent="1 utilisé" color="info" variant="gradient" size="md"  />
                                </MDBox>
                              </Grid>
                              
                              <Grid container spacing={3} xs={12}>
                                <Grid item>
                                  <MDBox >
                                    <MDBadge badgeContent="Industrie et transformation" color="dark" variant="gradient" size="md" style={{width:  "100%"}} />
                                  </MDBox>
                                </Grid>
                              </Grid>
                          </Grid>

                          <Grid item xs={12}>
                            <MDBox opacity={0.3}>
                              <Divider />
                            </MDBox>
                          </Grid>
                          <Grid item xs={6}>
                            <MDTypography variant="h6" style={{fontSize : "13px"}} fontWeight="light" textTransform="capitalize">
                              Services
                            </MDTypography>
                          </Grid>
                          <Grid item xs={6} container>
                              <Grid item xs={12}>
                                <MDBox style={{marginBottom : "10px"}}>
                                  <MDBadge badgeContent="2 défini" color="info" variant="gradient" size="md"  />
                                </MDBox>
                              </Grid>
                          </Grid>

                          <Grid item xs={12}>
                            <MDBox opacity={0.3}>
                              <Divider />
                            </MDBox>
                          </Grid>

                          <Grid item xs={6}>
                            <MDTypography variant="h6" style={{fontSize : "13px"}} fontWeight="light" textTransform="capitalize">
                              Publireportages
                            </MDTypography>
                          </Grid>
                          <Grid item xs={6} container>
                              <MDBox style={{marginBottom : "10px"}}>
                                <MDBadge badgeContent="2 utilisés" color="info" variant="gradient" size="md"  />
                              </MDBox>
                          </Grid>

                          <Grid item xs={12}>
                            <MDBox opacity={0.3}>
                              <Divider />
                            </MDBox>
                          </Grid>
                    </Grid>

                    <Grid container spacing={3} style={{width : "100%"}}>

                    </Grid>
                    
                  </MDBox>
                </MDBox>
              </Card>
              
            </Grid>
            
            <Grid item xs={12} md={12} xl={4}  sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              <ProfilesList title="Groupe" profiles={profilesListData} shadow={false} />
              {/* <PlatformSettings /> */}
            </Grid>
          </Grid>
        </MDBox>
        <MDBox pt={2} px={2} lineHeight={1.25}>
          <MDTypography variant="h6" fontWeight="medium">
            Services
          </MDTypography>
        <Container className="p-4">
          <PerfectScrollbar >
              <Row style={{width : "100%"}} className="py-3">
                
              {services.map((card, index) => (
                        
                        <Col key={index} xs={12} className="mb-5">
                          <CardTW style={{width : "100%" , maxWidth : "100%" , boxShadow : "0 0 2px 1px #d9d9d9"}}>
                            <Row>
                              <Col xs={12} md={3}>
                                  <CardImage imageSrc={card.imageSrc} style={{minHeight : "150px"}} />
                              </Col>
                              <Col xs={12}  md={9} className="pb-3" style={{padding : "30px"}}>
                                <div className="w-100 d-flex justify-content-end align-items-center">
                                    <MDBox color="text" px={2}>
                                      <Dropdown align="start">
                                        <Dropdown.Toggle  id="icon-dropdown">
                                          Actions
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          <Dropdown.Item href="#action-2" disabled>Débloquer</Dropdown.Item>
                                          <Dropdown.Item href="#action-3">Bloquer</Dropdown.Item>
                                          {/* <Dropdown.Item href="#action-4">Archiver</Dropdown.Item> */}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                  </MDBox>
                                </div>
                                  <TextInfo style={{maxHeight : "220px" , overflow:"hidden"}}>
                                    <TitleReviewContainer>
                                      <Title style={{fontSize : "16px"}}>{card.title}</Title>
                                    </TitleReviewContainer>
                                    <IconWithText className="mt-3">
                                        <StarIcon  style={{width : "20px" , height : "20px"}}/>
                                      <Text style={{fontSize : "13px"}}>{card.entrepriseText}</Text>
                                    </IconWithText>
                                    <IconWithText className="mt-2">
                                      <LocationIcon style={{width : "20px" , height : "20px"}}/>
                                      <Text style={{fontSize : "13px"}}>{card.locationText}</Text>
                                    </IconWithText>
                                    <Description  style={{fontSize : "12px"}}>{card.description}</Description>
                                  </TextInfo>
                                  <div className="w-100 d-flex justify-content-between align-items-center">
                                    <PrimaryButton  style={{ paddingTop : "15px" , paddingBottom : "15px", height : "20px" , fontSize : "12px" , display : "flex" , justifyContent : "center" , alignItems : "center" , maxWidth : '150px' }}>Site web</PrimaryButton>
                                    <SecondaryButton style={{margin : 0 , height : "20px" , fontSize : "12px" , display : "flex" , justifyContent : "center" , alignItems : "center", maxWidth : '150px' }}>Voir plus</SecondaryButton>
                                  </div>
                              </Col>
                            </Row>
                          </CardTW>
                          
                        </Col>
                      ))}

                    </Row>

            </PerfectScrollbar>
        </Container>
      
        </MDBox>

        {/* <MDBox pt={2} px={2} lineHeight={1.25}>
          <MDTypography variant="h6" fontWeight="medium">
            Projects
          </MDTypography>
          <MDBox mb={1}>
            <MDTypography variant="button" color="text">
              Architects design houses
            </MDTypography>
          </MDBox>
        </MDBox> */}
        {/* <MDBox p={2}>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6} xl={3}>
              <DefaultProjectCard
                image={homeDecor1}
                label="project #2"
                title="modern"
                description="As Uber works through a huge amount of internal management turmoil."
                action={{
                  type: "internal",
                  route: "/pages/profile/profile-overview",
                  color: "info",
                  label: "view project",
                }}
                authors={[
                  { image: team1, name: "Elena Morison" },
                  { image: team2, name: "Ryan Milly" },
                  { image: team3, name: "Nick Daniel" },
                  { image: team4, name: "Peterson" },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <DefaultProjectCard
                image={homeDecor2}
                label="project #1"
                title="scandinavian"
                description="Music is something that everyone has their own specific opinion about."
                action={{
                  type: "internal",
                  route: "/pages/profile/profile-overview",
                  color: "info",
                  label: "view project",
                }}
                authors={[
                  { image: team3, name: "Nick Daniel" },
                  { image: team4, name: "Peterson" },
                  { image: team1, name: "Elena Morison" },
                  { image: team2, name: "Ryan Milly" },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <DefaultProjectCard
                image={homeDecor3}
                label="project #3"
                title="minimalist"
                description="Different people have different taste, and various types of music."
                action={{
                  type: "internal",
                  route: "/pages/profile/profile-overview",
                  color: "info",
                  label: "view project",
                }}
                authors={[
                  { image: team4, name: "Peterson" },
                  { image: team3, name: "Nick Daniel" },
                  { image: team2, name: "Ryan Milly" },
                  { image: team1, name: "Elena Morison" },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <DefaultProjectCard
                image={homeDecor4}
                label="project #4"
                title="gothic"
                description="Why would anyone pick blue over pink? Pink is obviously a better color."
                action={{
                  type: "internal",
                  route: "/pages/profile/profile-overview",
                  color: "info",
                  label: "view project",
                }}
                authors={[
                  { image: team4, name: "Peterson" },
                  { image: team3, name: "Nick Daniel" },
                  { image: team2, name: "Ryan Milly" },
                  { image: team1, name: "Elena Morison" },
                ]}
              />
            </Grid>
          </Grid>
        </MDBox> */}
      </Header>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Overview;
