import { authApi } from "./server";


export const validerPub = async (userInfos) => {
    authApi.defaults.headers.common['Authorization'] = "Bearer "+ localStorage.getItem("token");
    const response = await authApi.post('pubs/validerPub' , userInfos);
    return response.data;
};


export const annulerPub = async (userInfos) => {
    authApi.defaults.headers.common['Authorization'] = "Bearer "+ localStorage.getItem("token");
    const response = await authApi.post('pubs/annulerPub' , userInfos);
    return response.data;
};



export const getPublireportages = async () => {
    const response = await authApi.post('pubs' , {});
    return response.data;
};



