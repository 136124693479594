import { useState, useRef, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Chip,
  ClickAwayListener,
  Divider,
  Grid,
  InputAdornment,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  OutlinedInput,
  Paper,
  Popper,
  Stack,
  Switch,
  Typography,
} from "@mui/material";

// third-party
import PerfectScrollbar from "react-perfect-scrollbar";

// project imports
import MainCard from "ui-component/cards/MainCard";
import Transitions from "ui-component/extended/Transitions";
import UpgradePlanCard from "./UpgradePlanCard";
import User1 from "assets/images/users/user-round.svg";

// assets
import { IconLogout, IconSearch, IconSettings, IconUser } from "@tabler/icons";
import { BASE_URL_Image } from "services/server";

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
  const theme = useTheme();
  const customization = useSelector((state) => state.customization);
  const navigate = useNavigate();

  const [ProfilePic, setProfilePic] = useState(null);
  const [UserName, setUserName] = useState(null);
  const [UserRole, setUserRole] = useState(null);

  const [sdm, setSdm] = useState(true);
  const [value, setValue] = useState("");
  const [notification, setNotification] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null);
  const handleLogout = async () => {
    console.log("Logout");
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListItemClick = (event, index, route = "") => {
    setSelectedIndex(index);
    handleClose(event);

    if (route && route !== "") {
      navigate(route);
    }
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    let image = localStorage.getItem("profile_pic");
    if (image === "null") image = null;
    console.log(image);
    setProfilePic(image);
    setUserName(localStorage.getItem("name"));
    setUserRole(localStorage.getItem("role"));
  }, []);

  return (
    <>
      <Chip
        sx={{
          height: "48px",
          alignItems: "center",
          borderRadius: "27px",
          transition: "all .2s ease-in-out",
          borderColor: theme.palette.primary.light,
          backgroundColor: theme.palette.primary.light,
          direction: customization.lang === "ar" ? "rtl" : "ltr",
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: theme.palette.primary.main,
            background: `${theme.palette.primary.main}!important`,
            color: theme.palette.primary.light,
            "& svg": {
              stroke: theme.palette.primary.light,
            },
          },
          "& .MuiChip-label": {
            lineHeight: 0,
          },
        }}
        className="icon_home_topbar"
        icon={
          ProfilePic != null ? (
            <Avatar
              src={ProfilePic != null ? BASE_URL_Image + ProfilePic : User1}
              sx={{
                ...theme.typography.mediumAvatar,
                margin: "8px 0 8px 8px !important",
                marginRight: customization.lang === "ar" ? "40px !important" : "",
                marginLeft: customization.lang === "ar" ? "" : "20px !important",
                cursor: "pointer",
              }}
              ref={anchorRef}
              aria-controls={open ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              color="inherit"
            />
          ) : (
            <Avatar
              sx={{
                ...theme.typography.mediumAvatar,
                margin: "8px 0 8px 8px !important",
                marginRight: customization.lang === "ar" ? "40px !important" : "",
                marginLeft: customization.lang === "ar" ? "" : "20px !important",
                cursor: "pointer",
              }}
              ref={anchorRef}
              aria-controls={open ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              color="inherit"
            >
              <IconUser fontSize="inherit" />
            </Avatar>
          )
        }
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={16}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                >
                  <Box sx={{ p: 2 }}>
                    <Stack>
                      <Stack
                        direction="row"
                        spacing={0.5}
                        alignItems="center"
                        style={{ direction: customization.lang === "ar" ? "rtl" : "ltr" }}
                      >
                        <Typography variant="h4">
                          {customization.lang === "fr"
                            ? "Bonjour,"
                            : customization.lang === "en"
                            ? "Hello,"
                            : "مرحباً,"}{" "}
                        </Typography>
                        <Typography component="span" variant="h4" sx={{ fontWeight: 400 }}>
                          {UserName}
                        </Typography>
                      </Stack>
                      <Typography
                        variant="subtitle2"
                        style={{
                          textTransform: "uppercase",
                          direction: customization.lang === "ar" ? "rtl" : "ltr",
                          textAlign: customization.lang === "ar" ? "right" : "left",
                        }}
                      >
                        {UserRole === "acheteur"
                          ? "" +
                            (customization.lang === "fr"
                              ? "Acheteur"
                              : customization.lang === "en"
                              ? "Buyer"
                              : "مشتر")
                          : UserRole === "entreprise"
                          ? "" +
                            (customization.lang === "fr"
                              ? "Vendeur"
                              : customization.lang === "en"
                              ? "Seller"
                              : "بائع")
                          : ""}
                      </Typography>
                    </Stack>
                    {/* <OutlinedInput
                      sx={{ width: "100%", pr: 1, pl: 2, my: 2 }}
                      id="input-search-profile"
                      value={value}
                      onChange={(e) => setValue(e.target.value)}
                      placeholder="Search profile options"
                      startAdornment={
                        <InputAdornment position="start">
                          <IconSearch stroke={1.5} size="1rem" color={theme.palette.grey[500]} />
                        </InputAdornment>
                      }
                      aria-describedby="search-helper-text"
                      inputProps={{
                        "aria-label": "weight",
                      }}
                    /> */}
                    <Divider />
                  </Box>
                  <PerfectScrollbar
                    style={{
                      height: "100%",
                      maxHeight: "calc(100vh - 250px)",
                      overflowX: "hidden",
                    }}
                  >
                    <Box sx={{ p: 2 }}>
                      {/* <UpgradePlanCard /> */}
                      {/* <Divider /> */}
                      {/* <Card
                        sx={{
                          bgcolor: theme.palette.primary.light,
                          my: 2,
                        }}
                      >
                        <CardContent>
                          <Grid container spacing={3} direction="column">
                            <Grid item>
                              <Grid
                                item
                                container
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Grid item>
                                  <Typography variant="subtitle1">Start DND Mode</Typography>
                                </Grid>
                                <Grid item>
                                  <Switch
                                    color="primary"
                                    checked={sdm}
                                    onChange={(e) => setSdm(e.target.checked)}
                                    name="sdm"
                                    size="small"
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item>
                              <Grid
                                item
                                container
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Grid item>
                                  <Typography variant="subtitle1">Allow Notifications</Typography>
                                </Grid>
                                <Grid item>
                                  <Switch
                                    checked={notification}
                                    onChange={(e) => setNotification(e.target.checked)}
                                    name="sdm"
                                    size="small"
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                      <Divider /> */}
                      <List
                        component="nav"
                        sx={{
                          width: "100%",
                          maxWidth: 350,
                          minWidth: 300,
                          direction: customization.lang === "ar" ? "rtl" : "ltr",
                          backgroundColor: theme.palette.background.paper,
                          borderRadius: "10px",
                          [theme.breakpoints.down("md")]: {
                            minWidth: "100%",
                          },
                          "& .MuiListItemButton-root": {
                            mt: 0.5,
                          },
                        }}
                      >
                        <ListItemButton
                          sx={{ borderRadius: `${customization.borderRadius}px` }}
                          selected={selectedIndex === 1}
                          onClick={() => {
                            if (UserRole === "entreprise") {
                              navigate("/entreprise");
                            } else if (UserRole === "acheteur") {
                              navigate("/acheteur");
                            } else if (UserRole === "admin") {
                              navigate("/admin");
                            }
                          }}
                        >
                          <ListItemIcon>
                            <IconUser stroke={1.5} size="1.3rem" />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Grid container spacing={1} justifyContent="space-between">
                                <Grid item>
                                  <Typography variant="body2">
                                    {customization.lang === "fr"
                                      ? "Votre espace"
                                      : customization.lang === "en"
                                      ? "Your space"
                                      : "المساحة الخاصة بك"}
                                  </Typography>
                                </Grid>
                              </Grid>
                            }
                          />
                        </ListItemButton>
                        <ListItemButton
                          sx={{ borderRadius: `${customization.borderRadius}px` }}
                          selected={selectedIndex === 0}
                          onClick={() => {
                            if (UserRole === "entreprise") {
                              navigate("/entreprise/settings");
                            } else if (UserRole === "acheteur") {
                              navigate("/acheteur/settings");
                            } else if (UserRole === "admin") {
                              navigate("/admin/settings");
                            }
                          }}
                        >
                          <ListItemIcon>
                            <IconSettings stroke={1.5} size="1.3rem" />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Grid container spacing={1} justifyContent="space-between">
                                <Grid item>
                                  <Typography variant="body2">
                                    {customization.lang === "fr"
                                      ? "Paramètre du compte"
                                      : customization.lang === "en"
                                      ? "Account settings"
                                      : "إعدادات الحساب"}
                                  </Typography>
                                </Grid>
                              </Grid>
                            }
                          />
                        </ListItemButton>
                        <ListItemButton
                          sx={{ borderRadius: `${customization.borderRadius}px` }}
                          selected={selectedIndex === 4}
                          onClick={handleLogout}
                        >
                          <ListItemIcon>
                            <IconLogout stroke={1.5} size="1.3rem" />
                          </ListItemIcon>
                          <ListItemText
                            onClick={() => {
                              localStorage.removeItem("token");
                              localStorage.removeItem("name");
                              localStorage.removeItem("id");
                              localStorage.removeItem("role");
                              localStorage.removeItem("email");
                              localStorage.removeItem("entreprise_id");
                              localStorage.removeItem("groupe_id");
                              localStorage.removeItem("acheteur_id");
                              localStorage.removeItem("profile_pic");
                              localStorage.removeItem("activated");
                              // navigate("/authentication/sign-in");
                              window.location.reload(false);
                            }}
                            primary={
                              <Grid container spacing={1} justifyContent="space-between">
                                <Grid item>
                                  <Typography variant="body2">
                                    {customization.lang === "fr"
                                      ? "Se déconnecter"
                                      : customization.lang === "en"
                                      ? "Sign out"
                                      : "تسجيل الخروج"}
                                  </Typography>
                                </Grid>
                              </Grid>
                            }
                          />
                        </ListItemButton>
                      </List>
                    </Box>
                  </PerfectScrollbar>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default ProfileSection;
