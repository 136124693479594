import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Button } from "@mui/material";

// project imports
import MainCard from "ui-component/cards/MainCard";

import { Email, Send } from "@mui/icons-material";
import { useEffect, useState } from "react";
import ServiceNewMessage from "./NewMessage";
import ServiceMessageList from "./MessageList";
import MustAuth from "components/shareProduct/MustAuth";
import { useMutation } from "@tanstack/react-query";
import { getUserMessages } from "services/messagesService";
import { makeResponseToMessage } from "services/messagesService";
import { makeUserResponseAsReaded } from "services/messagesService";
import { makeUserMessageAsReaded } from "services/messagesService";
import { toast } from "react-toastify";
import ServiceMessageDetails from "./MessageDatails";
import { useSelector } from "react-redux";

const ServiceMessages = ({ type, concerned_id, responded_by, message_id }) => {
  const theme = useTheme();
  const lang = useSelector((state) => state.customization.lang);

  const [open, setOpen] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [message, setMessage] = useState(null);

  const [auth, setAuth] = useState(false);
  const [UserRole, setUserRole] = useState(null);
  const [userId, setUserId] = useState("");

  const [messages, setMessages] = useState([]);

  const handleOpen = (message) => {
    setMessage({ ...message });
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setMessage(null);
  };
  useEffect(() => {
    if (open && message != null) {
      readUserMessage();
      readUserResponse();
    }
  }, [open]);

  const handleOpenAdd = () => {
    if (UserRole != "admin") {
      setOpenAdd(true);
    }
  };

  const { mutate: mutateMessages, isLoading: isLoadingMessages } = useMutation(
    (filters) => getUserMessages(filters),
    {
      async onSuccess(data) {
        console.log(data);
        if (data && data.data) {
          setMessages(data.data);
        } else {
        }
      },
      onError(error) {},
    }
  );

  const { mutate: mutateMakeUserMessageAsReaded, isLoadingMakeUserMessageAsReaded } = useMutation(
    (filters) => makeUserMessageAsReaded(filters),
    {
      async onSuccess(data) {
        console.log(data);
        if (data && data.success) {
          setMessages([
            ...messages.map((m) => {
              if (m.id === data.data.id) {
                return { ...data.data };
              }
              return m;
            }),
          ]);
          setMessage({ ...data.data });
        } else {
        }
      },
      onError(error) {},
    }
  );

  const { mutate: mutateMakeUserResponseAsReaded, isLoadingMakeUserResponseAsReaded } = useMutation(
    (filters) => makeUserResponseAsReaded(filters),
    {
      async onSuccess(data) {
        console.log(data);
        if (data && data.success) {
          setMessages([
            ...messages.map((m) => {
              if (m.id === data.data.id) {
                return { ...data.data };
              }
              return m;
            }),
          ]);
          setMessage({ ...data.data });
        } else {
        }
      },
      onError(error) {},
    }
  );

  const { mutate: mutateMakeResponseToMessage, isLoadingMakeResponseToMessage } = useMutation(
    (filters) => makeResponseToMessage(filters),
    {
      async onSuccess(data) {
        console.log(data);
        if (data && data.success) {
          setMessages([
            ...messages.map((m) => {
              if (m.id === data.data.id) {
                return { ...data.data };
              }
              return m;
            }),
          ]);
          setMessage({ ...data.data });
        } else {
        }
      },
      onError(error) {},
    }
  );

  useEffect(() => {
    let token = localStorage.getItem("token");
    let id = localStorage.getItem("id");
    setUserId(id);
    setAuth(token != null && token !== "");

    setUserRole(localStorage.getItem("role"));
  }, []);

  useEffect(() => {
    refreshList();
  }, [type, concerned_id, message_id]);

  const refreshList = () => {
    // mutate liste
    if (message_id) {
      mutateMessages({ message_id: message_id });
    } else {
      mutateMessages({
        type: type,
        concerned_id: concerned_id,
      });
    }
  };

  const readUserMessage = () => {
    if (
      message != null &&
      parseInt(message.receiver_id) === parseInt(userId) &&
      parseInt(message.message_readed) !== 1
    ) {
      mutateMakeUserMessageAsReaded({ message_id: message.id });
    }
  };
  const readUserResponse = () => {
    if (
      message != null &&
      parseInt(message.sender_id) === parseInt(userId) &&
      parseInt(message.answered) === 1 &&
      parseInt(message.response_readed) !== 1
    ) {
      mutateMakeUserResponseAsReaded({ message_id: message.id });
    }
  };
  const responseToUserMessage = (response) => {
    if (
      message != null &&
      parseInt(message.receiver_id) === parseInt(userId) &&
      parseInt(message.answered) !== 1 &&
      response !== ""
    ) {
      mutateMakeResponseToMessage({ message_id: message.id, response: response });
    } else {
      notify(
        lang === "fr"
          ? "erreur lors de repondre !"
          : lang === "en"
          ? "error while responding!"
          : "خطأ أثناء الرد!"
      );
    }
  };

  const notify = (message) => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 3000,
      fontSize: "12px",
    });
  };

  const notify_success = (message) => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 3000,
    });
  };

  return (
    <>
      <MainCard className="p-0">
        {message_id ? (
          <></>
        ) : (
          <>
            <div className="w-100 new_message_button d-flex justify-content-between align-items-center p-0">
              <Email style={{ color: theme.palette.grey[400] }} fontSize="medium" />

              {userId != responded_by ? (
                <Button
                  startIcon={
                    <Send
                      style={
                        lang === "ar"
                          ? { transform: "rotate(180deg)", marginRight: "10px", marginLeft: "10px" }
                          : { marginRight: "10px", marginLeft: "10px" }
                      }
                    />
                  }
                  className="mx-2 px-3"
                  sx={{
                    ...theme.typography.commonAvatar,
                    ...theme.typography.button,
                    transition: "all .2s ease-in-out",
                    background: theme.palette.grey[200],
                    color: theme.palette.text.dark,
                    "&:hover": {
                      background: theme.palette.grey[900],
                      color: "white !important",
                    },
                    width: "max-content",
                    overflow: "hidden",
                  }}
                  onClick={handleOpenAdd}
                >
                  {lang === "fr" ? "Message" : lang === "en" ? "Message" : "رسالة"}
                </Button>
              ) : (
                <></>
              )}
            </div>
          </>
        )}
        {auth === true && userId != null ? (
          <ServiceMessageList
            messages={messages}
            userId={userId}
            handleOpen={handleOpen}
          ></ServiceMessageList>
        ) : (
          <></>
        )}
      </MainCard>
      {auth === true ? (
        <>
          {message_id ? (
            <></>
          ) : (
            <>
              <ServiceNewMessage
                openAdd={openAdd}
                setOpenAdd={setOpenAdd}
                type={type}
                concerned_id={concerned_id}
                responded_by={responded_by}
                refreshList={refreshList}
              ></ServiceNewMessage>
            </>
          )}

          <ServiceMessageDetails
            openAdd={open}
            userId={userId}
            setOpenAdd={handleOpen}
            handleClose={handleClose}
            message={message}
            editable={true}
            isLoadingResponse={isLoadingMakeResponseToMessage}
            makeResponse={(e) => {
              if (e && e != "") {
                responseToUserMessage(e);
              } else {
                notify(
                  lang === "fr"
                    ? "Merci de saisir une réponse."
                    : lang === "en"
                    ? "Please enter a response."
                    : "الرجاء إدخال الرد."
                );
              }
            }}
          ></ServiceMessageDetails>
        </>
      ) : (
        <MustAuth openAdd={openAdd} setOpenAdd={setOpenAdd}></MustAuth>
      )}
    </>
  );
};

ServiceMessages.propTypes = {
  isLoading: PropTypes.bool,
};

export default ServiceMessages;
