/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";

// Data
import data from "layouts/dashboard/components/Publireportage/data";
import { Box } from "@mui/material";
import  Button  from "react-bootstrap/Button";
import Modal from 'react-bootstrap/Modal';
import MDButton from "components/MDButton";
import tw from "twin.macro";
import styled from "styled-components";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import { useLocation } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { getWaitingPublireportages } from "services/adminService";
import { validerPub , annulerPub } from "services/pubsService";
import { BASE_URL_Image } from "services/server";

const CardPub = tw.div`h-full flex! flex-col sm:border max-w-sm  relative focus:outline-none bg-white m-0`;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full h-56 sm:h-64 bg-cover bg-center `
]);

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-2xl font-bold`;

const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4`;

const PrimaryButton = tw(PrimaryButtonBase)`mt-auto sm:text-lg rounded-none w-full  py-3 sm:py-6 border-none`;


function PublireportageDashboard() {
  const [menu, setMenu] = useState(null);
  const [publireportage, setPublireportage] = useState(null);
  const { pathname } = useLocation();

  

  const { mutate, isLoading } = useMutation(
    () => getWaitingPublireportages(),
    {
      async onSuccess(data) {
        console.log(data);
        if(data) {
          setItems(
            data.map((el) => {
              return {
                id : el.id , 
                company : {id : el.entreprise_id ,  image : BASE_URL_Image+el.entreprise_image , name : el.entreprise_name} ,
                publireportage : {
                  imageSrc : BASE_URL_Image+el.image , 
                  title : el.titre , 
                  description : el.contenu
                }
              } 
            })
            ) ; 
        }else { 
          
        }
      },
      onError(error) {
        
      },
    }
  );
  const [items, setItems] = useState([
    
  ]);

  

  useEffect(()=> {
    mutate() ; 
  }, [pathname]) ;

  


  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);

  
  const [open, setOpen] = useState({value : false , id : null });
  const handleOpen = (publireportage , id) => {
    console.log(publireportage);
    console.log(id);
    setPublireportage(publireportage) ;
    setOpen({value : true , id : id }) ;
  };
  const handleClose = () => {
    setOpen((oldValue) => {return {id : oldValue.id , value : false}});
  };

  
  const [openValidation, setOpenValidation] = useState({value : false , id : null});
  const handleOpenValidation = (publireportage , id) => {
    setPublireportage(publireportage) ;
    setOpenValidation({value : true , id : id }) ;
  };
  const handleCloseValidation = () => {
    setOpenValidation((oldValue) => {return {id : oldValue.id , value : false}});
  };

  const { columns, rows } = data({handleOpen , handleOpenValidation , items : items});

  
  
  const { mutate : mutate2 , isLoading : isLoading2 } = useMutation(
    () => validerPub({id : openValidation.id , dashboard : true}),
    {
      async onSuccess(data) {
        console.log(data);
        if(data) {
          setItems(
            data.data.map((el) => {
              return {
                id : el.id , 
                company : {id : el.entreprise_id ,  image : BASE_URL_Image+el.entreprise_image , name : el.entreprise_name} ,
                publireportage : {
                  imageSrc : BASE_URL_Image+el.image , 
                  title : el.titre , 
                  description : el.contenu
                }
              } 
            })
            ) ; 
            setOpenValidation((oldValue) => {return {id : null , value : false}});
        }else { 
          
        }
      },
      onError(error) {
        
      },
    }
  );

  const { mutate : mutate3 , isLoading : isLoading3 } = useMutation(
    () => annulerPub({id : open.id , dashboard : true}),
    {
      async onSuccess(data) {
        console.log(data);
        if(data) {
          setItems(
            data.data.map((el) => {
              return {
                id : el.id , 
                company : {id : el.entreprise_id ,  image : BASE_URL_Image+el.entreprise_image , name : el.entreprise_name} ,
                publireportage : {
                  imageSrc : BASE_URL_Image+el.image , 
                  title : el.titre , 
                  description : el.contenu
                }
              } 
            })
            ) ; 
            setOpen((oldValue) => {return {id : null , value : false}});
        }else { 
          
        }
      },
      onError(error) {
        
      },
    }
  );

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={closeMenu}>Action</MenuItem>
      <MenuItem onClick={closeMenu}>Another action</MenuItem>
      <MenuItem onClick={closeMenu}>Something else</MenuItem>
    </Menu>
  );

  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox>
          <MDTypography variant="h6" gutterBottom>
            Demandes des Publireportages
          </MDTypography>
          <MDBox display="flex" alignItems="center" lineHeight={0}>
            <Icon
              sx={{
                fontWeight: "bold",
                color: ({ palette: { info } }) => info.main,
                mt: -0.5,
              }}
            >
              timer
            </Icon>
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;<strong>4 Demandes</strong> en attente de validation 
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox>
        <DataTable
          table={{ columns, rows }}
          showTotalEntries={false}
          isSorted={false}
          noEndBorder
          entriesPerPage={false}
        />
      </MDBox>
      <Modal
      show={open.value}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        Annulation d'un publireportage
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <MDBox
          mx={2}
          my={1}
          py={3}
          px={2}
          variant="gradient"
          bgColor="error"
          borderRadius="lg"
          coloredShadow="info"
        >
          <MDTypography variant="h6" color="white">
          Est-ce-que vous voulez vraiment annuler ce publireportage ?
          </MDTypography>
        </MDBox>
        <div style={{width :"100%" , display : "flex" , justifyContent : "center" , alignItems : "center"}}>
        <CardPub style={{maxWidth : "300px" , width : "100%" , boxShadow : "0 0 5px 1px lightgrey"}}>
            <CardImage imageSrc={publireportage != null ? publireportage.imageSrc : null} />
            <TextInfo style={{maxHeight : "200px"}}>
              <TitleReviewContainer>
                <Title>{publireportage != null ? publireportage.title : ""}</Title>
              </TitleReviewContainer>
              <Description>{publireportage != null ? publireportage.description : ""}</Description>
            </TextInfo>
            
            <PrimaryButton>Voir l'entreprise</PrimaryButton>
          </CardPub>

        </div>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button onClick={handleClose}>Confirmer</Button> */}
        <MDButton color="error"  onClick={() => {mutate3() ; }}>Confirmer</MDButton>
      </Modal.Footer>
    </Modal>

      <Modal
      show={openValidation.value}
      onHide={handleCloseValidation}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Validation d'un publireportage
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <MDBox
          mx={2}
          my={1}
          py={3}
          px={2}
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
        >
          <MDTypography variant="h6" color="white">
          Est-ce-que vous voulez vraiment valider ce publireportage ?
          </MDTypography>
        </MDBox>
        <div style={{width :"100%" , display : "flex" , justifyContent : "center" , alignItems : "center"}}>
        <CardPub style={{maxWidth : "300px" , width : "100%" , boxShadow : "0 0 5px 1px lightgrey"}}>
            <CardImage imageSrc={publireportage != null ? publireportage.imageSrc : null} />
            <TextInfo style={{maxHeight : "200px"}}>
              <TitleReviewContainer>
                <Title>{publireportage != null ? publireportage.title : ""}</Title>
              </TitleReviewContainer>
              <Description>{publireportage != null ? publireportage.description : ""}</Description>
            </TextInfo>
            
            <PrimaryButton>Voir l'entreprise</PrimaryButton>
          </CardPub>

        </div>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button onClick={handleCloseValidation}>Confirmer</Button> */}
        <MDButton color="info"  onClick={mutate2}>Confirmer</MDButton>
      </Modal.Footer>
    </Modal>
    </Card>
  );
}

export default PublireportageDashboard;
