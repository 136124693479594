import React, { useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import { ReactComponent as PriceIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { ReactComponent as StarIcon } from "feather-icons/dist/icons/star.svg";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";
import { useEffect } from "react";

import { ReactComponent as UserIcon } from "feather-icons/dist/icons/user.svg";
import { ReactComponent as TagIcon } from "feather-icons/dist/icons/tag.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../../../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../../images/svg-decorator-blob-3.svg";
import { Col, Modal, Row } from "react-bootstrap";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import { useMutation } from "@tanstack/react-query";
import { getPartenaires } from "services/partenairesService";
import { BASE_URL_Image } from "services/server";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MainCard from "ui-component/cards/MainCard";
import { Button as ButtonMui } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const HeadingWithControl = tw.div`flex flex-col items-center sm:items-stretch sm:flex-row justify-between`;
const Heading = tw(SectionHeading)``;
const Controls = tw.div`flex items-center`;
const ControlButton = styled(PrimaryButtonBase)`
  ${tw`mt-4 sm:mt-0 first:ml-0 ml-6 rounded-full p-2  border-none bg-gray-500 text-gray-100 border-none `}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const PrevButton = tw(ControlButton)``;
const NextButton = tw(ControlButton)``;

const CardSlider = styled(Slider)`
  ${tw`mt-16`}
  .slick-track {
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1`}
  }
`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;

const Card = tw.div`lg:mx-4 xl:mx-8 max-w-sm flex flex-col h-full`;
const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`bg-contain bg-center h-80 lg:h-64 rounded rounded-b-none`,
]);

const Details = tw.div`p-6 rounded border-2  border-none border-primary-100 flex-1 flex flex-col items-center text-center lg:block lg:text-left`;
const MetaContainer = tw.div`flex items-center`;
const Meta = styled.div`
  ${tw`text-secondary-100 font-medium text-sm flex items-center leading-none mr-6 last:mr-0`}
  svg {
    ${tw`w-4 h-4 mr-1`}
  }
`;

const Title = tw.h5`mt-4 leading-snug font-bold text-lg`;
const Description = tw.p`mt-2 text-sm text-secondary-100`;
const Link = styled(PrimaryButtonBase).attrs({ as: "a" })`
  ${tw`inline-block mt-4 text-sm font-semibold`}
`;

const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`-z-10 absolute bottom-0 right-0 w-48 h-48 transform translate-x-40 -translate-y-8 opacity-25`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`-z-10 absolute top-0 left-0 w-48 h-48 transform -translate-x-32 translate-y-full opacity-25`;

export default () => {
  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [sliderRef, setSliderRef] = useState(null);
  const lang = useSelector((state) => state.customization.lang);
  const theme = useTheme();
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (sliderRef) {
        sliderRef?.slickNext();
      }
    }, 3000);
    return () => {
      clearInterval(intervalId);
    };
  }, [sliderRef]);

  useEffect(() => {
    if (document.getElementById("header_smart") != null)
      document.getElementById("header_smart").classList.add("header_smart_solution");
    return () => {
      if (document.getElementById("header_smart") != null)
        document.getElementById("header_smart").classList.remove("header_smart_solution");
    };
  }, []);
  const sliderSettings = {
    arrows: false,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
        },
      },

      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  /* Change this according to your needs */
  const cards = [
    {
      imageSrc: "https://www.joodek.com/storage/2113/6096c5b723ba7.png",
      entreprise: "Ooredoo",
      secteur: "Telecomunication",
      title: "Ooredoo",
      description:
        "Ooredoo précédemment connu sous le nom Nedjma est le troisième opérateur de téléphonie mobile en Algérie. C'est la marque commerciale mobile de Wataniya Télécom Algérie. Le parc abonnés de Ooredoo s'est établi à plus de 12,298 millions.",
      url: "https://reddit.com",
    },
    {
      imageSrc:
        "https://d2q79iu7y748jz.cloudfront.net/s/_squarelogo/256x256/658ed2b9bbb4685063b9bb3d2f164a98",
      entreprise: "Owais Khan",
      secteur: "Technologie et numérique",
      title: "Cevital",
      description: "Punchword est un média social Web 3 qui redonne le pouvoir aux créateurs.",
      url: "https://timerse.com",
    },
    {
      imageSrc: "https://www.largus.fr/images/images/logo-renault-fond-noir.jpg",
      entreprise: "Ooredoo",
      secteur: "Telecomunication",
      title: "Reneault",
      description:
        "Ooredoo précédemment connu sous le nom Nedjma est le troisième opérateur de téléphonie mobile en Algérie. C'est la marque commerciale mobile de Wataniya Télécom Algérie. Le parc abonnés de Ooredoo s'est établi à plus de 12,298 millions.",
      url: "https://reddit.com",
    },
    {
      imageSrc: "https://www.societegenerale.com/sites/default/files/logo-societe-generale.png",
      entreprise: "Owais Khan",
      secteur: "Technologie et numérique",
      title: "Société générale ",
      description: "Punchword est un média social Web 3 qui redonne le pouvoir aux créateurs.",
      url: "https://timerse.com",
    },
    {
      imageSrc: "https://upload.wikimedia.org/wikipedia/fr/6/66/Ibis_red.jpg",
      entreprise: "Owais Khan",
      secteur: "Technologie et numérique",
      title: "Ibis",
      description: "Punchword est un média social Web 3 qui redonne le pouvoir aux créateurs.",
      url: "https://timerse.com",
    },
    {
      imageSrc: "https://yassir.com/wp-content/uploads/yassir_ogimg-1.png",
      entreprise: "Owais Khan",
      secteur: "Technologie et numérique",
      title: "Yassir",
      description: "Punchword est un média social Web 3 qui redonne le pouvoir aux créateurs.",
      url: "https://timerse.com",
    },
  ];
  const [items, setItems] = useState([]);
  const { mutate, isLoading } = useMutation(() => getPartenaires(), {
    async onSuccess(data) {
      console.log(data);
      if (data && data.data) {
        setItems(
          data.data.map((el) => {
            return {
              id: el.id,
              title: el.nom,
              imageSrc: BASE_URL_Image + el.image,
            };
          })
        );
      } else {
      }
    },
    onError(error) {},
  });

  useEffect(() => {
    mutate();
  }, []);

  return (
    <AnimationRevealPage>
      <Container style={{ marginTop: "70px" }}>
        <Content>
          <HeadingWithControl>
            <Heading style={{ fontSize: "20px" }}>Nos Partenaires</Heading>
          </HeadingWithControl>
          <Row className="mt-5">
            {items.map((card, index) => (
              <Col key={card.id} xs={6} md={4} lg={3} style={{ marginTop: "20px" }}>
                <Card
                  style={{
                    padding: 0,
                    cursor: "pointer",
                    overflow: "hidden",
                    border: "none",
                    background: "white",
                    borderRadius: "20px",
                    boxShadow: "-3px 4px 8px 0px #999da3",
                  }}
                >
                  <Details style={{ padding: 0, display: "flex", alignItems: "center" }}>
                    <Image
                      imageSrc={card.imageSrc}
                      style={{ backgroundRepeat: "no-repeat", width: "100%", maxWidth: "150px" }}
                    />
                    <div style={{ padding: "20px" }}>
                      <Title style={{ textAlign: "center" }}>{card.title}</Title>
                    </div>
                  </Details>
                </Card>
              </Col>
            ))}
          </Row>
          <div className="w-100 mt-5 d-flex align-items-center justify-content-center">
            <div className="w-100" style={{ maxWidth: "900px" }}>
              <MainCard className="p-0">
                <div className="w-100">
                  <h3 className="text-lg font-semibold mb-3">
                    {lang === "fr"
                      ? "Devenez Notre Partenaire de Confiance"
                      : lang === "en"
                      ? "Become Our Trusted Partner"
                      : "كن شريكنا الموثوق"}
                  </h3>
                  <p className="text-md font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
                    {lang === "fr"
                      ? "Rejoignez-nous dans notre aventure et bénéficiez d'opportunités uniques pour faire croître votre entreprise. "
                      : lang === "en"
                      ? "Join us on our adventure and benefit from unique opportunities to grow your business. "
                      : "انضم إلينا في مغامرتنا واستفد من الفرص الفريدة لتنمية أعمالك."}

                    <br></br>
                    {lang === "fr"
                      ? "Ensemble, nous pouvons atteindre de nouveaux sommets. Partageons notre succès!"
                      : lang === "en"
                      ? "Together we can reach new heights. Let's share our success!"
                      : "معًا يمكننا الوصول إلى آفاق جديدة. دعونا نشارك نجاحنا!"}
                  </p>
                </div>
                <div className="w-100 new_message_button d-flex justify-content-center align-items-center p-0">
                  {/* <Email style={{ color: theme.palette.grey[400] }} fontSize="medium" /> */}

                  <ButtonMui
                    sx={{
                      ...theme.typography.commonAvatar,
                      ...theme.typography.button,
                      transition: "all .2s ease-in-out",
                      width: "100%",
                      background: theme.palette.primary.light,
                      color: theme.palette.primary.dark,
                      "&:hover": {
                        background: theme.palette.primary.dark,
                        color: theme.palette.primary.light,
                      },
                      height: "100%",
                      overflow: "hidden",
                      borderRadius: "none",
                    }}
                    className="py-2 px-5"
                    onClick={() => {}}
                  >
                    {lang === "fr"
                      ? "Devenez Partenaire avec Nous"
                      : lang === "en"
                      ? "Become a Partner with Us"
                      : "كن شريكًا معنا"}
                  </ButtonMui>
                </div>
              </MainCard>
            </div>
          </div>
        </Content>
      </Container>
      <Modal show={isLoading} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Body>
          <MDBox
            mx={2}
            my={1}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              Loading ...
            </MDTypography>
          </MDBox>
        </Modal.Body>
      </Modal>
    </AnimationRevealPage>
  );
};
