// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import List from "layouts/MainLayout/Header/NotificationSection/NotificationPage/components/List";

// Dashboard components

function NotificationsPage() {
  return (
    <>
      <MDBox py={3}>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <List />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      {/* <Footer /> */}
    </>
  );
}

export default NotificationsPage;
