import React, { useEffect, useState } from "react";
import { Box, Typography, Alert } from "@mui/material";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import { useSelector } from "react-redux";

// Fonction pour détecter les anciens appareils iOS
const detectOldiOSDevices = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Vérifier si l'appareil est un iPhone ou un iPad
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    const iOSVersion = userAgent.match(/OS (\d+)_/i);
    if (iOSVersion && iOSVersion.length > 1) {
      const majorVersion = parseInt(iOSVersion[1], 10);
      if (majorVersion < 11) {
        return true;
      }
    }
  }
  return false;
};

const WarningMessage = () => {
  const lang = useSelector((state) => state.customization.lang);
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    if (detectOldiOSDevices()) {
      setShowWarning(true);
    }
  }, []);

  if (!showWarning) {
    return null;
  }

  return (
    <Box sx={{ width: "100%", p: 2 }}>
      <Alert
        severity="warning"
        sx={{ textAlign: "center", alignItems: "center", justifyContent: "center" }}
      >
        <Typography variant="body1">
          {lang === "fr"
            ? "Attention : Vous utilisez un ancien appareil iOS. Pour une meilleure expérience, veuillez utiliser un appareil plus récent."
            : lang === "en"
            ? "Warning: You are using an old iOS device. For a better experience, please use a newer device."
            : "تحذير: أنت تستخدم جهاز iOS قديمًا. للحصول على تجربة أفضل، يرجى استخدام جهاز أحدث."}
        </Typography>
      </Alert>

      <Alert
        severity="info"
        sx={{ textAlign: "center", alignItems: "center", justifyContent: "center" }}
        className="mt-3"
      >
        <Box display="flex" alignItems="center" justifyContent="center" mt={1}>
          <Typography variant="body2">
            <RotateLeftIcon sx={{ mr: 1 }} />
            <br></br>

            {lang === "fr"
              ? "Vous pouvez également naviguer en mode paysage."
              : lang === "en"
              ? "You can also navigate in landscape mode."
              : "يمكنك أيضًا التنقل في الوضع الأفقي."}
          </Typography>
        </Box>
      </Alert>
    </Box>
  );
};

export default WarningMessage;
