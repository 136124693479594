import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import { useMaterialUIController, setMiniSidenav } from 'context';
import Sidenav from 'examples/Sidenav';
import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

// Material Dashboard 2 React routes
import routes from "routes";
function PubLayout() {
    
  const navigate = useNavigate();
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [activated, setActivated] = useState(false);

  useEffect(()=> {
    let act = localStorage.getItem("activated") ;
    
    if(act != null && act != "" && act != "null" && act != "false" &&  act != false ) {
      setActivated(true) ;
    }else {
      setActivated(false) ;
    }
  } , [])
  

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };
  return (
    <>
      {activated &&
      <>
        <Sidenav
          color={sidenavColor}
          // brand={brandWhite}
          brandName="Le Portail Economique Algérien"
          brandType="pub"
          routes={routes}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
        />
        <Outlet />
      </>
      }
      {!activated  &&
        <div className='w-100 h-100 d-flex justify-content-center align-items-center' style={{minHeight : "100vh" , minWidth : "100vw"}}>
          
          <MDBox
            mx={2}
            my={1}
            py={3}
            px={2}
            variant="gradient"
            bgColor="light"
            borderRadius="lg"
            coloredShadow="info"
            style={{cursor : "pointer" , width : "100%" , maxWidth : "550px"}}
          >
            <MDTypography variant="h6">
                Votre demande est en cours de traitement... 
            </MDTypography>
            <div className='w-100 mt-5 d-flex align-items-center justify-content-end'>
              <MDButton
                component="button"
                target="_blank"
                rel="noreferrer"
                variant="gradient"
                color={"primary"}
                onClick={()=> {
                  localStorage.removeItem("token") ; 
                  localStorage.removeItem("name") ; 
                  localStorage.removeItem("id") ; 
                  localStorage.removeItem("role") ; 
                  localStorage.removeItem("email") ; 
                  localStorage.removeItem("entreprise_id") ; 
                  localStorage.removeItem("groupe_id") ; 
                  localStorage.removeItem("activated") ; 
                  navigate("/") ; 
                }}
                fullWidth
              >
                Se déconnecter 
              </MDButton>
              
            </div>
          </MDBox>

        </div>
      }
    </>
  );
}

export default PubLayout;