/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "layoutsGroupe/Groupe/components/ProfileInfoCard";
import ProfilesList from "layoutsGroupe/Groupe/components/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "layoutsGroupe/Groupe/components/Header";
import PlatformSettings from "layoutsGroupe/Groupe/components/PlatformSettings";

// Data
import profilesListData from "layoutsGroupe/Groupe/data/profilesListData";

// Images
import homeDecor1 from "assets/images/home-decor-1.jpg";
import homeDecor2 from "assets/images/home-decor-2.jpg";
import homeDecor3 from "assets/images/home-decor-3.jpg";
import homeDecor4 from "assets/images/home-decor-4.jpeg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { Card, Icon } from "@mui/material";
import MDBadge from "components/MDBadge";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { getGroupePage } from "services/groupeService";
import { BASE_URL_Image } from "services/server";
import { Modal } from "react-bootstrap";
import { updateGroupe } from "services/adminService";
import { toast } from "react-toastify";

function Overview() {
  const navigate = useNavigate();
  const [services, setServices] = useState([]) ;
  const [secteurs, setSecteurs] = useState([]) ;

    

  const [groupe, setGroupe] = useState({}) ; 
  const { mutate : mutateGroupe, isLoading : isLoadingGroupe } = useMutation(
    () => getGroupePage(),
    {
      async onSuccess(data) {
        console.log(data);
        if(data && data.data && data.data.groupe) {
          let result = data.data.groupe[0] ; 
          
          let infos_result = {} ; 
          if(result.tel)  infos_result["tel"] = result.tel ; 
          if(result.fax)  infos_result["fax"] = result.fax ;
          if(result.email) infos_result["email"] = result.email ;
          if(result.localisation) infos_result["location"] = result.localisation ; 

          let links_result = [] ; 
          
          if(result.facebook) {
            links_result.push({
              link: result.facebook,
              icon: <FacebookIcon />,
              color: "facebook",
            }) ; 
          }
          if(result.twitter) {
            links_result.push({
              link: result.twitter,
              icon: <TwitterIcon />,
              color: "twitter",
            }) ; 
          }
          if(result.instagram) {
            links_result.push({
              link: result.instagram,
              icon: <InstagramIcon />,
              color: "instagram", 
            }) ; 
          }
          if(result.sitweb) {
            links_result.push({
              link: result.sitweb,
              icon: <Icon fontSize="small">language</Icon>,
              color: "portefolio", 
            }) ; 
          }

          setGroupe({
            id : result.id , 
            nom : result.nom , 
            profile_pic :BASE_URL_Image+ result.profile_pic , 
            cover_pic : BASE_URL_Image+result.cover_pic , 
            presentation : result.presentation  , 
            infos : infos_result ,
            links : links_result , 
            entreprises : data.data.entreprises.map(el => {return {
              id : el.id ,
              image: BASE_URL_Image+el.profile_pic,
              name: el.nom,
              services : el.services , 
              action: {
                type: "internal",
                route: "/entreprise/"+el.id,
                color: "info",
                label: "Voir",
              },
            }})
          }) ; 


          setServices(
            data.data.services.map((el) => {
              return {
                  id : el.id , 
                  imageSrc: BASE_URL_Image + el.image ,
                  title: el.titre,
                  description: el.description,
                  locationText: el.localisation,
                  entrepriseText: el.entreprise_nom,
                  entreprise_id: el.entreprise_id,
                  secteur: el.secteur_nom,
                  secteur_id: el.secteur_id,
              } 
            })
            ) ; 


            setSecteurs( data.data.secteurs ) ; 

        }else { 
          
        }
      },
      onError(error) {
        
      },
    }
  );

  useEffect(()=> {
    mutateGroupe() ; 
  } , []) ;

  const { mutate : mutateUpdateGroupe, isLoading : isLoadingUpdateGroupe } = useMutation(
    (userInfo) => updateGroupe({...userInfo , id : groupe.id}),
    {
      async onSuccess(data) {
        console.log(data);
        if(data && data.data) {
          notify_success("Mettre à jour avec succès") ;
          mutateGroupe() ; 
        }else { 
          notify("Une erreur a été produit") ;
        }
      },
      onError(error) {
        
      },
    }
  );
  const notify = (message) => {
    
    toast.error(message, {
      position: "bottom-right",
      autoClose: 3000 , 
      fontSize : "12px" 
      });
  }

  const notify_success = (message) => {
    
    toast.success(message, {
      position: "bottom-right",
      autoClose: 3000
      });
  }


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header groupe={groupe} onUpdate={mutateUpdateGroupe}>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} xl={4} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              <ProfileInfoCard
                title="Description du groupe"
                description={groupe && groupe.presentation ? groupe.presentation : ""}
                info={groupe && groupe.infos ? groupe.infos : {}}
                social={groupe && groupe.links ? groupe.links : []}
                action={{ route: "", tooltip: "Modifier les informaitons" }}
                shadow={false}
              />
              <Divider orientation="vertical" sx={{ mx: 0 }} />
            </Grid>
            <Grid item xs={12} xl={4}>
              <Card sx={{ height: "100%" , boxShadow :"none" }}>
                <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
                  <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                    Secteurs
                  </MDTypography>
                </MDBox>
                <MDBox p={2}>
                  <MDBox mb={2} lineHeight={1}>
                    
                    <Grid container spacing={3} style={{width : "100%"}}>
                      
                          
                    <Grid item xs={12} container>
                              <Grid container spacing={3} xs={12}>
                                <Grid item>
                                  <MDBox >
                                  {secteurs.map((secteur, index) => (
                                    <MDBadge key={secteur.id} badgeContent={secteur.nom} color="dark" variant="gradient" size="md" style={{width:  "100%" , marginBottom : "5px"}} />
                                    ))}
                                  </MDBox>
                                </Grid>
                              </Grid>
                          </Grid>

                          
                          <Grid item xs={12}>
                            <MDBox opacity={0.3}>
                              <Divider />
                            </MDBox>
                          </Grid>
                    </Grid>

                    <Grid container spacing={3} style={{width : "100%"}}>

                    </Grid>
                    
                  </MDBox>
                </MDBox>
              </Card>
              
            </Grid>
            
            <Grid item xs={12} md={6} xl={4}  sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              <ProfilesList title="Entreprises" profiles={groupe && groupe.entreprises ? groupe.entreprises : []} shadow={false} />
              {/* <PlatformSettings /> */}
            </Grid>
          </Grid>
        </MDBox>
      </Header>
      {/* <Footer /> */}
      <Modal
      show={ isLoadingGroupe || isLoadingUpdateGroupe}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <MDBox
          mx={2}
          my={1}
          py={3}
          px={2}
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
        >
          <MDTypography variant="h6" color="white">
          {isLoadingGroupe ? "Loading ..." : "Updating ..."}
          </MDTypography> 
        </MDBox>
      </Modal.Body>
    </Modal>
    </DashboardLayout>
  );
}

export default Overview;
