import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import Header, { NavLink, NavLinks, PrimaryLink, LogoLink, NavToggle, DesktopNavLinks } from "../../../components/headers/light.js";
import ResponsiveVideoEmbed from "../../../helpers/ResponsiveVideoEmbed.js";

import HeaderSlider from "./Slider.js" ;
import HeaderBannieresSlider from "./SliderBannieres.js" ;
import logo from "assets/images/logo_mini.png";
import { useNavigate } from "react-router-dom";
import background from 'assets/images/backgroundHeaderHome.jpg'
import './home.css' ;
const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
  background-image: url("/assets/images/backgroundHeaderHome.jpg") ;
  
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-50`;

const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`pt-24 pb-32 px-4 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`flex flex-col items-center lg:block`;
const RightColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8`;

const Heading = styled.h1`
  ${tw`text-3xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative text-primary-500 px-4 -mx-4 py-2`}
  &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`}
  }
`;

const Notification = tw.span`inline-block my-4 pl-3 py-1 text-gray-100 border-l-4 border-blue-500 font-medium text-sm`;

const PrimaryAction = tw.button`px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 text-primary-500 font-bold rounded shadow transition duration-300 hocus:bg-primary-500 hocus:text-gray-100 focus:shadow-outline`;

const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  padding-bottom: 56.25% !important;
  padding-top: 0px !important;
  ${tw`rounded`}
  iframe {
    ${tw`rounded bg-black shadow-xl`}
  }
`;


const Actions = styled.div`
  ${tw`relative max-w-md text-center mx-auto lg:mx-0`}
  input {
    ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-primary-500 hover:border-gray-500 border-none`}
  }
  button {
    ${tw`w-full sm:absolute right-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
  }
`;

export default ({publireportages}) => {
  let navigate = useNavigate();
  const [search, setSearch] = useState("");

  
  const rechercher = () => {
    if(search != "") {
      localStorage.setItem("searchService" , search) ; 
    }
    navigate("/services") ; 
  }
  

  return (
    <Container style={{minHeight :  "100vh", backgroundImage : "url('"+background+"'" }} className="headerHome">
      <OpacityOverlay />
      <HeroContainer>
        <TwoColumn className="headerPadding" style={{paddingBottom : "0px" , paddingTop: "5vw"}}>
          <LeftColumn>
            <Heading className="d-flex justify-content-center align-items-center">
              <img className="imgLogo" src={logo} alt="logo" style={{width : "100px" , height : "auto"}} />
              {/* <span className="textLogo" style={{fontSize : "20px" , marginLeft : "10px"}}>
              Le Portail Economique Algérien
              </span> */}
              
            </Heading>
            <Heading className="textH1 d-flex justify-content-center align-items-center">
              {/* <span>Trouver</span> */}
              <br />
              <SlantedBackground>Le Portail Economique Algérien</SlantedBackground>
              <br />
              {/* <span>en Algérie</span> */}
            </Heading>
            <Notification style={{width : "100%" , textAlign : "center"}}>Votre plateforme pour trouver un professionnel en Algérie.</Notification>

            <br/>
            <Actions>
              <input className="heightInput" type="text" placeholder="Recherche..."  onChange={e => setSearch(e.currentTarget.value)}/>
              <button style={{border : "none"}} onClick={rechercher}>Trouver</button>
            </Actions>
            {/* <PrimaryAction>Read Customer Stories</PrimaryAction> */}
          </LeftColumn>
          <RightColumn>
             <HeaderSlider items={publireportages}/>
          </RightColumn>
        </TwoColumn>
        <HeaderBannieresSlider></HeaderBannieresSlider>
      </HeroContainer>
    </Container>
  );
};
