import React from "react";
import { Container, Typography, List, ListItem, ListItemText, Divider } from "@mui/material";

const PolitiqueUtilisation = () => {
  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Politique d'Utilisation de la Plateforme d'Exportation
      </Typography>
      <Typography variant="h6" gutterBottom>
        1. Introduction
      </Typography>
      <Typography paragraph>
        Bienvenue sur notre plateforme d'exportation dédiée aux acheteurs et aux vendeurs. Notre
        mission est de faciliter les échanges commerciaux internationaux en fournissant un espace
        interactif et sécurisé où les utilisateurs peuvent promouvoir, vendre et acheter des
        produits.
      </Typography>
      <Divider />
      <Typography variant="h6" gutterBottom>
        2. Inscription et Abonnement
      </Typography>
      <List>
        <ListItem>
          <ListItemText
            primary="2.1 Inscription Gratuite"
            secondary="Les utilisateurs peuvent s'inscrire gratuitement sur notre plateforme et bénéficier de toutes les fonctionnalités pendant les trois premiers mois suivant leur inscription."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="2.2 Abonnement Payant"
            secondary="À l'issue de la période d'essai gratuite de trois mois, les utilisateurs doivent souscrire un abonnement payant pour continuer à utiliser les fonctionnalités interactives de la plateforme, notamment : ajouter des produits ou des annonces à la liste des favoris, envoyer des messages concernant les produits ou les annonces, publier des produits ou des annonces."
          />
        </ListItem>
      </List>
      <Divider />
      <Typography variant="h6" gutterBottom>
        3. Utilisation de la Plateforme
      </Typography>
      <List>
        <ListItem>
          <ListItemText
            primary="3.1 Création de Profil"
            secondary="Les utilisateurs doivent créer un profil détaillé, incluant des informations précises sur leur entreprise et leurs produits. Les informations fournies doivent être exactes et à jour."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="3.2 Publication d'Annonces"
            secondary="Les annonces doivent respecter les critères de qualité et les directives de notre plateforme. Les produits proposés doivent être légaux et conformes aux réglementations internationales."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="3.3 Interaction et Communication"
            secondary="Les utilisateurs peuvent communiquer entre eux via la messagerie intégrée de la plateforme. Toutes les communications doivent être respectueuses et professionnelles."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="3.4 Favoris"
            secondary="Les utilisateurs peuvent ajouter des produits ou des annonces à leur liste de favoris pour un suivi et un accès rapides."
          />
        </ListItem>
      </List>
      <Divider />
      <Typography variant="h6" gutterBottom>
        4. Politique de Paiement
      </Typography>
      <List>
        <ListItem>
          <ListItemText
            primary="4.1 Modes de Paiement"
            secondary="Les abonnements peuvent être payés par carte de crédit, virement bancaire ou tout autre mode de paiement accepté par la plateforme."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="4.2 Renouvellement et Annulation"
            secondary="Les abonnements sont renouvelés automatiquement à la fin de chaque période de facturation, sauf si l'utilisateur annule son abonnement avant la date de renouvellement."
          />
        </ListItem>
      </List>
      <Divider />
      <Typography variant="h6" gutterBottom>
        5. Sécurité et Confidentialité
      </Typography>
      <List>
        <ListItem>
          <ListItemText
            primary="5.1 Protection des Données"
            secondary="Nous nous engageons à protéger les données personnelles de nos utilisateurs. Toutes les informations recueillies sont utilisées conformément à notre politique de confidentialité."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="5.2 Sécurité des Transactions"
            secondary="Les transactions effectuées sur notre plateforme sont sécurisées par des protocoles de cryptage avancés pour assurer la protection des informations financières des utilisateurs."
          />
        </ListItem>
      </List>
      <Divider />
      <Typography variant="h6" gutterBottom>
        6. Responsabilités des Utilisateurs
      </Typography>
      <List>
        <ListItem>
          <ListItemText
            primary="6.1 Conformité Légale"
            secondary="Les utilisateurs doivent respecter toutes les lois et réglementations applicables à leurs activités commerciales et à l'utilisation de la plateforme."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="6.2 Contenu Prohibé"
            secondary="La publication de contenu illicite, offensant ou inapproprié est strictement interdite. Nous nous réservons le droit de supprimer tout contenu non conforme et de suspendre ou résilier les comptes des contrevenants."
          />
        </ListItem>
      </List>
      <Divider />
      <Typography variant="h6" gutterBottom>
        7. Modifications de la Politique
      </Typography>
      <Typography paragraph>
        Nous nous réservons le droit de modifier cette politique à tout moment. Les utilisateurs
        seront informés de tout changement significatif par e-mail ou via une notification sur la
        plateforme.
      </Typography>
      <Divider />
      <Typography variant="h6" gutterBottom>
        8. Contact
      </Typography>
      <Typography paragraph>
        Pour toute question ou demande d'assistance, veuillez contacter notre service client à
        l'adresse suivante : support@exportplatform.com
      </Typography>
    </Container>
  );
};

export default PolitiqueUtilisation;
