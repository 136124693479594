import { useSelector, useDispatch } from "react-redux";

import Flag1 from "assets/img/flag_fr.png";
import Flag2 from "assets/img/flag_ar.png";
import Flag3 from "assets/img/flag_en.png";
import { SET_LANG } from "store/actions";
// Images

function AuthLangues() {
  const lang = useSelector((state) => state.customization.lang);
  const dispatch = useDispatch();

  return (
    <div className="semiBold font11 flexCenter">
      <div
        className="radius8"
        style={{
          padding: "10px 15px",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          backgroundColor: lang === "fr" ? "#8d8d8d6e" : "",
        }}
        onClick={() => {
          dispatch({ type: SET_LANG, lang: "fr" });
        }}
      >
        <img style={{ height: "25px", width: "auto" }} src={Flag1} alt="fr" />
      </div>

      <div
        className="radius8"
        style={{
          padding: "10px 8px",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          backgroundColor: lang === "ar" ? "#8d8d8d6e" : "",
        }}
        onClick={() => {
          dispatch({ type: SET_LANG, lang: "ar" });
        }}
      >
        <img style={{ height: "25px", width: "auto" }} src={Flag2} alt="ar" />
      </div>

      <div
        className="radius8 "
        style={{
          padding: "10px 8px",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          backgroundColor: lang === "en" ? "#8d8d8d6e" : "",
        }}
        onClick={() => {
          dispatch({ type: SET_LANG, lang: "en" });
        }}
      >
        <img style={{ height: "25px", width: "auto" }} src={Flag3} alt="en" />
      </div>
    </div>
  );
}

export default AuthLangues;
