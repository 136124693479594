import { useEffect, useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { signin } from "services/authService";
import {
  AppBar,
  Box,
  Button,
  ButtonBase,
  Checkbox,
  Divider,
  FormControlLabel,
  Icon,
  LinearProgress,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import LogoIcon from "assets/images/logo_targets_zone_dark2.png";
import IllutrationLogin from "assets/images/login-illustration.png";
import { SocialIcon } from "react-social-icons";
import { ArrowBack, CheckBox, ShoppingCart, Storefront } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { demandeInscription } from "services/visiteurService";
import AuthLangues from "./AuthLangues";
import { GoogleLogin } from "@react-oauth/google";
import { OauthInscription } from "services/authService";
import { Modal } from "react-bootstrap";
import PolitiqueUtilisation from "./PolitiqueUtilisation";

// Images

function SignUp() {
  const theme = useTheme();
  const lang = useSelector((state) => state.customization.lang);
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [acceptPolicy, setAcceptPolicy] = useState(false);
  const handleSetTabValue = (event, newValue) => setTabValue(newValue);
  const { mutate, isLoading } = useMutation((userData) => demandeInscription(userData), {
    async onSuccess(data) {
      console.log(data);

      // if (data.result) {
      //   notify_success("valid");
      //   localStorage.setItem("token", data.token);
      //   localStorage.setItem("email", data.email);
      //   localStorage.setItem("name", data.name);
      //   localStorage.setItem("id", data.id);
      //   localStorage.setItem("role", data.role);
      //   localStorage.setItem("acheteur_id", data.acheteur_id);
      //   localStorage.setItem("entreprise_id", data.entreprise_id);
      //   localStorage.setItem("activated", data.activated);
      //   localStorage.setItem("profile_pic", data.profile_pic);
      //   if (data.role === "admin") {
      //     navigate("/admin");
      //   } else if (data.role === "acheteur") {
      //     navigate("/acheteur");
      //   } else if (data.role === "entreprise") {
      //     navigate("/entreprise");
      //   }
      // }
      if (data.success) {
        navigate("/authentication/account-validation");
      } else {
        notify(data.message != "" ? data.message : "error");
      }
    },
    onError(error) {
      notify("error");
      if (Array.isArray(error.response.data.error)) {
        error.response.data.error.forEach((el) => console.log(el.message));
      } else {
        console.log(error.response.data.message);
      }
    },
  });

  const loginOnSuccess = (data) => {
    console.log(data);

    if (data.message === "Valider votre compte") {
      navigate("/authentication/account-validation/" + data.data);
    }
    if (data.message === "Votre compte est bloqué!") {
      navigate("/authentication/bloqued_account/");
    } else if (data.result) {
      localStorage.setItem("token", data.token);
      localStorage.setItem("email", data.email);
      localStorage.setItem("name", data.name);
      localStorage.setItem("id", data.id);
      localStorage.setItem("role", data.role);
      localStorage.setItem("groupe_id", data.groupe_id);
      localStorage.setItem("entreprise_id", data.entreprise_id);
      localStorage.setItem("acheteur_id", data.acheteur_id);
      localStorage.setItem("activated", data.activated);
      localStorage.setItem("profile_pic", data.profile_pic);
      if (data.role === "admin") {
        navigate("/admin");
      } else if (data.role === "groupe") {
        navigate("/groupe");
      } else if (data.role === "entreprise") {
        navigate("/entreprise");
      } else if (data.role === "acheteur") {
        navigate("/acheteur");
      } else if (data.role === "pub") {
        navigate("/pub");
      }
    } else {
      notify(data.message);
    }
  };

  const { mutate: mutateOauth, isLoading: isLoadingOauth } = useMutation(
    (userData) => OauthInscription(userData),
    {
      async onSuccess(data) {
        loginOnSuccess(data);
      },
      onError(error) {
        notify("error");
        if (Array.isArray(error.response.data.error)) {
          error.response.data.error.forEach((el) => console.log(el.message));
        } else {
          console.log(error.response.data.message);
        }
      },
    }
  );
  const [rememberMe, setRememberMe] = useState(false);
  const [nom, setNom] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");

  const navigate = useNavigate();
  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const Login = () => {
    var user = {
      email,
      password,
      password2,
      type: tabValue === 0 ? "acheteur" : "entreprise",
      nom,
      lang,
    };
    console.log(user);

    var valid = true;

    if (
      !email ||
      email === "" ||
      !String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      notify(
        lang === "fr"
          ? "Entrez votre Email s'il vous plaît !"
          : lang === "en"
          ? "Enter your Email please!"
          : "أدخل بريدك الإلكتروني من فضلك!"
      );
      valid = false;
    } else if (!nom || nom === "") {
      notify(
        lang === "fr"
          ? "Entrez votre dénomination s'il vous plaît !"
          : lang === "en"
          ? "Enter your denomination please!"
          : "أدخل التسمية خاصتك من فضلك!"
      );
      valid = false;
    } else if (!password || password === "") {
      notify(
        lang === "fr"
          ? "Entrez votre mot de passe s'il vous plaît !"
          : lang === "en"
          ? "Enter your password please !"
          : "أدخل كلمة المرور الخاصة بك من فضلك!"
      );
      valid = false;
    } else if (!password2 || password2 === "") {
      notify(
        lang === "fr"
          ? "Entrez votre confirmation de mot de passe s'il vous plaît !"
          : lang === "en"
          ? "Enter your password confirmation please !"
          : "أدخل تأكيد كلمة المرور الخاصة بك من فضلك!"
      );
      valid = false;
    } else if (password !== password2) {
      notify(
        lang === "fr"
          ? "Mot de passe pas le même !"
          : lang === "en"
          ? "Password not the same!"
          : "كلمة المرور ليست هي نفسها!"
      );
      valid = false;
    }

    if (valid) {
      mutate(user);
    }
  };

  const notify = (message) => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 3000,
      fontSize: "12px",
    });
  };

  const notify_success = (message) => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 3000,
    });
  };

  useEffect(() => {
    let token = localStorage.getItem("token");
    let role = localStorage.getItem("role");
    if (token != null && token !== "") {
      if (role === "admin") {
        navigate("/admin");
      } else if (role === "acheteur") {
        navigate("/acheteur");
      } else if (role === "entreprise") {
        navigate("/entreprise");
      }
    }
  }, []);

  function isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      window.navigator.userAgent
    );
  }
  function parseJwt(token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  return (
    <BasicLayout image={null}>
      <Card>
        {/* <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Login
          </MDTypography>
          <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <FacebookIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GitHubIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GoogleIcon color="inherit" />
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox> */}
        <div className="w-100 mb-5">
          <Button
            startIcon={
              <ArrowBack style={lang === "ar" ? { transform: "rotate(180deg)" } : {}}></ArrowBack>
            }
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.button,
              transition: "all .2s ease-in-out",
              background: theme.palette.secondary.light,
              color: theme.palette.secondary.dark,
              "&:hover": {
                background: theme.palette.secondary.dark,
                color: theme.palette.secondary.light,
              },
              height: "100%",
              overflow: "hidden",
              borderRadius: "none",
            }}
            className="py-2 px-5"
            onClick={() => {
              navigate("/authentication/sign-up");
            }}
          >
            {lang === "fr" ? "Retour" : lang === "en" ? "Go Back" : "العودة"}
          </Button>
        </div>
        <div className="row w-100 m-0 row-sm-reverse">
          <div className="col-12 d-flex align-items-center justify-content-around">
            <MDBox
              component="form"
              role="form"
              className="p-2"
              style={{ width: "95%", direction: lang === "ar" ? "rtl" : "ltr" }}
            >
              <div className="w-100 d-flex align-items-center justify-content-center">
                <div className="w-100">
                  <PolitiqueUtilisation></PolitiqueUtilisation>
                </div>
              </div>
            </MDBox>
          </div>
          {/* {!isMobileDevice() ? (
          ) : (
            <></>
          )} */}
        </div>
        {/* <MDBox pt={4} pb={3} px={3}></MDBox> */}
      </Card>

      <Modal
        show={isLoading || isLoadingOauth}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body style={{ overflow: "hidden" }}>
          <Box sx={{ width: "100%" }}>
            <LinearProgress color="secondary" />
          </Box>
        </Modal.Body>
      </Modal>
    </BasicLayout>
  );
}

export default SignUp;
