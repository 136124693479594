import PropTypes from "prop-types";

// material-ui
import { styled, useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";

// project imports
import MainCard from "ui-component/cards/MainCard";
import TotalIncomeCard from "ui-component/cards/Skeleton/TotalIncomeCard";

// assets
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { useEffect, useState } from "react";

// styles
const CardWrapper = styled(MainCard)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.dark,
  color: theme.palette.secondary.light,
  overflow: "hidden",
  position: "relative",
  "&:after": {
    content: '""',
    position: "absolute",
    width: 210,
    height: 210,
    background: `linear-gradient(210.04deg, ${theme.palette.secondary[200]} -50.94%, rgba(144, 202, 249, 0) 83.49%)`,
    borderRadius: "50%",
    top: -30,
    right: -180,
  },
  "&:before": {
    content: '""',
    position: "absolute",
    width: 210,
    height: 210,
    background: `linear-gradient(140.9deg, ${theme.palette.secondary[200]} -14.02%, rgba(144, 202, 249, 0) 77.58%)`,
    borderRadius: "50%",
    top: -160,
    right: -130,
  },
}));

// ==============================|| DASHBOARD - TOTAL INCOME DARK CARD ||============================== //

const LinksCard = ({ info, lang }) => {
  const theme = useTheme();
  const [labels, setLabels] = useState([]);
  const [values, setValues] = useState([]);

  useEffect(() => {
    let new_labels = [];
    let new_values = [];
    if (lang === "ar") {
      Object.keys(info).forEach((el) => new_labels.push(el));
    } else {
      Object.keys(info).forEach((el) => {
        if (el.match(/[A-Z\s]+/)) {
          const uppercaseLetter = Array.from(el).find((i) => i.match(/[A-Z]+/));
          const newElement = el.replace(uppercaseLetter, ` ${uppercaseLetter.toLowerCase()}`);

          new_labels.push(newElement);
        } else {
          new_labels.push(el);
        }
      });
    }

    // Push the object values into the values array
    Object.values(info).forEach((el) => new_values.push(el));

    setLabels(new_labels);
    setValues(new_values);
  }, [info, lang]);

  // Render the card info items
  const renderItems = labels.map((label, key) => (
    <div className="w-100" style={{ paddingLeft: "10px" }}>
      <MDBox key={label} display="flex" py={1} pr={2}>
        <MDTypography variant="button" fontWeight="bold" textTransform="capitalize" color="light">
          {label}: &nbsp;
        </MDTypography>
        <MDTypography variant="button" fontWeight="regular" color="light">
          &nbsp;{values[key]}
        </MDTypography>
      </MDBox>
    </div>
  ));

  return (
    <>
      <CardWrapper border={false} content={false} style={{ width: "100%" }}>
        <Box sx={{ p: 2 }}>
          <List sx={{ py: 0 }}>
            <ListItem alignItems="center" disableGutters sx={{ py: 0 }}>
              <ListItemAvatar>
                <Avatar
                  variant="rounded"
                  sx={{
                    ...theme.typography.commonAvatar,
                    ...theme.typography.largeAvatar,
                    backgroundColor: theme.palette.secondary[800],
                    color: "#fff",
                  }}
                >
                  <TableChartOutlinedIcon fontSize="inherit" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                sx={{
                  py: 0,
                  mt: 0.45,
                  mb: 0.45,
                }}
                primary={
                  <Typography variant="h4" sx={{ color: "#fff" }}>
                    Information de contact
                  </Typography>
                }
              />
            </ListItem>
          </List>
          <div className="w-100">{renderItems}</div>
        </Box>
      </CardWrapper>
    </>
  );
};

LinksCard.propTypes = {
  isLoading: PropTypes.bool,
};

export default LinksCard;
