/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ProfileInfoCard from "layoutsGroupe/Contact/components/ProfileInfoCard";
import ProfilesList from "layoutsGroupe/Contact/components/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "layoutsGroupe/Contact/components/Header";
import PlatformSettings from "layoutsGroupe/Contact/components/PlatformSettings";

// Data
import profilesListData from "layoutsGroupe/Contact/data/profilesListData";

// Images
import { Card, Icon } from "@mui/material";
import MDBadge from "components/MDBadge";
import { useState } from "react";

// @mui material components
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";

// Data
import { Box } from "@mui/material";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import React from "react";
import { useEffect } from "react";

function Overview() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} xl={4} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              <ProfileInfoCard
                title="Présentation"
                description="Oxxo est une marque française qui existe depuis 1923, avec une expertise et un savoir-faire reconnu comme fournisseur de solutions performantes et durables sur le marché de la menuiserie. Oxxo fabrique et pose des fenêtres, des portes et des fermetures à hautes performances thermiques, acoustiques et d’étanchéité.

                Acquise en 2013 par Cevital, Oxxo est présente sur le marché Algérien depuis 2014. Oxxo Algérie Spa est active dans le domaine de l’extrusion du profilé PVC et l’assemblage des fenêtres et portes fenêtres en PVC à hautes performances thermiques. L’usine de production, implantée dans la localité d’Ain Taghrout, sur une surface de 35 hectares, est équipée de technologies ultra-modernes en robotique et automatisation.
                
                Les sites de production sont répartis comme suit : un site en Algérie, avec une capacité de production annuelle de 2,1 millions de fenêtres par an, soit l’équivalent de 400 000 logements ; et un site en  France, avec une capacité de production annuelle de 200 000 fenêtres, soit l’équivalent de 40 000 logements par an, situé à Cluny près de Lyon. Ce site de production dispose également de moyens de production modernes et puissants, permettant de garantir la fabrication de menuiseries de qualité.
                
                Les activités ont été réparties en 2 réseaux : Oxxo Bâtiment destiné aux professionnels du bâtiment, et Oxxo Baies destiné aux particuliers, en neuf et en rénovation."
                info={
                  {
                    // location: "Ilot D N° 6 Zhun Garidi  - Kouba 16050 Alger - Algérie",
                  }
                }
                social={
                  [
                    // {
                    //   link: "https://www.facebook.com/GroupeCevital/",
                    //   icon: <FacebookIcon />,
                    //   color: "facebook",
                    // },
                    // {
                    //   link: "https://twitter.com/creativetim",
                    //   icon: <TwitterIcon />,
                    //   color: "twitter",
                    // },
                    // {
                    //   link: "https://www.instagram.com/creativetimofficial/",
                    //   icon: <InstagramIcon />,
                    //   color: "instagram",
                    // },
                    // {
                    //   link: " http://www.oxxo.fr",
                    //   icon: <Icon fontSize="small">language</Icon>,
                    //   color: "facebook",
                    // },
                  ]
                }
                action={{ route: "", tooltip: "Modifier les informaitons" }}
                shadow={false}
              />
              <Divider orientation="vertical" sx={{ mx: 0 }} />
            </Grid>
            <Grid item xs={12} md={12} xl={4}>
              <Card sx={{ height: "100%", boxShadow: "none" }}>
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  pt={2}
                  px={2}
                >
                  <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                    Contact
                  </MDTypography>
                </MDBox>
                <MDBox p={2}>
                  <MDBox mb={2} lineHeight={1}>
                    <MDInput style={{ width: "100%", marginTop: "20px" }} label="Téléphone" />
                    <MDInput style={{ width: "100%", marginTop: "20px" }} label="Fax" />
                    <MDInput style={{ width: "100%", marginTop: "20px" }} label="Email" />
                    <MDInput style={{ width: "100%", marginTop: "20px" }} label="Localisation" />
                    <MDInput style={{ width: "100%", marginTop: "20px" }} label="facebook" />
                    <MDInput style={{ width: "100%", marginTop: "20px" }} label="linkedIn" />
                    <MDInput style={{ width: "100%", marginTop: "20px" }} label="Twitter" />
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>

            <Grid item xs={12} md={12} xl={4} sx={{ display: "flex" }}>
              {/* 
              <ProfilesList title="Groupe" profiles={profilesListData} shadow={false} /> */}
              {/* <PlatformSettings /> */}
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              <MDBox>
                <Card sx={{ height: "100%", boxShadow: "none" }}>
                  <MDBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    pt={2}
                    px={2}
                  >
                    <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                      Admin Email
                    </MDTypography>
                  </MDBox>
                  <MDBox p={2}>
                    <MDBox mb={2} lineHeight={1}>
                      <MDTypography variant="p" fontWeight="light" style={{ fontSize: "13px" }}>
                        admin@cevital-groupe.com
                      </MDTypography>
                    </MDBox>
                    <MDButton color="dark" onClick={() => {}}>
                      Changer l'email
                    </MDButton>
                  </MDBox>

                  <MDBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    pt={2}
                    px={2}
                  >
                    <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                      Mot de passe
                    </MDTypography>
                  </MDBox>
                  <MDBox p={2}>
                    <MDBox mb={2} lineHeight={2}>
                      <MDTypography variant="p" fontWeight="light" style={{ fontSize: "13px" }}>
                        ************
                      </MDTypography>
                    </MDBox>
                    <MDButton color="dark" onClick={() => {}}>
                      Changer le mot de passe
                    </MDButton>
                  </MDBox>
                </Card>
              </MDBox>
            </Grid>
            <Grid item xs={12} style={{ display: "flex", justifyContent: "flex-end" }}>
              <MDButton color="warning" onClick={() => {}} style={{ marginRight: "10px" }}>
                Reset
              </MDButton>
              <MDButton color="info" onClick={() => {}}>
                Confirmer
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </Header>
    </DashboardLayout>
  );
}

export default Overview;
