import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import Icon from "@mui/material/Icon";

import MDBadge from "components/MDBadge";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";



export default function data(props) {

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const navigate = useNavigate();
  
  const avatars = (members) =>
    members.map(([image, name]) => (
      <Tooltip key={name} title={name} placeholder="bottom">
        <MDAvatar
          src={image}
          alt="name"
          size="md"
          sx={{
            border: ({ borders: { borderWidth }, palette: { white } }) =>
              `${borderWidth[2]} solid ${white.main}`,
            cursor: "pointer",
            position: "relative",

            "&:not(:first-of-type)": {
              ml: -1.25,
            },

            "&:hover, &:focus": {
              zIndex: "10",
            },
          }}
        />
      </Tooltip>
    ));

    const openModal = () => {
      props.handleOpen() ;
    }

    const openModalValidation = (entreprise) => {
      props.handleOpenValidation(entreprise) ;
    }
    
    const handleUpdate = (userInfo)=> {
      props.handleUpdate(userInfo) ; 
    }
    

    const Company = ({ image, name }) => (
      <MDBox display="flex" alignItems="center" lineHeight={1}>
        <MDAvatar src={image} name={name} size="sm" />
        <MDTypography variant="button" fontWeight="medium" ml={1} lineHeight={1}>
          {name}
        </MDTypography>
      </MDBox>
    );

  return {
    columns: [
      { Header: "Entreprise", accessor: "entreprise", width: "30%", align: "left" },
      { Header: "Groupe", accessor: "companie",  align: "center" },
      { Header: "Secteurs", accessor: "secteurs", align: "center" },
      { Header: "Services", accessor: "services", align: "center" },
      { Header: "Publireportages", accessor: "publireportage", align: "center" },
      { Header: "Status", accessor: "status", align: "center" },
      { Header: "", accessor: "action", align: "center" },
    ],

    rows : props.items.map(el => {
      return {
        entreprise: <Company image={el.image} name={el.name} />,
        abonnement: (
          <MDBox ml={-1}>
            <MDBadge badgeContent={el.abonnement_id ? "payée" : "non payée" }color={el.abonnement_id ? "success"  : "warning"} variant="gradient" size="sm" />
          </MDBox>
        ),
        companie : (
          <MDBox display="flex" py={1}>
            {avatars(el.company.map(ent => [ent.image , ent.name]))}
          </MDBox>
        ),
        secteurs: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
          {(""+el.usedSecteurs).replaceAll("null" , "--")}
          </MDTypography>
        ),
        services: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
          {(""+el.usedServices).replaceAll("null" , "--")}
          </MDTypography>
        ),
        publireportage: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
          {(""+el.usedPublireportages).replaceAll("null" , "--")}
          </MDTypography>
        ),
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent={el.status ? "Activée"  : "Bloquée"} color={el.status ? "success"  : "warning"} variant="gradient" size="sm" />
          </MDBox>
        ),
        action : (
          
          
          
          
        <MDBox color="text" px={2}>
        
        
        <Dropdown align="start">
          <Dropdown.Toggle variant="link" id="icon-dropdown">
            
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item  onClick={()=> {
              navigate("/entreprise/"+el.id); ; 
            }}>Afficher</Dropdown.Item>
            <Dropdown.Item  onClick={()=> {
              handleUpdate({id : el.id , bloque : 1 })
            }} disabled={!el.status}>Bloquer</Dropdown.Item>
            <Dropdown.Item  onClick={()=> {
              handleUpdate({id : el.id , bloque : 0 })
            }} disabled={el.status}>Debloquer</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
          </MDBox>
        )
      }
    }) ,

  };
}
