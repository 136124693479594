/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ProfileInfoCard from "layoutsEntreprises/Contact/components/ProfileInfoCard";
import ProfilesList from "layoutsEntreprises/Contact/components/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "layoutsEntreprises/Contact/components/Header";
import PlatformSettings from "layoutsEntreprises/Contact/components/PlatformSettings";

// Data
import profilesListData from "layoutsEntreprises/Contact/data/profilesListData";

// Images
import { ButtonBase, Card, Icon, LinearProgress, Switch } from "@mui/material";
import MDBadge from "components/MDBadge";
import { useState } from "react";

// @mui material components
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";
import { useTheme } from "@mui/material/styles";

// Data
import data from "layoutsEntreprises/PartenaireList/components/List/data";
import { Box } from "@mui/material";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import React from "react";
import { useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { getInfos } from "services/adminService";
import { toast } from "react-toastify";
import tw from "twin.macro";
import { updateUserEmail } from "services/adminService";
import { updateUserPassword } from "services/adminService";
import { updateUserInfos } from "services/adminService";
import { useSelector } from "react-redux";
import { countries } from "services/countriesService";

const CardPub = tw.div`h-full flex! flex-col sm:border max-w-sm  relative focus:outline-none bg-white m-0`;

function Overview({ role }) {
  const theme = useTheme();
  const lang = useSelector((state) => state.customization.lang);
  const [onEditEmail, setOnEditEmail] = useState(false);

  const [country, setCountry] = useState("");
  function getLangKey(lang) {
    const langMap = {
      fr: "french",
      en: "english",
      ar: "arabic",
    };
    return langMap[lang] || "english"; // Default to 'english' if lang is invalid
  }

  // Function to sort the array based on the selected language
  function sortCountriesByLang(countries, lang) {
    // Get the correct language key based on lang
    const langKey = getLangKey(lang);

    // Sorting the array based on the specified language
    return countries.sort((a, b) => {
      if (a[langKey] < b[langKey]) return -1;
      if (a[langKey] > b[langKey]) return 1;
      return 0;
    });
  }

  const [showPassword, setShowPassword] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const handleOpenEditPassword = () => {
    setOpenAdd(true);
  };
  const handleOpenEditEmail = () => {
    setOnEditEmail(true);
    setOpenAdd(true);
  };
  const handleCloseAdd = () => {
    setOpenAdd(false);
    setOnEditEmail(false);
    setUserInfos((oldValue) => {
      return {
        ...oldValue,
        new_email: "",
        new_email_confirmation: "",
        new_password: "",
        new_password_confirmation: "",
        password: "",
      };
    });
    setShowPassword(false);
  };

  const [userInfos, setUserInfos] = useState({
    email: "",
    new_email: "",
    new_email_confirmation: "",
    new_password: "",
    new_password_confirmation: "",
    password: "",
  });

  const [newInfos, setNewInfos] = useState({
    tel: "",
    fax: "",
    localisation: "",
    email: "",
    facebook: "",
    linkedin: "",
    twitter: "",
    presentation: "",
    siteweb: "",
    instagram: "",
    secteur: "",
  });

  const [infos, setInfos] = useState({
    tel: "",
    fax: "",
    localisation: "",
    email: "",
    facebook: "",
    linkedin: "",
    twitter: "",
    presentation: "",
    siteweb: "",
    instagram: "",
    secteur: "",
  });
  const { mutate, isLoading } = useMutation(() => getInfos(), {
    async onSuccess(data) {
      console.log(data);
      if (data && data.data) {
        setInfos({
          tel: data.data.tel != null && data.data.tel !== "" ? data.data.tel : "",
          fax: data.data.fax != null && data.data.fax !== "" ? data.data.fax : "",
          localisation:
            data.data.localisation != null && data.data.localisation !== ""
              ? data.data.localisation
              : "",
          email: data.data.email != null && data.data.email !== "" ? data.data.email : "",
          facebook:
            data.data.facebook != null && data.data.facebook !== "" ? data.data.facebook : "",
          linkedin:
            data.data.linkedin != null && data.data.linkedin !== "" ? data.data.linkedin : "",
          twitter: data.data.twitter != null && data.data.twitter !== "" ? data.data.twitter : "",
          presentation:
            data.data.presentation != null && data.data.presentation !== ""
              ? data.data.presentation
              : "",
          siteweb: data.data.sitweb != null && data.data.sitweb !== "" ? data.data.sitweb : "",
          instagram:
            data.data.instagram != null && data.data.instagram !== "" ? data.data.instagram : "",
        });
        setNewInfos({
          tel: data.data.tel != null && data.data.tel !== "" ? data.data.tel : "",
          fax: data.data.fax != null && data.data.fax !== "" ? data.data.fax : "",
          localisation:
            data.data.localisation != null && data.data.localisation !== ""
              ? data.data.localisation
              : "",
          email: data.data.email != null && data.data.email !== "" ? data.data.email : "",
          facebook:
            data.data.facebook != null && data.data.facebook !== "" ? data.data.facebook : "",
          linkedin:
            data.data.linkedin != null && data.data.linkedin !== "" ? data.data.linkedin : "",
          twitter: data.data.twitter != null && data.data.twitter !== "" ? data.data.twitter : "",
          presentation:
            data.data.presentation != null && data.data.presentation !== ""
              ? data.data.presentation
              : "",
          siteweb: data.data.sitweb != null && data.data.sitweb !== "" ? data.data.sitweb : "",
          instagram:
            data.data.instagram != null && data.data.instagram !== "" ? data.data.instagram : "",
          secteur: data.data.secteur != null && data.data.secteur !== "" ? data.data.secteur : "",
        });

        if (localStorage.getItem("email")) {
          setUserInfos((oldValue) => {
            return { ...oldValue, email: localStorage.getItem("email") };
          });
        }
      } else {
      }
    },
    onError(error) {},
  });

  const { mutate: mutateUpdateEmail, isLoading: isLoadingUpdateEmail } = useMutation(
    () =>
      updateUserEmail({
        new_email: userInfos.new_email,
        new_email_confirmation: userInfos.new_email_confirmation,
        password: userInfos.password,
      }),
    {
      async onSuccess(data) {
        console.log(data);
        if (data && data.success) {
          setUserInfos((oldValue) => {
            return { ...oldValue, email: oldValue.new_email };
          });
          localStorage.setItem("email", userInfos.new_email);
          handleCloseAdd();
        } else {
        }
      },
      onError(error) {},
    }
  );

  const { mutate: mutateUpdatePassword, isLoading: isLoadingUpdatePassword } = useMutation(
    () =>
      updateUserPassword({
        new_password: userInfos.new_password,
        new_password_confirmation: userInfos.new_password_confirmation,
        password: userInfos.password,
      }),
    {
      async onSuccess(data) {
        console.log(data);
        if (data && data.success) {
          handleCloseAdd();
        } else {
        }
      },
      onError(error) {},
    }
  );

  const { mutate: mutateUpdateInfos, isLoading: isLoadingUpdateInfos } = useMutation(
    () =>
      updateUserInfos({
        tel: newInfos.tel != null && newInfos.tel !== "" ? newInfos.tel : "",
        fax: newInfos.fax != null && newInfos.fax !== "" ? newInfos.fax : "",
        localisation:
          newInfos.localisation != null && newInfos.localisation !== ""
            ? newInfos.localisation
            : "",
        email: newInfos.email != null && newInfos.email !== "" ? newInfos.email : "",
        facebook: newInfos.facebook != null && newInfos.facebook !== "" ? newInfos.facebook : "",
        linkedin: newInfos.linkedin != null && newInfos.linkedin !== "" ? newInfos.linkedin : "",
        twitter: newInfos.twitter != null && newInfos.twitter !== "" ? newInfos.twitter : "",
        presentation:
          newInfos.presentation != null && newInfos.presentation !== ""
            ? newInfos.presentation
            : "",
        sitweb: newInfos.siteweb != null && newInfos.siteweb !== "" ? newInfos.siteweb : "",
        instagram:
          newInfos.instagram != null && newInfos.instagram !== "" ? newInfos.instagram : "",
        secteur: newInfos.secteur != null && newInfos.secteur !== "" ? newInfos.secteur : "",
      }),
    {
      async onSuccess(data) {
        console.log(data);
        if (data && data.success) {
          mutate();
        } else {
        }
      },
      onError(error) {},
    }
  );

  useEffect(() => {
    mutate();
  }, []);

  const resetForm = () => {
    setNewInfos(infos);
  };

  const changeInfos = () => {
    console.log(newInfos);
    var tel_regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    var email_regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // Numéro de téléphone local (ex. : 01-23-45-67-89)
    const regexLocal = /^\d{10}$/;

    // Numéro de téléphone étranger (ex. : +33 1 23 45 67 89)
    const regexEtranger = /^\+\d{12,14}$/;
    var valid = true;

    if (newInfos.email !== "" && !String(newInfos.email).toLowerCase().match(email_regex)) {
      notify(
        lang === "fr"
          ? "Entrer un email correct stp !"
          : lang === "en"
          ? "Please enter a correct email!"
          : "الرجاء إدخال بريد إلكتروني الصحيح!"
      );
      valid = false;
    }

    if (
      newInfos.tel !== "" &&
      !String(newInfos.tel).toLowerCase().match(regexLocal) &&
      !String(newInfos.tel).toLowerCase().match(regexEtranger)
    ) {
      notify(
        lang === "fr"
          ? "Entrer un numéro du téléphone correct stp !"
          : lang === "en"
          ? "Please enter a correct phone number !"
          : "الرجاء إدخال رقم هاتف الصحيح!"
      );
      valid = false;
    }

    if (
      newInfos.fax !== "" &&
      !String(newInfos.fax).toLowerCase().match(regexLocal) &&
      !String(newInfos.fax).toLowerCase().match(regexEtranger)
    ) {
      notify(
        lang === "fr"
          ? "Entrer un numéro du fax correct stp !"
          : lang === "en"
          ? "Please enter a correct fax number !"
          : "الرجاء إدخال رقم فاكس الصحيح!"
      );
      valid = false;
    }

    if (role === "vendeur" && newInfos.secteur === "") {
      notify(
        lang === "fr"
          ? "Entrer un secteur stp !"
          : lang === "en"
          ? "Please enter a sector !"
          : "الرجاء إدخال القطاع الخاص بك!"
      );
      valid = false;
    }

    if (valid) {
      mutateUpdateInfos();
    }
  };

  const notify = (message) => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 3000,
      fontSize: "12px",
    });
  };

  const notify_success = (message) => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 3000,
    });
  };

  const changeUserInfos = () => {
    console.log(userInfos);
    var email_regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (onEditEmail) {
      var valid = true;

      if (
        userInfos.new_email === "" ||
        !String(userInfos.new_email).toLowerCase().match(email_regex)
      ) {
        notify(
          lang === "fr"
            ? "Entrer un email correct stp !"
            : lang === "en"
            ? "Please enter a correct email!"
            : "الرجاء إدخال بريد إلكتروني الصحيح!"
        );
        valid = false;
      }
      if (userInfos.new_email !== userInfos.new_email_confirmation) {
        notify(
          lang === "fr"
            ? "Confirmer votre nouveau email stp !"
            : lang === "en"
            ? "Please confirm your new email!"
            : "يرجى تأكيد بريدك الإلكتروني الجديد!"
        );
        valid = false;
      }

      if (userInfos.password === "") {
        notify(
          lang === "fr"
            ? "Entrer votre mot de passe stp !"
            : lang === "en"
            ? "Please enter your password!"
            : "من فضلك أدخل كلمة المرور الخاصة بك!"
        );
        valid = false;
      }

      if (valid) {
        mutateUpdateEmail();
      }
    } else {
      var valid = true;

      if (userInfos.new_password === "") {
        notify(
          lang === "fr"
            ? "Entrer un nouveau mot de passe correct stp !"
            : lang === "en"
            ? "Please enter a new correct password!"
            : "الرجاء إدخال كلمة مرور جديدة صحيحة!"
        );
        valid = false;
      }
      if (userInfos.new_password !== userInfos.new_password_confirmation) {
        notify(
          lang === "fr"
            ? "Confirmer votre nouveau mot de passe stp !"
            : lang === "en"
            ? "Please confirm your new password!"
            : "يرجى تأكيد كلمة المرور الجديدة الخاصة بك!"
        );
        valid = false;
      }

      if (userInfos.password === "") {
        notify(
          lang === "fr"
            ? "Entrer votre mot de passe stp !"
            : lang === "en"
            ? "Please enter your password!"
            : "من فضلك أدخل كلمة المرور الخاصة بك!"
        );

        valid = false;
      }

      if (valid) {
        mutateUpdatePassword();
      }
    }
  };

  return (
    <>
      {/* <DashboardNavbar /> */}
      <MDBox mb={2} />
      <Header theme={theme} role={role}>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} xl={12} sx={{ display: "flex" }}>
              {/* 
              <ProfilesList title="Groupe" profiles={profilesListData} shadow={false} /> */}
              {/* <PlatformSettings /> */}
              {/* <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} /> */}
              <MDBox>
                <Grid container spacing={1} className="px-2">
                  <Grid item xs={12} md={6} xl={6}>
                    <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2}>
                      <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                        {lang === "fr"
                          ? "Admin Email"
                          : lang === "en"
                          ? "Admin Email"
                          : "البريد الإلكتروني الخاص بالمشرف"}
                      </MDTypography>
                    </MDBox>
                    <MDBox mb={2} lineHeight={1}>
                      <MDTypography variant="p" fontWeight="light" style={{ fontSize: "13px" }}>
                        {userInfos && userInfos.email ? userInfos.email : ""}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} xl={6}>
                    <MDBox p={2}>
                      <ButtonBase
                        sx={{
                          ...theme.typography.commonAvatar,
                          ...theme.typography.button,
                          transition: "all .2s ease-in-out",
                          background:
                            role === "acheteur"
                              ? theme.palette.secondary.light
                              : theme.palette.warning.light,
                          color:
                            role === "acheteur"
                              ? theme.palette.secondary.dark
                              : theme.palette.warning.dark,
                          "&:hover": {
                            background:
                              role === "acheteur"
                                ? theme.palette.secondary.dark
                                : theme.palette.warning.dark,
                            color:
                              role === "acheteur"
                                ? theme.palette.secondary.light
                                : theme.palette.warning.light,
                          },
                          height: "100%",
                          overflow: "hidden",
                        }}
                        className="py-2 px-5"
                        onClick={handleOpenEditEmail}
                      >
                        {lang === "fr"
                          ? "Changer l'email"
                          : lang === "en"
                          ? "Change email"
                          : "تغيير البريد الإلكتروني "}
                      </ButtonBase>
                    </MDBox>
                  </Grid>

                  <Grid item xs={12} md={6} xl={6}>
                    <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2}>
                      <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                        {lang === "fr"
                          ? "Mot de passe"
                          : lang === "en"
                          ? "Password"
                          : "كلمة المرور "}
                      </MDTypography>
                    </MDBox>

                    <MDBox mb={2} lineHeight={2}>
                      <MDTypography variant="p" fontWeight="light" style={{ fontSize: "13px" }}>
                        ************
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} xl={6}>
                    <MDBox p={2}>
                      <ButtonBase
                        sx={{
                          ...theme.typography.commonAvatar,
                          ...theme.typography.button,
                          transition: "all .2s ease-in-out",
                          background:
                            role === "acheteur"
                              ? theme.palette.secondary.light
                              : theme.palette.warning.light,
                          color:
                            role === "acheteur"
                              ? theme.palette.secondary.dark
                              : theme.palette.warning.dark,
                          "&:hover": {
                            background:
                              role === "acheteur"
                                ? theme.palette.secondary.dark
                                : theme.palette.warning.dark,
                            color:
                              role === "acheteur"
                                ? theme.palette.secondary.light
                                : theme.palette.warning.light,
                          },
                          height: "100%",
                          overflow: "hidden",
                        }}
                        className="py-2 px-5"
                        onClick={handleOpenEditPassword}
                      >
                        {lang === "fr"
                          ? "Changer Mot de passe"
                          : lang === "en"
                          ? "Change Password"
                          : "تغيير كلمة المرور "}
                      </ButtonBase>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {role === "admin" ? (
              <></>
            ) : (
              <>
                {role === "vendeur" ? (
                  <>
                    <Grid item xs={12} md={12} xl={12} sx={{ display: "flex" }}>
                      <Card sx={{ height: "100%", width: "100%", boxShadow: "none" }}>
                        <MDBox
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          pt={2}
                          px={2}
                        >
                          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                            {lang === "fr" ? "Secteur" : lang === "en" ? "Sector" : "القطاع"}
                          </MDTypography>
                        </MDBox>

                        <MDBox p={2}>
                          <div className="flex-auto">
                            <div className="relative w-full mb-3 mt-8">
                              <label
                                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                htmlFor="sector"
                              >
                                {lang === "fr" ? "Secteur" : lang === "en" ? "Sector" : "القطاع"}
                              </label>

                              <input
                                type="text"
                                aria-label="sector"
                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                placeholder={
                                  lang === "fr" ? "Secteur" : lang === "en" ? "Sector" : "القطاع"
                                }
                                value={newInfos && newInfos.secteur ? newInfos.secteur : ""}
                                onChange={(e) => {
                                  let new_value = e.currentTarget.value;
                                  setNewInfos((oldValue) => {
                                    return { ...oldValue, secteur: new_value };
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <MDBox opacity={0.3}>
                            <Divider />
                          </MDBox>
                        </MDBox>
                      </Card>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </>
                ) : (
                  <></>
                )}

                <Grid item xs={12} md={12} xl={12} sx={{ display: "flex" }}>
                  <Card sx={{ height: "100%", width: "100%", boxShadow: "none" }}>
                    <MDBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      pt={2}
                      px={2}
                    >
                      <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                        {lang === "fr" ? "Pays" : lang === "en" ? "Country" : "البلاد"}
                      </MDTypography>
                    </MDBox>

                    <MDBox p={2}>
                      <MDBox mb={2}>
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="full-name"
                          >
                            {lang === "fr" ? "Pays" : lang === "en" ? "Country" : "الدولة"}
                          </label>
                          <select
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            style={{
                              paddingLeft: "30px !important",
                              paddingRight: "30px !important",
                            }}
                            placeholder={
                              lang === "fr"
                                ? "Votre Pays"
                                : lang === "en"
                                ? "Your Country"
                                : "الدولة خاصتك"
                            }
                            value={country}
                            onChange={(e) => setCountry(e.currentTarget.value)}
                          >
                            {sortCountriesByLang(countries, lang).map((el) => {
                              return (
                                <option value={el.id}>
                                  {lang === "fr"
                                    ? el.french
                                    : lang === "en"
                                    ? el.english
                                    : el.arabic}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </MDBox>
                      <MDBox opacity={0.3}>
                        <Divider />
                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12} md={12} xl={12} sx={{ display: "flex" }}>
                  <Card sx={{ height: "100%", width: "100%", boxShadow: "none" }}>
                    <MDBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      pt={2}
                      px={2}
                    >
                      <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                        {lang === "fr" ? "Présentation" : lang === "en" ? "Presentation" : "عرض"}
                      </MDTypography>
                    </MDBox>
                    <MDBox p={2}>
                      <div className="flex-auto">
                        <div className="relative w-full mb-3 mt-8">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="full-name"
                          >
                            {lang === "fr"
                              ? "Biographie"
                              : lang === "en"
                              ? "Biography"
                              : "سيرة شخصية"}
                          </label>
                          <textarea
                            rows="18"
                            cols="80"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                            placeholder={
                              lang === "fr"
                                ? "Biographie"
                                : lang === "en"
                                ? "Biography"
                                : "سيرة شخصية"
                            }
                            value={newInfos && newInfos.presentation ? newInfos.presentation : ""}
                            onChange={(e) => {
                              let new_value = e.currentTarget.value;
                              setNewInfos((oldValue) => {
                                return { ...oldValue, presentation: new_value };
                              });
                            }}
                          />
                        </div>
                      </div>
                      {/* <MDBox mb={2} lineHeight={1}>
                    <MDInput
                      style={{ width: "100%", marginTop: "20px" }}
                      label="Description"
                      multiline
                      rows={20}
                      value={newInfos && newInfos.presentation ? newInfos.presentation : ""}
                      onChange={(e) => {
                        let new_value = e.currentTarget.value;
                        setNewInfos((oldValue) => {
                          return { ...oldValue, presentation: new_value };
                        });
                      }}
                    />
                  </MDBox> */}
                      <MDBox opacity={0.3}>
                        <Divider />
                      </MDBox>
                    </MDBox>
                  </Card>
                  {/* <Divider orientation="vertical" sx={{ mx: 0 }} /> */}
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12} md={12} xl={12}>
                  <Card sx={{ height: "100%", boxShadow: "none" }}>
                    <MDBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      pt={2}
                      px={2}
                    >
                      <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                        {lang === "fr" ? "Contact" : lang === "en" ? "Contact" : "الاتصال"}
                      </MDTypography>
                    </MDBox>
                    <MDBox p={2}>
                      <MDBox mb={2} lineHeight={1}>
                        <div className="w-100 row m-0">
                          <div className="col-12 col-md-6">
                            <div className="flex-auto">
                              <div className="relative w-full mb-3 mt-3">
                                <label
                                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                  htmlFor="full-name"
                                >
                                  Téléphone
                                </label>
                                <input
                                  type="text"
                                  aria-label="Téléphone"
                                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  placeholder="Téléphone"
                                  value={newInfos && newInfos.tel ? newInfos.tel : ""}
                                  onChange={(e) => {
                                    let new_value = e.currentTarget.value;
                                    setNewInfos((oldValue) => {
                                      return { ...oldValue, tel: new_value };
                                    });
                                  }}
                                />
                              </div>

                              <div className="relative w-full mb-3">
                                <label
                                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                  htmlFor="full-name"
                                >
                                  Fax
                                </label>
                                <input
                                  type="text"
                                  aria-label="Fax"
                                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  placeholder="Fax"
                                  value={newInfos && newInfos.fax ? newInfos.fax : ""}
                                  onChange={(e) => {
                                    let new_value = e.currentTarget.value;
                                    setNewInfos((oldValue) => {
                                      return { ...oldValue, fax: new_value };
                                    });
                                  }}
                                />
                              </div>

                              <div className="relative w-full mb-3">
                                <label
                                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                  htmlFor="full-name"
                                >
                                  Email
                                </label>
                                <input
                                  type="email"
                                  aria-label="Email"
                                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  placeholder="Email"
                                  value={newInfos && newInfos.email ? newInfos.email : ""}
                                  onChange={(e) => {
                                    let new_value = e.currentTarget.value;
                                    setNewInfos((oldValue) => {
                                      return { ...oldValue, email: new_value };
                                    });
                                  }}
                                />
                              </div>

                              <div className="relative w-full mb-3">
                                <label
                                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                  htmlFor="full-name"
                                >
                                  Localisation
                                </label>
                                <input
                                  type="text"
                                  aria-label="Localisation"
                                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  placeholder="Localisation"
                                  value={
                                    newInfos && newInfos.localisation ? newInfos.localisation : ""
                                  }
                                  onChange={(e) => {
                                    let new_value = e.currentTarget.value;
                                    setNewInfos((oldValue) => {
                                      return { ...oldValue, localisation: new_value };
                                    });
                                  }}
                                />
                              </div>

                              <div className="relative w-full mb-3">
                                <label
                                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                  htmlFor="full-name"
                                >
                                  Siteweb
                                </label>
                                <input
                                  type="text"
                                  aria-label="Siteweb"
                                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  placeholder="Siteweb"
                                  value={newInfos && newInfos.siteweb ? newInfos.siteweb : ""}
                                  onChange={(e) => {
                                    let new_value = e.currentTarget.value;
                                    setNewInfos((oldValue) => {
                                      return { ...oldValue, siteweb: new_value };
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="relative w-full mb-3 mt-3">
                              <label
                                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                htmlFor="full-name"
                              >
                                linkedIn
                              </label>
                              <input
                                type="text"
                                aria-label="linkedIn"
                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                placeholder="linkedIn"
                                value={newInfos && newInfos.linkedin ? newInfos.linkedin : ""}
                                onChange={(e) => {
                                  let new_value = e.currentTarget.value;
                                  setNewInfos((oldValue) => {
                                    return { ...oldValue, linkedin: new_value };
                                  });
                                }}
                              />
                            </div>

                            <div className="relative w-full mb-3">
                              <label
                                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                htmlFor="full-name"
                              >
                                Twitter
                              </label>
                              <input
                                type="text"
                                aria-label="Twitter"
                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                placeholder="Twitter"
                                value={newInfos && newInfos.twitter ? newInfos.twitter : ""}
                                onChange={(e) => {
                                  let new_value = e.currentTarget.value;
                                  setNewInfos((oldValue) => {
                                    return { ...oldValue, twitter: new_value };
                                  });
                                }}
                              />
                            </div>

                            <div className="relative w-full mb-3">
                              <label
                                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                htmlFor="full-name"
                              >
                                Instagram
                              </label>
                              <input
                                type="text"
                                aria-label="Instagram"
                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                placeholder="Instagram"
                                value={newInfos && newInfos.instagram ? newInfos.instagram : ""}
                                onChange={(e) => {
                                  let new_value = e.currentTarget.value;
                                  setNewInfos((oldValue) => {
                                    return { ...oldValue, instagram: new_value };
                                  });
                                }}
                              />
                            </div>

                            <div className="relative w-full mb-3">
                              <label
                                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                htmlFor="full-name"
                              >
                                Facebook
                              </label>
                              <input
                                type="text"
                                aria-label="Facebook"
                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                placeholder="Facebook"
                                value={newInfos && newInfos.facebook ? newInfos.facebook : ""}
                                onChange={(e) => {
                                  let new_value = e.currentTarget.value;
                                  setNewInfos((oldValue) => {
                                    return { ...oldValue, facebook: new_value };
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        {/* <MDInput
                      style={{ width: "100%", marginTop: "20px" }}
                      label="Téléphone"
                      value={newInfos && newInfos.tel ? newInfos.tel : ""}
                      onChange={(e) => {
                        let new_value = e.currentTarget.value;
                        setNewInfos((oldValue) => {
                          return { ...oldValue, tel: new_value };
                        });
                      }}
                    /> */}
                        {/* <MDInput
                      style={{ width: "100%", marginTop: "20px" }}
                      label="Fax"
                      value={newInfos && newInfos.fax ? newInfos.fax : ""}
                      onChange={(e) => {
                        let new_value = e.currentTarget.value;
                        setNewInfos((oldValue) => {
                          return { ...oldValue, fax: new_value };
                        });
                      }}
                    /> */}
                        {/* <MDInput
                      style={{ width: "100%", marginTop: "20px" }}
                      label="Email"
                      value={newInfos && newInfos.email ? newInfos.email : ""}
                      onChange={(e) => {
                        let new_value = e.currentTarget.value;
                        setNewInfos((oldValue) => {
                          return { ...oldValue, email: new_value };
                        });
                      }}
                    /> */}
                        {/* <MDInput
                      style={{ width: "100%", marginTop: "20px" }}
                      label="Localisation"
                      value={newInfos && newInfos.localisation ? newInfos.localisation : ""}
                      onChange={(e) => {
                        let new_value = e.currentTarget.value;
                        setNewInfos((oldValue) => {
                          return { ...oldValue, localisation: new_value };
                        });
                      }}
                    /> */}
                        {/* <MDInput
                      style={{ width: "100%", marginTop: "20px" }}
                      label="Siteweb"
                      value={newInfos && newInfos.siteweb ? newInfos.siteweb : ""}
                      onChange={(e) => {
                        let new_value = e.currentTarget.value;
                        setNewInfos((oldValue) => {
                          return { ...oldValue, siteweb: new_value };
                        });
                      }}
                    /> */}
                        {/* <MDInput
                      style={{ width: "100%", marginTop: "20px" }}
                      label="linkedIn"
                      value={newInfos && newInfos.linkedin ? newInfos.linkedin : ""}
                      onChange={(e) => {
                        let new_value = e.currentTarget.value;
                        setNewInfos((oldValue) => {
                          return { ...oldValue, linkedin: new_value };
                        });
                      }}
                    /> */}
                        {/* <MDInput
                      style={{ width: "100%", marginTop: "20px" }}
                      label="Twitter"
                      value={newInfos && newInfos.twitter ? newInfos.twitter : ""}
                      onChange={(e) => {
                        let new_value = e.currentTarget.value;
                        setNewInfos((oldValue) => {
                          return { ...oldValue, twitter: new_value };
                        });
                      }}
                    /> */}
                        {/* <MDInput
                      style={{ width: "100%", marginTop: "20px" }}
                      label="Instagram"
                      value={newInfos && newInfos.instagram ? newInfos.instagram : ""}
                      onChange={(e) => {
                        let new_value = e.currentTarget.value;
                        setNewInfos((oldValue) => {
                          return { ...oldValue, instagram: new_value };
                        });
                      }}
                    /> */}
                        {/* <MDInput
                      style={{ width: "100%", marginTop: "20px" }}
                      label="facebook"
                      value={newInfos && newInfos.facebook ? newInfos.facebook : ""}
                      onChange={(e) => {
                        let new_value = e.currentTarget.value;
                        setNewInfos((oldValue) => {
                          return { ...oldValue, facebook: new_value };
                        });
                      }}
                    /> */}
                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid>
                <Grid item xs={12} style={{ display: "flex", justifyContent: "flex-end" }}>
                  <ButtonBase
                    sx={{
                      ...theme.typography.commonAvatar,
                      ...theme.typography.button,
                      transition: "all .2s ease-in-out",
                      background: theme.palette.info.light,
                      color: theme.palette.info.dark,
                      "&:hover": {
                        background: theme.palette.info.dark,
                        color: theme.palette.info.light,
                      },
                      height: "100%",
                      overflow: "hidden",
                    }}
                    className="py-2 px-5"
                    onClick={resetForm}
                  >
                    {lang === "fr" ? "Réinitialiser" : lang === "en" ? "Reset" : "إعادة"}
                  </ButtonBase>

                  <ButtonBase
                    sx={{
                      ...theme.typography.commonAvatar,
                      ...theme.typography.button,
                      transition: "all .2s ease-in-out",
                      background:
                        role === "acheteur"
                          ? theme.palette.secondary.light
                          : theme.palette.warning.light,
                      color:
                        role === "acheteur"
                          ? theme.palette.secondary.dark
                          : theme.palette.warning.dark,
                      "&:hover": {
                        background:
                          role === "acheteur"
                            ? theme.palette.secondary.dark
                            : theme.palette.warning.dark,
                        color:
                          role === "acheteur"
                            ? theme.palette.secondary.light
                            : theme.palette.warning.light,
                      },
                      height: "100%",
                      overflow: "hidden",
                    }}
                    className="py-2 px-5"
                    onClick={changeInfos}
                  >
                    {lang === "fr" ? "Confirmer" : lang === "en" ? "Confirm" : "تأكيد"}
                  </ButtonBase>
                </Grid>
              </>
            )}
          </Grid>
        </MDBox>
      </Header>

      <Modal
        show={isLoading || isLoadingUpdateEmail || isLoadingUpdatePassword || isLoadingUpdateInfos}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="myModalLoading"
      >
        <Modal.Body style={{ overflow: "hidden" }}>
          <Box sx={{ width: "100%" }}>
            <LinearProgress color="secondary" />
          </Box>
        </Modal.Body>
      </Modal>

      <Modal
        show={openAdd}
        onHide={handleCloseAdd}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: "0.85em" }}>
            {onEditEmail !== null
              ? "" +
                (lang === "fr"
                  ? "Modifier votre email"
                  : lang === "en"
                  ? "Edit your email"
                  : "تغيير البريد الإلكتروني الخاص بك")
              : "" +
                (lang === "fr"
                  ? "Modifier votre mote de passe"
                  : lang === "en"
                  ? "Change your password"
                  : "تغيير كلمة المرور الخاصة بك")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CardPub style={{ width: "100%" }}>
              {onEditEmail && (
                <>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="full-name"
                    >
                      {lang === "fr"
                        ? "Nouveau email"
                        : lang === "en"
                        ? "new email"
                        : "البريد الإلكتروني الجديد"}
                    </label>
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder={
                        lang === "fr"
                          ? "Nouveau email"
                          : lang === "en"
                          ? "new email"
                          : "البريد الإلكتروني الجديد"
                      }
                      value={userInfos && userInfos.new_email ? userInfos.new_email : ""}
                      onChange={(e) => {
                        let new_value = e.currentTarget.value;
                        setUserInfos((oldValue) => {
                          return { ...oldValue, new_email: new_value };
                        });
                      }}
                    />
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="full-name"
                    >
                      {lang === "fr"
                        ? "Nouveau email (confirmation)"
                        : lang === "en"
                        ? "new email (confirmation)"
                        : "البريد الإلكتروني الجديد (تأكيد)"}
                    </label>
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder={
                        lang === "fr"
                          ? "Nouveau email (confirmation)"
                          : lang === "en"
                          ? "new email (confirmation)"
                          : "البريد الإلكتروني الجديد (تأكيد)"
                      }
                      value={
                        userInfos && userInfos.new_email_confirmation
                          ? userInfos.new_email_confirmation
                          : ""
                      }
                      onChange={(e) => {
                        let new_value = e.currentTarget.value;
                        setUserInfos((oldValue) => {
                          return { ...oldValue, new_email_confirmation: new_value };
                        });
                      }}
                    />
                  </div>
                  {/* <MDInput
                    style={{ width: "100%", marginTop: "20px" }}
                    label="Nouveau email"
                    value={userInfos && userInfos.new_email ? userInfos.new_email : ""}
                    onChange={(e) => {
                      let new_value = e.currentTarget.value;
                      setUserInfos((oldValue) => {
                        return { ...oldValue, new_email: new_value };
                      });
                    }}
                  /> */}
                  {/* <MDInput
                    style={{ width: "100%", marginTop: "20px" }}
                    label="Nouveau email (confirmation)"
                    value={
                      userInfos && userInfos.new_email_confirmation
                        ? userInfos.new_email_confirmation
                        : ""
                    }
                    onChange={(e) => {
                      let new_value = e.currentTarget.value;
                      setUserInfos((oldValue) => {
                        return { ...oldValue, new_email_confirmation: new_value };
                      });
                    }}
                  /> */}
                </>
              )}

              {!onEditEmail && (
                <>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="full-name"
                    >
                      {lang === "fr"
                        ? "Nouveau Mot de passe"
                        : lang === "en"
                        ? "New Password"
                        : "كلمة المرور الجديدة"}
                    </label>
                    <input
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder={
                        lang === "fr"
                          ? "Nouveau Mot de passe"
                          : lang === "en"
                          ? "New Password"
                          : "كلمة المرور الجديدة"
                      }
                      value={userInfos && userInfos.new_password ? userInfos.new_password : ""}
                      type={showPassword ? "text" : "password"}
                      onChange={(e) => {
                        let new_value = e.currentTarget.value;
                        setUserInfos((oldValue) => {
                          return { ...oldValue, new_password: new_value };
                        });
                      }}
                    />
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="full-name"
                    >
                      {lang === "fr"
                        ? "Nouveau Mot de passe (confirmation)"
                        : lang === "en"
                        ? "New Password (confirmation)"
                        : " (تأكيد) كلمة المرور الجديدة"}
                    </label>
                    <input
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder={
                        lang === "fr"
                          ? "Nouveau Mot de passe (confirmation)"
                          : lang === "en"
                          ? "New Password (confirmation)"
                          : " (تأكيد) كلمة المرور الجديدة"
                      }
                      value={
                        userInfos && userInfos.new_password_confirmation
                          ? userInfos.new_password_confirmation
                          : ""
                      }
                      type={showPassword ? "text" : "password"}
                      onChange={(e) => {
                        let new_value = e.currentTarget.value;
                        setUserInfos((oldValue) => {
                          return { ...oldValue, new_password_confirmation: new_value };
                        });
                      }}
                    />
                  </div>
                  {/* <MDInput
                    style={{ width: "100%", marginTop: "20px" }}
                    label="Nouveau Mot de passe"
                    value={userInfos && userInfos.new_password ? userInfos.new_password : ""}
                    type={showPassword ? "text" : "password"}
                    onChange={(e) => {
                      let new_value = e.currentTarget.value;
                      setUserInfos((oldValue) => {
                        return { ...oldValue, new_password: new_value };
                      });
                    }}
                  /> */}
                  {/* <MDInput
                    style={{ width: "100%", marginTop: "20px" }}
                    label="Nouveau  Mot de passe (confirmation)"
                    value={
                      userInfos && userInfos.new_password_confirmation
                        ? userInfos.new_password_confirmation
                        : ""
                    }
                    type={showPassword ? "text" : "password"}
                    onChange={(e) => {
                      let new_value = e.currentTarget.value;
                      setUserInfos((oldValue) => {
                        return { ...oldValue, new_password_confirmation: new_value };
                      });
                    }}
                  /> */}
                </>
              )}

              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="full-name"
                >
                  {lang === "fr" ? "Mot de passe" : lang === "en" ? "Password" : " كلمة المرور "}
                </label>
                <input
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder={
                    lang === "fr" ? "Mot de passe" : lang === "en" ? "Password" : " كلمة المرور "
                  }
                  value={userInfos && userInfos.password ? userInfos.password : ""}
                  type={showPassword ? "text" : "password"}
                  onChange={(e) => {
                    let new_value = e.currentTarget.value;
                    setUserInfos((oldValue) => {
                      return { ...oldValue, password: new_value };
                    });
                  }}
                />
              </div>
              {/* <MDInput
                style={{ width: "100%", marginTop: "20px" }}
                label="Mot de passe"
                value={userInfos && userInfos.password ? userInfos.password : ""}
                type={showPassword ? "text" : "password"}
                onChange={(e) => {
                  let new_value = e.currentTarget.value;
                  setUserInfos((oldValue) => {
                    return { ...oldValue, password: new_value };
                  });
                }}
              /> */}

              <MDBox display="flex" alignItems="center" ml={-1}>
                <Switch
                  checked={showPassword}
                  onChange={() => {
                    setShowPassword((value) => !value);
                  }}
                />
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  onClick={() => {
                    setShowPassword((value) => !value);
                  }}
                  sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                >
                  &nbsp;&nbsp;
                  {lang === "fr"
                    ? "Afficher les Mots de passe"
                    : lang === "en"
                    ? "Show Passwords"
                    : "  إظهار كلمات المرور "}
                </MDTypography>
              </MDBox>
            </CardPub>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button onClick={handleCloseAdd}>Confirmer</Button> */}
          {/* <MDButton color="info" onClick={changeUserInfos}>
            Confirmer
          </MDButton> */}

          <ButtonBase
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.button,
              transition: "all .2s ease-in-out",
              background:
                role === "acheteur" ? theme.palette.secondary.light : theme.palette.warning.light,
              color:
                role === "acheteur" ? theme.palette.secondary.dark : theme.palette.warning.dark,
              "&:hover": {
                background:
                  role === "acheteur" ? theme.palette.secondary.dark : theme.palette.warning.dark,
                color:
                  role === "acheteur" ? theme.palette.secondary.light : theme.palette.warning.light,
              },
              height: "100%",
              overflow: "hidden",
            }}
            className="py-2 px-5"
            onClick={changeUserInfos}
          >
            {lang === "fr" ? "Confirmer" : lang === "en" ? "Confirm" : "تأكيد"}
          </ButtonBase>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Overview;
