import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-in/SignUp";
import ForgetPassword from "layouts/authentication/sign-in/ForgetPassword";
import ResetPassword from "layouts/authentication/sign-in/ResetPassword";
import AccountValidation from "layouts/authentication/sign-in/AccountValidation";
import BloquedAccount from "layouts/authentication/sign-in/BloquedAccount";
import Politique from "layouts/authentication/sign-in/Politique";
// import SignUp from "layouts/authentication/sign-up";

import { Icon } from "@mui/material";

const AuthRoutes = [
  {
    type: "collapse",
    name: "Sign In",
    key: "authentication",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
    role: "authentication",
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
    role: "authentication",
  },
  {
    type: "collapse",
    name: "Forget Password",
    key: "forget-password",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/forget-password",
    component: <ForgetPassword />,
    role: "authentication",
  },
  {
    type: "collapse",
    name: "Reset Password",
    key: "reset-password",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/reset-password/:token",
    component: <ResetPassword />,
    role: "authentication",
  },
  {
    type: "collapse",
    name: "Account Validation",
    key: "account-validation",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/account-validation/:token",
    component: <AccountValidation />,
    role: "authentication",
  },
  {
    type: "collapse",
    name: "Bloqued Account",
    key: "bloqued-account",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/bloqued_account",
    component: <BloquedAccount />,
    role: "authentication",
  },
  {
    type: "collapse",
    name: "Politique",
    key: "politique",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/politique",
    component: <Politique />,
    role: "authentication",
  },
];

export default AuthRoutes;
