import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";
import ContactDetails from "./ContactDetails";
import logo from "assets/images/Portail_logo.png";
import logo2 from "assets/images/Smart_system_logo.png";
import { Divider } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { getInfos } from "services/visiteurService";
import { Modal } from "react-bootstrap";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { toast } from "react-toastify";
import { contactForm } from "services/visiteurService";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
const Textarea = styled(Input).attrs({as: "textarea"})`
  ${tw`h-24`}
`

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`

export default ({
  subheading = "Nous Contacter ",
  heading = <>N'hésitez pas à <span tw="text-primary-500">nous contacter.</span><wbr/></>,
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  submitButtonText = "Send",
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  const [infos, setInfos] = useState({
    tel : "" , 
    fax : "" , 
    localisation : "" , 
    email : "" , 
    facebook : "" ,
    linkedin : "" ,
    twitter : "" , 
    presentation : ""
  }) ; 


  const [Email, setEmail] = useState("") ; 
  const [Subject, setSubject] = useState("") ; 
  const [Message, setMessage] = useState("") ; 
  const [Name, setName] = useState("") ; 

  const { mutate, isLoading } = useMutation(
    () => getInfos(1),
    {
      async onSuccess(data) {
        console.log(data);
        if(data && data.data) {
          setInfos(
            {
              tel : data.data.tel != null &&  data.data.tel !== "" ?  data.data.tel : "", 
              fax : data.data.fax != null &&  data.data.fax !== "" ?  data.data.fax : "",  
              localisation : data.data.localisation != null &&  data.data.localisation !== "" ?  data.data.localisation : "",  
              email : data.data.email != null &&  data.data.email !== "" ?  data.data.email : "",  
              facebook : data.data.facebook != null &&  data.data.facebook !== "" ?  data.data.facebook : "", 
              linkedin : data.data.linkedin != null &&  data.data.linkedin !== "" ?  data.data.linkedin : "", 
              twitter : data.data.twitter != null &&  data.data.twitter !== "" ?  data.data.twitter : "",  
              presentation : data.data.presentation != null &&  data.data.presentation !== "" ?  data.data.presentation : "", 
              siteweb : data.data.sitweb != null &&  data.data.sitweb !== "" ?  data.data.sitweb : "", 
            }
            ) ; 
        }else { 
          
        }
      },
      onError(error) {
        
      },
    }
  );

  

  const { mutate : mutateSendEmail , isLoading : isLoadingSendEmail} = useMutation(
    (formInfos) => contactForm(formInfos),
    {
      async onSuccess(data) {
        console.log(data);
        if(data && data.result) {
          notify_success("Votre message a été envoyé avec succès") ;
          setEmail("") ;
          setName("") ;
          setSubject("") ;
          setMessage("") ;
        }else { 
          notify("Une erreur est survenue!!") ;
          
        }
      },
      onError(error) {
        
      },
    }
  );

  useEffect(()=> {
    mutate() ; 
  } , []) ;

  
  const send = () => {
    var form = {
      name : Name , 
      email : Email ,
      subject : Subject ,
      message : Message
    } ; 
    console.log(form) ;

    if(!Email || Email === "" || !String(Email).toLowerCase().match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )) {
      notify("Enter your Email please !") ;
    }
    else if(!Name || Name === "") {
      notify("Enter your Name please !") ;
    }
    else if(!Subject || Subject === "") {
      notify("Enter your Subject please !") ;
    }
    else if(!Message || Message === "") {
      notify("Enter your Message please !") ;
    }
    
    else {
      notify_success("valid form") ;
      mutateSendEmail(form) ;
      // mutate(user) ; 
      // navigate("/admin");

    }
  }
  

  const notify = (message) => {
    
    toast.error(message, {
      position: "bottom-right",
      autoClose: 3000 , 
      fontSize : "12px" 
      });
  }

  const notify_success = (message) => {
    
    toast.success(message, {
      position: "bottom-right",
      autoClose: 3000
      });
  }
  return (
    <Container>
    <TwoColumn>
      <ImageColumn>
        <Image imageSrc={logo} />
        
      </ImageColumn>
      <TextColumn textOnLeft={textOnLeft}>
        <TextContent>
          <Subheading>Plateforme Owner </Subheading>
          <Heading>Le Portail Economique Algérien</Heading>
          { infos && infos.presentation && infos.presentation !== "" &&
            <Description  className="m-2 p-1" > {infos.presentation ? 
              infos.presentation.split("\n").map((el) => {
                return <div>
                        {el} 
                          <br/>
                        </div> ; 
              }) 
              : ""
              }</Description>
          }
          { infos && infos.tel && infos.tel !== "" &&
            <Description  className="m-2 p-1" >TEL : {infos.tel}</Description>
          }
          { infos && infos.fax && infos.fax !== "" &&
            <Description  className="m-2 p-1" >Fax : {infos.fax}</Description>
          }
          { infos && infos.email && infos.email !== "" &&
            <Description  className="m-2 p-1" >Email : {infos.email}</Description>
          }
          { infos && infos.facebook && infos.facebook !== "" &&
            <Description  className="m-2 p-1" >Facebook : {infos.facebook}</Description>
          }
          { infos && infos.twitter && infos.twitter !== "" &&
            <Description  className="m-2 p-1" >Twitter : {infos.twitter}</Description>
          }
          { infos && infos.instagram && infos.instagram !== "" &&
            <Description  className="m-2 p-1" >Instagram : {infos.instagram}</Description>
          }
          { infos && infos.siteweb && infos.siteweb !== "" &&
            <Description  className="m-2 p-1" >Siteweb : {infos.siteweb}</Description>
          }
          { infos && infos.localisation && infos.localisation !== "" &&
            <Description  className="m-2 p-1" > {infos.localisation}</Description>
          }
          
        </TextContent>
      </TextColumn>
    </TwoColumn>
    <Divider></Divider>
    <TwoColumn>
      <TextColumn>
        <TextContent>
          <Subheading>Plateforme Production </Subheading>
          <Heading>Smart System & Solutions</Heading>
          <Description>Conseil , Consulting and Smart City System & Solutions </Description>
          
          <Description  className="m-2 p-1" >WhatsApp : 00336 99 51 01 66 </Description>
          
          <Description  className="m-2 p-1" >TEL : 026 42 02 84/ 0541 19 32 98</Description>
          
          <Description  className="m-2 p-1" >Email : contact.lpeadz@yahoo.com</Description>

          <Description  className="m-2 p-1" >Algérie</Description>
        </TextContent>
      </TextColumn>
      <ImageColumn>
        <Image imageSrc={logo2} />
        
      </ImageColumn>
    </TwoColumn>
      <Divider></Divider>
      <TwoColumn>
        <ContactDetails infos={infos}/>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {/* {description && <Description>{description}</Description>} */}
            <Form>
              <Input 
                type="email" 
                name="email" 
                value={Email}
                placeholder="Your Email Address" 
                style={{border : "none" , paddingLeft : "10px" , paddingRight : "10px" , borderRadius : "20px" , overflow : "hidden"}}
                onChange={e => setEmail(e.currentTarget.value)}
                />
              <Input 
                type="text" 
                name="name" 
                value={Name}
                placeholder="Full Name" 
                style={{border : "none" , paddingLeft : "10px" , paddingRight : "10px" , borderRadius : "20px" , overflow : "hidden"}} 
                onChange={e => setName(e.currentTarget.value)}
                />
              <Input 
                type="text" 
                name="subject" 
                value={Subject}
                placeholder="Subject" 
                style={{border : "none" , paddingLeft : "10px" , paddingRight : "10px" , borderRadius : "20px" , overflow : "hidden"}} 
                onChange={e => setSubject(e.currentTarget.value)}
                />
              <Textarea 
                name="message" 
                value={Message}
                placeholder="Your Message Here" 
                style={{border : "none" , paddingLeft : "10px" , paddingRight : "10px" , borderRadius : "20px" , overflow : "hidden"}} 
                onChange={e => setMessage(e.currentTarget.value)}
                />
              <SubmitButton type="button" style={{border : "none"}} onClick={send}>{submitButtonText}</SubmitButton>
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
      


        <Modal
        show={isLoading || isLoadingSendEmail}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <MDBox
            mx={2}
            my={1}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
            {isLoadingSendEmail ? "Sending..." : "Loading..."}
            </MDTypography>
          </MDBox>
        </Modal.Body>
      </Modal>
    </Container>
  );
};
