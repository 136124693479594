import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import { useMutation } from "@tanstack/react-query";
import { BASE_URL_Image } from "services/server";
import { getPublireportages } from "services/pubsService";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Border from "assets/images/publireportage_border.png";
import BorderTop from "assets/images/pub_border_top.png";
import BorderBottom from "assets/images/pub_border_bottom.png";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const Heading = styled.h4`
  ${tw`text-3xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative text-primary-500 px-4 -mx-4 py-2`}
  &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`}
  }
`;

const CardSlider = styled(Slider)`
  ${tw`mt-8`}
  .slick-track {
    ${tw`flex`}
  }

  .slick-slide {
    ${tw`h-auto flex justify-center mb-1`}
    div:first-child {
      width: 250px !important ;
      position: relative;
      border: none !important ;
    }
  }
  padding: 0;

  padding: 20px 0px;
  max-width: 310px;
  margin: auto;
  margin-top: 20px;
  border-radius: 20px;
`;
const Card = tw.div`h-full flex! flex-col sm:border max-w-sm  relative focus:outline-none bg-white m-0`;
const CardImage = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full h-56 sm:h-64 bg-cover bg-center `,
]);

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-2xl font-bold`;

const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4`;

const PrimaryButton = tw(
  PrimaryButtonBase
)`mt-auto sm:text-lg rounded-none w-full  py-3 sm:py-6 border-none`;
export default ({ items }) => {
  const lang = useSelector((state) => state.customization.lang);
  const navigate = useNavigate();
  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [sliderRef, setSliderRef] = useState(null);
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (sliderRef) {
        sliderRef?.slickNext();
      }
    }, 2000);
    return () => {
      clearInterval(intervalId);
    };
  }, [sliderRef]);
  const sliderSettings = {
    arrows: false,
    slidesToShow: 1,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 1,
        },
      },

      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  console.log("items");
  console.log(items);

  /* Change this according to your needs */

  return (
    <Container>
      {items && items.length && (
        <Content style={{ padding: 0 }}>
          <h1
            className="text-white font-semibold text-5xl"
            style={{ fontSize: "clamp(18px, 2vw, 30px)", textAlign: "center" }}
          >
            {lang === "fr" ? "ACTUALITÉS" : lang === "en" ? "NEWS" : "الأخبار"}
          </h1>

          {/* <div>
            <img
              alt=""
              src={BorderTop}
              style={{
                width: "350px",
                height: "auto",
                margin: "auto",
                marginBottom: "-57px",
                transform: "rotate(180deg) rotateX(30deg)",
                position: "relative",
                zIndex: 5,
              }}
            />
          </div> */}
          <CardSlider ref={setSliderRef} {...sliderSettings}>
            {items.map((card, index) => (
              <Card
                key={card.id}
                style={{
                  maxWidth: "250px",
                  height: "380px",
                  marginTop: "0",
                  border: "none",
                }}
                onClick={() => {
                  window.open(card.link);
                }}
              >
                <CardImage
                  imageSrc={card.imageSrc}
                  style={{ height: "250px", marginTop: "0", cursor: "pointer" }}
                />
              </Card>
            ))}
          </CardSlider>
          {/* <div>
            <img
              alt=""
              src={BorderBottom}
              style={{
                width: "350px",
                height: "auto",
                margin: "auto",
                marginTop: "-41px",
                transform: "rotateX(30deg)",
              }}
            />
          </div> */}
        </Content>
      )}
    </Container>
  );
};
