import { useEffect, useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import React from "react";
import { Alert, Container } from "@mui/material";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { signin } from "services/authService";
import {
  AppBar,
  Box,
  Button,
  ButtonBase,
  Checkbox,
  Divider,
  FormControlLabel,
  Icon,
  LinearProgress,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import LogoIcon from "assets/images/logo_targets_zone_dark2.png";
import IllutrationLogin from "assets/images/login-illustration.png";
import { SocialIcon } from "react-social-icons";
import { ArrowBack, CheckBox, ShoppingCart, Storefront } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { demandeInscription } from "services/visiteurService";
import AuthLangues from "./AuthLangues";
import { GoogleLogin } from "@react-oauth/google";
import { OauthInscription } from "services/authService";
import { Modal } from "react-bootstrap";
import { countries } from "services/countriesService";
// Images

function SignUp() {
  const theme = useTheme();
  const lang = useSelector((state) => state.customization.lang);
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [acceptPolicy, setAcceptPolicy] = useState(false);
  const handleSetTabValue = (event, newValue) => setTabValue(newValue);
  const { mutate, isLoading } = useMutation((userData) => demandeInscription(userData), {
    async onSuccess(data) {
      console.log(data);

      // if (data.result) {
      //   notify_success("valid");
      //   localStorage.setItem("token", data.token);
      //   localStorage.setItem("email", data.email);
      //   localStorage.setItem("name", data.name);
      //   localStorage.setItem("id", data.id);
      //   localStorage.setItem("role", data.role);
      //   localStorage.setItem("acheteur_id", data.acheteur_id);
      //   localStorage.setItem("entreprise_id", data.entreprise_id);
      //   localStorage.setItem("activated", data.activated);
      //   localStorage.setItem("profile_pic", data.profile_pic);
      //   if (data.role === "admin") {
      //     navigate("/admin");
      //   } else if (data.role === "acheteur") {
      //     navigate("/acheteur");
      //   } else if (data.role === "entreprise") {
      //     navigate("/entreprise");
      //   }
      // }
      if (data.success) {
        navigate("/authentication/account-validation");
      } else {
        notify(data.message != "" ? data.message : "error");
      }
    },
    onError(error) {
      notify("error");
      if (Array.isArray(error.response.data.error)) {
        error.response.data.error.forEach((el) => console.log(el.message));
      } else {
        console.log(error.response.data.message);
      }
    },
  });

  const loginOnSuccess = (data) => {
    console.log(data);

    if (data.message === "Valider votre compte") {
      navigate("/authentication/account-validation/" + data.data);
    }
    if (data.message === "Votre compte est bloqué!") {
      navigate("/authentication/bloqued_account/");
    } else if (data.result) {
      localStorage.setItem("token", data.token);
      localStorage.setItem("email", data.email);
      localStorage.setItem("name", data.name);
      localStorage.setItem("id", data.id);
      localStorage.setItem("role", data.role);
      localStorage.setItem("groupe_id", data.groupe_id);
      localStorage.setItem("entreprise_id", data.entreprise_id);
      localStorage.setItem("acheteur_id", data.acheteur_id);
      localStorage.setItem("activated", data.activated);
      localStorage.setItem("profile_pic", data.profile_pic);
      if (data.role === "admin") {
        navigate("/admin");
      } else if (data.role === "groupe") {
        navigate("/groupe");
      } else if (data.role === "entreprise") {
        navigate("/entreprise");
      } else if (data.role === "acheteur") {
        navigate("/acheteur");
      } else if (data.role === "pub") {
        navigate("/pub");
      }
    } else {
      notify(data.message);
    }
  };

  const { mutate: mutateOauth, isLoading: isLoadingOauth } = useMutation(
    (userData) => OauthInscription(userData),
    {
      async onSuccess(data) {
        loginOnSuccess(data);
      },
      onError(error) {
        notify("error");
        if (Array.isArray(error.response.data.error)) {
          error.response.data.error.forEach((el) => console.log(el.message));
        } else {
          console.log(error.response.data.message);
        }
      },
    }
  );
  const [rememberMe, setRememberMe] = useState(false);
  const [nom, setNom] = useState("");
  const [secteur, setSecteur] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");

  const [country, setCountry] = useState("");
  function getLangKey(lang) {
    const langMap = {
      fr: "french",
      en: "english",
      ar: "arabic",
    };
    return langMap[lang] || "english"; // Default to 'english' if lang is invalid
  }

  // Function to sort the array based on the selected language
  function sortCountriesByLang(countries, lang) {
    // Get the correct language key based on lang
    const langKey = getLangKey(lang);

    // Sorting the array based on the specified language
    return countries.sort((a, b) => {
      if (a[langKey] < b[langKey]) return -1;
      if (a[langKey] > b[langKey]) return 1;
      return 0;
    });
  }

  const navigate = useNavigate();
  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const Login = () => {
    var user = {
      email,
      password,
      password2,
      type: tabValue === 0 ? "acheteur" : "entreprise",
      nom,
      lang,
      accepted_policy: acceptPolicy ? 1 : 0,
      secteur: tabValue === 0 ? null : secteur,
      country: country,
    };
    console.log(user);

    var valid = true;

    if (
      !email ||
      email === "" ||
      !String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      notify(
        lang === "fr"
          ? "Entrez votre Email s'il vous plaît !"
          : lang === "en"
          ? "Enter your Email please!"
          : "أدخل بريدك الإلكتروني من فضلك!"
      );
      valid = false;
    } else if (!nom || nom === "") {
      notify(
        lang === "fr"
          ? "Entrez votre dénomination s'il vous plaît !"
          : lang === "en"
          ? "Enter your denomination please!"
          : "أدخل التسمية خاصتك من فضلك!"
      );
      valid = false;
    } else if (tabValue === 1 && (!secteur || secteur === "")) {
      notify(
        lang === "fr"
          ? "Entrez votre secteur s'il vous plaît !"
          : lang === "en"
          ? "Please enter your sector!"
          : "الرجاء إدخال القطاع الخاص بك!"
      );
      valid = false;
    } else if (!country) {
      notify(
        lang === "fr"
          ? "Entrez votre pays s'il vous plaît !"
          : lang === "en"
          ? "Please enter your country!"
          : "الرجاء إدخال بلادك!"
      );
      valid = false;
    } else if (!password || password === "") {
      notify(
        lang === "fr"
          ? "Entrez votre mot de passe s'il vous plaît !"
          : lang === "en"
          ? "Enter your password please !"
          : "أدخل كلمة المرور الخاصة بك من فضلك!"
      );
      valid = false;
    } else if (!password2 || password2 === "") {
      notify(
        lang === "fr"
          ? "Entrez votre confirmation de mot de passe s'il vous plaît !"
          : lang === "en"
          ? "Enter your password confirmation please !"
          : "أدخل تأكيد كلمة المرور الخاصة بك من فضلك!"
      );
      valid = false;
    } else if (password !== password2) {
      notify(
        lang === "fr"
          ? "Mot de passe pas le même !"
          : lang === "en"
          ? "Password not the same!"
          : "كلمة المرور ليست هي نفسها!"
      );
      valid = false;
    } else if (!acceptPolicy) {
      notify(
        lang === "fr"
          ? "Vous devez lire et accepter la politique s'il vous plaît !"
          : lang === "en"
          ? "You must read and agree to the policy please!"
          : "يجب عليك قراءة السياسة والموافقة عليها من فضلك!"
      );
      valid = false;
    }

    if (valid) {
      mutate(user);
    }
  };

  const notify = (message) => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 3000,
      fontSize: "12px",
    });
  };

  const notify_success = (message) => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 3000,
    });
  };

  useEffect(() => {
    let token = localStorage.getItem("token");
    let role = localStorage.getItem("role");
    if (token != null && token !== "") {
      if (role === "admin") {
        navigate("/admin");
      } else if (role === "acheteur") {
        navigate("/acheteur");
      } else if (role === "entreprise") {
        navigate("/entreprise");
      }
    }
  }, []);

  function isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      window.navigator.userAgent
    );
  }
  function parseJwt(token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  return (
    <BasicLayout image={null}>
      <Card>
        {/* <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Login
          </MDTypography>
          <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <FacebookIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GitHubIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GoogleIcon color="inherit" />
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox> */}
        <div className="w-100 mb-5">
          <Button
            startIcon={
              <ArrowBack style={lang === "ar" ? { transform: "rotate(180deg)" } : {}}></ArrowBack>
            }
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.button,
              transition: "all .2s ease-in-out",
              background: theme.palette.secondary.light,
              color: theme.palette.secondary.dark,
              "&:hover": {
                background: theme.palette.secondary.dark,
                color: theme.palette.secondary.light,
              },
              height: "100%",
              overflow: "hidden",
              borderRadius: "none",
            }}
            className="py-2 px-5"
            onClick={() => {
              navigate("/");
            }}
          >
            {lang === "fr" ? "Retour" : lang === "en" ? "Go Back" : "العودة"}
          </Button>
        </div>
        <div className="row w-100 m-0 row-sm-reverse">
          <div className="col-12 col-lg-6 d-sm-block d-lg-none">
            <div
              className="w-100 d-flex align-items-center justify-content-center"
              style={{
                height: "100%",
              }}
            >
              <div>
                <img
                  alt=""
                  src={LogoIcon}
                  style={{
                    width: "100%",
                    height: "auto",
                    maxWidth: "200px",
                    margin: "auto",
                    marginBottom: "40px",
                  }}
                />
                <img
                  alt=""
                  src={IllutrationLogin}
                  style={{ width: "100%", height: "auto", maxWidth: "650px" }}
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 d-flex align-items-center justify-content-around">
            <MDBox
              component="form"
              role="form"
              className="p-2"
              style={{ width: "95%", direction: lang === "ar" ? "rtl" : "ltr" }}
            >
              <div className="w-100 d-flex align-items-center justify-content-center">
                <div className="w-100" style={{ maxWidth: "500px" }}>
                  <AuthLangues></AuthLangues>
                  <MDBox
                    style={{
                      // background: "#1e293b",
                      borderRadius: "12px",
                      marginBottom: "50px",
                      textAlign: lang === "ar" ? "right" : "left",
                      marginTop: "50px",
                    }}
                  >
                    <MDTypography
                      variant="h2"
                      color="text"
                      style={{
                        textTransform: "uppercase",
                        // fontSize: isMobileDevice() ? "18px" : "",
                      }}
                    >
                      {lang === "fr"
                        ? "Inscription"
                        : lang === "en"
                        ? "Registration"
                        : "الانضمام إلينا"}
                    </MDTypography>
                  </MDBox>
                  {/* <div
                    className="w-100 d-flex align-items-center justify-content-center"
                    style={{
                      // background: "#1e293b",
                      padding: "20px",
                      borderRadius: "12px",
                      marginBottom: "20px",
                    }}
                  >
                    <img
                      alt=""
                      src={LogoIcon}
                      style={{ width: "100%", height: "auto", maxWidth: "150px" }}
                    />
                  </div> */}
                  <MDBox mb={3}>
                    <AppBar position="static">
                      <Tabs
                        orientation={tabsOrientation}
                        value={tabValue}
                        onChange={handleSetTabValue}
                      >
                        <Tab
                          label={lang === "fr" ? "Acheteur" : lang === "en" ? "Buyer" : "مشتر"}
                          icon={<ShoppingCart fontSize="small" sx={{ mt: -0.25 }}></ShoppingCart>}
                        />
                        <Tab
                          label={lang === "fr" ? "Vendeur" : lang === "en" ? "Seller" : "بائع"}
                          icon={<Storefront fontSize="small" sx={{ mt: -0.25 }}></Storefront>}
                        />
                      </Tabs>
                    </AppBar>
                  </MDBox>

                  {tabValue === 1 ? (
                    <>
                      <MDBox mb={2}>
                        <Container sx={{ mt: 2 }}>
                          <Alert severity="info" variant="filled">
                            {lang === "fr"
                              ? "L'inscription est gratuite pendant 6 mois. Vous pouvez nous contacter à tout moment pour acheter un abonnement avant la fin de la période gratuite."
                              : lang === "en"
                              ? "Registration is free for 6 months. You can contact us at any time to purchase a subscription before the end of the free period."
                              : "التسجيل مجاني لمدة 6 أشهر. يمكنك الاتصال بنا في أي وقت لشراء اشتراك قبل نهاية الفترة المجانية."}
                          </Alert>
                        </Container>
                      </MDBox>
                      <MDBox mb={2}>
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="full-name"
                          >
                            {lang === "fr" ? "Secteur" : lang === "en" ? "Sector" : "القطاع"}
                          </label>
                          <input
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder={
                              lang === "fr"
                                ? "Votre Secteur"
                                : lang === "en"
                                ? "Your Sector"
                                : "القطاع خاصتك"
                            }
                            value={secteur}
                            onChange={(e) => setSecteur(e.currentTarget.value)}
                          />
                        </div>
                      </MDBox>
                    </>
                  ) : (
                    <></>
                  )}
                  <MDBox mb={2}>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="full-name"
                      >
                        {lang === "fr"
                          ? "Dénomination"
                          : lang === "en"
                          ? "Denomination"
                          : "التسمية"}
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder={
                          lang === "fr"
                            ? "Votre dénomination"
                            : lang === "en"
                            ? "Your denomination"
                            : "التسمية خاصتك"
                        }
                        onChange={(e) => setNom(e.currentTarget.value)}
                      />
                    </div>
                  </MDBox>
                  <MDBox mb={2}>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="full-name"
                      >
                        {lang === "fr" ? "Pays" : lang === "en" ? "Country" : "الدولة"}
                      </label>
                      <select
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        style={{
                          paddingLeft: "30px !important",
                          paddingRight: "30px !important",
                        }}
                        placeholder={
                          lang === "fr"
                            ? "Votre Pays"
                            : lang === "en"
                            ? "Your Country"
                            : "الدولة خاصتك"
                        }
                        value={country}
                        onChange={(e) => setCountry(e.currentTarget.value)}
                      >
                        {sortCountriesByLang(countries, lang).map((el) => {
                          return (
                            <option value={el.id}>
                              {lang === "fr" ? el.french : lang === "en" ? el.english : el.arabic}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </MDBox>
                  <MDBox mb={2}>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="full-name"
                      >
                        {lang === "fr" ? "Email" : lang === "en" ? "Email" : "البريد الإلكتروني"}
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder={
                          lang === "fr"
                            ? "votre email"
                            : lang === "en"
                            ? "your email"
                            : "بريدك الإلكتروني"
                        }
                        onChange={(e) => setEmail(e.currentTarget.value)}
                      />
                    </div>
                  </MDBox>
                  <MDBox mb={2}>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="full-name"
                      >
                        {lang === "fr" ? "Mot de passe" : lang === "en" ? "Password" : "كلمة السر"}
                      </label>
                      <input
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder={
                          lang === "fr"
                            ? "Votre mot de passe ..."
                            : lang === "en"
                            ? "Your password ..."
                            : "كلمة السر ..."
                        }
                        type={showPassword ? "text" : "password"}
                        onChange={(e) => setPassword(e.currentTarget.value)}
                      />
                    </div>
                  </MDBox>
                  <MDBox mb={2}>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="full-name"
                      >
                        {lang === "fr"
                          ? "Mot de passe (Confirmation)"
                          : lang === "en"
                          ? "Password (Confirmation)"
                          : "كلمة السر (تأكيد)"}
                      </label>
                      <input
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder={
                          lang === "fr"
                            ? "Confirmer votre mot de passe ..."
                            : lang === "en"
                            ? "Confirm your password"
                            : "أكد كلمة السر الخاصة بك"
                        }
                        type={showPassword ? "text" : "password"}
                        onChange={(e) => setPassword2(e.currentTarget.value)}
                      />
                    </div>
                  </MDBox>

                  <MDBox display="flex" alignItems="center" ml={-1}>
                    <Switch
                      checked={showPassword}
                      onChange={() => {
                        setShowPassword((value) => !value);
                      }}
                      onClick={() => {
                        setShowPassword((value) => !value);
                      }}
                    />
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      onClick={() => {
                        setShowPassword((value) => !value);
                      }}
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;&nbsp;
                      {lang === "fr"
                        ? "Afficher le mot de passe"
                        : lang === "en"
                        ? "Show password"
                        : "إظهار كلمة المرور"}
                    </MDTypography>
                  </MDBox>

                  <MDBox display="flex" alignItems="center" ml={-1} mt={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={acceptPolicy}
                          onChange={(e) => setAcceptPolicy(e.target.checked)}
                          name="acceptPolicy"
                          color="primary"
                        />
                      }
                      label={
                        <MDTypography variant="button" color="text">
                          {lang === "fr"
                            ? "J'accepte la"
                            : lang === "en"
                            ? "I accept the"
                            : "أوافق على"}{" "}
                          <MDTypography
                            variant="button"
                            color="info"
                            fontWeight="medium"
                            textGradient
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              navigate("/authentication/politique");
                            }}
                          >
                            {lang === "fr"
                              ? "politique de la plateforme"
                              : lang === "en"
                              ? "platform policy"
                              : "سياسة المنصة"}
                          </MDTypography>
                        </MDTypography>
                      }
                    />
                  </MDBox>
                  <MDBox mt={4} mb={1}>
                    <ButtonBase
                      sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.button,
                        transition: "all .2s ease-in-out",
                        background: "#1e293b",
                        color: "#ffffff",
                        "&:hover": {
                          background: theme.palette.secondary.dark,
                          color: theme.palette.secondary.light,
                        },
                        height: "100%",
                        overflow: "hidden",
                        width: "100%",
                      }}
                      className="py-2 px-5"
                      onClick={() => {
                        Login();
                      }}
                    >
                      {lang === "fr" ? "S'inscrire" : lang === "en" ? "Register" : "انضم إلينا"}
                    </ButtonBase>
                  </MDBox>
                  <MDBox mt={3} mb={5} textAlign="center">
                    <MDTypography variant="button" color="text">
                      {lang === "fr"
                        ? "Vous avez déja un compte ?"
                        : lang === "en"
                        ? "Already have an account ?"
                        : "هل لديك حساب ؟"}{" "}
                      <MDTypography
                        variant="button"
                        color="info"
                        fontWeight="medium"
                        textGradient
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigate("/authentication/sign-in");
                        }}
                      >
                        {lang === "fr"
                          ? "Se connecter"
                          : lang === "en"
                          ? "Sign in"
                          : "تسجيل الدخول"}
                      </MDTypography>
                    </MDTypography>
                  </MDBox>
                  <Divider>
                    <MDTypography variant="button" color="text">
                      {lang === "fr" ? "Ou" : lang === "en" ? "Or" : "أو"}
                    </MDTypography>
                  </Divider>

                  <MDBox mb={3}>
                    <AppBar position="static">
                      <Tabs
                        orientation={tabsOrientation}
                        value={tabValue}
                        onChange={handleSetTabValue}
                      >
                        <Tab
                          label={lang === "fr" ? "Acheteur" : lang === "en" ? "Buyer" : "مشتر"}
                          icon={<ShoppingCart fontSize="small" sx={{ mt: -0.25 }}></ShoppingCart>}
                        />
                        <Tab
                          label={lang === "fr" ? "Vendeur" : lang === "en" ? "Seller" : "بائع"}
                          icon={<Storefront fontSize="small" sx={{ mt: -0.25 }}></Storefront>}
                        />
                      </Tabs>
                    </AppBar>
                  </MDBox>

                  {tabValue === 1 ? (
                    <>
                      <MDBox mb={2}>
                        <Container sx={{ mt: 2 }}>
                          <Alert severity="info" variant="filled">
                            {lang === "fr"
                              ? "L'inscription est gratuite pendant 6 mois. Vous pouvez nous contacter à tout moment pour acheter un abonnement avant la fin de la période gratuite."
                              : lang === "en"
                              ? "Registration is free for 6 months. You can contact us at any time to purchase a subscription before the end of the free period."
                              : "التسجيل مجاني لمدة 6 أشهر. يمكنك الاتصال بنا في أي وقت لشراء اشتراك قبل نهاية الفترة المجانية."}
                          </Alert>
                        </Container>
                      </MDBox>
                      <MDBox mb={2}>
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="full-name"
                          >
                            {lang === "fr" ? "Secteur" : lang === "en" ? "Sector" : "القطاع"}
                          </label>
                          <input
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder={
                              lang === "fr"
                                ? "Votre Secteur"
                                : lang === "en"
                                ? "Your Sector"
                                : "القطاع خاصتك"
                            }
                            value={secteur}
                            onChange={(e) => setSecteur(e.currentTarget.value)}
                          />
                        </div>
                      </MDBox>
                    </>
                  ) : (
                    <></>
                  )}

                  <MDBox mb={2}>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="full-name"
                      >
                        {lang === "fr" ? "Pays" : lang === "en" ? "Country" : "الدولة"}
                      </label>
                      <select
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        style={{
                          paddingLeft: "30px !important",
                          paddingRight: "30px !important",
                        }}
                        placeholder={
                          lang === "fr"
                            ? "Votre Pays"
                            : lang === "en"
                            ? "Your Country"
                            : "الدولة خاصتك"
                        }
                        value={country}
                        onChange={(e) => setCountry(e.currentTarget.value)}
                      >
                        {sortCountriesByLang(countries, lang).map((el) => {
                          return (
                            <option value={el.id}>
                              {lang === "fr" ? el.french : lang === "en" ? el.english : el.arabic}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </MDBox>
                  <MDBox display="flex" alignItems="center" ml={-1} mt={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={acceptPolicy}
                          onChange={(e) => setAcceptPolicy(e.target.checked)}
                          name="acceptPolicy"
                          color="primary"
                        />
                      }
                      label={
                        <MDTypography variant="button" color="text">
                          {lang === "fr"
                            ? "J'accepte la"
                            : lang === "en"
                            ? "I accept the"
                            : "أوافق على"}{" "}
                          <MDTypography
                            variant="button"
                            color="info"
                            fontWeight="medium"
                            textGradient
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              navigate("/authentication/politique");
                            }}
                          >
                            {lang === "fr"
                              ? "politique de la plateforme"
                              : lang === "en"
                              ? "platform policy"
                              : "سياسة المنصة"}
                          </MDTypography>
                        </MDTypography>
                      }
                    />
                  </MDBox>
                  <MDBox mb={5} textAlign="center" mt={3}>
                    {/* <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
                      <Grid item xs={4}>

                        <SocialIcon network="facebook" onClick={() => {}} />
                      </Grid>
                      <Grid item xs={4}>
                        <SocialIcon network="google" onClick={() => {}} />
                      </Grid>
                      <Grid item xs={4}>
                        <SocialIcon network="twitter" onClick={() => {}} />
                      </Grid>
                    </Grid> */}
                    <div className="w-100 d-flex align-items-center justify-content-center">
                      <GoogleLogin
                        onSuccess={(credentialResponse) => {
                          console.log(credentialResponse);

                          if (credentialResponse && credentialResponse.credential) {
                            let jwt = parseJwt(credentialResponse.credential);
                            console.log(jwt);
                            if (tabValue === 1 && (!secteur || secteur === "") && !country) {
                              notify(
                                lang === "fr"
                                  ? "Entrez votre secteur s'il vous plaît !"
                                  : lang === "en"
                                  ? "Please enter your sector!"
                                  : "الرجاء إدخال القطاع الخاص بك!"
                              );
                            } else if (!acceptPolicy) {
                              notify(
                                lang === "fr"
                                  ? "Vous devez lire et accepter la politique s'il vous plaît !"
                                  : lang === "en"
                                  ? "You must read and agree to the policy please!"
                                  : "يجب عليك قراءة السياسة والموافقة عليها من فضلك!"
                              );
                            } else {
                              mutateOauth({
                                token: credentialResponse.credential,
                                lang: lang,
                                type: tabValue === 0 ? "acheteur" : "entreprise",
                                secteur: tabValue === 0 ? null : secteur,
                                country: country,
                                accepted_policy: acceptPolicy ? 1 : 0,
                              });
                            }
                          }
                        }}
                        onError={() => {
                          console.log("Inscription Failed");
                        }}
                      />
                    </div>
                  </MDBox>
                </div>
              </div>
            </MDBox>
            <Divider orientation="vertical" className="d-none d-lg-block" />
          </div>
          <div className="col-12 col-lg-6 d-none d-lg-block">
            <div
              className="w-100 d-flex align-items-center justify-content-center"
              style={{
                height: "100%",
              }}
            >
              <div>
                <img
                  alt=""
                  src={LogoIcon}
                  style={{
                    width: "100%",
                    height: "auto",
                    maxWidth: "200px",
                    margin: "auto",
                    marginBottom: "40px",
                  }}
                />
                <img
                  alt=""
                  src={IllutrationLogin}
                  style={{ width: "100%", height: "auto", maxWidth: "650px" }}
                />
              </div>
            </div>
          </div>
          {/* {!isMobileDevice() ? (
          ) : (
            <></>
          )} */}
        </div>
        {/* <MDBox pt={4} pb={3} px={3}></MDBox> */}
      </Card>

      <Modal
        show={isLoading || isLoadingOauth}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body style={{ overflow: "hidden" }}>
          <Box sx={{ width: "100%" }}>
            <LinearProgress color="secondary" />
          </Box>
        </Modal.Body>
      </Modal>
    </BasicLayout>
  );
}

export default SignUp;
