import React, { useEffect, useRef, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { Button, Divider, Switch } from "@mui/material";
import { useSelector } from "react-redux";
const Plan = styled.div`
  ${tw`w-full max-w-sm  text-center px-8 rounded-lg shadow relative pt-2 text-gray-900 bg-white flex flex-col`}
  .planHighlight {
    ${tw`rounded-t-lg absolute top-0 inset-x-0 h-2`}
  }

  ${(props) =>
    props.featured &&
    css`
      background: rgb(100,21,255);
      background: linear-gradient(135deg, rgba(100,21,255,1) 0%, rgba(128,64,252,1) 100%);
background: rgb(85,60,154);
background: linear-gradient(135deg, rgba(85,60,154,1) 0%, rgba(128,90,213,1) 100%);
background: rgb(76,81,191);
background: linear-gradient(135deg, rgba(76,81,191,1) 0%, rgba(102,126,234,1) 100%);
      ${tw`bg-primary-500 text-gray-100`}
      .planHighlight {
        ${tw`hidden`}
      }
      .duration {
        ${tw`text-gray-200!`}
      }
      ${PlanFeatures} {
        ${tw`border-indigo-500`}
      }
      .feature:not(.mainFeature) {
        ${tw`text-gray-300!`}
      }
      ${BuyNowButton} {
        ${tw`bg-gray-100 text-primary-500 hocus:bg-gray-300 hocus:text-primary-800`}
    `}
`;

const PlanHeader = styled.div`
  ${tw`flex flex-col uppercase leading-relaxed pt-8 pb-3`}
  .name {
    ${tw`font-bold`}
    font-size : 0.85rem;
  }
  .price {
    ${tw`font-bold text-4xl sm:text-5xl my-1`}
  }
  .duration {
    ${tw`text-gray-500 font-bold tracking-widest`}
  }
`;
const PlanFeatures = styled.div`
  ${tw`flex flex-col -mx-8 px-8 py-3 border-t-2 border-b-2 flex-1 `}
  .feature {
    ${tw`mt-2 first:mt-0 font-medium`}
    &:not(.mainFeature) {
      ${tw`text-gray-600`}
    }
  }
  .mainFeature {
    ${tw`text-xl font-bold tracking-wide`}

    font-size : 0.85rem !important;
  }
`;

const PlanAction = tw.div`px-4 py-8`;
const BuyNowButton = styled(PrimaryButtonBase)`
  ${tw`rounded-full uppercase tracking-wider py-4 w-full text-sm hover:shadow-xl transform hocus:translate-x-px hocus:-translate-y-px focus:shadow-outline border-none`}
`;

const AbonnementItem = ({ plan }) => {
  const lang = useSelector((state) => state.customization.lang);
  return (
    <>
      <Plan
        featured={plan.featured}
        className="col-12"
        style={{ direction: lang === "ar" ? "rtl" : "" }}
      >
        <div className="w-100 d-flex align-items-center justify-content-center">
          <PlanHeader>
            <span className="name">
              {lang === "fr" ? plan.name : lang === "en" ? plan.name_en : plan.name_ar}
            </span>
          </PlanHeader>
        </div>
        {!plan.featured && (
          <div className="planHighlight" style={{ background: plan.linearColor }} />
        )}
        {plan.price > 0 && (
          <PlanHeader className="py-0">
            <span className="price">{plan.price} DA</span>
            <span className="duration">{plan.duration}</span>
          </PlanHeader>
        )}
        <PlanFeatures style={{ marginTop: "0" }} className="py-0 my-1">
          <span className="feature mainFeature" style={{ fontSize: "13px", color: "#6415ff" }}>
            {plan.note}
          </span>

          <span className="feature mainFeature">
            {lang === "fr"
              ? plan.mainFeature
              : lang === "en"
              ? plan.mainFeature_en
              : plan.mainFeature_ar}
          </span>
          {plan && plan.features && plan.features.length > 0 ? (
            <>
              {lang === "fr"
                ? plan.features.map((feature, index) => (
                    <li
                      key={index}
                      className="feature"
                      style={{ fontSize: "12px", textAlign: lang === "ar" ? "right" : "left" }}
                    >
                      {feature}
                    </li>
                  ))
                : lang === "en"
                ? plan.features_en.map((feature, index) => (
                    <li
                      key={index}
                      className="feature"
                      style={{ fontSize: "12px", textAlign: lang === "ar" ? "right" : "left" }}
                    >
                      {feature}
                    </li>
                  ))
                : plan.features_ar.map((feature, index) => (
                    <li
                      key={index}
                      className="feature"
                      style={{ fontSize: "12px", textAlign: lang === "ar" ? "right" : "left" }}
                    >
                      {feature}
                    </li>
                  ))}
            </>
          ) : (
            <></>
          )}
        </PlanFeatures>
        <PlanAction></PlanAction>
      </Plan>
    </>
  );
};
export default AbonnementItem;
