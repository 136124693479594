import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";

// Material Dashboard 2 React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
import { useNavigate } from "react-router-dom";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { IconEdit } from "@tabler/icons";
import { useSelector } from "react-redux";

function Header({ entreprise, onUpdate, children }) {
  const navigate = useNavigate();
  const lang = useSelector((state) => state.customization.lang);
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const [UserRole, setUserRole] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }
    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  const [pictureUrl, setPictureUrl] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const [pictureUrlCover, setPictureUrlCover] = useState("");

  const [selectedFileCover, setSelectedFileCover] = useState(null);
  const fileInputRefCover = useRef(null);

  const handleFileChangeCover = (event) => {
    setSelectedFileCover(event.target.files[0]);
  };

  const handleButtonClickCover = () => {
    fileInputRefCover.current.click();
  };

  useEffect(() => {
    if (selectedFile) {
      console.log("upload profile image");
      onUpdate({ profile_pic: selectedFile });
      setSelectedFile(null);
    }
  }, [selectedFile]);

  useEffect(() => {
    if (selectedFileCover) {
      console.log("upload cover image");
      onUpdate({ cover_pic: selectedFileCover });
      setSelectedFileCover(null);
    }
  }, [selectedFileCover]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    console.log("entreprise");
    console.log(entreprise);
    setUserRole(localStorage.getItem("role"));

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Add event listener to update window width when it changes
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <MDBox position="relative" mb={5}>
      <input
        accept="image/*"
        ref={fileInputRef}
        onClick={handleFileChange}
        onChange={handleFileChange}
        type="file"
        style={{ display: "none" }}
      />
      <input
        accept="image/*"
        ref={fileInputRefCover}
        onClick={handleFileChangeCover}
        onChange={handleFileChangeCover}
        type="file"
        style={{ display: "none" }}
      />
      <Card
        sx={{
          position: "relative",
          mt: "20px",
          // mx: windowWidth > 800 ? 3 : 0 ,
          mx: 0,
          py: 2,
          px: 2,
          borderRadius: "0",
        }}
      >
        <MDBox
          display="flex"
          alignItems="center"
          position="relative"
          minHeight="50px"
          // borderRadius="xl"
          sx={{
            // backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            //   `${linearGradient(
            //     rgba(gradients.dark.main, 0.4),
            //     rgba(gradients.dark.state, 0.4)
            //   )}, url("` +
            //   (entreprise && entreprise.cover_pic ? entreprise.cover_pic : "") +
            //   `")`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <MDBox
            color="text"
            px={0}
            py={0}
            style={
              lang === "ar"
                ? { position: "absolute", top: "0", left: "0", background: "white" }
                : { position: "absolute", top: "0", right: "0", background: "white" }
            }
          >
            <IconButton
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
              aria-label="Open to show more"
              title="Open to show more"
            >
              <IconEdit stroke={1.5} size="1.3rem" style={{ color: "#7450bb" }} />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {/* <MenuItem onClick={handleButtonClickCover}>Image de couverture</MenuItem> */}
              <MenuItem onClick={handleButtonClick}>
                {lang === "fr"
                  ? "Image de profile"
                  : lang === "en"
                  ? "Profile picture"
                  : "الصوره الشخصيه"}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  if (UserRole === "entreprise") {
                    navigate("/entreprise/settings");
                  } else if (UserRole === "acheteur") {
                    navigate("/acheteur/settings");
                  } else if (UserRole === "admin") {
                    navigate("/admin/settings");
                  }
                }}
              >
                {lang === "fr"
                  ? "Informations & Sécurité"
                  : lang === "en"
                  ? "Information & Security"
                  : "المعلومات والأمن"}
              </MenuItem>
            </Menu>
          </MDBox>
        </MDBox>
        <Grid container spacing={3} alignItems="center">
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              marginTop: "-50px",
            }}
          >
            <div
              style={{
                background: "white",
                width: "240px",
                height: "240px",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <MDAvatar
                src={entreprise && entreprise.profile_pic !== null ? entreprise.profile_pic : ""}
                alt="profile-image"
                size="xxl"
                shadow="sm"
                style={{ width: "200px", height: "200px" }}
              />
            </div>
          </Grid>
        </Grid>
        <div
          style={{
            width: "100%",
            marginTop: "0",
          }}
        >
          {children}
        </div>
      </Card>
    </MDBox>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  children: "",
};

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
