// groupe components

import GroupeProfile from "layoutsGroupe/Groupe";
import GEntreprisesList from "layoutsGroupes/EntreprisesList";
import GSecteurList from "layoutsGroupes/SecteurList";
import GPublireportageList from "layoutsGroupes/PublireportageList";
import GBannieresList from "layoutsGroupes/BannieresList";
import GContact from "layoutsGroupes/Contact";
import GAbonnementList from "layoutsGroupes/AbonnementList";
import GServiceList from "layoutsGroupes/ServiceList";
import GInscriptionsList from "layoutsGroupes/InscriptionsList";

import { Icon } from "@mui/material";

const GroupeRoutes = [
  {
    type: "collapse",
    name: "Profile",
    key: "groupe",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/groupe/",
    component: <GroupeProfile />,
    role: "groupe",
    group: "Users",
  },
  {
    type: "collapse",
    name: "Entreprises",
    key: "_groupe_entreprises",
    icon: <Icon fontSize="small">apartment</Icon>,
    route: "/groupe/entreprises",
    component: <GEntreprisesList />,
    role: "groupe",
    group: "Users",
  },
  {
    type: "collapse",
    name: "Secteurs",
    key: "_groupe_secteurs",
    icon: <Icon fontSize="small">account_tree</Icon>,
    route: "/groupe/secteurs",
    component: <GSecteurList />,
    role: "groupe",
    group: "Users",
  },
  {
    type: "collapse",
    name: "Publireportages",
    key: "_groupe_publireportages",
    icon: <Icon fontSize="small">ads_click</Icon>,
    route: "/groupe/publireportages",
    component: <GPublireportageList />,
    role: "groupe",
    group: "Users",
  },
  {
    type: "collapse",
    name: "Bannieres",
    key: "_groupe_bannieres",
    icon: <Icon fontSize="small">ad_units</Icon>,
    route: "/groupe/bannieres",
    component: <GBannieresList />,
    role: "groupe",
    group: "Users",
  },
  {
    type: "collapse",
    name: "Abonnements",
    key: "_groupe_abonnements",
    icon: <Icon fontSize="small">card_membership</Icon>,
    route: "/groupe/abonnements",
    component: <GInscriptionsList />,
    role: "groupe",
    group: "Users",
  },
  {
    type: "collapse",
    name: "Services",
    key: "_groupe_services",
    icon: <Icon fontSize="small">rss_feed</Icon>,
    route: "/groupe/services",
    component: <GServiceList />,
    role: "groupe",
    group: "Users",
  },
  {
    type: "collapse",
    name: "Paramètres",
    key: "_groupe_settings",
    icon: <Icon fontSize="small">settings</Icon>,
    route: "/groupe/settings",
    component: <GContact />,
    role: "groupe",
    group: "Users",
  },
];

export default GroupeRoutes;
