import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Components
import Sidebar from "./Sidebar";
import Backdrop from "./Backdrop";
// Assets
import LogoIcon from "assets/images/logo_targets_zone_dark2.png";

import Flag1 from "assets/img/flag_fr.png";
import Flag2 from "assets/img/flag_ar.png";
import Flag3 from "assets/img/flag_en.png";
import BurgerIcon from "assets/img/BurgerIcon";
import { useNavigate } from "react-router-dom";
import NotificationSection from "layouts/MainLayout/Header/NotificationSection";
import LangueSection from "layouts/MainLayout/Header/LangueSection";
import ProfileSection from "layouts/MainLayout/Header/ProfileSection";
import { useDispatch, useSelector } from "react-redux";

import { SET_LANG } from "store/actions";

export default function TopNavbar() {
  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);
  const [auth, setAuth] = useState(false);

  const lang = useSelector((state) => state.customization.lang);
  const dispatch = useDispatch();

  let navigate = useNavigate();

  useEffect(() => {}, [y]);

  useEffect(() => {
    let token = localStorage.getItem("token");
    setAuth(token && token != "");

    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, []);

  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <Wrapper
        className="flexCenter animate"
        id="header_smart"
        style={y > 100 ? { height: "80px" } : { height: "80px" }}
      >
        <NavInner className="container flexSpaceCenter">
          <Link
            onClick={() => {
              navigate("/");
            }}
            className="pointer flexNullCenter py-1"
            to="home"
            smooth={true}
          >
            {/* <LogoIcon /> */}
            <Img className="imgLogo" src={LogoIcon} alt="office" style={{ zIndex: 9 }} />
          </Link>
          <LangWrapper>
            <LangueSection></LangueSection>
          </LangWrapper>
          <BurderWrapper className="pointer" onClick={() => toggleSidebar(!sidebarOpen)}>
            <BurgerIcon />
          </BurderWrapper>
          <UlWrapper className="flexNullCenter">
            <li className="semiBold font11 pointer">
              <Link
                onClick={() => {
                  navigate("/");
                }}
                activeClass="active"
                style={{
                  padding: "10px 15px",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  color: "white",
                }}
                to="home"
                spy={true}
                smooth={true}
                offset={-80}
              >
                {lang === "fr" ? "Accueil" : lang === "en" ? "Home" : "الرئيسية"}
              </Link>
            </li>
            {/* <li className="semiBold font11 pointer">
              <Link activeClass="active" style={{ padding: "10px 15px" , color : "white"}} to="services" spy={true} smooth={true} offset={-80}>
                Services
              </Link>
            </li>
            <li className="semiBold font11 pointer">
              <Link activeClass="active" style={{ padding: "10px 15px" , color : "white"}} to="projects" spy={true} smooth={true} offset={-80}>
                Projects
              </Link>
            </li>
            <li className="semiBold font11 pointer">
              <Link activeClass="active" style={{ padding: "10px 15px" , color : "white"}} to="blog" spy={true} smooth={true} offset={-80}>
                Blog
              </Link>
            </li> */}
            <li className="semiBold font11 pointer">
              <Link
                activeClass="active"
                style={{
                  padding: "10px 15px",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  color: "white",
                }}
                to="aboutUs"
                spy={true}
                smooth={true}
                offset={-80}
              >
                {lang === "fr" ? "Qui Somme Nous ?" : lang === "en" ? "Who Are We ?" : "من نحن؟"}
              </Link>
            </li>
            <li className="semiBold font11 pointer">
              <Link
                activeClass="active"
                style={{
                  padding: "10px 15px",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  color: "white",
                }}
                to="products"
                spy={true}
                smooth={true}
                offset={-80}
              >
                {lang === "fr" ? "Produits" : lang === "en" ? "Products" : "المنتجات"}
              </Link>
            </li>
            <li className="semiBold font11 pointer">
              <Link
                activeClass="active"
                style={{
                  padding: "10px 15px",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  color: "white",
                }}
                to="annonces"
                spy={true}
                smooth={true}
                offset={-80}
                onClick={() => {
                  navigate("/annonces");
                }}
              >
                {lang === "fr" ? "Annonces" : lang === "en" ? "Announcements" : "الإعلانات"}
              </Link>
            </li>
            <li className="semiBold font11 pointer">
              <Link
                activeClass="active"
                style={{
                  padding: "10px 15px",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  color: "white",
                }}
                to="partenaires"
                spy={true}
                smooth={true}
                offset={-80}
              >
                {lang === "fr" ? "Partenaires" : lang === "en" ? "Partners" : "الشركاء"}
              </Link>
            </li>
            <li className="semiBold font11 pointer">
              <Link
                activeClass="active"
                style={{
                  padding: "10px 15px",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  color: "white",
                }}
                to="contactUs"
                spy={true}
                smooth={true}
                offset={-80}
              >
                {lang === "fr" ? "Contactez nous" : lang === "en" ? "Contact us" : "اتصل بنا"}
              </Link>
            </li>
            <li className="semiBold font11 pointer">
              <Link
                activeClass="active"
                style={{
                  padding: "10px 15px",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  color: "white",
                }}
                to="services"
                spy={true}
                smooth={true}
                offset={-80}
                onClick={() => {
                  navigate("/services");
                }}
              >
                {lang === "fr" ? "Services" : lang === "en" ? "Services" : "الخدمات"}
              </Link>
            </li>
          </UlWrapper>
          {!auth && (
            <UlWrapperRight className="flexNullCenter">
              <li className="semiBold font11 pointer">
                <a
                  href="/authentication/sign-in"
                  style={{ padding: "10px 30px 10px 0", color: "white" }}
                >
                  {lang === "fr" ? "Se connecter" : lang === "en" ? "Login" : "تسجيل الدخول"}
                </a>
              </li>
              <li className="semiBold font11 pointer flexCenter">
                <div
                  className="radius8"
                  style={{
                    padding: "10px 15px",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    backgroundColor: lang === "fr" ? "#8d8d8d6e" : "",
                  }}
                  onClick={() => {
                    dispatch({ type: SET_LANG, lang: "fr" });
                  }}
                >
                  <ImgFlag src={Flag1} alt="fr" />
                </div>

                <div
                  className="radius8"
                  style={{
                    padding: "10px 8px",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    backgroundColor: lang === "ar" ? "#8d8d8d6e" : "",
                  }}
                  onClick={() => {
                    dispatch({ type: SET_LANG, lang: "ar" });
                  }}
                >
                  <ImgFlag src={Flag2} alt="ar" />
                </div>

                <div
                  className="radius8 "
                  style={{
                    padding: "10px 8px",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    backgroundColor: lang === "en" ? "#8d8d8d6e" : "",
                  }}
                  onClick={() => {
                    dispatch({ type: SET_LANG, lang: "en" });
                  }}
                >
                  <ImgFlag src={Flag3} alt="en" />
                </div>
              </li>
            </UlWrapperRight>
          )}
          {auth && (
            <UlWrapperRight className="flexNullCenter">
              <li className="semiBold font11 pointer">
                <NotificationSection />
              </li>
              <li className="semiBold font11 pointer">
                <LangueSection />
              </li>
              <li className="semiBold font11 pointer">
                <ProfileSection />
              </li>
            </UlWrapperRight>
          )}
        </NavInner>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`;
const NavInner = styled.div`
  position: relative;
  height: 100%;
  direction: ltr;
`;
const BurderWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 1100px) {
    display: block;
  }
`;
const LangWrapper = styled.div`
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 1100px) {
    display: flex;
    align-items : center ; 
    justify-content-center ; 
  }
`;
const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 1100px) {
    display: none;
  }
`;
const UlWrapperRight = styled.ul`
  @media (max-width: 1100px) {
    display: none;
  }
`;

const Img = styled.img`
  width: auto;
  height: 60px;
  color: #ef423e;
`;
const ImgFlag = styled.img`
  width: auto;
  height: 25px;
`;
