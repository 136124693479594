import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import { useMutation } from "@tanstack/react-query";
import { BASE_URL_Image } from "services/server";
import { getPublireportages } from "services/pubsService";
import banniere1 from "assets/img/banniere1-1.jpg";
import banniere2 from "assets/img/banniere3-3.png";
import { useNavigate } from "react-router-dom";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const CardSlider = styled(Slider)`
  ${tw`mt-8`}
  .slick-track {
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1`}
    div {
      width: 100% !important ;
      position: relative;
      max-width: 100%;
      background: transparent;
    }
  }
  padding: 0;
`;
const Card = tw.div`h-full flex! flex-col sm:border max-w-sm  relative focus:outline-none bg-white m-0`;
const CardImage = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full h-56 sm:h-64 bg-cover bg-center `,
]);

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-2xl font-bold`;

const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4`;

const PrimaryButton = tw(
  PrimaryButtonBase
)`mt-auto sm:text-lg rounded-none w-full  py-3 sm:py-6 border-none`;
export default () => {
  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [sliderRef, setSliderRef] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (sliderRef) {
        sliderRef?.slickNext();
      }
    }, 5000);
    return () => {
      clearInterval(intervalId);
    };
  }, [sliderRef]);
  const sliderSettings = {
    arrows: false,
    slidesToShow: 1,
    responsive: [
      {
        breakpoint: 2300,
        settings: {
          slidesToShow: 1,
        },
      },

      {
        breakpoint: 2300,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const [items, setItems] = useState([
    {
      id: 1,
      entreprise_id: 1,
      image: banniere1,
      link: "https://www.bpifrance.fr/catalogue-offres/accelerateur-agroalimentaire-5eme-promotion",
    },
    {
      id: 2,
      entreprise_id: 1,
      image: banniere2,
      link: "https://www.conseiller.ca/conseiller-pme/nouvelles-pme/6-etapes-a-suivre-pour-exporter-avec-succes/",
    },
  ]);
  const { mutate, isLoading } = useMutation(() => getPublireportages(), {
    async onSuccess(data) {
      console.log(data);
      if (data && data.data) {
        setItems(
          data.data.map((el) => {
            return {
              imageSrc: BASE_URL_Image + el.image,
              title: el.titre,
              description: el.contenu,
            };
          })
        );
      } else {
      }
    },
    onError(error) {},
  });

  useEffect(() => {
    // mutate() ;
  }, []);

  /* Change this according to your needs */

  return (
    <Container>
      <Content style={{ padding: 0 }}>
        {items && items.length && (
          <CardSlider ref={setSliderRef} {...sliderSettings}>
            {items.map((card, index) => (
              <Card
                className="slider_card border-none"
                style={{ position: "relative", border: "none", cursor: "pointer" }}
                key={card.id}
                onClick={() => {
                  window.open(card.link);
                }}
              >
                {/* <img alt="" src={card.image} style={{width : "100%" , height : "auto" , cursor : "pointer"}} onClick={()=> {
                    navigate("/entreprise/1") ;
                  }}/> */}
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    top: "0",
                    left: "0",
                    backgroundImage: "url('" + card.image + "'",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    opacity: "0.3",
                    cursor: "pointer",
                  }}
                ></div>
                <div
                  style={{
                    height: "150px",
                    width: "100%",
                    overflow: "hidden",
                    backgroundImage: "url('" + card.image + "'",
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    cursor: "pointer",
                  }}
                ></div>
              </Card>
            ))}
          </CardSlider>
        )}
      </Content>
    </Container>
  );
};
