
// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";

// Data
import data from "layoutsGroupe/ServiceList/components/List/data";
import { Box, Divider, Grid } from "@mui/material";
import  Button  from "react-bootstrap/Button";
import Modal from 'react-bootstrap/Modal';
import MDButton from "components/MDButton";
import tw from "twin.macro";
import styled from "styled-components";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import MDInput from "components/MDInput";
import React, { useState } from "react";
import Slider from "react-slick";
import { SectionHeading } from "components/misc/Headings";
import { ReactComponent as PriceIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { ReactComponent as StarIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";
import { useEffect } from "react";
import { Row , Col , Pagination, Dropdown  } from 'react-bootstrap';
import AnchorLink from "react-anchor-link-smooth-scroll";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import PerfectScrollbar from 'react-perfect-scrollbar'

const ActionButton = tw(
  AnchorLink
)`px-8 py-3 font-bold rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300 mt-12 inline-block tracking-wide text-center px-10 py-4 font-semibold tracking-normal`;
const SecondaryButton = tw(
  ActionButton
)`mt-6 sm:mt-12 sm:ml-8 bg-gray-300 text-gray-800 hocus:bg-gray-400 hocus:text-gray-900`;
const Container = tw.div`relative w-full`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const HeadingWithControl = tw.div`flex flex-col items-center sm:items-stretch sm:flex-row justify-between`;
const Heading = tw(SectionHeading)``;
const Controls = tw.div`flex items-center`;
const ControlButton = styled(PrimaryButtonBase)`
${tw`mt-4 sm:mt-0 first:ml-0 ml-6 rounded-full p-2  border-none bg-gray-500 text-gray-100 border-none `}
svg {
    ${tw`w-6 h-6`}
}

`;
const PrevButton = tw(ControlButton)``;
const NextButton = tw(ControlButton)``;
const PrimaryButton = tw(PrimaryButtonBase)`mt-auto sm:text-lg rounded-none w-full py-3 sm:py-6 bg-gray-500 text-gray-100 border-none`;

const CardSlider = styled(Slider)`
  ${tw`mt-16`}
  .slick-track { 
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1`}
  }
`;
const CardTW = tw.div`h-full flex! flex-col sm:border max-w-sm  relative focus:outline-none`;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");height : 100% ; `,
  tw`w-full  bg-cover bg-center rounded sm:rounded-none`
]);

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-2xl font-bold`;

const RatingsInfo = styled.div`
  ${tw`flex items-center sm:ml-4 mt-2 sm:mt-0`}
  svg {
    ${tw`w-6 h-6 text-yellow-500 fill-current`}
  }
`;
const Rating = tw.span`ml-2 font-bold`;

const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4`;

const SecondaryInfoContainer = tw.div`flex flex-col sm:flex-row mt-2 sm:mt-4`;
const IconWithText = tw.div`flex items-center mr-6 my-2 sm:my-0`;
const IconContainer = styled.div`
  ${tw`inline-block rounded-full p-2  border-none bg-gray-700 text-gray-100`}
  svg {
    ${tw`w-3 h-3`}
  }
`;
const Text = tw.div`ml-2 text-sm font-semibold text-gray-800`;

const Actions = styled.div`
  ${tw`relative  text-center mx-auto lg:mx-0`}
  input {
    ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-primary-500 hover:border-gray-500`}
  }
  button {
    ${tw`w-full sm:absolute right-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
  }
`;
const TabsControl = tw.div`flex flex-wrap bg-gray-200 px-2 py-2 rounded leading-none mt-12 xl:mt-0`;

const TabControl = styled.div`
  ${tw`cursor-pointer px-6 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${props => props.active && tw`bg-primary-500! text-gray-100!`}
  }
`;


function List() {
  const [menu, setMenu] = useState(null);
  const [publireportage, setPublireportage] = useState(null);
  
  const secteurs = [
    {
      id : 0 , 
      text : "Tout "
    } ,
    {
      id : 1 , 
      text : "Energie "
    } ,{
      id : 2 , 
      text : "Habitat ,construction, travaux et aménagement"
    } ,{
      id : 3 , 
      text : "Hydrique"
    } ,{
      id : 4 , 
      text : "Industrie et transformation"
    } ,{
      id : 5 , 
      text : "Transport et mobilité"
    } ,
    {
      id : 6 , 
      text : "Santé et médical"
    } ,
    {
      id : 7 , 
      text : "Tourisme, hôtellerie et catering "
    } ,
    {
      id : 8 , 
      text : "Technologie et numérique"
    } ,
    {
      id : 9 , 
      text : "Culture,formation"
    } ,
    {
      id : 11 , 
      text : "Finance"
    } ,
    {
      id : 12 , 
      text : "Telecommunication"
    } ,
  ]  ;
  const [activeSecteur, setActiveSecteur] = useState(0);
  const [indexPage, setIndexPage] = useState(1);
  const [items, setItems] = useState([
    {
      id : 1 ,
      imageSrc: "https://i.ytimg.com/vi/i7AJwAAK0IU/maxresdefault.jpg",
      title: "L’assemblage des fenêtres et portes fenêtres en PVC",
      description: "Oxxo Algérie Spa est active dans le domaine de  l’assemblage des fenêtres et portes fenêtres en PVC à hautes performances thermiques",
      locationText: "Ilot D N° 6 Zhun Garidi - Kouba 16050 Alger - Algérie",
      entrepriseText: "OXXO - Cevital",
      rating: "4.8",
    },
    {
      id : 2 ,
      imageSrc: "https://prototechasia.com/wp-content/uploads/extrusion-plastique.jpg",
      title: "L’extrusion du profilé PVC",
      description: "Oxxo Algérie Spa est active dans le domaine de l’extrusion du profilé PVC",
      locationText: "Ilot D N° 6 Zhun Garidi - Kouba 16050 Alger - Algérie",
      entrepriseText: "OXXO - Cevital",
      rating: 4.9,
    },
    {
      id : 2 ,
      imageSrc: "https://prototechasia.com/wp-content/uploads/extrusion-plastique.jpg",
      title: "L’extrusion du profilé PVC",
      description: "Oxxo Algérie Spa est active dans le domaine de l’extrusion du profilé PVC",
      locationText: "Ilot D N° 6 Zhun Garidi - Kouba 16050 Alger - Algérie",
      entrepriseText: "OXXO - Cevital",
      rating: 4.9,
    },
    {
      id : 1 ,
      imageSrc: "https://i.ytimg.com/vi/i7AJwAAK0IU/maxresdefault.jpg",
      title: "L’assemblage des fenêtres et portes fenêtres en PVC",
      description: "Oxxo Algérie Spa est active dans le domaine de  l’assemblage des fenêtres et portes fenêtres en PVC à hautes performances thermiques",
      locationText: "Ilot D N° 6 Zhun Garidi - Kouba 16050 Alger - Algérie",
      entrepriseText: "OXXO - Cevital",
      rating: "4.8",
    },
    {
      id : 1 ,
      imageSrc: "https://i.ytimg.com/vi/i7AJwAAK0IU/maxresdefault.jpg",
      title: "L’assemblage des fenêtres et portes fenêtres en PVC",
      description: "Oxxo Algérie Spa est active dans le domaine de  l’assemblage des fenêtres et portes fenêtres en PVC à hautes performances thermiques",
      locationText: "Ilot D N° 6 Zhun Garidi - Kouba 16050 Alger - Algérie",
      entrepriseText: "OXXO - Cevital",
      rating: "4.8",
    },
  ]);

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);

  
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  
  const [openAdd, setOpenAdd] = useState(false);
  const handleOpenAdd = (publireportage) => {
    setPublireportage(publireportage) ;
    setOpenAdd(true);
    console.log(publireportage);
  };
  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  const { columns, rows } = data({handleOpen , handleOpenAdd , items : items});

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={closeMenu}>Action</MenuItem>
      <MenuItem onClick={closeMenu}>Another action</MenuItem>
      <MenuItem onClick={closeMenu}>Something else</MenuItem>
    </Menu>
  );

  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox style={{width : "100%"}}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            
          
          <MDTypography variant="h6" gutterBottom>
            Liste des Services
          </MDTypography>
          <MDBox display="flex" alignItems="center" lineHeight={0}>
            <Icon
              sx={{
                fontWeight: "bold",
                color: ({ palette: { info } }) => info.main,
                mt: -0.5,
              }}
            >
              handshake
            </Icon>
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;<strong>{items != null ? items.length : 0} {items != null && items.length > 1 ? "Services": "Service"}</strong> en total
            </MDTypography>
          </MDBox>
          
            </Grid>
          <Grid item xs={12} md={8}>
          
          <MDBox>
            <Grid container spacing={1}>
              <Grid item xs={8}>
                <MDInput style={{width : "100%"}} label="Service" />
              </Grid>
              <Grid item xs={2}>
                <MDButton color="dark" style={{maxWidth : "100%" , overflow  : "hidden"}}   onClick={handleOpenAdd}  >Filter</MDButton>
              </Grid>
               <Grid item xs={2}>
                <MDButton color="info"   onClick={handleOpenAdd}  style={{maxWidth : "100%" , overflow  : "hidden"}}  >Ajouter</MDButton>
              </Grid> 
            </Grid>
            </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <MDBox>
        {/* <DataTable
          table={{ columns, rows }}
          showTotalEntries={true}
          isSorted={false}
          noEndBorder
          entriesPerPage={true}
        /> */}
        <Container className="p-4">
          <PerfectScrollbar  style={{maxHeight : "65vh", overflowY : "scroll" }}>
              <Row style={{width : "100%"}} className="py-3">
                
              {items.map((card, index) => (
                        
                        <Col key={index} xs={12} className="mb-5">
                          <CardTW style={{width : "100%" , maxWidth : "100%" , boxShadow : "0 0 2px 1px #d9d9d9"}}>
                            <Row>
                              <Col xs={12} md={3}>
                                  <CardImage imageSrc={card.imageSrc} style={{minHeight : "150px"}} />
                              </Col>
                              <Col xs={12}  md={9} className="pb-3" style={{padding : "30px"}}>
                                <div className="w-100 d-flex justify-content-end align-items-center">
                                    <MDBox color="text" px={2}>
                                      <Dropdown align="start">
                                        <Dropdown.Toggle  id="icon-dropdown">
                                          Actions
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          <Dropdown.Item href="#action-2" disabled>Débloquer</Dropdown.Item>
                                          <Dropdown.Item href="#action-3">Bloquer</Dropdown.Item>
                                          <Dropdown.Item href="#action-4">Archiver</Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                  </MDBox>
                                </div>
                                  <TextInfo style={{maxHeight : "220px" , overflow:"hidden"}}>
                                    <TitleReviewContainer>
                                      <Title style={{fontSize : "16px"}}>{card.title}</Title>
                                    </TitleReviewContainer>
                                    <IconWithText className="mt-3">
                                        <StarIcon  style={{width : "20px" , height : "20px"}}/>
                                      <Text style={{fontSize : "13px"}}>{card.entrepriseText}</Text>
                                    </IconWithText>
                                    <IconWithText className="mt-2">
                                      <LocationIcon style={{width : "20px" , height : "20px"}}/>
                                      <Text style={{fontSize : "13px"}}>{card.locationText}</Text>
                                    </IconWithText>
                                    <Description  style={{fontSize : "12px"}}>{card.description}</Description>
                                  </TextInfo>
                                  <div className="w-100 d-flex justify-content-between align-items-center">
                                    <PrimaryButton  style={{ paddingTop : "15px" , paddingBottom : "15px", height : "20px" , fontSize : "12px" , display : "flex" , justifyContent : "center" , alignItems : "center" , maxWidth : '150px' }}>Site web</PrimaryButton>
                                    <SecondaryButton style={{margin : 0 , height : "20px" , fontSize : "12px" , display : "flex" , justifyContent : "center" , alignItems : "center", maxWidth : '150px' }}>Voir plus</SecondaryButton>
                                  </div>
                              </Col>
                            </Row>
                          </CardTW>
                          
                        </Col>
                      ))}

                    </Row>

            </PerfectScrollbar>
        </Container>
      </MDBox>
      <Modal
      show={open}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        Annulation d'un publireportage
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <MDBox
          mx={2}
          my={1}
          py={3}
          px={2}
          variant="gradient"
          bgColor="error"
          borderRadius="lg"
          coloredShadow="info"
        >
          <MDTypography variant="h6" color="white">
          Est-ce-que vous voulez vraiment annuler ce publireportage ?
          </MDTypography>
        </MDBox>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button onClick={handleClose}>Confirmer</Button> */}
        <MDButton color="error"  onClick={handleClose}>Confirmer</MDButton>
      </Modal.Footer>
    </Modal>

      <Modal
      show={openAdd}
      onHide={handleCloseAdd}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Ajouter un nouveau service
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{width :"100%" , display : "flex" , justifyContent : "center" , alignItems : "center"}}>
        <CardTW style={{maxWidth : "300px" , width : "100%" }}>
            <div style={{width : "300px"  , height : "300px" , border : "3px dashed  #1A73E8" , margin : "20px auto" , display : "flex" , justifyContent : "center" ,alignItems : "center" , cursor : "pointer" }}>
              <Icon fontSize="large" color="info">add_a_photo</Icon>
            </div>
            <MDInput style={{width : "100%" , marginTop : "20px"}} label="Entreprise" />
            <MDInput style={{width : "100%" , marginTop : "20px"}} label="Nom du service" />
            <MDInput style={{width : "100%" , marginTop : "20px"}} label="Description du service"  multiline rows={4} />
            <MDInput style={{width : "100%" , marginTop : "20px"}} label="Lien siteweb" />
       </CardTW>

        </div>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button onClick={handleCloseAdd}>Confirmer</Button> */}
        <MDButton color="info"  onClick={handleCloseAdd}>Confirmer</MDButton>
      </Modal.Footer>
    </Modal>
    </Card>
  );
}

export default List;
