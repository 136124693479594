import { Modal } from "react-bootstrap";

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
  FacebookIcon as FacebookIcon2,
  EmailIcon,
} from "react-share";
import { useSelector } from "react-redux";

import "./ShareProduct.css";
function ShareProduct({ shareProduct, onHideProduct, product }) {
  const lang = useSelector((state) => state.customization.lang);
  return (
    <>
      <Modal
        show={shareProduct}
        onHide={onHideProduct}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ fontSize: "0.95em", textTransform: "uppercase" }}
          >
            <strong>
              {product != null
                ? lang === "fr"
                  ? product.title
                  : lang === "en"
                  ? product.title_en
                  : product.title_ar
                : ""}
            </strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {product != null ? (
              <div style={{ width: "100%" }}>
                {/* <MDBox display="flex" alignItems="between" ml={-1}>
                    <FacebookShareButton url={window.location.href} />
                    <LinkedinShareButton
                      title={product.title}
                      summary={product.description}
                      source={window.location.href}
                      url={window.location.href}
                    />
                    <EmailShareButton url={window.location.href} />
                    <WhatsappShareButton url={window.location.href} />
                  </MDBox> */}
                <div className="Demo__container d-flex align-items-center justify-content-center">
                  <div className="Demo__some-network">
                    <FacebookShareButton
                      url={"https://abdennourbadeche.com"}
                      className="Demo__some-network__share-button"
                    >
                      <FacebookIcon2 size={32} round />
                    </FacebookShareButton>
                  </div>
                  <div className="Demo__some-network">
                    <TwitterShareButton
                      url={"https://abdennourbadeche.com"}
                      title={product.title}
                      className="Demo__some-network__share-button"
                    >
                      <XIcon size={32} round />
                    </TwitterShareButton>
                  </div>
                  <div className="Demo__some-network">
                    <WhatsappShareButton
                      url={"https://abdennourbadeche.com"}
                      title={product.title}
                      separator=":: "
                      className="Demo__some-network__share-button"
                    >
                      <WhatsappIcon size={32} round />
                    </WhatsappShareButton>
                  </div>
                  <div className="Demo__some-network">
                    <LinkedinShareButton
                      url={"https://abdennourbadeche.com"}
                      className="Demo__some-network__share-button"
                    >
                      <LinkedinIcon size={32} round />
                    </LinkedinShareButton>
                  </div>
                  <div className="Demo__some-network">
                    <EmailShareButton
                      url={"https://abdennourbadeche.com"}
                      subject={product.title}
                      body={product.description}
                      className="Demo__some-network__share-button"
                    >
                      <EmailIcon size={32} round />
                    </EmailShareButton>
                  </div>
                </div>
              </div>
            ) : (
              <> </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ShareProduct;
