// routes
import AuthRoutes from "./AuthRoutes.js";
import AdminRoutes from "./AdminRoutes.js";
import GroupeRoutes from "./GroupeRoutes.js";
import EntrepriseRoutes from "./EntrepriseRoutes.js";
import PubRoutes from "./PubRoutes.js";
import AcheteurRoutes from "./AcheteurRoutes.js";

const routes = [
  ...AuthRoutes,
  ...AdminRoutes,
  ...GroupeRoutes,
  ...EntrepriseRoutes,
  ...AcheteurRoutes,
  ...PubRoutes,
];

export default routes;
