import { useEffect, useRef, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";

// Data
import data from "layoutsGroupes/SecteurList/components/List/data";
import { Grid } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import MDButton from "components/MDButton";
import tw from "twin.macro";
import MDInput from "components/MDInput";

import { useMutation } from "@tanstack/react-query";
import { getSecteurs } from "services/adminService";
import { BASE_URL_Image } from "services/server";
import { Pagination } from "react-bootstrap";
import { toast } from "react-toastify";
import { uploaderSercteur } from "services/adminService";
import { updateSecteur } from "services/adminService";
import { desactivateSecteur } from "services/adminService";
import { activateSecteur } from "services/adminService";

const CardPub = tw.div`h-full flex! flex-col sm:border max-w-sm  relative focus:outline-none bg-white m-0`;

function List() {
  const [onUpdateSecteur, setOnUpdateSecteur] = useState(null);
  const [search, setSearch] = useState("");
  const [items, setItems] = useState([]);

  const [page, setPage] = useState({
    index: 1,
    limit: 5,
    totalItems: 0,
  });

  const [paginationItems, setPaginationItems] = useState([]);
  const { mutate, isLoading } = useMutation((userInfo) => getSecteurs(userInfo), {
    async onSuccess(data) {
      console.log(data);
      if (data && data.data) {
        setItems(
          data.data.map((el) => {
            return {
              imageSrc: BASE_URL_Image + el.image,
              id: el.id,
              title: el.nom,
              status: parseInt(el.status) === 1,
            };
          })
        );
        setPage((oldValue) => {
          return { ...oldValue, totalItems: parseInt(data.total) };
        });
        let table_items = [];
        if (parseInt(data.total) !== 0) {
          for (
            let number = 1;
            number <= parseInt(parseInt(data.total) / 5) + (parseInt(data.total) % 5 > 0 ? 1 : 0);
            number++
          ) {
            table_items.push(
              <Pagination.Item
                key={number}
                active={number === page.index}
                onClick={() => {
                  setPage((oldValue) => {
                    return { ...oldValue, index: number };
                  });
                }}
              >
                {number}
              </Pagination.Item>
            );
          }
        }
        setPaginationItems(table_items);
      } else {
      }
    },
    onError(error) {},
  });

  useEffect(() => {
    mutate({ filter: search, limit: 5, offset: (page.index - 1) * 5 });
  }, []);
  useEffect(() => {
    mutate({ filter: search, limit: 5, offset: (page.index - 1) * 5 });
  }, [page.index]);

  const rechercher = () => {
    mutate({ filter: search, limit: 5, offset: (page.index - 1) * 5 });
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [openAdd, setOpenAdd] = useState(false);
  const handleOpenAdd = (publireportage) => {
    setOpenAdd(true);
    console.log(publireportage);
  };
  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  const [nomSecteur, setNomSecteur] = useState("");
  const [pictureUrl, setPictureUrl] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    console.log(selectedFile);
    if (selectedFile) {
      let url = URL.createObjectURL(selectedFile);
      if (url) {
        console.log(url);
        setPictureUrl(url);
      }
    }
  }, [selectedFile]);

  const insertNewSecteur = () => {
    if (!selectedFile || !pictureUrl || pictureUrl == "") {
      notify("Vous devez inserer une image");
    } else if (nomSecteur == "") {
      notify("Vous devez entrez un nom");
    } else {
      mutateNewSecteur();
      // setOpenAdd(false);
    }
  };

  const { mutate: mutateNewSecteur, isLoading: isLoadingNewSecteur } = useMutation(
    () => uploaderSercteur({ file: selectedFile, nom: nomSecteur }),
    {
      async onSuccess(data) {
        console.log(data);
        if (data && data.data) {
          notify_success("Ajouter avec succès");
          handleCloseAdd();
          mutate({ filter: search, limit: 5, offset: (page.index - 1) * 5 });
        } else {
          notify("Une erreur a été produit");
        }
      },
      onError(error) {},
    }
  );

  const { mutate: mutateActivateSecteur, isLoading: isLoadingActivateSecteur } = useMutation(
    (userInfo) => activateSecteur(userInfo),
    {
      async onSuccess(data) {
        console.log(data);
        if (data && data.success) {
          notify_success("Secteur activé avec succès");
          mutate({ filter: search, limit: 5, offset: (page.index - 1) * 5 });
        } else {
          notify(data.message && data.message !== "" ? data.message : "Une erreur a été produit");
        }
      },
      onError(error) {},
    }
  );

  const { mutate: mutateDesactivateSecteur, isLoading: isLoadingDesactivateSecteur } = useMutation(
    (userInfo) => desactivateSecteur(userInfo),
    {
      async onSuccess(data) {
        console.log(data);
        if (data && data.success) {
          notify_success("Secteur désactivé avec succès");
          mutate({ filter: search, limit: 5, offset: (page.index - 1) * 5 });
        } else {
          notify(data.message && data.message !== "" ? data.message : "Une erreur a été produit");
        }
      },
      onError(error) {},
    }
  );

  const notify = (message) => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 3000,
      fontSize: "12px",
    });
  };

  const notify_success = (message) => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 3000,
    });
  };

  const handleOnUpdateSecteur = (secteur) => {
    console.log(secteur);
    setSelectedFile(null);
    setPictureUrl(secteur.imageSrc ? secteur.imageSrc : "");
    setNomSecteur(secteur.title ? secteur.title : "");
    setOnUpdateSecteur(secteur.id ? secteur.id : null);
    setOpenAdd(true);
  };

  const handleCloseOnUpdateSecteur = () => {
    setSelectedFile(null);
    setPictureUrl("");
    setNomSecteur("");
    setOnUpdateSecteur(null);
    setOpenAdd(false);
  };

  const { columns, rows } = data({
    handleOpen,
    handleOpenAdd,
    items: items,
    handleActivate: mutateActivateSecteur,
    handleDesactivate: mutateDesactivateSecteur,
    handleOpenUpdateSecteur: handleOnUpdateSecteur,
  });

  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox style={{ width: "100%" }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <MDTypography variant="h6" gutterBottom>
                Liste des secteurs
              </MDTypography>
              <MDBox display="flex" alignItems="center" lineHeight={0}>
                <Icon
                  sx={{
                    fontWeight: "bold",
                    color: ({ palette: { info } }) => info.main,
                    mt: -0.5,
                  }}
                >
                  account_tree
                </Icon>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;
                  <strong>
                    {page.totalItems ? page.totalItems : 0}{" "}
                    {page.totalItems && page.totalItems > 1 ? "Secteurs" : "Secteur"}
                  </strong>{" "}
                  en total
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <MDBox>
                <Grid container spacing={3} className="d-flex justify-content-end">
                  <Grid item xs={12} className="d-flex justify-content-end"></Grid>
                </Grid>
              </MDBox>
            </Grid>

            <div className="px-2 w-100">
              <Grid container spacing={3} className=" w-100 m-0">
                <Grid item style={{ flex: 1 }} className="px-2">
                  <MDInput
                    style={{ width: "100%" }}
                    label="Recherche"
                    onChange={(e) => setSearch(e.currentTarget.value)}
                  />
                </Grid>
                <Grid item className="d-flex justify-content-end">
                  <MDButton color="light" onClick={rechercher}>
                    Filtrer
                  </MDButton>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </MDBox>
      </MDBox>
      <MDBox>
        <DataTable
          table={{ columns, rows }}
          showTotalEntries={false}
          isSorted={false}
          noEndBorder
          entriesPerPage={false}
        />
        <div className="w-100 d-flex justify-content-center align-items-center">
          <Pagination>{paginationItems}</Pagination>
        </div>
        {page.totalItems === 0 && (
          <div className="w-100 d-flex justify-content-center align-items-center">
            <MDTypography variant="h6" color="light">
              Aucun secteur
            </MDTypography>
          </div>
        )}
      </MDBox>
      <Modal
        show={
          isLoading ||
          isLoadingNewSecteur ||
          isLoadingActivateSecteur ||
          isLoadingDesactivateSecteur
        }
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <MDBox
            mx={2}
            my={1}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              {isLoading ? "Loading ..." : "Updating ..."}
            </MDTypography>
          </MDBox>
        </Modal.Body>
      </Modal>
    </Card>
  );
}

export default List;
