import { authApi } from "./server";

export const sendMessage = async (userInfo) => {
  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("messages/sendMessage", userInfo);
  return response.data;
};

export const getUserMessages = async (userInfo) => {
  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("messages/getMessagesList", userInfo);
  return response.data;
};

export const makeUserMessageAsReaded = async (userInfo) => {
  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("messages/updateReadMessage", userInfo);
  return response.data;
};

export const makeResponseToMessage = async (userInfo) => {
  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("messages/updateAnswerMessage", userInfo);
  return response.data;
};

export const makeUserResponseAsReaded = async (userInfo) => {
  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("messages/updateReadResponse", userInfo);
  return response.data;
};

export const getAdminMessages = async (userInfo) => {
  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("messages/getAdminMessagesList", userInfo);
  return response.data;
};
export const makeAdminMessageAsReaded = async (userInfo) => {
  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("messages/updateAdminReadMessage", userInfo);
  return response.data;
};

export const makeAdminResponseAsReaded = async (userInfo) => {
  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("messages/updateAdminReadResponseMessage", userInfo);
  return response.data;
};

export const makeAdminValidateMessage = async (userInfo) => {
  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("messages/updateAdminValidateMessage", userInfo);
  return response.data;
};

export const makeAdminValidateResponse = async (userInfo) => {
  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("messages/updateAdminValidateResponse", userInfo);
  return response.data;
};
