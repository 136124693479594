import React, { useEffect, useState } from "react";
import TopNavbar from "../components/Nav/TopNavbarSecond";
import logo from "assets/images/logo_targets_zone_dark2.png";
import LandingPartenaires from "./LandingPartenaires";

export default () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    if (document.getElementById("header_smart") != null)
      document.getElementById("header_smart").classList.add("header_smart_solution");
    return () => {
      if (document.getElementById("header_smart") != null)
        document.getElementById("header_smart").classList.remove("header_smart_solution");
    };
  }, []);

  return (
    <>
      <TopNavbar></TopNavbar>
      <div id="partenaires">
        <section className="pb-20 relative block" style={{ paddingTop: "80px" }}>
          <div className="container mx-auto px-4 pt-4">
            <LandingPartenaires></LandingPartenaires>
          </div>
        </section>
      </div>
    </>
  );
};
