// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import ProfileInfoCard from "./components/ProfileInfoCard";
import ProfilesList from "./components/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "./components/Header";

// Data
import profilesListData from "./data/profilesListData";

// Images
import { useEffect, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import PerfectScrollbar from "react-perfect-scrollbar";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { ReactComponent as StarIcon } from "feather-icons/dist/icons/briefcase.svg";
import { Icon } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { getGroupePage } from "services/visiteurService";
import { BASE_URL_Image } from "services/server";

const ActionButton = tw(
  Button
)`px-8 py-3 font-bold rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300 mt-12 inline-block tracking-wide text-center px-10 py-4 font-semibold tracking-normal border-none`;
const SecondaryButton = tw(
  ActionButton
)`mt-6 sm:mt-12 sm:ml-8 bg-gray-300 text-gray-800 hocus:bg-gray-400 hocus:text-gray-900`;
const Container = tw.div`relative w-full`;

const CardTW = tw.div`h-full flex! flex-col sm:border max-w-sm  relative focus:outline-none`;
const CardImage = styled.div((props) => [
  `background-image: url("${props.imageSrc}");height : 100% ; `,
  tw`w-full  bg-cover bg-center rounded sm:rounded-none`,
]);

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-2xl font-bold`;

const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4`;

const IconWithText = tw.div`flex items-center mr-6 my-2 sm:my-0`;

const Text = tw.div`ml-2 text-sm font-semibold text-gray-800`;

function Overview() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [services, setServices] = useState([]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Add event listener to update window width when it changes
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [groupe, setGroupe] = useState({});
  const { mutate: mutateGroupe, isLoading: isLoadingGroupe } = useMutation(
    (id) => getGroupePage(id),
    {
      async onSuccess(data) {
        console.log(data);
        if (data && data.data && data.data.groupe) {
          let result = data.data.groupe[0];

          let infos_result = {};
          if (result.tel) infos_result["tel"] = result.tel;
          if (result.fax) infos_result["fax"] = result.fax;
          if (result.email) infos_result["email"] = result.email;
          if (result.localisation) infos_result["location"] = result.localisation;

          let links_result = [];

          if (result.facebook) {
            links_result.push({
              link: result.facebook,
              icon: <FacebookIcon />,
              color: "facebook",
            });
          }
          if (result.twitter) {
            links_result.push({
              link: result.twitter,
              icon: <TwitterIcon />,
              color: "twitter",
            });
          }
          if (result.instagram) {
            links_result.push({
              link: result.instagram,
              icon: <InstagramIcon />,
              color: "instagram",
            });
          }
          if (result.sitweb) {
            links_result.push({
              link: result.sitweb,
              icon: <Icon fontSize="small">language</Icon>,
              color: "portefolio",
            });
          }

          setGroupe({
            id: result.id,
            nom: result.nom,
            profile_pic: BASE_URL_Image + result.profile_pic,
            cover_pic: BASE_URL_Image + result.cover_pic,
            presentation: result.presentation,
            infos: infos_result,
            links: links_result,
            entreprises: data.data.entreprises.map((el) => {
              return {
                id: el.id,
                image: BASE_URL_Image + el.profile_pic,
                name: el.nom,
                action: {
                  type: "internal",
                  route: "/entreprise/" + el.id,
                  color: "info",
                  label: "Voir",
                },
              };
            }),
          });

          setServices(
            data.data.services.map((el) => {
              return {
                id: el.id,
                imageSrc: BASE_URL_Image + el.image,
                title: el.titre,
                description: el.description,
                locationText: el.localisation,
                entrepriseText: el.entreprise_nom,
                entreprise_id: el.entreprise_id,
                secteur: el.secteur_nom,
                secteur_id: el.secteur_id,
              };
            })
          );
        } else {
        }
      },
      onError(error) {},
    }
  );

  useEffect(() => {
    console.log(id);
    if (id) {
      console.log("loading");
      mutateGroupe(id);
    }
  }, []);

  useEffect(() => {
    if (document.getElementById("header_smart") != null)
      document.getElementById("header_smart").classList.add("header_smart_solution");
    return () => {
      if (document.getElementById("header_smart") != null)
        document.getElementById("header_smart").classList.remove("header_smart_solution");
    };
  });
  return (
    <div
      className="w-100"
      style={{ padding: windowWidth > 800 ? "40px" : "0px", paddingTop: "80px" }}
    >
      {/* <DashboardNavbar /> */}
      <MDBox mb={2} />
      <Header groupe={groupe}>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} xl={8} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              <ProfileInfoCard
                title="Description du groupe"
                description={groupe && groupe.presentation ? groupe.presentation : ""}
                info={groupe && groupe.infos ? groupe.infos : {}}
                social={groupe && groupe.links ? groupe.links : []}
                action={{ route: "", tooltip: "Modifier les informaitons" }}
                shadow={false}
              />
              <Divider orientation="vertical" sx={{ mx: 0 }} />
            </Grid>
            <Grid item xs={12} xl={4} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              <ProfilesList
                title="Entreprises"
                profiles={groupe && groupe.entreprises ? groupe.entreprises : []}
                shadow={false}
              />
              {/* <PlatformSettings /> */}
            </Grid>
          </Grid>
        </MDBox>
        <MDBox pt={2} px={2} lineHeight={1.25}>
          <MDTypography variant="h6" fontWeight="medium">
            Services
          </MDTypography>
          <Container className="p-4 px-0">
            <PerfectScrollbar>
              <Row style={{ width: "100%" }} className="py-3">
                {services.map((card, index) => (
                  <Col key={index} xs={12} className="mb-5">
                    <CardTW
                      style={{ width: "100%", maxWidth: "100%", boxShadow: "0 0 2px 1px #d9d9d9" }}
                    >
                      <Row>
                        <Col xs={12} md={3}>
                          <CardImage imageSrc={card.imageSrc} style={{ minHeight: "150px" }} />
                        </Col>
                        <Col xs={12} md={9} className="pb-3" style={{ padding: "30px" }}>
                          <TextInfo style={{ maxHeight: "220px", overflow: "hidden" }}>
                            <TitleReviewContainer>
                              <Title style={{ fontSize: "16px" }}>{card.title}</Title>
                            </TitleReviewContainer>
                            <IconWithText className="mt-3">
                              <StarIcon style={{ width: "20px", height: "20px" }} />
                              <Text style={{ fontSize: "13px" }}>{card.entrepriseText}</Text>
                            </IconWithText>
                            <IconWithText className="mt-2">
                              <LocationIcon style={{ width: "20px", height: "20px" }} />
                              <Text style={{ fontSize: "13px" }}>{card.locationText}</Text>
                            </IconWithText>
                            <Description style={{ fontSize: "12px" }}>
                              {card.description}
                            </Description>
                          </TextInfo>
                          <div className="w-100 d-flex justify-content-end align-items-center">
                            <SecondaryButton
                              style={{
                                margin: 0,
                                height: "20px",
                                fontSize: "12px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                maxWidth: "150px",
                              }}
                              onClick={() => {
                                navigate("/product/" + card.id);
                              }}
                            >
                              Voir plus
                            </SecondaryButton>
                          </div>
                        </Col>
                      </Row>
                    </CardTW>
                  </Col>
                ))}
              </Row>
            </PerfectScrollbar>
          </Container>
        </MDBox>
        {/* <MDBox pt={2} px={2} lineHeight={1.25}>
          <MDTypography variant="h6" fontWeight="medium">
            Projects
          </MDTypography>
          <MDBox mb={1}>
            <MDTypography variant="button" color="text">
              Architects design houses
            </MDTypography>
          </MDBox>
        </MDBox> */}
        {/* <MDBox p={2}>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6} xl={3}>
              <DefaultProjectCard
                image={homeDecor1}
                label="project #2"
                title="modern"
                description="As Uber works through a huge amount of internal management turmoil."
                action={{
                  type: "internal",
                  route: "/pages/profile/profile-overview",
                  color: "info",
                  label: "view project",
                }}
                authors={[
                  { image: team1, name: "Elena Morison" },
                  { image: team2, name: "Ryan Milly" },
                  { image: team3, name: "Nick Daniel" },
                  { image: team4, name: "Peterson" },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <DefaultProjectCard
                image={homeDecor2}
                label="project #1"
                title="scandinavian"
                description="Music is something that everyone has their own specific opinion about."
                action={{
                  type: "internal",
                  route: "/pages/profile/profile-overview",
                  color: "info",
                  label: "view project",
                }}
                authors={[
                  { image: team3, name: "Nick Daniel" },
                  { image: team4, name: "Peterson" },
                  { image: team1, name: "Elena Morison" },
                  { image: team2, name: "Ryan Milly" },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <DefaultProjectCard
                image={homeDecor3}
                label="project #3"
                title="minimalist"
                description="Different people have different taste, and various types of music."
                action={{
                  type: "internal",
                  route: "/pages/profile/profile-overview",
                  color: "info",
                  label: "view project",
                }}
                authors={[
                  { image: team4, name: "Peterson" },
                  { image: team3, name: "Nick Daniel" },
                  { image: team2, name: "Ryan Milly" },
                  { image: team1, name: "Elena Morison" },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <DefaultProjectCard
                image={homeDecor4}
                label="project #4"
                title="gothic"
                description="Why would anyone pick blue over pink? Pink is obviously a better color."
                action={{
                  type: "internal",
                  route: "/pages/profile/profile-overview",
                  color: "info",
                  label: "view project",
                }}
                authors={[
                  { image: team4, name: "Peterson" },
                  { image: team3, name: "Nick Daniel" },
                  { image: team2, name: "Ryan Milly" },
                  { image: team1, name: "Elena Morison" },
                ]}
              />
            </Grid>
          </Grid>
        </MDBox> */}
      </Header>
      {/* <Footer /> */}
      <Modal
        show={isLoadingGroupe}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <MDBox
            mx={2}
            my={1}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              Loading ...
            </MDTypography>
          </MDBox>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Overview;
