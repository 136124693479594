import { useEffect, useState } from "react";

// material-ui
import { Grid, Typography } from "@mui/material";

// project imports
import EarningCard from "./EarningCard";
import PopularCard from "./PopularCard";
import MessagesCard from "./MessagesCard";
import TotalOrderLineChartCard from "./TotalOrderLineChartCard";
import TotalIncomeDarkCard from "./TotalIncomeDarkCard";
import TotalIncomeLightCard from "./TotalIncomeLightCard";
import TotalGrowthBarChart from "./TotalGrowthBarChart";
import { gridSpacing } from "store/constant";
import TotalIncomePurpleCard from "./TotalIncomePurpleCard";
import TotalIncomeWarningCard from "./TotalIncomeWarningCard";
import ApplicationsCard from "./ApplicationsCard";
import { useMutation } from "@tanstack/react-query";
import { getAdminDashboard } from "services/adminService";
import ResponsesCard from "./ResponsesCard";
import { useSelector } from "react-redux";

// ==============================|| DEFAULT DASHBOARD ||============================== //

const Dashboard = () => {
  const [data, setData] = useState({
    total_sellers: 0,
    total_sellers_blocked: 0,
    total_buyers: 0,
    total_buyers_blocked: 0,
    total_products: 0,
    total_products_blocked: 0,
    total_announcements: 0,
    total_announcements_blocked: 0,
    total_unvalidated_messages: 0,
    total_unvalidated_responses: 0,
    total_unvalidated_requests: 0,
  });

  const lang = useSelector((state) => state.customization.lang);

  const { mutate, isLoading } = useMutation(() => getAdminDashboard(), {
    async onSuccess(data) {
      console.log(data);
      if (data && data.success) {
        setData({
          total_sellers: data.total_sellers,
          total_sellers_blocked: data.total_sellers_blocked,
          total_buyers: data.total_buyers,
          total_buyers_blocked: data.total_buyers_blocked,
          total_products: data.total_products,
          total_products_blocked: data.total_products_blocked,
          total_announcements: data.total_announcements,
          total_announcements_blocked: data.total_announcements_blocked,
          total_unvalidated_messages: data.total_unvalidated_messages,
          total_unvalidated_responses: data.total_unvalidated_responses,
          total_unvalidated_requests: data.total_unvalidated_requests,
        });
      } else {
      }
    },
    onError(error) {},
  });

  useEffect(() => {
    mutate();
    // setLoading(false);
  }, []);

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item>
        <Typography variant="h4" color="inherit" className="my-3">
          {lang === "fr"
            ? "Admin Dashboard"
            : lang === "en"
            ? "Admin Dashboard"
            : "لوحة تحكم المشرف"}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={gridSpacing}>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            {/* <EarningCard isLoading={isLoading} /> */}

            <TotalIncomePurpleCard
              total={data.total_announcements}
              blocked={data.total_announcements_blocked}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            {/* <TotalOrderLineChartCard isLoading={isLoading} /> */}
            <TotalIncomeWarningCard
              total={data.total_products}
              blocked={data.total_products_blocked}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            {/* <TotalOrderLineChartCard isLoading={isLoading} /> */}
            <TotalIncomeDarkCard
              total={data.total_buyers}
              blocked={data.total_buyers_blocked}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            {/* <TotalOrderLineChartCard isLoading={isLoading} /> */}
            <TotalIncomeLightCard
              total={data.total_sellers}
              blocked={data.total_sellers_blocked}
              isLoading={isLoading}
            />
          </Grid>
          {/* <Grid item lg={4} md={12} sm={12} xs={12}>
            <Grid container spacing={gridSpacing}>
              <Grid item sm={6} xs={12} md={6} lg={12}>
                <TotalIncomeDarkCard isLoading={isLoading} />
              </Grid>
              <Grid item sm={6} xs={12} md={6} lg={12}>
                <TotalIncomeLightCard isLoading={isLoading} />
              </Grid>
            </Grid>
          </Grid> */}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={gridSpacing}>
          <Grid item xs={12} md={4}>
            <PopularCard total={data.total_unvalidated_requests} isLoading={isLoading} />
          </Grid>
          <Grid item xs={12} md={4}>
            <MessagesCard total={data.total_unvalidated_messages} isLoading={isLoading} />
          </Grid>
          <Grid item xs={12} md={4}>
            <ResponsesCard total={data.total_unvalidated_responses} isLoading={isLoading} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <ApplicationsCard></ApplicationsCard>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
