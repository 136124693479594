import { useEffect, useRef, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";

// Data
import data from "layoutsEntreprises/EntreprisesList/components/List/data";
import { Box, Divider, Grid, Switch } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import MDButton from "components/MDButton";
import tw from "twin.macro";
import MDInput from "components/MDInput";

import { useMutation } from "@tanstack/react-query";
import { getEntreprises } from "services/adminService";
import { BASE_URL_Image } from "services/server";
import { Pagination } from "react-bootstrap";
import { toast } from "react-toastify";
import { uploaderEntreprise } from "services/adminService";
import { updateEntreprise } from "services/adminService";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { inscriptionAbonnementsList } from "services/adminService";

import edahabiaImage from "images/edahabia.png";
import virementImage from "images/virement.png";
import virementHoverImage from "images/virement_hover.png";
import { demandeInscription } from "services/visiteurService";
const FormContainer = tw.div`w-full flex-1 mt-8`;

const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const CardPub = tw.div`h-full flex! flex-col sm:border max-w-sm  relative focus:outline-none bg-white m-0`;

const PlansContainer = tw.div`flex justify-around flex-col lg:flex-row items-center lg:items-stretch relative`;
const Plan = styled.div`
  ${tw`w-full max-w-sm mt-16 lg:mr-8 lg:last:mr-0 text-center px-8 rounded-lg shadow relative pt-2 text-gray-900 bg-white flex flex-col`}
  .planHighlight {
    ${tw`rounded-t-lg absolute top-0 inset-x-0 h-2`}
  }

  ${(props) =>
    props.featured &&
    css`
      background: rgb(100,21,255);
      background: linear-gradient(135deg, rgba(100,21,255,1) 0%, rgba(128,64,252,1) 100%);
background: rgb(85,60,154);
background: linear-gradient(135deg, rgba(85,60,154,1) 0%, rgba(128,90,213,1) 100%);
background: rgb(76,81,191);
background: linear-gradient(135deg, rgba(76,81,191,1) 0%, rgba(102,126,234,1) 100%);
      ${tw`bg-primary-500 text-gray-100`}
      .planHighlight {
        ${tw`hidden`}
      }
      .duration {
        ${tw`text-gray-200!`}
      }
      ${PlanFeatures} {
        ${tw`border-indigo-500`}
      }
      .feature:not(.mainFeature) {
        ${tw`text-gray-300!`}
      }
      ${BuyNowButton} {
        ${tw`bg-gray-100 text-primary-500 hocus:bg-gray-300 hocus:text-primary-800`}
    `}
`;

const PlanHeader = styled.div`
  ${tw`flex flex-col uppercase leading-relaxed py-8`}
  .name {
    ${tw`font-bold text-xl`}
  }
  .price {
    ${tw`font-bold text-4xl sm:text-5xl my-1`}
  }
  .duration {
    ${tw`text-gray-500 font-bold tracking-widest`}
  }
`;
const PlanFeatures = styled.div`
  ${tw`flex flex-col -mx-8 px-8 py-8 border-t-2 border-b-2 flex-1`}
  .feature {
    ${tw`mt-5 first:mt-0 font-medium`}
    &:not(.mainFeature) {
      ${tw`text-gray-600`}
    }
  }
  .mainFeature {
    ${tw`text-xl font-bold tracking-wide`}
  }
`;

const PlanAction = tw.div`px-4 sm:px-8 xl:px-16 py-8`;
const BuyNowButton = styled(PrimaryButtonBase)`
  ${tw`rounded-full uppercase tracking-wider py-4 w-full text-sm hover:shadow-xl transform hocus:translate-x-px hocus:-translate-y-px focus:shadow-outline border-none`}
`;

function List() {
  const navigate = useNavigate();
  const [menu, setMenu] = useState(null);
  const [publireportage, setPublireportage] = useState(null);
  const [onUpdateEntreprise, setOnUpdateEntreprise] = useState(null);
  const [search, setSearch] = useState("");
  const [items, setItems] = useState([]);
  const [abonnements, setAbonnements] = useState([]);
  const [selectedAbonnement, setSelectedAbonnement] = useState(null);
  const [abonnement, setAbonnement] = useState(null);
  const [modePaiment, setModePaiment] = useState(1); // dahabia : 1 , virement : 2

  const [page, setPage] = useState({
    index: 1,
    limit: 5,
    totalItems: 0,
  });

  const [paginationItems, setPaginationItems] = useState([]);
  const { mutate, isLoading } = useMutation(
    (userInfo) => {
      let groupe_id = localStorage.getItem("groupe_id");
      if (groupe_id != null && parseInt(groupe_id)) {
        return getEntreprises({ ...userInfo, groupe_id: parseInt(groupe_id) });
      }
      return getEntreprises(userInfo);
    },
    {
      async onSuccess(data) {
        console.log(data);
        if (data && data.data) {
          setItems(
            data.data.map((el) => {
              return {
                id: el.id,
                image: BASE_URL_Image + el.profile_pic,
                name: el.nom,
                services: el.abonnement_services,
                usedServices: el.consomation_services,
                secteurs: el.abonnement_secteurs,
                usedSecteurs: el.consomation_secteurs,
                publireportages: el.abonnement_publireportages,
                usedPublireportages: el.consomation_publireportages,
                abonnement_name: el.abonnement_name,
                status: parseInt(el.status) === 0,
                abonnement_id: el.abonnement_id,
                consomation_id: el.consomation_id,
                abonnement_entreprises: el.abonnement_entreprises,
                company: el.groupe.map((it) => {
                  return { ...it, image: BASE_URL_Image + it.image };
                }),
              };
            })
          );
          setPage((oldValue) => {
            return { ...oldValue, totalItems: parseInt(data.total) };
          });
          let table_items = [];
          if (parseInt(data.total) !== 0) {
            for (
              let number = 1;
              number <= parseInt(parseInt(data.total) / 5) + (parseInt(data.total) % 5 > 0 ? 1 : 0);
              number++
            ) {
              table_items.push(
                <Pagination.Item
                  key={number}
                  active={number === page.index}
                  onClick={() => {
                    setPage((oldValue) => {
                      return { ...oldValue, index: number };
                    });
                  }}
                >
                  {number}
                </Pagination.Item>
              );
            }
          }
          setPaginationItems(table_items);
        } else {
        }
      },
      onError(error) {},
    }
  );

  useEffect(() => {
    setAbonnements(inscriptionAbonnementsList.filter((el) => el.id < 4));
    mutate({ filter: search, limit: 5, offset: (page.index - 1) * 5 });
  }, []);
  useEffect(() => {
    mutate({ filter: search, limit: 5, offset: (page.index - 1) * 5 });
  }, [page.index]);

  const rechercher = () => {
    mutate({ filter: search, limit: 5, offset: (page.index - 1) * 5 });
  };

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);

  const [open, setOpen] = useState(false);
  const handleOpen = (id) => {
    // setOpen(true);
    navigate("/groupe/" + id);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [openAdd, setOpenAdd] = useState(false);
  const handleOpenAdd = (publireportage) => {
    setPublireportage(publireportage);
    setOpenAdd(true);
    console.log(publireportage);
  };
  const handleCloseAdd = () => {
    setSelectedAbonnement(null);
    setAbonnement(null);
    setUsername("");
    setEmail("");
    setPassword("");
    setPassword2("");
    setModePaiment(1);
    setOpenAdd(false);
  };
  useEffect(() => {
    console.log("abonnement");
    console.log(abonnement);
  }, [abonnement]);

  const [nomEntreprise, setNomEntreprise] = useState("");
  const [pictureUrl, setPictureUrl] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    console.log(selectedFile);
    if (selectedFile) {
      let url = URL.createObjectURL(selectedFile);
      if (url) {
        console.log(url);
        setPictureUrl(url);
      }
    }
  }, [selectedFile]);

  const insertNewEntreprise = () => {
    if (!selectedFile || !pictureUrl || pictureUrl == "") {
      notify("Vous devez inserer une image");
    } else if (nomEntreprise == "") {
      notify("Vous devez entrez un nom");
    } else {
      mutateNewEntreprise();
      // setOpenAdd(false);
    }
  };

  const modifierEntreprise = (id) => {
    if (!selectedFile && pictureUrl !== "" && nomEntreprise != "") {
      mutateUpdateEntreprise({ id: onUpdateEntreprise, nom: nomEntreprise });
    } else if (nomEntreprise === "") {
      notify("Vous devez entrez un nom");
    } else if (pictureUrl === "") {
      notify("Vous devez inserer une image");
    } else {
      mutateUpdateEntreprise({ id: onUpdateEntreprise, file: selectedFile, nom: nomEntreprise });
      // setOpenAdd(false);
    }
  };
  const { mutate: mutateNewEntreprise, isLoading: isLoadingNewEntreprise } = useMutation(
    () => uploaderEntreprise({ file: selectedFile, nom: nomEntreprise }),
    {
      async onSuccess(data) {
        console.log(data);
        if (data && data.data) {
          notify_success("Ajouter avec succès");
          handleCloseAdd();
          mutate({ filter: search, limit: 5, offset: (page.index - 1) * 5 });
        } else {
          notify("Une erreur a été produit");
        }
      },
      onError(error) {},
    }
  );

  const { mutate: mutateUpdateEntreprise, isLoading: isLoadingUpdateEntreprise } = useMutation(
    (userInfo) => updateEntreprise(userInfo),
    {
      async onSuccess(data) {
        console.log(data);
        if (data && data.data) {
          notify_success("Mettre à jour avec succès");
          if (onUpdateEntreprise !== null) {
            handleCloseOnUpdateEntreprise();
          } else {
            handleCloseAdd();
          }
          mutate({ filter: search, limit: 5, offset: (page.index - 1) * 5 });
        } else {
          notify("Une erreur a été produit");
        }
      },
      onError(error) {},
    }
  );

  const notify = (message) => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 3000,
      fontSize: "12px",
    });
  };

  const notify_success = (message) => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 3000,
    });
  };

  const handleOnUpdateEntreprise = (Entreprise) => {
    console.log(Entreprise);
    setSelectedFile(null);
    setPictureUrl(Entreprise.imageSrc ? Entreprise.imageSrc : "");
    setNomEntreprise(Entreprise.title ? Entreprise.title : "");
    setOnUpdateEntreprise(Entreprise.id ? Entreprise.id : null);
    setOpenAdd(true);
  };

  const handleCloseOnUpdateEntreprise = () => {
    setSelectedFile(null);
    setPictureUrl("");
    setNomEntreprise("");
    setOnUpdateEntreprise(null);
    setOpenAdd(false);
  };

  const { columns, rows } = data({
    handleOpen,
    handleOpenAdd,
    items: items,
    handleUpdate: mutateUpdateEntreprise,
    handleOpenUpdateEntreprise: handleOnUpdateEntreprise,
    handleUpdateEntreprise: modifierEntreprise,
  });

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const { mutate: mutateAdd, isLoading: isLoadingAdd } = useMutation(
    (userData) => demandeInscription(userData),
    {
      async onSuccess(data) {
        console.log(data);

        if (data.result) {
          notify_success("valid");
          handleCloseAdd();
        } else {
          notify("error");
        }

        if (data.message !== "") {
          notify(data.message);
        }
      },
      onError(error) {
        notify("error");
        if (Array.isArray(error.response.data.error)) {
          error.response.data.error.forEach((el) => console.log(el.message));
        } else {
          console.log(error.response.data.message);
        }
      },
    }
  );

  const lancerPaiment = () => {
    let grp_id = localStorage.getItem("groupe_id");

    var user = {
      nom: username,
      email,
      password,
      password2,
      abonnement_id: abonnement.id,
      groupe_id: parseInt(grp_id),
      file: selectedFile,
    };
    console.log(user);

    var valid = true;

    if (!username || username === "") {
      notify("Enter your username please !");
      valid = false;
    } else if (
      !email ||
      email === "" ||
      !String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      notify("Enter your email please !");
      valid = false;
    } else if (!password || password === "") {
      notify("Enter your password please !");
      valid = false;
    } else if (!password2 || password2 === "") {
      notify("Confirm your password please !");
      valid = false;
    } else if (password2 !== password) {
      notify("Re-confirm your password please !");
      valid = false;
    } else if (!pictureUrl || pictureUrl === "") {
      notify("Vous devez entrer une preuve de paimenet");
    } else {
      notify_success("valid form");
      // setStartPaiment(true) ;
      mutateAdd(user);
      // navigate("/admin");
    }
  };

  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox style={{ width: "100%" }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <MDTypography variant="h6" gutterBottom>
                Liste des Entreprises
              </MDTypography>
              <MDBox display="flex" alignItems="center" lineHeight={0}>
                <Icon
                  sx={{
                    fontWeight: "bold",
                    color: ({ palette: { info } }) => info.main,
                    mt: -0.5,
                  }}
                >
                  account_tree
                </Icon>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;
                  <strong>
                    {page.totalItems ? page.totalItems : 0}{" "}
                    {page.totalItems && page.totalItems > 1 ? "Entreprises" : "Entreprise"}
                  </strong>{" "}
                  en total
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <MDBox>
                <Grid container spacing={3} className="d-flex justify-content-end">
                  <Grid item xs={12} className="d-flex justify-content-end">
                    <MDButton color="info" onClick={handleOpenAdd}>
                      Ajouter
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            </Grid>

            <div className="px-2 w-100">
              <Grid container spacing={3} className=" w-100 m-0">
                <Grid item style={{ flex: 1 }} className="px-2">
                  <MDInput
                    style={{ width: "100%" }}
                    label="Recherche"
                    onChange={(e) => setSearch(e.currentTarget.value)}
                  />
                </Grid>
                <Grid item className="d-flex justify-content-end">
                  <MDButton color="light" onClick={rechercher}>
                    Filtrer
                  </MDButton>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </MDBox>
      </MDBox>
      <MDBox>
        <DataTable
          table={{ columns, rows }}
          showTotalEntries={false}
          isSorted={false}
          noEndBorder
          entriesPerPage={false}
        />
        <div className="w-100 d-flex justify-content-center align-items-center">
          <Pagination>{paginationItems}</Pagination>
        </div>
        {page.totalItems === 0 && (
          <div className="w-100 d-flex justify-content-center align-items-center">
            <MDTypography variant="h6" color="light">
              Aucun Entreprise
            </MDTypography>
          </div>
        )}
      </MDBox>
      <Modal
        show={open}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Annulation d'un publireportage
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MDBox
            mx={2}
            my={1}
            py={3}
            px={2}
            variant="gradient"
            bgColor="error"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              Est-ce-que vous voulez vraiment annuler ce publireportage ?
            </MDTypography>
          </MDBox>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button onClick={handleClose}>Confirmer</Button> */}
          <MDButton color="error" onClick={handleClose}>
            Confirmer
          </MDButton>
        </Modal.Footer>
      </Modal>

      <Modal
        show={openAdd}
        onHide={handleCloseAdd}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {onUpdateEntreprise !== null ? "Modifier Entreprise" : "Ajouter un nouveau Entreprise"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {abonnement === null && (
            <PlansContainer className="row w-100 ml-0">
              {abonnements.map((plan, index) => (
                <Plan
                  key={index}
                  featured={plan.featured}
                  className="col-sm-12 col-md-4 col-lg-3"
                  style={{
                    background:
                      plan.id === 4
                        ? "linear-gradient(115deg, rgb(195 146 61) 0%, rgb(239 215 129) 100%)"
                        : "",
                  }}
                >
                  <div className="w-100 d-flex align-items-center justify-content-between">
                    <img alt="" src={plan.image} style={{ height: "150px", width: "auto" }} />
                    <PlanHeader>
                      <span className="name">{plan.name}</span>
                    </PlanHeader>
                  </div>
                  {!plan.featured && (
                    <div className="planHighlight" style={{ background: plan.linearColor }} />
                  )}
                  <PlanHeader className="py-0">
                    <span
                      className="price"
                      style={{
                        color: plan.id === 4 ? "white" : "",
                        textDecoration: "line-through",
                        fontSize: "13px",
                      }}
                    >
                      {plan.price} DA
                    </span>
                    <span className="price" style={{ color: plan.id === 4 ? "white" : "" }}>
                      {plan.price * 0.8} DA
                    </span>
                    <span className="duration" style={{ color: plan.id === 4 ? "white" : "" }}>
                      {plan.duration}
                    </span>
                  </PlanHeader>
                  <PlanFeatures style={{ marginTop: "0" }} className="py-0 my-1">
                    <span className="feature mainFeature">{plan.mainFeature}</span>
                    {plan.features.map((feature, index) => (
                      <li
                        key={index}
                        className="feature"
                        style={{
                          fontSize: "12px",
                          textAlign: "left",
                          color: plan.id === 4 ? "white" : "",
                        }}
                      >
                        {feature}
                      </li>
                    ))}
                    <span className="feature mainFeature">{plan.note}</span>
                  </PlanFeatures>
                  <PlanAction>
                    <BuyNowButton
                      onClick={() => {
                        console.log(plan);
                        setAbonnement(plan);
                      }}
                      style={{ background: plan.linearColor }}
                      // css={!plan.featured && highlightGradientsCss[index]}
                    >
                      Acheter
                    </BuyNowButton>
                  </PlanAction>
                </Plan>
              ))}
            </PlansContainer>
          )}
          {abonnement !== null && (
            <div style={{ width: "100%" }}>
              <div className="w-100 d-flex justify-content-center align-items-center">
                <div style={{ maxWidth: "300px" }}>
                  <FormContainer>
                    <Form onSubmit={() => {}}>
                      <Input
                        type="text"
                        placeholder={"Nom"}
                        style={{
                          border: "none",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                          borderRadius: "20px",
                          overflow: "hidden",
                        }}
                        onChange={(e) => setUsername(e.currentTarget.value)}
                      />
                      <Input
                        type="email"
                        placeholder="Email"
                        style={{
                          border: "none",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                          borderRadius: "20px",
                          overflow: "hidden",
                        }}
                        onChange={(e) => setEmail(e.currentTarget.value)}
                      />
                      {/* <Input type="text" placeholder="N° Tél" style={{border : "none" , paddingLeft : "10px" , paddingRight : "10px" , borderRadius : "20px" , overflow : "hidden"}}/> */}
                      <Input
                        type={showPassword ? "text" : "password"}
                        placeholder="Mot de passe"
                        style={{
                          border: "none",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                          borderRadius: "20px",
                          overflow: "hidden",
                        }}
                        onChange={(e) => setPassword(e.currentTarget.value)}
                      />
                      <Input
                        type={showPassword ? "text" : "password"}
                        placeholder="Confirmation du mot de passe"
                        style={{
                          border: "none",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                          borderRadius: "20px",
                          overflow: "hidden",
                        }}
                        onChange={(e) => setPassword2(e.currentTarget.value)}
                      />
                      <MDBox display="flex" alignItems="center" ml={-1}>
                        <Switch
                          checked={showPassword}
                          onChange={() => {
                            setShowPassword((value) => !value);
                          }}
                        />
                        <MDTypography
                          variant="button"
                          fontWeight="regular"
                          color="text"
                          onClick={() => {
                            setShowPassword((value) => !value);
                          }}
                          sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                        >
                          &nbsp;&nbsp;Afficher les mots de passe
                        </MDTypography>
                      </MDBox>
                    </Form>
                  </FormContainer>
                </div>
              </div>

              <Divider></Divider>
              <MDBox
                mx={2}
                my={1}
                py={3}
                px={2}
                variant="gradient"
                bgColor={modePaiment == 1 ? "info" : "light"}
                borderRadius="lg"
                coloredShadow={modePaiment == 1 ? "info" : "light"}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setModePaiment(1);
                }}
              >
                <div className="w-100 d-flex justify-content-around align-items-center">
                  <div
                    className="rounded-circle  mx-2"
                    style={{
                      height: "20px",
                      width: "20px",
                      backgroundColor: modePaiment == 1 ? "#1f4394" : "white",
                    }}
                  ></div>
                  <div className="mx-2">
                    <img alt="" src={edahabiaImage} style={{ width: "50px", height: "auto" }} />
                  </div>
                  <div
                    className="mx-2"
                    style={{
                      flex: 1,
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: modePaiment == 1 ? "white" : "black",
                    }}
                  >
                    Paiement avec EDAHABIA
                  </div>
                </div>
              </MDBox>

              <MDBox
                mx={2}
                my={1}
                py={3}
                px={2}
                variant="gradient"
                bgColor={modePaiment === 2 ? "info" : "light"}
                borderRadius="lg"
                coloredShadow={modePaiment === 2 ? "info" : "light"}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setModePaiment(2);
                }}
              >
                <div className="w-100 d-flex justify-content-around align-items-center">
                  <div
                    className="rounded-circle  mx-2"
                    style={{
                      height: "20px",
                      width: "20px",
                      backgroundColor: modePaiment == 2 ? "#1f4394" : "white",
                    }}
                  ></div>
                  <div className="mx-2">
                    <img
                      alt=""
                      src={modePaiment == 2 ? virementHoverImage : virementImage}
                      style={{ width: "50px", height: "auto" }}
                    />
                  </div>
                  <div
                    className="mx-2"
                    style={{
                      flex: 1,
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: modePaiment == 2 ? "white" : "black",
                    }}
                  >
                    Faire un virement
                  </div>
                </div>
              </MDBox>

              {modePaiment == 2 && (
                <div
                  style={{
                    width: "80%",
                    margin: "20px auto",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    overflow: "hidden",
                  }}
                  onClick={handleButtonClick}
                >
                  <input
                    accept="image/*"
                    ref={fileInputRef}
                    onClick={handleFileChange}
                    onChange={handleFileChange}
                    type="file"
                    style={{ display: "none" }}
                  />
                  <div>
                    <div className="d-flex justify-content-center align-items-center">
                      {pictureUrl && pictureUrl != "" && (
                        <img
                          style={{ height: "80px", width: "auto", margin: "auto" }}
                          src={pictureUrl}
                        />
                      )}
                    </div>
                    {pictureUrl && pictureUrl != "" && (
                      <MDTypography
                        style={{
                          fontSize: "13px",
                          width: "100%",
                          textAlign: "center",
                          marginTop: "5px",
                          fontWight: "bold",
                          color: "#3e98ef",
                        }}
                      >
                        Changer la photo
                      </MDTypography>
                    )}
                  </div>

                  {(!pictureUrl || pictureUrl === "") && (
                    <MDTypography style={{ fontSize: "13px" }}>
                      Ajouter une photo comme un preuve
                    </MDTypography>
                  )}
                </div>
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {abonnement != null && (
            <>
              <MDButton
                color="dark"
                onClick={() => {
                  setAbonnement(null);
                }}
              >
                Annuler
              </MDButton>
              <MDButton color="info" onClick={lancerPaiment}>
                Confirmer
              </MDButton>
            </>
          )}
        </Modal.Footer>
      </Modal>
      <Modal
        show={isLoading || isLoadingNewEntreprise || isLoadingUpdateEntreprise}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <MDBox
            mx={2}
            my={1}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              {isLoading ? "Loading ..." : "Updating ..."}
            </MDTypography>
          </MDBox>
        </Modal.Body>
      </Modal>
    </Card>
  );
}

export default List;
