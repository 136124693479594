// @mui material components
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDProgress from "components/MDProgress";
import Icon from "@mui/material/Icon";
import MDBadge from "components/MDBadge";
import { Dropdown } from "react-bootstrap";

export default function data(props) {
  const avatars = (image) =>
    image.map(([image, name]) => (
      <img
        key={name}
        src={image}
        alt="name"
        size="md"
        style={{ width: "50px", height: "auto", maxHeight: "50px" }}
      />
    ));

  const openModal = (item) => {
    props.handleOpen(item);
  };

  const openModalArchive = (item) => {
    props.handleOpenArchivage(item);
  };

  const openModalValidation = (banniere) => {
    props.handleOpenValidation(banniere);
  };

  const openModalAffichage = (banniere) => {
    props.handleOpenAffichage(banniere);
  };

  const Company = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="md" />
      <MDTypography variant="button" fontWeight="medium" ml={1} lineHeight={1}>
        {name}
      </MDTypography>
    </MDBox>
  );

  return {
    columns:
      props.lang === "fr"
        ? [
            { Header: "Image", accessor: "image", width: "10%", align: "left" },
            { Header: "Espace", accessor: "espace", align: "center" },
            { Header: "Heure Début", accessor: "start", align: "center" },
            { Header: "Heure Fin", accessor: "end", align: "center" },
            { Header: "Statut", accessor: "status", align: "center" },
            { Header: "", accessor: "action", align: "center" },
          ]
        : props.lang === "en"
        ? [
            { Header: "Image", accessor: "image", width: "10%", align: "left" },
            { Header: "Space", accessor: "espace", align: "center" },
            { Header: "Hour Start ", accessor: "start", align: "center" },
            { Header: "Hour End", accessor: "end", align: "center" },
            { Header: "Status", accessor: "status", align: "center" },
            { Header: "", accessor: "action", align: "center" },
          ]
        : [
            { Header: "الصورة", accessor: "image", width: "10%", align: "left" },
            { Header: "المكان", accessor: "espace", align: "center" },
            { Header: "ساعة البداية", accessor: "start", align: "center" },
            { Header: "ساعة النهاية", accessor: "end", align: "center" },
            { Header: "الحالة", accessor: "status", align: "center" },
            { Header: "", accessor: "action", align: "center" },
          ],

    rows: props.items.map((el) => {
      return {
        companies: <Company image={el.company.image} name={el.company.name} />,
        image: (
          <MDBox display="flex" py={1}>
            {avatars([[el.banniere.imageSrc, ""]])}
          </MDBox>
        ),
        espace: (
          <Tooltip title={el.banniere.espace} placeholder="bottom">
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {el.banniere.espace}
            </MDTypography>
          </Tooltip>
        ),
        start: (
          <MDBox ml={-1}>
            {el.status === 1 && (
              <MDBadge
                badgeContent={el.banniere.start ? el.banniere.start : "--"}
                color={"info"}
                variant="gradient"
                size="sm"
              />
            )}
          </MDBox>
        ),
        end: (
          <MDBox ml={-1}>
            {el.status === 1 && (
              <MDBadge
                badgeContent={el.banniere.end ? el.banniere.end : "--"}
                color={"dark"}
                variant="gradient"
                size="sm"
              />
            )}
          </MDBox>
        ),
        status: (
          <MDBox ml={-1}>
            <MDBadge
              badgeContent={el.status === 1 ? "Prouvé" : el.status === -1 ? "Annulé" : "En attente"}
              color={el.status === 1 ? "success" : el.status === -1 ? "danger" : "warning"}
              variant="gradient"
              size="sm"
            />
          </MDBox>
        ),
        action: (
          <>
            {el.status === 0 && (
              <MDBox color="text" px={2}>
                <Icon
                  sx={{ cursor: "pointer", fontWeight: "bold", marginRight: "5px" }}
                  color="error"
                  fontSize="small"
                  onClick={() => {
                    openModal(el.banniere);
                  }}
                >
                  close
                </Icon>
                <Icon
                  sx={{ cursor: "pointer", fontWeight: "bold" }}
                  color="success"
                  fontSize="small"
                  onClick={() => {
                    openModalValidation(el.banniere);
                  }}
                >
                  done
                </Icon>
              </MDBox>
            )}
            {el.status !== 0 && (
              <MDBox color="text" px={2}>
                <Dropdown align="start">
                  <Dropdown.Toggle variant="link" id="icon-dropdown"></Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        openModalAffichage(el.banniere);
                      }}
                    >
                      Afficher
                    </Dropdown.Item>
                    {/* <Dropdown.Item href="#action-2" disabled>Activer</Dropdown.Item>
                    <Dropdown.Item href="#action-3">Desavtiver</Dropdown.Item> */}
                    <Dropdown.Item
                      onClick={() => {
                        openModalArchive(el.banniere);
                      }}
                    >
                      Archiver
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </MDBox>
            )}
          </>
        ),
      };
    }),
  };
}
