import { Outlet } from "react-router-dom";
// import Navbar from "./components/Navbars/AuthNavbar";
import "./OurPages/Home.css";

import tw from "twin.macro"; //eslint-disable-line
import styled from "styled-components";
import { useEffect } from "react";

function FirstLayout() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <Navbar transparent /> */}
      <Outlet />
    </>
  );
}

export default FirstLayout;
