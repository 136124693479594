import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";

// Data
import data from "layoutsGroupe/BannieresList/components/data";
import { Box, Grid } from "@mui/material";
import  Button  from "react-bootstrap/Button";
import Modal from 'react-bootstrap/Modal';
import MDButton from "components/MDButton";
import tw from "twin.macro";
import styled from "styled-components";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import MDInput from "components/MDInput";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const CardPub = tw.div`h-full flex! flex-col sm:border max-w-sm  relative focus:outline-none bg-white m-0`;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full h-56 sm:h-64 bg-cover bg-center `
]);

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-2xl font-bold`;

const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4`;

const PrimaryButton = tw(PrimaryButtonBase)`mt-auto sm:text-lg rounded-none w-full  py-3 sm:py-6 border-none`;


function List() {
  const [menu, setMenu] = useState(null);
  const [publireportage, setPublireportage] = useState(null);
  const [items, setItems] = useState([
    {
      id : 1 , 
      company : {
        image : "https://oxxobatiment.fr/wp-content/uploads/2021/05/Logo-oxxo-2.png" , 
        name : "OXXO",
      } , 
      publireportage : {
        imageSrc : "https://media.gettyimages.com/id/1324372333/fr/vectoriel/conception-et-d%C3%A9veloppement-web-concept-banni%C3%A8re-web-isom%C3%A9trique-conception.jpg?s=2048x2048&w=gi&k=20&c=baFGOen9SQlPVrdVpW7mqgdZr8tNVF7sw2EqZASj084=" , 
        title : "Page d'acceuil -- Espace 01" , 
        description : "Comment une agence de conception de site web peut aider votre entreprise à se démarquer en ligne"
      } , 
      status : true , 
    } , 
    {
      id : 2 , 
      company : {
        image : "https://www.emploitic.com/attachments/company_logo/logo_4549883.jpg" , 
        name : "Sierra Cevital" ,
      } , 
      publireportage : {
        imageSrc : "https://i0.wp.com/www.24hdz.com/wp-content/uploads/2023/02/cevitall.jpeg?fit=800%2C319&ssl=1" , 
        title : "Page des services -- Espace 03" , 
        description : "Comment un coach professionnel peut vous aider à atteindre vos objectifs personnels et professionnels"
      }, 
      status : false , 
    } ,
  ]);

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);

  
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  
  const [openValidation, setOpenValidation] = useState(false);
  const handleOpenValidation = (publireportage) => {
    setPublireportage(publireportage) ;
    setOpenValidation(true);
    console.log(publireportage);
  };
  const handleCloseValidation = () => {
    setOpenValidation(false);
  };
  
  const [openAffichage, setOpenAffichage] = useState(false);
  const handleOpenAffichage = (publireportage) => {
    setPublireportage(publireportage) ;
    setOpenAffichage(true);
    console.log(publireportage);
  };
  const handleCloseAffichage = () => {
    setOpenAffichage(false);
  };
  const [openAdd, setOpenAdd] = useState(false);
  const handleOpenAdd = (publireportage) => {
    setPublireportage(publireportage) ;
    setOpenAdd(true);
    console.log(publireportage);
  };
  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  const { columns, rows } = data({handleOpen , handleOpenValidation , handleOpenAffichage , items : items});

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={closeMenu}>Action</MenuItem>
      <MenuItem onClick={closeMenu}>Another action</MenuItem>
      <MenuItem onClick={closeMenu}>Something else</MenuItem>
    </Menu>
  );

  return (
    <Card>
      
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox style={{width : "100%"}}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            
          
          <MDTypography variant="h6" gutterBottom>
            Liste des bannières
          </MDTypography>
          <MDBox display="flex" alignItems="center" lineHeight={0}>
            <Icon
              sx={{
                fontWeight: "bold",
                color: ({ palette: { info } }) => info.main,
                mt: -0.5,
              }}
            >
              ad_units
            </Icon>
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;<strong>{items != null ? items.length : 0} {items != null && items.length > 1 ? "Bannières": "Bannière"}</strong> en total
            </MDTypography>
          </MDBox>
          
            </Grid>
          <Grid item xs={12} md={6}>
          
          <MDBox>
            <Grid container spacing={3}>
              <Grid item xs={10}>
                <MDInput style={{width : "100%"}} label="Recherche" />
              </Grid>
               <Grid item xs={2}>
                <MDButton color="info"   onClick={handleOpenAdd}  style={{maxWidth : "100%" , overflow  : "hidden"}}  >Ajouter</MDButton>
              </Grid> 
            </Grid>
            </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <MDBox>
        <DataTable
          table={{ columns, rows }}
          showTotalEntries={true}
          isSorted={false}
          noEndBorder
          entriesPerPage={true}
        />
      </MDBox>
      <Modal
      show={open}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        Annulation d'une bannière
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <MDBox
          mx={2}
          my={1}
          py={3}
          px={2}
          variant="gradient"
          bgColor="error"
          borderRadius="lg"
          coloredShadow="info"
        >
          <MDTypography variant="h6" color="white">
          Est-ce-que vous voulez vraiment annuler cette bannière ?
          </MDTypography>
        </MDBox>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button onClick={handleClose}>Confirmer</Button> */}
        <MDButton color="error"  onClick={handleClose}>Confirmer</MDButton>
      </Modal.Footer>
    </Modal>

      <Modal
      show={openValidation}
      onHide={handleCloseValidation}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Validation d'une bannière
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <MDBox
          mx={2}
          my={1}
          py={3}
          px={2}
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
        >
          <MDTypography variant="h6" color="white">
          Est-ce-que vous voulez vraiment valider cette bannière ?
          </MDTypography>
        </MDBox>
        <div style={{width :"100%" , display : "flex" , justifyContent : "center" , alignItems : "center"}}>
        <CardPub style={{maxWidth : "1000px" , width : "100%" , height : "300px" , boxShadow : "0 0 5px 1px lightgrey"}}>
            <CardImage imageSrc={publireportage != null ? publireportage.imageSrc : null} style={{height : "300px" , width : "auto"}} />
          </CardPub>

        </div>
        <div style={{width :"100%" , display : "flex" , justifyContent : "center" , alignItems : "center" , marginTop : "20px"}}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker label="Début"/>
            <DatePicker label="Fin" />
          </LocalizationProvider>
          
        </div>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button onClick={handleCloseValidation}>Confirmer</Button> */}
        <MDButton color="info"  onClick={handleCloseValidation}>Confirmer</MDButton>
      </Modal.Footer>
    </Modal>

    <Modal
      show={openAffichage}
      onHide={handleCloseAffichage}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Bannière
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{width :"100%" , display : "flex" , justifyContent : "center" , alignItems : "center"}}>
        <CardPub style={{maxWidth : "1000px" , width : "100%" , height : "300px" , boxShadow : "0 0 5px 1px lightgrey"}}>
            <CardImage imageSrc={publireportage != null ? publireportage.imageSrc : null} style={{height : "300px" , width : "auto"}} />
          </CardPub>

        </div>
      </Modal.Body>
    </Modal>
    <Modal
    show={openAdd}
    onHide={handleCloseAdd}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
        Ajouter une nouvelle Baniere
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div style={{width :"100%" , display : "flex" , justifyContent : "center" , alignItems : "center"}}>
      <MDBox style={{maxWidth : "300px" , width : "100%" }}>
          <div style={{width : "300px"  , height : "150px" , border : "3px dashed  #1A73E8" , margin : "20px auto" , display : "flex" , justifyContent : "center" ,alignItems : "center" , cursor : "pointer" }}>
            <Icon fontSize="large" color="info">add_a_photo</Icon>
          </div>
          <MDInput style={{width : "100%" , marginTop : "20px"}} label="Entreprise" />
          <MDInput style={{width : "100%" , marginTop : "20px"}} label="Espace" />
          <MDInput style={{width : "100%" , marginTop : "20px"}} label="Titre" />
    </MDBox>

      </div>
    </Modal.Body>
    <Modal.Footer>
      {/* <Button onClick={handleCloseAdd}>Confirmer</Button> */}
      <MDButton color="info"  onClick={handleCloseAdd}>Confirmer</MDButton>
    </Modal.Footer>
    </Modal>
    </Card>
  );
}

export default List;
