import { useState, useEffect, useMemo } from "react";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
// react-router components
import { Routes, Route, Navigate, useLocation, BrowserRouter } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";

import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";
import themes from "assets/theme/themes";

// Material Dashboard 2 React Dark Mode themes
// import themeDark from "assets/theme-dark";

import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

//  routes
// import routes from "routes";
import routes from "routes/routes";

// Material Dashboard 2 React contexts
import {
  useMaterialUIController,
  setDirection,
  setMiniSidenav,
  setOpenConfigurator,
  setLayout,
} from "context";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import guard from "Guards";
import Home from "OurPages/Home";
import FirstLayout from "FirstLayout";
import AdminLayout from "AdminLayout";
import Dashboard from "layouts/dashboard";
import Partenaires from "OurPages/OurComponents/Home/Partenaires";
import ContactUs from "OurPages/OurComponents/Home/ContactUs";
import Entreprise from "OurPages/OurComponents/Home/Entreprise";
import Publireportage from "OurPages/OurComponents/Home/Publireportage";
import Service from "OurPages/OurComponents/Home/Service";
import Annonce from "OurPages/OurComponents/Home/Annonce";
import Groupe from "OurPages/OurComponents/Home/Groupe";
import Signup from "OurPages/OurComponents/Home/Signup";
import ComponentRenderer from "ComponentRenderer";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import GroupesLayout from "GroupesLayout";
import EntreprisesLayout from "EntreprisesLayout";
import "react-perfect-scrollbar/dist/css/styles.css";
import IdirLayout from "IdirLayout";
import PubLayout from "PubLayout";
import Landing from "pages/Landing";
import LandingQuiSommeNousScreen from "pages/LandingQuiSommeNousScreen";
import LandingProductsScreen from "pages/LandingProductsScreen";
import LandingAnnoncesScreen from "pages/LandingAnnoncesScreen";
import LandingPartenaireScreen from "pages/LandingPartenaireScreen";
import LandingContactezNousScreen from "pages/LandingContactezNousScreen";
import LandingServicesScreen from "pages/LandingServicesScreen";

import "./App.css";
import { StyledEngineProvider } from "@mui/system";
import { useSelector } from "react-redux";
import NavigationScroll from "layouts/NavigationScroll";
import MainLayout from "layouts/MainLayout";
export default function App() {
  const queryClient = new QueryClient();
  const customization = useSelector((state) => state.customization);

  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [userRole, setUserRole] = useState(localStorage.getItem("role"));
  const { pathname } = useLocation();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    let token = localStorage.getItem("token");
    let role = localStorage.getItem("role");
    console.log(role);
    if (token != null && token !== "" && role != null && role !== "") {
      setUserRole(role);
    } else {
      setUserRole(null);
    }
  }, [localStorage.getItem("role")]);

  useEffect(() => {
    if (customization.lang === "ar") {
      setDirection(dispatch, "rtl");
    } else {
      setDirection(dispatch, "ltr");
    }
  }, [customization]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route, key) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route path={route.route} element={route.component} key={key} />;
      }

      return null;
    });

  // const configsButton = (
  //   <MDBox
  //     display="flex"
  //     justifyContent="center"
  //     alignItems="center"
  //     width="3.25rem"
  //     height="3.25rem"
  //     bgColor="white"
  //     shadow="sm"
  //     borderRadius="50%"
  //     position="fixed"
  //     right="2rem"
  //     bottom="2rem"
  //     zIndex={99}
  //     color="dark"
  //     sx={{ cursor: "pointer" }}
  //     onClick={handleConfiguratorOpen}
  //   >
  //     <Icon fontSize="small" color="inherit">
  //       settings
  //     </Icon>
  //   </MDBox>
  // );

  return (
    <StyledEngineProvider injectFirst>
      {/* <ThemeProvider theme={theme}> */}
      <ThemeProvider theme={themes(customization)}>
        <QueryClientProvider client={queryClient}>
          <CssBaseline />
          <div className={customization.lang === "ar" ? "arabic_content" : ""}>
            {/* {layout === "dashboard" && (
            <>
              <Sidenav
                color={sidenavColor}
                // brand={brandWhite}
                brandName="Le Portail Economique Algérien"
                brandType="admin"
                routes={routes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Configurator />
              <Routes>
                {getRoutes(routes)}
                <Route path="*" element={<Navigate to="/dashboard" />} />
              </Routes>
            </>
          )} */}

            <NavigationScroll>
              <Routes>
                {/* <Route
                path="/admin"
                element={
                  // localStorage.getItem("role") === "admin" ?
                  <MainLayout />
                  // : <Navigate to="/" />
                }
              >
                {getRoutes(routes.filter((el) => el.role === "admin"))}
              </Route> */}

                <Route path="/" element={<FirstLayout />}>
                  <Route path="/" element={<Landing />} />
                  <Route path="/products" element={<LandingProductsScreen />} />
                  <Route path="/annonces" element={<LandingAnnoncesScreen />} />

                  <Route path="/partenaires" element={<LandingPartenaireScreen />} />
                  <Route path="/aboutUs" element={<LandingQuiSommeNousScreen />} />
                  <Route path="/contactUs" element={<LandingContactezNousScreen />} />
                  <Route path="/services" element={<LandingServicesScreen />} />

                  <Route path="/signup/:index" element={<Signup />} />

                  <Route path="/entreprise/:id" element={<Entreprise />} />
                  <Route path="/groupe/:id" element={<Groupe />} />
                  <Route path="/product/:id" element={<Service />} />
                  <Route path="/annonce/:id" element={<Annonce />} />
                  <Route path="/publireportage/:id" element={<Publireportage />} />

                  <Route path="/components/:type/:subtype/:name" element={<ComponentRenderer />} />
                  <Route path="/components/:type/:name" element={<ComponentRenderer />} />
                </Route>

                <Route
                  path="/groupe"
                  element={
                    localStorage.getItem("role") === "groupe" ? (
                      <GroupesLayout />
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                >
                  {getRoutes(routes.filter((el) => el.role === "groupe"))}
                </Route>

                <Route
                  path="/pub"
                  element={
                    localStorage.getItem("role") === "pub" ? <PubLayout /> : <Navigate to="/" />
                  }
                >
                  {getRoutes(routes.filter((el) => el.role === "pub"))}
                </Route>

                <Route
                  path="/entreprise"
                  element={
                    localStorage.getItem("role") === "entreprise" ? (
                      <MainLayout />
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                >
                  {getRoutes(routes.filter((el) => el.role === "entreprise"))}
                </Route>

                <Route
                  path="/acheteur"
                  element={
                    localStorage.getItem("role") === "acheteur" ? (
                      <MainLayout />
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                >
                  {getRoutes(routes.filter((el) => el.role === "acheteur"))}
                </Route>

                <Route
                  path="/admin"
                  element={
                    localStorage.getItem("role") === "admin" ? <MainLayout /> : <Navigate to="/" />
                  }
                >
                  {getRoutes(routes.filter((el) => el.role === "admin"))}
                </Route>

                <Route path="/signaux" element={<IdirLayout />}>
                  {getRoutes(routes.filter((el) => el.role === "idir"))}
                </Route>

                {getRoutes(routes.filter((el) => el.role === "authentication"))}

                <Route path="/*" element={<Navigate to="/" />} />
              </Routes>
            </NavigationScroll>

            <ToastContainer style={{ fontSize: "13px" }} />
          </div>
        </QueryClientProvider>
        {/* </ThemeProvider> */}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
