import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  ButtonBase,
  Divider,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Modal,
  Typography,
} from "@mui/material";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";

import { useEffect, useState } from "react";
import SearchSection from "components/search/SearchSection";
import { BASE_URL_Image } from "services/server";
import { useMutation } from "@tanstack/react-query";
import { getAnnonces } from "services/adminService";
import { getServices } from "services/servicesService";
import { Col, Row } from "react-bootstrap";
import ServiceItem from "OurPages/OurComponents/Home/ServiceItem";
import MDTypography from "components/MDTypography";
import AnnonceItem from "OurPages/OurComponents/Home/AnnonceItem";
import PerfectScrollbar from "react-perfect-scrollbar";
import AbonnementItem from "OurPages/OurComponents/Home/AbonnementItem";
import { useNavigate } from "react-router-dom";
import ScrollBar from "react-perfect-scrollbar";

const LandingSearch = ({ initSearch, setInitSearch, openSearch, setOpenSearch }) => {
  const theme = useTheme();
  const [search, setSearch] = useState("");

  const [itemsEtudes, setItemsEtudes] = useState([
    {
      id: 6,
      name: "Marketing & Communication",
      name_en: "Marketing & Communication",
      name_ar: "التسويق والاتصالات",
      price: 0,
      duration: "",
      featured: false,
      mainFeature: "",
      mainFeature_en: "",
      mainFeature_ar: "",
      note: "",
      features: ["Bannières d'accueil", "Vidéo à la une", "Autres bannières sur la plate-forme "],
      features_en: ["Home banners", "Featured video", "Other banners around the platform."],
      features_ar: ["لافتات الصفحة الرئيسية", "فيديو مميز", "لافتات أخرى على المنصة"],
      comptes: 1,
      secteurs: 100,
      services: 100,
      mensuelle: 0,
      trimestrielle: 0,
      semestrielle: 0,
      anuelle: 1,
      activated: true,
      archivated: false,
      status: 1,
      linearColor: "#1e293b",
      created_at: "2023-05-27 22:40:39",
      updated_at: "2023-06-04 05:08:47",
    },
    // {
    //   id: 7,
    //   name: "Accompagnement Juridique",
    //   name_en: "Legal support",
    //   name_ar: "الدعم القانوني",
    //   price: 0,
    //   duration: "",
    //   featured: false,
    //   mainFeature: "",
    //   mainFeature_en: "",
    //   mainFeature_ar: "",
    //   note: "",
    //   features: ["réglementations en vigueur"],
    //   features_en: ["current regulations"],
    //   features_ar: ["اللوائح الحالية"],
    //   comptes: 1,
    //   secteurs: 100,
    //   services: 100,
    //   mensuelle: 0,
    //   trimestrielle: 0,
    //   semestrielle: 0,
    //   anuelle: 1,
    //   activated: true,
    //   archivated: false,
    //   status: 1,
    //   linearColor: "#1e293b",
    //   created_at: "2023-05-27 22:40:39",
    //   updated_at: "2023-06-04 05:08:47",
    // },
    {
      id: 1,
      name: "Accompagnement Commercial",
      name_en: "Sales support",
      name_ar: "الدعم التجاري",
      price: 0,
      duration: "",
      featured: false,
      mainFeature: "",
      mainFeature_en: "",
      mainFeature_ar: "",
      note: "",
      features: ["réglementations en vigueur", "indices économiques"],
      features_en: ["current regulations", "economic indicators"],
      features_ar: ["اللوائح الحالية", "المؤشرات الاقتصادية"],
      comptes: 1,
      secteurs: 100,
      services: 100,
      mensuelle: 0,
      trimestrielle: 0,
      semestrielle: 0,
      anuelle: 1,
      activated: true,
      archivated: false,
      status: 1,
      linearColor: "#1e293b",
      created_at: "2023-05-27 22:40:39",
      updated_at: "2023-06-04 05:08:47",
    },
    // {
    //   id: 3,
    //   name: "Accompagnement de soutien",
    //   name_en: "Support services",
    //   name_ar: "خدمات الدعم",
    //   price: 0,
    //   duration: "",
    //   featured: false,
    //   mainFeature: "",
    //   mainFeature_en: "",
    //   mainFeature_ar: "",
    //   note: "",
    //   features: ["RDV d’affaire", "Visite et procédures"],
    //   features_en: ["Business meetings", "Visits and procedures"],
    //   features_ar: ["اجتماعات العمل", "الزيارات والإجراءات"],
    //   comptes: 1,
    //   secteurs: 100,
    //   services: 100,
    //   mensuelle: 1,
    //   trimestrielle: 0,
    //   semestrielle: 0,
    //   anuelle: 0,
    //   activated: true,
    //   archivated: false,
    //   status: 1,
    //   linearColor: "#1e293b",
    //   created_at: "2023-05-27 22:40:39",
    //   updated_at: "2023-06-04 05:08:47",
    // },
    {
      id: 2,
      name: "Accompagnement personnalisé",
      name_en: "Personalized support",
      name_ar: "الدعم المخصص",
      price: 0,
      duration: "",
      featured: false,
      mainFeature: "à la demande du partenaire",
      mainFeature_en: "at partner's request",
      mainFeature_ar: "بناءً على طلب الشريك",
      note: "",
      features: ["RDV d'affaire et visite", "prospection orientée"],
      features_en: ["Business meetings and visits", "targeted prospecting"],
      features_ar: ["اجتماعات العمل والزيارات", "التنقيب المستهدف"],
      comptes: 1,
      secteurs: 100,
      services: 100,
      mensuelle: 0,
      trimestrielle: 0,
      semestrielle: 0,
      anuelle: 1,
      activated: true,
      archivated: false,
      status: 1,
      linearColor: "#1e293b",
      created_at: "2023-05-27 22:40:39",
      updated_at: "2023-06-04 05:08:47",
    },
    // {
    //   id: 4,
    //   name: "Accompagnement pour prospection orientée",
    //   name_en: "Support for targeted prospecting",
    //   name_ar: "دعم التنقيب المستهدف",
    //   price: 0,
    //   duration: "",
    //   featured: false,
    //   mainFeature: "",
    //   mainFeature_en: "",
    //   mainFeature_ar: "",
    //   note: "",
    //   features: [],
    //   features_en: [],
    //   features_ar: [],
    //   comptes: 1,
    //   secteurs: 100,
    //   services: 100,
    //   mensuelle: 0,
    //   trimestrielle: 0,
    //   semestrielle: 0,
    //   anuelle: 1,
    //   activated: true,
    //   archivated: false,
    //   status: 1,
    //   linearColor: "#1e293b",
    //   created_at: "2023-05-27 22:40:39",
    //   updated_at: "2023-06-04 05:08:47",
    // },
  ]);

  const [page, setPage] = useState({
    index: 1,
    limit: 3,
    totalItems: 0,
  });

  const [pageProducts, setPageProducts] = useState({
    index: 1,
    limit: 3,
    totalItems: 0,
  });

  useEffect(() => {
    if (openSearch) {
      setSearch(initSearch);
      if (initSearch != "") {
        initRechercher(initSearch);
      }
    }
  }, [initSearch, openSearch]);

  const handleCloseAdd = () => {
    setSearch("");
    setInitSearch("");
    setOpenSearch(false);
  };
  let navigate = useNavigate();
  const [annonces, setAnnonces] = useState([]);
  const [products, setProducts] = useState([]);
  const [services, setServices] = useState([]);

  const { mutate: mutateAnnonces, isLoading: isLoadingAnnonces } = useMutation(
    (filters) => getAnnonces({ ...filters, home: true }),
    {
      async onSuccess(data) {
        console.log(data);
        if (data && data.data) {
          setAnnonces(
            data.data.map((el) => {
              return {
                id: el.annonce_id,
                imageSrc: BASE_URL_Image + el.image,
                title: el.titre,
                description: el.description,
                locationText: el.localisation,
                entrepriseText: el.entreprise_name,
                entrepriseImage: BASE_URL_Image + el.entreprise_image,
                entreprise_id: el.entreprise_id,
                groupeText: el.groupe_name,
                groupeImage: BASE_URL_Image + el.groupe_image,
                groupe_id: el.groupe_id,
                secteur: el.secteur_name,
                secteur_id: el.secteur_id,
                fav: el.fav && parseInt(el.fav) === 1,
              };
            })
          );
          setPage((oldValue) => {
            return { ...oldValue, totalItems: parseInt(data.total) };
          });
        } else {
        }
      },
      onError(error) {},
    }
  );

  const { mutate: mutateProducts, isLoading: isLoadingProducts } = useMutation(
    (filters) => getServices({ ...filters, home: true }),
    {
      async onSuccess(data) {
        console.log(data);
        if (data && data.data) {
          setProducts(
            data.data.map((el) => {
              return {
                id: el.service_id,
                imageSrc: BASE_URL_Image + el.image,
                title: el.titre,
                description: el.description,
                locationText: el.localisation,
                entrepriseText: el.entreprise_name,
                entrepriseImage: BASE_URL_Image + el.entreprise_image,
                entreprise_id: el.entreprise_id,
                groupeText: el.groupe_name,
                groupeImage: BASE_URL_Image + el.groupe_image,
                groupe_id: el.groupe_id,
                secteur: el.secteur_name,
                secteur_id: el.secteur_id,
                fav: el.fav ? parseInt(el.fav) === 1 : false,
              };
            })
          );
          setPageProducts((oldValue) => {
            return { ...oldValue, totalItems: parseInt(data.total) };
          });
        } else {
        }
      },
      onError(error) {},
    }
  );
  const initRechercher = (searchFor) => {
    mutateAnnonces({ filter: searchFor, limit: page.limit, offset: (page.index - 1) * page.limit });
    mutateProducts({ filter: searchFor, limit: page.limit, offset: (page.index - 1) * page.limit });
    setServices([
      ...itemsEtudes
        .filter((el) => el.name.toUpperCase().includes(searchFor.toUpperCase()))
        .slice(0, 3),
    ]);
  };

  const rechercher = () => {
    mutateAnnonces({ filter: search, limit: page.limit, offset: (page.index - 1) * page.limit });
    mutateProducts({ filter: search, limit: page.limit, offset: (page.index - 1) * page.limit });
    setServices([
      ...itemsEtudes
        .filter((el) => el.name.toUpperCase().includes(search.toUpperCase()))
        .slice(0, 3),
    ]);
  };

  const seeAll = () => {
    if (search != "") {
      localStorage.setItem("searchService", search);
    }
    setSearch("");
    setInitSearch("");
  };

  const [searchKeywords, setSearchKeywords] = useState("");
  const [showLists, setShowLists] = useState(false);

  // const handleSearch = () => {
  //   if (search.trim() !== "") {
  //     setShowLists(true);
  //   }
  // };

  return (
    <>
      <Modal
        open={openSearch}
        onClose={handleCloseAdd}
        aria-labelledby="full-screen-modal"
        aria-describedby="full-screen-modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "center",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "5px",
            outline: "none",
            width: "80vw",
            height: "90vh",
          }}
        >
          <div className="w-100 p-3 d-flex align-items-center justify-content-end">
            <IconButton
              aria-label="close"
              onClick={handleCloseAdd}
              sx={(theme) => ({
                color: theme.palette.grey[500],
              })}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="w-100">
            <SearchSection
              label="Search"
              value={search}
              valueChange={(value) => setSearch(value)}
              valueFilter={rechercher}
            />
          </div>
          <div className="w-100 p-2">
            <ScrollBar
              component="div"
              style={{
                height: "calc(90vh - 150px )",
                touchAction: "none",
              }}
            >
              {isLoadingAnnonces || isLoadingProducts ? (
                <Box sx={{ width: "100%" }}>
                  <LinearProgress color="secondary" />
                </Box>
              ) : (
                <div className="w-100">
                  <div className="w-100 my-3">
                    <Divider textAlign="left" className="mb-2">
                      Produits
                    </Divider>
                    {products.length > 0 && (
                      <Row>
                        {products.map((card, index) => (
                          <Col
                            key={index}
                            xs={12}
                            md={6}
                            lg={4}
                            className="mb-5 px-2 d-flex align-items-start justify-content-center"
                          >
                            <ServiceItem initCard={card}></ServiceItem>
                          </Col>
                        ))}
                        <Col xs={12} className="mb-2">
                          <Divider textAlign="right">
                            <MDTypography
                              variant="h6"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                seeAll();
                                navigate("/products");
                              }}
                            >
                              Voir tout ({pageProducts.totalItems})
                            </MDTypography>
                          </Divider>
                        </Col>
                      </Row>
                    )}
                    {pageProducts.totalItems == 0 && (
                      <Col xs={12} className="d-flex justify-content-center align-items-center">
                        <MDTypography variant="h6">Aucun produit</MDTypography>
                      </Col>
                    )}
                  </div>

                  <div className="w-100 my-3">
                    <Divider textAlign="left" className="mb-2">
                      Annonces
                    </Divider>
                    {annonces.length > 0 && (
                      <Row>
                        {annonces.map((card, index) => (
                          <Col
                            key={index}
                            xs={12}
                            md={6}
                            lg={4}
                            className="mb-5 px-2 d-flex align-items-start justify-content-center"
                          >
                            <AnnonceItem initCard={card}></AnnonceItem>
                          </Col>
                        ))}
                        <Col xs={12} className="mb-2">
                          <Divider textAlign="right">
                            <MDTypography
                              variant="h6"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                seeAll();
                                navigate("/annonces");
                              }}
                            >
                              Voir tout ({page.totalItems})
                            </MDTypography>
                          </Divider>
                        </Col>
                      </Row>
                    )}
                    {page.totalItems == 0 && (
                      <Col xs={12} className="d-flex justify-content-center align-items-center">
                        <MDTypography variant="h6">Aucune annonce</MDTypography>
                      </Col>
                    )}
                  </div>

                  <div className="w-100 my-3">
                    <Divider textAlign="left" className="mb-2">
                      Services
                    </Divider>
                    {services.length > 0 && (
                      <Row>
                        {services.map((card, index) => (
                          <Col
                            key={index}
                            xs={12}
                            md={6}
                            lg={4}
                            className="mb-5 px-2 d-flex align-items-start justify-content-center"
                          >
                            <AbonnementItem
                              plan={card}
                              index={index}
                              handleOpenAdd={() => {}}
                            ></AbonnementItem>
                          </Col>
                        ))}
                        <Col xs={12} className="mb-2">
                          <Divider textAlign="right">
                            <MDTypography
                              variant="h6"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                seeAll();
                                navigate("/services");
                              }}
                            >
                              Voir tout ({services.length})
                            </MDTypography>
                          </Divider>
                        </Col>
                      </Row>
                    )}
                    {services.length == 0 && (
                      <Col xs={12} className="d-flex justify-content-center align-items-center">
                        <MDTypography variant="h6">Aucun service</MDTypography>
                      </Col>
                    )}
                  </div>
                </div>
              )}
            </ScrollBar>
          </div>
        </div>
      </Modal>
    </>
  );
};

LandingSearch.propTypes = {
  isLoading: PropTypes.bool,
};

export default LandingSearch;
