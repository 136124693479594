import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Box, ButtonBase, LinearProgress } from "@mui/material";

import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { sendMessage } from "services/messagesService";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const ServiceNewMessage = ({
  openAdd,
  setOpenAdd,
  type,
  concerned_id,
  responded_by,
  refreshList,
}) => {
  const theme = useTheme();
  const lang = useSelector((state) => state.customization.lang);
  const [subject, setSubject] = useState("");
  const [Message, setMessage] = useState("");

  useEffect(() => {
    console.log({
      type: type,
      concerned_id: concerned_id,
      responded_by: responded_by,
      subject: subject,
      message: Message,
    });
  }, []);

  const handleCloseAdd = () => {
    setSubject("");
    setMessage("");
    setOpenAdd(false);
  };

  const { mutate: mutateNewMessage, isLoading: isLoadingNewMessage } = useMutation(
    () =>
      sendMessage({
        type: type,
        concerned_id: concerned_id,
        responded_by: responded_by,
        subject: subject,
        message: Message,
      }),
    {
      async onSuccess(data) {
        console.log(data);
        if (data && data.data) {
          notify_success("Ajouter avec succès");
          refreshList();
          handleCloseAdd();
        } else {
          notify("Une erreur a été produit");
        }
      },
      onError(error) {},
    }
  );

  const notify = (message) => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 3000,
      fontSize: "12px",
    });
  };

  const notify_success = (message) => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 3000,
    });
  };

  const sendNewMessage = () => {
    console.log({
      type: type,
      concerned_id: concerned_id,
      responded_by: responded_by,
      subject: subject,
      message: Message,
    });
    if (subject === "") {
      notify("Vous devez entrez un sujet");
    }
    // else if (selectedMessage === "") {
    //   notify("Vous devez selectionner un secteur");
    // }
    else if (Message === "") {
      notify("Vous devez entrez un message");
    } else {
      mutateNewMessage();
      // setOpenAdd(false);
    }
  };

  return (
    <>
      <Modal
        show={openAdd}
        onHide={handleCloseAdd}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: "0.85em" }}>
            {lang === "fr" ? "Nouveau Message" : lang === "en" ? "New message" : "رسالة جديدة"}
            {isLoadingNewMessage ? (
              <div className="w-100">
                <Box sx={{ width: "100%" }}>
                  <LinearProgress color="secondary" />
                </Box>
              </div>
            ) : (
              <></>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ width: "100%", direction: lang === "ar" ? "rtl" : "ltr" }}>
            <div className="w-100 d-flex justify-content-center align-items-center">
              <div style={{ maxWidth: "500px" }}>
                <div className="flex-auto">
                  <div className="relative w-full mb-3 mt-8">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="full-name"
                    >
                      {lang === "fr" ? "Sujet" : lang === "en" ? "Subject" : "الموضوع"}
                    </label>
                    <input
                      disabled={isLoadingNewMessage}
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder={
                        lang === "fr" ? "Sujet..." : lang === "en" ? "Subject..." : "الموضوع..."
                      }
                      onChange={(e) => setSubject(e.currentTarget.value)}
                    />
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="message"
                    >
                      {lang === "fr" ? "Message" : lang === "en" ? "Message" : "رسالتك"}
                    </label>
                    <textarea
                      disabled={isLoadingNewMessage}
                      rows="18"
                      cols="80"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                      placeholder={
                        lang === "fr"
                          ? "votre message..."
                          : lang === "en"
                          ? "your message..."
                          : "رسالتك..."
                      }
                      onChange={(e) => setMessage(e.currentTarget.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ direction: lang === "ar" ? "rtl" : "ltr" }}>
          <>
            <ButtonBase
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.button,
                transition: "all .2s ease-in-out",
                background: theme.palette.grey[200],
                color: theme.palette.text.dark,
                "&:hover": {
                  background: theme.palette.grey[900],
                  color: "white !important",
                },
                width: "max-content",
                overflow: "hidden",
              }}
              className="py-2 px-5"
              disabled={isLoadingNewMessage}
              onClick={handleCloseAdd}
            >
              {lang === "fr" ? "Annuler" : lang === "en" ? "Cancel" : "إلغاء"}
            </ButtonBase>
            <ButtonBase
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.button,
                transition: "all .2s ease-in-out",
                background: theme.palette.secondary.light,
                color: theme.palette.secondary.dark,
                "&:hover": {
                  background: theme.palette.secondary.dark,
                  color: theme.palette.secondary.light,
                },
                height: "100%",
                overflow: "hidden",
              }}
              className="py-2 px-5"
              disabled={isLoadingNewMessage}
              onClick={sendNewMessage}
            >
              {lang === "fr" ? "Envoyer" : lang === "en" ? "Send" : "إرسال"}
            </ButtonBase>
          </>
        </Modal.Footer>
      </Modal>
    </>
  );
};

ServiceNewMessage.propTypes = {
  isLoading: PropTypes.bool,
};

export default ServiceNewMessage;
