import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

import { Dropdown } from "react-bootstrap";
import MDBadge from "components/MDBadge";
export default function data(props) {
  const avatars = (members) =>
    members.map(([image, name]) => (
      <Tooltip key={name} title={name} placeholder="bottom">
        <img
          src={image}
          style={{color :"#1A73E8" , maxWidth : "50px" , height : "auto"}}
          alt="name"
          size="md"
          sx={{
            border: ({ borders: { borderWidth }, palette: { white } }) =>
              `${borderWidth[2]} solid ${white.main}`,
            cursor: "pointer",
            position: "relative",

            "&:not(:first-of-type)": {
              ml: -1.25,
            },

            "&:hover, &:focus": {
              zIndex: "10",
            },
          }}
        />
      </Tooltip>
    ));

    const openModal = () => {
      props.handleOpen() ;
    }

    const handleUpdate = (userInfo)=> {
      props.handleUpdate(userInfo) ; 
    }

    const openModalValidation = (publireportage) => {
      props.handleOpenValidation(publireportage) ;
    }

    const onUpdatePartenaire = (partenaire) => {
      props.handleOpenUpdatePartenaire(partenaire) ; 
    }

    const Company = ({ image, name }) => (
      <MDBox display="flex" alignItems="center" lineHeight={1}>
        <MDAvatar src={image} name={name} size="sm" />
        <MDTypography variant="button" fontWeight="medium" ml={1} lineHeight={1}>
          {name}
        </MDTypography>
      </MDBox>
    );

  return {
    columns: [
      { Header: "Image", accessor: "image", width: "20%", align: "left" },
      { Header: "Nom", accessor: "name", width: "20%", align: "left" },
      { Header: "Status", accessor: "status", align: "center" },
      { Header: "", accessor: "action", align: "center" },
    ],

    rows : props.items.map(el => {
      return {
        image : (
          <MDBox display="flex" py={1}>
            {avatars([[el.imageSrc , ""]])}
          </MDBox>
        ),
        name: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
          {el.title}
          </MDTypography>
        ),
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent={el.status ? "activé" : "desavtivé"} color={el.status ? "success" : "error"} variant="gradient" size="sm" />
          </MDBox>
        ),
        action : (
          
        <MDBox color="text" px={2}>
        
        
    <Dropdown align="start">
      <Dropdown.Toggle variant="link" id="icon-dropdown">
        
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item  onClick={()=> {
          onUpdatePartenaire(el) ;
        }}>Modifier</Dropdown.Item>
        <Dropdown.Item  onClick={()=> {
          handleUpdate({id : el.id , status : 1 })
        }} disabled={el.status}>Activer</Dropdown.Item>
        <Dropdown.Item  onClick={()=> {
          handleUpdate({id : el.id , status : 0 })
        }} disabled={!el.status}>Desavtiver</Dropdown.Item>
        <Dropdown.Item  onClick={()=> {
          handleUpdate({id : el.id , archive : 1 })
        }} disabled={el.archive}>Archiver</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
      </MDBox>
        )
      }
    }) ,

  };
}
