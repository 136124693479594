import React, { useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import { ReactComponent as PriceIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { ReactComponent as StarIcon } from "feather-icons/dist/icons/star.svg";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";
import { useEffect } from "react";

import { ReactComponent as UserIcon } from "feather-icons/dist/icons/user.svg";
import { ReactComponent as TagIcon } from "feather-icons/dist/icons/tag.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../../../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../../images/svg-decorator-blob-3.svg";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const HeadingWithControl = tw.div`flex flex-col items-center sm:items-stretch sm:flex-row justify-between`;
const Heading = tw(SectionHeading)``;
const Controls = tw.div`flex items-center`;
const ControlButton = styled(PrimaryButtonBase)`
${tw`mt-4 sm:mt-0 first:ml-0 ml-6 rounded-full p-2  border-none bg-gray-500 text-gray-100 border-none `}
svg {
    ${tw`w-6 h-6`}
}

`;
const PrevButton = tw(ControlButton)``;
const NextButton = tw(ControlButton)``;

const CardSlider = styled(Slider)`
  ${tw`mt-16`}
  .slick-track { 
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1`}
  }
`;



const HeadingInfoContainer = tw.div`flex flex-col items-center`;

const Card = tw.div`lg:mx-4 xl:mx-8 max-w-sm flex flex-col h-full`;
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`bg-cover bg-center h-80 lg:h-64 rounded rounded-b-none`
]);

const Details = tw.div`p-6 rounded border-2  border-solid border-primary-100 flex-1 flex flex-col items-center text-center lg:block lg:text-left`;
const MetaContainer = tw.div`flex items-center`;
const Meta = styled.div`
  ${tw`text-secondary-100 font-medium text-sm flex items-center leading-none mr-6 last:mr-0`}
  svg {
    ${tw`w-4 h-4 mr-1`}
  }
`;

const Title = tw.h5`mt-4 leading-snug font-bold text-lg`;
const Description = tw.p`mt-2 text-sm text-secondary-100`;
const Link = styled(PrimaryButtonBase).attrs({as: "a"})`
  ${tw`inline-block mt-4 text-sm font-semibold`}
`

const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`-z-10 absolute bottom-0 right-0 w-48 h-48 transform translate-x-40 -translate-y-8 opacity-25`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`-z-10 absolute top-0 left-0 w-48 h-48 transform -translate-x-32 translate-y-full opacity-25`;



export default ({items}) => {
  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [sliderRef, setSliderRef] = useState(null);
  let navigate = useNavigate();
  useEffect(()=> {
    const intervalId = setInterval(() => {
        if(sliderRef) {
            sliderRef?.slickNext() ;
        }
    } , 3000) ; 
    return () => {
        clearInterval(intervalId);
    } ;
  } , [sliderRef]) ;
  const sliderSettings = {
    arrows: false,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: items.length > 1 ? 2 : 1,
        }
      },

      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  };

  /* Change this according to your needs */
  const cards = [
    {
      imageSrc:
        "https://www.joodek.com/storage/2113/6096c5b723ba7.png",
      entreprise: "Ooredoo",
      secteur: "Telecomunication",
      title: "Ooredoo",
      description: "Ooredoo précédemment connu sous le nom Nedjma est le troisième opérateur de téléphonie mobile en Algérie. C'est la marque commerciale mobile de Wataniya Télécom Algérie. Le parc abonnés de Ooredoo s'est établi à plus de 12,298 millions.",
      url: "https://reddit.com"
    },
    {
      imageSrc:
        "https://i0.wp.com/zonebitcoin.co/wp-content/uploads/2023/01/punchword.001.jpeg?resize=800%2C500&ssl=1",
      entreprise: "Owais Khan",
      secteur: "Technologie et numérique",
      title: "Punchword",
      description: "Punchword est un média social Web 3 qui redonne le pouvoir aux créateurs.",
      url: "https://timerse.com"
    },
    {
      imageSrc:
        "https://www.joodek.com/storage/2113/6096c5b723ba7.png",
      entreprise: "Ooredoo",
      secteur: "Telecomunication",
      title: "Ooredoo",
      description: "Ooredoo précédemment connu sous le nom Nedjma est le troisième opérateur de téléphonie mobile en Algérie. C'est la marque commerciale mobile de Wataniya Télécom Algérie. Le parc abonnés de Ooredoo s'est établi à plus de 12,298 millions.",
      url: "https://reddit.com"
    },
    {
      imageSrc:
        "https://i0.wp.com/zonebitcoin.co/wp-content/uploads/2023/01/punchword.001.jpeg?resize=800%2C500&ssl=1",
      entreprise: "Owais Khan",
      secteur: "Technologie et numérique",
      title: "Punchword",
      description: "Punchword est un média social Web 3 qui redonne le pouvoir aux créateurs.",
      url: "https://timerse.com"
    },
  ];
  return (
    <Container>
      <Content>
        <HeadingWithControl>
          <Heading>Entreprise en vitrine</Heading>
          <Controls>
            <PrevButton onClick={sliderRef?.slickPrev}><ChevronLeftIcon/></PrevButton>
            <NextButton onClick={sliderRef?.slickNext}><ChevronRightIcon/></NextButton>
          </Controls>
        </HeadingWithControl>
        <CardSlider ref={setSliderRef} {...sliderSettings } slidesToShow={items.length < 1 ? 1 : items.length > 3 ? 3 : items.length }>
          {items.map((card, index) => (
            <Card key={card.id} style={{minWidth : "250px" , maxWidth : "250px"}}  >
            <Details style={{padding : 0, cursor : "pointer" , overflow : "hidden" , border: "none" ,  background: "white" , borderRadius: "20px" ,  boxShadow: "-3px 4px 8px 0px #999da3" }}>
            <Image imageSrc={card.imageSrc} style={{width : "100%"}}/>
            <div style={{padding : "20px"}}>
              <MetaContainer>
                <Meta>
                  <TagIcon />
                  <div>{card.secteur}</div>
                </Meta>
              </MetaContainer>
              <Title style={{height : "50px" , overflow : "hidden" , wordWrap : "break-word" , textOverflow : "ellipsis"}}>{card.title}</Title>
              <Description style={{height : "90px" , overflow : "hidden" , wordWrap : "break-word" , textOverflow : "ellipsis"}}>{card.description}</Description>
              <Link style={{cursor : "pointer"}} onClick={()=> {navigate("entreprise/"+card.id)}}>Voir l'entreprise</Link>

            </div>
            </Details>
          </Card>
          ))}
        </CardSlider>
      </Content>
    </Container>
  );
};
