import React, { useState } from "react";

// components

import Navbar from "../components/Navbars/AuthNavbar";
import TopNavbar from "../components/Nav/TopNavbar";

import Footer from "../components/footers/Footer";
import HeaderBannieresSlider from "../OurPages/OurComponents/Home/SliderBannieres.js";
import LandingHeader from "./LandingHeader";
import LandingServices from "./LandingServices";
import LandingALaUne from "./LandingALaUne";
import LandingQuiSommeNous from "./LandingQuiSommeNous";
import LandingProductsPresentation from "./LandingProductsPresentation";
import LandingPartenaires from "./LandingPartenaires";
import LandingContactezNous from "./LandingContactezNous";
import LandingContactezNousForm from "./LandingContactezNousForm";
import { BASE_URL_Image } from "services/server";
import LandingBuyPubSpace from "./LandingBuyPubSpace";

export default function Landing() {
  const [publireportages, setpublireportages] = useState([
    {
      id: 1,
      imageSrc: BASE_URL_Image + "/storage/pubs/bourse_dubai2.jpg",
      title: "",
      description: "",
      entreprise_id: 1,
      link: "https://www.dfm.ae/",
    },
    {
      id: 2,
      imageSrc: BASE_URL_Image + "/storage/pubs/bourse_londre2.jpg",
      title: "",
      description: "",
      entreprise_id: null,
      link: "https://www.londonstockexchange.com/",
    },
    {
      id: 1,
      imageSrc: BASE_URL_Image + "/storage/pubs/bourse_new_york2.jpg",
      title: "",
      description: "",
      entreprise_id: 1,
      link: "https://www.nyse.com/index",
    },
    // {
    //   id: 3,
    //   imageSrc: BASE_URL_Image + "/storage/pubs/pub3.jpg",
    //   title: "",
    //   description: "",
    //   entreprise_id: 1,
    //   link: "https://www.conseiller.ca/conseiller-pme/nouvelles-pme/6-etapes-a-suivre-pour-exporter-avec-succes/",
    // },
    // {
    //   id: 4,
    //   imageSrc: BASE_URL_Image + "/storage/pubs/pub1.png",
    //   title: "",
    //   description: "",
    //   entreprise_id: null,
    //   link: "https://www.entreprises.cci-paris-idf.fr/web/international/newsletter-j-exporte-",
    // },
  ]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    function handleScroll() {
      const position = window.pageYOffset;
      if (position > 70) {
        if (document.getElementById("header_smart") != null)
          document.getElementById("header_smart").classList.add("header_smart_solution");
      } else {
        if (document.getElementById("header_smart") != null)
          document.getElementById("header_smart").classList.remove("header_smart_solution");
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <TopNavbar></TopNavbar>
      <main>
        <div id="home">
          <LandingHeader publireportages={publireportages}></LandingHeader>
          <section
            className="pb-20 bg-blueGray-200 -mt-24"
            style={{ marginTop: "clamp(-200px, -4vw, -200px)" }}
          >
            <HeaderBannieresSlider></HeaderBannieresSlider>
            <LandingBuyPubSpace></LandingBuyPubSpace>
          </section>
          <section className="pb-20 bg-blueGray-200 ">
            <div className="container mx-auto px-4">
              <LandingServices></LandingServices>
              <LandingALaUne></LandingALaUne>
            </div>
          </section>
        </div>

        <div id="aboutUs">
          <section className="pb-20 relative block bg-blueGray-800">
            <div
              className="bottom-auto  right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
              style={{ transform: "translateZ(0)", top: "1px" }}
            >
              <svg
                className="absolute bottom-0 overflow-hidden"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="text-blueGray-800 fill-current"
                  points="2560 0 2560 100 0 100"
                ></polygon>
              </svg>
            </div>
            <div className="container mx-auto px-4 pt-4">
              <LandingQuiSommeNous></LandingQuiSommeNous>
            </div>
          </section>
        </div>

        <div id="products">
          <section className="pb-20 relative block">
            <div
              className="bottom-auto left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
              style={{ transform: "translateZ(0)", top: "1px" }}
            >
              <svg
                className="absolute bottom-0 overflow-hidden"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className=" fill-current"
                  color="#f0f2f5"
                  points="2560 0 2560 100 0 100"
                ></polygon>
              </svg>
            </div>
            <div className="container mx-auto px-4">
              <LandingProductsPresentation></LandingProductsPresentation>
            </div>
          </section>
        </div>

        <div id="partenaires">
          <section className="pt-20 pb-48">
            <LandingPartenaires></LandingPartenaires>
          </section>
        </div>
        <div id="contactUs">
          <section className="pb-20 relative block bg-blueGray-800">
            <div
              className="bottom-auto left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
              style={{ transform: "translateZ(0)", top: "1px" }}
            >
              <svg
                className="absolute bottom-0 overflow-hidden"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="text-blueGray-800 fill-current"
                  points="2560 0 2560 100 0 100"
                ></polygon>
              </svg>
            </div>
            <LandingContactezNous></LandingContactezNous>
          </section>
          <section className="relative block py-24 lg:pt-0 bg-blueGray-800">
            <div className="container mx-auto px-4">
              <div className="flex flex-wrap justify-center">
                <div className="w-full lg:w-6/12 px-0 mt-3">
                  <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200">
                    <LandingContactezNousForm></LandingContactezNousForm>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
      <Footer />
    </>
  );
}
