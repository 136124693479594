/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";

// Data
import data from "layoutsGroupe/EntrepriseList/components/List/data";
import { Box, Grid } from "@mui/material";
import  Button  from "react-bootstrap/Button";
import Modal from 'react-bootstrap/Modal';
import MDButton from "components/MDButton";
import tw from "twin.macro";
import styled from "styled-components";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import MDInput from "components/MDInput";
import { useNavigate } from "react-router-dom";

const CardPub = tw.div`h-full flex! flex-col sm:border max-w-sm  relative focus:outline-none bg-white m-0`;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full h-56 sm:h-64 bg-cover bg-center `
]);

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-2xl font-bold`;

const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4`;

const PrimaryButton = tw(PrimaryButtonBase)`mt-auto sm:text-lg rounded-none w-full  py-3 sm:py-6 border-none`;


function List() {
  const navigate = useNavigate();
  const [menu, setMenu] = useState(null);
  const [publireportage, setPublireportage] = useState(null);
  const [items, setItems] = useState([
    {
      id : 1 , 
      image : "https://www.parkmallsetif-dz.com/imgs/uno-1532274417.jpg" , 
      name : "UNO" ,
      company : [
        {
          image : "https://scontent.fczl2-1.fna.fbcdn.net/v/t39.30808-6/237089574_1869498609896402_4526829638075820959_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=09cbfe&_nc_ohc=U9UQNLobG7cAX_9QiWJ&_nc_ht=scontent.fczl2-1.fna&oh=00_AfDx60vz9j0sf50sLxh9t26qMkE_p61E0aKfWHaau-pOYQ&oe=645043E8" , 
          name : "Cevital" ,
        } 
      ],
      services : 5 , 
      usedServices : 2 ,
      secteurs : 1 , 
      usedSecteurs : 1 ,
      publireportages : 5 , 
      usedPublireportages : 1 ,
    } , 
    
    {
      id : 1 , 
      image : "https://www.emploitic.com/attachments/company_logo/logo_4549883.jpg" , 
      name : "Sierra Cevital" ,
      company : [
        {
          image : "https://scontent.fczl2-1.fna.fbcdn.net/v/t39.30808-6/237089574_1869498609896402_4526829638075820959_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=09cbfe&_nc_ohc=U9UQNLobG7cAX_9QiWJ&_nc_ht=scontent.fczl2-1.fna&oh=00_AfDx60vz9j0sf50sLxh9t26qMkE_p61E0aKfWHaau-pOYQ&oe=645043E8" , 
          name : "Cevital" ,
        } ,
      ],
      services : 5 , 
      usedServices : 1 ,
      secteurs : 1 , 
      usedSecteurs : 1 , 
      publireportages : 5 , 
      usedPublireportages : 1 ,
    } ,
     
    {
      id : 1 ,
      image : "https://oxxobatiment.fr/wp-content/uploads/2021/05/Logo-oxxo-2.png" , 
      name : "OXXO" ,
      company : [
        {
          image : "https://scontent.fczl2-1.fna.fbcdn.net/v/t39.30808-6/237089574_1869498609896402_4526829638075820959_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=09cbfe&_nc_ohc=U9UQNLobG7cAX_9QiWJ&_nc_ht=scontent.fczl2-1.fna&oh=00_AfDx60vz9j0sf50sLxh9t26qMkE_p61E0aKfWHaau-pOYQ&oe=645043E8" , 
          name : "Cevital" ,
        } ,
      ],
      services : 2 , 
      usedServices : 1 ,
      secteurs : 1 , 
      usedSecteurs : 1 , 
      publireportages : 5 , 
      usedPublireportages : 1 ,
    }
  ]);
  const [openAdd, setOpenAdd] = useState(false);
  const handleOpenAdd = (publireportage) => {
    setPublireportage(publireportage) ;
    setOpenAdd(true);
    console.log(publireportage);
  };
  const handleCloseAdd = () => {
    setOpenAdd(false);
  };


  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);

  
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    // setOpen(true);
    navigate("/entrepriseGroupe") ;
    
  };
  const handleClose = () => {
    setOpen(false);
  };

  
  const [openValidation, setOpenValidation] = useState(false);
  const handleOpenValidation = (publireportage) => {
    setPublireportage(publireportage) ;
    setOpenValidation(true);
    console.log(publireportage);
  };
  const handleCloseValidation = () => {
    setOpenValidation(false);
  };

  const { columns, rows } = data({handleOpen , handleOpenValidation , items : items});

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={closeMenu}>Action</MenuItem>
      <MenuItem onClick={closeMenu}>Another action</MenuItem>
      <MenuItem onClick={closeMenu}>Something else</MenuItem>
    </Menu>
  );

  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox style={{width : "100%"}}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            
          
          <MDTypography variant="h6" gutterBottom>
            Liste des entreprises
          </MDTypography>
          <MDBox display="flex" alignItems="center" lineHeight={0}>
            <Icon
              sx={{
                fontWeight: "bold",
                color: ({ palette: { info } }) => info.main,
                mt: -0.5,
              }}
            >
              apartment
            </Icon>
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;<strong>{items != null ? items.length : 0} {items != null && items.length > 1 ? "Entreprises": "Entreprise"}</strong> en total
            </MDTypography>
          </MDBox>
          
            </Grid>
          <Grid item xs={12} md={6}>
          
          
          <MDBox>
            <Grid container spacing={1}>
              <Grid item xs={10}>
                <MDInput style={{width : "100%"}} label="Recherche" />
              </Grid>
              {/* <Grid item xs={2}>
                <MDButton color="dark" style={{maxWidth : "100%" , overflow  : "hidden"}}   onClick={handleOpenAdd}  >Filter</MDButton>
              </Grid> */}
              <Grid item xs={2}>
                <MDButton color="info"   onClick={handleOpenAdd}  style={{maxWidth : "100%" , overflow  : "hidden"}}  >Ajouter</MDButton>
              </Grid>
            </Grid>
            </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <MDBox>
        <DataTable
          table={{ columns, rows }}
          showTotalEntries={false}
          isSorted={false}
          noEndBorder
          entriesPerPage={false}
        />
      </MDBox>
      <Modal
      show={open}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        Annulation d'un publireportage
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <MDBox
          mx={2}
          my={1}
          py={3}
          px={2}
          variant="gradient"
          bgColor="error"
          borderRadius="lg"
          coloredShadow="info"
        >
          <MDTypography variant="h6" color="white">
          Est-ce-que vous voulez vraiment annuler ce publireportage ?
          </MDTypography>
        </MDBox>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button onClick={handleClose}>Confirmer</Button> */}
        <MDButton color="error"  onClick={handleClose}>Confirmer</MDButton>
      </Modal.Footer>
    </Modal>

      <Modal
      show={openValidation}
      onHide={handleCloseValidation}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Validation d'un publireportage
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <MDBox
          mx={2}
          my={1}
          py={3}
          px={2}
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
        >
          <MDTypography variant="h6" color="white">
          Est-ce-que vous voulez vraiment valider ce publireportage ?
          </MDTypography>
        </MDBox>
        <div style={{width :"100%" , display : "flex" , justifyContent : "center" , alignItems : "center"}}>
        <CardPub style={{maxWidth : "300px" , width : "100%" , boxShadow : "0 0 5px 1px lightgrey"}}>
            <CardImage imageSrc={publireportage != null ? publireportage.imageSrc : null} />
            <TextInfo style={{maxHeight : "200px"}}>
              <TitleReviewContainer>
                <Title>{publireportage != null ? publireportage.title : ""}</Title>
                {/* <RatingsInfo>
                  <StarIcon />
                  <Rating>{card.rating}</Rating>
                </RatingsInfo> */}
              </TitleReviewContainer>
              {/* <SecondaryInfoContainer>
                <IconWithText>
                  <IconContainer>
                    <LocationIcon />
                  </IconContainer>
                  <Text>{card.locationText}</Text>
                </IconWithText>
                <IconWithText>
                  <IconContainer>
                    <PriceIcon />
                  </IconContainer>
                  <Text>{card.pricingText}</Text>
                </IconWithText>
              </SecondaryInfoContainer> */}
              <Description>{publireportage != null ? publireportage.description : ""}</Description>
            </TextInfo>
            
            <PrimaryButton>Voir l'entreprise</PrimaryButton>
          </CardPub>

        </div>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button onClick={handleCloseValidation}>Confirmer</Button> */}
        <MDButton color="info"  onClick={handleCloseValidation}>Confirmer</MDButton>
      </Modal.Footer>
    </Modal>
    

    <Modal
      show={openAdd}
      onHide={handleCloseAdd}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Ajouter une entreprise au groupe
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{width :"100%" , display : "flex" , justifyContent : "center" , alignItems : "center"}}>
        <MDBox style={{maxWidth : "300px" , width : "100%" }}>
            <div style={{width : "150px"  , height : "150px" , border : "3px dashed  #1A73E8" , margin : "20px auto" , display : "flex" , justifyContent : "center" ,alignItems : "center" , cursor : "pointer" }}>
              <Icon fontSize="large" color="info">add_a_photo</Icon>
            </div>
            <MDInput style={{width : "100%" , marginTop : "20px"}} label="Nom de l'entreprise" />
            <MDInput style={{width : "100%" , marginTop : "20px"}} label="Email" />
            <MDInput style={{width : "100%" , marginTop : "20px"}} label="Mot de passe" />
            <MDInput style={{width : "100%" , marginTop : "20px"}} label="Confirmation du Mot de passe" />
       </MDBox>

        </div>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button onClick={handleCloseAdd}>Confirmer</Button> */}
        <MDButton color="info"  onClick={handleCloseAdd}>Confirmer</MDButton>
      </Modal.Footer>
    </Modal>
    </Card>
  );
}

export default List;
