import React, { useEffect } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import ContactDetails from "./ContactDetails";
import ContactUsForm from "./ContactUsForm";

const Address = tw.span`leading-relaxed`;
const AddressLine = tw.span`block`;
const Email = tw.span`text-sm mt-6 block text-gray-500`;
const Phone = tw.span`text-sm mt-0 block text-gray-500`;

export default () => {
  useEffect(() => {
    if(document.getElementById("header_smart") != null)
    document.getElementById("header_smart").classList.add("header_smart_solution") ;
    return () => {
      if(document.getElementById("header_smart") != null)
      document.getElementById("header_smart").classList.remove("header_smart_solution") ;
    }}) ;
    
  
  return (
    <AnimationRevealPage>
      <ContactUsForm />
    </AnimationRevealPage>
  );
};
