import { useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { useSelector } from "react-redux";

import MoreVertIcon from "@mui/icons-material/MoreVert";

const DropdownUsersMenu = ({ el, updateAction }) => {
  const theme = useTheme();
  const lang = useSelector((state) => state.customization.lang);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton aria-label="settings" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            updateAction({ id: el.id, bloque: 1 });
            handleClose();
          }}
          disabled={el.bloque == 1}
        >
          {lang === "fr" ? "Suspendre " : lang === "en" ? "Suspend" : "توقيف"}
        </MenuItem>

        <MenuItem
          onClick={() => {
            updateAction({ id: el.id, bloque: 0 });
            handleClose();
          }}
          disabled={el.bloque == 0}
        >
          {lang === "fr" ? "Réactiver " : lang === "en" ? "Reactivate" : "اعادة تفعيل"}
        </MenuItem>
      </Menu>
    </>
  );
};

export default DropdownUsersMenu;
