import { useEffect, useRef, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";

// Data
import data from "layouts/GroupesList/components/List/data";
import { Box, Grid } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import MDButton from "components/MDButton";
import tw from "twin.macro";
import MDInput from "components/MDInput";

import { useMutation } from "@tanstack/react-query";
import { getGroupes } from "services/adminService";
import { BASE_URL_Image } from "services/server";
import { Pagination } from "react-bootstrap";
import { toast } from "react-toastify";
import { uploaderGroupe } from "services/adminService";
import { updateGroupe } from "services/adminService";
import { useNavigate } from "react-router-dom";

const CardPub = tw.div`h-full flex! flex-col sm:border max-w-sm  relative focus:outline-none bg-white m-0`;

function List() {
  const navigate = useNavigate();
  const [menu, setMenu] = useState(null);
  const [publireportage, setPublireportage] = useState(null);
  const [onUpdateGroupe, setOnUpdateGroupe] = useState(null);
  const [search, setSearch] = useState("");
  const [items, setItems] = useState([]);

  const [page, setPage] = useState({
    index: 1,
    limit: 5,
    totalItems: 0,
  });

  const [paginationItems, setPaginationItems] = useState([]);
  const { mutate, isLoading } = useMutation((userInfo) => getGroupes(userInfo), {
    async onSuccess(data) {
      console.log(data);
      if (data && data.data) {
        setItems(
          data.data.map((el) => {
            return {
              id: el.id,
              image: BASE_URL_Image + el.profile_pic,
              name: el.nom,
              entreprises: el.entreprises_array.map((it) => {
                return { ...it, image: BASE_URL_Image + it.profile_pic, name: it.nom };
              }),
              services: el.abonnement_services,
              usedServices: el.consomation_services,
              secteurs: el.abonnement_secteurs,
              usedSecteurs: el.consomation_secteurs,
              publireportages: el.abonnement_publireportages,
              usedPublireportages: el.consomation_publireportages,
              abonnement_name: el.abonnement_name,
              status: parseInt(el.status) === 0,
              abonnement_id: el.abonnement_id,
              consomation_id: el.consomation_id,
              abonnement_entreprises: el.abonnement_entreprises,
            };
          })
        );
        setPage((oldValue) => {
          return { ...oldValue, totalItems: parseInt(data.total) };
        });
        let table_items = [];
        if (parseInt(data.total) !== 0) {
          for (
            let number = 1;
            number <= parseInt(parseInt(data.total) / 5) + (parseInt(data.total) % 5 > 0 ? 1 : 0);
            number++
          ) {
            table_items.push(
              <Pagination.Item
                key={number}
                active={number === page.index}
                onClick={() => {
                  setPage((oldValue) => {
                    return { ...oldValue, index: number };
                  });
                }}
              >
                {number}
              </Pagination.Item>
            );
          }
        }
        setPaginationItems(table_items);
      } else {
      }
    },
    onError(error) {},
  });

  useEffect(() => {
    mutate({ filter: search, limit: 5, offset: (page.index - 1) * 5 });
  }, []);
  useEffect(() => {
    mutate({ filter: search, limit: 5, offset: (page.index - 1) * 5 });
  }, [page.index]);

  const rechercher = () => {
    mutate({ filter: search, limit: 5, offset: (page.index - 1) * 5 });
  };

  const [open, setOpen] = useState(false);
  const handleOpen = (id) => {
    // setOpen(true);
    navigate("/groupe/" + id);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [openAdd, setOpenAdd] = useState(false);
  const handleOpenAdd = (publireportage) => {
    setPublireportage(publireportage);
    setOpenAdd(true);
    console.log(publireportage);
  };
  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  const [nomGroupe, setNomGroupe] = useState("");
  const [pictureUrl, setPictureUrl] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    console.log(selectedFile);
    if (selectedFile) {
      let url = URL.createObjectURL(selectedFile);
      if (url) {
        console.log(url);
        setPictureUrl(url);
      }
    }
  }, [selectedFile]);

  const insertNewGroupe = () => {
    if (!selectedFile || !pictureUrl || pictureUrl == "") {
      notify("Vous devez inserer une image");
    } else if (nomGroupe == "") {
      notify("Vous devez entrez un nom");
    } else {
      mutateNewGroupe();
      // setOpenAdd(false);
    }
  };

  const modifierGroupe = (id) => {
    if (!selectedFile && pictureUrl !== "" && nomGroupe != "") {
      mutateUpdateGroupe({ id: onUpdateGroupe, nom: nomGroupe });
    } else if (nomGroupe === "") {
      notify("Vous devez entrez un nom");
    } else if (pictureUrl === "") {
      notify("Vous devez inserer une image");
    } else {
      mutateUpdateGroupe({ id: onUpdateGroupe, file: selectedFile, nom: nomGroupe });
      // setOpenAdd(false);
    }
  };
  const { mutate: mutateNewGroupe, isLoading: isLoadingNewGroupe } = useMutation(
    () => uploaderGroupe({ file: selectedFile, nom: nomGroupe }),
    {
      async onSuccess(data) {
        console.log(data);
        if (data && data.data) {
          notify_success("Ajouter avec succès");
          handleCloseAdd();
          mutate({ filter: search, limit: 5, offset: (page.index - 1) * 5 });
        } else {
          notify("Une erreur a été produit");
        }
      },
      onError(error) {},
    }
  );

  const { mutate: mutateUpdateGroupe, isLoading: isLoadingUpdateGroupe } = useMutation(
    (userInfo) => updateGroupe(userInfo),
    {
      async onSuccess(data) {
        console.log(data);
        if (data && data.data) {
          notify_success("Mettre à jour avec succès");
          if (onUpdateGroupe !== null) {
            handleCloseOnUpdateGroupe();
          } else {
            handleCloseAdd();
          }
          mutate({ filter: search, limit: 5, offset: (page.index - 1) * 5 });
        } else {
          notify("Une erreur a été produit");
        }
      },
      onError(error) {},
    }
  );

  const notify = (message) => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 3000,
      fontSize: "12px",
    });
  };

  const notify_success = (message) => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 3000,
    });
  };

  const handleOnUpdateGroupe = (Groupe) => {
    console.log(Groupe);
    setSelectedFile(null);
    setPictureUrl(Groupe.image ? Groupe.image : "");
    setNomGroupe(Groupe.title ? Groupe.title : "");
    setOnUpdateGroupe(Groupe.id ? Groupe.id : null);
    setOpenAdd(true);
  };

  const handleCloseOnUpdateGroupe = () => {
    setSelectedFile(null);
    setPictureUrl("");
    setNomGroupe("");
    setOnUpdateGroupe(null);
    setOpenAdd(false);
  };

  const { columns, rows } = data({
    handleOpen,
    handleOpenAdd,
    items: items,
    handleUpdate: mutateUpdateGroupe,
    handleOpenUpdateGroupe: handleOnUpdateGroupe,
    handleUpdateGroupe: modifierGroupe,
  });

  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox style={{ width: "100%" }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <MDTypography variant="h6" gutterBottom>
                Liste des Groupes
              </MDTypography>
              <MDBox display="flex" alignItems="center" lineHeight={0}>
                <Icon
                  sx={{
                    fontWeight: "bold",
                    color: ({ palette: { info } }) => info.main,
                    mt: -0.5,
                  }}
                >
                  account_tree
                </Icon>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;
                  <strong>
                    {page.totalItems ? page.totalItems : 0}{" "}
                    {page.totalItems && page.totalItems > 1 ? "Groupes" : "Groupe"}
                  </strong>{" "}
                  en total
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <MDBox>
                <Grid container spacing={3} className="d-flex justify-content-end">
                  <Grid item xs={12} className="d-flex justify-content-end">
                    {/* <MDButton color="info"   onClick={handleOpenAdd}  >Ajouter</MDButton> */}
                  </Grid>
                </Grid>
              </MDBox>
            </Grid>

            <div className="px-2 w-100">
              <Grid container spacing={3} className=" w-100 m-0">
                <Grid item style={{ flex: 1 }} className="px-2">
                  <MDInput
                    style={{ width: "100%" }}
                    label="Recherche"
                    onChange={(e) => setSearch(e.currentTarget.value)}
                  />
                </Grid>
                <Grid item className="d-flex justify-content-end">
                  <MDButton color="light" onClick={rechercher}>
                    Filtrer
                  </MDButton>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </MDBox>
      </MDBox>
      <MDBox>
        <DataTable
          table={{ columns, rows }}
          showTotalEntries={false}
          isSorted={false}
          noEndBorder
          entriesPerPage={false}
        />
        <div className="w-100 d-flex justify-content-center align-items-center">
          <Pagination>{paginationItems}</Pagination>
        </div>
        {page.totalItems === 0 && (
          <div className="w-100 d-flex justify-content-center align-items-center">
            <MDTypography variant="h6" color="light">
              Aucun Groupe
            </MDTypography>
          </div>
        )}
      </MDBox>
      <Modal
        show={open}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Annulation d'un publireportage
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MDBox
            mx={2}
            my={1}
            py={3}
            px={2}
            variant="gradient"
            bgColor="error"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              Est-ce-que vous voulez vraiment annuler ce publireportage ?
            </MDTypography>
          </MDBox>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button onClick={handleClose}>Confirmer</Button> */}
          <MDButton color="error" onClick={handleClose}>
            Confirmer
          </MDButton>
        </Modal.Footer>
      </Modal>

      <Modal
        show={openAdd}
        onHide={handleCloseAdd}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {onUpdateGroupe !== null ? "Modifier Groupe" : "Ajouter un nouveau Groupe"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CardPub style={{ maxWidth: "300px", width: "100%" }}>
              <div
                style={{
                  width: "300px",
                  height: "300px",
                  border: pictureUrl ? "" : "3px dashed  #1A73E8",
                  margin: "20px auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  overflow: "hidden",
                }}
                onClick={handleButtonClick}
              >
                <input
                  accept="image/*"
                  ref={fileInputRef}
                  onClick={handleFileChange}
                  onChange={handleFileChange}
                  type="file"
                  style={{ display: "none" }}
                />

                {pictureUrl && pictureUrl != "" && (
                  <img style={{ height: "100%", width: "auto" }} src={pictureUrl} />
                )}
                {(!pictureUrl || pictureUrl === "") && (
                  <Icon fontSize="large" color="info">
                    add_a_photo
                  </Icon>
                )}
              </div>
              <MDInput
                style={{ width: "100%" }}
                label="Nom du Groupe"
                value={nomGroupe}
                onChange={(e) => setNomGroupe(e.currentTarget.value)}
              />
            </CardPub>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button onClick={handleCloseAdd}>Confirmer</Button> */}
          <MDButton
            color="info"
            onClick={onUpdateGroupe !== null ? modifierGroupe : insertNewGroupe}
          >
            Confirmer
          </MDButton>
        </Modal.Footer>
      </Modal>
      <Modal
        show={isLoading || isLoadingNewGroupe || isLoadingUpdateGroupe}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <MDBox
            mx={2}
            my={1}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              {isLoading ? "Loading ..." : "Updating ..."}
            </MDTypography>
          </MDBox>
        </Modal.Body>
      </Modal>
    </Card>
  );
}

export default List;
