import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  ButtonBase,
  Chip,
  Collapse,
  Divider,
  Typography,
  Badge,
  Box,
  LinearProgress,
} from "@mui/material";

import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import MainCard from "ui-component/cards/MainCard";
import { CheckCircleOutline, ExpandLess, ExpandMore } from "@mui/icons-material";
import { IconClock } from "@tabler/icons";
import { BASE_URL_Image } from "services/server";
import { useSelector } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import { getUserMessages } from "services/messagesService";
import ServiceMessages from "OurPages/OurComponents/Home/Annonce/components/messages/Messages";

const NotificationDetails = ({ openAdd, setOpenAdd, userId, notification, handleClose }) => {
  const theme = useTheme();
  const lang = useSelector((state) => state.customization.lang);

  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  const [moreInfo, setMoreInfo] = useState(false);
  const [concerned, setConcerned] = useState(null);

  useEffect(() => {
    if (notification && notification.id && notification.concerne_id) {
      if (notification.concerne === "message") {
        // mutateMessage({message_id : notification.concerne_id}) ;
      }
    }
  }, [notification]);

  const { mutate: mutateMessage, isLoading: isLoadingMessage } = useMutation(
    (filters) => getUserMessages(filters),
    {
      async onSuccess(data) {
        console.log(data);
        if (data && data.data && data.data.length) {
          setConcerned(data.data[0]);
        } else {
          setConcerned(null);
        }
      },
      onError(error) {},
    }
  );

  return (
    <>
      {userId != null && notification && notification.id ? (
        <Modal
          show={openAdd}
          onHide={handleClose ?? handleCloseAdd}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: "0.85em" }}>
              {lang === "fr"
                ? "Détail de la notification"
                : lang === "en"
                ? "Notification details"
                : "تفاصيل الإشعار"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ width: "100%" }}>
              <div className="w-100 d-flex justify-content-center align-items-center">
                <div>
                  <div className="w-100 row m-0">
                    <div className="col-12 d-flex justify-content-end align-items-center mb-1">
                      <Typography
                        noWrap
                        variant="caption"
                        style={{
                          color: theme.palette.grey[400],
                          fontSize: "11px",
                          fontWeight: "bold",
                        }}
                      >
                        {lang === "fr" ? "le " : lang === "en" ? "the " : "في "} {notification.date}
                      </Typography>
                    </div>
                    <div className="col-12 d-flex justify-content-start align-items-center  my-3">
                      <Typography variant="h3" fontSize={"24px"}>
                        {lang === "fr"
                          ? notification.titre
                          : lang === "en"
                          ? notification.titre_en
                          : notification.titre_ar}
                      </Typography>
                    </div>
                    <div className="col-12   my-2">
                      <Divider></Divider>
                    </div>

                    <div className="col-12 d-flex justify-content-start align-items-center  my-3">
                      <Typography variant="inherit" fontSize={"12px"}>
                        {lang === "fr"
                          ? notification.description
                          : lang === "en"
                          ? notification.description_en
                          : notification.description_ar}
                      </Typography>
                    </div>

                    <div className="col-12   my-2">
                      <Divider></Divider>
                    </div>

                    <div className="col-12   my-2">
                      {notification.concerne_id && notification.concerne === "message" ? (
                        <>
                          <ServiceMessages message_id={notification.concerne_id}></ServiceMessages>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <>
              <ButtonBase
                sx={{
                  ...theme.typography.commonAvatar,
                  ...theme.typography.button,
                  transition: "all .2s ease-in-out",
                  background: theme.palette.grey[200],
                  color: theme.palette.text.dark,
                  "&:hover": {
                    background: theme.palette.grey[900],
                    color: "white !important",
                  },
                  width: "max-content",
                  overflow: "hidden",
                }}
                className="py-2 px-5"
                onClick={handleCloseAdd}
              >
                {lang === "fr" ? "Fermer" : lang === "en" ? "Close" : "إغلق"}
              </ButtonBase>
            </>
          </Modal.Footer>
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
};

NotificationDetails.propTypes = {
  isLoading: PropTypes.bool,
};

export default NotificationDetails;
