import Grid from "@mui/material/Grid";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Overview page components
import Header from "layoutsEntreprises/ProfileEntreprise/components/Header";

// Images
import { Box, LinearProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { getEntreprisePage } from "services/entrepriseService";
import { getAcheteurPage } from "services/acheteurService";
import { BASE_URL_Image } from "services/server";
import { Modal } from "react-bootstrap";
import { updateEntreprise } from "services/entrepriseService";
import { toast } from "react-toastify";
import TitleDescriptionCard from "components/indicator/TitleDescriptionCard";
import { SocialIcon } from "react-social-icons";
import LinksCard from "components/indicator/LinksCard";
import TitleDescriptionCardVendeur from "components/indicator/TitleDescriptionCardVendeur";
import LinksCardVendeur from "components/indicator/LinksCardVendeur";
import { useSelector } from "react-redux";
import MenuList from "layouts/MainLayout/Sidebar/MenuList";
import { updateAcheteur } from "services/acheteurService";

function Overview() {
  const [entreprise, setEntreprise] = useState({});
  const [role, setRole] = useState("");
  const lang = useSelector((state) => state.customization.lang);
  useEffect(() => {
    setRole(localStorage.getItem("role"));
  }, []);
  const { mutate: mutateEntreprise, isLoading: isLoadingEntreprise } = useMutation(
    () => {
      if (role === "acheteur") {
        return getAcheteurPage();
      } else {
        return getEntreprisePage();
      }
    },
    {
      async onSuccess(data) {
        console.log(data);
        if (data && data.data && (data.data.entreprise || data.data.acheteur)) {
          let result = [];
          if (role === "acheteur") {
            result = data.data.acheteur[0];
          } else {
            result = data.data.entreprise[0];
          }

          let infos_result = {};
          let infos_result_en = {};
          let infos_result_ar = {};

          if (role === "entreprise" && result.secteur) {
            infos_result["Secteur"] = result.secteur;
            infos_result_en["Sector"] = result.secteur;
            infos_result_ar["القطاع"] = result.secteur;
          }

          if (result.tel) {
            infos_result["tel"] = result.tel;
            infos_result_en["phone"] = result.tel;
            infos_result_ar["الهاتف"] = result.tel;
          }
          if (result.fax) {
            infos_result["fax"] = result.fax;
            infos_result_en["fax"] = result.fax;
            infos_result_ar["الفاكس"] = result.fax;
          }
          if (result.email) {
            infos_result["email"] = result.email;
            infos_result_en["email"] = result.email;
            infos_result_ar["البريد الإلكتروني"] = result.email;
          }
          if (result.localisation) {
            infos_result["emplacement"] = result.localisation;
            infos_result_en["location"] = result.localisation;
            infos_result_ar["الموقع"] = result.localisation;
          }

          let links_result = [];

          if (result.facebook) {
            links_result.push({
              link: result.facebook,
              icon: null,
              color: "facebook",
            });
          }
          if (result.twitter) {
            links_result.push({
              link: result.twitter,
              icon: null,
              color: "twitter",
            });
          }
          if (result.instagram) {
            links_result.push({
              link: result.instagram,
              icon: null,
              color: "instagram",
            });
          }
          if (result.sitweb) {
            links_result.push({
              link: result.sitweb,
              icon: null,
              color: "portefolio",
            });
          }

          setEntreprise({
            id: result.id,
            nom: result.nom,
            profile_pic: result.profile_pic != null ? BASE_URL_Image + result.profile_pic : null,
            cover_pic: result.cover_pic != null ? BASE_URL_Image + result.cover_pic : null,
            presentation: result.presentation,
            infos: infos_result,
            infos_en: infos_result_en,
            infos_ar: infos_result_ar,
            links: links_result,
            groupe: [],
          });

          // setSecteurs(data.data.secteurs);
        } else {
        }
      },
      onError(error) {},
    }
  );

  useEffect(() => {
    mutateEntreprise();
  }, []);

  const { mutate: mutateUpdateEntreprise, isLoading: isLoadingUpdateEntreprise } = useMutation(
    (userInfo) => {
      if (role === "acheteur") {
        updateAcheteur({ ...userInfo, id: entreprise.id });
      } else {
        return updateEntreprise({ ...userInfo, id: entreprise.id });
      }
    },
    {
      async onSuccess(data) {
        console.log(data);
        if (data && data.data) {
          notify_success("Mettre à jour avec succès");
          mutateEntreprise();
        } else {
          notify("Une erreur a été produit");
        }
      },
      onError(error) {},
    }
  );
  const notify = (message) => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 3000,
      fontSize: "12px",
    });
  };

  const notify_success = (message) => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 3000,
    });
  };

  return (
    <>
      {/* <DashboardNavbar /> */}
      <MDBox mb={2} />
      <Header entreprise={entreprise} onUpdate={mutateUpdateEntreprise}>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} xl={8} sx={{ display: "flex" }} className="px-3">
              {role === "acheteur" ? (
                <TitleDescriptionCard
                  title={entreprise.nom}
                  description={entreprise && entreprise.presentation ? entreprise.presentation : ""}
                />
              ) : (
                <TitleDescriptionCardVendeur
                  title={entreprise.nom}
                  description={entreprise && entreprise.presentation ? entreprise.presentation : ""}
                />
              )}
            </Grid>
            <Grid item xs={12} xl={4} sx={{ display: "flex" }} className="px-3">
              {role === "acheteur" ? (
                <LinksCard
                  info={
                    entreprise && entreprise.infos
                      ? lang === "fr"
                        ? entreprise.infos
                        : lang === "en"
                        ? entreprise.infos_en
                        : entreprise.infos_ar
                      : {}
                  }
                  lang={lang}
                />
              ) : (
                <LinksCardVendeur
                  info={
                    entreprise && entreprise.infos
                      ? lang === "fr"
                        ? entreprise.infos
                        : lang === "en"
                        ? entreprise.infos_en
                        : entreprise.infos_ar
                      : {}
                  }
                  lang={lang}
                />
              )}
            </Grid>
          </Grid>
          <div className="w-100 py-5 d-flex align-items-center justify-content-center">
            {entreprise && entreprise.links ? (
              entreprise.links.map(({ link, icon, color }) => (
                <div className="px-3">
                  <SocialIcon url={link} />
                </div>
              ))
            ) : (
              <></>
            )}
          </div>

          <div className="w-100 pb-5">
            <Typography variant="h4" className="mb-2">
              List des Applications
            </Typography>
            <MenuList dashboard={true}></MenuList>
          </div>
        </MDBox>
      </Header>
      {/* <Footer /> */}
      <Modal
        show={isLoadingEntreprise || isLoadingUpdateEntreprise}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="myModalLoading"
      >
        <Modal.Body style={{ overflow: "hidden" }}>
          <Box sx={{ width: "100%" }}>
            <LinearProgress color="secondary" />
          </Box>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Overview;
