import React, { useState } from "react";
import logo from "assets/images/logo_targets_zone_dark2.png";
import sponsorImage from "assets/img/sponsor.jpg";

import MainCard from "ui-component/cards/MainCard";
import { Box, ButtonBase, Button as ButtonMui, Divider, LinearProgress } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { demandeOption } from "services/visiteurService";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { demandePartenaires } from "services/visiteurService";
export default () => {
  const lang = useSelector((state) => state.customization.lang);
  const theme = useTheme();

  const [openAdd, setOpenAdd] = useState(false);

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [Message, setMessage] = useState("");
  const [Phone, setPhone] = useState("");
  const [WhatsApp, setWhatsApp] = useState("");
  const [dejaUser, setDejaUser] = useState(false);
  const handleOpenAdd = () => {
    setOpenAdd(true);
  };
  const handleCloseAdd = () => {
    setUsername("");
    setEmail("");
    setPhone("");
    setMessage("");
    setDejaUser(false);
    setOpenAdd(false);
  };

  const lancerPaiment = () => {
    var user = {
      nom: username,
      email,
      phone: Phone,
      whatsapp: WhatsApp,
      message: Message,
      alreadyUser: dejaUser,
      // file: selectedFile,
    };
    console.log(user);

    var valid = true;

    if (!username || username === "") {
      notify("Entrez votre Dénomination SVP !");
      valid = false;
    } else if (
      !email ||
      email === "" ||
      !String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      notify("Entrez votre adresse email SVP !");
      valid = false;
    } else if (!Phone || Phone === "") {
      notify("Veuillez entrer votre numéro de téléphone SVP !");
      valid = false;
    } else {
      notify_success("valid form");

      mutateAdd(user);
      // navigate("/admin");
    }
  };

  const notify = (message) => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 3000,
      fontSize: "12px",
    });
  };

  const notify_success = (message) => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 3000,
    });
  };

  const { mutate: mutateAdd, isLoading: isLoadingAdd } = useMutation(
    (userData) => demandePartenaires(userData),
    {
      async onSuccess(data) {
        console.log(data);

        if (data.success) {
          notify_success("Votre demande a été envoyée avec succès");
          handleCloseAdd();
        } else if (data.message !== "") {
          notify(data.message);
        } else {
          notify("error");
        }
      },
      onError(error) {
        notify("error");
        if (Array.isArray(error.response.data.error)) {
          error.response.data.error.forEach((el) => console.log(el.message));
        } else {
          console.log(error.response.data.message);
        }
      },
    }
  );
  return (
    <div className="container mx-auto  px-4">
      <div
        className="flex flex-wrap justify-center text-center mb-24"
        style={lang === "ar" ? { direction: "rtl" } : {}}
      >
        <div className="w-full lg:w-6/12 px-0">
          <h2 className="text-4xl font-semibold">
            {lang === "fr" ? "Nos partenaires" : lang === "en" ? "Our partners" : "شركاؤنا"}
          </h2>
          <p className="text-lg leading-relaxed m-4 text-blueGray-500">
            {lang === "fr"
              ? "Nous sommes ravis de vous annoncer que 'Sources Matière' est actuellement sponsorisé par les partenaires suivants"
              : lang === "en"
              ? "We are delighted to announce that 'Sources Matière' is currently sponsored by the following partners"
              : "يسعدنا أن نعلن أن برنامج 'Sources Matière' يحظى حالياً برعاية الشركاء التاليين"}
          </p>
        </div>
      </div>
      <div className="flex flex-wrap">
        <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
          <div className="px-6">
            <img
              alt="..."
              src={sponsorImage}
              className="shadow-lg rounded-full mx-auto max-w-120-px"
            />
            <div className="pt-6 text-center">
              <h5 className="text-xl font-bold">
                {lang === "fr" ? "Partenaire " : lang === "en" ? "Partner " : "الشريك "}1
              </h5>
              <p className="mt-1 text-sm text-blueGray-400 uppercase font-semibold">
                {lang === "fr"
                  ? "Role Partenaire "
                  : lang === "en"
                  ? "Partner Role "
                  : "دور الشريك "}
                1
              </p>
              <div className="mt-6">
                <button
                  className="bg-lightBlue-400 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                  type="button"
                >
                  <i className="fab fa-twitter"></i>
                </button>
                <button
                  className="bg-lightBlue-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                  type="button"
                >
                  <i className="fab fa-facebook-f"></i>
                </button>
                <button
                  className="bg-pink-500 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                  type="button"
                >
                  <i className="fab fa-dribbble"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
          <div className="px-6">
            <img
              alt="..."
              src={sponsorImage}
              className="shadow-lg rounded-full mx-auto max-w-120-px"
            />
            <div className="pt-6 text-center">
              <h5 className="text-xl font-bold">
                {lang === "fr" ? "Partenaire " : lang === "en" ? "Partner " : "الشريك "}2
              </h5>
              <p className="mt-1 text-sm text-blueGray-400 uppercase font-semibold">
                {lang === "fr"
                  ? "Role Partenaire "
                  : lang === "en"
                  ? "Partner Role "
                  : "دور الشريك "}
                2
              </p>
              <div className="mt-6">
                <button
                  className="bg-red-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                  type="button"
                >
                  <i className="fab fa-google"></i>
                </button>
                <button
                  className="bg-lightBlue-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                  type="button"
                >
                  <i className="fab fa-facebook-f"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
          <div className="px-6">
            <img
              alt="..."
              src={sponsorImage}
              className="shadow-lg rounded-full mx-auto max-w-120-px"
            />
            <div className="pt-6 text-center">
              <h5 className="text-xl font-bold">
                {lang === "fr" ? "Partenaire " : lang === "en" ? "Partner " : "الشريك "}3
              </h5>
              <p className="mt-1 text-sm text-blueGray-400 uppercase font-semibold">
                {lang === "fr"
                  ? "Role Partenaire "
                  : lang === "en"
                  ? "Partner Role "
                  : "دور الشريك "}
                3
              </p>
              <div className="mt-6">
                <button
                  className="bg-red-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                  type="button"
                >
                  <i className="fab fa-google"></i>
                </button>
                <button
                  className="bg-lightBlue-400 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                  type="button"
                >
                  <i className="fab fa-twitter"></i>
                </button>
                <button
                  className="bg-blueGray-700 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                  type="button"
                >
                  <i className="fab fa-instagram"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
          <div className="px-6">
            <img
              alt="..."
              src={sponsorImage}
              className="shadow-lg rounded-full mx-auto max-w-120-px"
            />
            <div className="pt-6 text-center">
              <h5 className="text-xl font-bold">
                {lang === "fr" ? "Partenaire " : lang === "en" ? "Partner " : "الشريك "}4
              </h5>
              <p className="mt-1 text-sm text-blueGray-400 uppercase font-semibold">
                {lang === "fr"
                  ? "Role Partenaire "
                  : lang === "en"
                  ? "Partner Role "
                  : "دور الشريك "}
                4
              </p>
              <div className="mt-6">
                <button
                  className="bg-pink-500 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                  type="button"
                >
                  <i className="fab fa-dribbble"></i>
                </button>
                <button
                  className="bg-red-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                  type="button"
                >
                  <i className="fab fa-google"></i>
                </button>
                <button
                  className="bg-lightBlue-400 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                  type="button"
                >
                  <i className="fab fa-twitter"></i>
                </button>
                <button
                  className="bg-blueGray-700 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                  type="button"
                >
                  <i className="fab fa-instagram"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-100 my-5 ">
        <Divider></Divider>
      </div>
      <div className="w-100 mt-5 d-flex align-items-center justify-content-center">
        <div className="w-100">
          <div className="p-0">
            <div className="w-100">
              <h3 className="text-lg font-semibold mb-3" style={{ textAlign: "center" }}>
                {lang === "fr"
                  ? "Devenez Notre Partenaire de Confiance"
                  : lang === "en"
                  ? "Become Our Trusted Partner"
                  : "كن شريكنا الموثوق"}
              </h3>
              <p
                className="text-md font-light leading-relaxed mt-4 mb-4 text-blueGray-600"
                style={{ textAlign: "center" }}
              >
                {lang === "fr"
                  ? "Rejoignez-nous dans notre aventure et bénéficiez d'opportunités uniques pour faire croître votre entreprise. "
                  : lang === "en"
                  ? "Join us on our adventure and benefit from unique opportunities to grow your business. "
                  : "انضم إلينا في مغامرتنا واستفد من الفرص الفريدة لتنمية أعمالك."}

                <br></br>
                {lang === "fr"
                  ? "Ensemble, nous pouvons atteindre de nouveaux sommets. Partageons notre succès!"
                  : lang === "en"
                  ? "Together we can reach new heights. Let's share our success!"
                  : "معًا يمكننا الوصول إلى آفاق جديدة. دعونا نشارك نجاحنا!"}
              </p>
            </div>
            <div className="w-100 new_message_button d-flex justify-content-center align-items-center p-0">
              {/* <Email style={{ color: theme.palette.grey[400] }} fontSize="medium" /> */}

              <ButtonMui
                sx={{
                  ...theme.typography.commonAvatar,
                  ...theme.typography.button,
                  transition: "all .2s ease-in-out",
                  background: theme.palette.primary.light,
                  color: theme.palette.primary.dark,
                  "&:hover": {
                    background: theme.palette.primary.dark,
                    color: theme.palette.primary.light,
                  },
                  height: "100%",
                  overflow: "hidden",
                  borderRadius: "none",
                }}
                className="py-2 px-5"
                onClick={() => {
                  handleOpenAdd();
                }}
              >
                {lang === "fr"
                  ? "Devenez Partenaire avec Nous"
                  : lang === "en"
                  ? "Become a Partner with Us"
                  : "كن شريكًا معنا"}
              </ButtonMui>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={isLoadingAdd}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="myModalLoading"
      >
        <Modal.Body style={{ overflow: "hidden" }}>
          <Box sx={{ width: "100%" }}>
            <LinearProgress color="secondary" />
          </Box>
        </Modal.Body>
      </Modal>

      <Modal
        show={openAdd}
        onHide={handleCloseAdd}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ fontSize: "0.85em", textAlign: lang === "ar" ? "right" : "left" }}
          >
            {lang === "fr"
              ? "Devenez Partenaire avec Nous"
              : lang === "en"
              ? "Become a Partner with Us"
              : "كن شريكًا معنا"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ direction: lang === "ar" ? "rtl" : "" }}>
          <div style={{ width: "100%" }}>
            <div className="w-100 d-flex justify-content-center align-items-center">
              <div style={{ maxWidth: "500px" }}>
                <div className="flex-auto">
                  <div className="relative w-full mb-3 mt-8">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="full-name"
                    >
                      {lang === "fr" ? "Dénomination" : lang === "en" ? "Denomination" : "التسمية"}
                    </label>
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder={
                        lang === "fr"
                          ? "Votre dénomination"
                          : lang === "en"
                          ? "Your denomination"
                          : "التسمية خاصتك"
                      }
                      onChange={(e) => setUsername(e.currentTarget.value)}
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="email"
                    >
                      {lang === "fr" ? "Email" : lang === "en" ? "Email" : "البريد الإلكتروني"}
                    </label>
                    <input
                      type="email"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder={
                        lang === "fr"
                          ? "votre email"
                          : lang === "en"
                          ? "your email"
                          : "بريدك الإلكتروني"
                      }
                      onChange={(e) => setEmail(e.currentTarget.value)}
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="email"
                    >
                      {lang === "fr"
                        ? "Numéro Téléphone"
                        : lang === "en"
                        ? "Phone number"
                        : "رقم الهاتف"}
                    </label>
                    <input
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder={
                        lang === "fr"
                          ? "Votre numéro du téléphone"
                          : lang === "en"
                          ? "Your phone number"
                          : "رقم هاتفك"
                      }
                      onChange={(e) => setPhone(e.currentTarget.value)}
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="whatsapp"
                    >
                      {lang === "fr" ? "WhatsApp" : lang === "en" ? "WhatsApp" : "واتساب"}
                    </label>
                    <input
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder={
                        lang === "fr"
                          ? "Votre WhatsApp"
                          : lang === "en"
                          ? "Your WhatsApp"
                          : "واتساب"
                      }
                      onChange={(e) => setWhatsApp(e.currentTarget.value)}
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="message"
                    >
                      {lang === "fr" ? "Message" : lang === "en" ? "Message" : "رسالتك"}
                    </label>
                    <textarea
                      rows="4"
                      cols="80"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                      placeholder={
                        lang === "fr"
                          ? "votre message..."
                          : lang === "en"
                          ? "your message..."
                          : "رسالتك..."
                      }
                      onChange={(e) => setMessage(e.currentTarget.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ direction: lang === "ar" ? "rtl" : "" }}>
          <>
            <ButtonBase
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.button,
                transition: "all .2s ease-in-out",
                background: theme.palette.secondary.light,
                color: theme.palette.secondary.dark,
                "&:hover": {
                  background: theme.palette.secondary.dark,
                  color: theme.palette.secondary.light,
                },
                height: "100%",
                overflow: "hidden",
              }}
              className="py-2 px-5"
              onClick={handleCloseAdd}
            >
              {lang === "fr" ? "Annuler" : lang === "en" ? "Cancel" : "إلغاء"}
            </ButtonBase>

            <ButtonBase
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.button,
                transition: "all .2s ease-in-out",
                background: theme.palette.primary.dark,
                color: theme.palette.primary.light,
                "&:hover": {
                  background: theme.palette.primary.light,
                  color: theme.palette.primary.dark,
                },
                height: "100%",
                overflow: "hidden",
              }}
              className="py-2 px-5"
              onClick={lancerPaiment}
            >
              {lang === "fr" ? "Envoyer" : lang === "en" ? "Send" : "إرسال"}
            </ButtonBase>
          </>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
