import PropTypes from "prop-types";

// material-ui
import { useTheme, styled } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";

// project imports
import MainCard from "ui-component/cards/MainCard";
import TotalIncomeCard from "ui-component/cards/Skeleton/TotalIncomeCard";

// assets
import StorefrontTwoToneIcon from "@mui/icons-material/StorefrontTwoTone";
import ReactImageGallery from "react-image-gallery";
import ReactPlayer from "react-player";
import { BASE_URL_Image } from "services/server";
import { Favorite, FavoriteBorder, Share } from "@mui/icons-material";
import { useEffect, useState } from "react";
import MustAuth from "components/shareProduct/MustAuth";
import { useSelector } from "react-redux";

// styles
const CardWrapper = styled(MainCard)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.primary.light,
  overflow: "hidden",
  position: "relative",
  "&:after": {
    content: '""',
    position: "absolute",
    width: 210,
    height: 210,
    background: `linear-gradient(210.04deg, ${theme.palette.primary[200]} -50.94%, rgba(144, 202, 249, 0) 83.49%)`,
    borderRadius: "50%",
    top: -30,
    right: -180,
  },
  "&:before": {
    content: '""',
    position: "absolute",
    width: 210,
    height: 210,
    background: `linear-gradient(140.9deg, ${theme.palette.primary[200]} -14.02%, rgba(144, 202, 249, 0) 77.58%)`,
    borderRadius: "50%",
    top: -160,
    right: -130,
  },
}));

// ==============================|| DASHBOARD - TOTAL INCOME LIGHT CARD ||============================== //

const ServiceCard = ({
  title,
  description,
  images,
  videoUrl,
  videoLink,
  entreprise,
  entreprise_image,
  onShare,
  fav,
  onLike,
}) => {
  const theme = useTheme();
  const lang = useSelector((state) => state.customization.lang);

  const [openAdd, setOpenAdd] = useState(false);
  const [auth, setAuth] = useState(false);

  useEffect(() => {
    let token = localStorage.getItem("token");
    console.log("token");
    console.log(token);
    console.log(token != null && token !== "");
    setAuth(token != null && token !== "");
  }, []);
  useEffect(() => {
    // onShare();
  }, []);

  const cardActions = (align, onShare, onLike, fav) => {
    return (
      <div className={"w-100 d-flex align-items-center " + align}>
        <Button
          startIcon={!fav ? <FavoriteBorder /> : <Favorite />}
          className="mx-2 px-3"
          onClick={onLike}
          sx={{
            ...theme.typography.commonAvatar,
            ...theme.typography.button,
            transition: "all .2s ease-in-out",
            background: theme.palette.grey[200],
            color: theme.palette.text.dark,
            "&:hover": {
              background: theme.palette.grey[900],
              color: "white !important",
            },
            direction: "ltr",
            width: "max-content",
            overflow: "hidden",
          }}
        >
          {lang === "fr" ? "Souhaits" : lang === "en" ? "Wishlist" : "الرغبات"}
        </Button>
        <Button
          startIcon={<Share />}
          className="mx-2 px-3"
          onClick={onShare}
          sx={{
            ...theme.typography.commonAvatar,
            ...theme.typography.button,
            transition: "all .2s ease-in-out",
            background: theme.palette.secondary.light,
            color: theme.palette.secondary.dark,
            "&:hover": {
              background: theme.palette.secondary.dark,
              color: theme.palette.secondary.light,
            },
            direction: "ltr",
            width: "max-content",
            overflow: "hidden",
          }}
        >
          {lang === "fr" ? "Partager" : lang === "en" ? "Share" : "مشاركة"}
        </Button>
      </div>
    );
  };

  return (
    <>
      <CardWrapper
        border={true}
        content={false}
        style={{ width: "100%", direction: lang === "ar" ? "rtl" : "ltr" }}
      >
        <Box sx={{ p: 2 }}>
          <List sx={{ py: 0 }}>
            <ListItem alignItems="center" disableGutters sx={{ py: 0 }}>
              <ListItemAvatar
                style={{ width: "70px", height: "70px", overflow: "hidden" }}
                className="d-flex align-items-center justify-content-center"
              >
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{
                    height: "50px",
                    width: "50px",
                    borderRadius: "50%",
                    overflow: "hidden",
                    background: "white",
                  }}
                >
                  <img
                    alt=""
                    style={{ width: "50px", height: "auto" }}
                    src={entreprise_image ? entreprise_image : ""}
                  />
                </div>
              </ListItemAvatar>
              <ListItemText
                sx={{
                  py: 0,
                }}
                primary={
                  <Typography
                    variant="body2"
                    style={{ color: "white", textAlign: lang === "ar" ? "right" : "left" }}
                  >
                    {entreprise}
                  </Typography>
                }
              />
            </ListItem>
          </List>
          <List>
            <ListItem alignItems="center" disableGutters sx={{ py: 0 }}>
              <ListItemText
                sx={{
                  py: 0,
                  mt: 0.45,
                  mb: 0.45,
                }}
                primary={
                  <Typography
                    variant="h1"
                    align="center"
                    style={{ textTransform: "uppercase", color: "white", textAlign: "center" }}
                    className="my-2"
                  >
                    {title}
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                sx={{
                  py: 0,
                  mt: 0.45,
                  mb: 0.45,
                }}
                secondary={
                  <Typography
                    variant="body1"
                    align="center"
                    sx={{
                      color: theme.palette.grey[200],
                      mt: 0.5,
                      my: 3,
                      textAlign: "center",
                    }}
                  >
                    {description != null && description.length > 0
                      ? description.split("\n").map((el) => {
                          return (
                            <div
                              style={{
                                textAlign: "center",
                              }}
                            >
                              {el}
                              <br />
                            </div>
                          );
                        })
                      : ""}
                  </Typography>
                }
              />
            </ListItem>
            {cardActions("justify-content-end", onShare, onLike, fav)}
          </List>
        </Box>
      </CardWrapper>

      <MustAuth openAdd={openAdd} setOpenAdd={setOpenAdd}></MustAuth>
    </>
  );
};

ServiceCard.propTypes = {
  isLoading: PropTypes.bool,
};

export default ServiceCard;
