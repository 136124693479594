/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ProfileInfoCard from "layoutsPub/Contact/components/ProfileInfoCard";
import ProfilesList from "layoutsPub/Contact/components/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "layoutsPub/Contact/components/Header";
import PlatformSettings from "layoutsPub/Contact/components/PlatformSettings";

// Data
import profilesListData from "layoutsPub/Contact/data/profilesListData";

// Images
import { Card, Icon, Switch } from "@mui/material";
import MDBadge from "components/MDBadge";
import { useState } from "react";

// @mui material components
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";

// Data
import { Box } from "@mui/material";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import React from "react";
import { useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { getInfos } from "services/adminService";
import { toast } from "react-toastify";
import tw from "twin.macro";
import { updateUserEmail } from "services/adminService";
import { updateUserPassword } from "services/adminService";
import { updateUserInfos } from "services/adminService";

const CardPub = tw.div`h-full flex! flex-col sm:border max-w-sm  relative focus:outline-none bg-white m-0`;

function Overview() {
  const [onEditEmail, setOnEditEmail] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const handleOpenEditPassword = () => {
    setOpenAdd(true);
  };
  const handleOpenEditEmail = () => {
    setOnEditEmail(true);
    setOpenAdd(true);
  };
  const handleCloseAdd = () => {
    setOpenAdd(false);
    setOnEditEmail(false);
    setUserInfos((oldValue) => {
      return {
        ...oldValue,
        new_email: "",
        new_email_confirmation: "",
        new_password: "",
        new_password_confirmation: "",
        password: "",
      };
    });
    setShowPassword(false);
  };

  const [userInfos, setUserInfos] = useState({
    email: "",
    new_email: "",
    new_email_confirmation: "",
    new_password: "",
    new_password_confirmation: "",
    password: "",
  });

  const [newInfos, setNewInfos] = useState({
    tel: "",
    fax: "",
    localisation: "",
    email: "",
    facebook: "",
    linkedin: "",
    twitter: "",
    presentation: "",
    siteweb: "",
    instagram: "",
  });

  const [infos, setInfos] = useState({
    tel: "",
    fax: "",
    localisation: "",
    email: "",
    facebook: "",
    linkedin: "",
    twitter: "",
    presentation: "",
    siteweb: "",
    instagram: "",
  });
  const { mutate, isLoading } = useMutation(() => getInfos(), {
    async onSuccess(data) {
      console.log(data);
      if (data && data.data) {
        setInfos({
          tel: data.data.tel != null && data.data.tel !== "" ? data.data.tel : "",
          fax: data.data.fax != null && data.data.fax !== "" ? data.data.fax : "",
          localisation:
            data.data.localisation != null && data.data.localisation !== ""
              ? data.data.localisation
              : "",
          email: data.data.email != null && data.data.email !== "" ? data.data.email : "",
          facebook:
            data.data.facebook != null && data.data.facebook !== "" ? data.data.facebook : "",
          linkedin:
            data.data.linkedin != null && data.data.linkedin !== "" ? data.data.linkedin : "",
          twitter: data.data.twitter != null && data.data.twitter !== "" ? data.data.twitter : "",
          presentation:
            data.data.presentation != null && data.data.presentation !== ""
              ? data.data.presentation
              : "",
          siteweb: data.data.sitweb != null && data.data.sitweb !== "" ? data.data.sitweb : "",
          instagram:
            data.data.instagram != null && data.data.instagram !== "" ? data.data.instagram : "",
        });
        setNewInfos({
          tel: data.data.tel != null && data.data.tel !== "" ? data.data.tel : "",
          fax: data.data.fax != null && data.data.fax !== "" ? data.data.fax : "",
          localisation:
            data.data.localisation != null && data.data.localisation !== ""
              ? data.data.localisation
              : "",
          email: data.data.email != null && data.data.email !== "" ? data.data.email : "",
          facebook:
            data.data.facebook != null && data.data.facebook !== "" ? data.data.facebook : "",
          linkedin:
            data.data.linkedin != null && data.data.linkedin !== "" ? data.data.linkedin : "",
          twitter: data.data.twitter != null && data.data.twitter !== "" ? data.data.twitter : "",
          presentation:
            data.data.presentation != null && data.data.presentation !== ""
              ? data.data.presentation
              : "",
          siteweb: data.data.sitweb != null && data.data.sitweb !== "" ? data.data.sitweb : "",
          instagram:
            data.data.instagram != null && data.data.instagram !== "" ? data.data.instagram : "",
        });

        if (localStorage.getItem("email")) {
          setUserInfos((oldValue) => {
            return { ...oldValue, email: localStorage.getItem("email") };
          });
        }
      } else {
      }
    },
    onError(error) {},
  });

  const { mutate: mutateUpdateEmail, isLoading: isLoadingUpdateEmail } = useMutation(
    () =>
      updateUserEmail({
        new_email: userInfos.new_email,
        new_email_confirmation: userInfos.new_email_confirmation,
        password: userInfos.password,
      }),
    {
      async onSuccess(data) {
        console.log(data);
        if (data && data.success) {
          setUserInfos((oldValue) => {
            return { ...oldValue, email: oldValue.new_email };
          });
          localStorage.setItem("email", userInfos.new_email);
          handleCloseAdd();
        } else {
        }
      },
      onError(error) {},
    }
  );

  const { mutate: mutateUpdatePassword, isLoading: isLoadingUpdatePassword } = useMutation(
    () =>
      updateUserPassword({
        new_password: userInfos.new_password,
        new_password_confirmation: userInfos.new_password_confirmation,
        password: userInfos.password,
      }),
    {
      async onSuccess(data) {
        console.log(data);
        if (data && data.success) {
          handleCloseAdd();
        } else {
        }
      },
      onError(error) {},
    }
  );

  const { mutate: mutateUpdateInfos, isLoading: isLoadingUpdateInfos } = useMutation(
    () =>
      updateUserInfos({
        tel: newInfos.tel != null && newInfos.tel !== "" ? newInfos.tel : "",
        fax: newInfos.fax != null && newInfos.fax !== "" ? newInfos.fax : "",
        localisation:
          newInfos.localisation != null && newInfos.localisation !== ""
            ? newInfos.localisation
            : "",
        email: newInfos.email != null && newInfos.email !== "" ? newInfos.email : "",
        facebook: newInfos.facebook != null && newInfos.facebook !== "" ? newInfos.facebook : "",
        linkedin: newInfos.linkedin != null && newInfos.linkedin !== "" ? newInfos.linkedin : "",
        twitter: newInfos.twitter != null && newInfos.twitter !== "" ? newInfos.twitter : "",
        presentation:
          newInfos.presentation != null && newInfos.presentation !== ""
            ? newInfos.presentation
            : "",
        sitweb: newInfos.siteweb != null && newInfos.siteweb !== "" ? newInfos.siteweb : "",
        instagram:
          newInfos.instagram != null && newInfos.instagram !== "" ? newInfos.instagram : "",
      }),
    {
      async onSuccess(data) {
        console.log(data);
        if (data && data.success) {
          mutate();
        } else {
        }
      },
      onError(error) {},
    }
  );

  useEffect(() => {
    mutate();
  }, []);

  const resetForm = () => {
    setNewInfos(infos);
  };

  const changeInfos = () => {
    console.log(newInfos);
    var tel_regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    var email_regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // Numéro de téléphone local (ex. : 01-23-45-67-89)
    const regexLocal = /^\d{10}$/;

    // Numéro de téléphone étranger (ex. : +33 1 23 45 67 89)
    const regexEtranger = /^\+\d{12,14}$/;
    var valid = true;

    if (newInfos.email !== "" && !String(newInfos.email).toLowerCase().match(email_regex)) {
      notify("Entrer un email correct stp !");
      valid = false;
    }

    if (
      newInfos.tel !== "" &&
      !String(newInfos.tel).toLowerCase().match(regexLocal) &&
      !String(newInfos.tel).toLowerCase().match(regexEtranger)
    ) {
      notify("Entrer un numéro du téléphone correct stp !");
      valid = false;
    }

    if (
      newInfos.fax !== "" &&
      !String(newInfos.fax).toLowerCase().match(regexLocal) &&
      !String(newInfos.fax).toLowerCase().match(regexEtranger)
    ) {
      notify("Entrer un numéro du fax correct stp !");
      valid = false;
    }

    if (valid) {
      mutateUpdateInfos();
    }
  };

  const notify = (message) => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 3000,
      fontSize: "12px",
    });
  };

  const notify_success = (message) => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 3000,
    });
  };

  const changeUserInfos = () => {
    console.log(userInfos);
    var email_regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (onEditEmail) {
      var valid = true;

      if (
        userInfos.new_email === "" ||
        !String(userInfos.new_email).toLowerCase().match(email_regex)
      ) {
        notify("Entrer un email correct stp !");
        valid = false;
      }
      if (userInfos.new_email !== userInfos.new_email_confirmation) {
        notify("Confirmer votre nouveau email stp !");
        valid = false;
      }

      if (userInfos.password === "") {
        notify("Entrer votre mot de passe stp !");
        valid = false;
      }

      if (valid) {
        mutateUpdateEmail();
      }
    } else {
      var valid = true;

      if (userInfos.new_password === "") {
        notify("Entrer un nouveau mot de passe correct stp !");
        valid = false;
      }
      if (userInfos.new_password !== userInfos.new_password_confirmation) {
        notify("Confirmer votre nouveau mot de passe stp !");
        valid = false;
      }

      if (userInfos.password === "") {
        notify("Entrer votre mot de passe stp !");
        valid = false;
      }

      if (valid) {
        mutateUpdatePassword();
      }
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} xl={12} sx={{ display: "flex" }}>
              {/* 
              <ProfilesList title="Groupe" profiles={profilesListData} shadow={false} /> */}
              {/* <PlatformSettings /> */}
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              <MDBox>
                <Card sx={{ height: "100%", boxShadow: "none" }}>
                  <MDBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    pt={2}
                    px={2}
                  >
                    <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                      Admin Email
                    </MDTypography>
                  </MDBox>
                  <MDBox p={2}>
                    <MDBox mb={2} lineHeight={1}>
                      <MDTypography variant="p" fontWeight="light" style={{ fontSize: "13px" }}>
                        {userInfos && userInfos.email ? userInfos.email : ""}
                      </MDTypography>
                    </MDBox>
                    <MDButton color="dark" onClick={handleOpenEditEmail}>
                      Changer l'email
                    </MDButton>
                  </MDBox>

                  <MDBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    pt={2}
                    px={2}
                  >
                    <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                      Mot de passe
                    </MDTypography>
                  </MDBox>
                  <MDBox p={2}>
                    <MDBox mb={2} lineHeight={2}>
                      <MDTypography variant="p" fontWeight="light" style={{ fontSize: "13px" }}>
                        ************
                      </MDTypography>
                    </MDBox>
                    <MDButton color="dark" onClick={handleOpenEditPassword}>
                      Changer le mot de passe
                    </MDButton>
                  </MDBox>
                </Card>
              </MDBox>
            </Grid>
            {/* <Grid item xs={12} style={{display : "flex" , justifyContent : "flex-end"}}>
                    <MDButton color="warning"  onClick={resetForm} style={{marginRight : "10px"}} >Reset</MDButton>
                    <MDButton color="info"  onClick={changeInfos} >Confirmer</MDButton>

            </Grid> */}
          </Grid>
        </MDBox>
      </Header>

      <Modal
        show={isLoading || isLoadingUpdateEmail || isLoadingUpdatePassword || isLoadingUpdateInfos}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <MDBox
            mx={2}
            my={1}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              {isLoading ? "Loading ..." : "Updating ..."}
            </MDTypography>
          </MDBox>
        </Modal.Body>
      </Modal>

      <Modal
        show={openAdd}
        onHide={handleCloseAdd}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {onEditEmail !== null ? "Modifier votre email " : "Modifier votre mote de passe"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CardPub style={{ maxWidth: "300px", width: "100%" }}>
              {onEditEmail && (
                <>
                  <MDInput
                    style={{ width: "100%", marginTop: "20px" }}
                    label="Nouveau email"
                    value={userInfos && userInfos.new_email ? userInfos.new_email : ""}
                    onChange={(e) => {
                      let new_value = e.currentTarget.value;
                      setUserInfos((oldValue) => {
                        return { ...oldValue, new_email: new_value };
                      });
                    }}
                  />
                  <MDInput
                    style={{ width: "100%", marginTop: "20px" }}
                    label="Nouveau email (confirmation)"
                    value={
                      userInfos && userInfos.new_email_confirmation
                        ? userInfos.new_email_confirmation
                        : ""
                    }
                    onChange={(e) => {
                      let new_value = e.currentTarget.value;
                      setUserInfos((oldValue) => {
                        return { ...oldValue, new_email_confirmation: new_value };
                      });
                    }}
                  />
                </>
              )}

              {!onEditEmail && (
                <>
                  <MDInput
                    style={{ width: "100%", marginTop: "20px" }}
                    label="Nouveau Mot de passe"
                    value={userInfos && userInfos.new_password ? userInfos.new_password : ""}
                    type={showPassword ? "text" : "password"}
                    onChange={(e) => {
                      let new_value = e.currentTarget.value;
                      setUserInfos((oldValue) => {
                        return { ...oldValue, new_password: new_value };
                      });
                    }}
                  />
                  <MDInput
                    style={{ width: "100%", marginTop: "20px" }}
                    label="Nouveau  Mot de passe (confirmation)"
                    value={
                      userInfos && userInfos.new_password_confirmation
                        ? userInfos.new_password_confirmation
                        : ""
                    }
                    type={showPassword ? "text" : "password"}
                    onChange={(e) => {
                      let new_value = e.currentTarget.value;
                      setUserInfos((oldValue) => {
                        return { ...oldValue, new_password_confirmation: new_value };
                      });
                    }}
                  />
                </>
              )}

              <MDInput
                style={{ width: "100%", marginTop: "20px" }}
                label="Mot de passe"
                value={userInfos && userInfos.password ? userInfos.password : ""}
                type={showPassword ? "text" : "password"}
                onChange={(e) => {
                  let new_value = e.currentTarget.value;
                  setUserInfos((oldValue) => {
                    return { ...oldValue, password: new_value };
                  });
                }}
              />

              <MDBox display="flex" alignItems="center" ml={-1}>
                <Switch
                  checked={showPassword}
                  onChange={() => {
                    setShowPassword((value) => !value);
                  }}
                />
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  onClick={() => {
                    setShowPassword((value) => !value);
                  }}
                  sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                >
                  &nbsp;&nbsp;Afficher les mots de passe
                </MDTypography>
              </MDBox>
            </CardPub>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button onClick={handleCloseAdd}>Confirmer</Button> */}
          <MDButton color="info" onClick={changeUserInfos}>
            Confirmer
          </MDButton>
        </Modal.Footer>
      </Modal>
    </DashboardLayout>
  );
}

export default Overview;
