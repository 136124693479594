import { useEffect, useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { signin } from "services/authService";
import { Button, ButtonBase, Divider, Typography } from "@mui/material";
import LogoIcon from "assets/images/logo_targets_zone_dark2.png";
import IllutrationLogin from "assets/images/login-illustration.png";
import { SocialIcon } from "react-social-icons";
import { ArrowBack } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { sendResetPasswordToken } from "services/authService";
import AuthLangues from "./AuthLangues";

// Images

function Basic() {
  const theme = useTheme();
  const lang = useSelector((state) => state.customization.lang);
  const [email, setEmail] = useState("");
  const [validated, setValidated] = useState(false);
  const { mutate, isLoading } = useMutation((userData) => sendResetPasswordToken(userData), {
    async onSuccess(data) {
      console.log(data);

      if (data.success) {
        setValidated(true);
        notify_success(
          lang === "fr"
            ? "Lien envoyé avec succès"
            : lang === "en"
            ? "Link sent successfully"
            : "تم الرابط الرمز بنجاح"
        );
      } else {
        notify(data.message);
      }
    },
    onError(error) {
      notify("error");
      if (Array.isArray(error.response.data.error)) {
        error.response.data.error.forEach((el) => console.log(el.message));
      } else {
        console.log(error.response.data.message);
      }
    },
  });
  const [rememberMe, setRememberMe] = useState(false);
  const [password, setPassword] = useState("");

  const navigate = useNavigate();
  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const Login = () => {
    var user = {
      email,
      lang,
    };
    var valid = true;

    if (
      !email ||
      email === "" ||
      !String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      notify(
        lang === "fr"
          ? "Entrez email s'il vous plaît !"
          : lang === "en"
          ? "Enter your Email please!"
          : "أدخل بريدك الإلكتروني من فضلك!"
      );
      valid = false;
    }

    if (valid) {
      mutate(user);
    }
  };

  const notify = (message) => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 3000,
      fontSize: "12px",
    });
  };

  const notify_success = (message) => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 3000,
    });
  };

  useEffect(() => {
    let token = localStorage.getItem("token");
    let role = localStorage.getItem("role");
    if (token != null && token !== "") {
      if (role === "admin") {
        navigate("/admin");
      } else if (role === "groupe") {
        navigate("/groupe");
      } else if (role === "entreprise") {
        navigate("/entreprise");
      } else if (role === "acheteur") {
        navigate("/acheteur");
      }
    }
  }, []);

  function isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      window.navigator.userAgent
    );
  }

  return (
    <BasicLayout image={null}>
      <Card>
        {/* <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Login
          </MDTypography>
          <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <FacebookIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GitHubIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GoogleIcon color="inherit" />
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox> */}
        <div className="w-100 mb-5">
          <Button
            startIcon={
              <ArrowBack
                style={lang === "ar" ? { transform: "rotate(180deg)" } : {}}
                className="mx-2"
              ></ArrowBack>
            }
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.button,
              transition: "all .2s ease-in-out",
              background: theme.palette.secondary.light,
              color: theme.palette.secondary.dark,
              "&:hover": {
                background: theme.palette.secondary.dark,
                color: theme.palette.secondary.light,
              },
              height: "100%",
              overflow: "hidden",
              borderRadius: "none",
            }}
            className="py-2 px-5"
            onClick={() => {
              navigate("/");
            }}
          >
            {lang === "fr" ? "Retour" : lang === "en" ? "Go Back" : "العودة"}
          </Button>
        </div>
        <div className="row w-100 m-0 row-sm-reverse">
          <div className="col-12 col-lg-6 d-sm-block d-lg-none">
            <div
              className="w-100 d-flex align-items-center justify-content-center"
              style={{
                height: "100%",
              }}
            >
              <div>
                <img
                  alt=""
                  src={LogoIcon}
                  style={{
                    width: "100%",
                    height: "auto",
                    maxWidth: "200px",
                    margin: "auto",
                    marginBottom: "40px",
                  }}
                />
                <img
                  alt=""
                  src={IllutrationLogin}
                  style={{ width: "100%", height: "auto", maxWidth: "650px" }}
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 d-flex align-items-center justify-content-around">
            <MDBox
              component="form"
              role="form"
              className="p-2"
              style={{ width: "95%", direction: lang === "ar" ? "rtl" : "ltr" }}
            >
              <div className="w-100 d-flex align-items-center justify-content-center">
                <div className="w-100" style={{ maxWidth: "500px" }}>
                  <AuthLangues></AuthLangues>
                  <MDBox
                    style={{
                      // background: "#1e293b",
                      borderRadius: "12px",
                      marginBottom: "50px",
                      textAlign: lang === "ar" ? "right" : "left",
                      marginTop: "50px",
                    }}
                  >
                    <MDTypography
                      variant="h2"
                      color="text"
                      style={{
                        textTransform: "uppercase",
                        // fontSize: isMobileDevice() ? "18px" : "",
                      }}
                    >
                      {lang === "fr"
                        ? "Récupération de mot de passe"
                        : lang === "en"
                        ? "Reset Password"
                        : "إسترجاع كلمة السر"}
                    </MDTypography>
                  </MDBox>
                  {validated ? (
                    <>
                      <MDBox
                        style={{
                          marginBottom: "50px",
                          textAlign: lang === "ar" ? "right" : "left",
                        }}
                      >
                        <MDTypography
                          variant="h6"
                          color="danger"
                          style={{
                            textTransform: "uppercase",
                            // fontSize: isMobileDevice() ? "18px" : "",
                          }}
                        >
                          {lang === "fr"
                            ? "Votre demande de réinitialisation de mot de passe a été envoyée avec succès, vérifiez votre boîte email !"
                            : lang === "en"
                            ? "Your password reset request has been sent successfully, check your email inbox!"
                            : "لقد تم إرسال طلب إعادة تعيين كلمة المرور الخاصة بك بنجاح، تفقد صندوق البريد الإلكتروني الخاص بك!"}
                        </MDTypography>
                      </MDBox>
                    </>
                  ) : (
                    <>
                      <MDBox mb={2}>
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="full-name"
                          >
                            {lang === "fr"
                              ? "Email"
                              : lang === "en"
                              ? "Email"
                              : "البريد الإلكتروني"}
                          </label>
                          <input
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder={
                              lang === "fr"
                                ? "votre email"
                                : lang === "en"
                                ? "your email"
                                : "بريدك الإلكتروني"
                            }
                            onChange={(e) => setEmail(e.currentTarget.value)}
                          />
                        </div>
                      </MDBox>
                      <MDBox mt={4} mb={1}>
                        <ButtonBase
                          sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.button,
                            transition: "all .2s ease-in-out",
                            background: "#1e293b",
                            color: "#ffffff",
                            "&:hover": {
                              background: theme.palette.secondary.dark,
                              color: theme.palette.secondary.light,
                            },
                            height: "100%",
                            overflow: "hidden",
                            width: "100%",
                          }}
                          className="py-2 px-5"
                          onClick={() => {
                            Login();
                          }}
                        >
                          {lang === "fr" ? "Envoyer" : lang === "en" ? "Send" : "أرسل"}
                        </ButtonBase>
                      </MDBox>
                    </>
                  )}
                  <MDBox mt={3} mb={5} textAlign="center">
                    <MDTypography variant="button" color="text">
                      {validated
                        ? ""
                        : "" +
                          (lang === "fr"
                            ? "Vous avez déja un compte ?"
                            : lang === "en"
                            ? "Already have an account ?"
                            : "هل لديك حساب ؟") +
                          " "}
                      <MDTypography
                        variant="button"
                        color="info"
                        fontWeight="medium"
                        textGradient
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigate("/authentication/sign-in");
                        }}
                      >
                        {lang === "fr"
                          ? "Se connecter"
                          : lang === "en"
                          ? "Sign in"
                          : "تسجيل الدخول"}
                      </MDTypography>
                    </MDTypography>
                  </MDBox>
                </div>
              </div>
            </MDBox>
            <Divider orientation="vertical" className="d-none d-lg-block" />
          </div>
          <div className="col-12 col-lg-6 d-none d-lg-block">
            <div
              className="w-100 d-flex align-items-center justify-content-center mb-5"
              style={{
                height: "100%",
              }}
            >
              <div>
                <img
                  alt=""
                  src={LogoIcon}
                  style={{
                    width: "100%",
                    height: "auto",
                    maxWidth: "200px",
                    margin: "auto",
                    marginBottom: "40px",
                  }}
                />
                <img
                  alt=""
                  src={IllutrationLogin}
                  style={{ width: "100%", height: "auto", maxWidth: "650px" }}
                />
              </div>
            </div>
          </div>
          {/* {!isMobileDevice() ? (
          ) : (
            <></>
          )} */}
        </div>
        {/* <MDBox pt={4} pb={3} px={3}></MDBox> */}
      </Card>
    </BasicLayout>
  );
}

export default Basic;
