// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import Pagination from "@mui/material/Pagination";
import ReactPlayer from "react-player";

import CardHeader from "@mui/material/CardHeader";
// Data
import { Box, ButtonBase, Grid, LinearProgress, Typography, List as MuiList } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import MDButton from "components/MDButton";
import tw from "twin.macro";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { Row, Col, Dropdown, Badge } from "react-bootstrap";
import { BASE_URL_Image } from "services/server";
import { getServices } from "services/adminService";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import SearchSection from "../SearchSection";
import { useTheme } from "@mui/material/styles";

import { useSelector } from "react-redux";
// import { notificationsList } from "../../../NotificationFakeData";
import NotificationItem from "../../../NotificationItem";
import NotificationDetails from "../NotificationDatails";
import { getUserNotificaions } from "services/notificationsService";

const Container = tw.div`relative w-full`;

function List() {
  const lang = useSelector((state) => state.customization.lang);
  const [menu, setMenu] = useState(null);
  const [role, setRole] = useState("");
  const [search, setSearch] = useState("");
  const [entreprise, setEntreprise] = useState("");
  const [groupe, setGroupe] = useState("");

  const [userId, setUserId] = useState("");
  const [open, setOpen] = useState(false);
  const [notificationsList, setNotificationsList] = useState([]);
  const [notification, setNotification] = useState([]);

  const handleOpen = (notification) => {
    setNotification({ ...notification });
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setNotification(null);
  };

  const navigate = useNavigate();

  const [page, setPage] = useState({
    index: 1,
    limit: 6,
    totalItems: 0,
  });

  const [entreprise_id, setentreprise_id] = useState(null);
  const [services, setServices] = useState([]);

  useEffect(() => {
    let ent_id = localStorage.getItem("entreprise_id");
    console.log("entreprise_id");
    console.log(ent_id);
    setRole(localStorage.getItem("role"));
    setentreprise_id(parseInt(ent_id));
    let id = localStorage.getItem("id");
    setUserId(id);
  }, []);

  const { mutate: mutateNotifications, isLoading: isLoadingNotifications } = useMutation(
    (filters) => {
      return getUserNotificaions(filters);
    },
    {
      async onSuccess(data) {
        console.log(data);
        if (data && data.data) {
          setNotificationsList(
            data.data.map((el) => {
              return {
                ...el,
                image: el.image ? BASE_URL_Image + el.image : null,
                fav: el.fav ? parseInt(el.fav) === 1 : false,
                error: el.error ? parseInt(el.error) === 1 : false,
                info: el.info ? parseInt(el.info) === 1 : false,
                warning: el.warning ? parseInt(el.warning) === 1 : false,
                success: el.success ? parseInt(el.success) === 1 : false,
                new: el.new ? parseInt(el.new) === 1 : false,
                readed: el.readed ? parseInt(el.readed) === 1 : false,
              };
            })
          );
          // setPage((oldValue) => {
          //   return { ...oldValue, totalItems: parseInt(data.total) };
          // });
        } else {
        }
      },
      onError(error) {},
    }
  );

  useEffect(() => {
    let ent_id = localStorage.getItem("entreprise_id");
    mutateNotifications({
      limit: 6,
      offset: (page.index - 1) * 6,
    });
  }, [page.index]);

  const rechercher = () => {
    mutateNotifications({
      limit: page.limit,
      offset: (page.index - 1) * page.limit,
    });
  };

  const notify = (message) => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 3000,
      fontSize: "12px",
    });
  };

  const notify_success = (message) => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 3000,
    });
  };

  const theme = useTheme();

  return (
    <Card style={{ overflow: "hidden" }}>
      <div>
        <MDTypography
          variant="h6"
          gutterBottom
          className="px-3 py-2"
          style={{
            background:
              role === "acheteur" || role === "admin"
                ? theme.palette.secondary.dark
                : theme.palette.warning.dark,
            color:
              role === "acheteur" || role === "admin"
                ? theme.palette.secondary.light
                : theme.palette.warning.light,
            width: "max-content",
          }}
        >
          {lang === "fr"
            ? "Liste des Notifications"
            : lang === "en"
            ? "List of Notifications"
            : "قائمة الاشعارات"}
        </MDTypography>
      </div>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox style={{ width: "100%" }}>
          <Grid container spacing={3}>
            <Grid item xs={12} xl={4}></Grid>
            <Grid item xs={12} xl={8}>
              <MDBox>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <SearchSection
                      label={lang === "fr" ? "Produit" : lang === "en" ? "Product" : "المنتج"}
                      value={search}
                      valueChange={(value) => setSearch(value)}
                      valueFilter={rechercher}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SearchSection
                      label={lang === "fr" ? "Produit" : lang === "en" ? "Product" : "المنتج"}
                      value={search}
                      valueChange={(value) => setSearch(value)}
                      valueFilter={rechercher}
                    />
                  </Grid>
                </Grid>
              </MDBox>
            </Grid>
          </Grid>

          <div className="w-100 py-2 px-3 mt-2 d-flex align-items-center justify-content-center">
            <MDBox display="flex" alignItems="center" lineHeight={0}>
              <MDTypography variant="button" fontWeight="regular" style={{ color: "grey" }}>
                &nbsp;
                <strong
                  style={{
                    color:
                      role === "acheteur" || role === "admin"
                        ? theme.palette.secondary.dark
                        : theme.palette.warning.dark,
                  }}
                >
                  <>
                    {page.totalItems ?? 0}{" "}
                    {page.totalItems > 1
                      ? "" +
                        (lang === "fr"
                          ? "Notifications"
                          : lang === "en"
                          ? "Notificaitons"
                          : "اشعارات")
                      : "" +
                        (lang === "fr" ? "Notification" : lang === "en" ? "Notificaiton" : "اشعار")}
                  </>
                </strong>{" "}
                {lang === "fr" ? "en total" : lang === "en" ? "in total" : "في المجمل"}
              </MDTypography>
            </MDBox>
          </div>
        </MDBox>
      </MDBox>
      <MDBox>
        <Container className="p-4">
          <MuiList
            sx={{
              width: "100%",
              py: 0,
              borderRadius: "10px",
              [theme.breakpoints.down("md")]: {
                maxWidth: 300,
              },
              "& .MuiListItemSecondaryAction-root": {
                top: 22,
              },
              "& .MuiDivider-root": {
                my: 0,
              },
              "& .list-container": {
                pl: 7,
              },
            }}
          >
            {notificationsList.map((notif) => {
              return (
                <>
                  <NotificationItem notif={notif} handleOpen={handleOpen}></NotificationItem>
                </>
              );
            })}
          </MuiList>
          <Row style={{ paddingTop: "10px", borderTop: "1px solid #e5e5e5 ", marginTop: "10px" }}>
            <Col xs={12} className="d-flex justify-content-center align-items-center">
              {/* <Pagination>{items}</Pagination> */}
              <Pagination
                color="light"
                count={
                  parseInt(page.totalItems) % page.limit == 0
                    ? parseInt(parseInt(page.totalItems) / page.limit)
                    : parseInt(parseInt(page.totalItems) / page.limit) + 1
                }
                page={page.index}
                onChange={(event, value) => {
                  setPage((oldValue) => {
                    return { ...oldValue, index: value };
                  });
                }}
              />
            </Col>
            {page.totalItems == 0 && !isLoadingNotifications && (
              <Col xs={12} className="d-flex justify-content-center align-items-center">
                <MDTypography
                  variant="h6"
                  color="light"
                  style={{
                    color:
                      role === "acheteur" || role === "admin"
                        ? theme.palette.secondary.dark
                        : theme.palette.warning.dark,
                  }}
                >
                  {lang === "fr"
                    ? "Aucune notification"
                    : lang === "en"
                    ? "No Notifications"
                    : "لا توجد إشعارات"}
                </MDTypography>
              </Col>
            )}
          </Row>
        </Container>
      </MDBox>
      <NotificationDetails
        openAdd={open}
        userId={userId}
        setOpenAdd={handleOpen}
        handleClose={handleClose}
        notification={notification}
      ></NotificationDetails>

      <Modal
        show={isLoadingNotifications}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="myModalLoading"
      >
        <Modal.Body style={{ overflow: "hidden" }}>
          <Box sx={{ width: "100%" }}>
            <LinearProgress color="secondary" />
          </Box>
        </Modal.Body>
      </Modal>
    </Card>
  );
}

export default List;
