import React, { useEffect, useRef, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as SvgDecoratorBlob } from "images/svg-decorator-blob-6.svg";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import { useNavigate } from "react-router-dom";
import { getAbonnement } from "services/visiteurService";
import { useMutation } from "@tanstack/react-query";
import { Col, Modal } from "react-bootstrap";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import micro_abonnement_image from "assets/images/micro_abonnement.png";
import junior_abonnement_image from "assets/images/junior_abonnement.png";
import confort_abonnement_image from "assets/images/confort_abonnement.png";
import groupe_abonnement_image from "assets/images/groupe_abonnement.png";
import publication_abonnement_image from "assets/images/publication_abonnement.png";
import edahabiaImage from "images/edahabia.png";
import virementImage from "images/virement.png";
import virementHoverImage from "images/virement_hover.png";
import publicitaire_abonnement_image from "assets/images/publicitaire_abonnement.png";
import { inscriptionAbonnementsList } from "services/adminService";
import { Box, Button, ButtonBase, Divider, LinearProgress, Switch } from "@mui/material";
import MDButton from "components/MDButton";
import { toast } from "react-toastify";
import { demandeInscription } from "services/visiteurService";
import { demandeOption } from "services/visiteurService";
import { demandePub } from "services/visiteurService";
import AbonnementItem from "./AbonnementItem";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
const HeaderContainer = tw.div`mt-10 w-full flex flex-col items-center`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const PlansContainer = tw.div`flex justify-around flex-col lg:flex-row items-center lg:items-stretch relative`;
const Plan = styled.div`
  ${tw`w-full max-w-sm mt-16 lg:mr-8 lg:last:mr-0 text-center px-8 rounded-lg shadow relative pt-2 text-gray-900 bg-white flex flex-col`}
  .planHighlight {
    ${tw`rounded-t-lg absolute top-0 inset-x-0 h-2`}
  }

  ${(props) =>
    props.featured &&
    css`
      background: rgb(100,21,255);
      background: linear-gradient(135deg, rgba(100,21,255,1) 0%, rgba(128,64,252,1) 100%);
background: rgb(85,60,154);
background: linear-gradient(135deg, rgba(85,60,154,1) 0%, rgba(128,90,213,1) 100%);
background: rgb(76,81,191);
background: linear-gradient(135deg, rgba(76,81,191,1) 0%, rgba(102,126,234,1) 100%);
      ${tw`bg-primary-500 text-gray-100`}
      .planHighlight {
        ${tw`hidden`}
      }
      .duration {
        ${tw`text-gray-200!`}
      }
      ${PlanFeatures} {
        ${tw`border-indigo-500`}
      }
      .feature:not(.mainFeature) {
        ${tw`text-gray-300!`}
      }
      ${BuyNowButton} {
        ${tw`bg-gray-100 text-primary-500 hocus:bg-gray-300 hocus:text-primary-800`}
    `}
`;

const PlanHeader = styled.div`
  ${tw`flex flex-col uppercase leading-relaxed pt-8 pb-3`}
  .name {
    ${tw`font-bold`}
    font-size : 0.85rem;
  }
  .price {
    ${tw`font-bold text-4xl sm:text-5xl my-1`}
  }
  .duration {
    ${tw`text-gray-500 font-bold tracking-widest`}
  }
`;
const PlanFeatures = styled.div`
  ${tw`flex flex-col -mx-8 px-8 py-3 border-t-2 border-b-2 flex-1 `}
  .feature {
    ${tw`mt-2 first:mt-0 font-medium`}
    &:not(.mainFeature) {
      ${tw`text-gray-600`}
    }
  }
  .mainFeature {
    ${tw`text-xl font-bold tracking-wide`}

    font-size : 0.85rem !important;
  }
`;

const PlanAction = tw.div`px-4 py-8`;
const BuyNowButton = styled(PrimaryButtonBase)`
  ${tw`rounded-full uppercase tracking-wider py-4 w-full text-sm hover:shadow-xl transform hocus:translate-x-px hocus:-translate-y-px focus:shadow-outline border-none`}
`;

const DecoratorBlob = styled(SvgDecoratorBlob)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-25 transform -translate-x-1/2 translate-y-1/2`}
`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-black placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const Textarea = styled(Input).attrs({ as: "textarea" })`
  ${tw`h-24`}
`;

export default function ({
  subheading = "Tarification",
  heading = "Abonnements.",
  description = "",
  primaryButtonText = "Acheter",
}) {
  const navigate = useNavigate();
  const lang = useSelector((state) => state.customization.lang);
  const theme = useTheme();

  useEffect(() => {
    if (document.getElementById("header_smart") != null)
      document.getElementById("header_smart").classList.add("header_smart_solution");
    return () => {
      if (document.getElementById("header_smart") != null)
        document.getElementById("header_smart").classList.remove("header_smart_solution");
    };
  }, []);
  const [items, setItems] = useState(inscriptionAbonnementsList);

  const [itemsEtudes, setItemsEtudes] = useState([
    {
      id: 6,
      name: "Marketing & Communication",
      name_en: "Marketing & Communication",
      name_ar: "التسويق والاتصالات",
      price: 0,
      duration: "",
      featured: false,
      mainFeature: "",
      mainFeature_en: "",
      mainFeature_ar: "",
      note: "",
      features: ["Bannières d'accueil", "Vidéo à la une", "Autres bannières sur la plate-forme "],
      features_en: ["Home banners", "Featured video", "Other banners around the platform."],
      features_ar: ["لافتات الصفحة الرئيسية", "فيديو مميز", "لافتات أخرى على المنصة"],
      comptes: 1,
      secteurs: 100,
      services: 100,
      mensuelle: 0,
      trimestrielle: 0,
      semestrielle: 0,
      anuelle: 1,
      activated: true,
      archivated: false,
      status: 1,
      linearColor: "#1e293b",
      created_at: "2023-05-27 22:40:39",
      updated_at: "2023-06-04 05:08:47",
    },
    // {
    //   id: 7,
    //   name: "Accompagnement Juridique",
    //   name_en: "Legal support",
    //   name_ar: "الدعم القانوني",
    //   price: 0,
    //   duration: "",
    //   featured: false,
    //   mainFeature: "",
    //   mainFeature_en: "",
    //   mainFeature_ar: "",
    //   note: "",
    //   features: ["réglementations en vigueur"],
    //   features_en: ["current regulations"],
    //   features_ar: ["اللوائح الحالية"],
    //   comptes: 1,
    //   secteurs: 100,
    //   services: 100,
    //   mensuelle: 0,
    //   trimestrielle: 0,
    //   semestrielle: 0,
    //   anuelle: 1,
    //   activated: true,
    //   archivated: false,
    //   status: 1,
    //   linearColor: "#1e293b",
    //   created_at: "2023-05-27 22:40:39",
    //   updated_at: "2023-06-04 05:08:47",
    // },
    {
      id: 1,
      name: "Accompagnement Commercial",
      name_en: "Sales support",
      name_ar: "الدعم التجاري",
      price: 0,
      duration: "",
      featured: false,
      mainFeature: "",
      mainFeature_en: "",
      mainFeature_ar: "",
      note: "",
      features: ["réglementations en vigueur", "indices économiques"],
      features_en: ["current regulations", "economic indicators"],
      features_ar: ["اللوائح الحالية", "المؤشرات الاقتصادية"],
      comptes: 1,
      secteurs: 100,
      services: 100,
      mensuelle: 0,
      trimestrielle: 0,
      semestrielle: 0,
      anuelle: 1,
      activated: true,
      archivated: false,
      status: 1,
      linearColor: "#1e293b",
      created_at: "2023-05-27 22:40:39",
      updated_at: "2023-06-04 05:08:47",
    },
    // {
    //   id: 3,
    //   name: "Accompagnement de soutien",
    //   name_en: "Support services",
    //   name_ar: "خدمات الدعم",
    //   price: 0,
    //   duration: "",
    //   featured: false,
    //   mainFeature: "",
    //   mainFeature_en: "",
    //   mainFeature_ar: "",
    //   note: "",
    //   features: ["RDV d’affaire", "Visite et procédures"],
    //   features_en: ["Business meetings", "Visits and procedures"],
    //   features_ar: ["اجتماعات العمل", "الزيارات والإجراءات"],
    //   comptes: 1,
    //   secteurs: 100,
    //   services: 100,
    //   mensuelle: 1,
    //   trimestrielle: 0,
    //   semestrielle: 0,
    //   anuelle: 0,
    //   activated: true,
    //   archivated: false,
    //   status: 1,
    //   linearColor: "#1e293b",
    //   created_at: "2023-05-27 22:40:39",
    //   updated_at: "2023-06-04 05:08:47",
    // },
    {
      id: 2,
      name: "Accompagnement personnalisé",
      name_en: "Personalized support",
      name_ar: "الدعم المخصص",
      price: 0,
      duration: "",
      featured: false,
      mainFeature: "à la demande du partenaire",
      mainFeature_en: "at partner's request",
      mainFeature_ar: "بناءً على طلب الشريك",
      note: "",
      features: ["RDV d'affaire et visite", "prospection orientée"],
      features_en: ["Business meetings and visits", "targeted prospecting"],
      features_ar: ["اجتماعات العمل والزيارات", "التنقيب المستهدف"],
      comptes: 1,
      secteurs: 100,
      services: 100,
      mensuelle: 0,
      trimestrielle: 0,
      semestrielle: 0,
      anuelle: 1,
      activated: true,
      archivated: false,
      status: 1,
      linearColor: "#1e293b",
      created_at: "2023-05-27 22:40:39",
      updated_at: "2023-06-04 05:08:47",
    },
    // {
    //   id: 4,
    //   name: "Accompagnement pour prospection orientée",
    //   name_en: "Support for targeted prospecting",
    //   name_ar: "دعم التنقيب المستهدف",
    //   price: 0,
    //   duration: "",
    //   featured: false,
    //   mainFeature: "",
    //   mainFeature_en: "",
    //   mainFeature_ar: "",
    //   note: "",
    //   features: [],
    //   features_en: [],
    //   features_ar: [],
    //   comptes: 1,
    //   secteurs: 100,
    //   services: 100,
    //   mensuelle: 0,
    //   trimestrielle: 0,
    //   semestrielle: 0,
    //   anuelle: 1,
    //   activated: true,
    //   archivated: false,
    //   status: 1,
    //   linearColor: "#1e293b",
    //   created_at: "2023-05-27 22:40:39",
    //   updated_at: "2023-06-04 05:08:47",
    // },
  ]);

  const { mutate: mutateLoading, isLoading: isLoadingList } = useMutation(() => getAbonnement(), {
    async onSuccess(data) {
      console.log("abdou");
      console.log(data);
      if (data && data.data) {
        setItems(data.data);
      } else {
      }
    },
    onError(error) {},
  });

  useEffect(() => {
    // mutateLoading() ;
  }, []);

  const [openAdd, setOpenAdd] = useState(false);

  const [abonnement, setAbonnement] = useState(null);

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [Message, setMessage] = useState("");
  const [Phone, setPhone] = useState("");
  const [dejaUser, setDejaUser] = useState(false);

  const [modePaiment, setModePaiment] = useState(1); // dahabia : 1 , virement : 2

  const handleOpenAdd = (abonnement) => {
    setAbonnement(abonnement);
    setOpenAdd(true);
  };
  const handleCloseAdd = () => {
    setAbonnement(null);
    setUsername("");
    setEmail("");
    setPhone("");
    setMessage("");
    setDejaUser(false);
    setModePaiment(1);
    setOpenAdd(false);
  };
  useEffect(() => {
    console.log("abonnement");
    console.log(abonnement);
  }, [abonnement]);

  const [nomEntreprise, setNomEntreprise] = useState("");
  const [pictureUrl, setPictureUrl] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    console.log(selectedFile);
    if (selectedFile) {
      let url = URL.createObjectURL(selectedFile);
      if (url) {
        console.log(url);
        setPictureUrl(url);
      }
    }
  }, [selectedFile]);

  const lancerPaiment = () => {
    var user = {
      nom: username,
      email,
      phone: Phone,
      message: Message,
      alreadyUser: dejaUser,
      abonnement_id: abonnement.id,
      // file: selectedFile,
    };
    console.log(user);

    var valid = true;

    if (!username || username === "") {
      notify("Entrez votre  SVP !");
      valid = false;
    } else if (
      !email ||
      email === "" ||
      !String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      notify("Entrez votre adresse email SVP !");
      valid = false;
    } else if (!Phone || Phone === "") {
      notify("Veuillez entrer votre numéro de téléphone SVP !");
      valid = false;
    }
    // else if(abonnement != null && abonnement.id === 1 && (!pictureUrl || pictureUrl === "")) {
    //   notify("Vous devez entrer une preuve de paimenet") ;
    // }
    else {
      notify_success("valid form");
      // setStartPaiment(true) ;
      if (abonnement.id === 99999) {
        mutateAddPub(user);
      } else {
        mutateAdd(user);
      }
      // navigate("/admin");
    }
  };

  const notify = (message) => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 3000,
      fontSize: "12px",
    });
  };

  const notify_success = (message) => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 3000,
    });
  };

  const { mutate: mutateAdd, isLoading: isLoadingAdd } = useMutation(
    (userData) => demandeOption(userData),
    {
      async onSuccess(data) {
        console.log(data);

        if (data.success) {
          notify_success("Votre demande a été envoyée avec succès");
          handleCloseAdd();
        } else if (data.message !== "") {
          notify(data.message);
        } else {
          notify("error");
        }
      },
      onError(error) {
        notify("error");
        if (Array.isArray(error.response.data.error)) {
          error.response.data.error.forEach((el) => console.log(el.message));
        } else {
          console.log(error.response.data.message);
        }
      },
    }
  );

  const { mutate: mutateAddPub, isLoading: isLoadingAddPub } = useMutation(
    (userData) => demandePub(userData),
    {
      async onSuccess(data) {
        console.log(data);

        if (data.success) {
          notify_success("Votre demande a été envoyée avec succès");
          handleCloseAdd();
        } else if (data.message !== "") {
          notify(data.message);
        } else {
          notify("error");
        }
      },
      onError(error) {
        notify("error");
        if (Array.isArray(error.response.data.error)) {
          error.response.data.error.forEach((el) => console.log(el.message));
        } else {
          console.log(error.response.data.message);
        }
      },
    }
  );

  return (
    <>
      <Container>
        <ContentWithPaddingXl>
          <HeaderContainer>
            <Heading
              style={{
                marginTop: "0px",
                fontSize: "19px",
                textAlign: lang === "ar" ? "right" : "left",
              }}
            >
              {lang === "fr"
                ? "Nos Services"
                : lang === "en"
                ? "Our services"
                : "الخدمات التي نقدمها"}
            </Heading>
            {description && <Description>{description}</Description>}
          </HeaderContainer>
          <PlansContainer
            className="row w-100 ml-0 pt-5 m-0"
            style={{ justifyContent: "flex-start" }}
          >
            {itemsEtudes.map((plan, index) => (
              // <Plan key={index} featured={plan.featured} className="col-sm-12 col-md-4 col-lg-3">
              //   <div className="w-100 d-flex align-items-center justify-content-center">
              //     <PlanHeader>
              //       <span className="name">{plan.name}</span>
              //     </PlanHeader>
              //   </div>
              //   {!plan.featured && (
              //     <div className="planHighlight" style={{ background: plan.linearColor }} />
              //   )}
              //   {plan.price > 0 && (
              //     <PlanHeader className="py-0">
              //       <span className="price">{plan.price} DA</span>
              //       <span className="duration">{plan.duration}</span>
              //     </PlanHeader>
              //   )}
              //   <PlanFeatures style={{ marginTop: "0" }} className="py-0 my-1">
              //     <span
              //       className="feature mainFeature"
              //       style={{ fontSize: "13px", color: "#6415ff" }}
              //     >
              //       {plan.note}
              //     </span>

              //     <span className="feature mainFeature">{plan.mainFeature}</span>
              //     {plan.features.map((feature, index) => (
              //       <li
              //         key={index}
              //         className="feature"
              //         style={{ fontSize: "12px", textAlign: "left" }}
              //       >
              //         {feature}
              //       </li>
              //     ))}
              //   </PlanFeatures>
              //   <PlanAction>
              //     <div className="w-100 d-flex align-items-center justify-content-center">
              //       <Button
              //         variant="outlined"
              //         color="primary"
              //         style={{
              //           color: plan.linearColor,
              //           borderColor: plan.linearColor,
              //           fontSize: "14px",
              //         }}
              //         onClick={() => {
              //           handleOpenAdd(plan);
              //         }}
              //       >
              //         Demandez un devis
              //       </Button>
              //     </div>
              //   </PlanAction>
              // </Plan>
              <Col
                key={index}
                xs={12}
                md={6}
                lg={4}
                className="mb-5 px-2 d-flex align-items-start justify-content-center"
              >
                <AbonnementItem
                  plan={plan}
                  index={index}
                  handleOpenAdd={handleOpenAdd}
                ></AbonnementItem>
              </Col>
            ))}
          </PlansContainer>
        </ContentWithPaddingXl>
      </Container>
      <Modal
        show={isLoadingList || isLoadingAdd}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body style={{ overflow: "hidden" }}>
          <Box sx={{ width: "100%" }}>
            <LinearProgress color="secondary" />
          </Box>
        </Modal.Body>
      </Modal>

      <Modal
        show={openAdd}
        onHide={handleCloseAdd}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ fontSize: "0.85em", textAlign: lang === "ar" ? "right" : "left" }}
          >
            {abonnement !== null
              ? lang === "fr"
                ? abonnement.name
                : lang === "en"
                ? abonnement.name_en
                : abonnement.name_ar
              : ""}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ direction: lang === "ar" ? "rtl" : "" }}>
          {abonnement !== null && (
            <div style={{ width: "100%" }}>
              <div className="w-100 d-flex justify-content-center align-items-center">
                <div style={{ maxWidth: "500px" }}>
                  <div className="flex-auto">
                    <div className="relative w-full mb-3 mt-8">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="full-name"
                      >
                        {lang === "fr"
                          ? "Dénomination"
                          : lang === "en"
                          ? "Denomination"
                          : "التسمية"}
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder={
                          lang === "fr"
                            ? "Votre dénomination"
                            : lang === "en"
                            ? "Your denomination"
                            : "التسمية خاصتك"
                        }
                        onChange={(e) => setUsername(e.currentTarget.value)}
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="email"
                      >
                        {lang === "fr" ? "Email" : lang === "en" ? "Email" : "البريد الإلكتروني"}
                      </label>
                      <input
                        type="email"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder={
                          lang === "fr"
                            ? "votre email"
                            : lang === "en"
                            ? "your email"
                            : "بريدك الإلكتروني"
                        }
                        onChange={(e) => setEmail(e.currentTarget.value)}
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="email"
                      >
                        {lang === "fr"
                          ? "Numéro Téléphone"
                          : lang === "en"
                          ? "Phone number"
                          : "رقم الهاتف"}
                      </label>
                      <input
                        type="email"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder={
                          lang === "fr"
                            ? "Votre numéro du téléphone"
                            : lang === "en"
                            ? "Your phone number"
                            : "رقم هاتفك"
                        }
                        onChange={(e) => setPhone(e.currentTarget.value)}
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="message"
                      >
                        {lang === "fr" ? "Message" : lang === "en" ? "Message" : "رسالتك"}
                      </label>
                      <textarea
                        rows="4"
                        cols="80"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                        placeholder={
                          lang === "fr"
                            ? "votre message..."
                            : lang === "en"
                            ? "your message..."
                            : "رسالتك..."
                        }
                        onChange={(e) => setMessage(e.currentTarget.value)}
                      />
                    </div>

                    {/* <div className="text-center mt-6 d-flex align-items-center">
                      <Switch
                        checked={dejaUser}
                        onChange={() => {
                          setDejaUser((value) => !value);
                        }}
                      />
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold "
                        htmlFor="message"
                        onClick={() => {
                          setDejaUser((value) => !value);
                        }}
                      >
                        &nbsp;&nbsp;
                        {lang === "fr"
                          ? "Êtes-vous déjà inscrit ?"
                          : lang === "en"
                          ? "Are you already registered?"
                          : "هل أنت مسجل بالفعل؟"}
                      </label>
                    </div> */}
                  </div>
                </div>
              </div>

              {/* {abonnement.id === 1 && 
                  <>
                      <Divider></Divider> */}
              {/* <MDBox
                              mx={2}
                              my={1}
                              py={3}
                              px={2}
                              variant="gradient"
                              bgColor={ modePaiment == 1 ? "info" : "light"}
                              borderRadius="lg"
                              coloredShadow={ modePaiment == 1 ? "info" : "light"}
                              style={{cursor : "pointer"}}
                              onClick={()=>{setModePaiment(1) ; }}
                            >
                              <div className="w-100 d-flex justify-content-around align-items-center">
                                  <div className="rounded-circle  mx-2" style={{height : "20px" , width : "20px" , backgroundColor :  modePaiment == 1 ?  "#1f4394" : "white"}}></div>
                                  <div className="mx-2">
                                    <img
                                      alt="" 
                                      src={edahabiaImage}
                                      style={{width : "50px" , height : "auto"}}
                                    />
                                  </div>
                                  <div className="mx-2" style={{flex : 1 , fontSize : "14px" , fontWeight : "bold" , color : modePaiment == 1 ? "white" :  "black"}}>
                                    Paiement avec EDAHABIA
                                  </div>
                              </div>
                            </MDBox>

                            
                            <MDBox
                              mx={2}
                              my={1}
                              py={3}
                              px={2}
                              variant="gradient"
                              bgColor={modePaiment === 2 ? "info" : "light"}
                              borderRadius="lg"
                              coloredShadow={modePaiment === 2 ? "info" : "light"}
                              style={{cursor : "pointer"}}
                              onClick={()=>{ setModePaiment(2) ; }}
                            >
                              <div className="w-100 d-flex justify-content-around align-items-center">
                                  <div className="rounded-circle  mx-2" style={{height : "20px" , width : "20px" , backgroundColor : modePaiment == 2 ? "#1f4394" :  "white"}}></div>
                                  <div className="mx-2">
                                    <img
                                      alt="" 
                                      src={modePaiment == 2  ? virementHoverImage :  virementImage}
                                      style={{width : "50px" , height : "auto"}}
                                    />
                                  </div>
                                  <div className="mx-2" style={{flex : 1 , fontSize : "14px" , fontWeight : "bold" , color : modePaiment == 2 ? "white" :  "black"}}>
                                    Faire un virement 
                                  </div>
                              </div>
                            </MDBox>

                            {modePaiment == 2 &&
                              <div 
                                style={{width : "80%"   , margin : "20px auto" , display : "flex" , justifyContent : "center" ,alignItems : "center" , cursor : "pointer" , overflow : "hidden" }}
                                onClick={handleButtonClick}
                                >
                                <input  accept="image/*" ref={fileInputRef} onClick={handleFileChange}  onChange={handleFileChange} type="file" style={{display : "none"}}/>
                                <div>
                                  <div className="d-flex justify-content-center align-items-center">
                                    {pictureUrl && pictureUrl != "" && <img style={{height : "80px" , width : "auto" , margin : "auto" }} src={pictureUrl} />}
                                  </div>
                                  {pictureUrl && pictureUrl != "" && 
                                    <MDTypography style={{fontSize : "13px"  , width: "100%" , textAlign: "center" , marginTop: "5px" , fontWight: "bold" , color: "#3e98ef"}}>Changer la photo</MDTypography>
                                    }

                                </div>

                                {(!pictureUrl || pictureUrl === "") && 
                                  <MDTypography style={{fontSize : "13px"}}>Ajouter une photo comme un preuve</MDTypography>
                                }
                              </div>
                            } */}
              {/* </>
                } */}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer style={{ direction: lang === "ar" ? "rtl" : "" }}>
          {abonnement != null && (
            <>
              <ButtonBase
                sx={{
                  ...theme.typography.commonAvatar,
                  ...theme.typography.button,
                  transition: "all .2s ease-in-out",
                  background: theme.palette.secondary.light,
                  color: theme.palette.secondary.dark,
                  "&:hover": {
                    background: theme.palette.secondary.dark,
                    color: theme.palette.secondary.light,
                  },
                  height: "100%",
                  overflow: "hidden",
                }}
                className="py-2 px-5"
                onClick={handleCloseAdd}
              >
                {lang === "fr" ? "Annuler" : lang === "en" ? "Cancel" : "إلغاء"}
              </ButtonBase>

              <ButtonBase
                sx={{
                  ...theme.typography.commonAvatar,
                  ...theme.typography.button,
                  transition: "all .2s ease-in-out",
                  background: theme.palette.primary.dark,
                  color: theme.palette.primary.light,
                  "&:hover": {
                    background: theme.palette.primary.light,
                    color: theme.palette.primary.dark,
                  },
                  height: "100%",
                  overflow: "hidden",
                }}
                className="py-2 px-5"
                onClick={lancerPaiment}
              >
                {lang === "fr" ? "Envoyer" : lang === "en" ? "Send" : "إرسال"}
              </ButtonBase>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
