import { useMaterialUIController, setMiniSidenav } from 'context';
import Sidenav from 'examples/Sidenav';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';

// Material Dashboard 2 React routes
import routes from "routes";
function IdirLayout() {
    
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };
  return (
    <>
          <Sidenav
            color={sidenavColor}
            // brand={brandWhite}
            brandName="Signaux"
            brandType="idir"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Outlet />
        </>
  );
}

export default IdirLayout;