import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";

import { useTheme } from "@mui/material/styles";
// Data
import data from "layoutsEntreprises/UsersList/components/data";
import { Box, Grid, LinearProgress, Pagination } from "@mui/material";
import Modal from "react-bootstrap/Modal";

import tw from "twin.macro";
import styled from "styled-components";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";

import { BASE_URL_Image } from "services/server";
import { useMutation } from "@tanstack/react-query";
import dayjs from "dayjs";
import { getDemandeInscriptions } from "services/adminService";
import { updateDemandeInscription } from "services/adminService";
import { inscriptionAbonnementsList } from "services/adminService";

import SearchSection from "components/search/SearchSection";
import { useSelector } from "react-redux";
import { getUsers } from "services/adminService";
import { updateUser } from "services/adminService";

function List({ type }) {
  const dateToDateJS = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();

    const dateString = `${year}/${month}/${day}`;
    console.log("init date debut");
    console.log(dateString);
    return dayjs(dateString);
  };
  const lang = useSelector((state) => state.customization.lang);

  const [publireportage, setPublireportage] = useState(null);

  const [page, setPage] = useState({
    index: 1,
    limit: 12,
    totalItems: 0,
  });
  const [search, setSearch] = useState("");
  const [dateDebut, setDateDebut] = useState(dateToDateJS(new Date()));
  const [dateFin, setDateFin] = useState(null);

  const [paginationItems, setPaginationItems] = useState([]);

  const [items, setItems] = useState([]);

  const [open, setOpen] = useState(false);

  const handleOpen = (publireportage) => {
    console.log(publireportage);
    setPublireportage(publireportage);
    setOpen(true);
  };
  const handleClose = () => {
    setPublireportage(null);
    setOpen(false);
  };

  const [openArchivage, setOpenArchivage] = useState(false);
  const handleOpenArchivage = (publireportage) => {
    console.log(publireportage);
    setPublireportage(publireportage);
    setOpenArchivage(true);
  };
  const handleCloseArchivage = () => {
    setPublireportage(null);
    setOpenArchivage(false);
  };

  const { mutate, isLoading } = useMutation((userInfo) => getUsers(userInfo), {
    async onSuccess(data) {
      console.log(data);
      if (data && data.data) {
        setItems(
          data.data.map((it) => {
            return {
              ...it,
            };
          })
        );
        setPage((oldValue) => {
          return { ...oldValue, totalItems: parseInt(data.total) };
        });
      } else {
      }
    },
    onError(error) {},
  });

  useEffect(() => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    setDateFin(dateToDateJS(tomorrow));
    setSearch("");
    setPage((oldValue) => {
      return { ...oldValue, index: 1 };
    });
    mutate({
      role: type === "vendeur" ? "entreprise" : type,
      filter: "",
      limit: 5,
      offset: 0,
    });
  }, [type]);

  const [openValidation, setOpenValidation] = useState(false);
  const handleOpenValidation = (publireportage) => {
    console.log(dateDebut);
    console.log(dateFin);
    setPublireportage(publireportage);
    setOpenValidation(true);
    console.log(publireportage);
  };
  const handleCloseValidation = () => {
    setDateDebut(dateToDateJS(new Date()));
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    setDateFin(dateToDateJS(tomorrow));
    setPublireportage(null);
    setOpenValidation(false);
  };

  const [openAffichage, setOpenAffichage] = useState(false);
  const handleOpenAffichage = (publireportage) => {
    setPublireportage(publireportage);
    setOpenAffichage(true);
    console.log(publireportage);
  };
  const handleCloseAffichage = () => {
    setOpenAffichage(false);
  };

  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useMutation(
    (userInfo) => updateUser(userInfo),
    {
      async onSuccess(data) {
        console.log(data);
        if (data && data.data) {
          setItems((oldValue) => {
            return oldValue.map((el) => {
              if (el.id === data.data.id) {
                return {
                  ...el,
                  bloque: data.data.bloque,
                };
              }
              return el;
            });
          });
        } else {
        }
      },
      onError(error) {},
    }
  );

  const updateAction = (userInfo) => {
    mutateUpdate(userInfo);
  };

  const { columns, rows } = data({
    handleOpen,
    handleOpenValidation,
    handleOpenAffichage,
    handleOpenArchivage,
    items: items,
    updateAction,
    lang,
  });

  const rechercher = () => {
    mutate({
      role: type === "vendeur" ? "entreprise" : type,
      filter: search,
      limit: 5,
      offset: 0,
    });
  };

  const { mutate: updateDemandes, isLoading: isLoadingupdateDemandes } = useMutation(
    (userInfos) => updateDemandeInscription(userInfos),
    {
      async onSuccess(data) {
        console.log(data);
        if (data) {
          // mutate();
          // setOpenValidation((oldValue) => {return {id : null , value : false}});
        } else {
        }
      },
      onError(error) {},
    }
  );

  const annulerItem = () => {
    updateDemandes({ id: publireportage.id, status: -1 });
    handleClose();
  };

  const validerItem = () => {
    console.log(publireportage);
    updateDemandes({
      id: publireportage.id,
      status: 1,
      date_debut: dateDebut.format("YYYY/MM/DD"),
      date_fin: dateFin.format("YYYY/MM/DD"),
    });
    handleCloseValidation();
  };

  const archiverItem = () => {
    updateDemandes({ id: publireportage.id, archive: 1 });
    handleCloseArchivage();
  };
  const theme = useTheme();

  return (
    <Card style={{ overflow: "hidden" }}>
      <div>
        <MDTypography
          variant="h6"
          gutterBottom
          className="px-3 py-2"
          style={{
            background: theme.palette.secondary.dark,
            color: theme.palette.secondary.light,
            width: "max-content",
          }}
        >
          {type === "vendeur"
            ? "" +
              (lang === "fr"
                ? "Liste des vendeur"
                : lang === "en"
                ? "List of sellers"
                : "قائمة البائعين")
            : "" +
              (lang === "fr"
                ? "Liste des acheteur"
                : lang === "en"
                ? "List of buyers"
                : "قائمة المشترين")}
        </MDTypography>
      </div>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox style={{ width: "100%" }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MDBox>
                <Grid container spacing={3}>
                  <Grid item xs={12} xl={4}></Grid>
                  <Grid item xs={12} xl={8}>
                    <MDBox>
                      <SearchSection
                        label={lang === "fr" ? "Utilisateur" : lang === "en" ? "User" : "المستخدم"}
                        value={search}
                        valueChange={(value) => setSearch(value)}
                        valueFilter={rechercher}
                      />
                    </MDBox>
                  </Grid>
                </Grid>

                <div className="w-100 py-2 px-3 mt-2 d-flex align-items-center justify-content-center">
                  <MDBox display="flex" alignItems="center" lineHeight={0}>
                    <MDTypography variant="button" fontWeight="regular" style={{ color: "grey" }}>
                      &nbsp;
                      <strong
                        style={{
                          color: theme.palette.secondary.dark,
                        }}
                      >
                        {items != null ? items.length : 0}{" "}
                        {items != null && items.length > 1
                          ? "" +
                            (lang === "fr" ? "Utilisateurs" : lang === "en" ? "Users" : "مستخدمين")
                          : "" +
                            (lang === "fr" ? "Utilisateur" : lang === "en" ? "User" : "مستخدم")}
                      </strong>{" "}
                      {lang === "fr" ? "en total" : lang === "en" ? "in total" : "في المجمل"}
                    </MDTypography>
                  </MDBox>
                </div>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <MDBox>
        <DataTable
          table={{ columns, rows }}
          showTotalEntries={false}
          isSorted={false}
          noEndBorder
          entriesPerPage={false}
        />
        <div className="w-100 d-flex justify-content-center align-items-center">
          <Pagination
            color="light"
            count={
              parseInt(page.totalItems) % 5 == 0
                ? parseInt(parseInt(page.totalItems) / 5)
                : parseInt(parseInt(page.totalItems) / 5) + 1
            }
            page={page.index}
            onChange={(event, value) => {
              setPage((oldValue) => {
                return { ...oldValue, index: value };
              });
            }}
          />
        </div>
        {page.totalItems === 0 && !isLoading && (
          <div className="w-100 d-flex justify-content-center align-items-center">
            <MDTypography
              variant="h6"
              color="light"
              style={{
                color: theme.palette.secondary.dark,
              }}
            >
              {lang === "fr"
                ? "Aucun " + (type === "achetuer" ? "acheteur" : "vendeur")
                : lang === "en"
                ? "No " + (type === "achetuer" ? "buyer" : "seller")
                : "قائمة فارغة"}
            </MDTypography>
          </div>
        )}
      </MDBox>

      <Modal
        show={isLoading || isLoadingUpdate}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="myModalLoading"
      >
        <Modal.Body style={{ overflow: "hidden" }}>
          <Box sx={{ width: "100%" }}>
            <LinearProgress color="secondary" />
          </Box>
        </Modal.Body>
      </Modal>
    </Card>
  );
}

export default List;
