import React, { useEffect, useState } from "react";
import background from "assets/images/paperwork.jpg";
import HeaderSlider from "../OurPages/OurComponents/Home/Slider.js";
import LandingSearch from "./LandingSearch";
import { useSelector } from "react-redux";
import { TypeAnimation } from "react-type-animation";

export default ({ publireportages }) => {
  const lang = useSelector((state) => state.customization.lang);

  const [openSearch, setOpenSearch] = useState(false);
  const [landingInitSearch, SetLandingInitSearch] = useState("");
  return (
    <div
      className="relative pt-16 pb-32 flex content-center items-center justify-center"
      style={{
        paddingTop: "120px",
        paddingBottom: "clamp(200px, 5vw, 200px)",
        minHeight: "85vh",
      }}
    >
      <div
        className="absolute top-0 w-full h-full bg-center bg-cover"
        style={{
          backgroundImage: "url('" + background + "'",
        }}
      >
        <span id="blackOverlay" className="w-full h-full absolute opacity-75 bg-black"></span>
      </div>
      <div className="container relative mx-auto">
        <div className="items-center flex flex-wrap">
          <div className="w-full lg:w-6/12 px-0 ml-auto mr-auto text-center">
            <div className="">
              <h1
                className="text-white font-semibold text-5xl"
                style={{ fontSize: "clamp(18px, 2vw, 30px)" }}
              >
                {lang === "fr"
                  ? "RECHERCHE PAR MOTS-CLÉS."
                  : lang === "en"
                  ? "SEARCH WITH KEYWORDS."
                  : "البحث باستخدام الكلمات المفتاحية."}
              </h1>
              <div
                className="relative w-full my-3"
                style={{ direction: lang === "ar" ? "rtl" : "ltr" }}
              >
                <input
                  type="text"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder={
                    lang === "fr" ? "Rechercher..." : lang === "en" ? "Search for..." : "ابحث عن..."
                  }
                  value={landingInitSearch}
                  onChange={(e) => SetLandingInitSearch(e.target.value)}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      console.log("search clique");
                      setOpenSearch(true);
                    }
                  }}
                />
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={
                    lang === "ar"
                      ? {
                          position: "absolute",
                          left: "0px",
                          top: "0px",
                          height: "100%",
                          width: "50px",
                        }
                      : {
                          position: "absolute",
                          right: "0px",
                          top: "0px",
                          height: "100%",
                          width: "50px",
                        }
                  }
                  onClick={() => {
                    console.log("search clique");
                    setOpenSearch(true);
                  }}
                >
                  <i
                    className="fas fa-search"
                    style={{ fontSize: "25px", color: "#402d81", cursor: "pointer" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row w-100 ml-0 mt-3">
          <div
            className="col-12 col-md-6 d-flex justify-content-center align-items-center mb-5"
            style={{ textAlign: "center" }}
          >
            <div className="">
              <h1
                className="text-white font-semibold text-5xl"
                style={{ fontSize: "clamp(18px, 2vw, 30px)" }}
              >
                TARGETS.ZONE
              </h1>
              <p
                className="mt-4 mb-0 text-lg text-blueGray-200"
                style={{ fontSize: "12px", minHeight: "94px" }}
              >
                {lang === "fr" && (
                  <TypeAnimation
                    className="text-lg text-blueGray-200"
                    sequence={[
                      // Same substring at the start will only be typed out once, initially
                      "Un espace pour les chercheurs d'opportunités dans L'import Export.",
                      1000, // wait 1s before replacing "Mice" with "Hamsters"
                      "Un espace pour les chercheurs d'opportunités dans la matière première",
                      1000,
                      "Un espace pour les chercheurs d'opportunités dans Les produits industrialisés.",
                      1000,
                      "Un espace pour les chercheurs d'opportunités dans Les indices économiques et comerciaux.",
                      1000,
                    ]}
                    wrapper="span"
                    speed={50}
                    style={{ fontSize: "2em", display: "inline-block" }}
                    repeat={Infinity}
                  />
                )}
                {lang === "en" && (
                  <TypeAnimation
                    className="text-lg text-blueGray-200"
                    sequence={[
                      // Same substring at the start will only be typed out once, initially
                      "A space for opportunity seekers in Import Export.",
                      1000, // wait 1s before replacing "Mice" with "Hamsters"
                      "A space for opportunity seekers in Raw material.",
                      1000,
                      "A space for opportunity seekers in Unlisted products.",
                      1000,
                      "A space for opportunity seekers in Economic and trade indexes.",
                      1000,
                    ]}
                    wrapper="span"
                    speed={50}
                    style={{ fontSize: "2em", display: "inline-block" }}
                    repeat={Infinity}
                  />
                )}
                {lang === "ar" && (
                  <TypeAnimation
                    className="text-lg text-blueGray-200"
                    sequence={[
                      // Same substring at the start will only be typed out once, initially
                      "مساحة للباحثين عن الفرص في مجال الاستيراد والتصدير.",
                      1000, // wait 1s before replacing "Mice" with "Hamsters"
                      "مساحة للباحثين عن الفرص في مجال المواد الخام.",
                      1000,
                      "مساحة للباحثين عن الفرص في مجال المنتجات المستوردة.",
                      1000,
                      "مساحة للباحثين عن الفرص في مجال المؤشرات الاقتصادية والتجارية.",
                      1000,
                    ]}
                    wrapper="span"
                    speed={50}
                    style={{ fontSize: "2em", display: "inline-block" }}
                    repeat={Infinity}
                  />
                )}
              </p>
            </div>
            {/* <p className="m-0 text-lg text-blueGray-200">
                    L'import Export  ,
                    </p>
                    <p className="m-0 text-lg text-blueGray-200">
                    la matière première , les produits indistrualiés  , 
                    </p>
                    <p className="m-0 text-lg text-blueGray-200">
                    les indices économiques et comerciaux. 
                    </p> */}
          </div>
          <div className="col-12 col-md-6">
            <HeaderSlider items={publireportages} />
          </div>
        </div>
      </div>
      <div
        className="top-auto left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
        style={{ transform: "translateZ(0)", bottom: "-1px" }}
      >
        <svg
          className="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            className="text-blueGray-200 fill-current"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
      <LandingSearch
        initSearch={landingInitSearch}
        setInitSearch={SetLandingInitSearch}
        openSearch={openSearch}
        setOpenSearch={setOpenSearch}
      />
    </div>
  );
};
