import React, { useState } from "react";
import logo from "assets/images/logo_targets_zone_dark2.png";

import { useSelector } from "react-redux";
export default () => {
  const lang = useSelector((state) => state.customization.lang);
  return (
    <>
      <div style={lang === "ar" ? { direction: "rtl" } : {}}>
        <div className="text-lightBlue-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-lightBlue-300">
          <i className="fas fa-rocket text-xl"></i>
        </div>
        <h3 className="text-4xl font-semibold text-white mb-3">
          {lang === "fr" ? "Qui somme nous ?" : lang === "en" ? "Who are we?" : "من نكون؟"}
        </h3>
      </div>
      <div className="row w-100 ml-0">
        <div className="col-12  col-lg-4 h-100 pt-4 d-flex align-items-center justify-content-center">
          <img alt="..." className="max-w-full rounded-lg " src={logo} />
        </div>
        <div className="col-12  col-lg-8 mt-5">
          <div className="md:pr-12" style={lang === "ar" ? { direction: "rtl" } : {}}>
            <div className="flex items-center">
              <div>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mx-3">
                  <i className="fas fa-fingerprint"></i>
                </span>
              </div>
              <div>
                <h4 className="text-white">TARGETS.ZONE</h4>
              </div>
            </div>

            <p className="mt-4 text-lg leading-relaxed  text-white">
              {lang === "fr"
                ? "Est le fruit d’un effort consenti d’une équipe multidisciplinaire dans l’intelligence économique, le commerce international et les solutions numériques."
                : lang === "en"
                ? "Is the result of a concerted effort by a multidisciplinary team in business intelligence, international trade and digital solutions."
                : "هو ثمرة الجهد المتفاني لفريق متعدد التخصصات في الاستخبارات الاقتصادية والتجارة الدولية والحلول الرقمية."}
            </p>

            <p className="mt-4 text-lg leading-relaxed  text-blueGray-500">
              {lang === "fr"
                ? "C’est La première plate-forme indépendante, du secteur de l’import-export, des matières premières et industrialisées."
                : lang === "en"
                ? "It is the first independent platform in the import-export sector of raw and industrialized materials."
                : "وهي أول منصة مستقلة في قطاع الاستيراد والتصدير والمواد الخام والصناعية."}
            </p>

            <p className="mt-4 text-lg leading-relaxed  text-blueGray-500">
              {lang === "fr"
                ? "C’est un espace digital Unique, qui connecte d’une façon sécurisée toutes les parties prenantes de ce secteur commercial."
                : lang === "en"
                ? "It is a unique digital space, which securely connects all stakeholders in this commercial sector."
                : "إنها مساحة رقمية فريدة من نوعها، تربط بشكل آمن جميع أصحاب المصلحة في هذا القطاع التجاري."}
            </p>

            <p className="mt-4 text-lg leading-relaxed  text-blueGray-500">
              <span>
                {lang === "fr"
                  ? "Cette solution en ligne vous permet de communiquer et "
                  : lang === "en"
                  ? "This online solution allows you to communicate and "
                  : "يتيح لك هذا الحل عبر الإنترنت التواصل و "}
              </span>
              <span className="text-white">
                {lang === "fr"
                  ? "d’augmenter considérablement votre notoriété"
                  : lang === "en"
                  ? "to significantly increase your notoriety"
                  : "لزيادة سمعتك بشكل كبير"}
              </span>
              <span>
                {lang === "fr"
                  ? ". Vous pouvez effectuer des campagnes de communication ciblées, interagir avec la communauté, mais également mettre en ligne votre offre commerciale ainsi que vos produits."
                  : lang === "en"
                  ? ". You can carry out targeted communication campaigns, interact with the community, but also put your commercial offer and your products online."
                  : ". يمكنك تنفيذ حملات اتصال مستهدفة، والتفاعل مع المجتمع، ولكن يمكنك أيضًا وضع عرضك التجاري ومنتجاتك عبر الإنترنت."}
              </span>
            </p>

            <p className="mt-4 text-lg leading-relaxed  text-white">
              {lang === "fr"
                ? "Elle est dotée :"
                : lang === "en"
                ? "It is equipped with:"
                : "وهي مجهزة بـ:"}
            </p>

            <div className="mt-2 px-3 flex items-center">
              <div>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-white mx-3">
                  <i className="fas fa-fingerprint"></i>
                </span>
              </div>
              <div>
                <p className="text-lg leading-relaxed  text-blueGray-500">
                  <span>{lang === "fr" ? "D’un " : lang === "en" ? "" : ""}</span>
                  <span className="text-white">
                    {lang === "fr"
                      ? "moteur de recherche plein texte "
                      : lang === "en"
                      ? "full-text search engine"
                      : "محرك بحث عن النص الكامل "}
                  </span>
                  <span>
                    {lang === "fr"
                      ? "qui canalise le besoin des utilisateurs grâce à des mots clefs référentiels."
                      : lang === "en"
                      ? " that channels user needs through reference keywords."
                      : "يوجه احتياجات المستخدم من خلال الكلمات الرئيسية المرجعية."}
                  </span>
                </p>
              </div>
            </div>

            <div className="mt-2 px-3 flex items-center">
              <div>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-white mx-3">
                  <i className="fas fa-fingerprint"></i>
                </span>
              </div>
              <div>
                <p className="text-lg leading-relaxed  text-blueGray-500">
                  <span>
                    {lang === "fr"
                      ? "D’un volet d’"
                      : lang === "en"
                      ? "From a section of "
                      : "قسم خاص بـ"}
                  </span>
                  <span className="text-white">
                    {lang === "fr" ? "actualités " : lang === "en" ? "news " : "الأخبار"}
                  </span>
                  <span>
                    {lang === "fr"
                      ? "liées au monde économique."
                      : lang === "en"
                      ? " related to the economic world."
                      : " المتعلقة بالعالم الإقتصادي."}
                  </span>
                </p>
              </div>
            </div>

            <div className="mt-2 px-3 flex items-center">
              <div>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-white mx-3">
                  <i className="fas fa-fingerprint"></i>
                </span>
              </div>
              <div>
                <p className="text-lg leading-relaxed  text-blueGray-500">
                  <span>
                    {lang === "fr"
                      ? "D’une connexion directe aux plus "
                      : lang === "en"
                      ? "From a direct connection to "
                      : "اتصال مباشر بـ."}
                  </span>
                  <span className="text-white">
                    {lang === "fr"
                      ? "grandes bourses mondiales. "
                      : lang === "en"
                      ? "the world's largest stock exchanges."
                      : "أكبر البورصات في العالم"}
                  </span>
                </p>
              </div>
            </div>

            <div className="mt-2 px-3 flex items-center">
              <div>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-white mx-3">
                  <i className="fas fa-fingerprint"></i>
                </span>
              </div>
              <div>
                <p className="text-lg leading-relaxed  text-blueGray-500">
                  <span>
                    {lang === "fr" ? "D’un espace " : lang === "en" ? "From a " : "مساحة "}
                  </span>
                  <span className="text-white">
                    {lang === "fr" ? "Marketplace " : lang === "en" ? "Marketplace " : "السوق "}
                  </span>
                  <span>
                    {lang === "fr"
                      ? "comme vitrine de votre contenu et produits."
                      : lang === "en"
                      ? "space as a showcase for your content and products."
                      : "كعرض للمحتوى والمنتجات الخاصة بك."}
                  </span>
                </p>
              </div>
            </div>

            <div className="mt-2 px-3 flex items-center">
              <div>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-white mx-3">
                  <i className="fas fa-fingerprint"></i>
                </span>
              </div>
              <div>
                <p className="text-lg leading-relaxed  text-blueGray-500">
                  <span>
                    {lang === "fr"
                      ? "D’une "
                      : lang === "en"
                      ? "From a secure inter-user exchange "
                      : ""}
                  </span>
                  <span className="text-white">
                    {lang === "fr"
                      ? "messagerie "
                      : lang === "en"
                      ? "messaging system"
                      : "المراسلة."}
                  </span>
                  <span>
                    {lang === "fr"
                      ? "d’échanges sécurisée inter-utilisateurs (communautés internes,partenaires, clients ou prospects)."
                      : lang === "en"
                      ? " (internal communities, partners, customers or prospects)."
                      : " الآمنة للتبادلات بين المستخدمين (المجتمعات الداخلية أو الشركاء أو العملاء أو العملاء المحتملين)"}
                  </span>
                </p>
              </div>
            </div>

            <div className="mt-2 px-3 flex items-center">
              <div>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-white mx-3">
                  <i className="fas fa-fingerprint"></i>
                </span>
              </div>
              <div>
                <p className="text-lg leading-relaxed  text-blueGray-500">
                  <span>
                    {lang === "fr"
                      ? "D’espaces dédiés à la "
                      : lang === "en"
                      ? "Spaces dedicated to "
                      : "مساحات مخصصة لـ"}
                  </span>
                  <span className="text-white">
                    {lang === "fr"
                      ? "communication et à la publicité. "
                      : lang === "en"
                      ? "communication and advertising."
                      : "لاتصال والإعلان."}
                  </span>
                </p>
              </div>
            </div>

            <div className="mt-2 px-3 flex items-center">
              <div>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-white mx-3">
                  <i className="fas fa-fingerprint"></i>
                </span>
              </div>
              <div>
                <p className="text-lg leading-relaxed  text-blueGray-500">
                  <span>{lang === "fr" ? "D’un " : lang === "en" ? "From a " : ""}</span>
                  <span className="text-white">
                    {lang === "fr"
                      ? "système de notifications "
                      : lang === "en"
                      ? "notification system "
                      : "نظام الإشعارات "}
                  </span>
                  <span>
                    {lang === "fr"
                      ? "qui vous alerte des demandes et des opportunités pour y répondre en temps réel."
                      : lang === "en"
                      ? "that alerts you to requests and opportunities to respond to them in real time."
                      : "الذي ينبهك للطلبات وفرص الرد عليها في الوقت الفعلي."}
                  </span>
                </p>
              </div>
            </div>

            <br />
            <br />

            <div className="flex items-center">
              <div>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mx-3">
                  <i className="fas fa-fingerprint"></i>
                </span>
              </div>
              <div>
                <h4 className="text-white">
                  {lang === "fr"
                    ? "ACCOMPAGNEMENT DE NOS PARTENAIRES"
                    : lang === "en"
                    ? "SUPPORTING OUR PARTNERS"
                    : "دعم شركائنا"}
                </h4>
              </div>
            </div>

            <p className="mt-5 text-lg leading-relaxed  text-blueGray-500">
              <span>{lang === "fr" ? "L’espace " : lang === "en" ? "The space " : "المساحة "}</span>
              <span className="text-white">
                {lang === "fr" ? "Nos Service " : lang === "en" ? "Services " : "الخدمات "}
              </span>
              <span>{lang === "fr" ? "dans " : lang === "en" ? "in " : "في "}</span>
              <span className="text-white">TARGETS.ZONE</span>
              <span>
                {lang === "fr"
                  ? ",est réservé aux "
                  : lang === "en"
                  ? ",is reserved for "
                  : "محجوز لـ"}
              </span>
              <span className="text-white">
                {lang === "fr" ? "missions " : lang === "en" ? "the missions" : "لمهام "}
              </span>
              <span>
                {lang === "fr"
                  ? "et l’accompagnement que nous assurons."
                  : lang === "en"
                  ? " and support we provide."
                  : "والدعم الذي نقدمه."}
              </span>
            </p>
            <p className="mt-4 text-lg leading-relaxed  text-blueGray-500">
              {lang === "fr"
                ? "Notre équipe d’experts, dédiée, assure la bonne gestion de vos demandes et vous accompagne dans toutes les étapes nécessaires à la réussite de chaque mission confiée."
                : lang === "en"
                ? "Our dedicated team of experts ensures that your requests are properly managed, and supports you every step of the way to ensure the success of every assignment you entrust to us."
                : "سيضمن فريق الخبراء المتخصصين لدينا إدارة طلباتك بشكل صحيح وسيدعمونك في كل خطوة على الطريق لضمان نجاح كل مهمة."}
            </p>
            <br />
          </div>
        </div>
      </div>
    </>
  );
};
