import PropTypes from "prop-types";
import { useState } from "react";

// material-ui
import { useTheme, styled } from "@mui/material/styles";
import {
  Avatar,
  Box,
  ButtonBase,
  Card,
  Grid,
  InputAdornment,
  OutlinedInput,
  Popper,
} from "@mui/material";

// third-party
import PopupState, { bindPopper, bindToggle } from "material-ui-popup-state";

// project imports
import Transitions from "ui-component/extended/Transitions";

// assets
import {
  IconAdjustmentsHorizontal,
  IconMessage,
  IconPackage,
  IconSearch,
  IconX,
} from "@tabler/icons";
import { shouldForwardProp } from "@mui/system";
import { ShoppingCart } from "@mui/icons-material";

// styles
const PopperStyle = styled(Popper, { shouldForwardProp })(({ theme }) => ({
  zIndex: 1100,
  width: "99%",
  top: "-55px !important",
  padding: "0 12px",
  [theme.breakpoints.down("sm")]: {
    padding: "0 10px",
  },
}));

const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(({ theme }) => ({
  width: 434,
  marginLeft: 16,
  paddingLeft: 16,
  paddingRight: 16,
  "& input": {
    background: "transparent !important",
    paddingLeft: "4px !important",
  },
  [theme.breakpoints.down("lg")]: {
    width: 250,
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    marginLeft: 4,
    background: "#fff",
  },
}));

const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(({ theme }) => ({
  ...theme.typography.commonAvatar,
  ...theme.typography.mediumAvatar,
  background: theme.palette.secondary.light,
  color: theme.palette.secondary.dark,
  "&:hover": {
    background: theme.palette.secondary.dark,
    color: theme.palette.secondary.light,
  },
}));

// ==============================|| SEARCH INPUT ||============================== //

const SearchSection = ({ label, value, valueChange, valueFilter }) => {
  const theme = useTheme();

  return (
    <>
      <Box sx={{ display: { md: "block", width: "100%" } }}>
        <OutlineInputStyle
          id="input-search-header"
          value={value}
          onChange={(e) => valueChange(e.target.value)}
          placeholder={label && label !== "" ? label : "Search"}
          style={{ width: "90%" }}
          startAdornment={
            <InputAdornment position="start">
              {label === "Acheteur" ? (
                <ShoppingCart stroke={1.5} size="1rem" color={theme.palette.grey[500]} />
              ) : (
                <></>
              )}
              {label === "Produit" ? (
                <IconPackage stroke={1.5} size="1rem" color={theme.palette.grey[500]} />
              ) : (
                <></>
              )}
              {label === "Message" ? (
                <IconMessage stroke={1.5} size="1rem" color={theme.palette.grey[500]} />
              ) : (
                <></>
              )}
            </InputAdornment>
          }
          aria-describedby="search-helper-text"
          inputProps={{ "aria-label": "weight" }}
        />
      </Box>
    </>
  );
};

export default SearchSection;
