import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {Container as ContainerBase } from "components/misc/Layouts.js"
import logo from "../../../images/logo.svg";
import { ReactComponent as FacebookIcon } from "../../../images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "../../../images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "../../../images/youtube-icon.svg";
import  InstagramIcon  from "../../../images/logo-instagram.png";
import { useNavigate } from "react-router-dom";
import { getInfos } from "services/visiteurService";
import { useMutation } from "@tanstack/react-query";


const Container = tw(ContainerBase)`bg-gray-900 text-gray-100 -mx-8 -mb-8`
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const Row = tw.div`flex items-center justify-center flex-col px-8`

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-2xl font-black tracking-wider`;

const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4`;

const SocialLinksContainer = tw.div`mt-10`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`;

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`
export default ({infos}) => {

  const [currentYear, setCurrentYear] = useState("") ; 
  
  useEffect(()=> {
    let currentDate = new Date();
    let year = currentDate.getFullYear();
    setCurrentYear(year) ;
  } , []) ;
  let navigate = useNavigate();
  return (
    <Container>
      <Content>
        <Row>
          <LogoContainer>
            {/* <LogoImg src={logo} /> */}
            <LogoText style={{textAlign : "center"}}>Le Portail Economique Algérien</LogoText>
          </LogoContainer>
          <LinksContainer>
            <Link style={{cursor : "pointer"}}  onClick={()=> {navigate("/");}} >Home</Link>
            <Link style={{cursor : "pointer"}}  onClick={()=> {navigate("/services");}} >Services</Link>
            <Link style={{cursor : "pointer"}}  onClick={()=> {navigate("/abonnements");}} >Abonnements</Link>
            <Link style={{cursor : "pointer"}}  onClick={()=> {navigate("/partenaires");}} >Partenaires</Link>
            <Link style={{cursor : "pointer"}}  onClick={()=> {navigate("/contactUs");}} >Nous Contacter </Link>
          </LinksContainer>
          <SocialLinksContainer>
            <SocialLink href={infos && infos.facebook ?  infos.facebook:  "facebook.com"}>
              <FacebookIcon />
            </SocialLink>
            <SocialLink href={infos && infos.twitter ?  infos.twitter:  "twitter.com"}>
              <TwitterIcon />
            </SocialLink>
            <SocialLink href={infos && infos.instagram ?  infos.instagram:  "instagram.com"}>
              <img alt="" src={InstagramIcon} style={{height : "20px" , width : "auto"}}/>
            </SocialLink>
          </SocialLinksContainer>
          <CopyrightText>
            &copy; Copyright {currentYear} {currentYear !== "" && currentYear+"" !== "2023" ? " - "+currentYear : "" } . All Rights Reserved.
          </CopyrightText>
        </Row>
      </Content>
    </Container>
  );
};
