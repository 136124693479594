// @mui material components
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDProgress from "components/MDProgress";
import Icon from "@mui/material/Icon";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

import MDBadge from "components/MDBadge";
import { Dropdown } from "react-bootstrap";
import { BASE_URL_Image } from "services/server";
import { Badge, IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CheckCircleOutline } from "@mui/icons-material";
import { IconCheck, IconCircleCheck, IconHomeCheck, IconUser, IconUserCheck } from "@tabler/icons";
import { fontSize, minWidth, textAlign, width } from "@mui/system";
import DropdownUsersMenu from "components/dropdown/Dropdown";

export default function data(props) {
  const avatars = (image) =>
    image.map(([image, name], index) => (
      <Tooltip title={name} placeholder="bottom">
        <div
          style={{
            width: "40px",
            height: "40px",
            overflow: "hidden",
            borderRadius: "50%",
            backgroundColor: "#ede7f6",
            border: "2px solid #cabae9",
            marginLeft: index > 0 ? "-20px" : "",
            cursor: "pointer",
          }}
          className="d-flex align-items-center justify-content-center"
        >
          <img
            key={name}
            src={image}
            alt="name"
            size="md"
            style={{ width: "40px", height: "auto" }}
          />
        </div>
      </Tooltip>
    ));

  const openModal = (item) => {
    props.handleOpen(item);
  };

  const openModalArchive = (item) => {
    props.handleOpenArchivage(item);
  };

  const openModalValidation = (publireportage) => {
    props.handleOpenValidation(publireportage);
  };

  const openModalAffichage = (publireportage) => {
    props.handleOpenAffichage(publireportage);
  };

  const updateItemStatus = (item, status) => {
    props.handleUpdate({ id: item.id, status: status });
  };

  const updateItemArchive = (item, archive) => {
    props.handleUpdate({ id: item.id, archive: archive });
  };

  const Company = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="md" />
      <MDTypography variant="button" fontWeight="medium" ml={1} lineHeight={1}>
        {name}
      </MDTypography>
    </MDBox>
  );

  const getAvatars = (el) => {
    let avatars_array = [];
    avatars_array.push([BASE_URL_Image + el.profile_pic, ""]);
    return avatars(avatars_array);
  };

  return {
    columns:
      props.lang === "fr"
        ? [
            { Header: "", accessor: "images", align: "left" },
            { Header: "Nom", accessor: "user_nom", align: "left" },
            { Header: "Email", accessor: "user_email", align: "left" },
            { Header: "Date d'inscription", accessor: "join_date", align: "center" },
            { Header: "Validé", accessor: "valide", width: "100px", align: "center" },
            { Header: "Status", accessor: "status", align: "center" },
            { Header: "", accessor: "action", align: "center" },
          ]
        : props.lang === "en"
        ? [
            { Header: "", accessor: "images", align: "left" },
            { Header: "Name", accessor: "user_nom", align: "left" },
            { Header: "Email", accessor: "user_email", align: "left" },
            { Header: "Registration date", accessor: "join_date", align: "center" },
            { Header: "Validated", accessor: "valide", width: "100px", align: "center" },
            { Header: "Status", accessor: "status", align: "center" },
            { Header: "", accessor: "action", align: "center" },
          ]
        : [
            { Header: "", accessor: "images", align: "left" },
            { Header: "الإسم", accessor: "user_nom", align: "left" },
            { Header: "البريد", accessor: "user_email", align: "left" },
            { Header: "تاريخ الإنضمام", accessor: "join_date", align: "center" },
            { Header: "مؤكد", accessor: "valide", width: "100px", align: "center" },
            { Header: "الحالة", accessor: "status", align: "center" },
            { Header: "", accessor: "action", align: "center" },
          ],

    rows: props.items.map((el) => {
      return {
        images: (
          <MDBox display="flex" py={1} style={{ maxWidth: "100px" }}>
            {getAvatars(el)}
          </MDBox>
        ),
        user_email: (
          <MDBox display="flex" py={1} style={{ minWidth: "120px" }}>
            <MDTypography
              variant="caption"
              color="text"
              fontWeight="medium"
              style={{ textAlign: props.lang === "ar" ? "right" : "left", minWidth: "100%" }}
            >
              {el.email}
            </MDTypography>
          </MDBox>
        ),
        user_nom: (
          <MDBox display="flex" py={1} style={{ minWidth: "120px" }}>
            <MDTypography
              variant="caption"
              color="text"
              fontWeight="medium"
              style={{ textAlign: props.lang === "ar" ? "right" : "left", minWidth: "100%" }}
            >
              {el.nom}
            </MDTypography>
          </MDBox>
        ),

        join_date: (
          <MDBox ml={-1}>
            <MDBadge
              badgeContent={el.created_at ? el.created_at : "--"}
              color={"dark"}
              variant="gradient"
              size="sm"
            />
          </MDBox>
        ),
        status: (
          <Badge
            color={el.bloque === 0 ? "success" : "error"}
            style={{ minWidth: "50px", width: "120px" }}
            badgeContent={
              el.bloque === 0 ? (
                <>
                  <IconCheck
                    style={{ color: el.bloque === 0 ? "white" : "", width: "28px" }}
                  ></IconCheck>
                </>
              ) : (
                <spam style={{ fontSize: "10px", padding: "5px" }}>
                  {"" +
                    (props.lang === "fr"
                      ? "Suspendu par l'admin "
                      : props.lang === "en"
                      ? "Suspended by admin"
                      : "تم توقيفه من قبل المشرف")}
                </spam>
              )
            }
          ></Badge>
        ),
        valide: (
          <>
            <Badge
              color={el.validated === 1 ? "success" : "warning"}
              style={{ minWidth: "50px", width: "120px" }}
              badgeContent={
                el.validated === 1 ? (
                  <>
                    <IconCheck
                      style={{ color: el.validated === 1 ? "white" : "", width: "28px" }}
                    ></IconCheck>
                  </>
                ) : (
                  <spam style={{ fontSize: "10px" }}>
                    {"" +
                      (props.lang === "fr"
                        ? "Pas encore"
                        : props.lang === "en"
                        ? "Not yet"
                        : "ليس بعد")}
                  </spam>
                )
              }
            ></Badge>
          </>
        ),
        action: (
          <>
            <DropdownUsersMenu el={el} updateAction={props.updateAction}></DropdownUsersMenu>
          </>
        ),
      };
    }),
  };
}
