import React, { useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "components/misc/Headings";

import defaultCardImage from "../../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../../images/svg-decorator-blob-3.svg";

import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";

import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";
import SupportIconImage from "../../../images/support-icon.svg";
import EnergyIconImage from "../../../images/zap.svg";
import HomeIconImage from "../../../images/home.svg";
import HydriqueIconImage from "../../../images/droplet.svg";
import TransportIconImage from "../../../images/truck.svg";
import TransformationIconImage from "../../../images/settings.svg";
import TechIconImage from "../../../images/cpu.svg";
import SanteIconImage from "../../../images/activity.svg";
import TourismeIconImage from "../../../images/map.svg";
import CoultureIconImage from "../../../images/file-text.svg";
import TelecomIconImage from "../../../images/phone-incoming.svg";
import FinanceIconImage from "../../../images/dollar-sign.svg";
import { useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { getSecteurs } from "services/secteursService";
import { BASE_URL_Image } from "services/server";
import { useNavigate } from "react-router-dom";
const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`w-full h-full px-10 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col w-full h-full items-center px-6 py-10 border-2 border-dashed border-primary-500 rounded-lg`}
  .imageContainer {
    ${tw`border-2 border-primary-500 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
    font-size : 15px ; 
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;


const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const HeadingWithControl = tw.div`flex flex-col items-center sm:items-stretch sm:flex-row justify-between`;
const Controls = tw.div`flex items-center`;
const ControlButton = styled(PrimaryButtonBase)`
${tw`mt-4 sm:mt-0 first:ml-0 ml-6 rounded-full p-2  border-none bg-gray-500 text-gray-100 border-none `}
svg {
    ${tw`w-6 h-6`}
}

`;
const PrevButton = tw(ControlButton)``;
const NextButton = tw(ControlButton)``;

const CardSlider = styled(Slider)`
  ${tw`mt-16`}
  .slick-track { 
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1`}
  }
  div {
    width : 100% ;
  }
`;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}"); max-height : 100px ; `,
  tw`w-full h-56 sm:h-64 bg-cover bg-center rounded sm:rounded-none`
]);

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-2xl font-bold`;

const RatingsInfo = styled.div`
  ${tw`flex items-center sm:ml-4 mt-2 sm:mt-0`}
  svg {
    ${tw`w-6 h-6 text-yellow-500 fill-current`}
  }
`;
const Rating = tw.span`ml-2 font-bold`;

const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4`;

const SecondaryInfoContainer = tw.div`flex flex-col sm:flex-row mt-2 sm:mt-4`;
const IconWithText = tw.div`flex items-center mr-6 my-2 sm:my-0`;
const IconContainer = styled.div`
  ${tw`inline-block rounded-full p-2  border-none bg-gray-700 text-gray-100`}
  svg {
    ${tw`w-3 h-3`}
  }
`;
const Text = tw.div`ml-2 text-sm font-semibold text-gray-800`;

const PrimaryButton = tw(PrimaryButtonBase)`mt-auto sm:text-lg rounded-none w-full py-3 sm:py-6 bg-gray-500 text-gray-100`;
export default ({items}) => {
  let navigate = useNavigate();
  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [sliderRef, setSliderRef] = useState(null);
  useEffect(()=> {
    const intervalId = setInterval(() => {
        if(sliderRef) {
            sliderRef?.slickNext() ;
        }
    } , 3000) ; 
    return () => {
        clearInterval(intervalId);
    } ;
  } , [sliderRef]) ;
  const sliderSettings = {
    arrows: false,
    slidesToShow: 4,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
        }
      },

      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  };
  return (
    <Container>
      <Content>
        <HeadingWithControl>
          <Heading>Secteurs</Heading>
          <Controls>
            <PrevButton onClick={sliderRef?.slickPrev}><ChevronLeftIcon/></PrevButton>
            <NextButton onClick={sliderRef?.slickNext}><ChevronRightIcon/></NextButton>
          </Controls>
        </HeadingWithControl>
        <CardSlider ref={setSliderRef} {...sliderSettings}>
          {items.map((card, i) => (
            <Column key={i} style={{width :"100%"}}>
            <Card style={{width : "100%" , height:"100%" , cursor : "pointer" , border: "none" ,  background: "white" , borderRadius: "20px" ,  boxShadow: "-3px 4px 8px 0px #999da3" ,}}
              onClick={()=> {
                localStorage.setItem("activatedSecteur" , card.id) ; 
                navigate("/services") ; 
              }}
            >
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt=""  style={{height: "100px" , width: "auto"}}/>
              </span>
              <span className="textContainer">
                <span className="title" style={{color : "black"}}>{card.title || "Fully Secure"}</span>
                <p className="description">
                </p>
              </span>
            </Card>
          </Column>
          ))}
        </CardSlider>
      </Content>
    </Container>
  );
};
