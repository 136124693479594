export const countries = [
  { id: 1, arabic: "أفغانستان", french: "Afghanistan", english: "Afghanistan" },
  { id: 2, arabic: "ألبانيا", french: "Albanie", english: "Albania" },
  { id: 3, arabic: "الجزائر", french: "Algérie", english: "Algeria" },
  { id: 4, arabic: "أندورا", french: "Andorre", english: "Andorra" },
  { id: 5, arabic: "أنغولا", french: "Angola", english: "Angola" },
  {
    id: 6,
    arabic: "أنتيغوا وباربودا",
    french: "Antigua-et-Barbuda",
    english: "Antigua and Barbuda",
  },
  { id: 7, arabic: "الأرجنتين", french: "Argentine", english: "Argentina" },
  { id: 8, arabic: "أرمينيا", french: "Arménie", english: "Armenia" },
  { id: 9, arabic: "أستراليا", french: "Australie", english: "Australia" },
  { id: 10, arabic: "النمسا", french: "Autriche", english: "Austria" },
  { id: 11, arabic: "أذربيجان", french: "Azerbaïdjan", english: "Azerbaijan" },
  { id: 12, arabic: "باهاماس", french: "Bahamas", english: "Bahamas" },
  { id: 13, arabic: "البحرين", french: "Bahreïn", english: "Bahrain" },
  { id: 14, arabic: "بنغلاديش", french: "Bangladesh", english: "Bangladesh" },
  { id: 15, arabic: "باربادوس", french: "Barbade", english: "Barbados" },
  { id: 16, arabic: "بيلاروسيا", french: "Biélorussie", english: "Belarus" },
  { id: 17, arabic: "بلجيكا", french: "Belgique", english: "Belgium" },
  { id: 18, arabic: "بليز", french: "Belize", english: "Belize" },
  { id: 19, arabic: "بنين", french: "Bénin", english: "Benin" },
  { id: 20, arabic: "بوتان", french: "Bhoutan", english: "Bhutan" },
  { id: 21, arabic: "بوليفيا", french: "Bolivie", english: "Bolivia" },
  {
    id: 22,
    arabic: "البوسنة والهرسك",
    french: "Bosnie-Herzégovine",
    english: "Bosnia and Herzegovina",
  },
  { id: 23, arabic: "بوتسوانا", french: "Botswana", english: "Botswana" },
  { id: 24, arabic: "البرازيل", french: "Brésil", english: "Brazil" },
  { id: 25, arabic: "بروناي", french: "Brunei", english: "Brunei" },
  { id: 26, arabic: "بلغاريا", french: "Bulgarie", english: "Bulgaria" },
  { id: 27, arabic: "بوركينا فاسو", french: "Burkina Faso", english: "Burkina Faso" },
  { id: 28, arabic: "بوروندي", french: "Burundi", english: "Burundi" },
  { id: 29, arabic: "كابو فيردي", french: "Cap-Vert", english: "Cape Verde" },
  { id: 30, arabic: "كمبوديا", french: "Cambodge", english: "Cambodia" },
  { id: 31, arabic: "الكاميرون", french: "Cameroun", english: "Cameroon" },
  { id: 32, arabic: "كندا", french: "Canada", english: "Canada" },
  {
    id: 33,
    arabic: "جمهورية أفريقيا الوسطى",
    french: "République centrafricaine",
    english: "Central African Republic",
  },
  { id: 34, arabic: "تشاد", french: "Tchad", english: "Chad" },
  { id: 35, arabic: "تشيلي", french: "Chili", english: "Chile" },
  { id: 36, arabic: "الصين", french: "Chine", english: "China" },
  { id: 37, arabic: "كولومبيا", french: "Colombie", english: "Colombia" },
  { id: 38, arabic: "جزر القمر", french: "Comores", english: "Comoros" },
  { id: 39, arabic: "جمهورية الكونغو", french: "République du Congo", english: "Congo" },
  {
    id: 40,
    arabic: "جمهورية الكونغو الديمقراطية",
    french: "République démocratique du Congo",
    english: "Democratic Republic of the Congo",
  },
  { id: 41, arabic: "كوستاريكا", french: "Costa Rica", english: "Costa Rica" },
  { id: 42, arabic: "كرواتيا", french: "Croatie", english: "Croatia" },
  { id: 43, arabic: "كوبا", french: "Cuba", english: "Cuba" },
  { id: 44, arabic: "قبرص", french: "Chypre", english: "Cyprus" },
  { id: 45, arabic: "جمهورية التشيك", french: "République tchèque", english: "Czech Republic" },
  { id: 46, arabic: "الدنمارك", french: "Danemark", english: "Denmark" },
  { id: 47, arabic: "جيبوتي", french: "Djibouti", english: "Djibouti" },
  { id: 48, arabic: "دومينيكا", french: "Dominique", english: "Dominica" },
  {
    id: 49,
    arabic: "جمهورية الدومينيكان",
    french: "République dominicaine",
    english: "Dominican Republic",
  },
  { id: 50, arabic: "تيمور الشرقية", french: "Timor oriental", english: "East Timor" },
  { id: 51, arabic: "الإكوادور", french: "Équateur", english: "Ecuador" },
  { id: 52, arabic: "مصر", french: "Égypte", english: "Egypt" },
  { id: 53, arabic: "السلفادور", french: "Salvador", english: "El Salvador" },
  {
    id: 54,
    arabic: "غينيا الاستوائية",
    french: "Guinée équatoriale",
    english: "Equatorial Guinea",
  },
  { id: 55, arabic: "إريتريا", french: "Érythrée", english: "Eritrea" },
  { id: 56, arabic: "إستونيا", french: "Estonie", english: "Estonia" },
  { id: 57, arabic: "إسواتيني", french: "Eswatini", english: "Eswatini" },
  { id: 58, arabic: "إثيوبيا", french: "Éthiopie", english: "Ethiopia" },
  { id: 59, arabic: "فيجي", french: "Fidji", english: "Fiji" },
  { id: 60, arabic: "فنلندا", french: "Finlande", english: "Finland" },
  { id: 61, arabic: "فرنسا", french: "France", english: "France" },
  { id: 62, arabic: "الغابون", french: "Gabon", english: "Gabon" },
  { id: 63, arabic: "غامبيا", french: "Gambie", english: "Gambia" },
  { id: 64, arabic: "جورجيا", french: "Géorgie", english: "Georgia" },
  { id: 65, arabic: "ألمانيا", french: "Allemagne", english: "Germany" },
  { id: 66, arabic: "غانا", french: "Ghana", english: "Ghana" },
  { id: 67, arabic: "اليونان", french: "Grèce", english: "Greece" },
  { id: 68, arabic: "جرينادا", french: "Grenade", english: "Grenada" },
  { id: 69, arabic: "غواتيمالا", french: "Guatemala", english: "Guatemala" },
  { id: 70, arabic: "غينيا", french: "Guinée", english: "Guinea" },
  { id: 71, arabic: "غينيا بيساو", french: "Guinée-Bissau", english: "Guinea-Bissau" },
  { id: 72, arabic: "غيانا", french: "Guyana", english: "Guyana" },
  { id: 73, arabic: "هايتي", french: "Haïti", english: "Haiti" },
  { id: 74, arabic: "هندوراس", french: "Honduras", english: "Honduras" },
  { id: 75, arabic: "المجر", french: "Hongrie", english: "Hungary" },
  { id: 76, arabic: "آيسلندا", french: "Islande", english: "Iceland" },
  { id: 77, arabic: "الهند", french: "Inde", english: "India" },
  { id: 78, arabic: "إندونيسيا", french: "Indonésie", english: "Indonesia" },
  { id: 79, arabic: "إيران", french: "Iran", english: "Iran" },
  { id: 80, arabic: "العراق", french: "Irak", english: "Iraq" },
  { id: 81, arabic: "أيرلندا", french: "Irlande", english: "Ireland" },
  { id: 82, arabic: "إسرائيل", french: "Israël", english: "Israel" },
  { id: 83, arabic: "إيطاليا", french: "Italie", english: "Italy" },
  { id: 84, arabic: "جامايكا", french: "Jamaïque", english: "Jamaica" },
  { id: 85, arabic: "اليابان", french: "Japon", english: "Japan" },
  { id: 86, arabic: "الأردن", french: "Jordanie", english: "Jordan" },
  { id: 87, arabic: "كازاخستان", french: "Kazakhstan", english: "Kazakhstan" },
  { id: 88, arabic: "كينيا", french: "Kenya", english: "Kenya" },
  { id: 89, arabic: "كيريباتي", french: "Kiribati", english: "Kiribati" },
  { id: 90, arabic: "كوريا الشمالية", french: "Corée du Nord", english: "North Korea" },
  { id: 91, arabic: "كوريا الجنوبية", french: "Corée du Sud", english: "South Korea" },
  { id: 92, arabic: "الكويت", french: "Koweït", english: "Kuwait" },
  { id: 93, arabic: "قرغيزستان", french: "Kirghizistan", english: "Kyrgyzstan" },
  { id: 94, arabic: "لاوس", french: "Laos", english: "Laos" },
  { id: 95, arabic: "لاتفيا", french: "Lettonie", english: "Latvia" },
  { id: 96, arabic: "لبنان", french: "Liban", english: "Lebanon" },
  { id: 97, arabic: "ليسوتو", french: "Lesotho", english: "Lesotho" },
  { id: 98, arabic: "ليبيريا", french: "Libéria", english: "Liberia" },
  { id: 99, arabic: "ليبيا", french: "Libye", english: "Libya" },
  { id: 100, arabic: "ليختنشتاين", french: "Liechtenstein", english: "Liechtenstein" },
  { id: 101, arabic: "ليتوانيا", french: "Lituanie", english: "Lithuania" },
  { id: 102, arabic: "لوكسمبورغ", french: "Luxembourg", english: "Luxembourg" },
  { id: 103, arabic: "مدغشقر", french: "Madagascar", english: "Madagascar" },
  { id: 104, arabic: "مالاوي", french: "Malawi", english: "Malawi" },
  { id: 105, arabic: "ماليزيا", french: "Malaisie", english: "Malaysia" },
  { id: 106, arabic: "جزر المالديف", french: "Maldives", english: "Maldives" },
  { id: 107, arabic: "مالي", french: "Mali", english: "Mali" },
  { id: 108, arabic: "مالطا", french: "Malte", english: "Malta" },
  { id: 109, arabic: "جزر مارشال", french: "Îles Marshall", english: "Marshall Islands" },
  { id: 110, arabic: "موريتانيا", french: "Mauritanie", english: "Mauritania" },
  { id: 111, arabic: "موريشيوس", french: "Maurice", english: "Mauritius" },
  { id: 112, arabic: "المكسيك", french: "Mexique", english: "Mexico" },
  { id: 113, arabic: "ميكرونيزيا", french: "Micronésie", english: "Micronesia" },
  { id: 114, arabic: "مولدوفا", french: "Moldavie", english: "Moldova" },
  { id: 115, arabic: "موناكو", french: "Monaco", english: "Monaco" },
  { id: 116, arabic: "منغوليا", french: "Mongolie", english: "Mongolia" },
  { id: 117, arabic: "الجبل الأسود", french: "Monténégro", english: "Montenegro" },
  { id: 118, arabic: "المغرب", french: "Maroc", english: "Morocco" },
  { id: 119, arabic: "موزمبيق", french: "Mozambique", english: "Mozambique" },
  { id: 120, arabic: "ميانمار", french: "Myanmar", english: "Myanmar" },
  { id: 121, arabic: "ناميبيا", french: "Namibie", english: "Namibia" },
  { id: 122, arabic: "ناورو", french: "Nauru", english: "Nauru" },
  { id: 123, arabic: "نيبال", french: "Népal", english: "Nepal" },
  { id: 124, arabic: "هولندا", french: "Pays-Bas", english: "Netherlands" },
  { id: 125, arabic: "نيوزيلندا", french: "Nouvelle-Zélande", english: "New Zealand" },
  { id: 126, arabic: "نيكاراغوا", french: "Nicaragua", english: "Nicaragua" },
  { id: 127, arabic: "النيجر", french: "Niger", english: "Niger" },
  { id: 128, arabic: "نيجيريا", french: "Nigéria", english: "Nigeria" },
  { id: 129, arabic: "مقدونيا الشمالية", french: "Macédoine du Nord", english: "North Macedonia" },
  { id: 130, arabic: "النرويج", french: "Norvège", english: "Norway" },
  { id: 131, arabic: "سلطنة عمان", french: "Oman", english: "Oman" },
  { id: 132, arabic: "باكستان", french: "Pakistan", english: "Pakistan" },
  { id: 133, arabic: "بالاو", french: "Palaos", english: "Palau" },
  { id: 134, arabic: "بنما", french: "Panama", english: "Panama" },
  {
    id: 135,
    arabic: "بابوا غينيا الجديدة",
    french: "Papouasie-Nouvelle-Guinée",
    english: "Papua New Guinea",
  },
  { id: 136, arabic: "باراغواي", french: "Paraguay", english: "Paraguay" },
  { id: 137, arabic: "بيرو", french: "Pérou", english: "Peru" },
  { id: 138, arabic: "الفلبين", french: "Philippines", english: "Philippines" },
  { id: 139, arabic: "بولندا", french: "Pologne", english: "Poland" },
  { id: 140, arabic: "البرتغال", french: "Portugal", english: "Portugal" },
  { id: 141, arabic: "قطر", french: "Qatar", english: "Qatar" },
  { id: 142, arabic: "رومانيا", french: "Roumanie", english: "Romania" },
  { id: 143, arabic: "روسيا", french: "Russie", english: "Russia" },
  { id: 144, arabic: "رواندا", french: "Rwanda", english: "Rwanda" },
  {
    id: 145,
    arabic: "سانت كيتس ونيفيس",
    french: "Saint-Christophe-et-Niévès",
    english: "Saint Kitts and Nevis",
  },
  { id: 146, arabic: "سانت لوسيا", french: "Sainte-Lucie", english: "Saint Lucia" },
  {
    id: 147,
    arabic: "سانت فنسنت وجزر غرينادين",
    french: "Saint-Vincent-et-les-Grenadines",
    english: "Saint Vincent and the Grenadines",
  },
  { id: 148, arabic: "ساموا", french: "Samoa", english: "Samoa" },
  { id: 149, arabic: "سان مارينو", french: "Saint-Marin", english: "San Marino" },
  {
    id: 150,
    arabic: "ساو تومي وبرينسيب",
    french: "Sao Tomé-et-Principe",
    english: "Sao Tome and Principe",
  },
  { id: 151, arabic: "السعودية", french: "Arabie Saoudite", english: "Saudi Arabia" },
  { id: 152, arabic: "السنغال", french: "Sénégal", english: "Senegal" },
  { id: 153, arabic: "صربيا", french: "Serbie", english: "Serbia" },
  { id: 154, arabic: "سيشيل", french: "Seychelles", english: "Seychelles" },
  { id: 155, arabic: "سيراليون", french: "Sierra Leone", english: "Sierra Leone" },
  { id: 156, arabic: "سنغافورة", french: "Singapour", english: "Singapore" },
  { id: 157, arabic: "سلوفاكيا", french: "Slovaquie", english: "Slovakia" },
  { id: 158, arabic: "سلوفينيا", french: "Slovénie", english: "Slovenia" },
  { id: 159, arabic: "جزر سليمان", french: "Îles Salomon", english: "Solomon Islands" },
  { id: 160, arabic: "الصومال", french: "Somalie", english: "Somalia" },
  { id: 161, arabic: "جنوب أفريقيا", french: "Afrique du Sud", english: "South Africa" },
  { id: 162, arabic: "جنوب السودان", french: "Soudan du Sud", english: "South Sudan" },
  { id: 163, arabic: "إسبانيا", french: "Espagne", english: "Spain" },
  { id: 164, arabic: "سريلانكا", french: "Sri Lanka", english: "Sri Lanka" },
  { id: 165, arabic: "السودان", french: "Soudan", english: "Sudan" },
  { id: 166, arabic: "سورينام", french: "Suriname", english: "Suriname" },
  { id: 167, arabic: "السويد", french: "Suède", english: "Sweden" },
  { id: 168, arabic: "سويسرا", french: "Suisse", english: "Switzerland" },
  { id: 169, arabic: "سوريا", french: "Syrie", english: "Syria" },
  { id: 170, arabic: "طاجيكستان", french: "Tadjikistan", english: "Tajikistan" },
  { id: 171, arabic: "تنزانيا", french: "Tanzanie", english: "Tanzania" },
  { id: 172, arabic: "تايلاند", french: "Thaïlande", english: "Thailand" },
  { id: 173, arabic: "تيمور الشرقية", french: "Timor oriental", english: "Timor-Leste" },
  { id: 174, arabic: "توجو", french: "Togo", english: "Togo" },
  { id: 175, arabic: "تونغا", french: "Tonga", english: "Tonga" },
  {
    id: 176,
    arabic: "ترينيداد وتوباغو",
    french: "Trinité-et-Tobago",
    english: "Trinidad and Tobago",
  },
  { id: 177, arabic: "تونس", french: "Tunisie", english: "Tunisia" },
  { id: 178, arabic: "تركيا", french: "Turquie", english: "Turkey" },
  { id: 179, arabic: "تركمانستان", french: "Turkménistan", english: "Turkmenistan" },
  { id: 180, arabic: "توفالو", french: "Tuvalu", english: "Tuvalu" },
  { id: 181, arabic: "أوغندا", french: "Ouganda", english: "Uganda" },
  { id: 182, arabic: "أوكرانيا", french: "Ukraine", english: "Ukraine" },
  {
    id: 183,
    arabic: "الإمارات العربية المتحدة",
    french: "Émirats Arabes Unis",
    english: "United Arab Emirates",
  },
  { id: 184, arabic: "المملكة المتحدة", french: "Royaume-Uni", english: "United Kingdom" },
  { id: 185, arabic: "الولايات المتحدة", french: "États-Unis", english: "United States" },
  { id: 186, arabic: "أوروغواي", french: "Uruguay", english: "Uruguay" },
  { id: 187, arabic: "أوزبكستان", french: "Ouzbékistan", english: "Uzbekistan" },
  { id: 188, arabic: "فانواتو", french: "Vanuatu", english: "Vanuatu" },
  { id: 189, arabic: "فنزويلا", french: "Venezuela", english: "Venezuela" },
  { id: 190, arabic: "فيتنام", french: "Viêt Nam", english: "Vietnam" },
  { id: 191, arabic: "اليمن", french: "Yémen", english: "Yemen" },
  { id: 192, arabic: "زامبيا", french: "Zambie", english: "Zambia" },
  { id: 193, arabic: "زيمبابوي", french: "Zimbabwe", english: "Zimbabwe" },
];
