import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import ServiceItem from "OurPages/OurComponents/Home/ServiceItem";
import { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import { getSecteurs } from "services/secteursService";
import { useMutation } from "@tanstack/react-query";
import { getServices } from "services/servicesService";
import { BASE_URL_Image } from "services/server";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Pagination from "@mui/material/Pagination";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
// Import Swiper React components

import Hammer from "hammerjs";
// Import Swiper styles
import "swiper/css";

import HorizontalScroll from "react-scroll-horizontal";
import "./serviceSlider.css";
import { Close } from "@mui/icons-material";
import { Box, Button, ButtonBase, LinearProgress } from "@mui/material";
import SearchSection from "components/search/SearchSection";
import ServiceCard from "ui-component/cards/Skeleton/ServiceCard";
import { toast } from "react-toastify";
import { demandeOption } from "services/visiteurService";
import { useTheme } from "@mui/material/styles";
import { demandePersonnalisees } from "services/visiteurService";
import MustAuth from "components/shareProduct/MustAuth";

const Container = tw.div`relative w-full`;
const Content = tw.div`max-w-screen-xl mx-auto `;

const Actions = styled.div`
  ${tw`relative  text-center mx-auto lg:mx-0`}
  input {
    ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-primary-500 hover:border-gray-500 border-none`}
  }
  button {
    ${tw`w-full sm:absolute right-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center  sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300 `}
  }
`;

export default () => {
  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [sliderRef, setSliderRef] = useState(null);
  const [sliderHammerRef, setSliderHammerRef] = useState(null);
  const [activeSecteur, setActiveSecteur] = useState(0);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const lang = useSelector((state) => state.customization.lang);
  const theme = useTheme();

  const [auth, setAuth] = useState(false);
  const [UserRole, setUserRole] = useState("");
  const [openAdd2, setOpenAdd2] = useState(false);

  const [openAdd, setOpenAdd] = useState(false);

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [Message, setMessage] = useState("");
  const [Phone, setPhone] = useState("");
  const [WhatsApp, setWhatsApp] = useState("");
  const [dejaUser, setDejaUser] = useState(false);

  useEffect(() => {
    let token = localStorage.getItem("token");
    setAuth(token != null && token !== "");
    let role = localStorage.getItem("role");
    if (role != null && role != "null" && role != "") {
      setUserRole(role);
    }
  }, []);

  const handleOpenAdd = () => {
    setOpenAdd(true);
  };
  const handleCloseAdd = () => {
    setUsername("");
    setEmail("");
    setPhone("");
    setMessage("");
    setDejaUser(false);
    setOpenAdd(false);
  };

  const lancerPaiment = () => {
    var user = {
      nom: username,
      email,
      phone: Phone,
      whatsapp: WhatsApp,
      message: Message,
      alreadyUser: dejaUser,
      // file: selectedFile,
    };
    console.log(user);

    var valid = true;

    if (!username || username === "") {
      notify("Entrez votre Dénomination SVP !");
      valid = false;
    } else if (
      !email ||
      email === "" ||
      !String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      notify("Entrez votre adresse email SVP !");
      valid = false;
    } else if (!Phone || Phone === "") {
      notify("Veuillez entrer votre numéro de téléphone SVP !");
      valid = false;
    } else {
      notify_success("valid form");

      mutateAdd(user);
      // navigate("/admin");
    }
  };

  const notify = (message) => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 3000,
      fontSize: "12px",
    });
  };

  const notify_success = (message) => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 3000,
    });
  };

  const { mutate: mutateAdd, isLoading: isLoadingAdd } = useMutation(
    (userData) => demandePersonnalisees(userData),
    {
      async onSuccess(data) {
        console.log(data);

        if (data.success) {
          notify_success("Votre demande a été envoyée avec succès");
          handleCloseAdd();
        } else if (data.message !== "") {
          notify(data.message);
        } else {
          notify("error");
        }
      },
      onError(error) {
        notify("error");
        if (Array.isArray(error.response.data.error)) {
          error.response.data.error.forEach((el) => console.log(el.message));
        } else {
          console.log(error.response.data.message);
        }
      },
    }
  );

  const [page, setPage] = useState({
    index: 1,
    limit: 6,
    totalItems: 0,
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (sliderRef) {
        sliderRef?.slickNext();
      }
    }, 3000);
    return () => {
      clearInterval(intervalId);
    };
  }, [sliderRef]);

  useEffect(() => {
    const element = sliderHammerRef?.current;
    if (element) {
      const hammer = new Hammer(element);
      hammer.get("pan").set({ direction: Hammer.DIRECTION_HORIZONTAL });
      hammer.on("pan", this.handlePan);
    }
  }, [sliderHammerRef]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (document.getElementById("header_smart") != null)
      document.getElementById("header_smart").classList.add("header_smart_solution");
    return () => {
      if (document.getElementById("header_smart") != null)
        document.getElementById("header_smart").classList.remove("header_smart_solution");
    };
  }, []);

  const [items, setItems] = useState([]);
  const [showFilter, setShowfilter] = useState(false);

  const [services, setServices] = useState([]);
  const { mutate: mutateServices, isLoading: isLoadingServices } = useMutation(
    (filters) => getServices({ ...filters, home: true }),
    {
      async onSuccess(data) {
        console.log(data);
        if (data && data.data) {
          setServices(
            data.data.map((el) => {
              return {
                id: el.service_id,
                imageSrc: BASE_URL_Image + el.image,
                title: el.titre,
                description: el.description,
                title_en: el.titre_en,
                description_en: el.description_en,
                title_ar: el.titre_ar,
                description_ar: el.description_ar,
                locationText: el.localisation,
                entrepriseText: el.entreprise_name,
                entrepriseImage: BASE_URL_Image + el.entreprise_image,
                entreprise_id: el.entreprise_id,
                groupeText: el.groupe_name,
                groupeImage: BASE_URL_Image + el.groupe_image,
                groupe_id: el.groupe_id,
                secteur: el.secteur_name,
                secteur_id: el.secteur_id,
                fav: el.fav ? parseInt(el.fav) === 1 : false,
              };
            })
          );
          setPage((oldValue) => {
            return { ...oldValue, totalItems: parseInt(data.total) };
          });
        } else {
        }
      },
      onError(error) {},
    }
  );

  const [secteurs, setSecteurs] = useState([]);
  const { mutate: mutateSecteurs, isLoading: isLoadingSecteurs } = useMutation(
    () => getSecteurs(),
    {
      async onSuccess(data) {
        console.log(data);
        if (data && data.data) {
          setSecteurs(
            data.data.map((el) => {
              return {
                id: el.id,
                text: el.nom,
              };
            })
          );
        } else {
        }
      },
      onError(error) {},
    }
  );

  useEffect(() => {
    let homeSearch = localStorage.getItem("searchService");
    let homeSecteur = localStorage.getItem("activatedSecteur");
    if ((homeSearch == null || homeSearch === "") && (homeSecteur == null || homeSecteur === "")) {
      console.log("use effect active secteur");
      mutateServices({
        secteur_id: activeSecteur,
        filter: search,
        limit: 6,
        offset: (page.index - 1) * 6,
      });
    }
  }, [activeSecteur]);

  useEffect(() => {
    let homeSearch = localStorage.getItem("searchService");
    let homeSecteur = localStorage.getItem("activatedSecteur");
    if ((homeSearch == null || homeSearch === "") && (homeSecteur == null || homeSecteur === "")) {
      console.log("use effect page index");
      if (activeSecteur && activeSecteur > 0) {
        mutateServices({
          secteur_id: activeSecteur,
          filter: search,
          limit: page.limit,
          offset: (page.index - 1) * page.limit,
        });
      } else {
        mutateServices({
          filter: search,
          limit: page.limit,
          offset: (page.index - 1) * page.limit,
        });
      }
    }
  }, [page.index]);

  const rechercher = () => {
    if (activeSecteur && activeSecteur > 0) {
      mutateServices({
        secteur_id: activeSecteur,
        limit: page.limit,
        offset: (page.index - 1) * page.limit,
      });
    } else {
      mutateServices({ filter: search, limit: page.limit, offset: (page.index - 1) * page.limit });
    }
  };

  function isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      window.navigator.userAgent
    );
  }

  useEffect(() => {
    console.log("loading");
    // mutateSecteurs();
    let homeSearch = localStorage.getItem("searchService");
    let homeSecteur = localStorage.getItem("activatedSecteur");
    if (homeSearch && homeSearch !== "") {
      setSearch(homeSearch);
      if (homeSecteur && homeSecteur !== "") {
        setActiveSecteur(parseInt(homeSecteur));
        mutateServices({
          secteur_id: parseInt(homeSecteur),
          filter: homeSearch,
          limit: page.limit,
          offset: (page.index - 1) * page.limit,
        });
      } else {
        mutateServices({
          filter: homeSearch,
          limit: page.limit,
          offset: (page.index - 1) * page.limit,
        });
      }
    } else {
      if (homeSecteur && homeSecteur !== "") {
        setActiveSecteur(parseInt(homeSecteur));
        mutateServices({
          secteur_id: parseInt(homeSecteur),
          filter: search,
          limit: page.limit,
          offset: (page.index - 1) * page.limit,
        });
      } else {
        mutateServices({
          filter: search,
          limit: page.limit,
          offset: (page.index - 1) * page.limit,
        });
      }
    }
    localStorage.removeItem("searchService");
    localStorage.removeItem("activatedSecteur");
  }, []);

  return (
    <div id="products">
      <Container style={{ marginTop: "100px", direction: lang === "ar" ? "rtl" : "ltr" }}>
        <Content>
          <Row>
            <Col xs={12} md={9} className="mb-1">
              <SearchSection
                label={lang === "fr" ? "Produit" : lang === "en" ? "Product" : "المنتج"}
                value={search}
                valueChange={(value) => setSearch(value)}
                valueFilter={rechercher}
              />
            </Col>
            <Col xs={12} md={3} className="mb-1 d-flex align-items-center justify-content-center">
              <Button
                sx={{
                  ...theme.typography.commonAvatar,
                  ...theme.typography.button,
                  transition: "all .2s ease-in-out",
                  width: "100%",
                  background: theme.palette.secondary.light,
                  color: theme.palette.secondary.dark,
                  "&:hover": {
                    background: theme.palette.secondary.dark,
                    color: theme.palette.secondary.light,
                  },
                  height: "100%",
                  overflow: "hidden",
                  borderRadius: "12px",
                  fontSize: "12px",
                }}
                className="py-2 px-5"
                onClick={() => {
                  if (auth) {
                    if (UserRole === "entreprise") {
                      navigate("/entreprise/products");
                    } else if (UserRole === "acheteur") {
                      notify(
                        lang === "fr"
                          ? "Vous devez être un vendeur"
                          : lang === "en"
                          ? "You must be a seller"
                          : "يجب أن تكون بائع"
                      );
                    }
                  } else {
                    setOpenAdd2(true);
                  }
                }}
              >
                {lang === "fr"
                  ? "Lancez votre produit"
                  : lang === "en"
                  ? "Launch your product"
                  : "أطلق منتوجك"}
              </Button>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Row>
                {services.length > 0 && (
                  <Col xs={12} className="d-flex justify-content-center align-items-center my-3">
                    {/* <Pagination>{items}</Pagination> */}
                    <Pagination
                      count={
                        parseInt(page.totalItems) % page.limit == 0
                          ? parseInt(parseInt(page.totalItems) / page.limit)
                          : parseInt(parseInt(page.totalItems) / page.limit) + 1
                      }
                      style={{ direction: "ltr" }}
                      page={page.index}
                      onChange={(event, value) => {
                        setPage((oldValue) => {
                          return { ...oldValue, index: value };
                        });
                      }}
                    />
                  </Col>
                )}

                {services.length === 0 && isLoadingServices ? (
                  <>
                    <Col
                      key={0}
                      xs={12}
                      md={6}
                      lg={4}
                      className="mb-5 px-2 d-flex align-items-start justify-content-center"
                    >
                      <ServiceCard />
                    </Col>
                    <Col
                      key={0}
                      xs={12}
                      md={6}
                      lg={4}
                      className="mb-5 px-2 d-flex align-items-start justify-content-center"
                    >
                      <ServiceCard />
                    </Col>
                    <Col
                      key={0}
                      xs={12}
                      md={6}
                      lg={4}
                      className="mb-5 px-2 d-flex align-items-start justify-content-center"
                    >
                      <ServiceCard />
                    </Col>
                  </>
                ) : (
                  <></>
                )}
                {services.map((card, index) => (
                  <Col
                    key={index}
                    xs={12}
                    md={6}
                    lg={4}
                    className="mb-5 px-2 d-flex align-items-start justify-content-center"
                  >
                    <ServiceItem initCard={card} isLoading={isLoadingServices}></ServiceItem>
                  </Col>
                ))}
                <Col xs={12} className="d-flex justify-content-center align-items-center">
                  {/* <Pagination>{items}</Pagination> */}
                  <Pagination
                    count={
                      parseInt(page.totalItems) % page.limit == 0
                        ? parseInt(parseInt(page.totalItems) / page.limit)
                        : parseInt(parseInt(page.totalItems) / page.limit) + 1
                    }
                    style={{ direction: "ltr" }}
                    page={page.index}
                    onChange={(event, value) => {
                      setPage((oldValue) => {
                        return { ...oldValue, index: value };
                      });
                    }}
                  />
                </Col>
                {page.totalItems == 0 && (
                  <Col xs={12} className="d-flex justify-content-center align-items-center">
                    <MDTypography variant="h6" color="light">
                      Aucun service
                    </MDTypography>
                  </Col>
                )}
                {!(isLoadingSecteurs || isLoadingServices) && (
                  <Col xs={12} className="">
                    <h3 className="text-lg font-semibold mb-3">
                      {lang === "fr"
                        ? "Faites une demande personnalisée"
                        : lang === "en"
                        ? "Make a personalized request"
                        : "تقديم طلب مخصص"}
                    </h3>
                    <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
                      {lang === "fr"
                        ? "Vous ne trouvez pas exactement ce que vous cherchez ? Pas de soucis ! "
                        : lang === "en"
                        ? "Can't find exactly what you're looking for? No problem! "
                        : "لا يمكنك العثور على ما تبحث عنه بالضبط؟ لا مشكلة! "}

                      <br></br>
                      {lang === "fr"
                        ? "Utilisez notre fonction de demande personnalisée pour spécifier vos besoins exacts. Nous travaillerons en étroite collaboration avec notre réseau de fournisseurs pour vous proposer des solutions sur mesure qui correspondent à vos critères."
                        : lang === "en"
                        ? "Use our custom request function to specify your exact requirements. We'll work closely with our network of suppliers to offer you customized solutions that meet your criteria."
                        : "استخدم وظيفة الاستفسار المخصص لدينا لتحديد متطلباتك بدقة. وسنعمل عن كثب مع شبكة الموردين لدينا لنقدم لك حلولاً مصممة خصيصاً لتلبية معاييرك."}
                    </p>
                    <div className="w-100 d-flex align-items-center justify-content-center">
                      <Button
                        variant="outlined"
                        color="primary"
                        style={{
                          color: "#0286c9",
                          borderColor: "#0286c9",
                          fontSize: "14px",
                        }}
                        onClick={handleOpenAdd}
                      >
                        {lang === "fr"
                          ? "Faire une demande"
                          : lang === "en"
                          ? "Make a request"
                          : "تقديم طلب"}
                      </Button>
                    </div>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Content>
      </Container>

      <Modal
        show={isLoadingSecteurs || isLoadingAdd}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="myModalLoading"
      >
        <Modal.Body style={{ overflow: "hidden" }}>
          <Box sx={{ width: "100%" }}>
            <LinearProgress color="secondary" />
          </Box>
        </Modal.Body>
      </Modal>

      <Modal
        show={openAdd}
        onHide={handleCloseAdd}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ fontSize: "0.85em", textAlign: lang === "ar" ? "right" : "left" }}
          >
            {lang === "fr" ? "Faire une demande" : lang === "en" ? "Make a request" : "تقديم طلب"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ direction: lang === "ar" ? "rtl" : "" }}>
          <div style={{ width: "100%" }}>
            <div className="w-100 d-flex justify-content-center align-items-center">
              <div style={{ maxWidth: "500px" }}>
                <div className="flex-auto">
                  <div className="relative w-full mb-3 mt-8">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="full-name"
                    >
                      {lang === "fr" ? "Dénomination" : lang === "en" ? "Denomination" : "التسمية"}
                    </label>
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder={
                        lang === "fr"
                          ? "Votre dénomination"
                          : lang === "en"
                          ? "Your denomination"
                          : "التسمية خاصتك"
                      }
                      onChange={(e) => setUsername(e.currentTarget.value)}
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="email"
                    >
                      {lang === "fr" ? "Email" : lang === "en" ? "Email" : "البريد الإلكتروني"}
                    </label>
                    <input
                      type="email"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder={
                        lang === "fr"
                          ? "votre email"
                          : lang === "en"
                          ? "your email"
                          : "بريدك الإلكتروني"
                      }
                      onChange={(e) => setEmail(e.currentTarget.value)}
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="email"
                    >
                      {lang === "fr"
                        ? "Numéro Téléphone"
                        : lang === "en"
                        ? "Phone number"
                        : "رقم الهاتف"}
                    </label>
                    <input
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder={
                        lang === "fr"
                          ? "Votre numéro du téléphone"
                          : lang === "en"
                          ? "Your phone number"
                          : "رقم هاتفك"
                      }
                      onChange={(e) => setPhone(e.currentTarget.value)}
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="whatsapp"
                    >
                      {lang === "fr" ? "WhatsApp" : lang === "en" ? "WhatsApp" : "واتساب"}
                    </label>
                    <input
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder={
                        lang === "fr"
                          ? "Votre WhatsApp"
                          : lang === "en"
                          ? "Your WhatsApp"
                          : "واتساب"
                      }
                      onChange={(e) => setWhatsApp(e.currentTarget.value)}
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="message"
                    >
                      {lang === "fr" ? "Message" : lang === "en" ? "Message" : "رسالتك"}
                    </label>
                    <textarea
                      rows="4"
                      cols="80"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                      placeholder={
                        lang === "fr"
                          ? "votre message..."
                          : lang === "en"
                          ? "your message..."
                          : "رسالتك..."
                      }
                      onChange={(e) => setMessage(e.currentTarget.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ direction: lang === "ar" ? "rtl" : "" }}>
          <>
            <ButtonBase
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.button,
                transition: "all .2s ease-in-out",
                background: theme.palette.secondary.light,
                color: theme.palette.secondary.dark,
                "&:hover": {
                  background: theme.palette.secondary.dark,
                  color: theme.palette.secondary.light,
                },
                height: "100%",
                overflow: "hidden",
              }}
              className="py-2 px-5"
              onClick={handleCloseAdd}
            >
              {lang === "fr" ? "Annuler" : lang === "en" ? "Cancel" : "إلغاء"}
            </ButtonBase>

            <ButtonBase
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.button,
                transition: "all .2s ease-in-out",
                background: theme.palette.primary.dark,
                color: theme.palette.primary.light,
                "&:hover": {
                  background: theme.palette.primary.light,
                  color: theme.palette.primary.dark,
                },
                height: "100%",
                overflow: "hidden",
              }}
              className="py-2 px-5"
              onClick={lancerPaiment}
            >
              {lang === "fr" ? "Envoyer" : lang === "en" ? "Send" : "إرسال"}
            </ButtonBase>
          </>
        </Modal.Footer>
      </Modal>

      <MustAuth openAdd={openAdd2} setOpenAdd={setOpenAdd2}></MustAuth>
    </div>
  );
};
