// admin components

import EntrepriseProfile from "layoutsEntreprises/ProfileEntreprise";
import EEntreprisesList from "layoutsEntreprises/EntreprisesList";
import ESecteurList from "layoutsEntreprises/SecteurList";
import EPublireportageList from "layoutsEntreprises/PublireportageList";
import EALauneList from "layoutsEntreprises/ALauneList";
import EBannieresList from "layoutsEntreprises/BannieresList";
import EContact from "layoutsEntreprises/Contact";
import EProductMessagesList from "layoutsAdmin/ProductMessagesList";
import Dashboard from "layoutsAdmin/Dashboard";
import EAbonnementList from "layoutsEntreprises/AbonnementList";
import EProductList from "layoutsEntreprises/ServiceList";
import EServicesList from "layoutsEntreprises/ServicesList";
import EDemandesPartenairesList from "layoutsEntreprises/DemandesPartenairesList";
import EDemandesPersonnaliseesList from "layoutsEntreprises/DemandesPersonnaliseesList";
import EUsersList from "layoutsEntreprises/UsersList";
import EAnnonceList from "layoutsEntreprises/AnnonceList";
import EInscriptionsList from "layoutsEntreprises/InscriptionsList";
import { Icon } from "@mui/material";

import {
  IconUser,
  IconDashboard,
  IconPackage,
  IconSlideshow,
  IconPhotoStar,
  IconKey,
  IconAdjustments,
  IconClipboard,
  IconMessage,
  IconServicemark,
  IconShoppingCart,
  IconCreditCard,
  IconFileAlert,
  IconHeart,
  IconNotification,
} from "@tabler/icons";
import { IconVideo } from "@tabler/icons-react";
import NotificationsPage from "layouts/MainLayout/Header/NotificationSection/NotificationPage/index";

const AdminRoutes = [
  // admins

  // {
  //   type: "collapse",
  //   name: "Dashboard",
  //   key: "admin",
  //   icon: <Icon fontSize="small">dashboard</Icon>,
  //   route: "/admin",
  //   component: <MDTypography ></MDTypography>,
  //   role : "admin" ,
  //  group: "Users",
  // },

  {
    id: "admin",
    type: "collapse",
    name: "Dashboard",
    name_en: "Dashboard",
    name_ar: "لوحة القيادة",
    key: "admin",
    icon: IconDashboard,
    route: "/admin/",
    component: <Dashboard></Dashboard>,
    role: "admin",
    group: "Dashboard",
    group_en: "Dashboard",
    group_ar: "لوحة القيادة",
  },

  {
    id: "adminvendeurs",
    type: "collapse",
    name: "Vendeurs",
    name_en: "Sellers",
    name_ar: "الباعة",
    key: "adminvendeurs",
    icon: IconUser,
    route: "/admin/adminvendeurs",
    component: <EUsersList type="vendeur" />,
    role: "admin",
    group: "Utilisateurs",
    group_en: "Users",
    group_ar: "المستخدمين",
  },

  {
    id: "adminacheteurs",
    type: "collapse",
    name: "Acheteurs",
    name_en: "Buyers",
    name_ar: "المشترين",
    key: "adminacheteurs",
    icon: IconUser,
    route: "/admin/adminacheteurs",
    component: <EUsersList type="acheteur" />,
    role: "admin",
    group: "Utilisateurs",
    group_en: "Users",
    group_ar: "المستخدمين",
  },
  {
    id: "products",
    type: "collapse",
    name: "Produits",
    name_en: "Products",
    name_ar: "المنتجات",
    key: "_admin_products",
    icon: IconPackage,
    route: "/admin/products",
    component: <EProductList fav={false} />,
    role: "admin",
    group: "Produits",
    group_en: "Products",
    group_ar: "المنتجات",
  },
  {
    id: "productsmessages",
    type: "collapse",
    name: "Messages",
    name_en: "Messages",
    name_ar: "الرسائل",
    key: "_admin_productsmessages",
    icon: IconMessage,
    route: "/admin/productsmessages",
    component: <EProductMessagesList role="acheteur" type="produit" />,
    role: "admin",
    group: "Produits",
    group_en: "Products",
    group_ar: "المنتجات",
  },
  {
    id: "annonces",
    type: "collapse",
    name: "Annonces",
    name_en: "Announcements",
    name_ar: "الإعلانات",
    key: "_admin_annonces",
    icon: IconPackage,
    route: "/admin/annonces",
    component: <EAnnonceList fav={false} />,
    role: "admin",
    group: "Annonces",
    group_en: "Announcements",
    group_ar: "الإعلانات",
  },
  {
    id: "annoncemessages",
    type: "collapse",
    name: "Messages",
    name_en: "Messages",
    name_ar: "الرسائل",
    key: "_admin_annoncemessages",
    icon: IconMessage,
    route: "/admin/annoncemessages",
    component: <EProductMessagesList role="acheteur" type="annonce" />,
    role: "admin",
    group: "Annonces",
    group_en: "Announcements",
    group_ar: "الإعلانات",
  },
  {
    id: "demandeservices",
    type: "collapse",
    name: "Services",
    name_en: "Services",
    name_ar: "الخدمات",
    key: "_admin_demandeservices",
    icon: IconCreditCard,
    route: "/admin/demandeservices",
    component: <EServicesList />,
    role: "admin",
    group: "Demandes",
    group_en: "Demands",
    group_ar: "الطلبات",
  },
  {
    id: "demandepartenaire",
    type: "collapse",
    name: "Partenaires",
    name_en: "Partners",
    name_ar: "الشركاء",
    key: "_admin_demandepartenaire",
    icon: IconCreditCard,
    route: "/admin/demandepartenaire",
    component: <EDemandesPartenairesList />,
    role: "admin",
    group: "Demandes",
    group_en: "Demands",
    group_ar: "الطلبات",
  },
  {
    id: "demandepersonnalisees",
    type: "collapse",
    name: "Personnalisées",
    name_en: "Custom",
    name_ar: "المخصصة",
    key: "_admin_demandepersonnalisees",
    icon: IconCreditCard,
    route: "/admin/demandepersonnalisees",
    component: <EDemandesPersonnaliseesList />,
    role: "admin",
    group: "Demandes",
    group_en: "Demands",
    group_ar: "الطلبات",
  },
  {
    id: "publireportages",
    type: "collapse",
    name: "Publi-reportages",
    name_en: "Advertorials",
    name_ar: "ريبورتاج",
    key: "_admin_publireportages",
    icon: IconPhotoStar,
    route: "/admin/publireportages",
    component: <EPublireportageList />,
    role: "admin",
    group: "Pubs",
    group_en: "Platform Ads",
    group_ar: "إعلانات المنصة",
  },
  {
    id: "bannieres",
    type: "collapse",
    name: "Bannières",
    name_en: "Banners",
    name_ar: "لافتات",
    key: "_admin_bannieres",
    icon: IconSlideshow,
    route: "/admin/bannieres",
    component: <EBannieresList />,
    role: "admin",
    group: "Pubs",
    group_en: "Platform Ads",
    group_ar: "إعلانات المنصة",
  },
  {
    id: "videoalaune",
    type: "collapse",
    name: "Vidéo à la une",
    name_en: "Featured Video",
    name_ar: "فيديو مميز",
    key: "_admin_videoalaune",
    icon: IconVideo,
    route: "/admin/videoalaune",
    component: <EALauneList />,
    role: "admin",
    group: "Pubs",
    group_en: "Platform Ads",
    group_ar: "إعلانات المنصة",
  },
  // {
  //   id: "abonnements",
  //   type: "collapse",
  //   name: "Abonnements",
  //   key: "_admin_abonnements",
  //   icon: IconKey,
  //   route: "/admin/abonnements",
  //   component: <EInscriptionsList />,
  //   role: "admin",
  //   group: "Parametres",
  // },
  {
    id: "settings",
    type: "collapse",
    name: "Paramètres",
    name_en: "Settings",
    name_ar: "الإعدادات",
    key: "_admin_settings",
    icon: IconAdjustments,
    route: "/admin/settings",
    component: <EContact role="admin" />,
    role: "admin",
    group: "Paramètres",
    group_en: "Settings",
    group_ar: "الإعدادات",
  },
  {
    id: "notifications",
    type: "collapse",
    name: "Notificaions",
    name_en: "Notificaions",
    name_ar: "الإشعارات",
    key: "_admin_notifications",
    icon: IconNotification,
    route: "/admin/notifications",
    component: <NotificationsPage />,
    role: "admin",
    group: "Paramètres",
    group_en: "Settings",
    group_ar: "الإعدادات",
  },
];

export default AdminRoutes;
