import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Avatar, Badge, Button, Tooltip, Typography } from "@mui/material";

import { CheckCircleOutline, OpenInNew } from "@mui/icons-material";
import { IconClock } from "@tabler/icons";
import ServiceMessageDetails from "./MessageDatails";
import { useEffect, useState } from "react";
import { BASE_URL_Image } from "services/server";
import MDBadge from "components/MDBadge";
import { useSelector } from "react-redux";

const ServiceMessageItem = ({ message, handleOpen, userId }) => {
  const theme = useTheme();
  const lang = useSelector((state) => state.customization.lang);

  useEffect(() => {}, []);

  const avatars = (image) =>
    image.map(([image, name], index) => (
      <Tooltip title={name} placeholder="bottom">
        <div
          style={{
            width: "40px",
            height: "40px",
            overflow: "hidden",
            borderRadius: "50%",
            backgroundColor: "#ede7f6",
            border: "2px solid #cabae9",
            marginLeft: index > 0 ? "-20px" : "",
            cursor: "pointer",
          }}
          className="d-flex align-items-center justify-content-center"
        >
          <img
            key={name}
            src={image}
            alt="name"
            size="md"
            style={{ width: "40px", height: "auto" }}
          />
        </div>
      </Tooltip>
    ));

  return (
    <>
      {userId != null && message && message.id ? (
        <div className="w-100 row m-0  p-0 mt-4 py-3" style={{ borderTop: "1px solid #ebebeb" }}>
          <div className="col-12 col-md-6 d-flex justify-content-start align-items-center mb-1">
            <Typography
              noWrap
              variant="caption"
              style={{ color: theme.palette.grey[400], fontSize: "11px", fontWeight: "bold" }}
            >
              {lang === "fr" ? "le " : lang === "en" ? "the " : "في "} {message.send_date}
            </Typography>
          </div>
          <div className="col-12 col-md-6 d-flex justify-content-end align-items-center mb-1">
            <Tooltip
              title={message.message_readed === 1 ? message.readed_date : ""}
              placeholder="bottom"
            >
              <MDBadge
                badgeContent={
                  message.message_readed === 1
                    ? "" + (lang === "fr" ? "Lu" : lang === "en" ? "Readed" : "مقروء")
                    : "" + (lang === "fr" ? "Non" : lang === "en" ? "No" : "لا")
                }
                color={message.message_readed === 1 ? "dark" : "info"}
                variant="gradient"
                size="sm"
              />
            </Tooltip>

            <Tooltip
              title={message.answered === 1 ? message.response_date : ""}
              placeholder="bottom"
            >
              <Badge
                className="px-3 py-1 d-flex align-items-center ml-2"
                sx={{
                  transition: "all .2s ease-in-out",
                  background: message.answered
                    ? theme.palette.success.dark
                    : theme.palette.warning.dark,
                  color: "white !important",
                  width: "max-content",
                  overflow: "hidden",
                  borderRadius: "8px",
                }}
              >
                {message.answered ? (
                  <CheckCircleOutline
                    style={{ height: "14px" }}
                    className="mr-2"
                  ></CheckCircleOutline>
                ) : (
                  <IconClock style={{ height: "14px" }} className="mr-2"></IconClock>
                )}
                <Typography noWrap style={{ fontWeight: "bold", fontSize: "10px" }}>
                  {message.answered
                    ? "" + (lang === "fr" ? "Répondu" : lang === "en" ? "Responsed" : "تم الرد")
                    : "" + (lang === "fr" ? "Non" : lang === "en" ? "No" : "لا")}
                </Typography>
              </Badge>
            </Tooltip>
            <Tooltip
              title={message.response_readed === 1 ? message.response_readed_date : ""}
              placeholder="bottom"
            >
              <MDBadge
                badgeContent={
                  message.response_readed === 1
                    ? "" +
                      (lang === "fr"
                        ? "Réponse Lue"
                        : lang === "en"
                        ? "Response Readed"
                        : "رد مقروء")
                    : "" + (lang === "fr" ? "Non" : lang === "en" ? "No" : "لا")
                }
                color={message.response_readed === 1 ? "dark" : "info"}
                variant="gradient"
                size="sm"
              />
            </Tooltip>
          </div>
          <div className="col-12 col-lg-8 d-flex justify-content-start align-items-center">
            {avatars([[BASE_URL_Image + message.sender_image, message.sender_name]])}
            <Typography noWrap variant="h6" className="ml-2">
              {message.subject}
            </Typography>
          </div>
          <div className="col-12  col-lg-4 d-flex justify-content-end align-items-center">
            <Button
              startIcon={<OpenInNew fontSize="medium"></OpenInNew>}
              className="mx-2 px-3"
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.button,
                transition: "all .2s ease-in-out",
                background: "white ",
                color: theme.palette.info.dark,
                "&:hover": {
                  background: theme.palette.info.dark,
                  color: "white !important",
                },
                width: "max-content",
                overflow: "hidden",
                fontSize: "11px",
              }}
              onClick={() => {
                handleOpen(message);
              }}
            ></Button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

ServiceMessageItem.propTypes = {
  isLoading: PropTypes.bool,
};

export default ServiceMessageItem;
