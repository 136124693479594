import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";

// Data
import data from "layouts/PublireportageList/components/data";
import { Grid } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import MDButton from "components/MDButton";
import tw from "twin.macro";
import styled from "styled-components";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import MDInput from "components/MDInput";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Pagination } from "react-bootstrap";
import { BASE_URL_Image } from "services/server";
import { useMutation } from "@tanstack/react-query";
import { getPublireportages } from "services/adminService";
import dayjs from "dayjs";
import { updatePublireportage } from "services/adminService";
const CardPub = tw.div`h-full flex! flex-col sm:border max-w-sm  relative focus:outline-none bg-white m-0`;
const CardImage = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full h-56 sm:h-64 bg-cover bg-center `,
]);

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-2xl font-bold`;

const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4`;

const PrimaryButton = tw(
  PrimaryButtonBase
)`mt-auto sm:text-lg rounded-none w-full  py-3 sm:py-6 border-none`;

function List() {
  const dateToDateJS = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();

    const dateString = `${year}/${month}/${day}`;
    console.log("init date debut");
    console.log(dateString);
    return dayjs(dateString);
  };

  const [publireportage, setPublireportage] = useState(null);

  const [page, setPage] = useState({
    index: 1,
    limit: 5,
    totalItems: 0,
  });
  const [search, setSearch] = useState("");
  const [dateDebut, setDateDebut] = useState(dateToDateJS(new Date()));
  const [dateFin, setDateFin] = useState(null);

  const [paginationItems, setPaginationItems] = useState([]);

  const [items, setItems] = useState([]);

  const [open, setOpen] = useState(false);
  const handleOpen = (publireportage) => {
    console.log(publireportage);
    setPublireportage(publireportage);
    setOpen(true);
  };
  const handleClose = () => {
    setPublireportage(null);
    setOpen(false);
  };

  const [openArchivage, setOpenArchivage] = useState(false);
  const handleOpenArchivage = (publireportage) => {
    console.log(publireportage);
    setPublireportage(publireportage);
    setOpenArchivage(true);
  };
  const handleCloseArchivage = () => {
    setPublireportage(null);
    setOpenArchivage(false);
  };

  const { mutate, isLoading } = useMutation((userInfo) => getPublireportages(userInfo), {
    async onSuccess(data) {
      console.log(data);
      if (data && data.data) {
        setItems(
          data.data.map((el) => {
            return {
              id: el.id,
              status: el.status,
              archive: el.archive,
              company: {
                id: el.entreprise_id,
                groupe_id: el.groupe_id,
                image:
                  el.groupe_id != null
                    ? BASE_URL_Image + el.groupe_image
                    : BASE_URL_Image + el.entreprise_image,
                name: el.groupe_id != null ? el.groupe_name : el.entreprise_name,
              },
              publireportage: {
                id: el.id,
                imageSrc: BASE_URL_Image + el.image,
                title: el.titre,
                description: el.contenu,
                start: el.date_debut,
                end: el.date_fin,
              },
            };
          })
        );
        setPage((oldValue) => {
          return { ...oldValue, totalItems: parseInt(data.total) };
        });
        let table_items = [];
        if (parseInt(data.total) !== 0) {
          for (
            let number = 1;
            number <= parseInt(parseInt(data.total) / 5) + (parseInt(data.total) % 5 > 0 ? 1 : 0);
            number++
          ) {
            table_items.push(
              <Pagination.Item
                key={number}
                active={number === page.index}
                onClick={() => {
                  setPage((oldValue) => {
                    return { ...oldValue, index: number };
                  });
                }}
              >
                {number}
              </Pagination.Item>
            );
          }
        }
        setPaginationItems(table_items);
      } else {
      }
    },
    onError(error) {},
  });

  useEffect(() => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    setDateFin(dateToDateJS(tomorrow));
    mutate({ filter: search, limit: 5, offset: (page.index - 1) * 5 });
  }, []);

  const [openValidation, setOpenValidation] = useState(false);
  const handleOpenValidation = (publireportage) => {
    console.log(dateDebut);
    console.log(dateFin);
    setPublireportage(publireportage);
    setOpenValidation(true);
    console.log(publireportage);
  };
  const handleCloseValidation = () => {
    setDateDebut(dateToDateJS(new Date()));
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    setDateFin(dateToDateJS(tomorrow));
    setPublireportage(null);
    setOpenValidation(false);
  };

  const [openAffichage, setOpenAffichage] = useState(false);
  const handleOpenAffichage = (publireportage) => {
    setPublireportage(publireportage);
    setOpenAffichage(true);
    console.log(publireportage);
  };
  const handleCloseAffichage = () => {
    setOpenAffichage(false);
  };

  const { columns, rows } = data({
    handleOpen,
    handleOpenValidation,
    handleOpenAffichage,
    handleOpenArchivage,
    items: items,
  });

  const rechercher = () => {
    mutate({ filter: search, limit: 5, offset: (page.index - 1) * 5 });
  };

  const { mutate: updatePub, isLoading: isLoadingUpdatePub } = useMutation(
    (userInfos) => updatePublireportage(userInfos),
    {
      async onSuccess(data) {
        console.log(data);
        if (data) {
          mutate();
          // setOpenValidation((oldValue) => {return {id : null , value : false}});
        } else {
        }
      },
      onError(error) {},
    }
  );

  const annulerItem = () => {
    updatePub({ id: publireportage.id, status: -1 });
    handleClose();
  };

  const validerItem = () => {
    console.log(publireportage);
    updatePub({
      id: publireportage.id,
      status: 1,
      date_debut: dateDebut.format("YYYY/MM/DD"),
      date_fin: dateFin.format("YYYY/MM/DD"),
    });
    handleCloseValidation();
  };

  const archiverItem = () => {
    updatePub({ id: publireportage.id, archive: 1 });
    handleCloseArchivage();
  };

  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox style={{ width: "100%" }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <MDTypography variant="h6" gutterBottom>
                Liste des publireportages
              </MDTypography>
              <MDBox display="flex" alignItems="center" lineHeight={0}>
                <Icon
                  sx={{
                    fontWeight: "bold",
                    color: ({ palette: { info } }) => info.main,
                    mt: -0.5,
                  }}
                >
                  ads_click
                </Icon>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;
                  <strong>
                    {items != null ? items.length : 0}{" "}
                    {items != null && items.length > 1 ? "Publireportages" : "Publireportage"}
                  </strong>{" "}
                  en total
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <MDBox>
                <div className="px-2 w-100">
                  <Grid container spacing={3} className=" w-100 m-0">
                    <Grid item style={{ flex: 1 }} className="px-2">
                      <MDInput
                        style={{ width: "100%" }}
                        label="Recherche"
                        onChange={(e) => setSearch(e.currentTarget.value)}
                      />
                    </Grid>
                    <Grid item className="d-flex justify-content-end">
                      <MDButton color="light" onClick={rechercher}>
                        Filtrer
                      </MDButton>
                    </Grid>
                  </Grid>
                </div>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <MDBox>
        <DataTable
          table={{ columns, rows }}
          showTotalEntries={false}
          isSorted={false}
          noEndBorder
          entriesPerPage={false}
        />
        <div className="w-100 d-flex justify-content-center align-items-center">
          <Pagination>{paginationItems}</Pagination>
        </div>
        {page.totalItems === 0 && (
          <div className="w-100 d-flex justify-content-center align-items-center">
            <MDTypography variant="h6" color="light">
              Aucun Publireportage
            </MDTypography>
          </div>
        )}
      </MDBox>
      <Modal
        show={open || openArchivage}
        onHide={open ? handleClose : handleCloseAffichage}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {open ? "Annulation d'un publireportage" : "Archivage d'un publireportage"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MDBox
            mx={2}
            my={1}
            py={3}
            px={2}
            variant="gradient"
            bgColor="error"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              {open
                ? "Est-ce-que vous voulez vraiment annuler ce publireportage ?"
                : "Est-ce-que vous voulez vraiment archiver ce publireportage ?"}
            </MDTypography>
          </MDBox>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button onClick={handleClose}>Confirmer</Button> */}
          <MDButton color="error" onClick={open ? annulerItem : archiverItem}>
            Confirmer
          </MDButton>
        </Modal.Footer>
      </Modal>

      <Modal
        show={openValidation}
        onHide={handleCloseValidation}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Validation d'un publireportage
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MDBox
            mx={2}
            my={1}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              Est-ce-que vous voulez vraiment valider ce publireportage ?
            </MDTypography>
          </MDBox>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CardPub
              style={{ maxWidth: "300px", width: "100%", boxShadow: "0 0 5px 1px lightgrey" }}
            >
              <CardImage imageSrc={publireportage != null ? publireportage.imageSrc : null} />
              <TextInfo style={{ maxHeight: "200px" }}>
                <TitleReviewContainer>
                  <Title>{publireportage != null ? publireportage.title : ""}</Title>
                </TitleReviewContainer>
                <Description>
                  {publireportage != null ? publireportage.description : ""}
                </Description>
              </TextInfo>

              <PrimaryButton>Voir l'entreprise</PrimaryButton>
            </CardPub>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Début"
                defaultValue={dateDebut}
                mode="date"
                format="YYYY-MM-DD"
                onChange={(date) => {
                  setDateDebut(date);
                }}
              />
            </LocalizationProvider>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Fin"
                defaultValue={dateFin}
                mode="date"
                format="YYYY-MM-DD"
                onChange={(date) => {
                  setDateFin(date);
                }}
              />
            </LocalizationProvider>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button onClick={handleCloseValidation}>Confirmer</Button> */}
          <MDButton color="info" onClick={validerItem}>
            Confirmer
          </MDButton>
        </Modal.Footer>
      </Modal>

      <Modal
        show={openAffichage}
        onHide={handleCloseAffichage}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Publireportage</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CardPub
              style={{ maxWidth: "300px", width: "100%", boxShadow: "0 0 5px 1px lightgrey" }}
            >
              <CardImage imageSrc={publireportage != null ? publireportage.imageSrc : null} />
              <TextInfo style={{ maxHeight: "200px" }}>
                <TitleReviewContainer>
                  <Title>{publireportage != null ? publireportage.title : ""}</Title>
                </TitleReviewContainer>
                <Description>
                  {publireportage != null ? publireportage.description : ""}
                </Description>
              </TextInfo>

              <PrimaryButton>Voir l'entreprise</PrimaryButton>
            </CardPub>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={isLoading || isLoadingUpdatePub}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <MDBox
            mx={2}
            my={1}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              {isLoading ? "Loading ..." : "Updating ..."}
            </MDTypography>
          </MDBox>
        </Modal.Body>
      </Modal>
    </Card>
  );
}

export default List;
