import React, { useEffect, useState } from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import Hero from "components/hero/BackgroundAsImage.js";
import Features from "components/features/DashedBorderSixFeatures";
import MainFeature from "components/features/TwoColSingleFeatureWithStats2.js";
import MainFeature2 from "components/features/TwoColWithTwoFeaturesAndButtons.js";
import Portfolio from "components/cards/PortfolioTwoCardsWithImage.js";
import Blog from "components/blogs/ThreeColSimpleWithImageAndDashedBorder.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
import FAQ from "./OurComponents/Home/FAQ";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustration.js";
import customerSupportIllustrationSrc from "images/customer-support-illustration.svg";
import HeaderHome from "./OurComponents/Home/Header";
import Footer from "./OurComponents/Home/Footer.js";
import SecteurSlider from "./OurComponents/Home/SecteursSlider";
import EntrepriseSlider from "./OurComponents/Home/EntrepriseSlider";
import PublicationsSlider from "./OurComponents/Home/PublicationsSlider";
import GetStarted from "./OurComponents/Home/GetStarted";
import EspacePub from "./OurComponents/Home/EspacePub";
import ListServices from "./OurComponents/Home/ListServices";
import ContactUs from "./OurComponents/Home/ContactUs";
import Abonnements from "./OurComponents/Home/Abonnements";
import { BASE_URL_Image } from "services/server";
import { useMutation } from "@tanstack/react-query";
import { getPublireportages } from "services/pubsService";
import { getSecteurs } from "services/secteursService";
import { getEntrepriseALaUne } from "services/visiteurService";
import { getHomeData } from "services/visiteurService";

export default () => {
  React.useEffect(() => {}, []);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    function handleScroll() {
      const position = window.pageYOffset;
      if (position > 70) {
        if (document.getElementById("header_smart") != null)
          document.getElementById("header_smart").classList.add("header_smart_solution");
      } else {
        if (document.getElementById("header_smart") != null)
          document.getElementById("header_smart").classList.remove("header_smart_solution");
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [publireportages, setpublireportages] = useState([]);
  const [SecteurItems, setSecteurItems] = useState([]);
  const [EntrepriseItems, setEntrepriseItems] = useState([]);
  const [Publications, setPublications] = useState([]);
  const [infos, setInfos] = useState({
    tel: "",
    fax: "",
    localisation: "",
    email: "",
    facebook: "",
    linkedin: "",
    twitter: "",
    instagram: "",
    presentation: "",
  });

  const { mutate, isLoading } = useMutation(() => getHomeData(), {
    async onSuccess(data) {
      console.log(data);
      if (data && data.data && data.data.pubs) {
        setpublireportages(
          data.data.pubs.map((el) => {
            return {
              id: el.id,
              imageSrc: BASE_URL_Image + el.image,
              title: el.titre,
              description: el.contenu,
              entreprise_id: el.entreprise_id,
            };
          })
        );
      }
      if (data && data.data && data.data.secteurs) {
        setSecteurItems(
          data.data.secteurs.map((el) => {
            return {
              imageSrc: BASE_URL_Image + el.image,
              id: el.id,
              title: el.nom,
            };
          })
        );
      }

      if (data && data.data && data.data.entreprises) {
        setEntrepriseItems(
          data.data.entreprises.map((el) => {
            return {
              imageSrc: BASE_URL_Image + el.profile_pic,
              id: el.id,
              entreprise: el.nom,
              description: el.description,
              secteur: el.secteur ? el.secteur : "",
            };
          })
        );
      }

      if (data && data.data && data.data.publications) {
        setPublications(
          data.data.publications.map((el) => {
            return {
              imageSrc: BASE_URL_Image + el.image,
              attachement: BASE_URL_Image + el.attachement,
              id: el.id,
              titre: el.titre,
              description: el.description,
              status: parseInt(el.status) === 1,
              archive: parseInt(el.archive) === 1,
            };
          })
        );
      }

      if (data && data.data && data.data.infos) {
        setInfos({
          tel: data.data.infos.tel != null && data.data.infos.tel !== "" ? data.data.infos.tel : "",
          fax: data.data.infos.fax != null && data.data.infos.fax !== "" ? data.data.infos.fax : "",
          localisation:
            data.data.infos.localisation != null && data.data.infos.localisation !== ""
              ? data.data.infos.localisation
              : "",
          email:
            data.data.infos.email != null && data.data.infos.email !== ""
              ? data.data.infos.email
              : "",
          facebook:
            data.data.infos.facebook != null && data.data.infos.facebook !== ""
              ? data.data.infos.facebook
              : "",
          linkedin:
            data.data.infos.linkedin != null && data.data.infos.linkedin !== ""
              ? data.data.infos.linkedin
              : "",
          twitter:
            data.data.infos.twitter != null && data.data.infos.twitter !== ""
              ? data.data.infos.twitter
              : "",
          presentation:
            data.data.infos.presentation != null && data.data.infos.presentation !== ""
              ? data.data.infos.presentation
              : "",
          siteweb:
            data.data.infos.sitweb != null && data.data.infos.sitweb !== ""
              ? data.data.infos.sitweb
              : "",
          instagram:
            data.data.infos.instagram != null && data.data.infos.instagram !== ""
              ? data.data.infos.instagram
              : "",
        });
      }
    },
    onError(error) {},
  });

  useEffect(() => {
    mutate();
  }, []);

  return (
    <AnimationRevealPage>
      {/* <Hero /> */}
      {/* <MainFeature /> */}
      {/* <Features /> */}
      {/* <MainFeature2 /> */}
      {/* <Portfolio /> */}

      <HeaderHome publireportages={publireportages} />
      <PublicationsSlider items={Publications} />
      <SecteurSlider items={SecteurItems} />
      <Testimonial
        //   subheading="Testimonials"
        heading={
          <>
            Aider vos clients à trouver <span tw="text-primary-500">vos services.</span>
          </>
        }
        description=""
        testimonials={[
          {
            imageSrc:
              "https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&auto=format&fit=crop&w=1024&q=80",
            profileImageSrc:
              "https://static.wixstatic.com/media/b40f54_97fc00f69b484800935a1fba601df105~mv2.jpg/v1/crop/x_0,y_256,w_2400,h_2488/fill/w_494,h_512,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/Ken%20Blanchard%202015%20color%20casual.jpg",
            quote:
              "Avoir des clients satisfaits n’est plus suffisant. Si vous voulez vraiment avoir une entreprise florissante, vous devez créer des fans enthousiastes.",
            customerName: "Ken Blanchard",
            customerTitle: "Consultant en management internationalement",
          },
          {
            imageSrc:
              "https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
            profileImageSrc:
              "https://miro.medium.com/v2/resize:fit:720/format:webp/1*4Mus3xmxYkx1CcEqr8u6Tw.jpeg",
            quote:
              "La clé est de fixer des attentes réalistes pour les clients, puis de ne pas se contenter de les satisfaire, mais de les dépasser – de préférence de manière inattendue et utile.",
            customerName: "Richard Branson",
            customerTitle: "the Virgin Group founder ",
          },
        ]}
        textOnLeft={true}
      />
      <GetStarted />
      <EntrepriseSlider items={EntrepriseItems} />
      <EspacePub />
      <FAQ
        imageSrc={
          "https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80"
        }
        imageContain={true}
        imageShadow={false}
        subheading="FAQs"
        heading={
          <>
            Avez-vous des <span tw="text-primary-500">Questions ?</span>
          </>
        }
      />

      {/* <Blog /> */}
      {/* <ContactUsForm /> */}

      <Footer infos={infos} />
    </AnimationRevealPage>
  );
};
