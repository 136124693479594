import React, { useEffect, useRef, useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import illustration from "images/signup-illustration.svg";
import logo from "images/logo.svg";
import googleIconImageSrc from "images/google-icon.png";
import twitterIconImageSrc from "images/twitter-icon.png";
import { ReactComponent as SignUpIcon } from "feather-icons/dist/icons/user-plus.svg";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { useNavigate, useParams } from "react-router-dom";
import MDBox from "components/MDBox";
import { Icon, Switch } from "@mui/material";
import MDTypography from "components/MDTypography";

import publication_abonnement_image from "assets/images/publication_abonnement.png";
import { ToastContainer, toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { register } from "services/authService";
import { getAbonnement } from "services/visiteurService";
import { Modal } from "react-bootstrap";
import MDButton from "components/MDButton";

import edahabiaImage from "images/edahabia.png";
import virementImage from "images/virement.png";
import virementHoverImage from "images/virement_hover.png";
import { inscriptionAbonnementsList } from "services/adminService";
import { demandeInscription } from "services/visiteurService";

const Container = tw(
  ContainerBase
)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

const SocialButtonsContainer = tw.div`flex flex-col items-center`;
const SocialButton = styled.a`
  ${tw`w-full max-w-xs font-semibold rounded-lg py-3 border text-gray-900 bg-gray-100 hocus:bg-gray-200 hocus:border-gray-400 flex items-center justify-center transition-all duration-300 focus:outline-none focus:shadow-outline text-sm mt-5 first:mt-0`}
  .iconContainer {
    ${tw`bg-white p-2 rounded-full`}
  }
  .icon {
    ${tw`w-4`}
  }
  .text {
    ${tw`ml-4`}
  }
`;

const DividerTextContainer = tw.div`my-12 border-b text-center relative`;
const DividerText = tw.div`leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform -translate-y-1/2 absolute inset-x-0 top-1/2 bg-transparent`;

const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const Textarea = styled(Input).attrs({ as: "textarea" })`
  ${tw`h-24`}
`;

const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-purple-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${(props) => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-lg bg-contain bg-center bg-no-repeat`}
`;

const PlansContainer = tw.div`flex justify-between flex-col lg:flex-row items-center lg:items-stretch relative`;
const Plan = styled.div`
  ${tw`w-full max-w-sm mt-16 mb-16 lg:mr-8 lg:last:mr-0 text-center px-8 rounded-lg shadow relative pt-2 text-gray-900 bg-white flex flex-col`}
  .planHighlight {
    ${tw`rounded-t-lg absolute top-0 inset-x-0 h-2`}
  }

  ${(props) =>
    props.featured &&
    css`
      background: rgb(100,21,255);
      background: linear-gradient(135deg, rgba(100,21,255,1) 0%, rgba(128,64,252,1) 100%);
background: rgb(85,60,154);
background: linear-gradient(135deg, rgba(85,60,154,1) 0%, rgba(128,90,213,1) 100%);
background: rgb(76,81,191);
background: linear-gradient(135deg, rgba(76,81,191,1) 0%, rgba(102,126,234,1) 100%);
      ${tw`bg-primary-500 text-gray-100`}
      .planHighlight {
        ${tw`hidden`}
      }
      .duration {
        ${tw`text-gray-200!`}
      }
      ${PlanFeatures} {
        ${tw`border-indigo-500`}
      }
      .feature:not(.mainFeature) {
        ${tw`text-gray-300!`}
      }
      ${BuyNowButton} {
        ${tw`bg-gray-100 text-primary-500 hocus:bg-gray-300 hocus:text-primary-800`}
    `}
`;

const PlanHeader = styled.div`
  ${tw`flex flex-col uppercase leading-relaxed py-8`}
  .name {
    ${tw`font-bold text-xl`}
  }
  .price {
    ${tw`font-bold text-4xl sm:text-5xl my-1`}
  }
  .duration {
    ${tw`text-gray-500 font-bold tracking-widest`}
  }
`;
const PlanFeatures = styled.div`
  ${tw`flex flex-col -mx-8 px-8 py-8 border-t-2 border-b-2 flex-1`}
  .feature {
    ${tw`mt-5 first:mt-0 font-medium`}
    &:not(.mainFeature) {
      ${tw`text-gray-600`}
    }
  }
  .mainFeature {
    ${tw`text-xl font-bold tracking-wide`}
  }
`;

const PlanAction = tw.div`px-4 sm:px-8 xl:px-16 py-8`;
const BuyNowButton = styled(PrimaryButtonBase)`
  ${tw`rounded-full uppercase tracking-wider py-4 w-full text-sm hover:shadow-xl transform hocus:translate-x-px hocus:-translate-y-px focus:shadow-outline border-none`}
`;

export default ({
  logoLinkUrl = "#",
  illustrationImageSrc = illustration,
  headingText = "Inscription",
  socialButtons = [
    {
      iconImageSrc: googleIconImageSrc,
      text: "Inscription With Google",
      url: "https://google.com",
    },
    {
      iconImageSrc: twitterIconImageSrc,
      text: "Inscription With Twitter",
      url: "https://twitter.com",
    },
  ],
  submitButtonText = "S'inscrir",
  SubmitButtonIcon = SignUpIcon,
  tosUrl = "#",
  privacyPolicyUrl = "#",
  signInUrl = "#",
}) => {
  const { mutate, isLoading } = useMutation((userData) => demandeInscription(userData), {
    async onSuccess(data) {
      console.log(data);

      if (data.result) {
        notify_success("valid");
        localStorage.setItem("token", data.token);
        localStorage.setItem("email", data.email);
        localStorage.setItem("name", data.name);
        localStorage.setItem("id", data.id);
        localStorage.setItem("role", data.role);
        localStorage.setItem("acheteur_id", data.acheteur_id);
        localStorage.setItem("entreprise_id", data.entreprise_id);
        localStorage.setItem("activated", data.activated);
        setStartPaiment(false);

        if (data.role === "admin") {
          navigate("/admin");
        } else if (data.role === "groupe") {
          navigate("/groupe");
        } else if (data.role === "entreprise") {
          navigate("/entreprise");
        } else if (data.role === "pub") {
          navigate("/pub");
        }
      } else {
        notify("error");
      }

      if (data.message !== "") {
        notify(data.message);
      }
    },
    onError(error) {
      notify("error");
      if (Array.isArray(error.response.data.error)) {
        error.response.data.error.forEach((el) => console.log(el.message));
      } else {
        console.log(error.response.data.message);
      }
    },
  });

  const [modePaiment, setModePaiment] = useState(1); // dahabia : 1 , virement : 2
  const [pictureUrl, setPictureUrl] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    console.log(selectedFile);
    if (selectedFile) {
      let url = URL.createObjectURL(selectedFile);
      if (url) {
        console.log(url);
        setPictureUrl(url);
      }
    }
  }, [selectedFile]);

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [Message, setMessage] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const signUp = () => {
    var user = {
      nom: username,
      email,
      message: Message,
      password,
      password2,
      abonnement_id: index,
      file: selectedFile,
    };
    console.log(user);

    var valid = true;

    if (!username || username === "") {
      notify("Enter your username please !");
      valid = false;
    } else if (
      !email ||
      email === "" ||
      !String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      notify("Enter your email please !");
      valid = false;
    } else if (!password || password === "") {
      notify("Enter your password please !");
      valid = false;
    } else if (!password2 || password2 === "") {
      notify("Confirm your password please !");
      valid = false;
    } else if (password2 !== password) {
      notify("Re-confirm your password please !");
      valid = false;
    }

    if (valid) {
      notify_success("valid form");
      setStartPaiment(true);
      // mutate(user) ;
      // navigate("/admin");
    }
  };

  const lancerPaiment = () => {
    if (parseInt(index) !== 10 && (!pictureUrl || pictureUrl === "")) {
      notify("Vous devez entrer une preuve de paimenet");
    } else {
      var user = {
        nom: username,
        email,
        message: Message,
        password,
        password2,
        abonnement_id: index,
        file: selectedFile,
      };
      mutate(user);
    }
  };

  const notify = (message) => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 3000,
      fontSize: "12px",
    });
  };

  const notify_success = (message) => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 3000,
    });
  };

  let navigate = useNavigate();
  const { index } = useParams();

  const [items, setItems] = useState(inscriptionAbonnementsList);
  const [plan, setPlan] = useState(null);
  const [startPaiment, setStartPaiment] = useState(false);
  const [planIndex, setPlanIndex] = useState(0);
  const { mutate: mutateLoading, isLoading: isLoadingList } = useMutation(() => getAbonnement(), {
    async onSuccess(data) {
      console.log("abdou");
      console.log(data);
      if (data && data.data) {
        setItems(data.data);
      } else {
      }
    },
    onError(error) {},
  });

  useEffect(() => {
    // mutateLoading() ;
    let token = localStorage.getItem("token");
    let role = localStorage.getItem("role");
    if (token != null && token != "" && role != null && role != "") {
      if (role === "admin") {
        navigate("/admin");
      } else if (role === "groupe") {
        navigate("/groupe");
      } else if (role === "entreprise") {
        navigate("/entreprise");
      } else if (role === "pub") {
        navigate("/pub");
      }
    }

    if (items && items.length && index) {
      let it = items.findIndex((el) => el.id === parseInt(index));
      console.log(index);
      console.log(it);
      console.log(items);
      if (it !== -1) {
        console.log(items[it]);
        setPlan(items[it]);
        setPlanIndex(it);
      }
    }
  }, []);

  useEffect(() => {
    if (items && items.length && index) {
      let it = items.findIndex((el) => el.id === parseInt(index));
      console.log(index);
      console.log(it);
      console.log(items);
      if (it !== -1) {
        console.log(items[it]);
        setPlan(items[it]);
        setPlanIndex(it);
      }
    }
  }, [items, index]);

  const highlightGradientsCss = [
    css`
      background: rgb(56, 178, 172);
      background: linear-gradient(115deg, rgba(56, 178, 172, 1) 0%, rgba(129, 230, 217, 1) 100%);
    `,
    css`
      background: rgb(56, 178, 172);
      background-image: linear-gradient(115deg, #6415ff, #7431ff, #8244ff, #8e56ff, #9a66ff);
    `,
    css`
      background: rgb(245, 101, 101);
      background: linear-gradient(115deg, rgba(245, 101, 101, 1) 0%, rgba(254, 178, 178, 1) 100%);
    `,
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    if (document.getElementById("header_smart") != null)
      document.getElementById("header_smart").classList.add("d-none");
    return () => {
      if (document.getElementById("header_smart") != null)
        document.getElementById("header_smart").classList.remove("d-none");
    };
  }, []);
  return (
    <AnimationRevealPage>
      <Container>
        <Content className="row flex-row-reverse  w-100 ml-0 ">
          <MainContainer className="col-12 col-lg-6">
            <LogoLink href={logoLinkUrl}>{/* <LogoImage src={logo} /> */}</LogoLink>
            <MainContent>
              <Heading>{headingText}</Heading>
              <FormContainer>
                <Form
                  onSubmit={() => {
                    signUp();
                  }}
                >
                  <Input
                    type="text"
                    placeholder={"Nom"}
                    style={{
                      border: "none",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      borderRadius: "20px",
                      overflow: "hidden",
                    }}
                    onChange={(e) => setUsername(e.currentTarget.value)}
                  />
                  <Input
                    type="email"
                    placeholder="Email"
                    style={{
                      border: "none",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      borderRadius: "20px",
                      overflow: "hidden",
                    }}
                    onChange={(e) => setEmail(e.currentTarget.value)}
                  />
                  {/* <Input type="text" placeholder="N° Tél" style={{border : "none" , paddingLeft : "10px" , paddingRight : "10px" , borderRadius : "20px" , overflow : "hidden"}}/> */}
                  <Input
                    type={showPassword ? "text" : "password"}
                    placeholder="Mot de passe"
                    style={{
                      border: "none",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      borderRadius: "20px",
                      overflow: "hidden",
                    }}
                    onChange={(e) => setPassword(e.currentTarget.value)}
                  />
                  <Input
                    type={showPassword ? "text" : "password"}
                    placeholder="Confirmation du mot de passe"
                    style={{
                      border: "none",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      borderRadius: "20px",
                      overflow: "hidden",
                    }}
                    onChange={(e) => setPassword2(e.currentTarget.value)}
                  />
                  <MDBox display="flex" alignItems="center" ml={-1}>
                    <Switch
                      checked={showPassword}
                      onChange={() => {
                        setShowPassword((value) => !value);
                      }}
                    />
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      onClick={() => {
                        setShowPassword((value) => !value);
                      }}
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;&nbsp;Afficher les mots de passe
                    </MDTypography>
                  </MDBox>
                  <Textarea
                    name="message"
                    placeholder="Message explicatif"
                    style={{
                      border: "none",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      borderRadius: "20px",
                      overflow: "hidden",
                    }}
                    onChange={(e) => setMessage(e.currentTarget.value)}
                  />

                  <SubmitButton
                    type="button"
                    style={{ border: "none" }}
                    onClick={() => {
                      if (parseInt(index) === 10) {
                        lancerPaiment();
                      } else {
                        signUp();
                      }
                    }}
                  >
                    <SubmitButtonIcon className="icon" style={{ border: "none" }} />
                    <span className="text">{submitButtonText}</span>
                  </SubmitButton>
                  <p tw="mt-6 text-xs text-gray-600 text-center">
                    J'accepte de respecter les instructions de Smart System & Solution{" "}
                    <a href={tosUrl}>Conditions d'utilisation</a> et sa{" "}
                    <a href={privacyPolicyUrl}>Politique de confidentialité</a>
                  </p>

                  <p tw="mt-8 text-sm text-gray-600 text-center">
                    Vous avez déjà un compte?{" "}
                    <a
                      onClick={() => {
                        navigate("/authentication/sign-in");
                      }}
                      className="text-primary"
                      style={{ cursor: "pointer" }}
                    >
                      S'identifier
                    </a>
                  </p>
                </Form>
              </FormContainer>
            </MainContent>
          </MainContainer>
          <IllustrationContainer className="col-12 col-lg-6 d-flex">
            {/* <IllustrationImage imageSrc={illustrationImageSrc} /> */}
            {parseInt(index) === 10 && (
              <Plan
                featured={false}
                className="col-sm-12 col-md-4 col-lg-3"
                style={{
                  background:
                    "linear-gradient(115deg, rgb(146 119 238) 10%, rgb(228 232 239) 100%)",
                }}
              >
                <div className="w-100 d-flex align-items-center justify-content-between">
                  <img
                    alt=""
                    src={publication_abonnement_image}
                    style={{ height: "150px", width: "auto" }}
                  />
                  <PlanHeader>
                    <span className="name" style={{ color: "white" }}>
                      {"ABONNEMENT PUBLICATION"}
                    </span>
                  </PlanHeader>
                </div>
                <PlanHeader className="py-0 " style={{ marginTop: "50px" }}>
                  <span className="price" style={{ color: "white", fontSize: "15px" }}>
                    AVEC ACCORD SUR LES MODALITES ACHAT ET CLOSES
                  </span>
                  <span
                    className="duration "
                    style={{
                      fontSize: "12px",
                      color: "black",
                      marginTop: "30px",
                      marginBottom: "30px",
                    }}
                  >
                    Via notre support, Vous vous benifisiez d'une autonomie totale sur votre
                    communication
                  </span>
                </PlanHeader>
                <PlanFeatures style={{ marginTop: "0" }} className="py-0 my-1">
                  <ul>
                    {["ACHAT PUBLICATION"].map((feature, index) => (
                      <li
                        key={index}
                        className="feature"
                        style={{ fontSize: "12px", textAlign: "left", color: "white" }}
                      >
                        {feature}
                      </li>
                    ))}
                  </ul>
                </PlanFeatures>
                <PlanAction>
                  <BuyNowButton
                    style={{
                      background:
                        "linear-gradient(115deg, rgb(146 119 238) 10%, rgb(228 232 239) 100%)",
                    }}
                    // css={!plan.featured && highlightGradientsCss[index]}
                    onClick={() => {
                      navigate("/abonnements");
                    }}
                  >
                    Changer
                  </BuyNowButton>
                </PlanAction>
              </Plan>
            )}
            {parseInt(index) != 10 && plan && (
              <Plan
                key={index}
                featured={plan.featured}
                className="col-sm-12 col-md-4 col-lg-3"
                style={{
                  background:
                    plan.id === 4
                      ? "linear-gradient(115deg, rgb(195 146 61) 0%, rgb(239 215 129) 100%)"
                      : "",
                }}
              >
                <div className="w-100 d-flex align-items-center justify-content-between">
                  <img alt="" src={plan.image} style={{ height: "150px", width: "auto" }} />
                  <PlanHeader>
                    <span className="name">{plan.name}</span>
                  </PlanHeader>
                </div>
                {!plan.featured && (
                  <div className="planHighlight" style={{ background: plan.linearColor }} />
                )}
                <PlanHeader className="py-0">
                  {/* <span className="price" style={{color : plan.id === 4 ? "white" : ""}}>{plan.price} DA</span> */}
                  <span className="duration" style={{ color: plan.id === 4 ? "white" : "" }}>
                    {plan.duration}
                  </span>
                </PlanHeader>
                <PlanFeatures style={{ marginTop: "0" }} className="py-0 my-1">
                  <span className="feature mainFeature">{plan.mainFeature}</span>
                  {plan.features.map((feature, index) => (
                    <li
                      key={index}
                      className="feature"
                      style={{
                        fontSize: "12px",
                        textAlign: "left",
                        color: plan.id === 4 ? "white" : "",
                      }}
                    >
                      {feature}
                    </li>
                  ))}
                  <span className="feature mainFeature">{plan.note}</span>
                </PlanFeatures>
                <PlanAction>
                  <BuyNowButton
                    style={{ background: plan.linearColor }}
                    // css={!plan.featured && highlightGradientsCss[index]}
                    onClick={() => {
                      navigate("/abonnements");
                    }}
                  >
                    Changer
                  </BuyNowButton>
                </PlanAction>
              </Plan>
            )}
          </IllustrationContainer>
        </Content>
        <Modal
          show={isLoadingList}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <MDBox
              mx={2}
              my={1}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              style={{ cursor: "pointer" }}
            >
              <MDTypography variant="h6" color="white">
                Loading ...
              </MDTypography>
            </MDBox>
          </Modal.Body>
        </Modal>
        <Modal
          // show={startPaiment}
          show={startPaiment}
          onHide={() => {
            setStartPaiment(false);
          }}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Paiement de l'abonnement</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <MDBox
              mx={2}
              my={1}
              py={3}
              px={2}
              variant="gradient"
              bgColor={modePaiment == 1 ? "info" : "light"}
              borderRadius="lg"
              coloredShadow={modePaiment == 1 ? "info" : "light"}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setModePaiment(1);
              }}
            >
              <div className="w-100 d-flex justify-content-around align-items-center">
                <div
                  className="rounded-circle  mx-2"
                  style={{
                    height: "20px",
                    width: "20px",
                    backgroundColor: modePaiment == 1 ? "#1f4394" : "white",
                  }}
                ></div>
                <div className="mx-2">
                  <img alt="" src={edahabiaImage} style={{ width: "50px", height: "auto" }} />
                </div>
                <div
                  className="mx-2"
                  style={{
                    flex: 1,
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: modePaiment == 1 ? "white" : "black",
                  }}
                >
                  Paiement avec EDAHABIA
                </div>
              </div>
            </MDBox>

            <MDBox
              mx={2}
              my={1}
              py={3}
              px={2}
              variant="gradient"
              bgColor={modePaiment === 2 ? "info" : "light"}
              borderRadius="lg"
              coloredShadow={modePaiment === 2 ? "info" : "light"}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setModePaiment(2);
              }}
            >
              <div className="w-100 d-flex justify-content-around align-items-center">
                <div
                  className="rounded-circle  mx-2"
                  style={{
                    height: "20px",
                    width: "20px",
                    backgroundColor: modePaiment == 2 ? "#1f4394" : "white",
                  }}
                ></div>
                <div className="mx-2">
                  <img
                    alt=""
                    src={modePaiment == 2 ? virementHoverImage : virementImage}
                    style={{ width: "50px", height: "auto" }}
                  />
                </div>
                <div
                  className="mx-2"
                  style={{
                    flex: 1,
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: modePaiment == 2 ? "white" : "black",
                  }}
                >
                  Faire un virement
                </div>
              </div>
            </MDBox>

            {modePaiment == 2 && (
              <div
                style={{
                  width: "80%",
                  margin: "20px auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  overflow: "hidden",
                }}
                onClick={handleButtonClick}
              >
                <input
                  accept="image/*"
                  ref={fileInputRef}
                  onClick={handleFileChange}
                  onChange={handleFileChange}
                  type="file"
                  style={{ display: "none" }}
                />
                <div>
                  <div className="d-flex justify-content-center align-items-center">
                    {pictureUrl && pictureUrl != "" && (
                      <img
                        style={{ height: "80px", width: "auto", margin: "auto" }}
                        src={pictureUrl}
                      />
                    )}
                  </div>
                  {pictureUrl && pictureUrl != "" && (
                    <MDTypography
                      style={{
                        fontSize: "13px",
                        width: "100%",
                        textAlign: "center",
                        marginTop: "5px",
                        fontWight: "bold",
                        color: "#3e98ef",
                      }}
                    >
                      Changer la photo
                    </MDTypography>
                  )}
                </div>

                {(!pictureUrl || pictureUrl === "") && (
                  <MDTypography style={{ fontSize: "13px" }}>
                    Ajouter une photo comme un preuve
                  </MDTypography>
                )}
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            {/* <Button onClick={handleCloseValidation}>Confirmer</Button> */}
            <MDButton
              color="info"
              onClick={() => {
                lancerPaiment();
              }}
            >
              Payer "{plan && plan.price ? " " + plan.price + " " : " -- "} DZD"
            </MDButton>
          </Modal.Footer>
        </Modal>
      </Container>
    </AnimationRevealPage>
  );
};
