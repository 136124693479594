import { authApi } from "./server";

export const register = async (userInfos) => {
  console.log("userInfos");
  const response = await authApi.post("register", userInfos);
  return response.data;
};

export const authfb = async (userInfos) => {
  const response = await authApi.post("auth/authfb", userInfos);
  return response.data;
};

export const signin = async (userInfos) => {
  console.log("userInfos");
  const response = await authApi.post("login", userInfos);
  return response.data;
};

export const OauthSignin = async (userInfos) => {
  console.log("userInfos");
  const response = await authApi.post("OauthLogin", userInfos);
  return response.data;
};

export const OauthInscription = async (userInfos) => {
  console.log("userInfos");
  const response = await authApi.post("OauthInscription", userInfos);
  return response.data;
};

export const getValidationTokenEmail = async (userInfos) => {
  console.log("userInfos");
  const response = await authApi.post("getTokenValidationEmail", userInfos);
  return response.data;
};

export const resendValidationToken = async (userInfos) => {
  console.log("userInfos");
  const response = await authApi.post("resendActivationCode", userInfos);
  return response.data;
};

export const sendResetPasswordToken = async (userInfos) => {
  console.log("userInfos");
  const response = await authApi.post("sendResetPasswordToken", userInfos);
  return response.data;
};

export const checkAndGetResetPasswordEmail = async (userInfos) => {
  console.log("userInfos");
  const response = await authApi.post("checkAndGetResetPasswordEmail", userInfos);
  return response.data;
};

export const resetPassword = async (userInfos) => {
  console.log("userInfos");
  const response = await authApi.post("resetPassword", userInfos);
  return response.data;
};

export const validateAccount = async (userInfos) => {
  console.log("userInfos");
  const response = await authApi.post("validateAccount", userInfos);
  return response.data;
};

export const verify = async (userInfos) => {
  const response = await authApi.post("auth/verify", userInfos);
  return response.data;
};

export const resendVerificationCOde = async (userInfos) => {
  const response = await authApi.post("auth/resend_verification_code", userInfos);
  return response.data;
};

export const resetPasswordRequest = async (userInfos) => {
  const response = await authApi.post("password-reset", userInfos);
  return response.data;
};

export const CheckresetPasswordToken = async (userInfos) => {
  const response = await authApi.get("password-reset/" + userInfos.email + "/" + userInfos.token);
  return response.data;
};

export const ResetPassword = async (userInfos) => {
  const response = await authApi.post("password-reset/" + userInfos.email + "/" + userInfos.token, {
    password: userInfos.password,
    password_confirmation: userInfos.password_confirmation,
  });
  return response.data;
};
