import { useEffect, useState } from "react";

// react-router-dom components
import { Link, useNavigate, useParams } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { signin } from "services/authService";
import { Box, Button, ButtonBase, Divider, LinearProgress, Typography } from "@mui/material";
import LogoIcon from "assets/images/logo_targets_zone_dark2.png";
import IllutrationLogin from "assets/images/login-illustration.png";
import { SocialIcon } from "react-social-icons";
import { ArrowBack } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { resetPassword } from "services/authService";
import { checkAndGetResetPasswordEmail } from "services/authService";
import { sendResetPasswordToken } from "services/authService";
import { Modal } from "react-bootstrap";
import AuthLangues from "./AuthLangues";

// Images

function Basic() {
  const theme = useTheme();
  const lang = useSelector((state) => state.customization.lang);
  const [email, setEmail] = useState("");
  const [tokenValid, setTokenValid] = useState(true);
  const [validated, setValidated] = useState(false);

  const [rememberMe, setRememberMe] = useState(false);
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");

  const navigate = useNavigate();
  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const notify = (message) => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 3000,
      fontSize: "12px",
    });
  };

  const notify_success = (message) => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 3000,
    });
  };

  useEffect(() => {
    let token = localStorage.getItem("token");
    let role = localStorage.getItem("role");
    if (token != null && token !== "") {
      if (role === "admin") {
        navigate("/admin");
      } else if (role === "groupe") {
        navigate("/groupe");
      } else if (role === "entreprise") {
        navigate("/entreprise");
      } else if (role === "acheteur") {
        navigate("/acheteur");
      }
    }
  }, []);

  function isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      window.navigator.userAgent
    );
  }

  return (
    <BasicLayout image={null}>
      <Card>
        <div className="w-100 mb-5">
          <Button
            startIcon={
              <ArrowBack
                style={lang === "ar" ? { transform: "rotate(180deg)" } : {}}
                className="mx-2"
              ></ArrowBack>
            }
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.button,
              transition: "all .2s ease-in-out",
              background: theme.palette.secondary.light,
              color: theme.palette.secondary.dark,
              "&:hover": {
                background: theme.palette.secondary.dark,
                color: theme.palette.secondary.light,
              },
              height: "100%",
              overflow: "hidden",
              borderRadius: "none",
            }}
            className="py-2 px-5"
            onClick={() => {
              navigate("/");
            }}
          >
            {lang === "fr" ? "Retour" : lang === "en" ? "Go Back" : "العودة"}
          </Button>
        </div>
        <div className="row w-100 m-0 row-sm-reverse">
          <div className="col-12 col-lg-6 d-sm-block d-lg-none">
            <div
              className="w-100 d-flex align-items-center justify-content-center"
              style={{
                height: "100%",
              }}
            >
              <div>
                <img
                  alt=""
                  src={LogoIcon}
                  style={{
                    width: "100%",
                    height: "auto",
                    maxWidth: "200px",
                    margin: "auto",
                    marginBottom: "40px",
                  }}
                />
                <img
                  alt=""
                  src={IllutrationLogin}
                  style={{ width: "100%", height: "auto", maxWidth: "650px" }}
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 d-flex align-items-center justify-content-around">
            <MDBox
              component="form"
              role="form"
              className="p-2"
              style={{ width: "95%", direction: lang === "ar" ? "rtl" : "ltr" }}
            >
              <div className="w-100 d-flex align-items-center justify-content-center">
                <div className="w-100" style={{ maxWidth: "500px" }}>
                  <AuthLangues></AuthLangues>
                  <MDBox
                    style={{
                      // background: "#1e293b",
                      borderRadius: "12px",
                      marginBottom: "50px",
                      textAlign: lang === "ar" ? "right" : "left",
                      marginTop: "50px",
                    }}
                  >
                    <MDTypography
                      variant="h2"
                      color="text"
                      style={{
                        textTransform: "uppercase",
                        // fontSize: isMobileDevice() ? "18px" : "",
                      }}
                    >
                      {lang === "fr"
                        ? "Compte bloqué"
                        : lang === "en"
                        ? "Bloqued Account"
                        : "حساب محضور"}
                    </MDTypography>
                  </MDBox>

                  <MDBox
                    style={{
                      marginBottom: "50px",
                      textAlign: lang === "ar" ? "right" : "left",
                    }}
                  >
                    <MDTypography
                      variant="h6"
                      color="danger"
                      style={{
                        textTransform: "uppercase",
                        // fontSize: isMobileDevice() ? "18px" : "",
                      }}
                    >
                      {lang === "fr"
                        ? "Votre Compte est bloqué par l'administrateur"
                        : lang === "en"
                        ? "Your Account is blocked by the administrator"
                        : "تم حظر حسابك من قبل المسؤول"}
                    </MDTypography>
                  </MDBox>

                  <MDBox mt={3} mb={5} textAlign="center">
                    <MDTypography
                      variant="button"
                      color="info"
                      fontWeight="medium"
                      textGradient
                      style={{ cursor: "pointer", textAlign: "center" }}
                      onClick={() => {
                        navigate("/contactUs");
                      }}
                    >
                      {lang === "fr" ? "Contactez nous" : lang === "en" ? "Contact us" : "إتصل بنا"}
                    </MDTypography>
                  </MDBox>
                </div>
              </div>
            </MDBox>
            <Divider orientation="vertical" className="d-none d-lg-block" />
          </div>
          <div className="col-12 col-lg-6 d-none d-lg-block">
            <div
              className="w-100 d-flex align-items-center justify-content-center mb-5"
              style={{
                height: "100%",
              }}
            >
              <div>
                <img
                  alt=""
                  src={LogoIcon}
                  style={{
                    width: "100%",
                    height: "auto",
                    maxWidth: "200px",
                    margin: "auto",
                    marginBottom: "40px",
                  }}
                />
                <img
                  alt=""
                  src={IllutrationLogin}
                  style={{ width: "100%", height: "auto", maxWidth: "650px" }}
                />
              </div>
            </div>
          </div>
          {/* {!isMobileDevice() ? (
          ) : (
            <></>
          )} */}
        </div>
        {/* <MDBox pt={4} pb={3} px={3}></MDBox> */}
      </Card>
    </BasicLayout>
  );
}

export default Basic;
