import React, { useState } from "react";
import { Box, Button, ButtonBase, LinearProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { demandeOption } from "services/visiteurService";
import { Modal } from "react-bootstrap";
import { useTheme } from "@mui/material/styles";
import { demandePersonnalisees } from "services/visiteurService";
export default () => {
  let navigate = useNavigate();
  const lang = useSelector((state) => state.customization.lang);
  const theme = useTheme();
  const [openAdd, setOpenAdd] = useState(false);

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [Message, setMessage] = useState("");
  const [Phone, setPhone] = useState("");
  const [WhatsApp, setWhatsApp] = useState("");
  const [dejaUser, setDejaUser] = useState(false);
  const handleOpenAdd = () => {
    setOpenAdd(true);
  };
  const handleCloseAdd = () => {
    setUsername("");
    setEmail("");
    setPhone("");
    setMessage("");
    setDejaUser(false);
    setOpenAdd(false);
  };

  const lancerPaiment = () => {
    var user = {
      nom: username,
      email,
      phone: Phone,
      message: Message,
      alreadyUser: dejaUser,
      whatsapp: WhatsApp,
      // file: selectedFile,
    };
    console.log(user);

    var valid = true;

    if (!username || username === "") {
      notify("Entrez votre Dénomination SVP !");
      valid = false;
    } else if (
      !email ||
      email === "" ||
      !String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      notify("Entrez votre adresse email SVP !");
      valid = false;
    } else if (!Phone || Phone === "") {
      notify("Veuillez entrer votre numéro de téléphone SVP !");
      valid = false;
    } else {
      notify_success("valid form");

      mutateAdd(user);
      // navigate("/admin");
    }
  };

  const notify = (message) => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 3000,
      fontSize: "12px",
    });
  };

  const notify_success = (message) => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 3000,
    });
  };

  const { mutate: mutateAdd, isLoading: isLoadingAdd } = useMutation(
    (userData) => demandePersonnalisees(userData),
    {
      async onSuccess(data) {
        console.log(data);

        if (data.success) {
          notify_success("Votre demande a été envoyée avec succès");
          handleCloseAdd();
        } else if (data.message !== "") {
          notify(data.message);
        } else {
          notify("error");
        }
      },
      onError(error) {
        notify("error");
        if (Array.isArray(error.response.data.error)) {
          error.response.data.error.forEach((el) => console.log(el.message));
        } else {
          console.log(error.response.data.message);
        }
      },
    }
  );
  return (
    <>
      <div className="flex flex-wrap items-center">
        <div
          className="w-full md:w-5/12 px-4 mr-auto ml-auto"
          style={lang === "ar" ? { direction: "rtl" } : {}}
        >
          <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
            <i className="fas fa-briefcase"></i>
          </div>
          <h3 className="text-3xl font-semibold mb-3">
            {lang === "fr"
              ? "Recherchez votre produit idéal"
              : lang === "en"
              ? "Find your ideal product"
              : "ابحث عن منتجك المثالي"}
          </h3>
          <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
            {lang === "fr"
              ? "Parcourez notre catalogue diversifié en utilisant notre fonction de recherche avancée."
              : lang === "en"
              ? "Browse our diverse catalog using our advanced search function."
              : "تصفح كتالوجنا المتنوع باستخدام وظيفة البحث المتقدم."}{" "}
            <br />
            {lang === "fr"
              ? "Tapez simplement le nom du produit recherché et laissez notre plateforme intelligente vous présenter une sélection soigneusement choisie qui répondra à vos besoins."
              : lang === "en"
              ? "Simply type in the name of the product you're looking for and let our intelligent platform present you with a carefully chosen selection to suit your needs."
              : "ما عليك سوى كتابة اسم المنتج الذي تبحث عنه ودع منصتنا الذكية تقدم لك مجموعة مختارة بعناية تلبي احتياجاتك."}
          </p>
          <div className="w-100 d-flex align-items-center justify-content-center">
            <Button
              variant="outlined"
              color="primary"
              style={{
                color: "#0286c9",
                borderColor: "#0286c9",
                fontSize: "14px",
              }}
              onClick={() => {
                navigate("/products");
              }}
            >
              {lang === "fr"
                ? "Commencez la recherche"
                : lang === "en"
                ? "Start search"
                : "ابدأ البحث"}
            </Button>
          </div>
          <br />
          <br />

          <h3 className="text-lg font-semibold mb-3">
            {lang === "fr"
              ? "Faites une demande personnalisée"
              : lang === "en"
              ? "Make a personalized request"
              : "تقديم طلب مخصص"}
          </h3>
          <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
            {lang === "fr"
              ? "Vous ne trouvez pas exactement ce que vous cherchez ? Pas de soucis ! "
              : lang === "en"
              ? "Can't find exactly what you're looking for? No problem! "
              : "لا يمكنك العثور على ما تبحث عنه بالضبط؟ لا مشكلة! "}

            <br></br>
            {lang === "fr"
              ? "Utilisez notre fonction de demande personnalisée pour spécifier vos besoins exacts. Nous travaillerons en étroite collaboration avec notre réseau de fournisseurs pour vous proposer des solutions sur mesure qui correspondent à vos critères."
              : lang === "en"
              ? "Use our custom request function to specify your exact requirements. We'll work closely with our network of suppliers to offer you customized solutions that meet your criteria."
              : "استخدم وظيفة الاستفسار المخصص لدينا لتحديد متطلباتك بدقة. وسنعمل عن كثب مع شبكة الموردين لدينا لنقدم لك حلولاً مصممة خصيصاً لتلبية معاييرك."}
          </p>
          <div className="w-100 d-flex align-items-center justify-content-center">
            <Button
              variant="outlined"
              color="primary"
              style={{
                color: "#0286c9",
                borderColor: "#0286c9",
                fontSize: "14px",
              }}
              className="mb-5"
              onClick={() => {
                handleOpenAdd();
              }}
            >
              {lang === "fr" ? "Faire une demande" : lang === "en" ? "Make a request" : "تقديم طلب"}
            </Button>
          </div>
        </div>

        <div className="w-full md:w-6/12 p-0 mr-auto ml-auto m-0">
          <img
            alt="..."
            className="max-w-full rounded-lg shadow-lg"
            src="https://images.unsplash.com/photo-1555212697-194d092e3b8f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80"
          />
        </div>
      </div>

      <Modal
        show={isLoadingAdd}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="myModalLoading"
      >
        <Modal.Body style={{ overflow: "hidden" }}>
          <Box sx={{ width: "100%" }}>
            <LinearProgress color="secondary" />
          </Box>
        </Modal.Body>
      </Modal>
      <Modal
        show={openAdd}
        onHide={handleCloseAdd}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ fontSize: "0.85em", textAlign: lang === "ar" ? "right" : "left" }}
          >
            {lang === "fr" ? "Faire une demande" : lang === "en" ? "Make a request" : "تقديم طلب"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ direction: lang === "ar" ? "rtl" : "" }}>
          <div style={{ width: "100%" }}>
            <div className="w-100 d-flex justify-content-center align-items-center">
              <div style={{ maxWidth: "500px" }}>
                <div className="flex-auto">
                  <div className="relative w-full mb-3 mt-8">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="full-name"
                    >
                      {lang === "fr" ? "Dénomination" : lang === "en" ? "Denomination" : "التسمية"}
                    </label>
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder={
                        lang === "fr"
                          ? "Votre dénomination"
                          : lang === "en"
                          ? "Your denomination"
                          : "التسمية خاصتك"
                      }
                      onChange={(e) => setUsername(e.currentTarget.value)}
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="email"
                    >
                      {lang === "fr" ? "Email" : lang === "en" ? "Email" : "البريد الإلكتروني"}
                    </label>
                    <input
                      type="email"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder={
                        lang === "fr"
                          ? "votre email"
                          : lang === "en"
                          ? "your email"
                          : "بريدك الإلكتروني"
                      }
                      onChange={(e) => setEmail(e.currentTarget.value)}
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="email"
                    >
                      {lang === "fr"
                        ? "Numéro Téléphone"
                        : lang === "en"
                        ? "Phone number"
                        : "رقم الهاتف"}
                    </label>
                    <input
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder={
                        lang === "fr"
                          ? "Votre numéro du téléphone"
                          : lang === "en"
                          ? "Your phone number"
                          : "رقم هاتفك"
                      }
                      onChange={(e) => setPhone(e.currentTarget.value)}
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="whatsapp"
                    >
                      {lang === "fr" ? "WhatsApp" : lang === "en" ? "WhatsApp" : "واتساب"}
                    </label>
                    <input
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder={
                        lang === "fr"
                          ? "Votre WhatsApp"
                          : lang === "en"
                          ? "Your WhatsApp"
                          : "واتساب"
                      }
                      onChange={(e) => setWhatsApp(e.currentTarget.value)}
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="message"
                    >
                      {lang === "fr" ? "Message" : lang === "en" ? "Message" : "رسالتك"}
                    </label>
                    <textarea
                      rows="4"
                      cols="80"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                      placeholder={
                        lang === "fr"
                          ? "votre message..."
                          : lang === "en"
                          ? "your message..."
                          : "رسالتك..."
                      }
                      onChange={(e) => setMessage(e.currentTarget.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ direction: lang === "ar" ? "rtl" : "" }}>
          <>
            <ButtonBase
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.button,
                transition: "all .2s ease-in-out",
                background: theme.palette.secondary.light,
                color: theme.palette.secondary.dark,
                "&:hover": {
                  background: theme.palette.secondary.dark,
                  color: theme.palette.secondary.light,
                },
                height: "100%",
                overflow: "hidden",
              }}
              className="py-2 px-5"
              onClick={handleCloseAdd}
            >
              {lang === "fr" ? "Annuler" : lang === "en" ? "Cancel" : "إلغاء"}
            </ButtonBase>

            <ButtonBase
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.button,
                transition: "all .2s ease-in-out",
                background: theme.palette.primary.dark,
                color: theme.palette.primary.light,
                "&:hover": {
                  background: theme.palette.primary.light,
                  color: theme.palette.primary.dark,
                },
                height: "100%",
                overflow: "hidden",
              }}
              className="py-2 px-5"
              onClick={lancerPaiment}
            >
              {lang === "fr" ? "Envoyer" : lang === "en" ? "Send" : "إرسال"}
            </ButtonBase>
          </>
        </Modal.Footer>
      </Modal>
    </>
  );
};
