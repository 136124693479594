import React from "react";
import { useSelector } from "react-redux";

export default function Footer() {
  const lang = useSelector((state) => state.customization.lang);
  return (
    <>
      <footer className="relative bg-blueGray-200 pt-8 pb-6">
        <div
          className="bottom-auto left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
          style={{ transform: "translateZ(0)", top: "1px" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-blueGray-200 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap text-center lg:text-left">
            <div className="w-full  px-4">
              <h4 className="text-3xl font-semibold">
                {lang === "fr"
                  ? "Restons en contact!"
                  : lang === "en"
                  ? "Stay in touch!"
                  : "ابق على تواصل معنا!"}
              </h4>
              <h5 className="text-lg mt-0 mb-2 text-blueGray-600">
                {lang === "fr"
                  ? "Retrouvez-nous sur l'une de ces plateformes, nous répondons sous 1 à 2 jours ouvrables."
                  : lang === "en"
                  ? "Find us on one of these platforms, and we'll get back to you within 1 to 2 working days."
                  : "ابحث عنا على إحدى هذه المنصات وسنرد عليك في غضون يوم إلى يومي عمل."}
              </h5>
              <div className="mt-6 lg:mb-0 mb-6">
                <button
                  className="bg-white text-lightBlue-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  type="button"
                >
                  <i className="fab fa-twitter"></i>
                </button>
                <button
                  className="bg-white text-lightBlue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  type="button"
                >
                  <i className="fab fa-facebook-square"></i>
                </button>
                <button
                  className="bg-white text-pink-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  type="button"
                >
                  <i className="fab fa-dribbble"></i>
                </button>
                <button
                  className="bg-white text-blueGray-800 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  type="button"
                >
                  <i className="fab fa-github"></i>
                </button>
              </div>
            </div>
          </div>
          <hr className="my-6 border-blueGray-300" />
          <div className="flex flex-wrap items-center md:justify-between justify-center">
            <div className="w-full md:w-4/12 px-4 mx-auto text-center">
              <div className="text-sm text-blueGray-500 font-semibold py-1">
                Copyright © {new Date().getFullYear()}{" "}
                <a href="#" className="text-blueGray-500 hover:text-blueGray-800">
                  TARGETS.ZONE
                </a>
                .
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
