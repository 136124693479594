import { useEffect, useRef, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";

// Data
import data from "layoutsEntreprises/PublireportageList/components/data";
import {
  Box,
  ButtonBase,
  Divider,
  Grid,
  LinearProgress,
  Pagination,
  Typography,
} from "@mui/material";
import Modal from "react-bootstrap/Modal";
import MDButton from "components/MDButton";
import tw from "twin.macro";
import styled from "styled-components";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import MDInput from "components/MDInput";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { BASE_URL_Image } from "services/server";
import { useMutation } from "@tanstack/react-query";
import { getPublireportages } from "services/adminService";
import dayjs from "dayjs";
import { updatePublireportage } from "services/adminService";
import { toast } from "react-toastify";
import { uploaderPublireportage } from "services/adminService";
import { useNavigate } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
import SearchSection from "components/search/SearchSection";
import { useSelector } from "react-redux";
const PrimaryButton = tw(
  PrimaryButtonBase
)`mt-auto sm:text-lg rounded-none w-full  py-3 sm:py-6 border-none`;

const CardPub = tw.div`h-full flex! flex-col sm:border max-w-sm  relative focus:outline-none bg-white m-0`;
const CardImage = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full h-56 sm:h-64 bg-cover bg-center `,
]);

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-2xl font-bold`;

function List() {
  const navigate = useNavigate();
  const lang = useSelector((state) => state.customization.lang);

  const dateToDateJS = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();

    const dateString = `${year}/${month}/${day}`;
    console.log("init date debut");
    console.log(dateString);
    return dayjs(dateString);
  };

  const [role, setRole] = useState("");

  const [publireportage, setPublireportage] = useState(null);
  const [selectedAddPub, setSelectedAddPub] = useState(null);

  const [pictureUrl, setPictureUrl] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);

  const [PubTitle, setPubTitle] = useState("");
  const [PubDescription, setPubDescription] = useState("");

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    console.log(selectedFile);
    if (selectedFile) {
      let url = URL.createObjectURL(selectedFile);
      if (url) {
        console.log(url);
        setPictureUrl(url);
      }
    }
  }, [selectedFile]);

  const [Autorises, setAutorises] = useState([]);
  const [Consommes, setConsommes] = useState([]);

  const [page, setPage] = useState({
    index: 1,
    limit: 5,
    totalItems: 0,
  });
  const [search, setSearch] = useState("");
  const [dateDebut, setDateDebut] = useState(dateToDateJS(new Date()));
  const [dateFin, setDateFin] = useState(null);

  const [paginationItems, setPaginationItems] = useState([]);

  const [items, setItems] = useState([]);

  const [open, setOpen] = useState(false);
  const handleOpen = (publireportage) => {
    console.log(publireportage);
    setPublireportage(publireportage);
    setOpen(true);
  };
  const handleClose = () => {
    setPublireportage(null);
    setOpen(false);
  };

  const [openArchivage, setOpenArchivage] = useState(false);
  const handleOpenArchivage = (publireportage) => {
    console.log(publireportage);
    setPublireportage(publireportage);
    setOpenArchivage(true);
  };
  const handleCloseArchivage = () => {
    setPublireportage(null);
    setOpenArchivage(false);
  };

  const { mutate, isLoading } = useMutation((userInfo) => getPublireportages(userInfo), {
    async onSuccess(data) {
      console.log(data);
      if (data && data.data) {
        setItems(
          data.data.map((el) => {
            return {
              id: el.id,
              status: el.status,
              archive: el.archive,
              company: {
                id: el.entreprise_id,
                groupe_id: el.groupe_id,
                image: BASE_URL_Image + el.entreprise_image,
                name: el.entreprise_name,
              },
              publireportage: {
                id: el.id,
                imageSrc: BASE_URL_Image + el.image,
                title: el.titre,
                description: el.contenu,
                start: el.date_debut,
                end: el.date_fin,
              },
            };
          })
        );
        setAutorises(data.autorises);
        setConsommes(data.consommes);
        setPage((oldValue) => {
          return { ...oldValue, totalItems: parseInt(data.total) };
        });
      } else {
      }
    },
    onError(error) {},
  });

  useEffect(() => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    setDateFin(dateToDateJS(tomorrow));

    let role_local = localStorage.getItem("role");
    if (![null, "null", ""].includes(role_local)) {
      setRole(role_local);
    }

    // mutate({ filter: search, limit: 5, offset: (page.index - 1) * 5 });
  }, []);

  const [openValidation, setOpenValidation] = useState(false);
  const handleOpenValidation = (publireportage) => {
    console.log(dateDebut);
    console.log(dateFin);
    setPublireportage(publireportage);
    setOpenValidation(true);
    setSelectedAddPub(null);
    console.log(publireportage);
  };
  const handleCloseValidation = () => {
    setDateDebut(dateToDateJS(new Date()));
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    setDateFin(dateToDateJS(tomorrow));
    setSelectedAddPub(null);
    setPublireportage(null);
    setOpenValidation(false);
    setPictureUrl("");
    setPubTitle("");
    setPubDescription("");
  };

  const [openAffichage, setOpenAffichage] = useState(false);
  const handleOpenAffichage = (publireportage) => {
    setPublireportage(publireportage);
    setOpenAffichage(true);
    console.log(publireportage);
  };
  const handleCloseAffichage = () => {
    setOpenAffichage(false);
  };

  const { columns, rows } = data({
    handleOpen,
    handleOpenValidation,
    handleOpenAffichage,
    handleOpenArchivage,
    items: items,
    lang,
  });

  const rechercher = () => {
    mutate({ filter: search, limit: 5, offset: (page.index - 1) * 5 });
  };

  const { mutate: updatePub, isLoading: isLoadingUpdatePub } = useMutation(
    (userInfos) => updatePublireportage(userInfos),
    {
      async onSuccess(data) {
        console.log(data);
        if (data) {
          mutate();
          // setOpenValidation((oldValue) => {return {id : null , value : false}});
        } else {
        }
      },
      onError(error) {},
    }
  );

  const annulerItem = () => {
    updatePub({ id: publireportage.id, status: -1 });
    handleClose();
  };

  const validerItem = () => {
    console.log(publireportage);
    updatePub({ id: publireportage.id, status: 1, pub_demande_id: selectedAddPub.id });
    handleCloseValidation();
  };

  const archiverItem = () => {
    updatePub({ id: publireportage.id, archive: 1 });
    handleCloseArchivage();
  };
  const insertNewPublireportage = () => {
    if (!selectedFile || !pictureUrl || pictureUrl === "") {
      notify("Vous devez inserer une image");
    } else if (PubTitle === "") {
      notify("Vous devez entrez un titre");
    } else if (PubDescription === "") {
      notify("Vous devez entrez une description");
    } else {
      mutateNewPublireportage();
    }
  };

  const { mutate: mutateNewPublireportage, isLoading: isLoadingNewPublireportage } = useMutation(
    () =>
      uploaderPublireportage({
        file: selectedFile,
        nom: PubTitle,
        description: PubDescription,
        demande_id: selectedAddPub.id,
      }),
    {
      async onSuccess(data) {
        console.log(data);
        if (data && data.data) {
          notify_success("Ajouter avec succès");
          handleCloseValidation();
          mutate({ filter: search, limit: 5, offset: (page.index - 1) * 5 });
        } else {
          notify("Une erreur a été produit");
        }
      },
      onError(error) {},
    }
  );

  const notify = (message) => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 3000,
      fontSize: "12px",
    });
  };

  const notify_success = (message) => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 3000,
    });
  };
  const theme = useTheme();

  return (
    <Card style={{ overflow: "hidden" }}>
      <div>
        <MDTypography
          variant="h6"
          gutterBottom
          className="px-3 py-2"
          style={{
            background: theme.palette.warning.dark,
            color: theme.palette.warning.light,
            width: "max-content",
          }}
        >
          {lang === "fr"
            ? "Liste des Publireportages"
            : lang === "en"
            ? "List of Advertorials"
            : "قائمة الريبورتاجات"}
        </MDTypography>
      </div>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox style={{ width: "100%" }}>
          <Grid container spacing={3}>
            {role === "admin" ? (
              <></>
            ) : (
              <>
                <Grid item xs={12} md={6}>
                  <MDBox display="flex" alignItems="center" lineHeight={0}>
                    <MDTypography variant="button" fontWeight="regular" color="info">
                      &nbsp;
                      <strong
                        style={{
                          color: theme.palette.warning.dark,
                        }}
                      >
                        {Autorises != null ? Autorises.length : 0}{" "}
                        {Autorises.length != null && Autorises.length > 1
                          ? "" +
                            (lang === "fr"
                              ? "Publireportages autorisés à ajouter"
                              : lang === "en"
                              ? "Advertorials allowed to add"
                              : "ريبورتاجات مسموحة للإضافة")
                          : "" +
                            (lang === "fr"
                              ? "Publireportage autorisé à ajouter"
                              : lang === "en"
                              ? "Advertorial allowed to add"
                              : "ريبورتاج للإضافة")}
                      </strong>
                    </MDTypography>
                  </MDBox>
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <MDBox>
                <Grid container spacing={3}>
                  <Grid item xs={12} xl={4}></Grid>
                  <Grid item xs={12} xl={8}>
                    <MDBox>
                      <Grid container spacing={1}>
                        <Grid item xs={role === "admin" ? 12 : 10}>
                          <SearchSection
                            label={
                              lang === "fr"
                                ? "Publireportage"
                                : lang === "en"
                                ? "Advertorials"
                                : "ريبورتاج"
                            }
                            value={search}
                            valueChange={(value) => setSearch(value)}
                            valueFilter={rechercher}
                          />
                        </Grid>
                        {role === "admin" ? (
                          <></>
                        ) : (
                          <>
                            <Grid
                              item
                              xs={2}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <ButtonBase
                                sx={{
                                  ...theme.typography.commonAvatar,
                                  ...theme.typography.button,
                                  transition: "all .2s ease-in-out",
                                  background: theme.palette.warning.light,
                                  color: theme.palette.warning.dark,
                                  "&:hover": {
                                    background: theme.palette.warning.dark,
                                    color: theme.palette.warning.light,
                                  },
                                  width: "100%",
                                  height: "100%",
                                  overflow: "hidden",
                                }}
                                onClick={handleOpenValidation}
                              >
                                {lang === "fr" ? "Ajouter" : lang === "en" ? "Add" : "أضف"}
                              </ButtonBase>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </MDBox>
                  </Grid>
                </Grid>

                <div className="w-100 py-2 px-3 mt-2 d-flex align-items-center justify-content-center">
                  <MDBox display="flex" alignItems="center" lineHeight={0}>
                    <MDTypography variant="button" fontWeight="regular" style={{ color: "grey" }}>
                      &nbsp;
                      <strong
                        style={{
                          color: theme.palette.warning.dark,
                        }}
                      >
                        {items != null ? items.length : 0}{" "}
                        {items != null && items.length > 1
                          ? "" +
                            (lang === "fr"
                              ? "Publireportages"
                              : lang === "en"
                              ? "Advertorials"
                              : "ريبورتاجات")
                          : "" +
                            (lang === "fr"
                              ? "Publireportage"
                              : lang === "en"
                              ? "Advertorial"
                              : "ريبورتاج")}
                      </strong>{" "}
                      {lang === "fr" ? "en total" : lang === "en" ? "in total" : "في المجمل"}
                    </MDTypography>
                  </MDBox>
                </div>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <MDBox>
        <DataTable
          table={{ columns, rows }}
          showTotalEntries={false}
          isSorted={false}
          noEndBorder
          entriesPerPage={false}
        />
        <div className="w-100 d-flex justify-content-center align-items-center">
          {/* <Pagination>{paginationItems}</Pagination> */}
          <Pagination
            color="light"
            count={
              parseInt(page.totalItems) % 12 == 0
                ? parseInt(parseInt(page.totalItems) / 12)
                : parseInt(parseInt(page.totalItems) / 12) + 1
            }
            page={page.index}
            onChange={(event, value) => {
              setPage((oldValue) => {
                return { ...oldValue, index: value };
              });
            }}
          />
        </div>
        {page.totalItems === 0 && !isLoading && (
          <div className="w-100 d-flex justify-content-center align-items-center">
            <MDTypography
              variant="h6"
              color="light"
              style={{
                color: theme.palette.warning.dark,
              }}
            >
              {lang === "fr"
                ? "Aucun Publireportage"
                : lang === "en"
                ? "No Advertorials"
                : "لا توجد ريبورتاجات"}
            </MDTypography>
          </div>
        )}
      </MDBox>
      <Modal
        show={open || openArchivage}
        onHide={open ? handleClose : handleCloseAffichage}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {open ? "Annulation d'un publireportage" : "Archivage d'un publireportage"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MDBox
            mx={2}
            my={1}
            py={3}
            px={2}
            variant="gradient"
            bgColor="error"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              {open
                ? "Est-ce-que vous voulez vraiment annuler ce publireportage ?"
                : "Est-ce-que vous voulez vraiment archiver ce publireportage ?"}
            </MDTypography>
          </MDBox>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button onClick={handleClose}>Confirmer</Button> */}
          <MDButton color="error" onClick={open ? annulerItem : archiverItem}>
            Confirmer
          </MDButton>
        </Modal.Footer>
      </Modal>

      <Modal
        show={openAffichage}
        onHide={handleCloseAffichage}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Publireportage</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CardPub style={{ maxWidth: "300px", height: "380px" }}>
              <CardImage imageSrc={publireportage != null ? publireportage.imageSrc : null} />
              <TextInfo style={{ maxHeight: "200px", padding: "10px" }}>
                <Title
                  style={{ fontSize: "15px", textAlign: "center", width: "100%" }}
                  className="mx-0"
                >
                  {publireportage != null ? publireportage.title : ""}
                </Title>
                {/* <TitleReviewContainer>
                  </TitleReviewContainer> */}
                {/* <Description  style={{fontSize: "11px" , margin : "0px"}}>{publireportage.description}</Description> */}
              </TextInfo>

              <PrimaryButton
                style={{
                  fontSize: "14px",
                  padding: "8px",
                  background: "white",
                  color: "#f8ac0b",
                  borderTop: "1px solid #ededed",
                }}
                onClick={() => {
                  if (publireportage != null) {
                    navigate("/entreprise/" + publireportage.entreprise_id);
                  }
                }}
              >
                Voir l'entreprise
              </PrimaryButton>
            </CardPub>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={isLoading || isLoadingUpdatePub || isLoadingNewPublireportage}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="myModalLoading"
      >
        <Modal.Body style={{ overflow: "hidden" }}>
          <Box sx={{ width: "100%" }}>
            <LinearProgress color="secondary" />
          </Box>
        </Modal.Body>
      </Modal>

      <Modal
        show={openValidation}
        onHide={handleCloseValidation}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedAddPub === null && (
            <div className="row w-100 mx-0">
              <div className="col-12">
                {Autorises && Autorises.length > 0 ? (
                  <MDBox
                    mx={2}
                    my={0}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="light"
                    borderRadius="lg"
                    coloredShadow="light"
                    className=" mt-5 d-flex align-items-center justify-content-center"
                  >
                    <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                      Selectionner un créneau
                    </MDTypography>
                  </MDBox>
                ) : (
                  <>
                    <>
                      <Typography align="center" variant="h5">
                        {lang === "fr"
                          ? "Aucun créneau autorisé !"
                          : lang === "en"
                          ? "No slots allowed!"
                          : "ليس لديك توقيت متاح!"}
                      </Typography>
                    </>
                  </>
                )}
              </div>
              {Autorises.map((el) => {
                return (
                  <div
                    className="row w-100 mx-0 mt-3 align-items-center"
                    onClick={() => {
                      setSelectedAddPub(el);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <div
                      className="col-6 d-flex align-items-center justify-content-center"
                      style={{ background: "#f0f0f0" }}
                    >
                      <MDTypography color="info" style={{ fontSize: "14px", fontWeight: "bold" }}>
                        {el.date_debut}
                      </MDTypography>
                    </div>
                    <div
                      className="col-6 d-flex align-items-center justify-content-center"
                      style={{ background: "#f0f0f0" }}
                    >
                      <MDTypography
                        color="primary"
                        style={{ fontSize: "14px", fontWeight: "bold" }}
                      >
                        {el.date_fin}
                      </MDTypography>
                    </div>

                    <div className="col-12">
                      <Divider></Divider>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {selectedAddPub != null && (
            <>
              <MDBox
                mx={2}
                my={1}
                py={3}
                px={2}
                variant="gradient"
                bgColor="light"
                borderRadius="lg"
                coloredShadow="light"
                className=" mt-5"
              >
                <div className="row w-100 ml-0">
                  {selectedAddPub && selectedAddPub.groupe_id != null && (
                    <>
                      <div className="col-6 d-flex justify-content-start align-items-center">
                        <MDTypography
                          color="primary"
                          style={{ fontSize: "14px", fontWeight: "bold" }}
                        >
                          Groupe
                        </MDTypography>
                      </div>
                      <div className="col-6 d-flex justify-content-end align-items-center">
                        <MDTypography
                          color="primary"
                          style={{ fontSize: "14px", fontWeight: "bold" }}
                        >
                          {selectedAddPub != null && selectedAddPub.groupe_name != null
                            ? selectedAddPub.groupe_name
                            : ""}
                        </MDTypography>
                      </div>
                      <Divider></Divider>
                    </>
                  )}
                  {selectedAddPub && selectedAddPub.entreprise_id != null && (
                    <>
                      <div className="col-6 d-flex justify-content-start align-items-center">
                        <MDTypography
                          color="primary"
                          style={{ fontSize: "14px", fontWeight: "bold" }}
                        >
                          Entreprise
                        </MDTypography>
                      </div>
                      <div className="col-6 d-flex justify-content-end align-items-center">
                        <MDTypography
                          color="primary"
                          style={{ fontSize: "14px", fontWeight: "bold" }}
                        >
                          {selectedAddPub != null && selectedAddPub.entreprise_name != null
                            ? selectedAddPub.entreprise_name
                            : ""}
                        </MDTypography>
                      </div>
                      <Divider></Divider>
                    </>
                  )}
                  <div className="col-6 d-flex justify-content-start align-items-center">
                    <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                      Nom
                    </MDTypography>
                  </div>
                  <div className="col-6 d-flex justify-content-end align-items-center">
                    <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                      {selectedAddPub != null && selectedAddPub.nom != null
                        ? selectedAddPub.nom
                        : ""}
                    </MDTypography>
                  </div>
                  <Divider></Divider>
                  <div className="col-6 d-flex justify-content-start align-items-center">
                    <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                      Email
                    </MDTypography>
                  </div>
                  <div className="col-6 d-flex justify-content-end align-items-center">
                    <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                      {selectedAddPub != null && selectedAddPub.email != null
                        ? selectedAddPub.email
                        : "--"}
                    </MDTypography>
                  </div>
                  <Divider></Divider>
                  <div className="col-6 d-flex justify-content-start align-items-center">
                    <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                      Phone
                    </MDTypography>
                  </div>
                  <div className="col-6 d-flex justify-content-end align-items-center">
                    <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                      {selectedAddPub != null && selectedAddPub.phone != null
                        ? selectedAddPub.phone
                        : "--"}
                    </MDTypography>
                  </div>
                  <Divider></Divider>
                  <div className="col-6 d-flex justify-content-start align-items-center">
                    <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                      Message
                    </MDTypography>
                  </div>
                  <div className="col-6 d-flex justify-content-end align-items-center">
                    <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                      {selectedAddPub != null && selectedAddPub.message != null
                        ? selectedAddPub.message
                        : ""}
                    </MDTypography>
                  </div>
                  <Divider></Divider>
                  <div className="col-6 d-flex justify-content-start align-items-center">
                    <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                      Date Demande
                    </MDTypography>
                  </div>
                  <div className="col-6 d-flex justify-content-end align-items-center">
                    <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                      {selectedAddPub != null && selectedAddPub.created_at != null
                        ? selectedAddPub.created_at
                        : "--"}
                    </MDTypography>
                  </div>
                  <Divider></Divider>
                  <div className="col-6 d-flex justify-content-start align-items-center">
                    <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                      Date Début
                    </MDTypography>
                  </div>
                  <div className="col-6 d-flex justify-content-end align-items-center">
                    <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                      {selectedAddPub != null && selectedAddPub.date_debut != null
                        ? selectedAddPub.date_debut
                        : "--"}
                    </MDTypography>
                  </div>
                  <Divider></Divider>
                  <div className="col-6 d-flex justify-content-start align-items-center">
                    <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                      Date Fin
                    </MDTypography>
                  </div>
                  <div className="col-6 d-flex justify-content-end align-items-center">
                    <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                      {selectedAddPub != null && selectedAddPub.date_fin != null
                        ? selectedAddPub.date_fin
                        : "--"}
                    </MDTypography>
                  </div>
                </div>
              </MDBox>

              <div className="row w-100 ml-0">
                <div className="col-12">
                  <Divider></Divider>
                </div>
                <div className="col-12">
                  <div className="w-100  d-flex justify-content-center">
                    <MDBox style={{ maxWidth: "300px", width: "100%" }}>
                      <div
                        style={{
                          width: "300px",
                          height: "300px",
                          border: pictureUrl ? "" : "3px dashed  #1A73E8",
                          margin: "20px auto",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                          overflow: "hidden",
                        }}
                        onClick={handleButtonClick}
                      >
                        <input
                          accept="image/*"
                          ref={fileInputRef}
                          onClick={handleFileChange}
                          onChange={handleFileChange}
                          type="file"
                          style={{ display: "none" }}
                        />

                        {pictureUrl && pictureUrl != "" && (
                          <img style={{ height: "100%", width: "auto" }} src={pictureUrl} />
                        )}
                        {(!pictureUrl || pictureUrl === "") && (
                          <Icon fontSize="large" color="info">
                            add_a_photo
                          </Icon>
                        )}
                      </div>
                    </MDBox>
                  </div>
                  <MDBox style={{ width: "100%" }}>
                    <MDInput
                      style={{ width: "100%", marginTop: "20px" }}
                      value={PubTitle}
                      label="Titre"
                      onChange={(e) => setPubTitle(e.currentTarget.value)}
                    />
                    <MDInput
                      style={{ width: "100%", marginTop: "20px" }}
                      value={PubDescription}
                      label="Description du publireportage"
                      multiline
                      rows={10}
                      onChange={(e) => setPubDescription(e.currentTarget.value)}
                    />
                  </MDBox>
                </div>
                {/* {modePaiment == 2 &&
                <div className="col-12 d-flex justify-content-center" >
                  <MDBox style={{ width : "100%" }}>
                  <div 
                    style={{width : "100%"  , height : "300px" , border : pictureUrl2 ? "" :  "3px dashed  #1A73E8" , margin : "20px auto" , display : "flex" , justifyContent : "center" ,alignItems : "center" , cursor : "pointer" , overflow : "hidden" }}
                    onClick={handleButtonClick2}
                    >
                    <input  accept="image/*" ref={fileInputRef2} onClick={handleFileChange2}  onChange={handleFileChange2} type="file" style={{display : "none"}}/>
                    
                    {pictureUrl2 && pictureUrl2 != "" && <img style={{height : "auto" , width : "100%"}} src={pictureUrl2} />}
                    {(!pictureUrl2 || pictureUrl2 === "") && 
                      <Icon fontSize="large" color="info">add_a_photo</Icon>
                    }
                  </div>
                      <MDInput style={{width : "100%" , marginTop : "20px"}} label="link" />
                </MDBox>

                </div>
              } */}
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {selectedAddPub != null && (
            <>
              <MDButton
                color="dark"
                onClick={() => {
                  setSelectedAddPub(null);
                  setPictureUrl("");
                  setPubTitle("");
                  setPubDescription("");
                }}
              >
                Retour
              </MDButton>
              <MDButton color="info" className="mx-2" onClick={insertNewPublireportage}>
                Confirmer
              </MDButton>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </Card>
  );
}

export default List;
