// @mui material components
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDProgress from "components/MDProgress";
import Icon from "@mui/material/Icon";
import MDBadge from "components/MDBadge";
import { Dropdown } from "react-bootstrap";
import { Badge, Button, Typography } from "@mui/material";
import { CheckCircleOutline, OpenInNew } from "@mui/icons-material";
import { IconClock } from "@tabler/icons";
import { BASE_URL_Image } from "services/server";

export default function data(props) {
  const avatars = (image) =>
    image.map(([image, name], index) => (
      <Tooltip title={name} placeholder="bottom">
        <div
          style={{
            width: "40px",
            height: "40px",
            overflow: "hidden",
            borderRadius: "50%",
            backgroundColor: "#ede7f6",
            border: "2px solid #cabae9",
            marginLeft: index > 0 ? "-20px" : "",
            cursor: "pointer",
          }}
          className="d-flex align-items-center justify-content-center"
        >
          <img
            key={name}
            src={image}
            alt="name"
            size="md"
            style={{ width: "40px", height: "auto" }}
          />
        </div>
      </Tooltip>
    ));

  const openModalArchive = (item) => {
    props.handleOpenArchivage(item);
  };

  const openModal = (message) => {
    props.handleOpen(message);
  };

  const openModalAffichage = (message) => {
    props.handleOpenAffichage(message);
  };

  const updateItemStatus = (item, status) => {
    props.handleUpdate({ id: item.id, status: status });
  };

  const updateItemArchive = (item, archive) => {
    props.handleUpdate({ id: item.id, archive: archive });
  };

  const Company = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="md" />
      <MDTypography variant="button" fontWeight="medium" ml={1} lineHeight={1}>
        {name}
      </MDTypography>
    </MDBox>
  );

  const getColumns = (role, type) => {
    let columns = [];
    columns.push(
      { Header: "", accessor: "images", align: "left" },
      { Header: "Date", accessor: "date", align: "center" }
    );

    if (type == "annonce") {
      columns.push({ Header: "Annonce", accessor: "annonce", align: "left" });
    }
    columns.push(
      { Header: "Sujet", accessor: "sujet", width: "20%", align: "left" },
      { Header: "Lu", accessor: "lu", align: "center" },
      { Header: "Message Validé", accessor: "luValidated", align: "center" },
      { Header: "Répondu", accessor: "repondu", align: "center" },
      { Header: "Réponse lue", accessor: "reponselu", align: "center" },
      { Header: "Réponse validée", accessor: "reponseValidated", align: "center" },
      { Header: "Action", accessor: "action", align: "center" }
    );

    return columns;
  };

  const getColumnsEn = (role, type) => {
    let columns = [];
    columns.push(
      { Header: "", accessor: "images", align: "left" },
      { Header: "Date", accessor: "date", align: "center" }
    );

    if (type == "annonce") {
      columns.push({ Header: "Announcement", accessor: "annonce", align: "left" });
    }
    columns.push(
      { Header: "Subject", accessor: "sujet", width: "40%", align: "left" },
      { Header: "readed", accessor: "lu", align: "center" },
      { Header: "Message validated", accessor: "luValidated", align: "center" },
      { Header: "Responded", accessor: "repondu", align: "center" },
      { Header: "Response readed", accessor: "reponselu", align: "center" },
      { Header: "Response validated", accessor: "reponseValidated", align: "center" },
      { Header: "Action", accessor: "action", align: "center" }
    );

    return columns;
  };
  const getColumnsAr = (role, type) => {
    let columns = [];
    columns.push(
      { Header: "", accessor: "images", align: "left" },
      { Header: "التاريخ", accessor: "date", align: "center" }
    );

    if (type == "annonce") {
      columns.push({ Header: "الإعلان", accessor: "annonce", align: "left" });
    }
    columns.push(
      { Header: "الموضوع", accessor: "sujet", width: "40%", align: "left" },
      { Header: "مقروءة؟", accessor: "lu", align: "center" },
      { Header: "رسالة مصادق عليها", accessor: "luValidated", align: "center" },
      { Header: "تم الرد؟", accessor: "repondu", align: "center" },
      { Header: "رد مقروء؟", accessor: "reponselu", align: "center" },
      { Header: "رد مصادق عليه", accessor: "reponseValidated", align: "center" },
      { Header: "المزيد", accessor: "action", align: "center" }
    );

    return columns;
  };

  const getAvatars = (el) => {
    let avatars_array = [];
    if (el.type == "produit") {
      avatars_array.push([BASE_URL_Image + el.concerned_image, el.concerned_name]);
    }
    if (props.userId != null && parseInt(props.userId) !== parseInt(el.sender_id)) {
      avatars_array.push([BASE_URL_Image + el.sender_image, el.sender_name]);
    }
    if (props.userId != null && parseInt(props.userId) !== parseInt(el.receiver_id)) {
      avatars_array.push([BASE_URL_Image + el.receiver_image, el.receiver_name]);
    }

    return avatars(avatars_array);
  };

  return {
    columns:
      props.lang === "fr"
        ? getColumns(props.role, props.type)
        : props.lang === "en"
        ? getColumnsEn(props.role, props.type)
        : getColumnsAr(props.role, props.type),

    rows: props.items.map((el) => {
      return {
        // companies: <Company image={el.company.image} name={el.company.name} />,
        images: (
          <MDBox display="flex" py={1} style={{ maxWidth: "100px" }}>
            {getAvatars(el)}
          </MDBox>
        ),
        annonce: (
          <Tooltip title={el.concerned_name} placeholder="bottom">
            <Typography noWrap variant="h6">
              {el.concerned_name}
            </Typography>
          </Tooltip>
        ),
        sujet: (
          <Tooltip title={el.subject} placeholder="bottom" style={{ maxWidth: "300px" }}>
            <Typography noWrap variant="h6">
              {el.subject}
            </Typography>
          </Tooltip>
        ),
        date: (
          <Typography
            noWrap
            variant="caption"
            style={{ color: props.theme.palette.grey[400], fontSize: "11px", fontWeight: "bold" }}
          >
            {props.lang === "fr" ? "le " : props.lang === "en" ? "the " : "في "} {el.send_date}
          </Typography>
        ),
        lu: (
          <MDBox ml={-1}>
            <Tooltip
              title={el.message_readed_by_admin === 1 ? el.message_admin_readed_date : ""}
              placeholder="bottom"
            >
              <MDBadge
                badgeContent={
                  el.message_readed_by_admin === 1
                    ? "" + (props.lang === "fr" ? "Lu" : props.lang === "en" ? "Readed" : "مقروء")
                    : "" + (props.lang === "fr" ? "Non" : props.lang === "en" ? "No" : "لا")
                }
                color={el.message_readed_by_admin === 1 ? "dark" : "info"}
                variant="gradient"
                size="sm"
              />
            </Tooltip>
          </MDBox>
        ),
        luValidated: (
          <MDBox ml={-1}>
            <Tooltip
              title={el.message_validated_by_admin === 1 ? el.message_validated_date : ""}
              placeholder="bottom"
            >
              <MDBadge
                badgeContent={
                  el.message_validated_by_admin === 1
                    ? "" + (props.lang === "fr" ? "Oui" : props.lang === "en" ? "Yes" : "نعم")
                    : "" + (props.lang === "fr" ? "Non" : props.lang === "en" ? "No" : "لا")
                }
                color={el.message_validated_by_admin === 1 ? "success" : "info"}
                variant="gradient"
                size="sm"
              />
            </Tooltip>
          </MDBox>
        ),
        repondu: (
          <MDBox ml={-1}>
            <Tooltip title={el.answered === 1 ? el.response_date : ""} placeholder="bottom">
              <Badge
                className="px-3 py-1 d-flex align-items-center"
                sx={{
                  transition: "all .2s ease-in-out",
                  background: el.answered
                    ? props.theme.palette.success.dark
                    : props.theme.palette.warning.dark,
                  color: "white !important",
                  width: "max-content",
                  overflow: "hidden",
                  borderRadius: "8px",
                }}
              >
                {el.answered ? (
                  <CheckCircleOutline
                    style={{ height: "16px" }}
                    className="mr-2"
                  ></CheckCircleOutline>
                ) : (
                  <IconClock style={{ height: "16px" }} className="mr-2"></IconClock>
                )}
                <Typography noWrap style={{ fontWeight: "bold", fontSize: "10px" }}>
                  {el.answered
                    ? "" +
                      (props.lang === "fr"
                        ? "Répondu"
                        : props.lang === "en"
                        ? "Responsed"
                        : "تم الرد")
                    : "" + (props.lang === "fr" ? "Non" : props.lang === "en" ? "No" : "لا")}
                </Typography>
              </Badge>
            </Tooltip>
          </MDBox>
        ),
        reponselu: (
          <MDBox ml={-1}>
            <Tooltip
              title={el.response_readed_by_admin === 1 ? el.response_admin_readed_date : ""}
              placeholder="bottom"
            >
              <MDBadge
                badgeContent={
                  el.response_readed_by_admin === 1
                    ? "" + (props.lang === "fr" ? "Lue" : props.lang === "en" ? "Readed" : "مقروء")
                    : "" + (props.lang === "fr" ? "Non" : props.lang === "en" ? "No" : "لا")
                }
                color={el.response_readed_by_admin === 1 ? "dark" : "info"}
                variant="gradient"
                size="sm"
              />
            </Tooltip>
          </MDBox>
        ),

        reponseValidated: (
          <MDBox ml={-1}>
            <Tooltip
              title={el.response_validated_by_admin === 1 ? el.admin_response_validate_date : ""}
              placeholder="bottom"
            >
              <MDBadge
                badgeContent={
                  el.response_validated_by_admin === 1
                    ? "" + (props.lang === "fr" ? "Oui" : props.lang === "en" ? "Yes" : "نعم")
                    : "" + (props.lang === "fr" ? "Non" : props.lang === "en" ? "No" : "لا")
                }
                color={el.response_validated_by_admin === 1 ? "success" : "info"}
                variant="gradient"
                size="sm"
              />
            </Tooltip>
          </MDBox>
        ),
        action: (
          <>
            <Button
              startIcon={<OpenInNew fontSize="medium"></OpenInNew>}
              className="mx-2 px-3"
              sx={{
                ...props.theme.typography.commonAvatar,
                ...props.theme.typography.button,
                transition: "all .2s ease-in-out",
                background: "white ",
                color: props.theme.palette.info.dark,
                "&:hover": {
                  background: props.theme.palette.info.dark,
                  color: "white !important",
                },
                width: "max-content",
                overflow: "hidden",
                fontSize: "11px",
              }}
              onClick={() => {
                props.handleOpen(el);
              }}
            ></Button>
          </>
        ),
      };
    }),
  };
}
