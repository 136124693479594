import { useEffect, useRef, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";

// Data
import data from "layoutsAdmin/ProductMessagesList/components/data";
import { Box, ButtonBase, Grid, LinearProgress, Tab, Tabs } from "@mui/material";
import Modal from "react-bootstrap/Modal";
// import { Pagination } from "react-bootstrap";
import Pagination from "@mui/material/Pagination";
import { BASE_URL_Image } from "services/server";
import { useMutation } from "@tanstack/react-query";
import { getPublireportages } from "services/adminService";
import dayjs from "dayjs";
import { updatePublireportage } from "services/adminService";
import { toast } from "react-toastify";
import { uploaderPublireportage } from "services/adminService";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import SearchSection from "./SearchSection";
import SelectSection from "./SelectSection";
import ServiceMessageDetails from "OurPages/OurComponents/Home/Annonce/components/messages/MessageDatails";
import { getUserMessages } from "services/messagesService";
import { makeUserMessageAsReaded } from "services/messagesService";
import { makeResponseToMessage } from "services/messagesService";
import { makeUserResponseAsReaded } from "services/messagesService";
import { getAdminMessages } from "services/messagesService";
import { makeAdminResponseAsReaded } from "services/messagesService";
import { makeAdminMessageAsReaded } from "services/messagesService";
import { makeAdminValidateMessage } from "services/messagesService";
import { makeAdminValidateResponse } from "services/messagesService";
import { useSelector } from "react-redux";

function List({ role, type }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const lang = useSelector((state) => state.customization.lang);

  const [userId, setUserId] = useState("");
  const [message, setMessage] = useState(null);

  const [page, setPage] = useState({
    index: 1,
    limit: 5,
    totalItems: 0,
    totalItemsNotReaded: 0,
    totalItemsNotAnswered: 0,
    totalItemsAnsweredNotReaded: 0,

    totalItemsNotAdminReaded: 0,
    totalItemsAnsweredNotAdminReaded: 0,
    totalItemsMessageNotValidated: 0,
    totalItemsResponseNotValidated: 0,
  });
  const [search, setSearch] = useState("");
  const [senderName, setSenderName] = useState("");
  const [receiverName, setReceiverName] = useState("");
  const [produit, setProduit] = useState("");
  const [repondu, setRepondu] = useState(0);
  const [lu, setLu] = useState(0);
  const [reponseLu, setReponseLu] = useState(0);
  const [adminLu, setAdminLu] = useState(0);
  const [adminReponseLu, setAdminReponseLu] = useState(0);
  const [messagevalide, setMessageValide] = useState(0);
  const [reponsevalide, setReponseValide] = useState(0);

  const [items, setItems] = useState([]);

  const { mutate, isLoading } = useMutation((filters) => getAdminMessages(filters), {
    async onSuccess(data) {
      console.log(data);
      if (data && data.data && Array.isArray(data.data)) {
        setItems(data.data);
        setPage((oldValue) => {
          return {
            ...oldValue,
            totalItems: parseInt(data.total),
            totalItemsNotReaded: parseInt(data.totalNotReaded),
            totalItemsNotAnswered: parseInt(data.totalNotAnswered),
            totalItemsAnsweredNotReaded: parseInt(data.totalAnsweredNotReaded),
            totalItemsNotAdminReaded: parseInt(data.totalNotAdminReaded),
            totalItemsAnsweredNotAdminReaded: parseInt(data.totalAnsweredNotAdminReaded),
            totalItemsMessageNotValidated: parseInt(data.totalMessageNotValidated),
            totalItemsResponseNotValidated: parseInt(data.totalResponseNotValidated),
          };
        });
      } else {
      }
    },
    onError(error) {},
  });

  const { mutate: mutateMakeAdminMessageAsReaded, isLoadingMakeAdminMessageAsReaded } = useMutation(
    (filters) => makeAdminMessageAsReaded(filters),
    {
      async onSuccess(data) {
        console.log(data);
        if (data && data.success) {
          setItems([
            ...items.map((m) => {
              if (m.id === data.data.id) {
                return { ...data.data };
              }
              return m;
            }),
          ]);
          setMessage({ ...data.data });
          setPage((oldValue) => {
            return {
              ...oldValue,
              totalItemsNotAdminReaded: parseInt(oldValue.totalItemsNotAdminReaded) - 1,
            };
          });
        } else {
        }
      },
      onError(error) {},
    }
  );

  const { mutate: mutateMakeAdminResponseAsReaded, isLoadingMakeAdminResponseAsReaded } =
    useMutation((filters) => makeAdminResponseAsReaded(filters), {
      async onSuccess(data) {
        console.log(data);
        if (data && data.success) {
          setItems([
            ...items.map((m) => {
              if (m.id === data.data.id) {
                return { ...data.data };
              }
              return m;
            }),
          ]);
          setMessage({ ...data.data });
          setPage((oldValue) => {
            return {
              ...oldValue,
              totalAnsweredNotAdminReaded: parseInt(oldValue.totalAnsweredNotAdminReaded) - 1,
            };
          });
        } else {
        }
      },
      onError(error) {},
    });

  const { mutate: mutateMakeResponseToMessage, isLoadingMakeResponseToMessage } = useMutation(
    (filters) => makeResponseToMessage(filters),
    {
      async onSuccess(data) {
        console.log(data);
        if (data && data.success) {
          setItems([
            ...items.map((m) => {
              if (m.id === data.data.id) {
                return { ...data.data };
              }
              return m;
            }),
          ]);
          setMessage({ ...data.data });
          setPage((oldValue) => {
            return {
              ...oldValue,
              totalItemsNotAnswered: parseInt(oldValue.totalItemsNotAnswered) - 1,
            };
          });
        } else {
        }
      },
      onError(error) {},
    }
  );

  const { mutate: mutateAdminValidateMessage, isLoadingAdminValidateMessage } = useMutation(
    (filters) => makeAdminValidateMessage(filters),
    {
      async onSuccess(data) {
        console.log(data);
        if (data && data.success) {
          setItems([
            ...items.map((m) => {
              if (m.id === data.data.id) {
                return { ...data.data };
              }
              return m;
            }),
          ]);
          setMessage({ ...data.data });
          setPage((oldValue) => {
            return {
              ...oldValue,
              totalItemsMessageNotValidated: parseInt(oldValue.totalItemsMessageNotValidated) - 1,
            };
          });
        } else {
        }
      },
      onError(error) {},
    }
  );

  const { mutate: mutateAdminValidateResponse, isLoadingAdminValidateResponse } = useMutation(
    (filters) => makeAdminValidateResponse(filters),
    {
      async onSuccess(data) {
        console.log(data);
        if (data && data.success) {
          setItems([
            ...items.map((m) => {
              if (m.id === data.data.id) {
                return { ...data.data };
              }
              return m;
            }),
          ]);
          setMessage({ ...data.data });
          setPage((oldValue) => {
            return {
              ...oldValue,
              totalItemsResponseNotValidated: parseInt(oldValue.totalItemsResponseNotValidated) - 1,
            };
          });
        } else {
        }
      },
      onError(error) {},
    }
  );

  useEffect(() => {
    let id = localStorage.getItem("id");
    setUserId(id);
  }, []);

  useEffect(() => {
    setItems([]);
    setPage({
      index: 1,
      limit: 5,
      totalItems: 0,
      totalItemsNotReaded: 0,
      totalItemsNotAnswered: 0,
      totalItemsAnsweredNotReaded: 0,

      totalItemsNotAdminReaded: 0,
      totalItemsAnsweredNotAdminReaded: 0,
      totalItemsMessageNotValidated: 0,
      totalItemsResponseNotValidated: 0,
    });
    setSearch("");
    setProduit("");
    setSenderName("");
    setReceiverName("");
    setLu(0);
    setRepondu(0);
    setAdminLu(0);
    setAdminReponseLu(0);
    setMessageValide(0);
    setReponseValide(0);
    setOpen(false);
    setMessage(null);
    if (userId != null && userId != "") {
      loadMessages();
    }
  }, [type, userId]);

  const [open, setOpen] = useState(false);
  const handleOpen = (message) => {
    setMessage({ ...message });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setMessage(null);
  };

  useEffect(() => {
    if (open && message != null) {
      readAdminMessage();
      readAdminResponse();
    }
  }, [open]);

  const loadMessages = () => {
    let filters = {
      type: type,
      filter: search,
      concerne_filter: produit,
      receiver_name: receiverName,
      sender_name: senderName,
      limit: 5,
      offset: (page.index - 1) * 5,
    };

    if (lu !== 0) {
      filters = { ...filters, readed: lu === 1 ? "1" : "0" };
    }

    if (repondu !== 0) {
      console.log("repondu = " + repondu);
      filters = { ...filters, answered: repondu === 1 ? "1" : "0" };
    }

    if (reponseLu !== 0) {
      console.log("reponseLu = " + reponseLu);
      filters = { ...filters, response_readed: reponseLu === 1 ? "1" : "0" };
    }

    if (adminLu !== 0) {
      console.log("adminLu = " + adminLu);
      filters = { ...filters, message_readed_by_admin: adminLu === 1 ? "1" : "0" };
    }

    if (adminReponseLu !== 0) {
      console.log("adminReponseLu = " + adminReponseLu);
      filters = { ...filters, response_readed_by_admin: adminReponseLu === 1 ? "1" : "0" };
    }

    if (messagevalide !== 0) {
      console.log("messagevalide = " + messagevalide);
      filters = { ...filters, message_validated_by_admin: messagevalide === 1 ? "1" : "0" };
    }

    if (reponsevalide !== 0) {
      console.log("reponsevalide = " + reponsevalide);
      filters = { ...filters, response_validated_by_admin: reponsevalide === 1 ? "1" : "0" };
    }

    mutate(filters);
  };

  const readAdminMessage = () => {
    if (message != null && parseInt(message.message_readed_by_admin) !== 1) {
      mutateMakeAdminMessageAsReaded({ message_id: message.id });
    }
  };
  const readAdminResponse = () => {
    if (
      message != null &&
      parseInt(message.answered) === 1 &&
      parseInt(message.response_readed_by_admin) !== 1
    ) {
      mutateMakeAdminResponseAsReaded({ message_id: message.id });
    }
  };
  const responseToUserMessage = (response) => {
    if (
      message != null &&
      parseInt(message.receiver_id) === parseInt(userId) &&
      parseInt(message.answered) !== 1 &&
      response !== ""
    ) {
      mutateMakeResponseToMessage({ message_id: message.id, response: response });
    } else {
      notify(
        lang === "fr"
          ? "erreur lors de repondre !"
          : lang === "en"
          ? "error while responding!"
          : "خطأ أثناء الرد!"
      );
    }
  };

  const adminValidateMessage = (modifiedSubject, modifiedMessage) => {
    if (message != null && parseInt(message.message_validated_by_admin) === 0) {
      let filters = { message_id: message.id };
      if (modifiedSubject && modifiedSubject != "") {
        filters = { ...filters, subject_admin_modification: modifiedSubject };
      }
      if (modifiedMessage && modifiedMessage != "") {
        filters = { ...filters, message_admin_modification: modifiedMessage };
      }
      mutateAdminValidateMessage(filters);
    } else {
      notify(
        lang === "fr"
          ? "erreur lors de repondre !"
          : lang === "en"
          ? "error while responding!"
          : "خطأ أثناء الرد!"
      );
    }
  };

  const adminValidateResponse = (modifiedResponse) => {
    if (message != null && parseInt(message.response_validated_by_admin) === 0) {
      let filters = { message_id: message.id };
      if (modifiedResponse && modifiedResponse != "") {
        filters = { ...filters, response_admin_modification: modifiedResponse };
      }
      mutateAdminValidateResponse(filters);
    } else {
      notify(
        lang === "fr"
          ? "erreur lors de repondre !"
          : lang === "en"
          ? "error while responding!"
          : "خطأ أثناء الرد!"
      );
    }
  };

  const rechercher = () => {
    loadMessages();
  };

  const notify = (message) => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 3000,
      fontSize: "12px",
    });
  };

  const notify_success = (message) => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 3000,
    });
  };

  const dateToDateJS = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();

    const dateString = `${year}/${month}/${day}`;
    console.log("init date debut");
    console.log(dateString);
    return dayjs(dateString);
  };
  function getCurrentDate() {
    const now = new Date();

    // Get date components
    const day = String(now.getDate()).padStart(2, "0");
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Month is zero-based
    const year = now.getFullYear();

    // Get time components
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    // Return the formatted date
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  }

  const { columns, rows } = data({
    handleOpen,
    items: items,
    theme: theme,
    role,
    type,
    userId,
    lang,
  });

  return (
    <Card style={{ overflow: "hidden" }}>
      <div>
        <MDTypography
          variant="h6"
          gutterBottom
          className="px-3 py-2"
          style={{
            background:
              role === "acheteur" ? theme.palette.secondary.dark : theme.palette.warning.dark,
            color:
              role === "acheteur" ? theme.palette.secondary.light : theme.palette.warning.light,
            width: "max-content",
          }}
        >
          {type === "produit"
            ? "" +
              (lang === "fr"
                ? "Liste des Messages concernant les produits"
                : lang === "en"
                ? "List of Messages regarding products"
                : "قائمة الرسائل المتعلقة بالمنتجات")
            : "" +
              (lang === "fr"
                ? "Liste des Messages concernant les annonces"
                : lang === "en"
                ? "List of Messages regarding announcements"
                : "قائمة الرسائل المتعلقة بالإعلانات")}
        </MDTypography>
      </div>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox style={{ width: "100%" }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} className="d-flex justify-content-end">
              <MDBox>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;
                  <strong>
                    {page.totalItems != null ? page.totalItems : 0}{" "}
                    {page.totalItems != null && page.totalItems > 1
                      ? "" + (lang === "fr" ? "Messages" : lang === "en" ? "Messages" : "رسائل")
                      : "" + (lang === "fr" ? "Message" : lang === "en" ? "Message" : "رسالة")}
                  </strong>{" "}
                  {lang === "fr" ? "en total" : lang === "en" ? "in total" : "في المجمل"}
                  <br></br>
                  <>
                    <strong>
                      {page.totalItemsNotAdminReaded != null ? page.totalItemsNotAdminReaded : 0}{" "}
                      <small>
                        {page.totalItemsNotAdminReaded != null && page.totalItemsNotAdminReaded > 1
                          ? "" +
                            (lang === "fr"
                              ? "messages non lus"
                              : lang === "en"
                              ? "unread messages"
                              : "رسائل غير مقروءة")
                          : "" +
                            (lang === "fr"
                              ? "message non lu"
                              : lang === "en"
                              ? "unread message"
                              : "رسالة غير مقروءة")}
                      </small>
                    </strong>
                  </>
                  <br></br>
                  <>
                    <strong>
                      {page.totalItemsAnsweredNotAdminReaded != null
                        ? page.totalItemsAnsweredNotAdminReaded
                        : 0}{" "}
                      <small>
                        {page.totalItemsAnsweredNotAdminReaded != null &&
                        page.totalItemsAnsweredNotAdminReaded > 1
                          ? "" +
                            (lang === "fr"
                              ? "réponses non lus"
                              : lang === "en"
                              ? "unread replies"
                              : "الردود غير المقروءة")
                          : "" +
                            (lang === "fr"
                              ? "réponse non lu"
                              : lang === "en"
                              ? "unread reply"
                              : "رد غير مقروء")}
                      </small>
                    </strong>
                  </>
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <MDBox display="flex" alignItems="center" lineHeight={0}>
                <MDTypography variant="button" fontWeight="regular" color="info">
                  &nbsp;
                  <strong>
                    <>
                      {page.totalItemsNotAnswered != null ? page.totalItemsNotAnswered : 0}{" "}
                      <small>
                        {page.totalItemsNotAnswered != null && page.totalItemsNotAnswered > 1
                          ? "" +
                            (lang === "fr"
                              ? "messages non repondus"
                              : lang === "en"
                              ? "unanswered messages"
                              : "رسائل لم يتم الرد عليها")
                          : "" +
                            (lang === "fr"
                              ? "message non repondu"
                              : lang === "en"
                              ? "unanswered message"
                              : "رسالة لم يتم الرد عليها")}
                      </small>
                    </>
                    <br></br>
                    <>
                      {page.totalItemsMessageNotValidated != null
                        ? page.totalItemsMessageNotValidated
                        : 0}{" "}
                      <small>
                        {page.totalItemsMessageNotValidated != null &&
                        page.totalItemsMessageNotValidated > 1
                          ? "" +
                            (lang === "fr"
                              ? "messages non validés"
                              : lang === "en"
                              ? "unvalidated messages"
                              : "رسائل لم يتم المصادقة عليها")
                          : "" +
                            (lang === "fr"
                              ? "message non validé"
                              : lang === "en"
                              ? "unvalidated message"
                              : "رسالة لم يتم المصادقة عليها")}
                      </small>
                    </>
                    <br></br>
                    <>
                      {page.totalItemsResponseNotValidated != null
                        ? page.totalItemsResponseNotValidated
                        : 0}{" "}
                      <small>
                        {page.totalItemsResponseNotValidated != null &&
                        page.totalItemsResponseNotValidated > 1
                          ? "" +
                            (lang === "fr"
                              ? "réponses non validées"
                              : lang === "en"
                              ? "unvalidated responses"
                              : "ردود لم يتم المصادقة عليها")
                          : "" +
                            (lang === "fr"
                              ? "réponse non validée"
                              : lang === "en"
                              ? "unvalidated response"
                              : "رد لم يتم المصادقة عليه")}
                      </small>
                    </>
                  </strong>
                </MDTypography>
              </MDBox>
            </Grid>

            <Grid item xs={3} md={2}>
              <SelectSection
                label={
                  lang === "fr"
                    ? "Lu par l'admin"
                    : lang === "en"
                    ? "Readed by admin"
                    : "مقروءة من طرف المشرف"
                }
                value={adminLu}
                valueChange={(value) => setAdminLu(parseInt(value))}
                valueFilter={rechercher}
                status={[
                  {
                    value: 0,
                    label: lang === "fr" ? "Tous" : lang === "en" ? "All" : "كل الحالات",
                  },
                  { value: 1, label: lang === "fr" ? "Lu" : lang === "en" ? "Readed" : "مقروءة" },
                  {
                    value: 2,
                    label: lang === "fr" ? "Non lu" : lang === "en" ? "Unreaded" : "غير مقروءة",
                  },
                ]}
              />
            </Grid>

            <Grid item xs={3} md={2}>
              <SelectSection
                label={
                  lang === "fr"
                    ? "Message Validé"
                    : lang === "en"
                    ? "Message validated"
                    : "رسالة مصادق عليها"
                }
                value={messagevalide}
                valueChange={(value) => {
                  setMessageValide(parseInt(value));
                }}
                valueFilter={rechercher}
                status={[
                  {
                    value: 0,
                    label: lang === "fr" ? "Tous" : lang === "en" ? "All" : "كل الحالات",
                  },
                  {
                    value: 1,
                    label: lang === "fr" ? "validé" : lang === "en" ? "validated" : "مصادق عليها",
                  },
                  {
                    value: 2,
                    label: lang === "fr" ? "Pas encore" : lang === "en" ? "Not yet" : "ليس بعد",
                  },
                ]}
              />
            </Grid>

            <Grid item xs={3} md={2}>
              <SelectSection
                label={
                  lang === "fr"
                    ? "Réponse lue par l'admin"
                    : lang === "en"
                    ? "Response readed by admin"
                    : "رد مقروء من طرف المشرف"
                }
                value={adminReponseLu}
                valueChange={(value) => {
                  setAdminReponseLu(parseInt(value));
                }}
                valueFilter={rechercher}
                status={[
                  {
                    value: 0,
                    label: lang === "fr" ? "Tous" : lang === "en" ? "All" : "كل الحالات",
                  },
                  {
                    value: 1,
                    label:
                      lang === "fr"
                        ? "Réponse lue"
                        : lang === "en"
                        ? "Response readed"
                        : "رد مقروء",
                  },
                  {
                    value: 2,
                    label:
                      lang === "fr"
                        ? "Réponse Non lue"
                        : lang === "en"
                        ? "Reply Unreaded"
                        : "رد غير مقروء",
                  },
                ]}
              />
            </Grid>

            <Grid item xs={3} md={2}>
              <SelectSection
                label={
                  lang === "fr"
                    ? "Réponse Validée"
                    : lang === "en"
                    ? "Response validated"
                    : "رد مصادق عليه"
                }
                value={reponsevalide}
                valueChange={(value) => {
                  setReponseValide(parseInt(value));
                }}
                valueFilter={rechercher}
                status={[
                  {
                    value: 0,
                    label: lang === "fr" ? "Tous" : lang === "en" ? "All" : "كل الحالات",
                  },
                  {
                    value: 1,
                    label: lang === "fr" ? "validée" : lang === "en" ? "validated" : "مصادق عليها",
                  },
                  {
                    value: 2,
                    label: lang === "fr" ? "Pas encore" : lang === "en" ? "Not yet" : "ليس بعد",
                  },
                ]}
              />
            </Grid>
            <Grid item xs={6} md={4} className="d-flex align-items-center">
              <SearchSection
                label={
                  type === "produit"
                    ? "" + (lang === "fr" ? "Produit" : lang === "en" ? "Product" : "منتج")
                    : "" + (lang === "fr" ? "Annonce" : lang === "en" ? "Announcement" : "إعلان")
                }
                value={produit}
                valueChange={(value) => setProduit(value)}
                valueFilter={rechercher}
              />
            </Grid>
            <Grid item xs={4} md={2}>
              <SelectSection
                label={lang === "fr" ? "Lu" : lang === "en" ? "Readed" : "مقروءة"}
                value={lu}
                valueChange={(value) => setLu(parseInt(value))}
                valueFilter={rechercher}
                status={[
                  {
                    value: 0,
                    label: lang === "fr" ? "Tous" : lang === "en" ? "All" : "كل الحالات",
                  },
                  { value: 1, label: lang === "fr" ? "Lu" : lang === "en" ? "Readed" : "مقروءة" },
                  {
                    value: 2,
                    label: lang === "fr" ? "Non lu" : lang === "en" ? "Unreaded" : "غير مقروءة",
                  },
                ]}
              />
            </Grid>
            <Grid item xs={4} md={2}>
              <SelectSection
                label={lang === "fr" ? "Répondu" : lang === "en" ? "Answered" : "تم الرد عليها"}
                value={repondu}
                valueChange={(value) => {
                  setRepondu(parseInt(value));
                }}
                valueFilter={rechercher}
                status={[
                  {
                    value: 0,
                    label: lang === "fr" ? "Tous" : lang === "en" ? "All" : "كل الحالات",
                  },
                  {
                    value: 1,
                    label: lang === "fr" ? "Répondu" : lang === "en" ? "Answered" : "تم الرد عليها",
                  },
                  {
                    value: 2,
                    label:
                      lang === "fr"
                        ? "Non répondu"
                        : lang === "en"
                        ? "Unreaded"
                        : "لم يتم الرد عليها",
                  },
                ]}
              />
            </Grid>

            <Grid item xs={4} md={2}>
              <SelectSection
                label={
                  lang === "fr" ? "Réponse lue" : lang === "en" ? "Response readed" : "رد مقروء"
                }
                value={reponseLu}
                valueChange={(value) => {
                  setReponseLu(parseInt(value));
                }}
                valueFilter={rechercher}
                status={[
                  {
                    value: 0,
                    label: lang === "fr" ? "Tous" : lang === "en" ? "All" : "كل الحالات",
                  },
                  {
                    value: 1,
                    label:
                      lang === "fr"
                        ? "Réponse lue"
                        : lang === "en"
                        ? "Response readed"
                        : "رد مقروء",
                  },
                  {
                    value: 2,
                    label:
                      lang === "fr"
                        ? "Réponse Non lue"
                        : lang === "en"
                        ? "Reply Unreaded"
                        : "رد غير مقروء",
                  },
                ]}
              />
            </Grid>
            <Grid item xs={6} md={3} className="d-flex align-items-center">
              <SearchSection
                label={lang === "fr" ? "Envoyé par" : lang === "en" ? "Sent by" : "ارسل من طرف"}
                value={senderName}
                valueChange={(value) => setSenderName(value)}
                valueFilter={rechercher}
              />
            </Grid>
            <Grid item xs={6} md={3} className="d-flex align-items-center">
              <SearchSection
                label={lang === "fr" ? "Envoyé à" : lang === "en" ? "Sent to" : "أرسلت إلى"}
                value={receiverName}
                valueChange={(value) => setReceiverName(value)}
                valueFilter={rechercher}
              />
            </Grid>
            <Grid item xs={12}>
              <div className="px-2 w-100">
                <Grid container spacing={3} className=" w-100 m-0 p-0">
                  <Grid item xs={8} className="p-0">
                    <SearchSection
                      label={lang === "fr" ? "Message" : lang === "en" ? "Message" : "الرسالة"}
                      value={search}
                      valueChange={(value) => setSearch(value)}
                      valueFilter={rechercher}
                    />
                  </Grid>
                  <Grid item xs={4} className="d-flex justify-content-end p-0">
                    <ButtonBase
                      sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.button,
                        transition: "all .2s ease-in-out",
                        background:
                          role === "acheteur"
                            ? theme.palette.secondary.light
                            : theme.palette.warning.light,
                        color:
                          role === "acheteur"
                            ? theme.palette.secondary.dark
                            : theme.palette.warning.dark,
                        "&:hover": {
                          background:
                            role === "acheteur"
                              ? theme.palette.secondary.dark
                              : theme.palette.warning.dark,
                          color:
                            role === "acheteur"
                              ? theme.palette.secondary.light
                              : theme.palette.warning.light,
                        },
                        width: "100%",
                        height: "100%",
                        overflow: "hidden",
                      }}
                      onClick={rechercher}
                    >
                      {lang === "fr" ? "Filtrer" : lang === "en" ? "Filter" : "عرض"}
                    </ButtonBase>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <MDBox>
        <DataTable
          table={{ columns, rows }}
          showTotalEntries={false}
          isSorted={false}
          noEndBorder
          entriesPerPage={false}
        />
        <div className="w-100 d-flex justify-content-center align-items-center">
          {/* <Pagination>{paginationItems}</Pagination> */}
          <Pagination
            color="light"
            count={
              parseInt(page.totalItems) % 12 == 0
                ? parseInt(parseInt(page.totalItems) / 12)
                : parseInt(parseInt(page.totalItems) / 12) + 1
            }
            page={page.index}
            onChange={(event, value) => {
              setPage((oldValue) => {
                return { ...oldValue, index: value };
              });
            }}
          />
        </div>
        {items.length === 0 && (
          <div className="w-100 d-flex justify-content-center align-items-center">
            <MDTypography variant="h6" color="light">
              {lang === "fr" ? "Aucun Message" : lang === "en" ? "No Message" : "لا توجد رسائل"}
            </MDTypography>
          </div>
        )}
      </MDBox>
      <Modal
        show={isLoading}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="myModalLoading"
      >
        <Modal.Body style={{ overflow: "hidden" }}>
          <Box sx={{ width: "100%" }}>
            <LinearProgress color="secondary" />
          </Box>
        </Modal.Body>
      </Modal>

      <ServiceMessageDetails
        openAdd={open}
        userId={userId}
        setOpenAdd={handleOpen}
        handleClose={handleClose}
        message={message}
        editable={true}
        isLoadingResponse={isLoadingMakeResponseToMessage}
        makeResponse={(e) => {
          if (e && e != "") {
            responseToUserMessage(e);
          } else {
            notify(
              lang === "fr"
                ? "Merci de saisir une réponse."
                : lang === "en"
                ? "Please enter a response."
                : "الرجاء إدخال الرد."
            );
          }
        }}
        isLoadingMessageValidation={isLoadingAdminValidateMessage}
        validateMessage={adminValidateMessage}
        isLoadingResponseValidation={isLoadingAdminValidateResponse}
        validateResponse={adminValidateResponse}
      ></ServiceMessageDetails>
    </Card>
  );
}

export default List;
