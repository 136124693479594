// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import Pagination from "@mui/material/Pagination";
import ReactPlayer from "react-player";

import CardHeader from "@mui/material/CardHeader";
// Data
import { Box, ButtonBase, Grid, LinearProgress, Typography } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import MDButton from "components/MDButton";
import tw from "twin.macro";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { Row, Col, Dropdown, Badge } from "react-bootstrap";
import { BASE_URL_Image } from "services/server";
import { getServices } from "services/adminService";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { updateService } from "services/adminService";
import { toast } from "react-toastify";
import { uploaderService } from "services/adminService";
import { getSecteurs } from "services/adminService";
import { getEntreprises } from "services/adminService";
import ServiceItem from "OurPages/OurComponents/Home/ServiceItem";
import SearchSection from "../SearchSection";
import { useTheme } from "@mui/material/styles";
import { IconPhotoPlus, IconPhotoUp, IconVideo, IconVideoPlus } from "@tabler/icons";
import { getServicesFav } from "services/adminService";
import LangueSection from "layoutsEntreprises/AnnonceList/components/LangueSection";
import { getTranslatedTextes } from "services/adminService";
import { useSelector } from "react-redux";

const Container = tw.div`relative w-full`;

function List({ fav }) {
  const lang = useSelector((state) => state.customization.lang);
  const [menu, setMenu] = useState(null);
  const [role, setRole] = useState("");
  const [search, setSearch] = useState("");
  const [entreprise, setEntreprise] = useState("");
  const [groupe, setGroupe] = useState("");

  const [publireportage, setPublireportage] = useState(null);
  const navigate = useNavigate();

  const [onUpdateSecteur, setOnUpdateSecteur] = useState(null);

  const [page, setPage] = useState({
    index: 1,
    limit: 6,
    totalItems: 0,
  });

  const [entreprise_id, setentreprise_id] = useState(null);
  const [activeSecteur, setActiveSecteur] = useState(0);
  const [indexPage, setIndexPage] = useState(1);
  const [items, setItems] = useState([]);
  const [services, setServices] = useState([]);

  const [entreprises, setEntreprises] = useState([]);
  const [selectedEntreprise, setSelectedEntreprise] = useState("");
  const { mutate: mutateEntreprises, isLoading: isLoadingEntreprises } = useMutation(
    (userInfo) => getEntreprises(userInfo),
    {
      async onSuccess(data) {
        console.log(data);
        if (data && data.data) {
          setEntreprises(
            data.data.map((el) => {
              return {
                imageSrc: BASE_URL_Image + el.profile_pic,
                id: el.id,
                title: el.nom,
              };
            })
          );

          if (data.data.length > 0) {
            let el = data.data[0];
            setSelectedEntreprise(el.id);
          }
        }
      },
      onError(error) {},
    }
  );

  useEffect(() => {
    let ent_id = localStorage.getItem("entreprise_id");
    console.log("entreprise_id");
    console.log(ent_id);
    setRole(localStorage.getItem("role"));
    setentreprise_id(parseInt(ent_id));
    // mutate({ entreprise_id: parseInt(ent_id) });
    // // mutateServices({
    // //   entreprise_id: parseInt(ent_id),
    // //   filter: search,
    // //   entreprise: entreprise,
    // //   groupe: groupe,
    // //   limit: localStorage.getItem("role") === "acheteur" ? 2 : 5,
    // //   offset: (page.index - 1) * (localStorage.getItem("role") === "acheteur" ? 2 : 5),
    // // });
    // mutateEntreprises({checkbox : 1 , entreprise_id : grp_id}) ;
  }, []);

  const [newServiceStep, setNewServiceStep] = useState(1);
  const [selectedLangToInsert, setSelectedLangToInsert] = useState(lang);
  const [nomService, setNomService] = useState("");
  const [description, setDescription] = useState("");

  const [nomServiceAr, setNomServiceAr] = useState("");
  const [descriptionAr, setDescriptionAr] = useState("");

  const [nomServiceEn, setNomServiceEn] = useState("");
  const [descriptionEn, setDescriptionEn] = useState("");

  const [selectedSecteur, setSelectedSecteurs] = useState("");

  const [forFiliale, setForFiliale] = useState(false);

  const [pictureUrl, setPictureUrl] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    console.log(selectedFile);
    if (selectedFile) {
      let url = URL.createObjectURL(selectedFile);
      if (url) {
        console.log(url);
        setPictureUrl(url);
      }
    }
  }, [selectedFile]);

  const [videoUrl, setVideoUrl] = useState("");
  const [videoLink, setVideoLink] = useState("");

  const [selectedVideo, setSelectedVideo] = useState(null);
  const videoInputRef = useRef(null);

  const handleVideoChange = (event) => {
    setSelectedVideo(event.target.files[0]);
  };

  const handleVideoButtonClick = () => {
    videoInputRef.current.click();
  };

  useEffect(() => {
    console.log(selectedVideo);
    if (selectedVideo) {
      let url = URL.createObjectURL(selectedVideo);
      if (url) {
        console.log(url);
        setVideoUrl(url);
      }
    }
  }, [selectedVideo]);

  const insertNewSecteur = () => {
    if (newServiceStep == 1) {
      if (!selectedFile || !pictureUrl || pictureUrl === "") {
        notify(
          lang === "fr"
            ? "Vous devez insérer une image"
            : lang === "en"
            ? "You must insert an image"
            : "يجب عليك إدراج صورة"
        );
      } else if (selectedLangToInsert === "fr" && (nomService === "" || description === "")) {
        notify(
          nomService === ""
            ? "" +
                (lang === "fr"
                  ? "Vous devez entrer un nom en Français"
                  : lang === "en"
                  ? "You must enter a name in French"
                  : "يجب عليك إدخال إسم باللغة الفرنسية")
            : "" +
                (lang === "fr"
                  ? "Vous devez entrer une description en Français"
                  : lang === "en"
                  ? "You must enter a description in French"
                  : "يجب عليك إدخال وصف باللغة الفرنسية")
        );
      } else if (selectedLangToInsert === "en" && (nomServiceEn === "" || descriptionEn === "")) {
        notify(
          nomService === ""
            ? "" +
                (lang === "fr"
                  ? "Vous devez entrer un nom en Anglais"
                  : lang === "en"
                  ? "You must enter a name in English"
                  : "يجب عليك إدخال إسم باللغة الإنجليزية")
            : "" +
                (lang === "fr"
                  ? "Vous devez entrer une description en Anglais"
                  : lang === "en"
                  ? "You must enter a description in English"
                  : "يجب عليك إدخال وصف باللغة الإنجليزية")
        );
      } else if (selectedLangToInsert === "ar" && (nomServiceAr === "" || descriptionAr === "")) {
        notify(
          nomService === ""
            ? "" +
                (lang === "fr"
                  ? "Vous devez entrer un nom en Arabe"
                  : lang === "en"
                  ? "You must enter a name in Arabic"
                  : "يجب عليك إدخال إسم باللغة العربية")
            : "" +
                (lang === "fr"
                  ? "Vous devez entrer une description en Arabe"
                  : lang === "en"
                  ? "You must enter a description in Arabic"
                  : "يجب عليك إدخال وصف باللغة العربية")
        );
      } else {
        mutateTranslate();
      }
    } else {
      if (!selectedFile || !pictureUrl || pictureUrl === "") {
        notify(
          lang === "fr"
            ? "Vous devez insérer une image"
            : lang === "en"
            ? "You must insert an image"
            : "يجب عليك إدراج صورة"
        );
      } else if (nomService === "") {
        notify(
          lang === "fr"
            ? "Vous devez entrer un nom en Français"
            : lang === "en"
            ? "You must enter a name in French"
            : "يجب عليك إدخال إسم باللغة الفرنسية"
        );
      } else if (description === "") {
        notify(
          lang === "fr"
            ? "Vous devez entrer une description en Français"
            : lang === "en"
            ? "You must enter a description in French"
            : "يجب عليك إدخال وصف باللغة الفرنسية"
        );
      } else if (nomServiceEn === "") {
        notify(
          lang === "fr"
            ? "Vous devez entrer un nom en Anglais"
            : lang === "en"
            ? "You must enter a name in English"
            : "يجب عليك إدخال إسم باللغة الإنجليزية"
        );
      } else if (descriptionEn === "") {
        notify(
          lang === "fr"
            ? "Vous devez entrer une description en Anglais"
            : lang === "en"
            ? "You must enter a description in English"
            : "يجب عليك إدخال وصف باللغة الإنجليزية"
        );
      } else if (nomServiceAr === "") {
        notify(
          lang === "fr"
            ? "Vous devez entrer un nom en Arabe"
            : lang === "en"
            ? "You must enter a name in Arabic"
            : "يجب عليك إدخال إسم باللغة العربية"
        );
      } else if (descriptionAr === "") {
        notify(
          lang === "fr"
            ? "Vous devez entrer une description en Arabe"
            : lang === "en"
            ? "You must enter a description in Arabic"
            : "يجب عليك إدخال وصف باللغة العربية"
        );
      } else {
        mutateNewSecteur();
      }
    }
  };

  const modifierSecteur = (id) => {
    if (!selectedFile && pictureUrl !== "" && nomService != "") {
      // mutateUpdateSecteur({id : onUpdateSecteur , nom : nomService }) ;
    } else if (nomService === "") {
      notify("Vous devez entrez un nom");
    } else if (pictureUrl === "") {
      notify("Vous devez inserer une image");
    } else {
      // mutateUpdateSecteur({id : onUpdateSecteur ,file : selectedFile , nom : nomService }) ;
      // setOpenAdd(false);
    }
  };
  const { mutate: mutateTranslate, isLoading: isLoadingTranslate } = useMutation(
    () =>
      getTranslatedTextes({
        lang: selectedLangToInsert,
        titre:
          selectedLangToInsert === "fr"
            ? nomService
            : selectedLangToInsert === "en"
            ? nomServiceEn
            : nomServiceAr,
        description:
          selectedLangToInsert === "fr"
            ? description
            : selectedLangToInsert === "en"
            ? descriptionEn
            : descriptionAr,
      }),
    {
      async onSuccess(data) {
        console.log(data);
        if (data && data.success) {
          notify_success(
            lang === "fr"
              ? "Avoir la traduction avec succès"
              : lang === "en"
              ? "Have the translation successfully"
              : "تمت الترجمة بنجاح"
          );
          if (selectedLangToInsert != "fr") {
            setNomService(data.data.titre_fr);
            setDescription(data.data.description_fr);
          }
          if (selectedLangToInsert != "en") {
            setNomServiceEn(data.data.titre_en);
            setDescriptionEn(data.data.description_en);
          }

          if (selectedLangToInsert != "ar") {
            setNomServiceAr(data.data.titre_ar);
            setDescriptionAr(data.data.description_ar);
          }
          setNewServiceStep(2);
        } else {
          notify(
            lang === "fr"
              ? "Une erreur a été produit"
              : lang === "en"
              ? "An error was produced"
              : "تم هنالك خطأ"
          );
        }
      },
      onError(error) {},
    }
  );
  const { mutate: mutateNewSecteur, isLoading: isLoadingNewSecteur } = useMutation(
    () =>
      uploaderService({
        file: selectedFile,
        video: selectedVideo,
        videoLink: videoLink,
        nom: nomService,
        entreprise_id: entreprise_id,
        secteur_id: selectedSecteur,
        description: description,
        titre_ar: nomServiceAr,
        description_ar: descriptionAr,
        titre_en: nomServiceEn,
        description_en: descriptionEn,
        forFiliale: true,
      }),
    {
      async onSuccess(data) {
        console.log(data);
        if (data && data.data) {
          notify_success(
            lang === "fr"
              ? "Ajouter avec succès"
              : lang === "en"
              ? "Add successfully"
              : "تمت الإضافة بنجاح"
          );
          handleCloseAdd();
          mutateServices({
            filter: search,
            limit: page.limit,
            offset: (page.index - 1) * page.limit,
            entreprise_id: entreprise_id,
          });
        } else {
          notify(
            lang === "fr"
              ? "Une erreur a été produit"
              : lang === "en"
              ? "An error was produced"
              : "تم هنالك خطأ"
          );
        }
      },
      onError(error) {},
    }
  );
  const { mutate: mutateServices, isLoading: isLoadingServices } = useMutation(
    (filters) => {
      if (fav) {
        return getServicesFav(filters);
      } else {
        return getServices(filters);
      }
    },
    {
      async onSuccess(data) {
        console.log(data);
        if (data && data.data) {
          setServices(
            data.data.map((el) => {
              return {
                id: el.service_id,
                imageSrc: BASE_URL_Image + el.image,
                title: el.titre,
                description: el.description,
                title_en: el.titre_en,
                description_en: el.description_en,
                title_ar: el.titre_ar,
                description_ar: el.description_ar,
                locationText: el.localisation,
                entrepriseText: el.entreprise_name,
                entrepriseImage: BASE_URL_Image + el.entreprise_image,
                entreprise_id: el.entreprise_id,
                groupeText: el.groupe_name,
                groupeImage: BASE_URL_Image + el.groupe_image,
                groupe_id: el.groupe_id,
                secteur: el.secteur_name,
                secteur_id: el.secteur_id,
                status: el.status && parseInt(el.status) === 1,
                blocked_admin: el.blocked_admin,
                fav: el.fav ? parseInt(el.fav) === 1 : false,
              };
            })
          );
          setPage((oldValue) => {
            return { ...oldValue, totalItems: parseInt(data.total) };
          });
        } else {
        }
      },
      onError(error) {},
    }
  );

  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useMutation(
    (userInfo) => updateService(userInfo),
    {
      async onSuccess(data) {
        console.log(data);
        if (data && data.data) {
          setServices((oldValue) => {
            return oldValue.map((el) => {
              if (el.id === data.data.id) {
                return {
                  ...el,
                  status: data.data.status && parseInt(data.data.status) === 1,
                  blocked_admin: data.data.blocked_admin,
                };
              }
              return el;
            });
          });
        } else {
        }
      },
      onError(error) {},
    }
  );

  useEffect(() => {
    let ent_id = localStorage.getItem("entreprise_id");
    mutateServices({
      entreprise_id: fav ? null : parseInt(ent_id),
      filter: search,
      entreprise: entreprise,
      groupe: groupe,
      limit: 6,
      offset: (page.index - 1) * 6,
    });
  }, [page.index, fav]);

  const rechercher = () => {
    mutateServices({
      entreprise_id: fav ? null : entreprise_id,
      filter: search,
      entreprise: entreprise,
      groupe: groupe,
      limit: page.limit,
      offset: (page.index - 1) * page.limit,
    });
  };

  const notify = (message) => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 3000,
      fontSize: "12px",
    });
  };

  const notify_success = (message) => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 3000,
    });
  };

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const [openAdd, setOpenAdd] = useState(false);
  const handleOpenAdd = (publireportage) => {
    setPublireportage(publireportage);
    setOpenAdd(true);
    console.log(publireportage);
  };
  const handleCloseAdd = () => {
    setOpenAdd(false);
    setPictureUrl("");
    setSelectedFile(null);
    setSelectedEntreprise("");
    setSelectedSecteurs("");
    setNewServiceStep(1);
    setNomService("");
    setDescription("");
    setNomServiceAr("");
    setDescriptionAr("");
    setNomServiceEn("");
    setDescriptionEn("");
    setForFiliale(false);
    setVideoUrl("");
    setVideoLink("");
  };
  const theme = useTheme();

  return (
    <Card style={{ overflow: "hidden" }}>
      <div>
        <MDTypography
          variant="h6"
          gutterBottom
          className="px-3 py-2"
          style={{
            background:
              role === "acheteur" || role === "admin"
                ? theme.palette.secondary.dark
                : theme.palette.warning.dark,
            color:
              role === "acheteur" || role === "admin"
                ? theme.palette.secondary.light
                : theme.palette.warning.light,
            width: "max-content",
          }}
        >
          {!fav
            ? lang === "fr"
              ? "Liste des Produits"
              : lang === "en"
              ? "List of Products"
              : "قائمة المنتجات"
            : ""}
          {fav
            ? lang === "fr"
              ? "Liste des Produits préférées"
              : lang === "en"
              ? "List of Favorite Products "
              : "قائمة المنتجات المفضلة"
            : ""}
        </MDTypography>
      </div>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox style={{ width: "100%" }}>
          <Grid container spacing={3}>
            <Grid item xs={12} xl={4}></Grid>
            <Grid item xs={12} xl={8}>
              <MDBox>
                <Grid container spacing={1}>
                  <Grid item xs={!(fav || role === "admin") ? 10 : 12}>
                    <SearchSection
                      label={lang === "fr" ? "Produit" : lang === "en" ? "Product" : "المنتج"}
                      value={search}
                      valueChange={(value) => setSearch(value)}
                      valueFilter={rechercher}
                    />
                  </Grid>

                  {!(fav || role === "admin") ? (
                    <Grid
                      item
                      xs={2}
                      style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                    >
                      <ButtonBase
                        sx={{
                          ...theme.typography.commonAvatar,
                          ...theme.typography.button,
                          transition: "all .2s ease-in-out",
                          background:
                            role === "acheteur" || role === "admin"
                              ? theme.palette.secondary.light
                              : theme.palette.warning.light,
                          color:
                            role === "acheteur" || role === "admin"
                              ? theme.palette.secondary.dark
                              : theme.palette.warning.dark,
                          "&:hover": {
                            background:
                              role === "acheteur" || role === "admin"
                                ? theme.palette.secondary.dark
                                : theme.palette.warning.dark,
                            color:
                              role === "acheteur" || role === "admin"
                                ? theme.palette.secondary.light
                                : theme.palette.warning.light,
                          },
                          width: "100%",
                          height: "100%",
                          overflow: "hidden",
                        }}
                        onClick={handleOpenAdd}
                      >
                        {lang === "fr" ? "Ajouter" : lang === "en" ? "Add" : "أضف"}
                      </ButtonBase>
                    </Grid>
                  ) : (
                    <></>
                  )}
                </Grid>
              </MDBox>
            </Grid>
          </Grid>

          <div className="w-100 py-2 px-3 mt-2 d-flex align-items-center justify-content-center">
            <MDBox display="flex" alignItems="center" lineHeight={0}>
              <MDTypography variant="button" fontWeight="regular" style={{ color: "grey" }}>
                &nbsp;
                <strong
                  style={{
                    color:
                      role === "acheteur" || role === "admin"
                        ? theme.palette.secondary.dark
                        : theme.palette.warning.dark,
                  }}
                >
                  <>
                    {page.totalItems ?? 0}{" "}
                    {page.totalItems > 1
                      ? "" + (lang === "fr" ? "Produits" : lang === "en" ? "Products" : "منتجات")
                      : "" + (lang === "fr" ? "Produit" : lang === "en" ? "Product" : "منتج")}
                  </>
                </strong>{" "}
                {lang === "fr" ? "en total" : lang === "en" ? "in total" : "في المجمل"}
              </MDTypography>
            </MDBox>
          </div>
        </MDBox>
      </MDBox>
      <MDBox>
        <Container className="p-4">
          <div>
            <Row style={{ width: "100%" }} className="py-3 m-0">
              {services.map((card, index) => (
                <Col
                  key={index}
                  xs={12}
                  md={6}
                  lg={4}
                  className="mb-5  d-flex align-items-start justify-content-center"
                  style={{ minWidth: "280px" }}
                >
                  <ServiceItem
                    initCard={card}
                    withActions={true}
                    updateAction={mutateUpdate}
                  ></ServiceItem>
                </Col>
              ))}
            </Row>
          </div>
          <Row style={{ paddingTop: "10px", borderTop: "1px solid #e5e5e5 ", marginTop: "10px" }}>
            <Col xs={12} className="d-flex justify-content-center align-items-center">
              {/* <Pagination>{items}</Pagination> */}
              <Pagination
                color="light"
                count={
                  parseInt(page.totalItems) % page.limit == 0
                    ? parseInt(parseInt(page.totalItems) / page.limit)
                    : parseInt(parseInt(page.totalItems) / page.limit) + 1
                }
                page={page.index}
                onChange={(event, value) => {
                  setPage((oldValue) => {
                    return { ...oldValue, index: value };
                  });
                }}
              />
            </Col>
            {page.totalItems == 0 && !isLoadingServices && (
              <Col xs={12} className="d-flex justify-content-center align-items-center">
                <MDTypography
                  variant="h6"
                  color="light"
                  style={{
                    color:
                      role === "acheteur" || role === "admin"
                        ? theme.palette.secondary.dark
                        : theme.palette.warning.dark,
                  }}
                >
                  {lang === "fr"
                    ? "Aucune produit"
                    : lang === "en"
                    ? "No Products"
                    : "لا توجد منتجات"}
                </MDTypography>
              </Col>
            )}
          </Row>
        </Container>
      </MDBox>
      <Modal
        show={open}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Annulation d'un publireportage
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MDBox
            mx={2}
            my={1}
            py={3}
            px={2}
            variant="gradient"
            bgColor="error"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              Est-ce-que vous voulez vraiment annuler ce publireportage ?
            </MDTypography>
          </MDBox>
        </Modal.Body>
        <Modal.Footer>
          <MDButton color="error" onClick={handleClose}>
            Confirmer
          </MDButton>
        </Modal.Footer>
      </Modal>

      <Modal
        show={openAdd}
        onHide={handleCloseAdd}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: "0.85em" }}>
            {onUpdateSecteur !== null
              ? "Modifier Secteur"
              : lang === "fr"
              ? "Ajouter un nouveau Produit"
              : lang === "en"
              ? "Add a new product"
              : "إضافة منتج جديد"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <CardPub style={{ width: "100%" }}> */}
            {newServiceStep === 1 ? (
              <div className="row w-100 m-0">
                <div className="col-12">
                  <div className="flex-auto">
                    <div className="relative w-full mb-3 mt-8" style={{ zIndex: "2" }}>
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-0"
                        htmlFor="full-name"
                      >
                        {lang === "fr"
                          ? "Langue de saisie"
                          : lang === "en"
                          ? "Input language"
                          : "لغة الإدخال"}
                      </label>
                      <LangueSection
                        selectedLangToInsert={selectedLangToInsert}
                        setSelectedLangToInsert={setSelectedLangToInsert}
                        editable={true}
                      ></LangueSection>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="flex-auto">
                    <div className="relative w-full mb-3 mt-8">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="full-name"
                      >
                        {lang === "fr"
                          ? "Nom du produit"
                          : lang === "en"
                          ? "Product Name"
                          : "إسم المنتوج"}
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder={
                          lang === "fr"
                            ? "Nom du produit"
                            : lang === "en"
                            ? "Product Name"
                            : "إسم المنتوج"
                        }
                        value={
                          selectedLangToInsert === "fr"
                            ? nomService
                            : selectedLangToInsert === "en"
                            ? nomServiceEn
                            : nomServiceAr
                        }
                        onChange={(e) =>
                          selectedLangToInsert === "fr"
                            ? setNomService(e.currentTarget.value)
                            : selectedLangToInsert === "en"
                            ? setNomServiceEn(e.currentTarget.value)
                            : setNomServiceAr(e.currentTarget.value)
                        }
                      />
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mt-5 "
                        htmlFor="full-name"
                      >
                        {lang === "fr"
                          ? "Image du produit"
                          : lang === "en"
                          ? "Product image"
                          : "صورة المنتج"}
                      </label>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "300px",
                      height: "300px",
                      // border: pictureUrl ? "" : "3px dashed  #1A73E8",
                      margin: "20px auto",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      overflow: "hidden",
                      color: "#d7d7d7",
                    }}
                    onClick={handleButtonClick}
                  >
                    <input
                      accept="image/*"
                      ref={fileInputRef}
                      onClick={handleFileChange}
                      onChange={handleFileChange}
                      type="file"
                      style={{ display: "none" }}
                    />

                    {pictureUrl && pictureUrl != "" && (
                      <img
                        style={{ height: "100%", width: "auto", maxWidth: "none" }}
                        src={pictureUrl}
                      />
                    )}
                    {(!pictureUrl || pictureUrl === "") && (
                      <IconPhotoUp style={{ height: "100%", width: "auto" }} />
                    )}
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="flex-auto">
                    <div className="relative w-full mb-3 mt-8">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="description du produit"
                      >
                        {lang === "fr" ? "Description" : lang === "en" ? "Description" : "الوصف"}
                      </label>
                      <textarea
                        rows="20"
                        cols="80"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                        placeholder={
                          lang === "fr" ? "Description" : lang === "en" ? "Description" : "الوصف"
                        }
                        value={
                          selectedLangToInsert === "fr"
                            ? description
                            : selectedLangToInsert === "en"
                            ? descriptionEn
                            : descriptionAr
                        }
                        onChange={(e) =>
                          selectedLangToInsert === "fr"
                            ? setDescription(e.currentTarget.value)
                            : selectedLangToInsert === "en"
                            ? setDescriptionEn(e.currentTarget.value)
                            : setDescriptionAr(e.currentTarget.value)
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <hr></hr>
                  <div className="flex-auto">
                    <div className="relative w-full mb-3 mt-8">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-1"
                        htmlFor="description du produit"
                      >
                        {lang === "fr"
                          ? "Vidéo du produit "
                          : lang === "en"
                          ? "Product video"
                          : "فيديو المنتج"}

                        <span style={{ fontSize: "10px" }}>
                          {lang === "fr" ? "(Optionnel)" : lang === "en" ? "(Optional)" : "(خياري)"}
                        </span>
                      </label>

                      <label
                        className="block uppercase text-info text-xs font-bold mb-2"
                        htmlFor="description du produit"
                      >
                        <span style={{ fontSize: "10px" }}>
                          {lang === "fr"
                            ? "Si vous souhaitez télécharger une vidéo, vous devez laisser le champ du lien vide"
                            : lang === "en"
                            ? "If you want to download a video, you must leave the link field empty"
                            : "إذا كنت تريد تنزيل مقطع فيديو، فيجب عليك ترك حقل الرابط فارغًا"}
                        </span>
                      </label>

                      <div className="w-100 row m-0">
                        <div className="col-12 col-lg-6">
                          <div className="relative w-full mb-3 mt-8">
                            <label
                              className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                              htmlFor="full-name"
                              style={{ fontSize: "10px" }}
                            >
                              {lang === "fr"
                                ? "Lien de la vidéo "
                                : lang === "en"
                                ? "Video link"
                                : "رابط الفيديو"}
                              (Youtube , Facebook , ......)
                            </label>
                            <input
                              type="text"
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder={
                                lang === "fr" ? "Lien" : lang === "en" ? "link" : "الرابط"
                              }
                              value={videoLink}
                              onChange={(e) => setVideoLink(e.currentTarget.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-lg-6">
                          <div
                            style={{
                              width: "100px",
                              height: "100px",
                              // border: pictureUrl ? "" : "3px dashed  #1A73E8",
                              margin: "20px auto",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                              overflow: "hidden",
                              color: "#d7d7d7",
                            }}
                            onClick={handleVideoButtonClick}
                          >
                            <input
                              accept="video/mp4"
                              ref={videoInputRef}
                              onClick={handleVideoChange}
                              onChange={handleVideoChange}
                              type="file"
                              style={{ display: "none" }}
                            />
                            {(pictureUrl || pictureUrl !== "") && (
                              <div className="">
                                <IconVideo
                                  style={{ height: "50px", width: "auto", margin: " auto" }}
                                />
                                <Typography variant="caption" align="center">
                                  {lang === "fr"
                                    ? "Modifier la Vidéo"
                                    : lang === "en"
                                    ? "Edit Video"
                                    : "تغيير الفيديو"}
                                </Typography>
                              </div>
                            )}

                            {(!pictureUrl || pictureUrl === "") && (
                              <div className="">
                                <IconVideoPlus
                                  style={{ height: "50px", width: "auto", margin: " auto" }}
                                />
                                <Typography variant="caption" align="center">
                                  {lang === "fr"
                                    ? "Uploader Vidéo"
                                    : lang === "en"
                                    ? "Upload Video"
                                    : "رفع فيديو"}
                                </Typography>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-12">
                          {((videoLink && videoLink !== "") || (videoUrl && videoUrl != "")) && (
                            // <img style={{ height: "100%", width: "auto" }} src={videoUrl} />
                            <>
                              <div
                                className="w-100 p-0 m-0"
                                style={{
                                  borderRadius: "20px",
                                  overflow: "hidden",
                                  // boxShadow: "#3f3f3f 0px 0px 10px 1px",
                                }}
                              >
                                <ReactPlayer
                                  url={videoLink && videoLink != "" ? videoLink : videoUrl}
                                  // light={alaune}
                                  controls
                                  width={"100%"}
                                />
                              </div>

                              <ButtonBase
                                sx={{
                                  ...theme.typography.commonAvatar,
                                  ...theme.typography.button,
                                  transition: "all .2s ease-in-out",
                                  background: theme.palette.error.light,
                                  color: theme.palette.error.dark,
                                  "&:hover": {
                                    background: theme.palette.error.dark,
                                    color: theme.palette.error.light,
                                  },
                                  width: "100%",
                                  overflow: "hidden",
                                }}
                                className="py-2 px-5 my-2"
                                onClick={() => {
                                  setVideoLink("");
                                  setVideoUrl("");
                                }}
                              >
                                {lang === "fr"
                                  ? "Supprimer la vidéo"
                                  : lang === "en"
                                  ? "Delete video"
                                  : "قم بنزع الفيديو"}
                              </ButtonBase>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : newServiceStep === 2 ? (
              <div className="row w-100 m-0">
                <div className="col-12 ">
                  <div className="flex-auto">
                    <>
                      <div
                        className="relative w-full mb-3 mt-8 flex align-items-center justify-content-start"
                        style={{ zIndex: "2" }}
                      >
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-0 mr-4"
                          htmlFor="full-name"
                        >
                          {lang === "fr" ? "Langue :" : lang === "en" ? "Language :" : "اللغة : "}
                        </label>
                        <LangueSection
                          selectedLangToInsert={selectedLangToInsert}
                          setSelectedLangToInsert={(lang) => {}}
                          editable={false}
                        ></LangueSection>
                      </div>
                      <div className="relative w-full mb-3 mt-8">
                        <CardHeader
                          className="p-0 m-0"
                          title={
                            <strong style={{ fontSize: "14px" }}>
                              {selectedLangToInsert === "fr"
                                ? nomService
                                : selectedLangToInsert === "en"
                                ? nomServiceEn
                                : nomServiceAr}
                            </strong>
                          }
                        />

                        {/* <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mt-5 "
                      htmlFor="full-name"
                    >
                      Image du produit
                    </label> */}
                      </div>

                      <div className="flex-auto">
                        <div className="relative w-full mb-3 mt-8">
                          <Typography variant="body2">
                            {selectedLangToInsert === "fr"
                              ? description
                              : selectedLangToInsert === "en"
                              ? descriptionEn
                              : descriptionAr}
                          </Typography>
                        </div>
                      </div>

                      <hr></hr>
                    </>

                    {selectedLangToInsert !== "fr" ? (
                      <>
                        <div
                          className="relative w-full mb-3 mt-8 flex align-items-center justify-content-start"
                          style={{ zIndex: "2" }}
                        >
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-0 mr-4"
                            htmlFor="full-name"
                          >
                            {lang === "fr" ? "Langue :" : lang === "en" ? "Language :" : "اللغة : "}
                          </label>
                          <LangueSection
                            selectedLangToInsert={"fr"}
                            setSelectedLangToInsert={(lang) => {}}
                            editable={false}
                          ></LangueSection>
                        </div>

                        <div className="relative w-full mb-3 mt-8">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-0"
                            htmlFor="full-name"
                          >
                            {lang === "fr"
                              ? "Nom du Produit en Français"
                              : lang === "en"
                              ? "Name of the product in French"
                              : "اسم المنتج باللغة الفرنسية"}
                          </label>
                          <input
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder={
                              lang === "fr"
                                ? "Nom du Produit en Français"
                                : lang === "en"
                                ? "Name of the product in French"
                                : "اسم المنتج باللغة الفرنسية"
                            }
                            value={nomService}
                            onChange={(e) => setNomService(e.currentTarget.value)}
                          />
                        </div>

                        <div className="flex-auto">
                          <div className="relative w-full mb-3 mt-8">
                            <label
                              className="block uppercase text-blueGray-600 text-xs font-bold mb-0"
                              htmlFor="description du produit"
                            >
                              {lang === "fr"
                                ? "Description en Français"
                                : lang === "en"
                                ? "Description in French"
                                : "الوصف باللغة الفرنسية"}
                            </label>
                            <textarea
                              rows="12"
                              cols="80"
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                              placeholder={
                                lang === "fr"
                                  ? "Description en Français"
                                  : lang === "en"
                                  ? "Description in French"
                                  : "الوصف باللغة الفرنسية"
                              }
                              value={description}
                              onChange={(e) => setDescription(e.currentTarget.value)}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    {selectedLangToInsert !== "en" ? (
                      <>
                        <div
                          className="relative w-full mb-3 mt-8 flex align-items-center justify-content-start"
                          style={{ zIndex: "2" }}
                        >
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-0 mr-4"
                            htmlFor="full-name"
                          >
                            {lang === "fr" ? "Langue :" : lang === "en" ? "Language :" : "اللغة : "}
                          </label>
                          <LangueSection
                            selectedLangToInsert={"en"}
                            setSelectedLangToInsert={(lang) => {}}
                            editable={false}
                          ></LangueSection>
                        </div>

                        <div className="relative w-full mb-3 mt-8">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-0"
                            htmlFor="full-name"
                          >
                            {lang === "fr"
                              ? "Nom du Produit en Anglais"
                              : lang === "en"
                              ? "Name of the product in English"
                              : "اسم المنتج باللغة الإنجليزية"}
                          </label>
                          <input
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder={
                              lang === "fr"
                                ? "Nom du Produit en Anglais"
                                : lang === "en"
                                ? "Name of the product in English"
                                : "اسم المنتج باللغة الإنجليزية"
                            }
                            value={nomServiceEn}
                            onChange={(e) => setNomServiceEn(e.currentTarget.value)}
                          />
                        </div>

                        <div className="flex-auto">
                          <div className="relative w-full mb-3 mt-8">
                            <label
                              className="block uppercase text-blueGray-600 text-xs font-bold mb-0"
                              htmlFor="description du produit"
                            >
                              {lang === "fr"
                                ? "Description en Anglais"
                                : lang === "en"
                                ? "Description in English"
                                : "الوصف باللغة الإنجليزية"}
                            </label>
                            <textarea
                              rows="12"
                              cols="80"
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                              placeholder={
                                lang === "fr"
                                  ? "Description en Anglais"
                                  : lang === "en"
                                  ? "Description in English"
                                  : "الوصف باللغة الإنجليزية"
                              }
                              value={descriptionEn}
                              onChange={(e) => setDescriptionEn(e.currentTarget.value)}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    {selectedLangToInsert !== "ar" ? (
                      <>
                        <div
                          className="relative w-full mb-3 mt-8 flex align-items-center justify-content-start"
                          style={{ zIndex: "2" }}
                        >
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-0 mr-4"
                            htmlFor="full-name"
                          >
                            {lang === "fr" ? "Langue :" : lang === "en" ? "Language :" : "اللغة : "}
                          </label>
                          <LangueSection
                            selectedLangToInsert={"ar"}
                            setSelectedLangToInsert={(lang) => {}}
                            editable={false}
                          ></LangueSection>
                        </div>

                        <div className="relative w-full mb-3 mt-8">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-0"
                            htmlFor="full-name"
                          >
                            {lang === "fr"
                              ? "Nom du Produit en Arabe"
                              : lang === "en"
                              ? "Name of the product in Arabic"
                              : "اسم المنتج باللغة العربية"}
                          </label>
                          <input
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder={
                              lang === "fr"
                                ? "Nom du Produit en Arabe"
                                : lang === "en"
                                ? "Name of the product in Arabic"
                                : "اسم المنتج باللغة العربية"
                            }
                            value={nomServiceAr}
                            onChange={(e) => setNomServiceAr(e.currentTarget.value)}
                          />
                        </div>

                        <div className="flex-auto">
                          <div className="relative w-full mb-3 mt-8">
                            <label
                              className="block uppercase text-blueGray-600 text-xs font-bold mb-0"
                              htmlFor="description du produit"
                            >
                              {lang === "fr"
                                ? "Description en Arabe"
                                : lang === "en"
                                ? "Description in Arabic"
                                : "الوصف باللغة العربية"}
                            </label>
                            <textarea
                              rows="12"
                              cols="80"
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                              placeholder={
                                lang === "fr"
                                  ? "Description en Arabe"
                                  : lang === "en"
                                  ? "Description in Arabic"
                                  : "الوصف باللغة العربية"
                              }
                              value={descriptionAr}
                              onChange={(e) => setDescriptionAr(e.currentTarget.value)}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div>
            {newServiceStep === 2 ? (
              <ButtonBase
                sx={{
                  ...theme.typography.commonAvatar,
                  ...theme.typography.button,
                  transition: "all .2s ease-in-out",
                  background: theme.palette.dark.light,
                  color: theme.palette.dark.dark,
                  "&:hover": {
                    background: theme.palette.dark.dark,
                    color: theme.palette.dark.light,
                  },
                  height: "100%",
                  overflow: "hidden",
                }}
                className="py-2 px-5 mx-3"
                onClick={() => {
                  setNewServiceStep(1);
                }}
              >
                {lang === "fr" ? "Retour" : lang === "en" ? "Go Back" : "العودة"}
              </ButtonBase>
            ) : (
              <></>
            )}
            <ButtonBase
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.button,
                transition: "all .2s ease-in-out",
                background:
                  role === "acheteur" || role === "admin"
                    ? theme.palette.secondary.light
                    : theme.palette.warning.light,
                color:
                  role === "acheteur" || role === "admin"
                    ? theme.palette.secondary.dark
                    : theme.palette.warning.dark,
                "&:hover": {
                  background:
                    role === "acheteur" || role === "admin"
                      ? theme.palette.secondary.dark
                      : theme.palette.warning.dark,
                  color:
                    role === "acheteur" || role === "admin"
                      ? theme.palette.secondary.light
                      : theme.palette.warning.light,
                },
                height: "100%",
                overflow: "hidden",
              }}
              className="py-2 px-5"
              onClick={onUpdateSecteur !== null ? modifierSecteur : insertNewSecteur}
            >
              {lang === "fr" ? "Confirmer" : lang === "en" ? "Confirm" : "تأكيد"}
            </ButtonBase>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        show={isLoadingServices || isLoadingUpdate || isLoadingTranslate}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="myModalLoading"
      >
        <Modal.Body style={{ overflow: "hidden" }}>
          <Box sx={{ width: "100%" }}>
            <LinearProgress color="secondary" />
          </Box>
        </Modal.Body>
      </Modal>
    </Card>
  );
}

export default List;
