import { authApi } from "./server";

export const getAcheteurPage = async () => {
  let id = localStorage.getItem("acheteur_id");
  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.get("acheteurs/page/" + id);
  return response.data;
};

export const updateAcheteur = async (userinfo) => {
  let id = userinfo.id;
  delete userinfo.id;

  let toUpload = {};

  if (userinfo.profile_pic) {
    const formData = new FormData();
    formData.append("profile_pic", userinfo.profile_pic);
    toUpload = formData;
  } else if (userinfo.cover_pic) {
    const formData = new FormData();
    formData.append("cover_pic", userinfo.cover_pic);
    toUpload = formData;
  } else {
    toUpload = userinfo;
  }

  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("acheteurs/update/" + id, toUpload);
  return response.data;
};
