import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";

// Data
import data from "layoutsGroupes/InscriptionsList/components/data";
import { Divider, Grid } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import MDButton from "components/MDButton";
import tw from "twin.macro";
import styled from "styled-components";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import MDInput from "components/MDInput";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Pagination } from "react-bootstrap";
import { BASE_URL_Image } from "services/server";
import { useMutation } from "@tanstack/react-query";
import dayjs from "dayjs";
import { getDemandeInscriptions } from "services/adminService";
import { updateDemandeInscription } from "services/adminService";
import { inscriptionAbonnementsList } from "services/adminService";
import AbonnementItem from "layoutsGroupes/InscriptionsList/components/abonnement";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

const CardPub = tw.div`h-full flex! flex-col sm:border max-w-sm  relative focus:outline-none bg-white m-0`;
const CardImage = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full h-56 sm:h-64 bg-cover bg-center `,
]);

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-2xl font-bold`;

const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4`;

const PrimaryButton = tw(
  PrimaryButtonBase
)`mt-auto sm:text-lg rounded-none w-full  py-3 sm:py-6 border-none`;

function List() {
  const dateToDateJS = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();

    const dateString = `${year}/${month}/${day}`;
    console.log("init date debut");
    console.log(dateString);
    return dayjs(dateString);
  };

  const [publireportage, setPublireportage] = useState(null);

  const [page, setPage] = useState({
    index: 1,
    limit: 5,
    totalItems: 0,
  });
  const [search, setSearch] = useState("");
  const [dateDebut, setDateDebut] = useState(dateToDateJS(new Date()));
  const [dateFin, setDateFin] = useState(null);

  const [paginationItems, setPaginationItems] = useState([]);

  const [items, setItems] = useState([]);

  const [open, setOpen] = useState(false);
  const handleOpen = (publireportage) => {
    console.log(publireportage);
    setPublireportage(publireportage);
    setOpen(true);
  };
  const handleClose = () => {
    setPublireportage(null);
    setOpen(false);
  };

  const [openArchivage, setOpenArchivage] = useState(false);
  const handleOpenArchivage = (publireportage) => {
    console.log(publireportage);
    setPublireportage(publireportage);
    setOpenArchivage(true);
  };
  const handleCloseArchivage = () => {
    setPublireportage(null);
    setOpenArchivage(false);
  };

  const { mutate, isLoading } = useMutation((userInfo) => getDemandeInscriptions(userInfo), {
    async onSuccess(data) {
      console.log(data);
      if (data && data.data) {
        let new_data = data.data.map((el) => {
          return {
            ...el,
            image: BASE_URL_Image + el.image,
            abonnement: inscriptionAbonnementsList.filter((it) => it.id === el.abonnement_id)[0],
          };
        });

        setItems(
          new_data.map((it) => {
            console.log({
              ...it,
              abonnement: {
                ...it.abonnement,
                price: it.groupe_id !== null ? it.abonnement.price * 0.8 : it.abonnement.price,
              },
            });
            return {
              ...it,
              abonnement: {
                ...it.abonnement,
                price: it.groupe_id !== null ? it.abonnement.price * 0.8 : it.abonnement.price,
              },
            };
          })
        );
        setPage((oldValue) => {
          return { ...oldValue, totalItems: parseInt(data.total) };
        });
        let table_items = [];
        if (parseInt(data.total) !== 0) {
          for (
            let number = 1;
            number <= parseInt(parseInt(data.total) / 5) + (parseInt(data.total) % 5 > 0 ? 1 : 0);
            number++
          ) {
            table_items.push(
              <Pagination.Item
                key={number}
                active={number === page.index}
                onClick={() => {
                  setPage((oldValue) => {
                    return { ...oldValue, index: number };
                  });
                }}
              >
                {number}
              </Pagination.Item>
            );
          }
        }
        setPaginationItems(table_items);
      } else {
      }
    },
    onError(error) {},
  });

  useEffect(() => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    setDateFin(dateToDateJS(tomorrow));
    mutate({ filter: search, limit: 5, offset: (page.index - 1) * 5 });
  }, []);

  const [openValidation, setOpenValidation] = useState(false);
  const handleOpenValidation = (publireportage) => {
    console.log(dateDebut);
    console.log(dateFin);
    setPublireportage(publireportage);
    setOpenValidation(true);
    console.log(publireportage);
  };
  const handleCloseValidation = () => {
    setDateDebut(dateToDateJS(new Date()));
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    setDateFin(dateToDateJS(tomorrow));
    setPublireportage(null);
    setOpenValidation(false);
  };

  const [openAffichage, setOpenAffichage] = useState(false);
  const handleOpenAffichage = (publireportage) => {
    setPublireportage(publireportage);
    setOpenAffichage(true);
    console.log(publireportage);
  };
  const handleCloseAffichage = () => {
    setOpenAffichage(false);
  };

  const { columns, rows } = data({
    handleOpen,
    handleOpenValidation,
    handleOpenAffichage,
    handleOpenArchivage,
    items: items,
  });

  const rechercher = () => {
    mutate({ filter: search, limit: 5, offset: (page.index - 1) * 5 });
  };

  const { mutate: updateDemandes, isLoading: isLoadingupdateDemandes } = useMutation(
    (userInfos) => updateDemandeInscription(userInfos),
    {
      async onSuccess(data) {
        console.log(data);
        if (data) {
          mutate();
          // setOpenValidation((oldValue) => {return {id : null , value : false}});
        } else {
        }
      },
      onError(error) {},
    }
  );

  const annulerItem = () => {
    updateDemandes({ id: publireportage.id, status: -1 });
    handleClose();
  };

  const validerItem = () => {
    console.log(publireportage);
    updateDemandes({
      id: publireportage.id,
      status: 1,
      date_debut: dateDebut.format("YYYY/MM/DD"),
      date_fin: dateFin.format("YYYY/MM/DD"),
    });
    handleCloseValidation();
  };

  const archiverItem = () => {
    updateDemandes({ id: publireportage.id, archive: 1 });
    handleCloseArchivage();
  };

  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox style={{ width: "100%" }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <MDTypography variant="h6" gutterBottom>
                Liste des demandes d'inscription
              </MDTypography>
              <MDBox display="flex" alignItems="center" lineHeight={0}>
                <Icon
                  sx={{
                    fontWeight: "bold",
                    color: ({ palette: { info } }) => info.main,
                    mt: -0.5,
                  }}
                >
                  ads_click
                </Icon>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;
                  <strong>
                    {items != null ? items.length : 0}{" "}
                    {items != null && items.length > 1 ? "Demandes" : "Demande"}
                  </strong>{" "}
                  en total
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <MDBox>
                <div className="px-2 w-100">
                  <Grid container spacing={3} className=" w-100 m-0">
                    <Grid item style={{ flex: 1 }} className="px-2">
                      <MDInput
                        style={{ width: "100%" }}
                        label="Recherche"
                        onChange={(e) => setSearch(e.currentTarget.value)}
                      />
                    </Grid>
                    <Grid item className="d-flex justify-content-end">
                      <MDButton color="light" onClick={rechercher}>
                        Filtrer
                      </MDButton>
                    </Grid>
                  </Grid>
                </div>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <MDBox>
        <DataTable
          table={{ columns, rows }}
          showTotalEntries={false}
          isSorted={false}
          noEndBorder
          entriesPerPage={false}
        />
        <div className="w-100 d-flex justify-content-center align-items-center">
          <Pagination>{paginationItems}</Pagination>
        </div>
        {page.totalItems === 0 && (
          <div className="w-100 d-flex justify-content-center align-items-center">
            <MDTypography variant="h6" color="light">
              Aucune Demande
            </MDTypography>
          </div>
        )}
      </MDBox>
      <Modal
        show={open || openArchivage}
        onHide={open ? handleClose : handleCloseAffichage}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {open ? "Refu d'une inscription" : "Archivage d'un publireportage"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MDBox
            mx={2}
            my={1}
            py={3}
            px={2}
            variant="gradient"
            bgColor="error"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              {open
                ? "Est-ce-que vous voulez vraiment réfuser cette demande ?"
                : "Est-ce-que vous voulez vraiment archiver ce publireportage ?"}
            </MDTypography>
          </MDBox>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button onClick={handleClose}>Confirmer</Button> */}
          <MDButton color="error" onClick={open ? annulerItem : archiverItem}>
            Confirmer
          </MDButton>
        </Modal.Footer>
      </Modal>

      <Modal
        show={openValidation}
        onHide={handleCloseValidation}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Validation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {publireportage != null && publireportage.abonnement != null && (
              <AbonnementItem abonement={publireportage.abonnement}></AbonnementItem>
            )}
          </div>
          <MDBox
            mx={2}
            my={1}
            py={3}
            px={2}
            variant="gradient"
            bgColor="light"
            borderRadius="lg"
            coloredShadow="light"
            className=" mt-5"
          >
            <div className="row w-100 ml-0">
              <div className="col-6 d-flex justify-content-start align-items-center">
                <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Nom
                </MDTypography>
              </div>
              <div className="col-6 d-flex justify-content-end align-items-center">
                <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                  {publireportage != null && publireportage.nom != null ? publireportage.nom : ""}
                </MDTypography>
              </div>
              <Divider></Divider>
              <div className="col-6 d-flex justify-content-start align-items-center">
                <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Email
                </MDTypography>
              </div>
              <div className="col-6 d-flex justify-content-end align-items-center">
                <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                  {publireportage != null && publireportage.email != null
                    ? publireportage.email
                    : ""}
                </MDTypography>
              </div>
              <Divider></Divider>
              <div className="col-6 d-flex justify-content-start align-items-center">
                <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Prix
                </MDTypography>
              </div>
              <div className="col-6 d-flex justify-content-end align-items-center">
                <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                  {publireportage != null && publireportage.abonnement != null
                    ? publireportage.abonnement.price
                    : ""}
                </MDTypography>
              </div>
              <Divider></Divider>
              <div className="col-6 d-flex justify-content-start align-items-center">
                <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Durée
                </MDTypography>
              </div>
              <div className="col-6 d-flex justify-content-end align-items-center">
                <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                  {publireportage != null && publireportage.abonnement != null
                    ? publireportage.abonnement.duration
                    : ""}
                </MDTypography>
              </div>
              <Divider></Divider>
              <div className="col-6 d-flex justify-content-start align-items-center">
                <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Attachement
                </MDTypography>
              </div>
              <div className="col-6 d-flex justify-content-end align-items-center">
                <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                  {publireportage != null && publireportage.image != null ? (
                    <a href={publireportage.image} download={true}>
                      <CloudDownloadIcon color="dark" fontSize="small" />
                    </a>
                  ) : (
                    ""
                  )}
                </MDTypography>
              </div>
            </div>
          </MDBox>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button onClick={handleCloseValidation}>Confirmer</Button> */}
          <MDButton color="info" onClick={validerItem}>
            Confirmer
          </MDButton>
        </Modal.Footer>
      </Modal>

      <Modal
        show={openAffichage}
        onHide={handleCloseAffichage}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {publireportage != null && publireportage.abonnement != null && (
              <AbonnementItem abonement={publireportage.abonnement}></AbonnementItem>
            )}
          </div>
          <MDBox
            mx={2}
            my={1}
            py={3}
            px={2}
            variant="gradient"
            bgColor="light"
            borderRadius="lg"
            coloredShadow="light"
            className=" mt-5"
          >
            <div className="row w-100 ml-0">
              <div className="col-6 d-flex justify-content-start align-items-center">
                <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Nom
                </MDTypography>
              </div>
              <div className="col-6 d-flex justify-content-end align-items-center">
                <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                  {publireportage != null && publireportage.nom != null ? publireportage.nom : ""}
                </MDTypography>
              </div>
              <Divider></Divider>
              <div className="col-6 d-flex justify-content-start align-items-center">
                <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Email
                </MDTypography>
              </div>
              <div className="col-6 d-flex justify-content-end align-items-center">
                <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                  {publireportage != null && publireportage.email != null
                    ? publireportage.email
                    : ""}
                </MDTypography>
              </div>
              <Divider></Divider>
              <div className="col-6 d-flex justify-content-start align-items-center">
                <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Prix
                </MDTypography>
              </div>
              <div className="col-6 d-flex justify-content-end align-items-center">
                <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                  {publireportage != null && publireportage.abonnement != null
                    ? publireportage.abonnement.price
                    : ""}
                </MDTypography>
              </div>
              <Divider></Divider>
              <div className="col-6 d-flex justify-content-start align-items-center">
                <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Durée
                </MDTypography>
              </div>
              <div className="col-6 d-flex justify-content-end align-items-center">
                <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                  {publireportage != null && publireportage.abonnement != null
                    ? publireportage.abonnement.duration
                    : ""}
                </MDTypography>
              </div>
              <Divider></Divider>
              <div className="col-6 d-flex justify-content-start align-items-center">
                <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Attachement
                </MDTypography>
              </div>
              <div className="col-6 d-flex justify-content-end align-items-center">
                <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                  {publireportage != null && publireportage.image != null ? (
                    <a href={publireportage.image} download={true}>
                      <CloudDownloadIcon color="dark" fontSize="small" />
                    </a>
                  ) : (
                    ""
                  )}
                </MDTypography>
              </div>
            </div>
          </MDBox>
        </Modal.Body>
      </Modal>
      <Modal
        show={isLoading || isLoadingupdateDemandes}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <MDBox
            mx={2}
            my={1}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              {isLoading ? "Loading ..." : "Updating ..."}
            </MDTypography>
          </MDBox>
        </Modal.Body>
      </Modal>
    </Card>
  );
}

export default List;
