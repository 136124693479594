/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import TopNavbar from "../../../../components/Nav/TopNavbarSecond";
import EmailIcon from "@mui/icons-material/Email";
// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import ProfileInfoCard from "./components/ProfileInfoCard";
import ProfilesList from "./components/ProfilesList";

// Overview page components
import Header from "./components/Header";
import EspacePub from "../EspacePub";

// Data
import profilesListData from "./data/profilesListData";

// Images
import { Box, Icon, LinearProgress } from "@mui/material";
import { useState } from "react";

import tw from "twin.macro";
import styled from "styled-components";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import React from "react";
import Slider from "react-slick";
import { SectionHeading } from "components/misc/Headings";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { ReactComponent as StarIcon } from "feather-icons/dist/icons/briefcase.svg";
import { useEffect } from "react";
import { Row, Col, Dropdown, Button, Modal, Badge } from "react-bootstrap";
import { Button as ButtonMui } from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useNavigate, useParams } from "react-router-dom";
import { getAnnoncePage } from "services/visiteurService";
import { BASE_URL_Image } from "services/server";
import { useMutation } from "@tanstack/react-query";
import ServiceItem from "../ServiceItem";
import TitleDescriptionCard from "components/indicator/TitleDescriptionCard";
import ReactImageGallery from "react-image-gallery";
import "./Service.css";
import ServiceCard from "./components/ServiceCard";
import ServiceMessages from "./components/messages/Messages";
import AnnonceItem from "../AnnonceItem";
import ShareProduct from "components/shareProduct/ShareProduct";
import { deleteAnnonceFav } from "services/adminService";
import { addAnnonceFav } from "services/adminService";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { ArrowBack } from "@mui/icons-material";
import MainCard from "ui-component/cards/MainCard";
import MustAuth from "components/shareProduct/MustAuth";

const ActionButton = tw(
  Button
)`px-8 py-3 font-bold rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300 mt-12 inline-block tracking-wide text-center px-10 py-4 font-semibold tracking-normal border-none`;
const SecondaryButton = tw(
  ActionButton
)`mt-6 sm:mt-12 sm:ml-8 bg-gray-300 text-gray-800 hocus:bg-gray-400 hocus:text-gray-900`;
const Container = tw.div`relative w-full`;

const PrimaryButton = tw(
  PrimaryButtonBase
)`mt-auto sm:text-lg rounded-none w-full py-3 sm:py-6 bg-gray-500 text-gray-100 border-none`;

const CardTW = tw.div`h-full flex! flex-col sm:border max-w-sm  relative focus:outline-none`;
const CardImage = styled.div((props) => [
  `background-image: url("${props.imageSrc}");height : 100% ; `,
  tw`w-full  bg-cover bg-center rounded sm:rounded-none`,
]);

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-2xl font-bold`;
const IconWithText = tw.div`flex items-center mr-6 my-2 sm:my-0`;

const Text = tw.div`ml-2 text-sm font-semibold text-gray-800`;

const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4`;

function Overview() {
  const { id } = useParams();
  const theme = useTheme();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const lang = useSelector((state) => state.customization.lang);
  const [auth, setAuth] = useState(false);
  const [UserRole, setUserRole] = useState("");
  const [openAdd, setOpenAdd] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Add event listener to update window width when it changes
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    let token = localStorage.getItem("token");
    setAuth(token != null && token !== "");
    let role = localStorage.getItem("role");
    if (role != null && role != "null" && role != "") {
      setUserRole(role);
    }
  }, []);

  const navigate = useNavigate();
  const [selectedService, setSelectedService] = useState({
    id: 0,
    imageSrc: "",
    title: "",
    description: "",
    locationText: "",
    entrepriseText: "",
    entreprise_id: "",
    secteur: "",
    secteur_id: "",
  });

  const [services, setServices] = useState([]);

  const [entreprise, setEntreprise] = useState({});
  const [groupe, setGroupe] = useState({});
  const [product, setProduct] = useState(null);
  const [shareProduct, setShareProduct] = useState(false);

  const { mutate: mutateService, isLoading: isLoadingService } = useMutation(
    (id) => getAnnoncePage(id),
    {
      async onSuccess(data) {
        console.log(data);
        if (data && data.data && data.data.annonce) {
          if (data.data.annonce.length > 0) {
            let result = data.data.annonce[0];

            let infos_result = {};
            if (result.tel) infos_result["tel"] = result.tel;
            if (result.fax) infos_result["fax"] = result.fax;
            if (result.email) infos_result["email"] = result.email;
            if (result.localisation) infos_result["location"] = result.localisation;

            let links_result = [];

            if (result.facebook) {
              links_result.push({
                link: result.facebook,
                icon: <FacebookIcon />,
                color: "facebook",
              });
            }
            if (result.twitter) {
              links_result.push({
                link: result.twitter,
                icon: <TwitterIcon />,
                color: "twitter",
              });
            }
            if (result.instagram) {
              links_result.push({
                link: result.instagram,
                icon: <InstagramIcon />,
                color: "instagram",
              });
            }
            if (result.sitweb) {
              links_result.push({
                link: result.sitweb,
                icon: <Icon fontSize="small">language</Icon>,
                color: "portefolio",
              });
            }

            setEntreprise({
              id: result.entreprise_id,
              nom: result.entreprise_name,
              profile_pic: BASE_URL_Image + result.entreprise_image,
              infos: infos_result,
              links: links_result,
            });

            setGroupe({
              id: result.groupe_id,
              nom: result.groupe_name,
              profile_pic: BASE_URL_Image + result.groupe_image,
              infos: infos_result,
              links: links_result,
            });

            setSelectedService({
              id: result.id,
              imageSrc: BASE_URL_Image + result.image,
              title: result.titre,
              description: result.description,
              title_en: result.titre_en,
              description_en: result.description_en,
              title_ar: result.titre_ar,
              description_ar: result.description_ar,
              locationText: result.localisation,
              entrepriseText: result.entreprise_name,
              entreprise_id: result.entreprise_id,
              groupeText: result.groupe_name,
              groupe_id: result.groupe_id,
              secteur: result.secteur_name,
              secteur_id: result.secteur_id,
              videoUrl: result.video,
              videoLink: result.videoLink,
              fav: result.fav ? parseInt(result.fav) === 1 : false,
            });
          } else {
            setSelectedService({
              id: 0,
              imageSrc: "",
              title: "",
              description: "",
              locationText: "",
              entrepriseText: "",
              entreprise_id: "",
              secteur: "",
              secteur_id: "",
            });
          }

          setServices(
            data.data.annonces.map((el) => {
              return {
                id: el.id,
                imageSrc: BASE_URL_Image + el.image,
                title: el.titre,
                description: el.description,
                title_en: el.titre_en,
                description_en: el.description_en,
                title_ar: el.titre_ar,
                description_ar: el.description_ar,
                locationText: el.localisation,
                entrepriseText: el.entreprise_name,
                entreprise_id: el.entreprise_id,
                secteur: el.secteur_nom,
                secteur_id: el.secteur_id,
                entrepriseImage: BASE_URL_Image + el.entreprise_image,
                fav: el.fav ? parseInt(el.fav) === 1 : false,
              };
            })
          );
        } else {
        }
      },
      onError(error) {},
    }
  );

  useEffect(() => {
    console.log(id);
    if (id) {
      console.log("loading");
      mutateService(id);
      window.scrollTo(0, 0);
    }
  }, [id]);

  useEffect(() => {
    if (document.getElementById("header_smart") != null)
      document.getElementById("header_smart").classList.add("header_smart_solution");
    return () => {
      if (document.getElementById("header_smart") != null)
        document.getElementById("header_smart").classList.remove("header_smart_solution");
    };
  });

  const { mutate: mutateAddFav, isLoading: isLoadingAddFav } = useMutation(
    (filters) => addAnnonceFav(filters),
    {
      async onSuccess(data) {
        console.log(data);
        if (data && data.success) {
          setSelectedService((oldValue) => {
            return { ...oldValue, fav: true };
          });
          notify_success(
            lang === "fr"
              ? "Ajoutée à la liste fav"
              : lang === "en"
              ? "Added to fav list"
              : "تمت إضافته إلى قائمة المفضلة"
          );
        } else {
          notify(lang === "fr" ? "Une erreur" : lang === "en" ? "An error" : "هنالك مشكلة");
        }
      },
      onError(error) {},
    }
  );

  const { mutate: mutateDeleteFav, isLoading: isLoadingDeleteFav } = useMutation(
    (filters) => deleteAnnonceFav(filters),
    {
      async onSuccess(data) {
        console.log(data);
        if (data && data.success) {
          setSelectedService((oldValue) => {
            return { ...oldValue, fav: false };
          });
          notify_success(
            lang === "fr"
              ? "Supprimée de la liste fav"
              : lang === "en"
              ? "Removed from fav list"
              : "تمت الإزالة من قائمة المفضلة"
          );
        } else {
          notify(lang === "fr" ? "Une erreur" : lang === "en" ? "An error" : "هنالك مشكلة");
        }
      },
      onError(error) {},
    }
  );

  const addfav = () => {
    let user_id = localStorage.getItem("id");
    mutateAddFav({
      user_id: parseInt(user_id),
      annonce_id: selectedService.id,
    });
  };

  const deletefav = () => {
    let user_id = localStorage.getItem("id");
    mutateDeleteFav({
      user_id: parseInt(user_id),
      annonce_id: selectedService.id,
    });
  };

  const onLike = () => {
    let entreprise_id = localStorage.getItem("id");

    if (entreprise_id && parseInt(entreprise_id) != parseInt(selectedService.entreprise_id)) {
      if (selectedService.fav) {
        deletefav();
      } else {
        addfav();
      }
    }
  };

  const notify = (message) => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 3000,
      fontSize: "12px",
    });
  };

  const notify_success = (message) => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 3000,
    });
  };

  return (
    <>
      <TopNavbar></TopNavbar>
      <div
        className="w-100"
        style={{ padding: windowWidth > 800 ? "40px" : "0px", paddingTop: "80px" }}
      >
        {/* <DashboardNavbar /> */}
        <MDBox mb={2} />
        {/* <Header entreprise={entreprise} groupe={groupe}> */}
        <MDBox mt={5} mb={3}>
          <div className="w-100 mb-5"></div>
          <Grid container spacing={1}>
            <Grid item xs={12} sx={{ display: "flex" }}>
              <div style={{ width: "100%", maxWidth: "100%" }}>
                <div className="w-100 mt-5 d-flex align-items-center justify-content-center">
                  <div className="w-100" style={{ maxWidth: "900px" }}>
                    <MainCard className="p-0">
                      <div className="w-100 new_message_button d-flex justify-content-between align-items-center p-0">
                        {/* <Email style={{ color: theme.palette.grey[400] }} fontSize="medium" /> */}

                        <ButtonMui
                          startIcon={
                            <ArrowBack
                              style={lang === "ar" ? { transform: "rotate(180deg)" } : {}}
                              className="mx-2"
                            ></ArrowBack>
                          }
                          sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.button,
                            transition: "all .2s ease-in-out",
                            background: theme.palette.secondary.light,
                            color: theme.palette.secondary.dark,
                            "&:hover": {
                              background: theme.palette.secondary.dark,
                              color: theme.palette.secondary.light,
                            },
                            height: "100%",
                            overflow: "hidden",
                            borderRadius: "none",
                          }}
                          className="py-2 px-5"
                          onClick={() => {
                            window.history.back();
                          }}
                        >
                          {lang === "fr" ? "Retour" : lang === "en" ? "Go Back" : "العودة"}
                        </ButtonMui>
                      </div>
                    </MainCard>
                  </div>
                </div>

                <div className="row w-100 m-0 mt-3 d-flex align-items-center justify-content-center">
                  <Col
                    xs={12}
                    className="d-flex align-items-center justify-content-center p-0"
                    style={{ maxWidth: "900px" }}
                  >
                    {/* <CardImage imageSrc={selectedService.imageSrc} style={{minHeight : "150px" , height : "auto"}} /> */}
                    {/* <img
                        src={
                          selectedService && selectedService.imageSrc
                            ? selectedService.imageSrc
                            : ""
                        }
                        alt=""
                        style={{
                          width: "100%",
                          height: "auto",
                          borderRadius: "15px",
                          maxWidth: "600px",
                        }}
                      /> */}
                    {/* <ReactImageGallery
                      showThumbnails={false}
                      showPlayButton={false}
                      style={{ width: "100%" }}
                      items={[
                        {
                          original:
                            selectedService && selectedService.imageSrc
                              ? selectedService.imageSrc
                              : "",
                          thumbnail:
                            selectedService && selectedService.imageSrc
                              ? selectedService.imageSrc
                              : "",
                          originalClass: "w-100",
                        },
                      ]}
                    /> */}
                    {selectedService && selectedService.id ? (
                      <ServiceCard
                        entreprise={entreprise.nom}
                        entreprise_image={entreprise.profile_pic}
                        title={
                          lang === "fr"
                            ? selectedService.title ?? ""
                            : lang === "en"
                            ? selectedService.title_en ?? ""
                            : selectedService.title_ar ?? ""
                        }
                        description={
                          lang === "fr"
                            ? selectedService.description ?? ""
                            : lang === "en"
                            ? selectedService.description_en ?? ""
                            : selectedService.description_ar ?? ""
                        }
                        videoUrl={selectedService.videoUrl}
                        videoLink={selectedService.videoLink}
                        onShare={() => {
                          setProduct(selectedService);
                          setShareProduct(true);
                        }}
                        images={
                          selectedService && selectedService.imageSrc
                            ? [selectedService.imageSrc]
                            : null
                        }
                        fav={selectedService.fav}
                        onLike={onLike}
                      ></ServiceCard>
                    ) : (
                      <></>
                    )}
                  </Col>
                </div>
                <div className="w-100 mt-5 d-flex align-items-center justify-content-center">
                  {selectedService && selectedService.id ? (
                    <div className="w-100" style={{ maxWidth: "900px" }}>
                      <ServiceMessages
                        type={"annonce"}
                        concerned_id={selectedService.id}
                        responded_by={entreprise.id}
                      ></ServiceMessages>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>

                <div className="w-100 mt-5 d-flex align-items-center justify-content-center">
                  <div className="w-100" style={{ maxWidth: "900px" }}>
                    <MainCard className="p-0">
                      <div className="w-100">
                        <h3 className="text-lg font-semibold mb-3">
                          {lang === "fr"
                            ? "Lancez Vos Annonces dès Aujourd'hui !"
                            : lang === "en"
                            ? "Launch Your Ads Today!"
                            : "أطلق إعلاناتك اليوم!"}
                        </h3>
                        <p className="text-md font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
                          {lang === "fr"
                            ? "Rejoignez notre plateforme en quelques clics et commencez à publier vos annonces dès maintenant. "
                            : lang === "en"
                            ? "Join our platform in just a few clicks and start publishing your ads now. "
                            : "انضم إلى منصتنا ببضع نقرات فقط وابدأ في نشر إعلاناتك الآن."}

                          <br></br>
                          {lang === "fr"
                            ? "Inscrivez-vous gratuitement et profitez d'une visibilité exceptionnelle pour vos annonces. Ne manquez pas cette opportunité !"
                            : lang === "en"
                            ? "Register for free and benefit from exceptional visibility for your ads. Don't miss this opportunity!"
                            : "سجل مجانًا واستفد من الرؤية الاستثنائية لإعلاناتك. لا تفوت هذه الفرصة!"}
                        </p>
                      </div>
                      <div className="w-100 new_message_button d-flex justify-content-center align-items-center p-0">
                        {/* <Email style={{ color: theme.palette.grey[400] }} fontSize="medium" /> */}

                        <ButtonMui
                          sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.button,
                            transition: "all .2s ease-in-out",
                            width: "100%",
                            background: theme.palette.primary.light,
                            color: theme.palette.primary.dark,
                            "&:hover": {
                              background: theme.palette.primary.dark,
                              color: theme.palette.primary.light,
                            },
                            height: "100%",
                            overflow: "hidden",
                            borderRadius: "none",
                          }}
                          className="py-2 px-5"
                          onClick={() => {
                            if (auth) {
                              if (UserRole === "entreprise") {
                                navigate("/entreprise/annonces");
                              } else if (UserRole === "acheteur") {
                                navigate("/acheteur/annonces");
                              }
                            } else {
                              setOpenAdd(true);
                            }
                          }}
                        >
                          {lang === "fr"
                            ? "Lancez votre annonce"
                            : lang === "en"
                            ? "Launch your ad"
                            : "أطلق إعلانك"}
                        </ButtonMui>
                      </div>
                    </MainCard>
                  </div>
                </div>
              </div>
              {/* <Divider orientation="vertical" sx={{ mx: 0 }} /> */}
            </Grid>
            {/* <Grid item xs={12} md={12} xl={4}  sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              <ProfileInfoCard
                title="Information du contact "
                info={entreprise && entreprise.infos ? entreprise.infos : {}}
                social={entreprise && entreprise.links ? entreprise.links : []}
                action={{ route: "", tooltip: "Modifier les informaitons" }}
                shadow={false}
              />
              
              <PlatformSettings />
            </Grid> */}
          </Grid>
        </MDBox>
        <MDBox pt={2} px={2} lineHeight={1.25}>
          <EspacePub />
        </MDBox>
        <MDBox pt={2} px={2} lineHeight={1.25}>
          <MDTypography variant="h6" fontWeight="medium">
            {lang === "fr"
              ? "Annonces Similaires"
              : lang === "en"
              ? "Similar Announcements"
              : "إعلانات مشابهة"}
          </MDTypography>
          <Container className="p-4">
            <PerfectScrollbar>
              <Row style={{ width: "100%" }} className="py-3">
                {services.map((card, index) => (
                  <Col
                    key={index}
                    xs={12}
                    md={6}
                    lg={4}
                    className="mb-5  d-flex align-items-start justify-content-center"
                    style={{ minWidth: "280px" }}
                  >
                    <AnnonceItem initCard={card}></AnnonceItem>
                  </Col>
                ))}
              </Row>
            </PerfectScrollbar>
          </Container>
        </MDBox>
        {/* </Header> */}
        <Modal
          show={isLoadingService}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="myModalLoading"
        >
          <Modal.Body style={{ overflow: "hidden" }}>
            <Box sx={{ width: "100%" }}>
              <LinearProgress color="secondary" />
            </Box>
          </Modal.Body>
        </Modal>
        {/* <Footer /> */}

        <ShareProduct
          product={product}
          shareProduct={shareProduct}
          onHideProduct={() => {
            setProduct(null);
            setShareProduct(false);
          }}
        />
      </div>

      <MustAuth openAdd={openAdd} setOpenAdd={setOpenAdd}></MustAuth>
    </>
  );
}

export default Overview;
