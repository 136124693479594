import { Box, LinearProgress } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";

import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { contactForm } from "services/visiteurService";

export default () => {
  const lang = useSelector((state) => state.customization.lang);

  const [Email, setEmail] = useState("");
  const [Subject, setSubject] = useState("");
  const [Message, setMessage] = useState("");
  const [Name, setName] = useState("");

  const { mutate: mutateSendEmail, isLoading: isLoadingSendEmail } = useMutation(
    (formInfos) => contactForm(formInfos),
    {
      async onSuccess(data) {
        console.log(data);
        if (data && data.result) {
          notify_success(
            lang === "fr"
              ? "Votre message a été envoyé avec succès"
              : lang === "en"
              ? "Your message has been sent successfully"
              : "تم ارسال رسالتك بنجاح"
          );
          setEmail("");
          setName("");
          setSubject("");
          setMessage("");
        } else {
          notify(
            lang === "fr"
              ? "Une erreur est survenue!!"
              : lang === "en"
              ? "An error has occurred!!"
              : "حدث خطأ!!"
          );
        }
      },
      onError(error) {},
    }
  );

  const send = () => {
    var form = {
      name: Name,
      email: Email,
      subject: Subject,
      message: Message,
    };
    console.log(form);

    if (
      !Email ||
      Email === "" ||
      !String(Email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      notify(
        lang === "fr"
          ? "Entrez votre Email s'il vous plaît !"
          : lang === "en"
          ? "Enter your Email please!"
          : "أدخل بريدك الإلكتروني من فضلك!"
      );
    } else if (!Name || Name === "") {
      notify(
        lang === "fr"
          ? "Entrez votre Dénomination s'il vous plaît !"
          : lang === "en"
          ? "Enter your Name Denomination!"
          : "أدخل التسمية خاصتك من فضلك!"
      );
    } else if (!Subject || Subject === "") {
      notify(
        lang === "fr"
          ? "Entrez votre sujet s'il vous plaît !"
          : lang === "en"
          ? "Enter your Subject please !"
          : "أدخل الموضوع الخاص بك من فضلك!"
      );
    } else if (!Message || Message === "") {
      notify(
        lang === "fr"
          ? "Entrez votre message s'il vous plaît !"
          : lang === "en"
          ? "Enter your Message please !"
          : "أدخل رسالتك من فضلك!"
      );
    } else {
      mutateSendEmail(form);
    }
  };

  const notify = (message) => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 3000,
      fontSize: "12px",
    });
  };

  const notify_success = (message) => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 3000,
    });
  };
  return (
    <div className="flex-auto p-5 lg:p-10" style={lang === "ar" ? { direction: "rtl" } : {}}>
      {/* <h4 className="text-2xl font-semibold">Want to work with us?</h4> */}
      <p
        className="leading-relaxed mt-1 mb-4 text-blueGray-500  font-bold "
        style={{ fontSize: "14px" }}
      >
        {lang === "fr"
          ? "N'hésitez pas à remplir le formulaire de contact ci-dessous, et nous vous répondrons dans les plus brefs délais."
          : lang === "en"
          ? "Please fill in the contact form below, and we'll get back to you as soon as possible."
          : "يرجى ملء نموذج الاتصال أدناه وسنعاود الاتصال بك في أقرب وقت ممكن."}

        <br />
        {lang === "fr"
          ? "Votre succès à l'international commence ici, avec notre équipe dédiée à rendre votre expérience d'exportation fluide et réussie."
          : lang === "en"
          ? "Your international success starts here, with our team dedicated to making your export experience smooth and successful."
          : "يبدأ نجاحك الدولي من هنا، مع فريقنا المكرس لجعل تجربة التصدير الخاصة بك سلسة وناجحة."}
      </p>
      <div className="relative w-full mb-3 mt-8">
        <label
          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="full-name"
        >
          {lang === "fr" ? "Dénomination" : lang === "en" ? "Denomination" : "التسمية"}
        </label>
        <input
          type="text"
          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          placeholder={
            lang === "fr"
              ? "Votre dénomination"
              : lang === "en"
              ? "Your denomination"
              : "التسمية خاصتك"
          }
          value={Name}
          onChange={(e) => setName(e.currentTarget.value)}
        />
      </div>

      <div className="relative w-full mb-3">
        <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="email">
          {lang === "fr" ? "Email" : lang === "en" ? "Email" : "البريد الإلكتروني"}
        </label>
        <input
          type="email"
          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          placeholder={
            lang === "fr" ? "votre email" : lang === "en" ? "your email" : "بريدك الإلكتروني"
          }
          value={Email}
          onChange={(e) => setEmail(e.currentTarget.value)}
        />
      </div>

      <div className="relative w-full mb-3">
        <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="email">
          {lang === "fr" ? "Sujet" : lang === "en" ? "Subject" : "الموضوع"}
        </label>
        <input
          type="email"
          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          placeholder={lang === "fr" ? "Sujet" : lang === "en" ? "Subject" : "الموضوع"}
          value={Subject}
          onChange={(e) => setSubject(e.currentTarget.value)}
        />
      </div>

      <div className="relative w-full mb-3">
        <label
          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="message"
        >
          {lang === "fr" ? "Message" : lang === "en" ? "Message" : "رسالتك"}
        </label>
        <textarea
          rows="4"
          cols="80"
          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
          placeholder={
            lang === "fr" ? "votre message..." : lang === "en" ? "your message..." : "رسالتك..."
          }
          value={Message}
          onChange={(e) => setMessage(e.currentTarget.value)}
        />
      </div>
      <div className="text-center mt-6">
        <button
          className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          type="button"
          onClick={send}
        >
          {lang === "fr" ? "Envoyer" : lang === "en" ? "Send" : "إرسال"}
        </button>
      </div>

      <Modal
        show={isLoadingSendEmail}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="myModalLoading"
      >
        <Modal.Body style={{ overflow: "hidden" }}>
          <Box sx={{ width: "100%" }}>
            <LinearProgress color="secondary" />
          </Box>
        </Modal.Body>
      </Modal>
    </div>
  );
};
