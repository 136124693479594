/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";

// Data
import data from "layoutsGroupe/SecteurList/components/List/data";
import { Box, Grid } from "@mui/material";
import  Button  from "react-bootstrap/Button";
import Modal from 'react-bootstrap/Modal';
import MDButton from "components/MDButton";
import tw from "twin.macro";
import styled from "styled-components";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import MDInput from "components/MDInput";

import EnergyIconImage from "../../../../images/zap.svg";
import HomeIconImage from "../../../../images/home.svg";
import HydriqueIconImage from "../../../../images/droplet.svg";
import TransportIconImage from "../../../../images/truck.svg";
import TransformationIconImage from "../../../../images/settings.svg";
import TechIconImage from "../../../../images/cpu.svg";
import SanteIconImage from "../../../../images/activity.svg";
import TourismeIconImage from "../../../../images/map.svg";
import CoultureIconImage from "../../../../images/file-text.svg";
import TelecomIconImage from "../../../../images/phone-incoming.svg";
import FinanceIconImage from "../../../../images/dollar-sign.svg";

const CardPub = tw.div`h-full flex! flex-col sm:border max-w-sm  relative focus:outline-none bg-white m-0`;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full h-56 sm:h-64 bg-cover bg-center `
]);

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-2xl font-bold`;

const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4`;

const PrimaryButton = tw(PrimaryButtonBase)`mt-auto sm:text-lg rounded-none w-full  py-3 sm:py-6 border-none`;


function List() {
  const [menu, setMenu] = useState(null);
  const [publireportage, setPublireportage] = useState(null);
  const [items, setItems] = useState([
    
    {
      id : 1 , 
      imageSrc : EnergyIconImage , 
      title : "Energie " , 
      status : false , 
    } ,{
      id : 2 , 
      imageSrc : HomeIconImage , 
      title : "Habitat ,construction, travaux et aménagement" , 
      status : false , 
    } ,{
      id : 3 , 
      imageSrc : HydriqueIconImage , 
      title : "Hydrique" , 
      status : false , 
    } ,{
      id : 4 , 
      imageSrc : TransformationIconImage , 
      title : "Industrie et transformation" , 
      status : true , 
    } ,{
      id : 5 , 
      imageSrc : TransportIconImage , 
      title : "Transport et mobilité" , 
      status : false , 
    } ,
    {
      id : 6 , 
      imageSrc : SanteIconImage , 
      title : "Santé et médical" , 
      status : false , 
    } ,
    {
      id : 7 , 
      imageSrc : TourismeIconImage , 
      title : "Tourisme, hôtellerie et catering " , 
      status : false , 
    } ,
    {
      id : 8 , 
      imageSrc : TechIconImage , 
      title : "Technologie et numérique" , 
      status : false , 
    } ,
    {
      id : 9 , 
      imageSrc : CoultureIconImage , 
      title : "Culture,formation" , 
      status : false , 
    } ,
    {
      id : 11 , 
      imageSrc : FinanceIconImage , 
      title : "Finance" , 
      status : false , 
    } ,
    {
      id : 12 , 
      imageSrc : TelecomIconImage , 
      title : "Telecommunication" , 
      status : false , 
    } ,
  ]);

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);

  
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  
  const [openAdd, setOpenAdd] = useState(false);
  const handleOpenAdd = (publireportage) => {
    setPublireportage(publireportage) ;
    setOpenAdd(true);
    console.log(publireportage);
  };
  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  const { columns, rows } = data({handleOpen , handleOpenAdd , items : items});

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={closeMenu}>Action</MenuItem>
      <MenuItem onClick={closeMenu}>Another action</MenuItem>
      <MenuItem onClick={closeMenu}>Something else</MenuItem>
    </Menu>
  );

  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox style={{width : "100%"}}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            
          
          <MDTypography variant="h6" gutterBottom>
            Liste des secteurs
          </MDTypography>
          <MDBox display="flex" alignItems="center" lineHeight={0}>
            <Icon
              sx={{
                fontWeight: "bold",
                color: ({ palette: { info } }) => info.main,
                mt: -0.5,
              }}
            >
              account_tree
            </Icon>
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;<strong>{items != null ? items.length : 0} {items != null && items.length > 1 ? "Secteurs": "Secteur"}</strong> en total
            </MDTypography>
          </MDBox>
          
            </Grid>
          <Grid item xs={12} md={6}>
          
          <MDBox>
            <Grid container spacing={3}>
              <Grid item xs={8}>
                <MDInput style={{width : "100%"}} label="Recherche" />
              </Grid>
              <Grid item xs={4}>
                <MDButton color="dark"   onClick={handleOpenAdd}  >filter</MDButton>
              </Grid>
            </Grid>
            </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <MDBox>
        <DataTable
          table={{ columns, rows }}
          showTotalEntries={true}
          isSorted={false}
          noEndBorder
          entriesPerPage={true}
        />
      </MDBox>
      <Modal
      show={open}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        Annulation d'un publireportage
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <MDBox
          mx={2}
          my={1}
          py={3}
          px={2}
          variant="gradient"
          bgColor="error"
          borderRadius="lg"
          coloredShadow="info"
        >
          <MDTypography variant="h6" color="white">
          Est-ce-que vous voulez vraiment annuler ce publireportage ?
          </MDTypography>
        </MDBox>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button onClick={handleClose}>Confirmer</Button> */}
        <MDButton color="error"  onClick={handleClose}>Confirmer</MDButton>
      </Modal.Footer>
    </Modal>

      <Modal
      show={openAdd}
      onHide={handleCloseAdd}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Ajouter un nouveau Secteur
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{width :"100%" , display : "flex" , justifyContent : "center" , alignItems : "center"}}>
        <CardPub style={{maxWidth : "300px" , width : "100%" }}>
            <div style={{width : "300px"  , height : "300px" , border : "3px dashed  #1A73E8" , margin : "20px auto" , display : "flex" , justifyContent : "center" ,alignItems : "center" , cursor : "pointer" }}>
              <Icon fontSize="large" color="info">add_a_photo</Icon>
            </div>
            <MDInput style={{width : "100%"}} label="Nom du secteur" />
       </CardPub>

        </div>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button onClick={handleCloseAdd}>Confirmer</Button> */}
        <MDButton color="info"  onClick={handleCloseAdd}>Confirmer</MDButton>
      </Modal.Footer>
    </Modal>
    </Card>
  );
}

export default List;
