
// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";

// Data
import data from "layouts/PartenaireList/components/List/data";
import { Box, Divider, Grid } from "@mui/material";
import  Button  from "react-bootstrap/Button";
import Modal from 'react-bootstrap/Modal';
import MDButton from "components/MDButton";
import tw from "twin.macro";
import styled from "styled-components";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import MDInput from "components/MDInput";
import React, { useState } from "react";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { ReactComponent as StarIcon } from "feather-icons/dist/icons/briefcase.svg";
import { useEffect } from "react";
import { Row , Col , Pagination, Dropdown, Badge  } from 'react-bootstrap';
import { BASE_URL_Image } from "services/server";
import { getServices } from "services/adminService";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { updateService } from "services/adminService";

const ActionButton = tw(
  Button
)`px-8 py-3 font-bold rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300 mt-12 inline-block tracking-wide text-center px-10 py-4 font-semibold tracking-normal border-none`;
const SecondaryButton = tw(
  ActionButton
)`mt-6 sm:mt-12 sm:ml-8 bg-gray-300 text-gray-800 hocus:bg-gray-400 hocus:text-gray-900`;
const Container = tw.div`relative w-full`;


const PrimaryButton = tw(PrimaryButtonBase)`mt-auto sm:text-lg rounded-none w-full py-3 sm:py-6 bg-gray-500 text-gray-100 border-none`;


const CardTW = tw.div`h-full flex! flex-col sm:border max-w-sm  relative focus:outline-none`;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");height : 100% ; `,
  tw`w-full  bg-cover bg-center rounded sm:rounded-none`
]);

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-2xl font-bold`;

const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4`;

const IconWithText = tw.div`flex items-center mr-6 my-2 sm:my-0`;

const Text = tw.div`ml-2 text-sm font-semibold text-gray-800`;



function List() {
  const [menu, setMenu] = useState(null);  
  const [search, setSearch] = useState("");
  const [entreprise, setEntreprise] = useState("");
  const [groupe, setGroupe] = useState("");

  const [publireportage, setPublireportage] = useState(null);
  const navigate = useNavigate();

  const [page, setPage] = useState({
    index : 1 , 
    limit : 5 , 
    totalItems : 0 , 
  });
  
  const secteurs = [
    {
      id : 0 , 
      text : "Tout "
    } ,
    {
      id : 1 , 
      text : "Energie "
    } ,{
      id : 2 , 
      text : "Habitat ,construction, travaux et aménagement"
    } ,{
      id : 3 , 
      text : "Hydrique"
    } ,{
      id : 4 , 
      text : "Industrie et transformation"
    } ,{
      id : 5 , 
      text : "Transport et mobilité"
    } ,
    {
      id : 6 , 
      text : "Santé et médical"
    } ,
    {
      id : 7 , 
      text : "Tourisme, hôtellerie et catering "
    } ,
    {
      id : 8 , 
      text : "Technologie et numérique"
    } ,
    {
      id : 9 , 
      text : "Culture,formation"
    } ,
    {
      id : 11 , 
      text : "Finance"
    } ,
    {
      id : 12 , 
      text : "Telecommunication"
    } ,
  ]  ;
  const [activeSecteur, setActiveSecteur] = useState(0);
  const [indexPage, setIndexPage] = useState(1);
  const [items, setItems] = useState([]);
  const [services, setServices] = useState([]) ; 
  const { mutate : mutateServices, isLoading : isLoadingServices } = useMutation(
    (filters) => getServices(filters),
    {
      async onSuccess(data) {
        console.log(data);
        if(data && data.data) {
          setServices(
            data.data.map((el) => {
              return {
                id : el.service_id , 
                imageSrc: BASE_URL_Image + el.image ,
                title: el.titre,
                description: el.description,
                locationText: el.localisation,
                entrepriseText: el.entreprise_name,
                entreprise_id: el.entreprise_id,
                secteur: el.secteur_name,
                secteur_id: el.secteur_id,
                status : el.status && parseInt(el.status ) === 1
              } 
            })
            ) ; 
            setPage(oldValue => {return {...oldValue , totalItems : parseInt(data.total)}}) ; 
            let table_items =  [] ; 
            if(parseInt(data.total) !== 0) {
              for (let number = 1; number <= parseInt(parseInt(data.total)/5)+(parseInt(data.total)%5 > 0 ? 1 : 0);number++) {
                table_items.push(
                  <Pagination.Item key={number} active={number === page.index} onClick={()=> {setPage(oldValue => {return {...oldValue , index : number}})}}>
                    {number}
                  </Pagination.Item>,
                );
              }
            }
            setItems(table_items) ;
        }else { 
          
        }
      },
      onError(error) {
        
      },
    }
  );

   
  const { mutate : mutateUpdate, isLoading : isLoadingUpdate } = useMutation(
    ( userInfo) => updateService(userInfo),
    {
      async onSuccess(data) {
        console.log(data);
        if(data && data.data) {
          setServices(oldValue => {
            return oldValue.map(el => {
              if(el.id === data.data.id) {
                return {
                  ...el , 
                  status : data.data.status && parseInt(data.data.status ) === 1
                  
                }
              }
              return el ;
            })
          })
          
        }else { 
          
        }
      },
      onError(error) {
        
      },
    }
  );
  useEffect(()=> {
    // mutateServices({ filter : search , entreprise : entreprise , groupe : groupe ,  limit : 5 , offset : (page.index -1)*5 }) ; 
  } , []) ;

  useEffect(()=> {
    mutateServices({filter : search , entreprise : entreprise , groupe : groupe ,   limit : 5 , offset : (page.index -1)*5  }) ; 
  } , [page.index]) ;

  

  const rechercher = () => {
    mutateServices({filter : search , entreprise : entreprise , groupe : groupe ,   limit : 5 , offset : (page.index -1)*5  }) ; 
  }
  


  


  const [open, setOpen] = useState(false);
  
  const handleClose = () => {
    setOpen(false);
  };

  
  const [openAdd, setOpenAdd] = useState(false);
  const handleOpenAdd = (publireportage) => {
    setPublireportage(publireportage) ;
    setOpenAdd(true);
    console.log(publireportage);
  };
  const handleCloseAdd = () => {
    setOpenAdd(false);
  };
  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox style={{width : "100%"}}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            
          
          <MDTypography variant="h6" gutterBottom>
            Nouveau Traitement
          </MDTypography>
          {/* <MDBox display="flex" alignItems="center" lineHeight={0}>
            <Icon
              sx={{
                fontWeight: "bold",
                color: ({ palette: { info } }) => info.main,
                mt: -0.5,
              }}
            >
              handshake
            </Icon>
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;<strong>{services != null ? services.length : 0} {services != null && services.length > 1 ? "Services": "Service"}</strong> en total
            </MDTypography>
          </MDBox> */}
          
            </Grid>
          <Grid item xs={12} md={8}>
          
          <MDBox>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <MDInput style={{width : "100%"}} label="Downsample Factor" />
              </Grid>
              <Grid item xs={4}>
                <MDInput style={{width : "100%"}} label="Cuttoff"   />
              </Grid>     
              <Grid item xs={4}>
                <MDInput style={{width : "100%"}} label="Comp Ratio"  />
              </Grid>
              {/* <Grid item xs={2}>
                <MDButton color="dark" style={{maxWidth : "100%" , overflow  : "hidden"}}   onClick={rechercher}  >Filter</MDButton>
              </Grid> */}
              {/* <Grid item xs={2}>
                <MDButton color="info"   onClick={handleOpenAdd}  style={{maxWidth : "100%" , overflow  : "hidden"}}  >Ajouter</MDButton>
              </Grid> */}
            </Grid>
            </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <MDBox>
        {/* <DataTable
          table={{ columns, rows }}
          showTotalEntries={true}
          isSorted={false}
          noEndBorder
          entriesPerPage={true}
        /> */}
        <Container className="p-4">
          <div  style={{maxHeight : "65vh", overflowY : "scroll" }}>
              <Row style={{width : "100%"}} className="py-3">
                    <Col xs={12} className="d-flex justify-content-start align-items-center ">
                    <MDTypography variant="h6" color="info">
                      Uploader le signal
                    </MDTypography>
                      
                    </Col>
                    <Col xs={12} className="d-flex justify-content-center align-items-center pb-3" style={{borderBottom : "1px solid #ebebeb"}}>
                    <div style={{width : "300px"  , height : "150px" , border : "3px dashed  #1A73E8" , margin : "20px auto" , display : "flex" , justifyContent : "center" ,alignItems : "center" , cursor : "pointer" }}>
                      <Icon fontSize="large" color="info">add_a_file</Icon>
                    </div>
                      
                    </Col>
                    <Col xs={12} className="d-flex justify-content-start align-items-center  my-5" >
                    <MDTypography variant="h6" color="info">
                      Ou bien entrer l'URL du signal
                    </MDTypography>
                      
                    </Col>
                    <Col xs={12} className="d-flex justify-content-center align-items-center">
                     <MDInput style={{width : "100%"}} label="URL signal"  />
                    
                    </Col>
                    <Col xs={12} className="d-flex justify-content-center align-items-center">
                      
                      <Grid container spacing={1} className="mt-5 d-flex justify-content-end align-items-center">
                        <Grid item xs={2}>
                          <MDButton color="dark" style={{maxWidth : "100%" , overflow  : "hidden"}}   onClick={rechercher}  >Sous échantillonnage</MDButton>
                        </Grid>
                        <Grid item xs={2}>
                          <MDButton color="info"   onClick={handleOpenAdd}  style={{maxWidth : "100%" , overflow  : "hidden"}}  >moyennage </MDButton>
                        </Grid>
                      </Grid>
                      
                    </Col>

                    </Row>

            </div>
        </Container>
      </MDBox>
      <Modal
      show={open}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        Annulation d'un publireportage
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <MDBox
          mx={2}
          my={1}
          py={3}
          px={2}
          variant="gradient"
          bgColor="error"
          borderRadius="lg"
          coloredShadow="info"
        >
          <MDTypography variant="h6" color="white">
          Est-ce-que vous voulez vraiment annuler ce publireportage ?
          </MDTypography>
        </MDBox>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button onClick={handleClose}>Confirmer</Button> */}
        <MDButton color="error"  onClick={handleClose}>Confirmer</MDButton>
      </Modal.Footer>
    </Modal>

      <Modal
      show={openAdd}
      onHide={handleCloseAdd}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Ajouter un nouveau service
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{width :"100%" , display : "flex" , justifyContent : "center" , alignItems : "center"}}>
        <CardTW style={{maxWidth : "300px" , width : "100%" }}>
            <div style={{width : "300px"  , height : "300px" , border : "3px dashed  #1A73E8" , margin : "20px auto" , display : "flex" , justifyContent : "center" ,alignItems : "center" , cursor : "pointer" }}>
              <Icon fontSize="large" color="info">add_a_photo</Icon>
            </div>
            <MDInput style={{width : "100%" , marginTop : "20px"}} label="Nom du service" />
            <MDInput style={{width : "100%" , marginTop : "20px"}} label="Description du service"  multiline rows={4} />
            <MDInput style={{width : "100%" , marginTop : "20px"}} label="Lien siteweb" />
       </CardTW>

        </div>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button onClick={handleCloseAdd}>Confirmer</Button> */}
        <MDButton color="info"  onClick={handleCloseAdd}>Confirmer</MDButton>
      </Modal.Footer>
    </Modal>
    <Modal
      show={ isLoadingServices || isLoadingUpdate}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <MDBox
          mx={2}
          my={1}
          py={3}
          px={2}
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
        >
          <MDTypography variant="h6" color="white">
          {isLoadingServices ? "Loading ..." : "Updating ..."}
          </MDTypography> 
        </MDBox>
      </Modal.Body>
    </Modal>
    </Card>
  );
}

export default List;
