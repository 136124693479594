import React, { useState } from "react";

import { Button as ButtonMui } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";

import { Link as LinkScroll } from "react-scroll";
export default () => {
  const lang = useSelector((state) => state.customization.lang);
  const theme = useTheme();

  return (
    <div className="container mx-auto  px-4">
      <div className="w-100 mt-5 d-flex align-items-center justify-content-center">
        <div className="w-100">
          <div className="p-0">
            <div className="w-100">
              <h3 className="text-xl font-semibold mb-3" style={{ textAlign: "center" }}>
                {lang === "fr"
                  ? "Boostez Votre Visibilité avec Nos Espaces Publicitaires !"
                  : lang === "en"
                  ? "Boost Your Visibility with Our Advertising Spaces!"
                  : "عزز رؤيتك مع مساحاتنا الإعلانية!"}
              </h3>
              <p
                className="text-md font-light leading-relaxed mt-4 mb-4 text-blueGray-600"
                style={{ textAlign: "center", fontWeight: "400" }}
              >
                {lang === "fr"
                  ? "Vous souhaitez donner un coup de pouce à votre entreprise ?"
                  : lang === "en"
                  ? "Do you want to give your business a boost?"
                  : "هل ترغب في تعزيز نشاطك التجاري؟"}

                <br></br>
                {lang === "fr"
                  ? "Profitez de nos espaces publicitaires pour atteindre une audience ciblée et accroître votre notoriété. Nous proposons divers formats publicitaires adaptés à vos besoins : publireportages, bannières, vidéos, et bien plus encore !"
                  : lang === "en"
                  ? "Take advantage of our advertising spaces to reach a targeted audience and increase your brand awareness. We offer various advertising formats tailored to your needs: advertorials, banners, videos, and much more!"
                  : "استفد من مساحاتنا الإعلانية للوصول إلى جمهور مستهدف وزيادة وعي علامتك التجارية. نحن نقدم أشكال إعلانية متنوعة تلبي احتياجاتك: مقالات دعائية، لافتات، مقاطع فيديو، وأكثر من ذلك بكثير!"}

                <br></br>
                {lang === "fr"
                  ? "Contactez-nous dès maintenant pour découvrir nos offres et obtenir un devis personnalisé."
                  : lang === "en"
                  ? "Contact us now to discover our offers and get a personalized quote."
                  : "اتصل بنا الآن لاكتشاف عروضنا والحصول على عرض أسعار مخصص."}
              </p>
            </div>
            <div className="w-100 new_message_button d-flex justify-content-center align-items-center p-0">
              {/* <Email style={{ color: theme.palette.grey[400] }} fontSize="medium" /> */}

              <LinkScroll
                to="contactUs"
                style={{
                  ...theme.typography.commonAvatar,
                  ...theme.typography.button,
                  transition: "all .2s ease-in-out",
                  background: theme.palette.primary.light,
                  color: theme.palette.primary.dark,
                  "&:hover": {
                    background: theme.palette.primary.dark,
                    color: theme.palette.primary.light,
                  },
                  height: "100%",
                  overflow: "hidden",
                  borderRadius: "none",
                }}
                className="py-2 px-5"
              >
                {lang === "fr" ? "Contactez nous" : lang === "en" ? "Contact us" : "اتصل بنا"}
              </LinkScroll>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
