import PContact from "layoutsPub/Contact";
import PublicationList from "layoutsPub/PublicationList";
import { Icon } from "@mui/material";

const PubRoutes = [
  //pub
  {
    type: "collapse",
    name: "Publications",
    key: "pub",
    icon: <Icon fontSize="small">rss_feed</Icon>,
    route: "/pub/",
    component: <PublicationList />,
    role: "pub",
    group: "Users",
  },
  {
    type: "collapse",
    name: "Paramètres",
    key: "_pub_settings",
    icon: <Icon fontSize="small">settings</Icon>,
    route: "/pub/settings",
    component: <PContact />,
    role: "pub",
    group: "Users",
  },
];

export default PubRoutes;
