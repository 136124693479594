// entreprise components

import EntrepriseProfile from "layoutsEntreprises/ProfileEntreprise";
import EEntreprisesList from "layoutsEntreprises/EntreprisesList";
import ESecteurList from "layoutsEntreprises/SecteurList";
import EPublireportageList from "layoutsEntreprises/PublireportageList";
import EALauneList from "layoutsEntreprises/ALauneList";
import EBannieresList from "layoutsEntreprises/BannieresList";
import EContact from "layoutsEntreprises/Contact";
import EProductMessagesList from "layoutsEntreprises/ProductMessagesList";
import EAbonnementList from "layoutsEntreprises/AbonnementList";
import EProductList from "layoutsEntreprises/ServiceList";
import EServicesList from "layoutsEntreprises/ServicesList";
import EAnnonceList from "layoutsEntreprises/AnnonceList";
import ENotificationsList from "layoutsEntreprises/AnnonceList";
import EInscriptionsList from "layoutsEntreprises/InscriptionsList";
import { Icon } from "@mui/material";

import {
  IconUser,
  IconDashboard,
  IconPackage,
  IconSlideshow,
  IconPhotoStar,
  IconKey,
  IconAdjustments,
  IconClipboard,
  IconMessage,
  IconServicemark,
  IconShoppingCart,
  IconCreditCard,
  IconFileAlert,
  IconHeart,
  IconNotification,
} from "@tabler/icons";
import { IconVideo } from "@tabler/icons-react";
import NotificationsPage from "layouts/MainLayout/Header/NotificationSection/NotificationPage/index";

const EntrepriseRoutes = [
  // entreprises

  // {
  //   type: "collapse",
  //   name: "Dashboard",
  //   key: "entreprise",
  //   icon: <Icon fontSize="small">dashboard</Icon>,
  //   route: "/entreprise",
  //   component: <MDTypography ></MDTypography>,
  //   role : "entreprise" ,
  //  group: "Users",
  // },

  {
    id: "entreprise",
    type: "collapse",
    name: "Profil",
    name_en: "Profile",
    name_ar: "الحساب",
    key: "entreprise",
    icon: IconUser,
    route: "/entreprise/",
    component: <EntrepriseProfile />,
    role: "entreprise",
    group: "Profil",
    group_en: "Profile",
    group_ar: "الحساب",
  },
  // {
  //   type: "collapse",
  //   name: "Entreprises",
  //   key: "_entreprise_entreprises",
  //   icon: IconUser,
  //   route: "/entreprise/entreprises",
  //   component: <EEntreprisesList />,
  //   role : "entreprise" ,
  // group: "Users",
  // },
  // {
  //   id: "secteurs",
  //   type: "collapse",
  //   name: "Secteurs",
  //   key: "_entreprise_secteurs",
  //   icon: IconClipboard,
  //   route: "/entreprise/secteurs",
  //   component: <ESecteurList />,
  //   role: "entreprise",
  //   group: "Produits",
  // },
  {
    id: "productsfav",
    type: "collapse",
    name: "Préférées",
    name_en: "Favorite",
    name_ar: "المفضلة",
    key: "_entreprise_productsfav",
    icon: IconHeart,
    route: "/entreprise/productsfav",
    component: <EProductList fav={true} />,
    role: "entreprise",
    group: "Produits",
    group_en: "Products",
    group_ar: "المنتجات",
  },
  {
    id: "products",
    type: "collapse",
    name: "Produits",
    name_en: "Products",
    name_ar: "المنتجات",
    key: "_entreprise_products",
    icon: IconPackage,
    route: "/entreprise/products",
    component: <EProductList fav={false} />,
    role: "entreprise",
    group: "Produits",
    group_en: "Products",
    group_ar: "المنتجات",
  },
  {
    id: "productsmessages",
    type: "collapse",
    name: "Messages",
    name_en: "Messages",
    name_ar: "الرسائل",
    key: "_entreprise_productsmessages",
    icon: IconMessage,
    route: "/entreprise/productsmessages",
    component: <EProductMessagesList role="vendeur" type="produit" />,
    role: "entreprise",
    group: "Produits",
    group_en: "Products",
    group_ar: "المنتجات",
  },
  {
    id: "annoncesfav",
    type: "collapse",
    name: "Préférées",
    name_en: "Favorite",
    name_ar: "المفضلة",
    key: "_entreprise_annoncesfav",
    icon: IconHeart,
    route: "/entreprise/annoncesfav",
    component: <EAnnonceList fav={true} />,
    role: "entreprise",
    group: "Annonces",
    group_en: "Announcements",
    group_ar: "الإعلانات",
  },
  {
    id: "annonces",
    type: "collapse",
    name: "Annonces",
    name_en: "Announcements",
    name_ar: "الإعلانات",
    key: "_entreprise_annonces",
    icon: IconPackage,
    route: "/entreprise/annonces",
    component: <EAnnonceList fav={false} />,
    role: "entreprise",
    group: "Annonces",
    group_en: "Announcements",
    group_ar: "الإعلانات",
  },
  {
    id: "annoncemessages",
    type: "collapse",
    name: "Messages",
    name_en: "Messages",
    name_ar: "الرسائل",
    key: "_entreprise_annoncemessages",
    icon: IconMessage,
    route: "/entreprise/annoncemessages",
    component: <EProductMessagesList role="vendeur" type="annonce" />,
    role: "entreprise",
    group: "Annonces",
    group_en: "Announcements",
    group_ar: "الإعلانات",
  },
  {
    id: "publireportages",
    type: "collapse",
    name: "Publi-reportages",
    name_en: "Advertorials",
    name_ar: "ريبورتاج",
    key: "_entreprise_publireportages",
    icon: IconPhotoStar,
    route: "/entreprise/publireportages",
    component: <EPublireportageList />,
    role: "entreprise",
    group: "Pubs",
    group_en: "Platform Ads",
    group_ar: "إعلانات المنصة",
  },
  {
    id: "bannieres",
    type: "collapse",
    name: "Bannières",
    name_en: "Banners",
    name_ar: "لافتات",
    key: "_entreprise_bannieres",
    icon: IconSlideshow,
    route: "/entreprise/bannieres",
    component: <EBannieresList />,
    role: "entreprise",
    group: "Pubs",
    group_en: "Platform Ads",
    group_ar: "إعلانات المنصة",
  },
  {
    id: "videoalaune",
    type: "collapse",
    name: "Vidéo à la une",
    name_en: "Featured Video",
    name_ar: "فيديو مميز",
    key: "_entreprise_videoalaune",
    icon: IconVideo,
    route: "/entreprise/videoalaune",
    component: <EALauneList />,
    role: "entreprise",
    group: "Pubs",
    group_en: "Platform Ads",
    group_ar: "إعلانات المنصة",
  },
  // {
  //   id: "abonnements",
  //   type: "collapse",
  //   name: "Abonnements",
  //   key: "_entreprise_abonnements",
  //   icon: IconKey,
  //   route: "/entreprise/abonnements",
  //   component: <EInscriptionsList />,
  //   role: "entreprise",
  //   group: "Parametres",
  // },
  {
    id: "demandeservices",
    type: "collapse",
    name: "Services",
    name_en: "Services",
    name_ar: "الخدمات",
    key: "_entreprise_demandeservices",
    icon: IconCreditCard,
    route: "/entreprise/demandeservices",
    component: <EServicesList />,
    role: "entreprise",
    group: "Paramètres",
    group_en: "Settings",
    group_ar: "الإعدادات",
  },
  {
    id: "settings",
    type: "collapse",
    name: "Paramètres",
    name_en: "Settings",
    name_ar: "الإعدادات",
    key: "_entreprise_settings",
    icon: IconAdjustments,
    route: "/entreprise/settings",
    component: <EContact role="vendeur" />,
    role: "entreprise",
    group: "Paramètres",
    group_en: "Settings",
    group_ar: "الإعدادات",
  },
  {
    id: "notifications",
    type: "collapse",
    name: "Notificaions",
    name_en: "Notificaions",
    name_ar: "الإشعارات",
    key: "_entreprise_notifications",
    icon: IconNotification,
    route: "/entreprise/notifications",
    component: <NotificationsPage />,
    role: "entreprise",
    group: "Paramètres",
    group_en: "Settings",
    group_ar: "الإعدادات",
  },
];

export default EntrepriseRoutes;
