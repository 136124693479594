
import { useState } from "react";
import { css } from "styled-components/macro"; //eslint-disable-line

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";

// Data
import data from "layoutsGroupe/AbonnementList/components/List/data";
import { AppBar, Box, Grid, Switch, Tab , Tabs} from "@mui/material";
import  Button  from "react-bootstrap/Button";
import Modal from 'react-bootstrap/Modal';
import MDButton from "components/MDButton";
import tw from "twin.macro";
import styled from "styled-components";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import MDInput from "components/MDInput";
import { ReactComponent as SvgDecoratorBlob } from "images/svg-decorator-blob-6.svg";
import { Dropdown } from "react-bootstrap";

const CardPub = tw.div`h-full flex! flex-col sm:border max-w-sm  relative focus:outline-none bg-white m-0`;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full h-56 sm:h-64 bg-cover bg-center `
]);

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-2xl font-bold`;

const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4`;

const PrimaryButton = tw(PrimaryButtonBase)`mt-auto sm:text-lg rounded-none w-full  py-3 sm:py-6 border-none`;

const highlightGradientsCss = [
  css`
    background: rgb(56, 178, 172);
    background: linear-gradient(115deg, rgba(56, 178, 172, 1) 0%, rgba(129, 230, 217, 1) 100%);
  `,
  css`
    background: rgb(56, 178, 172);
    background-image: linear-gradient(115deg, #6415ff, #7431ff, #8244ff, #8e56ff, #9a66ff);
  `,
  css`
    background: rgb(245, 101, 101);
    background: linear-gradient(115deg, rgba(245, 101, 101, 1) 0%, rgba(254, 178, 178, 1) 100%);
  `,
];

const PlansContainer = tw.div` lg:items-stretch relative`;
const Plan = styled.div`
  ${tw`w-full max-w-sm mt-16 lg:mr-8 lg:last:mr-0 text-center px-8 rounded-lg shadow relative pt-2 text-gray-900 bg-white flex flex-col`}
  .planHighlight {
    ${tw`rounded-t-lg absolute top-0 inset-x-0 h-2`}
  }

  ${(props) =>
    props.featured &&
    css`
      background: rgb(100,21,255);
      background: linear-gradient(135deg, rgba(100,21,255,1) 0%, rgba(128,64,252,1) 100%);
background: rgb(85,60,154);
background: linear-gradient(135deg, rgba(85,60,154,1) 0%, rgba(128,90,213,1) 100%);
background: rgb(76,81,191);
background: linear-gradient(135deg, rgba(76,81,191,1) 0%, rgba(102,126,234,1) 100%);
      ${tw`bg-primary-500 text-gray-100`}
      .planHighlight {
        ${tw`hidden`}
      }
      .duration {
        ${tw`text-gray-200!`}
      }
      ${PlanFeatures} {
        ${tw`border-indigo-500`}
      }
      .feature:not(.mainFeature) {
        ${tw`text-gray-300!`}
      }
      ${BuyNowButton} {
        ${tw`bg-gray-100 text-primary-500 hocus:bg-gray-300 hocus:text-primary-800 border-none`}
    `}
`;

const PlanHeader = styled.div`
  ${tw`flex flex-col uppercase leading-relaxed py-8`}
  .name {
    ${tw`font-bold text-xl`}
  }
  .price {
    ${tw`font-bold text-4xl sm:text-5xl my-1`}
  }
  .duration {
    ${tw`text-gray-500 font-bold tracking-widest`}
  }
`;
const PlanFeatures = styled.div`
  ${tw`flex flex-col -mx-8 px-8 py-8 border-t-2 border-b-2 flex-1`}
  .feature {
    ${tw`mt-5 first:mt-0 font-medium`}
    &:not(.mainFeature) {
      ${tw`text-gray-600`}
    }
  }
  .mainFeature {
    ${tw`text-xl font-bold tracking-wide`}
  }
`;

const PlanAction = tw.div`px-4 sm:px-8 xl:px-16 py-8`;
const BuyNowButton = styled(PrimaryButtonBase)`
  ${tw`rounded-full uppercase tracking-wider py-4 w-full text-sm hover:shadow-xl transform hocus:translate-x-px hocus:-translate-y-px focus:shadow-outline border-none`}
`;

const DecoratorBlob = styled(SvgDecoratorBlob)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-25 transform -translate-x-1/2 translate-y-1/2`}
`;

function List() {
  const [menu, setMenu] = useState(null);
  const [publireportage, setPublireportage] = useState(null);
  const [featured, setFeatured] = useState(false);
  const [forGroup, setForGroup] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [tabAbonnemntsValue, setTabAbonnementValue] = useState(0);
  const [items, setItems] = useState([
    {
      name: "Groupe",
      price: "6000 DZD",
      duration: "Mensuelle",
      mainFeature: "Grandes entreprises - groupes",
      features: ["5 Comptes", "Multiple Secteurs", "Multiples Services"],
      comptes : 5 ,  
      secteurs : 0 , 
      services : 0 , 
      featured: true,
      activated : true ,
      creation_date : "30/04/2023" , 
      end_date : "30/05/2023" , 
      archivated : false 
    },
  ]);

  const [itemsAchat, setItemsAchat] = useState([
    // {
    //   name: "Basique",
    //   price: "1000 DZD",
    //   duration: "Mensuelle",
    //   mainFeature: "Basique Abonnement",
    //   features: ["1 Compte", "1 Secteur", "1 Services"],
    //   comptes : 1 ,  
    //   secteurs : 1 , 
    //   services : 1 , 
    //   activated : true ,
    //   creation_date : "30/04/2023" , 
    //   archivated : false 
    // },
    // {
    //   name: "Entreprise",
    //   price: "3000 DZD",
    //   duration: "Mensuelle",
    //   mainFeature: "Entreprises simples",
    //   features: ["1 Compte", "2 Secteur", "10 Services"],
    //   comptes : 1 ,  
    //   secteurs : 2 , 
    //   services : 10 , 
    //   activated : true ,
    //   creation_date : "30/04/2023" , 
    //   archivated : false 
    // },
    {
      name: "Groupe",
      price: "6000 DZD",
      duration: "Mensuelle",
      mainFeature: "Grandes entreprises - groupes",
      features: ["5 Comptes", "Multiple Secteurs", "Multiples Services"],
      comptes : 5 ,  
      secteurs : 0 , 
      services : 0 , 
      featured: true,
      activated : true ,
      creation_date : "30/04/2023" , 
      archivated : false 
    },
  ]);


  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);

  
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  
  const [openAdd, setOpenAdd] = useState(false);
  const handleOpenAdd = (publireportage) => {
    setPublireportage(publireportage) ;
    setOpenAdd(true);
    console.log(publireportage);
  };
  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);
  const handleSetTabAbonnmenetValue = (event, newValue) => setTabAbonnementValue(newValue);
  const { columns, rows } = data({handleOpen , handleOpenAdd , items : items});

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={closeMenu}>Action</MenuItem>
      <MenuItem onClick={closeMenu}>Another action</MenuItem>
      <MenuItem onClick={closeMenu}>Something else</MenuItem>
    </Menu>
  );

  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox style={{width : "100%"}}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            
          
          <MDTypography variant="h6" gutterBottom>
            Liste des abonnements
          </MDTypography>
          <MDBox display="flex" alignItems="center" lineHeight={0}>
            <Icon
              sx={{
                fontWeight: "bold",
                color: ({ palette: { info } }) => info.main,
                mt: -0.5,
              }}
            >
              card_membership
            </Icon>
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;<strong>{items != null ? items.length : 0} {items != null && items.length > 1 ? "Abonnements": "Abonnement"}</strong> en total
            </MDTypography>
          </MDBox>
          
            </Grid>
          <Grid item xs={12} md={6}>
          
          <MDBox>
            <Grid container spacing={3}>
              <Grid item xs={12} sx={{ ml: "auto" }}  className="d-flex align-items-end justify-content-end">
                <AppBar position="static">
                  <Tabs  orientation={"horizontal"} value={tabAbonnemntsValue} onChange={handleSetTabAbonnmenetValue}>
                    <Tab
                      label="Tous"
                    />
                    <Tab
                      label="Acheter"
                    />
                    </Tabs>
                </AppBar>

              </Grid>
            </Grid>
            </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <MDBox>
        {tabAbonnemntsValue === 0 && 
          <DataTable
            table={{ columns, rows }}
            showTotalEntries={true}
            isSorted={false}
            noEndBorder
            entriesPerPage={true}
          />
        }
        {tabAbonnemntsValue === 1 && 
         <PlansContainer style={{padding : "50px" , paddingTop : "0px"}} className="row justify-content-center">
            {itemsAchat.map((plan, index) => (
              <Plan key={index} featured={plan.featured} className="col-12 col-md-4">
                {!plan.featured && (
                  <div
                    className="planHighlight"
                    css={highlightGradientsCss[index % highlightGradientsCss.length]}
                  />
                )}
                <div className="w-100 d-flex justify-content-end align-items-end pt-2">
                
                </div>
                <PlanHeader>
                  <span className="name">{plan.name}</span>
                  <span className="price">{plan.price}</span>
                  <span className="duration">{plan.duration}</span>
                </PlanHeader>
                <PlanFeatures>
                  <span className="feature mainFeature">{plan.mainFeature}</span>
                  {plan.features.map((feature, index) => (
                    <span key={index} className="feature">
                      {feature}
                    </span>
                  ))}
                </PlanFeatures>
                <PlanAction>
                  <BuyNowButton
                    onClick={() => {
                      // navigate(`/signup/${index}`);
                    }}
                    css={!plan.featured && highlightGradientsCss[index]}
                  >
                    Acheter
                  </BuyNowButton>
                </PlanAction>
              </Plan>
            ))}
            <DecoratorBlob />
          </PlansContainer>
        }
      </MDBox>
      <Modal
      show={open}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        Annulation d'un publireportage
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <MDBox
          mx={2}
          my={1}
          py={3}
          px={2}
          variant="gradient"
          bgColor="error"
          borderRadius="lg"
          coloredShadow="info"
        >
          <MDTypography variant="h6" color="white">
          Est-ce-que vous voulez vraiment annuler ce publireportage ?
          </MDTypography>
        </MDBox>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button onClick={handleClose}>Confirmer</Button> */}
        <MDButton color="error"  onClick={handleClose}>Confirmer</MDButton>
      </Modal.Footer>
    </Modal>

      <Modal
      show={openAdd}
      onHide={handleCloseAdd}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Nouvel abonnement
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{width :"100%" , display : "flex" , justifyContent : "center" , alignItems : "center"}}>
        <CardPub style={{width : "100%" }}>
          <MDInput style={{width : "100%"}} className="my-3" label="Nom de l'abonnement" />
          <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
            <MDBox mt={0.5}>
              <Switch checked={forGroup} onChange={() => setForGroup(!forGroup)} />
            </MDBox>
            <MDBox width="80%" ml={0.5}>
              <MDTypography variant="button" fontWeight="regular" color="text">
                  Pour un Groupe ?
              </MDTypography>
            </MDBox>
          </MDBox>
          {forGroup && 
            <MDInput style={{width : "100%"}} className="my-3" label="Nombre des entreprises" type="number" />
          }
          <MDInput style={{width : "100%"}} className="my-3" label="Nombre des secteurs" type="number" />
          <MDInput style={{width : "100%"}} className="my-3" label="Nombre des services" type="number" />
          <MDInput style={{width : "100%"}} className="my-3" label="Nombre des publireportage" type="number" />
          <MDInput style={{width : "100%"}} className="my-3" label="Prix" type="number" />
          
            <Grid container spacing={3} alignItems="center" >
              <Grid item xs={12} sx={{ ml: "auto" }}>
                <AppBar position="static">
                  <Tabs  orientation={"horizontal"} value={tabValue} onChange={handleSetTabValue}>
                    <Tab
                      label="Mensuelle"
                    />
                    <Tab
                      label="Trimestrielle"
                    />
                    <Tab
                      label="Semestrielle"
                    />
                    <Tab
                      label="Annuelle"
                    />
                  </Tabs>
                </AppBar>

              </Grid>
            </Grid>
            <MDInput style={{width : "100%"}} className="my-3" label="Description" type="text" />
            
            <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
              <MDBox mt={0.5}>
                <Switch checked={featured} onChange={() => setFeatured(!featured)} />
              </MDBox>
              <MDBox width="80%" ml={0.5}>
                <MDTypography variant="button" fontWeight="regular" color="text">
                    l'abonnement le plus important
                </MDTypography>
              </MDBox>
            </MDBox>
            {/* <MDBox py={1}  style={{boxShadow : "#d9d9d9 0px 0px 2px 1px" , padding :"20px" , borderRadius : "20px"}}>
              <MDBox>
                <Grid container spacing={3}>
                  <Grid item xs={12} >
                    <MDInput style={{width : "100%"}} className="my-3" label="Nom de la fonctionnalité" type="text" />
                    <div className="w-100 d-flex justify-content-center">
                    <MDButton color="dark"  onClick={()=>{}}>Ajouter une fonctionnalité</MDButton>            

                    </div>
                  </Grid>
                </Grid>
              </MDBox>
            </MDBox> */}
            
            
          
       </CardPub>

        </div>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button onClick={handleCloseAdd}>Confirmer</Button> */}
        <MDButton color="info"  onClick={handleCloseAdd}>Confirmer</MDButton>
      </Modal.Footer>
    </Modal>
    </Card>
  );
}

export default List;
