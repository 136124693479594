import { authApi } from "./server";

export const getEntrepriseALaUne = async () => {
  const response = await authApi.get("entreprises/aLaUneEntreprises", {});
  return response.data;
};

export const getInfos = async (id) => {
  const response = await authApi.get("infos/" + id);
  return response.data;
};

export const getGroupePage = async (id) => {
  const response = await authApi.get("groupes/page/" + id);
  return response.data;
};

export const getEntreprisePage = async (id) => {
  const response = await authApi.get("entreprises/page/" + id);
  return response.data;
};
export const getPublireportagePage = async (id) => {
  const response = await authApi.get("pubs/page/" + id);
  return response.data;
};

export const getServicePage = async (id) => {
  const response = await authApi.get("services/page/" + id);
  return response.data;
};

export const getAnnoncePage = async (id) => {
  const response = await authApi.get("annonces/page/" + id);
  return response.data;
};

export const getAbonnement = async () => {
  const response = await authApi.get("abonnements/home");
  return response.data;
};

export const getHomeData = async () => {
  const response = await authApi.post("homeData");
  return response.data;
};

export const contactForm = async (formInfos) => {
  const response = await authApi.post("sendContactMessage", formInfos);
  return response.data;
};

export const demandeInscription = async (userInfo) => {
  console.log(userInfo);

  const formData = new FormData();
  formData.append("nom", userInfo.nom);
  formData.append("email", userInfo.email);
  formData.append("password", userInfo.password);
  formData.append("password2", userInfo.password2);
  formData.append("type", userInfo.type);

  const response = await authApi.post("inscrptionDemande", formData);
  return response.data;
};

export const demandeOption = async (userInfo) => {
  console.log(userInfo);

  const formData = new FormData();
  formData.append("image", userInfo.file);
  formData.append("abonnement_id", userInfo.abonnement_id);
  formData.append("nom", userInfo.nom);
  formData.append("email", userInfo.email);
  formData.append("phone", userInfo.phone);
  formData.append("message", userInfo.message);
  formData.append("alreadyUser", userInfo.alreadyUser);

  const response = await authApi.post("optionDemande", formData);
  return response.data;
};

export const demandePersonnalisees = async (userInfo) => {
  console.log(userInfo);

  const formData = new FormData();
  formData.append("image", userInfo.file);
  formData.append("nom", userInfo.nom);
  formData.append("email", userInfo.email);
  formData.append("phone", userInfo.phone);
  formData.append("whatsapp", userInfo.whatsapp);
  formData.append("message", userInfo.message);
  formData.append("alreadyUser", userInfo.alreadyUser);

  const response = await authApi.post("demandesPersonnalisees", formData);
  return response.data;
};

export const demandePartenaires = async (userInfo) => {
  console.log(userInfo);

  const formData = new FormData();
  formData.append("image", userInfo.file);
  formData.append("nom", userInfo.nom);
  formData.append("email", userInfo.email);
  formData.append("phone", userInfo.phone);
  formData.append("whatsapp", userInfo.whatsapp);
  formData.append("message", userInfo.message);
  formData.append("alreadyUser", userInfo.alreadyUser);

  const response = await authApi.post("demandePartenaires", formData);
  return response.data;
};

export const demandePub = async (userInfo) => {
  console.log(userInfo);

  const formData = new FormData();
  formData.append("nom", userInfo.nom);
  formData.append("email", userInfo.email);
  formData.append("phone", userInfo.phone);
  formData.append("message", userInfo.message);
  formData.append("alreadyUser", userInfo.alreadyUser);

  const response = await authApi.post("pubDemande", formData);
  return response.data;
};

// Fonction pour détecter les anciens appareils iOS
export const detectOldiOSDevices = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Vérifier si l'appareil est un iPhone ou un iPad
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    const iOSVersion = userAgent.match(/OS (\d+)_/i);
    if (iOSVersion && iOSVersion.length > 1) {
      const majorVersion = parseInt(iOSVersion[1], 10);
      if (majorVersion < 11) {
        return true;
      }
    }
  }
  return false;
};
