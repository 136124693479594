// assets
import { IconDashboard } from "@tabler/icons";

// constant
const icons = { IconDashboard };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const adminDemandes = {
  id: "adminDemandes",
  title: "Demandes",
  type: "group",
  children: [
    {
      id: "inscriptions",
      title: "Inscriptions",
      type: "item",
      url: "/admin/inscriptions",
      icon: icons.IconDashboard,
      breadcrumbs: false,
    },
    {
      id: "adminDemandesPubs",
      title: "Pubs",
      type: "item",
      url: "/admin/demandepub",
      icon: icons.IconDashboard,
      breadcrumbs: false,
    },
    {
      id: "adminDemandesOptions",
      title: "Options",
      type: "item",
      url: "/admin/demandeoption",
      icon: icons.IconDashboard,
      breadcrumbs: false,
    },
  ],
};

export default adminDemandes;
