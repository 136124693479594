// entreprise components

import EntrepriseProfile from "layoutsEntreprises/ProfileEntreprise";
import EEntreprisesList from "layoutsEntreprises/EntreprisesList";
import ESecteurList from "layoutsEntreprises/SecteurList";
import EPublireportageList from "layoutsEntreprises/PublireportageList";
import EALauneList from "layoutsEntreprises/ALauneList";
import EBannieresList from "layoutsEntreprises/BannieresList";
import EContact from "layoutsEntreprises/Contact";
import EProductMessagesList from "layoutsEntreprises/ProductMessagesList";
import EAbonnementList from "layoutsEntreprises/AbonnementList";
import EProductList from "layoutsEntreprises/ServiceList";
import EServicesList from "layoutsEntreprises/ServicesList";
import EAnnonceList from "layoutsEntreprises/AnnonceList";
import EInscriptionsList from "layoutsEntreprises/InscriptionsList";
import { Icon } from "@mui/material";

import {
  IconUser,
  IconDashboard,
  IconPackage,
  IconSlideshow,
  IconPhotoStar,
  IconKey,
  IconAdjustments,
  IconClipboard,
  IconMessage,
  IconServicemark,
  IconShoppingCart,
  IconCreditCard,
  IconFileAlert,
  IconHeart,
} from "@tabler/icons";
import { IconVideo } from "@tabler/icons-react";

const AcheteurRoutes = [
  {
    id: "acheteur",
    type: "collapse",
    name: "Profil",
    name_en: "Profile",
    name_ar: "الحساب",
    key: "acheteur",
    icon: IconUser,
    route: "/acheteur/",
    component: <EntrepriseProfile />,
    role: "acheteur",
    group: "Profil",
    group_en: "Profile",
    group_ar: "الحساب",
  },
  {
    id: "annoncesfav",
    type: "collapse",
    name: "Préférées",
    name_en: "Announcements",
    name_ar: "المفضلة",
    key: "_acheteur_annoncesfav",
    icon: IconHeart,
    route: "/acheteur/annoncesfav",
    component: <EAnnonceList fav={true} />,
    role: "acheteur",
    group: "Annonces",
    group_en: "Announcements",
    group_ar: "الإعلانات",
  },
  {
    id: "annonces",
    type: "collapse",
    name: "Annonces",
    name_en: "Announcements",
    name_ar: "الإعلانات",
    key: "_acheteur_annonces",
    icon: IconPackage,
    route: "/acheteur/annonces",
    component: <EAnnonceList fav={false} />,
    role: "acheteur",
    group: "Annonces",
    group_en: "Announcements",
    group_ar: "الإعلانات",
  },
  {
    id: "annoncesmessages",
    type: "collapse",
    name: "Messages",
    name_en: "Messages",
    name_ar: "الرسائل",
    key: "_acheteur_annoncesmessages",
    icon: IconMessage,
    route: "/acheteur/annoncesmessages",
    component: <EProductMessagesList role="acheteur" type="annonce" />,
    role: "acheteur",
    group: "Annonces",
    group_en: "Announcements",
    group_ar: "الإعلانات",
  },
  {
    id: "products",
    type: "collapse",
    name: "Produits",
    name_en: "Products",
    name_ar: "المنتجات",
    key: "_acheteur_products",
    icon: IconHeart,
    route: "/acheteur/products",
    component: <EProductList fav={true} />,
    role: "acheteur",
    group: "Products",
    group_en: "Products",
    group_ar: "المنتجات",
  },
  {
    id: "productmessages",
    type: "collapse",
    name: "Messages",
    name_en: "Messages",
    name_ar: "الرسائل",
    key: "_acheteur_productmessages",
    icon: IconMessage,
    route: "/acheteur/productmessages",
    component: <EProductMessagesList role="acheteur" type="produit" />,
    role: "acheteur",
    group: "Products",
    group_en: "Products",
    group_ar: "المنتجات",
  },
  {
    id: "settings",
    type: "collapse",
    name: "Paramètres",
    name_en: "Settings",
    name_ar: "الإعدادات",
    key: "_acheteur_settings",
    icon: IconAdjustments,
    route: "/acheteur/settings",
    component: <EContact role="acheteur" />,
    role: "acheteur",
    group: "Paramètres",
    group_en: "Settings",
    group_ar: "الإعدادات",
  },
];

export default AcheteurRoutes;
