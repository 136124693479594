import { authApi } from "./server";
import micro_abonnement_image from "assets/images/micro_abonnement.png";
import junior_abonnement_image from "assets/images/junior_abonnement.png";
import confort_abonnement_image from "assets/images/confort_abonnement.png";
import groupe_abonnement_image from "assets/images/groupe_abonnement1.png";
import publicitaire_abonnement_image from "assets/images/publicitaire_abonnement.png";

export const banniereSpaces = [
  { id: 1, name: "Page d'acceuil - Espace 1" },
  { id: 2, name: "Page d'acceuil - Espace 2" },
  { id: 3, name: "Page Service - Espace 1" },
  { id: 4, name: "Page Service - Espace 2" },
  // {id : 5 , name : "Page Entreprise"} ,
  // {id : 6 , name : "Page Groupe"} ,
];

export const getAdminIndicators = async () => {
  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.get("admin/indicators");
  return response.data;
};

export const getWaitingPublireportages = async () => {
  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.get("admin/waitingPubs");
  return response.data;
};

export const getALaUneEntreprises = async () => {
  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.get("entreprises/aLaUneEntreprises");
  return response.data;
};

export const getALaUneEntreprisesList = async () => {
  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.get("entreprises/aLaUneEntreprisesList");
  return response.data;
};

export const defineEntrepriseALaUne = async (userInfos) => {
  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("admin/defineEntrepriseALaUne", userInfos);
  return response.data;
};

export const deleteEntrepriseALaUne = async (userInfos) => {
  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("admin/deleteEntrepriseALaUne", userInfos);
  return response.data;
};

export const createAbonnement = async (userInfos) => {
  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("abonnements", userInfos);
  return response.data;
};

export const getAbonnement = async () => {
  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.get("abonnements");
  return response.data;
};

export const getAnnonces = async (userInfo) => {
  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("annonces", userInfo);
  return response.data;
};

export const getAnnoncesFav = async (userInfo) => {
  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("annonces/getFavList", userInfo);
  return response.data;
};

export const addAnnonceFav = async (userInfo) => {
  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("annonces/addFav", userInfo);
  return response.data;
};

export const deleteAnnonceFav = async (userInfo) => {
  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("annonces/deleteFav", userInfo);
  return response.data;
};

export const updateAnnonce = async (userinfo) => {
  let id = userinfo.id;
  delete userinfo.id;
  console.log(id);
  console.log(userinfo);
  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("annonces/update/" + id, userinfo);
  return response.data;
};

export const getServices = async (userInfo) => {
  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("services", userInfo);
  return response.data;
};

export const getServicesFav = async (userInfo) => {
  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("services/getFavList", userInfo);
  return response.data;
};

export const addServiceFav = async (userInfo) => {
  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("services/addFav", userInfo);
  return response.data;
};

export const deleteServiceFav = async (userInfo) => {
  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("services/deleteFav", userInfo);
  return response.data;
};

export const updateService = async (userinfo) => {
  let id = userinfo.id;
  delete userinfo.id;
  console.log(id);
  console.log(userinfo);
  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("services/update/" + id, userinfo);
  return response.data;
};

export const uploaderAnnonce = async (userInfo) => {
  console.log(userInfo);
  const formData = new FormData();
  formData.append("titre", userInfo.titre);
  formData.append("description", userInfo.description);
  formData.append("titre_en", userInfo.titre_en);
  formData.append("description_en", userInfo.description_en);
  formData.append("titre_ar", userInfo.titre_ar);
  formData.append("description_ar", userInfo.description_ar);

  formData.append("status", "1");

  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("annonces/create", formData);
  return response.data;
};

export const uploaderService = async (userInfo) => {
  console.log(userInfo);
  const formData = new FormData();
  formData.append("image", userInfo.file);
  formData.append("video", userInfo.video);
  formData.append("videoLink", userInfo.videoLink);
  formData.append("titre", userInfo.nom);
  formData.append("description", userInfo.description);
  formData.append("titre_ar", userInfo.titre_ar);
  formData.append("description_ar", userInfo.description_ar);
  formData.append("titre_en", userInfo.titre_en);
  formData.append("description_en", userInfo.description_en);
  formData.append("secteur_id", userInfo.secteur_id);
  if (userInfo.forFiliale) {
    formData.append("entreprise_id", userInfo.entreprise_id);
  } else {
    let groupe_id = localStorage.getItem("groupe_id");
    if (groupe_id) {
      groupe_id = parseInt(groupe_id);
    }
    formData.append("groupe_id", groupe_id);
  }
  formData.append("status", "1");

  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("services/create", formData);
  return response.data;
};

export const getSecteurs = async (userInfo) => {
  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("secteurs", userInfo);
  return response.data;
};

export const uploaderSercteur = async (userInfo) => {
  console.log(userInfo);
  const formData = new FormData();
  formData.append("image", userInfo.file);
  formData.append("nom", userInfo.nom);
  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("secteurs/create", formData);
  return response.data;
};

export const updateSecteur = async (userinfo) => {
  let id = userinfo.id;
  delete userinfo.id;

  let toUpload = {};

  if (userinfo.file) {
    const formData = new FormData();
    formData.append("image", userinfo.file);
    formData.append("nom", userinfo.nom);
    toUpload = formData;
  } else {
    toUpload = userinfo;
  }

  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("secteurs/update/" + id, toUpload);
  return response.data;
};

export const activateSecteur = async (userinfo) => {
  let id = userinfo.id;
  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("secteurs/activate/" + id, {});
  return response.data;
};

export const desactivateSecteur = async (userinfo) => {
  let id = userinfo.id;
  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("secteurs/desactivate/" + id, {});
  return response.data;
};

export const getPartenaires = async (userInfo) => {
  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("partenaires", userInfo);
  return response.data;
};

export const uploaderPartenaire = async (userInfo) => {
  console.log(userInfo);
  const formData = new FormData();
  formData.append("image", userInfo.file);
  formData.append("nom", userInfo.nom);
  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("partenaires/create", formData);
  return response.data;
};

export const updatePartenaire = async (userinfo) => {
  let id = userinfo.id;
  delete userinfo.id;

  let toUpload = {};

  if (userinfo.file) {
    const formData = new FormData();
    formData.append("image", userinfo.file);
    formData.append("nom", userinfo.nom);
    toUpload = formData;
  } else {
    toUpload = userinfo;
  }

  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("partenaires/update/" + id, toUpload);
  return response.data;
};

export const getInfos = async () => {
  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  let id = localStorage.getItem("id") !== null ? localStorage.getItem("id") : null;
  const response = await authApi.get("infos/" + id, {});
  return response.data;
};

export const updateUserInfos = async (userinfo) => {
  let id = localStorage.getItem("id");

  if (id) {
    authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    const response = await authApi.post("infos/update/" + id, userinfo);
    return response.data;
  } else {
    return {
      success: false,
    };
  }
};

export const updateUserEmail = async (userinfo) => {
  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("users/updateEmail", userinfo);
  return response.data;
};

export const updateUserPassword = async (userinfo) => {
  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("users/updatePassword", userinfo);
  return response.data;
};

export const getGroupes = async (userInfo) => {
  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("groupes", userInfo);
  return response.data;
};

export const uploaderGroupe = async (userInfo) => {
  console.log(userInfo);
  const formData = new FormData();
  formData.append("image", userInfo.file);
  formData.append("nom", userInfo.nom);
  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("groupes/create", formData);
  return response.data;
};

export const updateGroupe = async (userinfo) => {
  let id = userinfo.id;
  delete userinfo.id;

  let toUpload = {};

  if (userinfo.profile_pic) {
    const formData = new FormData();
    formData.append("profile_pic", userinfo.profile_pic);
    toUpload = formData;
  } else if (userinfo.cover_pic) {
    const formData = new FormData();
    formData.append("cover_pic", userinfo.cover_pic);
    toUpload = formData;
  } else {
    toUpload = userinfo;
  }

  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("groupes/update/" + id, toUpload);
  return response.data;
};

export const getEntreprises = async (userInfo) => {
  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("entreprises", userInfo);
  return response.data;
};

export const uploaderEntreprise = async (userInfo) => {
  console.log(userInfo);
  const formData = new FormData();
  formData.append("image", userInfo.file);
  formData.append("nom", userInfo.nom);
  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("entreprises/create", formData);
  return response.data;
};

export const updateEntreprise = async (userinfo) => {
  let id = userinfo.id;
  delete userinfo.id;

  let toUpload = {};

  if (userinfo.file) {
    const formData = new FormData();
    formData.append("image", userinfo.file);
    formData.append("nom", userinfo.nom);
    toUpload = formData;
  } else {
    toUpload = userinfo;
  }

  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("entreprises/update/" + id, toUpload);
  return response.data;
};

export const getPublireportages = async (userInfo) => {
  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("pubs", userInfo);
  return response.data;
};

export const uploaderPublireportage = async (userInfo) => {
  console.log(userInfo);
  const formData = new FormData();
  formData.append("image", userInfo.file);
  formData.append("titre", userInfo.nom);
  formData.append("contenu", userInfo.description);
  formData.append("demande_id", userInfo.demande_id);
  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("pubs/create", formData);
  return response.data;
};

export const updatePublireportage = async (userinfo) => {
  console.log("service");
  console.log(userinfo);
  let id = userinfo.id;
  delete userinfo.id;

  let toUpload = {};

  if (userinfo.file) {
    const formData = new FormData();
    formData.append("image", userinfo.file);
    formData.append("nom", userinfo.nom);
    toUpload = formData;
  } else {
    toUpload = userinfo;
  }

  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("pubs/update/" + id, toUpload);
  return response.data;
};

export const getBannieres = async (userInfo) => {
  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("bannieres", userInfo);
  return response.data;
};

export const uploaderBanniere = async (userInfo) => {
  console.log(userInfo);
  const formData = new FormData();
  formData.append("image", userInfo.file);
  formData.append("demande_id", userInfo.demande_id);
  formData.append("espace_id", userInfo.espace_id);
  formData.append("link", userInfo.link);
  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("bannieres/create", formData);
  return response.data;
};

export const updateBanniere = async (userinfo) => {
  console.log("service");
  console.log(userinfo);

  let id = userinfo.id;
  delete userinfo.id;

  let toUpload = {};

  if (userinfo.file) {
    const formData = new FormData();
    formData.append("image", userinfo.file);
    toUpload = formData;
  } else {
    toUpload = userinfo;
  }

  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("bannieres/update/" + id, toUpload);
  return response.data;
};

export const updateAbonnement = async (userinfo) => {
  let id = userinfo.id;
  delete userinfo.id;

  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("abonnements/update/" + id, userinfo);
  return response.data;
};

export const getDemandeInscriptions = async (userInfo) => {
  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("inscription_demandes", userInfo);
  return response.data;
};

export const getDemandePubs = async (userInfo) => {
  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("pub_demandes", userInfo);
  return response.data;
};
export const getDemandeOptions = async (userInfo) => {
  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("option_demandes", userInfo);
  return response.data;
};

export const getDemandePartenaires = async (userInfo) => {
  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("partenaires_demandes", userInfo);
  return response.data;
};

export const getDemandePersonnalisees = async (userInfo) => {
  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("personnalisees_demandes", userInfo);
  return response.data;
};

export const updateDemandeInscription = async (userinfo) => {
  let id = userinfo.id;
  delete userinfo.id;

  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("inscription_demandes/update/" + id, userinfo);
  return response.data;
};

export const updateDemandeOption = async (userinfo) => {
  let id = userinfo.id;
  delete userinfo.id;

  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("option_demandes/update/" + id, userinfo);
  return response.data;
};

export const updateDemandePub = async (userinfo) => {
  let id = userinfo.id;
  delete userinfo.id;

  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("pub_demandes/update/" + id, userinfo);
  return response.data;
};

export const getTranslatedTextes = async (userInfo) => {
  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("translate/new", userInfo);
  return response.data;
};

export const getUsers = async (userInfo) => {
  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("users", userInfo);
  return response.data;
};

export const updateUser = async (userInfo) => {
  let id = userInfo.id;
  delete userInfo.id;
  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("users/update/" + id, userInfo);
  return response.data;
};

export const getAdminDashboard = async () => {
  authApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  const response = await authApi.post("users/indexAdminDashboard", {});
  return response.data;
};

export const inscriptionAbonnementsList = [
  {
    id: 1,
    image: micro_abonnement_image,
    name: "ABONNEMENT MICRO",
    price: 35000,
    duration: "MENSUEL",
    featured: false,
    mainFeature: "",
    note: "",
    features: ["Adhesion libre aux secteurs de la plate-forme", "Consultation libre des profils"],
    comptes: 1,
    secteurs: 100,
    services: 100,
    mensuelle: 1,
    trimestrielle: 0,
    semestrielle: 0,
    anuelle: 0,
    activated: true,
    archivated: false,
    status: 1,
    linearColor: "linear-gradient(115deg, rgb(95 15 13) 0%, rgb(179 181 182) 100%)",
    created_at: "2023-05-27 22:40:39",
    updated_at: "2023-06-04 05:08:47",
  },
  {
    id: 2,
    image: junior_abonnement_image,
    name: "ABONNEMENT JUNIOR",
    price: 25000,
    duration: "MENSUEL",
    featured: false,
    mainFeature: "ACCES DE BIENVENUE",
    note: "",
    features: [
      "Adhesion libre aux secteurs de la plate-forme",
      "Consultation libre des profils",
      "Veille juritique",
      "Conventions FISCALES",
      "Conventions DOUANIERES",
      "Les normes TECHNIQUES et ADMINISTRATIVES ALGERIENNES",
      "Acces aux documents LEGISLATIFS et REGLEMENTAIRES du MARCHE ALGERIEN",
    ],
    comptes: 1,
    secteurs: 100,
    services: 100,
    mensuelle: 1,
    trimestrielle: 0,
    semestrielle: 0,
    anuelle: 0,
    activated: true,
    archivated: false,
    status: 1,
    linearColor: "linear-gradient(115deg, rgb(232 232 232) 0%, rgb(179, 181, 182) 100%)",
    created_at: "2023-05-27 22:40:39",
    updated_at: "2023-06-04 05:08:47",
  },
  {
    id: 3,
    image: confort_abonnement_image,
    name: "ABONNEMENT CONFORT",
    price: 130000,
    duration: "MENSUEL",
    featured: false,
    mainFeature: "",
    note: "FORMULEZ UNE DEMANDE POUR LES OPTIONS",
    features: [
      "Adhesion libre aux secteurs de la plate-forme",
      "Consultation libre des profils",
      "Option notification OPPORTUNITE",
    ],
    comptes: 1,
    secteurs: 100,
    services: 100,
    mensuelle: 1,
    trimestrielle: 0,
    semestrielle: 0,
    anuelle: 0,
    activated: true,
    archivated: false,
    status: 1,
    linearColor: "linear-gradient(115deg, rgb(195 146 61) 0%, rgb(239 215 129) 100%)",
    created_at: "2023-05-27 22:40:39",
    updated_at: "2023-06-04 05:08:47",
  },
  {
    id: 4,
    image: groupe_abonnement_image,
    name: "ABONNEMENT GROUPE",
    price: 250000,
    duration: "MENSUEL",
    featured: false,
    mainFeature: "",
    note: "FORMULEZ UNE DEMANDE POUR LES OPTIONS",
    features: [
      "Adhesion libre aux secteurs de la plate-forme",
      "Consultation libre des profils",
      "Option notification OPPORTUNITE",
      "UN PUBLIREPORTAGE OFFERT POUR 3 JOURS DE DIFFUSION (option reservée que pour la maison mère, Fourniture et realisation par vos soins )",
      "Adhesion des filiales du groupe avec demande , -20% pour chaque abonnement sur la base de (abonnement confort sans option de notifications)",
    ],
    comptes: 1,
    secteurs: 100,
    services: 100,
    mensuelle: 1,
    trimestrielle: 0,
    semestrielle: 0,
    anuelle: 0,
    activated: true,
    archivated: false,
    status: 1,
    linearColor: "linear-gradient(115deg, rgb(195 146 61) 0%, rgb(239 215 129) 100%)",
    created_at: "2023-05-27 22:40:39",
    updated_at: "2023-06-04 05:08:47",
  },
];
