/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useRef } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

// Material Dashboard 2 React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
import burceMars from "assets/images/bruce-mars.jpg";
import backgroundImage from "assets/images/bg-profile.jpeg";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function Header({groupe , onUpdate , children }) {
  const navigate = useNavigate();
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  const [pictureUrl, setPictureUrl] = useState('');
  
  const [selectedFile, setSelectedFile] = useState(null);  
  const fileInputRef = useRef(null);


  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };


  
  const [pictureUrlCover, setPictureUrlCover] = useState('');
  
  const [selectedFileCover, setSelectedFileCover] = useState(null);  
  const fileInputRefCover = useRef(null);


  const handleFileChangeCover = (event) => {
    setSelectedFileCover(event.target.files[0]);
  };

  const handleButtonClickCover = () => {
    fileInputRefCover.current.click();
  };

  useEffect(()=>{
    if(selectedFile) {
      console.log("upload profile image") ;
      onUpdate({profile_pic : selectedFile}) ; 
      setSelectedFile(null) ; 
    }
  } , [selectedFile]) ;

  useEffect(()=>{
    if(selectedFileCover) {
      console.log("upload cover image") ;
      onUpdate({cover_pic : selectedFileCover}) ; 
      setSelectedFileCover(null) ; 
    }
  } , [selectedFileCover]) ;

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Add event listener to update window width when it changes
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); 

  return (
    <MDBox position="relative" mb={5}>
      <input  accept="image/*" ref={fileInputRef} onClick={handleFileChange}  onChange={handleFileChange} type="file" style={{display : "none"}}/>
      <input  accept="image/*" ref={fileInputRefCover} onClick={handleFileChangeCover}  onChange={handleFileChangeCover} type="file" style={{display : "none"}}/>

      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.4),
              rgba(gradients.dark.state, 0.4)
              )}, url("`+(groupe && groupe.cover_pic ? groupe.cover_pic : "" )+`")`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: windowWidth > 800 ? 3 : 0 ,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <MDAvatar src={groupe && groupe.profile_pic ? groupe.profile_pic : ""} alt="profile-image" size="xl" shadow="sm" />
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
              {groupe && groupe.nom ? groupe.nom : ""}
              </MDTypography>
              <MDTypography variant="button" color="text" fontWeight="regular">
              {groupe && groupe.entreprises && groupe.entreprises.length > 1 ? "Groupe avec "+groupe.entreprises.length+" filiales "  : ""}
              {groupe && groupe.entreprises && groupe.entreprises.length === 1 ? "Groupe avec une filiale "  : ""}
                
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }} style={{display : "flex" , justifyContent : "flex-end"}}>
            
          <MDBox color="text" px={2}>
                  <Dropdown align="start">
                    <Dropdown.Toggle id="icon-dropdown">
                      Actions
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={handleButtonClickCover}>Image de couverture</Dropdown.Item>
                      <Dropdown.Item onClick={handleButtonClick}>Image du groupe</Dropdown.Item>
                      <Dropdown.Item onClick={()=> {
                        navigate("/groupe/settings") ; 
                      }}>Informations & Sécurité</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
              </MDBox>
            {/* <AppBar position="static">
              <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
                <Tab
                  label="App"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      home
                    </Icon>
                  }
                />
                <Tab
                  label="Message"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      email
                    </Icon>
                  }
                />
                <Tab
                  label="Settings"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      settings
                    </Icon>
                  }
                />
              </Tabs>
            </AppBar> */}
          </Grid>
        </Grid>
        {children}
      </Card>
    </MDBox>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  children: "",
};

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
