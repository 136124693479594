/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts

import Dashboard from "layouts/dashboard";
import DashboardIdir from "idirLayout/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Notifications from "layouts/notificationsAdmin";
import GroupesList from "layouts/GroupesList";
import EntreprisesList from "layouts/EntreprisesList";
import SecteurList from "layouts/SecteurList";
import PartenairesList from "layouts/PartenairesList";
import PublireportageList from "layouts/PublireportageList";
import BannieresList from "layouts/BannieresList";
import Contact from "layouts/Contact";
import AbonnementList from "layouts/AbonnementList";
import ServiceList from "layouts/ServiceList";
import InscriptionsList from "layouts/InscriptionsList";
import PubsList from "layouts/PubsList";
import OptionsList from "layouts/OptionsList";
import SignauxList from "layouts/SignauxList";
import Profile from "layouts/profile";
import Groupe from "layouts/Groupe";
import Entreprise from "layouts/Entreprise";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

import GroupeEntreprises from "layoutsGroupe/EntrepriseList";
import GroupeEntreprise from "layoutsGroupe/Entreprise";
import GroupeSecteurList from "layoutsGroupe/SecteurList";
import GroupeServiceList from "layoutsGroupe/ServiceList";
import GroupePublireportageList from "layoutsGroupe/PublireportageList";
import GroupeBannieresList from "layoutsGroupe/BannieresList";
import GroupeAbonnementList from "layoutsGroupe/AbonnementList";
import GroupeContact from "layoutsGroupe/Contact";
// @mui icons
// import Icon from "@mui/material/Icon";
import MarkUnreadChatAltIcon from "@mui/icons-material/MarkUnreadChatAlt";
import Home from "OurPages/Home";

// groupe components

import GroupeProfile from "layoutsGroupe/Groupe";
import GEntreprisesList from "layoutsGroupes/EntreprisesList";
import GSecteurList from "layoutsGroupes/SecteurList";
import GPublireportageList from "layoutsGroupes/PublireportageList";
import GBannieresList from "layoutsGroupes/BannieresList";
import GContact from "layoutsGroupes/Contact";
import GAbonnementList from "layoutsGroupes/AbonnementList";
import GServiceList from "layoutsGroupes/ServiceList";
import GInscriptionsList from "layoutsGroupes/InscriptionsList";

// entreprise components

import EntrepriseProfile from "layoutsEntreprises/ProfileEntreprise";
import EEntreprisesList from "layoutsEntreprises/EntreprisesList";
import ESecteurList from "layoutsEntreprises/SecteurList";
import EPublireportageList from "layoutsEntreprises/PublireportageList";
import EBannieresList from "layoutsEntreprises/BannieresList";
import EContact from "layoutsEntreprises/Contact";
import EAbonnementList from "layoutsEntreprises/AbonnementList";
import EServiceList from "layoutsEntreprises/ServiceList";
import EInscriptionsList from "layoutsEntreprises/InscriptionsList";

import PContact from "layoutsPub/Contact";
import PublicationList from "layoutsPub/PublicationList";
import MDTypography from "components/MDTypography";
import { Icon } from "@mui/material";
import SamplePage from "pages/sample-page";

const routes = [
  // {
  //   type: "collapse",
  //   name: "Traitement",
  //   key: "signaux",
  //   icon: <Icon fontSize="small">dashboard</Icon>,
  //   route: "/signaux",
  //   component: <DashboardIdir />,
  //   role : "idir"
  // },
  // {
  //   type: "collapse",
  //   name: "resultat",
  //   key: "_signaux_resultat",
  //   icon: <Icon fontSize="small">diversity_2</Icon>,
  //   route: "/signaux/resultat",
  //   component: <DashboardIdir />,
  //   role : "idir"
  // },
  // {
  //   type: "collapse",
  //   name: "Saved",
  //   key: "_signaux_saved",
  //   icon: <Icon fontSize="small">diversity_2</Icon>,
  //   route: "/signaux/saved",
  //   component: <GroupesList />,
  //   role : "idir"
  // },

  // admin
  {
    type: "collapse",
    name: "Dashboard",
    key: "admin",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/admin",
    component: <Contact />,
    role: "admin",
    group: "Dashboard",
  },
  {
    type: "collapse",
    name: "Inscriptions",
    key: "_admin_inscriptions",
    icon: <Icon fontSize="small">how_to_reg</Icon>,
    route: "/admin/inscriptions",
    component: <InscriptionsList />,
    role: "admin",
    group: "Demandes",
  },
  {
    type: "collapse",
    name: "Pub Demandes",
    key: "_admin_demandepub",
    icon: <Icon fontSize="small">crop</Icon>,
    route: "/admin/demandepub",
    component: <PubsList />,
    role: "admin",
    group: "Demandes",
  },
  {
    type: "collapse",
    name: "Options Demandes",
    key: "_admin_demandeoption",
    icon: <Icon fontSize="small">crop</Icon>,
    route: "/admin/demandeoption",
    component: <OptionsList />,
    role: "admin",
    group: "Demandes",
  },
  {
    type: "collapse",
    name: "Groupes",
    key: "_admin_groupes",
    icon: <Icon fontSize="small">diversity_2</Icon>,
    route: "/admin/groupes",
    component: <GroupesList />,
    role: "admin",
    group: "Users",
  },
  {
    type: "collapse",
    name: "Entreprises",
    key: "_admin_entreprises",
    icon: <Icon fontSize="small">apartment</Icon>,
    route: "/admin/entreprises",
    component: <EntreprisesList />,
    role: "admin",
    group: "Users",
  },
  {
    type: "collapse",
    name: "Secteurs",
    key: "_admin_secteurs",
    icon: <Icon fontSize="small">account_tree</Icon>,
    route: "/admin/secteurs",
    component: <SecteurList />,
    role: "admin",
    group: "Data",
  },
  {
    type: "collapse",
    name: "Partenaires",
    key: "_admin_partenaire",
    icon: <Icon fontSize="small">handshake</Icon>,
    route: "/admin/partenaire",
    component: <PartenairesList />,
    role: "admin",
    group: "Data",
  },
  {
    type: "collapse",
    name: "Publireportages",
    key: "_admin_publireportages",
    icon: <Icon fontSize="small">ads_click</Icon>,
    route: "/admin/publireportages",
    component: <PublireportageList />,
    role: "admin",
    group: "Pubs",
  },
  {
    type: "collapse",
    name: "Bannieres",
    key: "_admin_bannieres",
    icon: <Icon fontSize="small">ad_units</Icon>,
    route: "/admin/bannieres",
    component: <BannieresList />,
    role: "admin",
    group: "Pubs",
  },
  // {
  //   type: "collapse",
  //   name: "Abonnements",
  //   key: "_admin_abonnements",
  //   icon: <Icon fontSize="small">card_membership</Icon>,
  //   route: "/admin/abonnements",
  //   component: <AbonnementList />,
  //   role : "admin"
  // },
  {
    type: "collapse",
    name: "Services",
    key: "_admin_services",
    icon: <Icon fontSize="small">rss_feed</Icon>,
    route: "/admin/services",
    component: <ServiceList />,
    role: "admin",
    group: "Users",
  },
  {
    type: "collapse",
    name: "Paramètres",
    key: "_admin_settings",
    icon: <Icon fontSize="small">settings</Icon>,
    route: "/admin/settings",
    component: <Contact />,
    role: "admin",
    group: "Parametres",
  },
  // {
  //   type: "none",
  //   name: "Notifications",
  //   key: "_admin_notifications",
  //   icon: <Icon fontSize="small">notifications</Icon>,
  //   route: "/admin/notifications",
  //   component: <Notifications />,
  //   role : "admin"
  // },
  // {
  //   type: "none",
  //   name: "Groupe",
  //   key: "_admin_groupe",
  //   icon: <Icon fontSize="small">rss_feed</Icon>,
  //   route: "/admin/groupe",
  //   component: <Groupe />,
  //   role : "admin"
  // },
  // {
  //   type: "none",
  //   name: "Entreprise",
  //   key: "_admin_entreprise",
  //   icon: <Icon fontSize="small">rss_feed</Icon>,
  //   route: "/admin/entreprise/:entreprise_id",
  //   component: <Entreprise />,
  //   role : "admin"
  // },
  {
    type: "collapse",
    name: "Profile",
    key: "groupe",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/groupe/",
    component: <GroupeProfile />,
    role: "groupe",
  },
  {
    type: "collapse",
    name: "Entreprises",
    key: "_groupe_entreprises",
    icon: <Icon fontSize="small">apartment</Icon>,
    route: "/groupe/entreprises",
    component: <GEntreprisesList />,
    role: "groupe",
  },
  {
    type: "collapse",
    name: "Secteurs",
    key: "_groupe_secteurs",
    icon: <Icon fontSize="small">account_tree</Icon>,
    route: "/groupe/secteurs",
    component: <GSecteurList />,
    role: "groupe",
  },
  {
    type: "collapse",
    name: "Publireportages",
    key: "_groupe_publireportages",
    icon: <Icon fontSize="small">ads_click</Icon>,
    route: "/groupe/publireportages",
    component: <GPublireportageList />,
    role: "groupe",
  },
  {
    type: "collapse",
    name: "Bannieres",
    key: "_groupe_bannieres",
    icon: <Icon fontSize="small">ad_units</Icon>,
    route: "/groupe/bannieres",
    component: <GBannieresList />,
    role: "groupe",
  },
  {
    type: "collapse",
    name: "Abonnements",
    key: "_groupe_abonnements",
    icon: <Icon fontSize="small">card_membership</Icon>,
    route: "/groupe/abonnements",
    component: <GInscriptionsList />,
    role: "groupe",
  },
  {
    type: "collapse",
    name: "Services",
    key: "_groupe_services",
    icon: <Icon fontSize="small">rss_feed</Icon>,
    route: "/groupe/services",
    component: <GServiceList />,
    role: "groupe",
  },
  {
    type: "collapse",
    name: "Paramètres",
    key: "_groupe_settings",
    icon: <Icon fontSize="small">settings</Icon>,
    route: "/groupe/settings",
    component: <GContact />,
    role: "groupe",
  },

  // entreprises

  // {
  //   type: "collapse",
  //   name: "Dashboard",
  //   key: "entreprise",
  //   icon: <Icon fontSize="small">dashboard</Icon>,
  //   route: "/entreprise",
  //   component: <MDTypography ></MDTypography>,
  //   role : "entreprise"
  // },

  {
    type: "collapse",
    name: "Profile",
    key: "entreprise",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/entreprise/",
    component: <EntrepriseProfile />,
    role: "entreprise",
  },
  // {
  //   type: "collapse",
  //   name: "Entreprises",
  //   key: "_entreprise_entreprises",
  //   icon: <Icon fontSize="small">apartment</Icon>,
  //   route: "/entreprise/entreprises",
  //   component: <EEntreprisesList />,
  //   role : "entreprise"
  // },
  {
    type: "collapse",
    name: "Secteurs",
    key: "_entreprise_secteurs",
    icon: <Icon fontSize="small">account_tree</Icon>,
    route: "/entreprise/secteurs",
    component: <ESecteurList />,
    role: "entreprise",
  },
  {
    type: "collapse",
    name: "Publireportages",
    key: "_entreprise_publireportages",
    icon: <Icon fontSize="small">ads_click</Icon>,
    route: "/entreprise/publireportages",
    component: <EPublireportageList />,
    role: "entreprise",
  },
  {
    type: "collapse",
    name: "Bannieres",
    key: "_entreprise_bannieres",
    icon: <Icon fontSize="small">ad_units</Icon>,
    route: "/entreprise/bannieres",
    component: <EBannieresList />,
    role: "entreprise",
  },
  {
    type: "collapse",
    name: "Abonnements",
    key: "_entreprise_abonnements",
    icon: <Icon fontSize="small">card_membership</Icon>,
    route: "/entreprise/abonnements",
    component: <EInscriptionsList />,
    role: "entreprise",
  },
  {
    type: "collapse",
    name: "Services",
    key: "_entreprise_services",
    icon: <Icon fontSize="small">rss_feed</Icon>,
    route: "/entreprise/services",
    component: <EServiceList />,
    role: "entreprise",
  },
  {
    type: "collapse",
    name: "Paramètres",
    key: "_entreprise_settings",
    icon: <Icon fontSize="small">settings</Icon>,
    route: "/entreprise/settings",
    component: <EContact />,
    role: "entreprise",
  },

  //pub
  {
    type: "collapse",
    name: "Publications",
    key: "pub",
    icon: <Icon fontSize="small">rss_feed</Icon>,
    route: "/pub/",
    component: <PublicationList />,
    role: "pub",
  },
  {
    type: "collapse",
    name: "Paramètres",
    key: "_pub_settings",
    icon: <Icon fontSize="small">settings</Icon>,
    route: "/pub/settings",
    component: <PContact />,
    role: "pub",
  },
  // {
  //   type: "collapse",
  //   name: "Entreprises",
  //   key: "entreprisesGroupe",
  //   icon: <Icon fontSize="small">apartment</Icon>,
  //   route: "/entreprisesGroupe",
  //   component: <GroupeEntreprises />,
  //   role : "groupe"
  // },
  // {
  //   type: "collapse",
  //   name: "Secteurs",
  //   key: "secteursGroupe",
  //   icon: <Icon fontSize="small">account_tree</Icon>,
  //   route: "/secteursGroupe",
  //   component: <GroupeSecteurList />,
  //   role : "groupe"
  // },
  // {
  //   type: "collapse",
  //   name: "Services",
  //   key: "servicesGroupe",
  //   icon: <Icon fontSize="small">rss_feed</Icon>,
  //   route: "/servicesGroupe",
  //   component: <GroupeServiceList />,
  //   role : "groupe"
  // },
  // {
  //   type: "collapse",
  //   name: "Publireportages",
  //   key: "publireportagesGroupe",
  //   icon: <Icon fontSize="small">ads_click</Icon>,
  //   route: "/publireportagesGroupe",
  //   component: <GroupePublireportageList />,
  //   role : "groupe"
  // },
  // {
  //   type: "collapse",
  //   name: "Bannieres",
  //   key: "bannieresGroupe",
  //   icon: <Icon fontSize="small">ad_units</Icon>,
  //   route: "/bannieresGroupe",
  //   component: <GroupeBannieresList />,
  //   role : "groupe"
  // },
  // {
  //   type: "collapse",
  //   name: "Abonnements",
  //   key: "abonnementsGroupe",
  //   icon: <Icon fontSize="small">card_membership</Icon>,
  //   route: "/abonnementsGroupe",
  //   component: <GroupeAbonnementList />,
  //   role : "groupe"
  // },
  // {
  //   type: "collapse",
  //   name: "Paramètres",
  //   key: "settingsGroupe",
  //   icon: <Icon fontSize="small">settings</Icon>,
  //   route: "/settingsGroupe",
  //   component: <GroupeContact />,
  //   role : "groupe"
  // },
  // {
  //   type: "none",
  //   name: "Entreprise",
  //   key: "entrepriseGroupe",
  //   icon: <Icon fontSize="small">rss_feed</Icon>,
  //   route: "/entrepriseGroupe",
  //   component: <GroupeEntreprise />,
  //   role : "groupe"
  // },
  // {
  //   type: "collapse",
  //   name: "Tables",
  //   key: "tables",
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   route: "/tables",
  //   component: <Tables />,
  // },
  // {
  //   type: "collapse",
  //   name: "Billing",
  //   key: "billing",
  //   icon: <Icon fontSize="small">receipt_long</Icon>,
  //   route: "/billing",
  //   component: <Billing />,
  // },
  // {
  //   type: "collapse",
  //   name: "RTL",
  //   key: "rtl",
  //   icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
  //   route: "/rtl",
  //   component: <RTL />,
  // },
  // {
  //   type: "collapse",
  //   name: "Profile",
  //   key: "profile",
  //   icon: <Icon fontSize="small">person</Icon>,
  //   route: "/profile",
  //   component: <Profile />,
  // },
  {
    type: "collapse",
    name: "Sign In",
    key: "authentication",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
    role: "authentication",
  },
  // {
  //   type: "collapse",
  //   name: "Sign Up",
  //   key: "sign-up",
  //   icon: <Icon fontSize="small">assignment</Icon>,
  //   route: "/authentication/sign-up",
  //   component: <SignUp />,
  // },
  // {
  //   type: "collapse",
  //   name: "Home",
  //   key: "home",
  //   icon: <Icon fontSize="small">assignment</Icon>,
  //   route: "/home",
  //   component: <Home />,
  // },
];

export default routes;
