import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  ButtonBase,
  Chip,
  Collapse,
  Divider,
  Typography,
  Badge,
  Box,
  LinearProgress,
} from "@mui/material";

import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import MainCard from "ui-component/cards/MainCard";
import { CheckCircleOutline, ExpandLess, ExpandMore } from "@mui/icons-material";
import { IconClock } from "@tabler/icons";
import { BASE_URL_Image } from "services/server";
import { useSelector } from "react-redux";

const ServiceMessageDetails = ({
  openAdd,
  setOpenAdd,
  userId,
  message,
  handleClose,
  editable,
  isLoadingResponse,
  makeResponse,
  isLoadingMessageValidation,
  validateMessage,
  isLoadingResponseValidation,
  validateResponse,
}) => {
  const theme = useTheme();
  const [reponse, setReponse] = useState("");
  const lang = useSelector((state) => state.customization.lang);

  const [modifiedSubjectMessage, setModifiedSubjectMessage] = useState(false);
  const [modifiedSubject, setModifiedSubject] = useState("");
  const [modifiedMessage, setModifiedMessage] = useState("");

  const [modifiedMessageResponse, setModifiedMessageResponse] = useState(false);
  const [modifiedResponse, setModifiedResponse] = useState("");

  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  const [moreInfo, setMoreInfo] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    let role = localStorage.getItem("role");
    setIsAdmin(role === "admin");
  }, []);

  return (
    <>
      {userId != null && message && message.id ? (
        <Modal
          show={openAdd}
          onHide={handleClose ?? handleCloseAdd}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: "0.85em" }}>
              {lang === "fr"
                ? "Détail du message"
                : lang === "en"
                ? "Message details"
                : "تفاصيل الرسالة"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ width: "100%" }}>
              <div className="w-100 d-flex justify-content-center align-items-center">
                <div>
                  <div className="w-100 row m-0">
                    <div className="col-12 d-flex justify-content-end align-items-center mb-1">
                      <Typography
                        noWrap
                        variant="caption"
                        style={{
                          color: theme.palette.grey[400],
                          fontSize: "11px",
                          fontWeight: "bold",
                        }}
                      >
                        {lang === "fr" ? "le " : lang === "en" ? "the " : "في "} {message.send_date}
                      </Typography>
                    </div>
                    <div className="col-12   my-2">
                      <Divider textAlign="left">
                        <Chip
                          label={lang === "fr" ? "Sujet" : lang === "en" ? "Subject" : "الموضوع"}
                          size="small"
                          style={{ fontSize: "12px", fontWeight: "bold" }}
                        />
                      </Divider>
                    </div>
                    <div className="col-12 d-flex justify-content-start align-items-center  my-3">
                      <Typography
                        variant="inherit"
                        fontSize={"12px"}
                        style={
                          isAdmin &&
                          message.subject_admin_modification != null &&
                          message.subject_admin_modification != ""
                            ? {
                                textDecoration: "line-through",
                              }
                            : {}
                        }
                      >
                        {message.subject}
                      </Typography>
                    </div>
                    {isAdmin &&
                    message.subject_admin_modification != null &&
                    message.subject_admin_modification != "" ? (
                      <div className="col-12 d-flex justify-content-start align-items-center  my-3">
                        <Typography variant="inherit" fontSize={"12px"}>
                          {message.subject_admin_modification}
                        </Typography>
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="col-12   my-2">
                      <Divider textAlign="left">
                        <Chip
                          label={lang === "fr" ? "Message" : lang === "en" ? "Message" : "الرسالة"}
                          size="small"
                          style={{ fontSize: "12px", fontWeight: "bold" }}
                        />
                      </Divider>
                    </div>

                    <div className="col-12 d-flex justify-content-start align-items-center  my-3">
                      <Typography
                        variant="inherit"
                        fontSize={"12px"}
                        style={
                          isAdmin &&
                          message.message_admin_modification != null &&
                          message.message_admin_modification != ""
                            ? {
                                textDecoration: "line-through",
                              }
                            : {}
                        }
                      >
                        {message.message}
                      </Typography>
                    </div>

                    {isAdmin &&
                    message.message_admin_modification != null &&
                    message.message_admin_modification != "" ? (
                      <div className="col-12 d-flex justify-content-start align-items-center  my-3">
                        <Typography variant="inherit" fontSize={"12px"}>
                          {message.message_admin_modification}
                        </Typography>
                      </div>
                    ) : (
                      <></>
                    )}
                    {editable && isAdmin && parseInt(message.message_validated_by_admin) === 0 ? (
                      <div className="col-12 d-flex justify-content-start align-items-center  my-3">
                        <div className="flex-auto">
                          <div className="relative w-full mb-3 mt-8">
                            {isLoadingMessageValidation ? (
                              <Box sx={{ width: "100%" }}>
                                <LinearProgress color="secondary" />
                              </Box>
                            ) : (
                              <></>
                            )}
                            {modifiedSubjectMessage ? (
                              <>
                                <div className="relative w-full mb-3 mt-8">
                                  <label
                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                    htmlFor="full-name"
                                  >
                                    {lang === "fr"
                                      ? "Sujet modifié"
                                      : lang === "en"
                                      ? "Subject edited"
                                      : "الموضوع المعدل"}
                                  </label>
                                  <input
                                    disabled={isLoadingMessageValidation}
                                    type="text"
                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    placeholder={
                                      lang === "fr"
                                        ? "Sujet modifié"
                                        : lang === "en"
                                        ? "Subject edited"
                                        : "الموضوع المعدل"
                                    }
                                    onChange={(e) => setModifiedSubject(e.currentTarget.value)}
                                  />
                                </div>
                                <textarea
                                  rows="18"
                                  cols="80"
                                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                                  placeholder={
                                    lang === "fr"
                                      ? "Message modifié"
                                      : lang === "en"
                                      ? "Message edited"
                                      : "الرسالة المعدلة"
                                  }
                                  value={modifiedMessage}
                                  onChange={(e) => {
                                    setModifiedMessage(e.currentTarget.value);
                                  }}
                                  disabled={isLoadingMessageValidation}
                                />
                              </>
                            ) : (
                              <></>
                            )}
                            <div className="w-100 row m-0">
                              {modifiedSubjectMessage ? (
                                <>
                                  <div className="col-6">
                                    <ButtonBase
                                      sx={{
                                        ...theme.typography.commonAvatar,
                                        ...theme.typography.button,
                                        transition: "all .2s ease-in-out",
                                        background: theme.palette.secondary.light,
                                        color: theme.palette.secondary.dark,
                                        "&:hover": {
                                          background: theme.palette.secondary.dark,
                                          color: theme.palette.secondary.light,
                                        },
                                        height: "100%",
                                        overflow: "hidden",
                                        width: "100%",
                                      }}
                                      className="py-2 px-5 mt-3"
                                      disabled={isLoadingMessageValidation}
                                      onClick={() => {
                                        setModifiedSubjectMessage(false);
                                      }}
                                    >
                                      {lang === "fr"
                                        ? "Annuler"
                                        : lang === "en"
                                        ? "Cancel"
                                        : "إلغاء"}
                                    </ButtonBase>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                              <div className="col-6">
                                <ButtonBase
                                  sx={{
                                    ...theme.typography.commonAvatar,
                                    ...theme.typography.button,
                                    transition: "all .2s ease-in-out",
                                    background: theme.palette.secondary.light,
                                    color: theme.palette.secondary.dark,
                                    "&:hover": {
                                      background: theme.palette.secondary.dark,
                                      color: theme.palette.secondary.light,
                                    },
                                    height: "100%",
                                    overflow: "hidden",
                                    width: "100%",
                                  }}
                                  className="py-2 px-5 mt-3"
                                  disabled={isLoadingMessageValidation}
                                  onClick={() => {
                                    if (modifiedSubjectMessage) {
                                      validateMessage(modifiedMessage, modifiedSubject);
                                    } else {
                                      validateMessage("", "");
                                    }
                                  }}
                                >
                                  {lang === "fr" ? "Valider" : lang === "en" ? "Validate" : "تأكيد"}
                                </ButtonBase>
                              </div>
                              {!modifiedSubjectMessage ? (
                                <>
                                  <div className="col-6">
                                    <ButtonBase
                                      sx={{
                                        ...theme.typography.commonAvatar,
                                        ...theme.typography.button,
                                        transition: "all .2s ease-in-out",
                                        background: theme.palette.secondary.light,
                                        color: theme.palette.secondary.dark,
                                        "&:hover": {
                                          background: theme.palette.secondary.dark,
                                          color: theme.palette.secondary.light,
                                        },
                                        height: "100%",
                                        overflow: "hidden",
                                        width: "100%",
                                      }}
                                      className="py-2 px-5 mt-3"
                                      disabled={isLoadingMessageValidation}
                                      onClick={() => {
                                        setModifiedSubjectMessage(true);
                                      }}
                                    >
                                      {lang === "fr"
                                        ? "Modifier et Valider"
                                        : lang === "en"
                                        ? "Edit and Validate"
                                        : "تعديل و تأكيد"}
                                    </ButtonBase>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="col-12   my-2">
                      <Divider textAlign="left">
                        <Chip
                          label={lang === "fr" ? "Réponse" : lang === "en" ? "Response" : "الرد"}
                          size="small"
                          style={{ fontSize: "12px", fontWeight: "bold" }}
                        />
                      </Divider>
                    </div>
                    <div className="col-6 d-flex justify-content-start align-items-center mb-1">
                      <Badge
                        className="px-3 py-1 d-flex align-items-center"
                        sx={{
                          transition: "all .2s ease-in-out",
                          background: message.answered
                            ? theme.palette.success.dark
                            : theme.palette.warning.dark,
                          color: "white !important",
                          width: "max-content",
                          overflow: "hidden",
                          borderRadius: "8px",
                        }}
                      >
                        {message.answered ? (
                          <CheckCircleOutline
                            style={{ height: "16px" }}
                            className="mr-2"
                          ></CheckCircleOutline>
                        ) : (
                          <IconClock style={{ height: "16px" }} className="mr-2"></IconClock>
                        )}
                        <Typography noWrap style={{ fontWeight: "bold", fontSize: "10px" }}>
                          {message.answered
                            ? "" +
                              (lang === "fr" ? "répondu" : lang === "en" ? "responsed" : "تم الرد")
                            : "" +
                              (lang === "fr"
                                ? "Aucune réponse"
                                : lang === "en"
                                ? "No response"
                                : "لم يتم تم الرد")}
                        </Typography>
                      </Badge>
                    </div>
                    <div className="col-6 d-flex justify-content-end align-items-center mb-1">
                      <Typography
                        noWrap
                        variant="caption"
                        style={{
                          color: theme.palette.grey[400],
                          fontSize: "11px",
                          fontWeight: "bold",
                        }}
                      >
                        {message.response_date && message.response_date != ""
                          ? "" +
                            (lang === "fr" ? "le " : lang === "en" ? "the " : "في ") +
                            message.response_date
                          : ""}
                      </Typography>
                    </div>
                    {message.response && message.response != "" ? (
                      <>
                        <div className="col-12 d-flex justify-content-start align-items-center  my-3">
                          <Typography
                            variant="inherit"
                            fontSize={"12px"}
                            style={
                              isAdmin &&
                              message.response_admin_modification != null &&
                              message.response_admin_modification != ""
                                ? {
                                    textDecoration: "line-through",
                                  }
                                : {}
                            }
                          >
                            {message.response}
                          </Typography>
                        </div>

                        {isAdmin &&
                        message.response_admin_modification != null &&
                        message.response_admin_modification != "" ? (
                          <div className="col-12 d-flex justify-content-start align-items-center  my-3">
                            <Typography variant="inherit" fontSize={"12px"}>
                              {message.response_admin_modification}
                            </Typography>
                          </div>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : editable &&
                      userId == message.receiver_id &&
                      parseInt(message.answered) === 0 ? (
                      <div className="col-12 d-flex justify-content-start align-items-center  my-3">
                        <div className="flex-auto">
                          <div className="relative w-full mb-3 mt-8">
                            {isLoadingResponse ? (
                              <Box sx={{ width: "100%" }}>
                                <LinearProgress color="secondary" />
                              </Box>
                            ) : (
                              <></>
                            )}
                            <textarea
                              rows="18"
                              cols="80"
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                              placeholder={
                                lang === "fr" ? "Réponse" : lang === "en" ? "Response" : "الرد"
                              }
                              value={reponse}
                              onChange={(e) => {
                                setReponse(e.currentTarget.value);
                              }}
                              disabled={isLoadingResponse}
                            />

                            <ButtonBase
                              sx={{
                                ...theme.typography.commonAvatar,
                                ...theme.typography.button,
                                transition: "all .2s ease-in-out",
                                background: theme.palette.secondary.light,
                                color: theme.palette.secondary.dark,
                                "&:hover": {
                                  background: theme.palette.secondary.dark,
                                  color: theme.palette.secondary.light,
                                },
                                height: "100%",
                                overflow: "hidden",
                                width: "100%",
                              }}
                              className="py-2 px-5 mt-3"
                              disabled={isLoadingResponse}
                              onClick={() => {
                                makeResponse(reponse);
                              }}
                            >
                              {lang === "fr" ? "Répondre" : lang === "en" ? "Validate" : "تأكيد"}
                            </ButtonBase>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}

                    {editable &&
                    isAdmin &&
                    parseInt(message.answered) === 1 &&
                    parseInt(message.response_validated_by_admin) === 0 ? (
                      <div className="col-12 d-flex justify-content-start align-items-center  my-3">
                        <div className="flex-auto">
                          <div className="relative w-full mb-3 mt-8">
                            {isLoadingResponseValidation ? (
                              <Box sx={{ width: "100%" }}>
                                <LinearProgress color="secondary" />
                              </Box>
                            ) : (
                              <></>
                            )}
                            {modifiedMessageResponse ? (
                              <>
                                <textarea
                                  rows="18"
                                  cols="80"
                                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                                  placeholder={
                                    lang === "fr"
                                      ? "Réponse modifié"
                                      : lang === "en"
                                      ? "Response edited"
                                      : "الرد المعدل"
                                  }
                                  value={modifiedResponse}
                                  onChange={(e) => {
                                    setModifiedResponse(e.currentTarget.value);
                                  }}
                                  disabled={isLoadingResponseValidation}
                                />
                              </>
                            ) : (
                              <></>
                            )}
                            <div className="w-100 row m-0">
                              {modifiedMessageResponse ? (
                                <>
                                  <div className="col-6">
                                    <ButtonBase
                                      sx={{
                                        ...theme.typography.commonAvatar,
                                        ...theme.typography.button,
                                        transition: "all .2s ease-in-out",
                                        background: theme.palette.secondary.light,
                                        color: theme.palette.secondary.dark,
                                        "&:hover": {
                                          background: theme.palette.secondary.dark,
                                          color: theme.palette.secondary.light,
                                        },
                                        height: "100%",
                                        overflow: "hidden",
                                        width: "100%",
                                      }}
                                      className="py-2 px-5 mt-3"
                                      disabled={isLoadingResponseValidation}
                                      onClick={() => {
                                        setModifiedMessageResponse(false);
                                      }}
                                    >
                                      {lang === "fr"
                                        ? "Annuler"
                                        : lang === "en"
                                        ? "Cancel"
                                        : "إلغاء"}
                                    </ButtonBase>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                              <div className="col-6">
                                <ButtonBase
                                  sx={{
                                    ...theme.typography.commonAvatar,
                                    ...theme.typography.button,
                                    transition: "all .2s ease-in-out",
                                    background: theme.palette.secondary.light,
                                    color: theme.palette.secondary.dark,
                                    "&:hover": {
                                      background: theme.palette.secondary.dark,
                                      color: theme.palette.secondary.light,
                                    },
                                    height: "100%",
                                    overflow: "hidden",
                                    width: "100%",
                                  }}
                                  className="py-2 px-5 mt-3"
                                  disabled={isLoadingResponseValidation}
                                  onClick={() => {
                                    if (modifiedMessageResponse) {
                                      validateResponse(modifiedResponse);
                                    } else {
                                      validateResponse("");
                                    }
                                  }}
                                >
                                  {lang === "fr" ? "Valider" : lang === "en" ? "Validate" : "تأكيد"}
                                </ButtonBase>
                              </div>
                              {!modifiedMessageResponse ? (
                                <>
                                  <div className="col-6">
                                    <ButtonBase
                                      sx={{
                                        ...theme.typography.commonAvatar,
                                        ...theme.typography.button,
                                        transition: "all .2s ease-in-out",
                                        background: theme.palette.secondary.light,
                                        color: theme.palette.secondary.dark,
                                        "&:hover": {
                                          background: theme.palette.secondary.dark,
                                          color: theme.palette.secondary.light,
                                        },
                                        height: "100%",
                                        overflow: "hidden",
                                        width: "100%",
                                      }}
                                      className="py-2 px-5 mt-3"
                                      disabled={isLoadingResponseValidation}
                                      onClick={() => {
                                        setModifiedMessageResponse(true);
                                      }}
                                    >
                                      {lang === "fr"
                                        ? "Modifier et Valider"
                                        : lang === "en"
                                        ? "Edit and Validate"
                                        : "تعديل و تأكيد"}
                                    </ButtonBase>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}

                    <div className="col-12 mt-3">
                      <MainCard className="p-0 w-100">
                        <div
                          className="w-100 new_message_button d-flex justify-content-between align-items-center p-0"
                          onClick={() => {
                            setMoreInfo(!moreInfo);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <Typography noWrap variant="h6">
                            {moreInfo
                              ? "" +
                                (lang === "fr"
                                  ? "Afficher moins de détail"
                                  : lang === "en"
                                  ? "Show less detail"
                                  : "عرض تفاصيل أقل")
                              : "" +
                                (lang === "fr"
                                  ? "Afficher plus de détail"
                                  : lang === "en"
                                  ? "Show more detail"
                                  : "عرض المزيد من التفاصيل")}
                          </Typography>
                          {moreInfo ? (
                            <ExpandLess
                              style={{ color: theme.palette.grey[400] }}
                              fontSize="medium"
                            />
                          ) : (
                            <ExpandMore
                              style={{ color: theme.palette.grey[400] }}
                              fontSize="medium"
                            />
                          )}
                        </div>
                        <Collapse
                          children={
                            <div
                              className="row w-100 m-0 p-0"
                              style={{
                                marginTop: " 20px !important",
                              }}
                            >
                              <div className="col-12   my-2">
                                <Divider textAlign="left">
                                  <Typography noWrap variant="h6">
                                    {lang === "fr"
                                      ? "Envoyé par"
                                      : lang === "en"
                                      ? "Sent by"
                                      : "ارسل من طرف"}
                                  </Typography>
                                </Divider>
                              </div>
                              <div className="col-12 d-flex justify-content-start align-items-center  my-3">
                                <Avatar
                                  className="d-flex align-items-center justify-content-center mr-3"
                                  style={{
                                    height: "40px",
                                    width: "40px",
                                    overflow: "hidden",
                                    borderRadius: "50%",
                                  }}
                                >
                                  <img
                                    alt=""
                                    src={BASE_URL_Image + message.sender_image}
                                    style={{ height: "40px", width: "auto" }}
                                  />
                                </Avatar>
                                <Typography variant="inherit" fontSize={"12px"}>
                                  {message.sender_name}
                                </Typography>
                              </div>
                              <div className="col-12  row m-0 p-0">
                                <div className="col-12   my-2">
                                  <Divider textAlign="left">
                                    <Typography noWrap variant="h6">
                                      {lang === "fr"
                                        ? "Envoyé à"
                                        : lang === "en"
                                        ? "Sent to"
                                        : "أرسلت إلى"}
                                    </Typography>
                                  </Divider>
                                </div>
                                <div className="col-12 d-flex justify-content-start align-items-center  my-3">
                                  <Avatar
                                    className="d-flex align-items-center justify-content-center mr-3"
                                    style={{
                                      height: "40px",
                                      width: "40px",
                                      overflow: "hidden",
                                      borderRadius: "50%",
                                    }}
                                  >
                                    <img
                                      alt=""
                                      src={BASE_URL_Image + message.receiver_image}
                                      style={{ height: "40px", width: "auto" }}
                                    />
                                  </Avatar>
                                  <Typography variant="inherit" fontSize={"12px"}>
                                    {message.receiver_name}
                                  </Typography>
                                </div>
                              </div>

                              <div className="col-12 row m-0 p-0">
                                <div className="col-12   my-2">
                                  <Divider textAlign="left">
                                    <Typography noWrap variant="h6">
                                      {message.type === "produit"
                                        ? "" +
                                          (lang === "fr"
                                            ? "Produit"
                                            : lang === "en"
                                            ? "Product"
                                            : "منتج")
                                        : "" +
                                          (lang === "fr"
                                            ? "Annonce"
                                            : lang === "en"
                                            ? "Announcement"
                                            : "إعلان")}
                                    </Typography>
                                  </Divider>
                                </div>
                                <div className="col-12 d-flex justify-content-start align-items-center my-3">
                                  {message.type == "annonce" ? (
                                    <></>
                                  ) : (
                                    <Avatar
                                      className="d-flex align-items-center justify-content-center mr-3"
                                      style={{
                                        height: "40px",
                                        width: "40px",
                                        overflow: "hidden",
                                        borderRadius: "50%",
                                      }}
                                    >
                                      <img
                                        alt=""
                                        src={BASE_URL_Image + message.concerned_image}
                                        style={{ height: "40px", width: "auto" }}
                                      />
                                    </Avatar>
                                  )}
                                  <Typography variant="inherit" fontSize={"12px"}>
                                    {message.concerned_name}
                                  </Typography>
                                </div>
                              </div>
                            </div>
                          }
                          in={moreInfo}
                        ></Collapse>
                      </MainCard>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <>
              <ButtonBase
                sx={{
                  ...theme.typography.commonAvatar,
                  ...theme.typography.button,
                  transition: "all .2s ease-in-out",
                  background: theme.palette.grey[200],
                  color: theme.palette.text.dark,
                  "&:hover": {
                    background: theme.palette.grey[900],
                    color: "white !important",
                  },
                  width: "max-content",
                  overflow: "hidden",
                }}
                className="py-2 px-5"
                onClick={handleCloseAdd}
              >
                {lang === "fr" ? "Fermer" : lang === "en" ? "Close" : "إغلق"}
              </ButtonBase>
            </>
          </Modal.Footer>
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
};

ServiceMessageDetails.propTypes = {
  isLoading: PropTypes.bool,
};

export default ServiceMessageDetails;
