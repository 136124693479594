import PropTypes from "prop-types";
import { useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Button,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";

// project imports
import MainCard from "ui-component/cards/MainCard";
import SkeletonPopularCard from "ui-component/cards/Skeleton/PopularCard";
import { gridSpacing } from "store/constant";
import MenuList from "layouts/MainLayout/Sidebar/MenuList";
import { useSelector } from "react-redux";

// ==============================|| DASHBOARD DEFAULT - POPULAR CARD ||============================== //

const ApplicationsCard = ({ isLoading }) => {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState(null);
  const lang = useSelector((state) => state.customization.lang);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {isLoading ? (
        <SkeletonPopularCard />
      ) : (
        <MainCard content={false}>
          <CardContent>
            <Grid container spacing={gridSpacing}>
              <Grid item xs={12}>
                <Grid container alignContent="center" justifyContent="space-between">
                  <Grid item>
                    <Typography variant="h4">
                      {lang === "fr"
                        ? "List des Applications"
                        : lang === "en"
                        ? "List of Applications"
                        : "قائمة التطبيقات"}
                    </Typography>
                  </Grid>
                  <Grid item></Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <MenuList dashboard={true} />
              </Grid>
            </Grid>
          </CardContent>
        </MainCard>
      )}
    </>
  );
};

ApplicationsCard.propTypes = {
  isLoading: PropTypes.bool,
};

export default ApplicationsCard;
