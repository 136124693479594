// material-ui
import { useTheme, styled } from "@mui/material/styles";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";

import Flag1 from "assets/img/flag_fr.png";
import Flag2 from "assets/img/flag_ar.png";
import Flag3 from "assets/img/flag_en.png";
// assets
import { IconBrandTelegram, IconBuildingStore, IconMailbox, IconPhoto } from "@tabler/icons";
import User1 from "assets/images/users/user-round.svg";

// styles
const ListItemWrapper = styled("div")(({ theme }) => ({
  cursor: "pointer",
  padding: 16,
  "&:hover": {
    background: theme.palette.primary.light,
  },
  "& .MuiListItem-root": {
    padding: 0,
  },
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const LanguesList = ({ selectedLangToInsert, setSelectedLangToInsert, handleToggle }) => {
  const theme = useTheme();

  const chipSX = {
    height: 24,
    padding: "0 6px",
  };
  const chipErrorSX = {
    ...chipSX,
    color: theme.palette.orange.dark,
    backgroundColor: theme.palette.orange.light,
    marginRight: "5px",
  };

  const chipWarningSX = {
    ...chipSX,
    color: theme.palette.warning.dark,
    backgroundColor: theme.palette.warning.light,
  };

  const chipSuccessSX = {
    ...chipSX,
    color: theme.palette.success.dark,
    backgroundColor: theme.palette.success.light,
    height: 28,
  };

  return (
    <List
      sx={{
        width: "100%",
        maxWidth: 330,
        py: 0,
        borderRadius: "10px",
        [theme.breakpoints.down("md")]: {
          maxWidth: 300,
        },
        "& .MuiListItemSecondaryAction-root": {
          top: 22,
        },
        "& .MuiDivider-root": {
          my: 0,
        },
        "& .list-container": {
          pl: 7,
        },
      }}
    >
      <ListItemWrapper>
        <ListItem
          alignItems="center"
          onClick={() => {
            setSelectedLangToInsert("fr");
            handleToggle();
          }}
        >
          <ListItemAvatar>
            <Avatar alt="Français" style={{ height: "25px", width: "25px" }} src={Flag1} />
          </ListItemAvatar>
          <ListItemText primary="Français" />
        </ListItem>
      </ListItemWrapper>
      <Divider />
      <ListItemWrapper>
        <ListItem
          alignItems="center"
          onClick={() => {
            setSelectedLangToInsert("en");
            handleToggle();
          }}
        >
          <ListItemAvatar>
            <Avatar alt="Anglais" style={{ height: "25px", width: "25px" }} src={Flag3} />
          </ListItemAvatar>
          <ListItemText primary="Anglais" />
        </ListItem>
      </ListItemWrapper>
      <Divider />
      <ListItemWrapper>
        <ListItem
          alignItems="center"
          onClick={() => {
            setSelectedLangToInsert("ar");
            handleToggle();
          }}
        >
          <ListItemAvatar>
            <Avatar alt="Arabe" style={{ height: "25px", width: "25px" }} src={Flag2} />
          </ListItemAvatar>
          <ListItemText primary="Arabe" />
        </ListItem>
      </ListItemWrapper>
    </List>
  );
};

export default LanguesList;
