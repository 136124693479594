export const notificationsList = [
  {
    id: 1,
    concerne: "user", // user ,  message , annonce , product , service , pub
    titre: "Compte validé",
    description: "votre compte a été bien validé",
    titre_ar: "Compte validé",
    description_ar: "votre compte a été bien validé",
    titre_en: "Compte validé",
    description_en: "votre compte a été bien validé",
    image: "user_image",
    error: false,
    info: false,
    warning: false,
    success: true,
    new: true,
    readed: false,
  },
  {
    id: 2,
    concerne: "message", // user ,  message , annonce , product , service , pub
    concerne_id: 4,
    date: "2024-07-03 14:19:00",
    titre: "Nouveau message",
    description: "Vous avez recu un nouveau message concernant le produit 'titre du produit'",
    titre_ar: "Nouveau message",
    description_ar: "Vous avez recu un nouveau message concernant le produit 'titre du produit'",
    titre_en: "Nouveau message",
    description_en: "Vous avez recu un nouveau message concernant le produit 'titre du produit'",
    image: "product_image",
    error: false,
    info: true,
    warning: false,
    success: false,
    new: true,
    readed: false,
  },
  {
    id: 3,
    concerne: "annonce", // user ,  message , annonce , product , service , pub
    titre: "Annonce Bloquée",
    description: "votre annonce 'titre_de_l'annonce' a été bloquer par l'admin",
    titre_ar: "Annonce Bloquée",
    description_ar: "votre annonce 'titre_de_l'annonce' a été bloquer par l'admin",
    titre_en: "Annonce Bloquée",
    description_en: "votre annonce 'titre_de_l'annonce' a été bloquer par l'admin",
    image: null,
    error: true,
    info: false,
    warning: false,
    success: false,
    new: true,
    readed: false,
  },
  {
    id: 4,
    concerne: "product", // user ,  message , annonce , product , service , pub
    titre: "Annonce Bloquée",
    description: "votre annonce 'titre_de_l'annonce' a été bloquer par l'admin",
    titre_ar: "Annonce Bloquée",
    description_ar: "votre annonce 'titre_de_l'annonce' a été bloquer par l'admin",
    titre_en: "Annonce Bloquée",
    description_en: "votre annonce 'titre_de_l'annonce' a été bloquer par l'admin",
    image: null,
    error: true,
    info: false,
    warning: false,
    success: false,
    new: false,
    readed: false,
  },
];
// actions :
// getList(concerne , new , readed , index , offset ) ,
// update(new , readed) ,
// getInfo(product , annonce , service , user , pub , message)
