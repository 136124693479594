import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Avatar, Badge, Button, CardActions, Divider, Typography } from "@mui/material";

// project imports
import MainCard from "ui-component/cards/MainCard";

import { CheckCircleOutline } from "@mui/icons-material";
import ServiceMessageItem from "./MessageItem";

const ServiceMessageList = ({ handleOpen, messages, userId }) => {
  const theme = useTheme();

  return (
    <>
      {messages && messages.length ? (
        <div>
          {messages.map((message, index) => (
            <ServiceMessageItem
              key={index}
              message={message}
              userId={userId}
              handleOpen={handleOpen}
            ></ServiceMessageItem>
          ))}

          {/* <Divider />
          <CardActions sx={{ p: 1.25, justifyContent: "center" }}>
            <Button size="small" disableElevation>
              Voir tout
            </Button>
          </CardActions> */}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

ServiceMessageList.propTypes = {
  isLoading: PropTypes.bool,
};

export default ServiceMessageList;
