import React, { useEffect, useState } from "react";
import TopNavbar from "../components/Nav/TopNavbarSecond";
import logo from "assets/images/logo_targets_zone_dark2.png";
import LandingContactezNous from "./LandingContactezNous";
import LandingContactezNousForm from "./LandingContactezNousForm";

export default () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    if (document.getElementById("header_smart") != null)
      document.getElementById("header_smart").classList.add("header_smart_solution");
    return () => {
      if (document.getElementById("header_smart") != null)
        document.getElementById("header_smart").classList.remove("header_smart_solution");
    };
  }, []);

  return (
    <>
      <TopNavbar></TopNavbar>
      <div id="contactUs">
        <section className="pb-20 relative block bg-blueGray-800" style={{ paddingTop: "80px" }}>
          <LandingContactezNous></LandingContactezNous>
        </section>
        <section className="relative block py-24 lg:pt-0 bg-blueGray-800">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap justify-center">
              <div className="w-full lg:w-6/12 px-0 mt-3">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200">
                  <LandingContactezNousForm></LandingContactezNousForm>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
