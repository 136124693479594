import { useEffect, useRef, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";

// Data
import data from "layoutsPub/PublicationList/components/List/data";
import { Grid } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import MDButton from "components/MDButton";
import tw from "twin.macro";
import MDInput from "components/MDInput";
import styled from "styled-components";

import { useMutation } from "@tanstack/react-query";
import { getPublications } from "services/publicationsService";
import { BASE_URL_Image } from "services/server";
import { Pagination } from "react-bootstrap";
import { toast } from "react-toastify";
import { uploaderPublication } from "services/publicationsService";
import { updatePublication } from "services/publicationsService";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";

const CardPub = tw.div`h-full flex! flex-col sm:border max-w-sm  relative focus:outline-none bg-white m-0`;
const CardTw = tw.div`lg:mx-4 xl:mx-8 max-w-sm flex flex-col h-full`;
const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`bg-cover bg-center h-80 lg:h-64 rounded rounded-b-none`,
]);

const Details = tw.div`p-6 rounded border-2  border-solid border-primary-100 flex-1 flex flex-col items-center text-center lg:block lg:text-left`;

const ControlButton = styled(PrimaryButtonBase)`
  ${tw`mt-4 sm:mt-0 first:ml-0 ml-6 rounded-full p-2  border-none bg-gray-500 text-gray-100 border-none `}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const Title = tw.h5`mt-4 leading-snug font-bold text-lg`;
const Description = tw.p`mt-2 text-sm text-secondary-100`;
const Link = styled(PrimaryButtonBase).attrs({ as: "a" })`
  ${tw`inline-block mt-4 text-sm font-semibold`}
`;

function List() {
  const [onupdatePublication, setOnupdatePublication] = useState(null);
  const [search, setSearch] = useState("");
  const [items, setItems] = useState([]);

  const [page, setPage] = useState({
    index: 1,
    limit: 5,
    totalItems: 0,
  });

  const [paginationItems, setPaginationItems] = useState([]);
  const { mutate, isLoading } = useMutation((userInfo) => getPublications(userInfo), {
    async onSuccess(data) {
      console.log(data);
      if (data && data.data) {
        setItems(
          data.data.map((el) => {
            return {
              imageSrc: BASE_URL_Image + el.image,
              attachement: BASE_URL_Image + el.attachement,
              id: el.id,
              titre: el.titre,
              description: el.description,
              status: parseInt(el.status) === 1,
              archive: parseInt(el.archive) === 1,
            };
          })
        );
        setPage((oldValue) => {
          return { ...oldValue, totalItems: parseInt(data.total) };
        });
        let table_items = [];
        if (parseInt(data.total) !== 0) {
          for (
            let number = 1;
            number <= parseInt(parseInt(data.total) / 5) + (parseInt(data.total) % 5 > 0 ? 1 : 0);
            number++
          ) {
            table_items.push(
              <Pagination.Item
                key={number}
                active={number === page.index}
                onClick={() => {
                  setPage((oldValue) => {
                    return { ...oldValue, index: number };
                  });
                }}
              >
                {number}
              </Pagination.Item>
            );
          }
        }
        setPaginationItems(table_items);
      } else {
      }
    },
    onError(error) {},
  });

  useEffect(() => {
    mutate({ filter: search, limit: 5, offset: (page.index - 1) * 5 });
  }, []);
  useEffect(() => {
    mutate({ filter: search, limit: 5, offset: (page.index - 1) * 5 });
  }, [page.index]);

  const rechercher = () => {
    mutate({ filter: search, limit: 5, offset: (page.index - 1) * 5 });
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [openAdd, setOpenAdd] = useState(false);
  const handleOpenAdd = (publireportage) => {
    setOpenAdd(true);
    console.log(publireportage);
  };
  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  const [openShow, setOpenShow] = useState(false);
  const handleOpenShow = (publireportage) => {
    setOpenShow(true);
    console.log(publireportage);
  };
  const handleCloseShow = () => {
    setOpenShow(false);
  };

  const [publication, setPublication] = useState(null);

  const [Titre, setTitre] = useState("");
  const [Description, setDescription] = useState("");

  const [pictureUrl, setPictureUrl] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    console.log(selectedFile);
    if (selectedFile) {
      let url = URL.createObjectURL(selectedFile);
      if (url) {
        console.log(url);
        setPictureUrl(url);
      }
    }
  }, [selectedFile]);

  const [pictureUrl2, setPictureUrl2] = useState("");

  const [selectedFile2, setSelectedFile2] = useState(null);
  const fileInputRef2 = useRef(null);

  const handleFileChange2 = (event) => {
    setSelectedFile2(event.target.files[0]);
  };

  const handleButtonClick2 = () => {
    fileInputRef2.current.click();
  };

  useEffect(() => {
    console.log(selectedFile2);
    if (selectedFile2) {
      let url = URL.createObjectURL(selectedFile2);
      if (url) {
        console.log(url);
        setPictureUrl2(url);
      }
    }
  }, [selectedFile2]);

  const insertNewPublication = () => {
    if (!selectedFile || !pictureUrl || pictureUrl == "") {
      notify("Vous devez inserer une image");
    }
    if (!selectedFile2 || !pictureUrl2 || pictureUrl2 == "") {
      notify("Vous devez inserer un attachement");
    } else if (Titre == "") {
      notify("Vous devez entrez un titre");
    } else if (Description == "") {
      notify("Vous devez entrez une description");
    } else {
      mutateNewPublication();
      // setOpenAdd(false);
    }
  };

  const modifierPublication = (id) => {
    if (!selectedFile && pictureUrl !== "" && Titre != "") {
      mutateupdatePublication({ id: onupdatePublication, nom: Titre });
    } else if (Titre === "") {
      notify("Vous devez entrez un nom");
    } else if (pictureUrl === "") {
      notify("Vous devez inserer une image");
    } else {
      mutateupdatePublication({ id: onupdatePublication, file: selectedFile, nom: Titre });
      // setOpenAdd(false);
    }
  };
  const { mutate: mutateNewPublication, isLoading: isLoadingNewPublication } = useMutation(
    () =>
      uploaderPublication({
        file: selectedFile,
        file2: selectedFile2,
        titre: Titre,
        description: Description,
      }),
    {
      async onSuccess(data) {
        console.log(data);
        if (data && data.data) {
          notify_success("Ajouter avec succès");
          handleCloseAdd();
          mutate({ filter: search, limit: 5, offset: (page.index - 1) * 5 });
        } else {
          notify("Une erreur a été produit");
        }
      },
      onError(error) {},
    }
  );

  const { mutate: mutateupdatePublication, isLoading: isLoadingupdatePublication } = useMutation(
    (userInfo) => updatePublication(userInfo),
    {
      async onSuccess(data) {
        console.log(data);
        if (data && data.data) {
          notify_success("Mettre à jour avec succès");
          if (onupdatePublication !== null) {
            handleCloseOnupdatePublication();
          } else {
            handleCloseAdd();
          }
          mutate({ filter: search, limit: 5, offset: (page.index - 1) * 5 });
        } else {
          notify("Une erreur a été produit");
        }
      },
      onError(error) {},
    }
  );

  const notify = (message) => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 3000,
      fontSize: "12px",
    });
  };

  const notify_success = (message) => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 3000,
    });
  };

  const handleOnupdatePublication = (Publication) => {
    console.log(Publication);
    // setSelectedFile(null) ;
    // setPictureUrl(Publication.imageSrc ? Publication.imageSrc : "") ;
    // setTitre(Publication.title ? Publication.title : "");
    // setDescription(Publication.description ? Publication.description : "");
    // setOnupdatePublication(Publication.id ? Publication.id : null) ;
    // setOpenAdd(true) ;
    setOpenShow(true);
    setPublication(Publication);
  };

  const handleCloseOnupdatePublication = () => {
    // setSelectedFile(null) ;
    // setPictureUrl("") ;
    // setTitre("") ;
    // setDescription("") ;
    // setOnupdatePublication(null) ;
    // setOpenAdd(false) ;

    setOpenShow(false);
    setPublication(null);
  };

  const { columns, rows } = data({
    handleOpen,
    handleOpenAdd,
    items: items,
    handleUpdate: mutateupdatePublication,
    handleOpenUpdatePublication: handleOnupdatePublication,
    handleupdatePublication: modifierPublication,
  });

  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox style={{ width: "100%" }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <MDTypography variant="h6" gutterBottom>
                Liste des publications
              </MDTypography>
              <MDBox display="flex" alignItems="center" lineHeight={0}>
                <Icon
                  sx={{
                    fontWeight: "bold",
                    color: ({ palette: { primary } }) => primary.main,
                    mt: -0.5,
                  }}
                >
                  rss_feed
                </Icon>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;
                  <strong>
                    {page.totalItems ? page.totalItems : 0}{" "}
                    {page.totalItems && page.totalItems > 1 ? "Publications" : "Publication"}
                  </strong>{" "}
                  en total
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <MDBox>
                <Grid container spacing={3} className="d-flex justify-content-end">
                  <Grid item xs={12} className="d-flex justify-content-end">
                    <MDButton color="primary" onClick={handleOpenAdd}>
                      Ajouter
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            </Grid>

            <div className="px-2 w-100">
              <Grid container spacing={3} className=" w-100 m-0">
                <Grid item style={{ flex: 1 }} className="px-2">
                  <MDInput
                    style={{ width: "100%" }}
                    label="Recherche"
                    onChange={(e) => setSearch(e.currentTarget.value)}
                  />
                </Grid>
                <Grid item className="d-flex justify-content-end">
                  <MDButton color="light" onClick={rechercher}>
                    Filtrer
                  </MDButton>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </MDBox>
      </MDBox>
      <MDBox>
        <DataTable
          table={{ columns, rows }}
          showTotalEntries={false}
          isSorted={false}
          noEndBorder
          entriesPerPage={false}
        />
        <div className="w-100 d-flex justify-content-center align-items-center">
          <Pagination>{paginationItems}</Pagination>
        </div>
        {page.totalItems === 0 && (
          <div className="w-100 d-flex justify-content-center align-items-center">
            <MDTypography variant="h6" color="light">
              Aucune Publications
            </MDTypography>
          </div>
        )}
      </MDBox>
      <Modal
        show={open}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Annulation d'un publireportage
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MDBox
            mx={2}
            my={1}
            py={3}
            px={2}
            variant="gradient"
            bgColor="error"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              Est-ce-que vous voulez vraiment annuler ce publireportage ?
            </MDTypography>
          </MDBox>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button onClick={handleClose}>Confirmer</Button> */}
          <MDButton color="error" onClick={handleClose}>
            Confirmer
          </MDButton>
        </Modal.Footer>
      </Modal>

      <Modal
        show={openShow}
        onHide={handleCloseShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {publication != null && (
              <Card key={publication.id} style={{ width: "100%", maxWidth: "250px" }}>
                <Details
                  style={{
                    padding: 0,
                    cursor: "pointer",
                    overflow: "hidden",
                    border: "none",
                    background: "white",
                    boxShadow: "rgb(231 232 233) -3px 4px 8px 0px",
                    width: "250px",
                  }}
                >
                  <Image imageSrc={publication.imageSrc} style={{ width: "100%" }} />
                  <div style={{ padding: "20px" }}>
                    <Title
                      style={{
                        height: "50px",
                        overflow: "hidden",
                        wordWrap: "break-word",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {publication.titre}
                    </Title>
                    <div
                      style={{
                        height: "90px",
                        overflow: "hidden",
                        wordWrap: "break-word",
                        textOverflow: "ellipsis",
                        fontSize: "12px",
                      }}
                    >
                      {publication.description}
                    </div>
                    <div
                      className="w-100 d-flex justify-content-end align-items-center"
                      style={{ fontSize: "13px" }}
                    >
                      <a href={publication.attachement} download={true} color="primary">
                        Voir plus
                      </a>
                    </div>
                  </div>
                </Details>
              </Card>
            )}
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={openAdd}
        onHide={handleCloseAdd}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {onupdatePublication !== null
              ? "Modifier une publication"
              : "Ajouter une nouvelle publication"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CardPub style={{ maxWidth: "300px", width: "100%" }}>
              <div
                style={{
                  width: "300px",
                  height: "300px",
                  border: pictureUrl ? "" : "3px dashed  #e91e63",
                  margin: "20px auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  overflow: "hidden",
                }}
                onClick={handleButtonClick}
              >
                <input
                  accept="image/*"
                  ref={fileInputRef}
                  onClick={handleFileChange}
                  onChange={handleFileChange}
                  type="file"
                  style={{ display: "none" }}
                />

                {pictureUrl && pictureUrl != "" && (
                  <img style={{ height: "100%", width: "auto" }} src={pictureUrl} />
                )}
                {(!pictureUrl || pictureUrl === "") && (
                  <Icon fontSize="large" color="primary">
                    add_a_photo
                  </Icon>
                )}
              </div>
              <MDInput
                style={{ width: "100%" }}
                label="Titre"
                value={Titre}
                onChange={(e) => setTitre(e.currentTarget.value)}
              />
              <MDInput
                style={{ width: "100%", marginTop: "20px" }}
                label="Description"
                multiline
                rows={5}
                value={Description}
                onChange={(e) => setDescription(e.currentTarget.value)}
              />
              <div
                style={{
                  width: "80%",
                  margin: "20px auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  overflow: "hidden",
                }}
                onClick={handleButtonClick2}
              >
                <input
                  accept=".pdf"
                  ref={fileInputRef2}
                  onClick={handleFileChange2}
                  onChange={handleFileChange2}
                  type="file"
                  style={{ display: "none" }}
                />
                <div>
                  <div className="d-flex justify-content-center align-items-center">
                    {pictureUrl2 && pictureUrl2 != "" && (
                      <MDTypography variant="h6" color="primary">
                        {selectedFile2 != null && selectedFile2.name != null
                          ? selectedFile2.name
                          : ""}
                      </MDTypography>
                    )}
                  </div>
                  {pictureUrl2 && pictureUrl2 != "" && (
                    <MDTypography
                      style={{
                        fontSize: "13px",
                        width: "100%",
                        textAlign: "center",
                        marginTop: "5px",
                        fontWight: "bold",
                        color: "#e91e63",
                      }}
                    >
                      Changer l'attachement
                    </MDTypography>
                  )}
                </div>

                {(!pictureUrl2 || pictureUrl2 === "") && (
                  <MDTypography style={{ fontSize: "13px" }}>Ajouter un PDF</MDTypography>
                )}
              </div>
            </CardPub>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button onClick={handleCloseAdd}>Confirmer</Button> */}
          <MDButton
            color="primary"
            onClick={onupdatePublication !== null ? modifierPublication : insertNewPublication}
          >
            Confirmer
          </MDButton>
        </Modal.Footer>
      </Modal>

      <Modal
        show={isLoading || isLoadingNewPublication || isLoadingupdatePublication}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <MDBox
            mx={2}
            my={1}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              {isLoading ? "Loading ..." : "Updating ..."}
            </MDTypography>
          </MDBox>
        </Modal.Body>
      </Modal>
    </Card>
  );
}

export default List;
