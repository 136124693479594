import React, { useState } from "react";

import { useSelector } from "react-redux";
export default () => {
  const lang = useSelector((state) => state.customization.lang);

  const services = [
    {
      id: 1,
      titre: "Exportez Sans Frontières",
      titre_en: "Export Without Border",
      titre_ar: "تصدير بلا حدود",
      description:
        "Partagez vos produits, connectez-vous mondialement, bénéficiez d'un accompagnement complet.",
      description_en: "Share your products, connect worldwide, benefit from comprehensive support.",
      description_ar: "شارك منتجاتك وتواصل على مستوى العالم واستفد من الدعم الشامل.",
    },
    {
      id: 2,
      titre: "Ventes Internationales Simplifiées",
      titre_en: "Simplified International Sales",
      titre_ar: "المبيعات الدولية المبسطة",
      description:
        "Simplifiez l'exportation, connectez-vous directement aux acheteurs, avec un support dédié.",
      description_en: "Simplify exporting, connect directly to buyers, with dedicated support.",
      description_ar: "تبسيط التصدير، والاتصال مباشرةً بالمشترين، مع دعم مخصص.",
    },
    {
      id: 3,
      titre: "Boostez Vos Ventes Globales",
      titre_en: "Boost Your Global Sales",
      titre_ar: "عزز مبيعاتك العالمية",
      description:
        "Maximisez vos opportunités grâce à notre plateforme innovante, avec une présentation attrayante et un accompagnement complet.",
      description_en:
        "Maximize your opportunities with our innovative platform, attractive presentation and comprehensive support.",
      description_ar:
        "استفد من فرصك إلى أقصى حد ممكن من خلال منصتنا المبتكرة والعرض التقديمي الجذاب والدعم الشامل.",
    },
  ];

  return (
    <div className="flex flex-wrap">
      <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
          <div className="px-4 py-5 flex-auto">
            <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
              <i className="fas fa-globe"></i>
            </div>
            <h6 className="font-semibold" style={{ fontSize: "0.85rem" }}>
              {lang === "fr"
                ? services[0].titre
                : lang === "en"
                ? services[0].titre_en
                : services[0].titre_ar}
            </h6>
            <p className="mt-2 mb-4 text-blueGray-500" style={{ fontSize: "0.85rem" }}>
              {lang === "fr"
                ? services[0].description
                : lang === "en"
                ? services[0].description_en
                : services[0].description_ar}
            </p>
          </div>
        </div>
      </div>

      <div className="w-full md:w-4/12 px-4 text-center">
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
          <div className="px-4 py-5 flex-auto">
            <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-lightBlue-400">
              <i className="fas fa-rocket"></i>
            </div>
            <h6 className="font-semibold" style={{ fontSize: "0.85rem" }}>
              {lang === "fr"
                ? services[1].titre
                : lang === "en"
                ? services[1].titre_en
                : services[1].titre_ar}
            </h6>
            <p className="mt-2 mb-4 text-blueGray-500" style={{ fontSize: "0.85rem" }}>
              {lang === "fr"
                ? services[1].description
                : lang === "en"
                ? services[1].description_en
                : services[1].description_ar}
            </p>
          </div>
        </div>
      </div>

      <div className="pt-6 w-full md:w-4/12 px-4 text-center">
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
          <div className="px-4 py-5 flex-auto">
            <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-emerald-400">
              <i className="fas fa-briefcase"></i>
            </div>
            <h6 className="font-semibold" style={{ fontSize: "0.85rem" }}>
              {lang === "fr"
                ? services[2].titre
                : lang === "en"
                ? services[2].titre_en
                : services[2].titre_ar}
            </h6>
            <p className="mt-2 mb-4 text-blueGray-500" style={{ fontSize: "0.85rem" }}>
              {lang === "fr"
                ? services[2].description
                : lang === "en"
                ? services[2].description_en
                : services[2].description_ar}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
