import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import ServiceItem from "OurPages/OurComponents/Home/ServiceItem";
import { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import { getSecteurs } from "services/secteursService";
import { useMutation } from "@tanstack/react-query";
import { getServices } from "services/servicesService";
import { BASE_URL_Image } from "services/server";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Pagination from "@mui/material/Pagination";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
// Import Swiper React components

import Hammer from "hammerjs";
// Import Swiper styles
import "swiper/css";

import HorizontalScroll from "react-scroll-horizontal";
import "./serviceSlider.css";
import { Close } from "@mui/icons-material";
import { Box, Button, LinearProgress } from "@mui/material";
import { getAnnonces } from "services/adminService";
import AnnonceItem from "./AnnonceItem";
import SearchSection from "components/search/SearchSection";
import AnnonceCard from "ui-component/cards/Skeleton/AnnonceCard";

import { useTheme } from "@mui/material/styles";
import MustAuth from "components/shareProduct/MustAuth";

const Container = tw.div`relative w-full`;
const Content = tw.div`max-w-screen-xl mx-auto `;

const Actions = styled.div`
  ${tw`relative  text-center mx-auto lg:mx-0`}
  input {
    ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-primary-500 hover:border-gray-500 border-none`}
  }
  button {
    ${tw`w-full sm:absolute right-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center  sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300 `}
  }
`;

export default () => {
  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [sliderRef, setSliderRef] = useState(null);
  const [sliderHammerRef, setSliderHammerRef] = useState(null);
  const [activeSecteur, setActiveSecteur] = useState(0);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const lang = useSelector((state) => state.customization.lang);

  const theme = useTheme();
  const [auth, setAuth] = useState(false);
  const [UserRole, setUserRole] = useState("");
  const [openAdd, setOpenAdd] = useState(false);

  const [page, setPage] = useState({
    index: 1,
    limit: 6,
    totalItems: 0,
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (sliderRef) {
        sliderRef?.slickNext();
      }
    }, 3000);
    return () => {
      clearInterval(intervalId);
    };
  }, [sliderRef]);

  useEffect(() => {
    const element = sliderHammerRef?.current;
    if (element) {
      const hammer = new Hammer(element);
      hammer.get("pan").set({ direction: Hammer.DIRECTION_HORIZONTAL });
      hammer.on("pan", this.handlePan);
    }
  }, [sliderHammerRef]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (document.getElementById("header_smart") != null)
      document.getElementById("header_smart").classList.add("header_smart_solution");
    return () => {
      if (document.getElementById("header_smart") != null)
        document.getElementById("header_smart").classList.remove("header_smart_solution");
    };
  }, []);

  useEffect(() => {
    let token = localStorage.getItem("token");
    setAuth(token != null && token !== "");
    let role = localStorage.getItem("role");
    if (role != null && role != "null" && role != "") {
      setUserRole(role);
    }
  }, []);

  const [items, setItems] = useState([]);
  const [showFilter, setShowfilter] = useState(false);

  const [services, setServices] = useState([]);
  const { mutate: mutateServices, isLoading: isLoadingServices } = useMutation(
    (filters) => getAnnonces({ ...filters, home: true }),
    {
      async onSuccess(data) {
        console.log(data);
        if (data && data.data) {
          setServices(
            data.data.map((el) => {
              return {
                id: el.annonce_id,
                imageSrc: BASE_URL_Image + el.image,
                title: el.titre,
                description: el.description,
                title_en: el.titre_en,
                description_en: el.description_en,
                title_ar: el.titre_ar,
                description_ar: el.description_ar,
                locationText: el.localisation,
                entrepriseText: el.entreprise_name,
                entrepriseImage: BASE_URL_Image + el.entreprise_image,
                entreprise_id: el.entreprise_id,
                groupeText: el.groupe_name,
                groupeImage: BASE_URL_Image + el.groupe_image,
                groupe_id: el.groupe_id,
                secteur: el.secteur_name,
                secteur_id: el.secteur_id,
                fav: el.fav ? parseInt(el.fav) === 1 : false,
              };
            })
          );
          setPage((oldValue) => {
            return { ...oldValue, totalItems: parseInt(data.total) };
          });
          let table_items = [];
          // if (parseInt(data.total) !== 0) {
          //   for (
          //     let number = 1;
          //     number <= parseInt(parseInt(data.total) / 5) + (parseInt(data.total) % 5 > 0 ? 1 : 0);
          //     number++
          //   ) {
          //     table_items.push(
          //       <Pagination.Item
          //         key={number}
          //         active={number === page.index}
          //         onClick={() => {
          //           setPage((oldValue) => {
          //             return { ...oldValue, index: number };
          //           });
          //         }}
          //       >
          //         {number}
          //       </Pagination.Item>
          //     );
          //   }
          // }
          setItems(table_items);
        } else {
        }
      },
      onError(error) {},
    }
  );

  const [secteurs, setSecteurs] = useState([]);
  const { mutate: mutateSecteurs, isLoading: isLoadingSecteurs } = useMutation(
    () => getSecteurs(),
    {
      async onSuccess(data) {
        console.log(data);
        if (data && data.data) {
          setSecteurs(
            data.data.map((el) => {
              return {
                id: el.id,
                text: el.nom,
              };
            })
          );
        } else {
        }
      },
      onError(error) {},
    }
  );

  useEffect(() => {
    let homeSearch = localStorage.getItem("searchService");
    let homeSecteur = localStorage.getItem("activatedSecteur");
    if ((homeSearch == null || homeSearch === "") && (homeSecteur == null || homeSecteur === "")) {
      console.log("use effect active secteur");
      mutateServices({
        secteur_id: activeSecteur,
        filter: search,
        limit: page.limit,
        offset: (page.index - 1) * page.limit,
      });
    }
  }, [activeSecteur]);

  useEffect(() => {
    let homeSearch = localStorage.getItem("searchService");
    let homeSecteur = localStorage.getItem("activatedSecteur");
    if ((homeSearch == null || homeSearch === "") && (homeSecteur == null || homeSecteur === "")) {
      console.log("use effect page index");
      if (activeSecteur && activeSecteur > 0) {
        mutateServices({
          secteur_id: activeSecteur,
          filter: search,
          limit: page.limit,
          offset: (page.index - 1) * page.limit,
        });
      } else {
        mutateServices({
          filter: search,
          limit: page.limit,
          offset: (page.index - 1) * page.limit,
        });
      }
    }
  }, [page.index]);

  const rechercher = () => {
    if (activeSecteur && activeSecteur > 0) {
      mutateServices({
        secteur_id: activeSecteur,
        limit: page.limit,
        offset: (page.index - 1) * page.limit,
      });
    } else {
      mutateServices({ filter: search, limit: page.limit, offset: (page.index - 1) * page.limit });
    }
  };

  function isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      window.navigator.userAgent
    );
  }

  useEffect(() => {
    console.log("loading");
    // mutateSecteurs();
    let homeSearch = localStorage.getItem("searchService");
    let homeSecteur = localStorage.getItem("activatedSecteur");
    if (homeSearch && homeSearch !== "") {
      setSearch(homeSearch);
      if (homeSecteur && homeSecteur !== "") {
        setActiveSecteur(parseInt(homeSecteur));
        mutateServices({
          secteur_id: parseInt(homeSecteur),
          filter: homeSearch,
          limit: page.limit,
          offset: (page.index - 1) * page.limit,
        });
      } else {
        mutateServices({
          filter: homeSearch,
          limit: page.limit,
          offset: (page.index - 1) * page.limit,
        });
      }
    } else {
      if (homeSecteur && homeSecteur !== "") {
        setActiveSecteur(parseInt(homeSecteur));
        mutateServices({
          secteur_id: parseInt(homeSecteur),
          filter: search,
          limit: page.limit,
          offset: (page.index - 1) * page.limit,
        });
      } else {
        mutateServices({
          filter: search,
          limit: page.limit,
          offset: (page.index - 1) * page.limit,
        });
      }
    }
    localStorage.removeItem("searchService");
    localStorage.removeItem("activatedSecteur");
  }, []);

  return (
    <div id="annonces">
      <Container style={{ marginTop: "100px", direction: lang === "ar" ? "rtl" : "ltr" }}>
        <Content>
          <Row>
            <Col xs={12} md={9} className="mb-1">
              {/* <Actions>
                <input
                  type="text"
                  placeholder="Trouver un service..."
                  value={search}
                  onChange={(e) => setSearch(e.currentTarget.value)}
                />
                <button style={{ border: "none" }} onClick={rechercher}>
                  Recherche
                </button>
              </Actions> */}

              {/* <div className="relative w-full my-3">
                <input
                  type="text"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Rechercher..."
                />
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{
                    position: "absolute",
                    right: "0px",
                    top: "0px",
                    height: "100%",
                    width: "50px",
                  }}
                >
                  <i
                    className="fas fa-search"
                    style={{ fontSize: "25px", color: "#402d81", cursor: "pointer" }}
                  />
                </div>
              </div> */}

              <SearchSection
                label={lang === "fr" ? "Annonce" : lang === "en" ? "Announcement" : "إعلان"}
                value={search}
                valueChange={(value) => setSearch(value)}
                valueFilter={rechercher}
              />
            </Col>
            <Col xs={12} md={3} className="mb-1 d-flex align-items-center justify-content-center">
              <Button
                sx={{
                  ...theme.typography.commonAvatar,
                  ...theme.typography.button,
                  transition: "all .2s ease-in-out",
                  width: "100%",
                  background: theme.palette.secondary.light,
                  color: theme.palette.secondary.dark,
                  "&:hover": {
                    background: theme.palette.secondary.dark,
                    color: theme.palette.secondary.light,
                  },
                  height: "100%",
                  overflow: "hidden",
                  borderRadius: "12px",
                  fontSize: "12px",
                }}
                className="py-2 px-5"
                onClick={() => {
                  if (auth) {
                    if (UserRole === "entreprise") {
                      navigate("/entreprise/annonces");
                    } else if (UserRole === "acheteur") {
                      navigate("/acheteur/annonces");
                    }
                  } else {
                    setOpenAdd(true);
                  }
                }}
              >
                {lang === "fr"
                  ? "Lancez votre annonce"
                  : lang === "en"
                  ? "Launch your ad"
                  : "أطلق إعلانك"}
              </Button>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Row>
                {services.length > 0 && (
                  <Col xs={12} className="d-flex justify-content-center align-items-center my-3">
                    {/* <Pagination>{items}</Pagination> */}
                    <Pagination
                      count={
                        parseInt(page.totalItems) % page.limit == 0
                          ? parseInt(parseInt(page.totalItems) / page.limit)
                          : parseInt(parseInt(page.totalItems) / page.limit) + 1
                      }
                      style={{ direction: "ltr" }}
                      page={page.index}
                      onChange={(event, value) => {
                        setPage((oldValue) => {
                          return { ...oldValue, index: value };
                        });
                      }}
                    />
                  </Col>
                )}
                {services.length === 0 && isLoadingServices ? (
                  <>
                    <Col
                      key={0}
                      xs={12}
                      md={6}
                      lg={4}
                      className="mb-5 px-2 d-flex align-items-start justify-content-center"
                    >
                      <AnnonceCard />
                    </Col>
                    <Col
                      key={0}
                      xs={12}
                      md={6}
                      lg={4}
                      className="mb-5 px-2 d-flex align-items-start justify-content-center"
                    >
                      <AnnonceCard />
                    </Col>
                    <Col
                      key={0}
                      xs={12}
                      md={6}
                      lg={4}
                      className="mb-5 px-2 d-flex align-items-start justify-content-center"
                    >
                      <AnnonceCard />
                    </Col>
                  </>
                ) : (
                  <></>
                )}
                {services.map((card, index) => (
                  <Col
                    key={index}
                    xs={12}
                    md={6}
                    lg={4}
                    className="mb-5 px-2 d-flex align-items-start justify-content-center"
                  >
                    <AnnonceItem initCard={card} isLoading={isLoadingServices}></AnnonceItem>
                  </Col>
                ))}
                <Col xs={12} className="d-flex justify-content-center align-items-center">
                  {/* <Pagination>{items}</Pagination> */}
                  <Pagination
                    count={
                      parseInt(page.totalItems) % page.limit == 0
                        ? parseInt(parseInt(page.totalItems) / page.limit)
                        : parseInt(parseInt(page.totalItems) / page.limit) + 1
                    }
                    style={{ direction: "ltr" }}
                    page={page.index}
                    onChange={(event, value) => {
                      setPage((oldValue) => {
                        return { ...oldValue, index: value };
                      });
                    }}
                  />
                </Col>
                {page.totalItems == 0 && (
                  <Col xs={12} className="d-flex justify-content-center align-items-center">
                    <MDTypography variant="h6" color="light">
                      Aucun service
                    </MDTypography>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Content>
      </Container>

      <Modal
        show={isLoadingSecteurs}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="myModalLoading"
      >
        <Modal.Body style={{ overflow: "hidden" }}>
          <Box sx={{ width: "100%" }}>
            <LinearProgress color="secondary" />
          </Box>
        </Modal.Body>
      </Modal>

      <MustAuth openAdd={openAdd} setOpenAdd={setOpenAdd}></MustAuth>
    </div>
  );
};
