import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";

import { useTheme } from "@mui/material/styles";
// Data
import data from "layoutsEntreprises/DemandesPersonnaliseesList/components/data";
import { Box, Button, ButtonBase, Divider, Grid, LinearProgress, Pagination } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import MDButton from "components/MDButton";
import tw from "twin.macro";
import styled from "styled-components";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import MDInput from "components/MDInput";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { BASE_URL_Image } from "services/server";
import { useMutation } from "@tanstack/react-query";
import dayjs from "dayjs";
import { getDemandeInscriptions } from "services/adminService";
import { updateDemandeInscription } from "services/adminService";
import { inscriptionAbonnementsList } from "services/adminService";
import AbonnementItem from "layoutsEntreprises/DemandesPersonnaliseesList/components/abonnement";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import SearchSection from "components/search/SearchSection";
import { useSelector } from "react-redux";
import { getDemandeOptions } from "services/adminService";
import MDBadge from "components/MDBadge";
import { getDemandePartenaires } from "services/adminService";
import { getDemandePersonnalisees } from "services/adminService";

const CardPub = tw.div`h-full flex! flex-col sm:border max-w-sm  relative focus:outline-none bg-white m-0`;
const CardImage = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full h-56 sm:h-64 bg-cover bg-center `,
]);

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-2xl font-bold`;

const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4`;

const PrimaryButton = tw(
  PrimaryButtonBase
)`mt-auto sm:text-lg rounded-none w-full  py-3 sm:py-6 border-none`;

function List() {
  const dateToDateJS = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();

    const dateString = `${year}/${month}/${day}`;
    console.log("init date debut");
    console.log(dateString);
    return dayjs(dateString);
  };
  const lang = useSelector((state) => state.customization.lang);

  const [publireportage, setPublireportage] = useState(null);

  const [page, setPage] = useState({
    index: 1,
    limit: 5,
    totalItems: 0,
  });
  const [search, setSearch] = useState("");
  const [dateDebut, setDateDebut] = useState(dateToDateJS(new Date()));
  const [dateFin, setDateFin] = useState(null);

  const [paginationItems, setPaginationItems] = useState([]);

  const [items, setItems] = useState([]);

  const [open, setOpen] = useState(false);
  const handleOpen = (publireportage) => {
    console.log(publireportage);
    setPublireportage(publireportage);
    setOpen(true);
  };
  const handleClose = () => {
    setPublireportage(null);
    setOpen(false);
  };

  const [openArchivage, setOpenArchivage] = useState(false);
  const handleOpenArchivage = (publireportage) => {
    console.log(publireportage);
    setPublireportage(publireportage);
    setOpenArchivage(true);
  };
  const handleCloseArchivage = () => {
    setPublireportage(null);
    setOpenArchivage(false);
  };

  const { mutate, isLoading } = useMutation((userInfo) => getDemandePersonnalisees(userInfo), {
    async onSuccess(data) {
      console.log(data);
      if (data && data.data) {
        let new_data = data.data.map((el) => {
          return {
            ...el,
            image: BASE_URL_Image + el.image,
          };
        });
        setItems(
          new_data.map((it) => {
            return {
              ...it,
              abonnement: {
                ...it.abonnement,
                price: 0,
              },
            };
          })
        );

        setPage((oldValue) => {
          return { ...oldValue, totalItems: parseInt(data.total) };
        });
      } else {
      }
    },
    onError(error) {},
  });

  useEffect(() => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    setDateFin(dateToDateJS(tomorrow));
    mutate({ filter: search, limit: 5, offset: (page.index - 1) * 5 });
  }, []);

  const [openValidation, setOpenValidation] = useState(false);
  const handleOpenValidation = (publireportage) => {
    console.log(dateDebut);
    console.log(dateFin);
    setPublireportage(publireportage);
    setOpenValidation(true);
    console.log(publireportage);
  };
  const handleCloseValidation = () => {
    setDateDebut(dateToDateJS(new Date()));
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    setDateFin(dateToDateJS(tomorrow));
    setPublireportage(null);
    setOpenValidation(false);
  };

  const [openAffichage, setOpenAffichage] = useState(false);
  const handleOpenAffichage = (publireportage) => {
    console.log(publireportage);
    setPublireportage(publireportage);
    setOpenAffichage(true);
  };
  const handleCloseAffichage = () => {
    setOpenAffichage(false);
  };

  const { columns, rows } = data({
    handleOpen,
    handleOpenValidation,
    handleOpenAffichage,
    handleOpenArchivage,
    items: items,
    lang,
  });

  const rechercher = () => {
    mutate({ filter: search, limit: 5, offset: (page.index - 1) * 5 });
  };

  const { mutate: updateDemandes, isLoading: isLoadingupdateDemandes } = useMutation(
    (userInfos) => updateDemandeInscription(userInfos),
    {
      async onSuccess(data) {
        console.log(data);
        if (data) {
          mutate();
          // setOpenValidation((oldValue) => {return {id : null , value : false}});
        } else {
        }
      },
      onError(error) {},
    }
  );

  const annulerItem = () => {
    updateDemandes({ id: publireportage.id, status: -1 });
    handleClose();
  };

  const validerItem = () => {
    console.log(publireportage);
    updateDemandes({
      id: publireportage.id,
      status: 1,
      date_debut: dateDebut.format("YYYY/MM/DD"),
      date_fin: dateFin.format("YYYY/MM/DD"),
    });
    handleCloseValidation();
  };

  const archiverItem = () => {
    updateDemandes({ id: publireportage.id, archive: 1 });
    handleCloseArchivage();
  };
  const theme = useTheme();

  return (
    <Card style={{ overflow: "hidden" }}>
      <div>
        <MDTypography
          variant="h6"
          gutterBottom
          className="px-3 py-2"
          style={{
            background: theme.palette.warning.dark,
            color: theme.palette.warning.light,
            width: "max-content",
          }}
        >
          {lang === "fr"
            ? "Liste des demandes personnalisées"
            : lang === "en"
            ? "List of custom requests"
            : "قائمة الطلبات المخصصة"}
        </MDTypography>
      </div>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox style={{ width: "100%" }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MDBox>
                <Grid container spacing={3}>
                  <Grid item xs={12} xl={4}></Grid>
                  <Grid item xs={12} xl={8}>
                    <MDBox>
                      <SearchSection
                        label={lang === "fr" ? "Demande" : lang === "en" ? "Request" : "طلب"}
                        value={search}
                        valueChange={(value) => setSearch(value)}
                        valueFilter={rechercher}
                      />
                    </MDBox>
                  </Grid>
                </Grid>

                <div className="w-100 py-2 px-3 mt-2 d-flex align-items-center justify-content-center">
                  <MDBox display="flex" alignItems="center" lineHeight={0}>
                    <MDTypography variant="button" fontWeight="regular" style={{ color: "grey" }}>
                      &nbsp;
                      <strong
                        style={{
                          color: theme.palette.warning.dark,
                        }}
                      >
                        {items != null ? items.length : 0}{" "}
                        {items != null && items.length > 1
                          ? "" + (lang === "fr" ? "Services" : lang === "en" ? "Services" : "خدمات")
                          : "" + (lang === "fr" ? "Service" : lang === "en" ? "Service" : "خدمة")}
                      </strong>{" "}
                      {lang === "fr" ? "en total" : lang === "en" ? "in total" : "في المجمل"}
                    </MDTypography>
                  </MDBox>
                </div>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <MDBox>
        <DataTable
          table={{ columns, rows }}
          showTotalEntries={false}
          isSorted={false}
          noEndBorder
          entriesPerPage={false}
        />
        <div className="w-100 d-flex justify-content-center align-items-center">
          <Pagination
            color="light"
            count={
              parseInt(page.totalItems) % 12 == 0
                ? parseInt(parseInt(page.totalItems) / 12)
                : parseInt(parseInt(page.totalItems) / 12) + 1
            }
            page={page.index}
            onChange={(event, value) => {
              setPage((oldValue) => {
                return { ...oldValue, index: value };
              });
            }}
          />
        </div>
        {page.totalItems === 0 && !isLoading && (
          <div className="w-100 d-flex justify-content-center align-items-center">
            <MDTypography
              variant="h6"
              color="light"
              style={{
                color: theme.palette.warning.dark,
              }}
            >
              {lang === "fr" ? "Aucun Service" : lang === "en" ? "No Service" : "لا توجد خدمات"}
            </MDTypography>
          </div>
        )}
      </MDBox>
      <Modal
        show={open || openArchivage}
        onHide={open ? handleClose : handleCloseAffichage}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {open ? "Refu d'une inscription" : "Archivage d'un publireportage"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MDBox
            mx={2}
            my={1}
            py={3}
            px={2}
            variant="gradient"
            bgColor="error"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              {open
                ? "Est-ce-que vous voulez vraiment réfuser cette demande ?"
                : "Est-ce-que vous voulez vraiment archiver ce publireportage ?"}
            </MDTypography>
          </MDBox>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button onClick={handleClose}>Confirmer</Button> */}
          <MDButton color="error" onClick={open ? annulerItem : archiverItem}>
            Confirmer
          </MDButton>
        </Modal.Footer>
      </Modal>

      <Modal
        show={openValidation}
        onHide={handleCloseValidation}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Validation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {publireportage != null && publireportage.abonnement != null && (
              <AbonnementItem plan={publireportage.abonnement}></AbonnementItem>
            )}
          </div>
          <MDBox
            mx={2}
            my={1}
            py={3}
            px={2}
            variant="gradient"
            bgColor="light"
            borderRadius="lg"
            coloredShadow="light"
            className=" mt-5"
          >
            <div className="row w-100 ml-0">
              <div className="col-12 col-md-6 d-flex justify-content-start align-items-center">
                <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                  {publireportage != null && publireportage.nom != null ? publireportage.nom : ""}
                </MDTypography>
              </div>
              <div className="col-12 col-md-6 d-flex justify-content-end align-items-center">
                <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                  {publireportage != null && publireportage.email != null
                    ? publireportage.email
                    : ""}
                </MDTypography>
              </div>
              <Divider></Divider>
              <div className="col-6 d-flex justify-content-start align-items-center">
                <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Prix
                </MDTypography>
              </div>
              <div className="col-6 d-flex justify-content-end align-items-center">
                <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                  {publireportage != null && publireportage.abonnement != null
                    ? publireportage.abonnement.price
                    : ""}
                </MDTypography>
              </div>
              <Divider></Divider>
              <div className="col-6 d-flex justify-content-start align-items-center">
                <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Durée
                </MDTypography>
              </div>
              <div className="col-6 d-flex justify-content-end align-items-center">
                <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                  {publireportage != null && publireportage.abonnement != null
                    ? publireportage.abonnement.duration
                    : ""}
                </MDTypography>
              </div>
              <Divider></Divider>
              <div className="col-6 d-flex justify-content-start align-items-center">
                <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Attachement
                </MDTypography>
              </div>
              <div className="col-6 d-flex justify-content-end align-items-center">
                <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                  {publireportage != null && publireportage.image != null ? (
                    <a href={publireportage.image} download={true}>
                      <CloudDownloadIcon color="dark" fontSize="small" />
                    </a>
                  ) : (
                    ""
                  )}
                </MDTypography>
              </div>
            </div>
          </MDBox>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button onClick={handleCloseValidation}>Confirmer</Button> */}
          <MDButton color="info" onClick={validerItem}>
            Confirmer
          </MDButton>
        </Modal.Footer>
      </Modal>

      <Modal
        show={openAffichage}
        onHide={handleCloseAffichage}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {publireportage != null ? (
            <>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="row w-100 ml-0 mt-3" style={{ maxWidth: "500px" }}>
                  <div className="col-12  d-flex justify-content-end align-items-center my-2">
                    <MDTypography color="dark" className="feature" style={{ fontSize: "12px" }}>
                      {publireportage.created_at ? publireportage.created_at : "--"}
                    </MDTypography>
                  </div>
                  <div className="col-12 col-md-6 d-flex justify-content-start align-items-center my-2">
                    <MDTypography color="dark" className="feature" style={{ fontSize: "12px" }}>
                      {publireportage != null && publireportage.nom != null ? (
                        <>
                          <strong>Nom & Prénom :</strong> {publireportage.nom}
                        </>
                      ) : (
                        ""
                      )}
                    </MDTypography>
                  </div>
                  <div className="col-12 col-md-6 d-flex justify-content-end align-items-center my-2">
                    <MDTypography color="dark" className="feature" style={{ fontSize: "12px" }}>
                      {publireportage != null && publireportage.email != null ? (
                        <>
                          <strong>Email :</strong> {publireportage.email}
                        </>
                      ) : (
                        ""
                      )}
                    </MDTypography>
                  </div>

                  <div className="col-12 col-md-6 d-flex justify-content-start align-items-center my-2">
                    <MDTypography color="dark" className="feature" style={{ fontSize: "12px" }}>
                      {publireportage != null && publireportage.phone != null ? (
                        <>
                          <strong>Tel :</strong> {publireportage.phone}
                        </>
                      ) : (
                        ""
                      )}
                    </MDTypography>
                  </div>
                  <div className="col-12 col-md-6 d-flex justify-content-end align-items-center my-2">
                    <MDTypography color="dark" className="feature" style={{ fontSize: "12px" }}>
                      {publireportage != null && publireportage.whatsapp != null ? (
                        <>
                          <strong>WhatsApp :</strong> {publireportage.whatsapp}
                        </>
                      ) : (
                        ""
                      )}
                    </MDTypography>
                  </div>

                  <div className="col-12 col-md-6 d-flex justify-content-start align-items-center my-2">
                    <MDTypography color="dark" className="feature" style={{ fontSize: "12px" }}>
                      <strong>Déja Utilisateur ? :</strong>
                    </MDTypography>
                    <MDBadge
                      badgeContent={
                        publireportage.alreadyUser === 1
                          ? "" + (lang === "fr" ? "Utilisateur" : lang === "en" ? "User" : "مستخدم")
                          : "Non"
                      }
                      color={publireportage.alreadyUser === 1 ? "success" : "info"}
                      variant="gradient"
                      size="sm"
                    />
                  </div>

                  <div className="col-12 col-md-6 d-flex justify-content-end align-items-center my-2">
                    <MDTypography color="dark" className="feature" style={{ fontSize: "12px" }}>
                      <strong>Status :</strong>
                    </MDTypography>
                    <MDBadge
                      badgeContent={
                        publireportage.status === 1
                          ? "Prouvé"
                          : publireportage.status === -1
                          ? "Annulé"
                          : "En attente"
                      }
                      color={
                        publireportage.status === 1
                          ? "success"
                          : publireportage.status === -1
                          ? "danger"
                          : "warning"
                      }
                      variant="gradient"
                      size="sm"
                    />
                  </div>

                  {publireportage.status === 1 ? (
                    <>
                      {/* <Divider></Divider> */}
                      <div className="col-6 d-flex justify-content-start align-items-center my-2">
                        <MDTypography color="info" style={{ fontSize: "14px", fontWeight: "bold" }}>
                          {publireportage.date_debut ? publireportage.date_debut : "--"}
                        </MDTypography>
                      </div>

                      {/* <Divider></Divider> */}
                      <div className="col-6 d-flex justify-content-end align-items-center my-2">
                        <MDTypography color="info" className="feature" style={{ fontSize: "12px" }}>
                          {publireportage.date_fin ? publireportage.date_fin : "--"}
                        </MDTypography>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {/* <Divider></Divider> */}
                  <div className="col-12  d-flex justify-content-center align-items-center my-2 mt-5 ">
                    <MDTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
                      Message
                    </MDTypography>
                  </div>
                  <div className="col-12  d-flex justify-content-center align-items-center my-2 mb-5">
                    <MDTypography color="dark" className="feature" style={{ fontSize: "12px" }}>
                      {publireportage != null && publireportage.message != null
                        ? publireportage.message
                        : ""}
                    </MDTypography>
                  </div>
                  {/* <Divider></Divider> */}
                  {publireportage != null &&
                  publireportage.abonnement != null &&
                  publireportage.abonnement.price > 0 ? (
                    <>
                      <div className="col-12 col-md-6 d-flex justify-content-start align-items-center my-2">
                        <MDTypography color="dark" className="feature" style={{ fontSize: "12px" }}>
                          {publireportage != null && publireportage.abonnement != null
                            ? publireportage.abonnement.price
                            : ""}
                        </MDTypography>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  {publireportage != null &&
                  publireportage.abonnement != null &&
                  publireportage.abonnement.duration !== "" ? (
                    <>
                      <div className="col-6 d-flex justify-content-end align-items-center">
                        <MDTypography color="dark" className="feature" style={{ fontSize: "12px" }}>
                          {publireportage != null && publireportage.abonnement != null
                            ? publireportage.abonnement.duration
                            : ""}
                        </MDTypography>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  {/* <Divider></Divider> */}
                  {publireportage.status === 1 ? (
                    <></>
                  ) : (
                    <>
                      <div className="col-12  d-flex justify-content-end align-items-center my-2">
                        <Button
                          className="mx-2 px-3"
                          sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.button,
                            transition: "all .2s ease-in-out",
                            background: theme.palette.secondary.light,
                            color: theme.palette.secondary.dark,
                            "&:hover": {
                              background: theme.palette.secondary.dark,
                              color: "white !important",
                            },
                            width: "100%",
                            overflow: "hidden",
                            fontSize: "11px",
                          }}
                          onClick={() => {
                            // handleOpen(message);
                          }}
                        >
                          Valider
                        </Button>
                      </div>
                      <div className="col-12  d-flex justify-content-end align-items-center my-2">
                        <Button
                          className="mx-2 px-3"
                          sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.button,
                            transition: "all .2s ease-in-out",
                            background: theme.palette.error.light,
                            color: theme.palette.error.dark,
                            "&:hover": {
                              background: theme.palette.error.dark,
                              color: "white !important",
                            },
                            width: "100%",
                            overflow: "hidden",
                            fontSize: "11px",
                          }}
                          onClick={() => {
                            // handleOpen(message);
                          }}
                        >
                          Réfuser
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </Modal.Body>
      </Modal>
      <Modal
        show={isLoading || isLoadingupdateDemandes}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="myModalLoading"
      >
        <Modal.Body style={{ overflow: "hidden" }}>
          <Box sx={{ width: "100%" }}>
            <LinearProgress color="secondary" />
          </Box>
        </Modal.Body>
      </Modal>
    </Card>
  );
}

export default List;
