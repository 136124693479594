import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import { ButtonBase, Typography } from "@mui/material";

import { Modal } from "react-bootstrap";
import { useState } from "react";
import "./MustAuth.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const MustAuth = ({ openAdd, setOpenAdd }) => {
  const theme = useTheme();
  const [subject, setSubject] = useState("");
  const [Message, setMessage] = useState("");
  const navigate = useNavigate();
  const lang = useSelector((state) => state.customization.lang);

  const handleCloseAdd = () => {
    setSubject("");
    setMessage("");
    setOpenAdd(false);
  };
  return (
    <>
      <Modal
        show={openAdd}
        onHide={handleCloseAdd}
        size="lg"
        style={{ direction: lang === "ar" ? "rtl" : "ltr" }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal_must_auth"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div style={{ width: "100%" }}>
            <Typography>
              {lang === "fr"
                ? "Vous devez s'authentifier afin de pouvoir utiliser cette option"
                : lang === "en"
                ? "You must authenticate in order to use this option"
                : "يجب عليك تسجبل الدخول لتتمكن من استخدام هذا الخيار"}
            </Typography>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <>
            <ButtonBase
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.button,
                transition: "all .2s ease-in-out",
                background: theme.palette.grey[900],
                color: "white",
                "&:hover": {
                  background: theme.palette.grey[200],
                  color: theme.palette.text.dark + "  !important",
                },
                width: "max-content",
                overflow: "hidden",
              }}
              className="py-2 px-5"
              onClick={() => {
                navigate("/authentication/sign-up");
              }}
            >
              {lang === "fr" ? "s'inscrir" : lang === "en" ? "register" : "انضم إلينا"}
            </ButtonBase>
            <ButtonBase
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.button,
                transition: "all .2s ease-in-out",
                background: theme.palette.secondary.dark,
                color: theme.palette.secondary.light,
                "&:hover": {
                  background: theme.palette.secondary.light,
                  color: theme.palette.secondary.dark,
                },
                height: "100%",
                overflow: "hidden",
              }}
              className="py-2 px-5"
              onClick={() => {
                navigate("/authentication/sign-in");
              }}
            >
              {lang === "fr" ? "se connecter" : lang === "en" ? "login" : "تسجيل الدخول"}
            </ButtonBase>
          </>
        </Modal.Footer>
      </Modal>
    </>
  );
};

MustAuth.propTypes = {
  isLoading: PropTypes.bool,
};

export default MustAuth;
