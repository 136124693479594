import React, { useState } from "react";

import { useSelector } from "react-redux";
export default () => {
  const lang = useSelector((state) => state.customization.lang);
  return (
    <div className="container mx-auto px-0 lg:pt-24 lg:pb-64">
      <div className="flex flex-wrap text-center justify-center">
        <div className="w-full lg:w-6/12 px-0">
          <h2 className="text-4xl font-semibold text-white">
            {lang === "fr" ? "Contactez-nous" : lang === "en" ? "Contact us" : "اتصل بنا"}
          </h2>
          <p className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-400">
            {lang === "fr"
              ? "Que vous ayez des questions, des demandes spécifiques ou simplement l'envie de discuter de vos projets d'exportation, notre équipe dévouée est là pour vous aider."
              : lang === "en"
              ? "Whether you have questions, specific requests or simply want to discuss your export plans, our dedicated team is here to help."
              : "سواءً كانت لديك أسئلة أو متطلبات محددة أو ترغب ببساطة في مناقشة خطط التصدير الخاصة بك، فإن فريقنا المتخصص هنا لمساعدتك."}

            <br />

            {lang === "fr"
              ? "Utilisez les options ci-dessous pour entrer en contact avec nous :"
              : lang === "en"
              ? "Use the options below to get in touch with us:"
              : "استخدم الخيارات أدناه للتواصل معنا:"}
          </p>
        </div>
      </div>
      <div className="flex flex-wrap mt-12 justify-center">
        <div className="w-full lg:w-3/12 px-4 text-center">
          <div className="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
            <i className="fas fa-medal text-xl"></i>
          </div>
          <h6 className="text-xl mt-5 font-semibold text-white">
            {lang === "fr"
              ? "Produit Personnalisé"
              : lang === "en"
              ? "Customized product"
              : "منتج مخصص"}
          </h6>
          <p className="mt-2 mb-4 text-blueGray-400" style={{ fontSize: "14px" }}>
            {lang === "fr"
              ? "Faites-nous part de vos exigences détaillées, et notre équipe se mettra en action pour trouver la solution d'exportation parfaite qui répond à vos critères."
              : lang === "en"
              ? "Tell us your detailed requirements, and our team will set to work to find the perfect export solution to meet your criteria."
              : "أخبرنا بمتطلباتك التفصيلية، وسيعمل فريقنا على إيجاد حل التصدير المثالي الذي يلبي معاييرك."}
          </p>
        </div>
        <div className="w-full lg:w-3/12 px-4 text-center">
          <div className="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
            <i className="fas fa-poll text-xl"></i>
          </div>
          <h5 className="text-xl mt-5 font-semibold text-white">
            {lang === "fr"
              ? "Assistance Technique"
              : lang === "en"
              ? "Technical support"
              : "الدعم الفني"}
          </h5>
          <p className="mt-2 mb-4 text-blueGray-400" style={{ fontSize: "14px" }}>
            {lang === "fr"
              ? "Notre équipe d'assistance technique est prête à résoudre vos problèmes et à vous guider à chaque étape."
              : lang === "en"
              ? "Our technical support team is ready to solve your problems and guide you every step of the way."
              : "فريق الدعم الفني لدينا جاهز لحل مشاكلك وإرشادك في كل خطوة على الطريق."}
          </p>
        </div>
        <div className="w-full lg:w-3/12 px-4 text-center">
          <div className="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
            <i className="fas fa-lightbulb text-xl"></i>
          </div>
          <h5 className="text-xl mt-5 font-semibold text-white">
            {lang === "fr"
              ? "Informations Commerciales"
              : lang === "en"
              ? "Sales information"
              : "معلومات المبيعات"}
          </h5>
          <p className="mt-2 mb-4 text-blueGray-400" style={{ fontSize: "14px" }}>
            {lang === "fr"
              ? "Contactez-nous pour obtenir toutes les informations nécessaires sur nos services d'exportation. "
              : lang === "en"
              ? "Contact us for all the information you need about our export services. "
              : "اتصل بنا لمعرفة المزيد عن خدمات التصدير لدينا. "}

            <br />

            {lang === "fr"
              ? "Nous sommes là pour vous fournir des réponses claires et transparentes."
              : lang === "en"
              ? "We're here to give you clear, transparent answers."
              : "نحن هنا لنقدم لك إجابات واضحة وشفافة."}
          </p>
        </div>
      </div>
    </div>
  );
};
