// @mui material components
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDProgress from "components/MDProgress";
import Icon from "@mui/material/Icon";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

import MDBadge from "components/MDBadge";
import { Dropdown } from "react-bootstrap";

export default function data(props) {
  const avatars = (image) =>
    image.map(([image, name]) => (
      <img
        key={name}
        src={image}
        alt="name"
        size="md"
        style={{ width: "50px", height: "auto", maxHeight: "50px" }}
      />
    ));

  const openModal = (item) => {
    props.handleOpen(item);
  };

  const openModalArchive = (item) => {
    props.handleOpenArchivage(item);
  };

  const openModalValidation = (publireportage) => {
    props.handleOpenValidation(publireportage);
  };

  const openModalAffichage = (publireportage) => {
    props.handleOpenAffichage(publireportage);
  };

  const updateItemStatus = (item, status) => {
    props.handleUpdate({ id: item.id, status: status });
  };

  const updateItemArchive = (item, archive) => {
    props.handleUpdate({ id: item.id, archive: archive });
  };

  const Company = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="md" />
      <MDTypography variant="button" fontWeight="medium" ml={1} lineHeight={1}>
        {name}
      </MDTypography>
    </MDBox>
  );

  return {
    columns:
      props.lang === "fr"
        ? [
            // { Header: "Service", accessor: "abonnement", align: "center" },
            { Header: "Date Demande", accessor: "date_demande", align: "center" },
            { Header: "Nom", accessor: "entreprise", width: "20%", align: "left" },
            { Header: "Email", accessor: "demandeur", width: "20%", align: "left" },
            // { Header: "Attachement", accessor: "attachement", align: "center" },
            { Header: "Statut", accessor: "status", align: "center" },
            // { Header: "Date Debut", accessor: "date_start", align: "center" },
            // { Header: "Date Fin", accessor: "date_end", align: "center" },
            { Header: "", accessor: "action", align: "center" },
          ]
        : props.lang === "en"
        ? [
            // { Header: "Service", accessor: "abonnement", align: "center" },
            { Header: "Request Date", accessor: "date_demande", align: "center" },
            { Header: "Name", accessor: "entreprise", width: "20%", align: "left" },
            { Header: "Email", accessor: "demandeur", width: "20%", align: "left" },
            // { Header: "Attachement", accessor: "attachement", align: "center" },
            { Header: "Status", accessor: "status", align: "center" },
            // { Header: "Start Date", accessor: "date_start", align: "center" },
            // { Header: "End Date", accessor: "date_end", align: "center" },
            { Header: "", accessor: "action", align: "center" },
          ]
        : [
            // { Header: "الخدمة", accessor: "abonnement", align: "center" },
            { Header: "تاريخ الطلب", accessor: "date_demande", align: "center" },
            { Header: "الإسم", accessor: "entreprise", width: "20%", align: "left" },
            { Header: "البريد الإلكتروني", accessor: "demandeur", width: "20%", align: "left" },
            // { Header: "Attachement", accessor: "attachement", align: "center" },
            { Header: "الحالة", accessor: "status", align: "center" },
            // { Header: "تاريخ البداية", accessor: "date_start", align: "center" },
            // { Header: "تاريخ النهاية", accessor: "date_end", align: "center" },
            { Header: "", accessor: "action", align: "center" },
          ],

    rows: props.items.map((el) => {
      return {
        demandeur: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {el.email}
            {el.alreadyUser === 1 ? (
              <>
                <MDBadge
                  badgeContent={
                    el.alreadyUser === 1
                      ? "" +
                        (props.lang === "fr"
                          ? "Utilisateur"
                          : props.lang === "en"
                          ? "User"
                          : "مستخدم")
                      : "Non"
                  }
                  color={el.alreadyUser === 1 ? "success" : "info"}
                  variant="gradient"
                  size="sm"
                />
              </>
            ) : (
              <></>
            )}
          </MDTypography>
        ),
        entreprise: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {el.nom}
          </MDTypography>
        ),
        abonnement: (
          <Tooltip
            title={el.abonnement.price + " - " + el.abonnement.duration}
            placeholder="bottom"
          >
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {el.abonnement.name}
            </MDTypography>
          </Tooltip>
        ),

        date_demande: (
          <MDBox ml={-1}>
            <MDBadge
              badgeContent={el.created_at ? el.created_at : "--"}
              color={"info"}
              variant="gradient"
              size="sm"
            />
          </MDBox>
        ),
        attachement: (
          <MDBox ml={-1}>
            <a href={el.image} download={true}>
              <CloudDownloadIcon color="dark" fontSize="small" />
            </a>
          </MDBox>
        ),
        status: (
          <MDBox ml={-1}>
            <MDBadge
              badgeContent={el.status === 1 ? "Prouvé" : el.status === -1 ? "Annulé" : "En attente"}
              color={el.status === 1 ? "success" : el.status === -1 ? "danger" : "warning"}
              variant="gradient"
              size="sm"
            />
          </MDBox>
        ),

        date_start: (
          <MDBox ml={-1}>
            <MDBadge
              badgeContent={el.date_debut ? el.date_debut : "--"}
              color={"dark"}
              variant="gradient"
              size="sm"
            />
          </MDBox>
        ),

        date_end: (
          <MDBox ml={-1}>
            <MDBadge
              badgeContent={el.date_fin ? el.date_fin : "--"}
              color={"primary"}
              variant="gradient"
              size="sm"
            />
          </MDBox>
        ),
        action: (
          <>
            <MDBox color="text" px={2}>
              <Dropdown align="start">
                <Dropdown.Toggle variant="link" id="icon-dropdown"></Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      openModalAffichage(el);
                    }}
                  >
                    {props.lang === "fr" ? "Afficher" : props.lang === "en" ? "Show" : "عرض"}
                  </Dropdown.Item>
                  {/* <Dropdown.Item href="#action-2" disabled={el.status === 1 || el.status === -1 }>Activer</Dropdown.Item>
                    <Dropdown.Item href="#action-3"  disabled={el.status === 0 || el.status === -1 }>Desavtiver</Dropdown.Item> */}
                  {/* <Dropdown.Item onClick={()=> {openModalArchive(el)}}  disabled={el.archive === 1 }>Archiver</Dropdown.Item> */}
                </Dropdown.Menu>
              </Dropdown>
            </MDBox>
          </>
        ),
      };
    }),
  };
}
