/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import ProfileInfoCard from "./components/ProfileInfoCard";
import ProfilesList from "./components/ProfilesList";

// Overview page components
import Header from "./components/Header";

// Data
import profilesListData from "./data/profilesListData";

// Images
import { useState } from "react";

// Data
import tw from "twin.macro";
import styled from "styled-components";
import React from "react";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { ReactComponent as StarIcon } from "feather-icons/dist/icons/briefcase.svg";
import { useEffect } from "react";
import { Row, Col, Button, Modal } from "react-bootstrap";
import AnchorLink from "react-anchor-link-smooth-scroll";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Icon } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import MDButton from "components/MDButton";
import { getEntreprisePage } from "services/visiteurService";
import { useMutation } from "@tanstack/react-query";
import { BASE_URL_Image } from "services/server";

const ActionButton = tw(
  Button
)`px-8 py-3 font-bold rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300 mt-12 inline-block tracking-wide text-center px-10 py-4 font-semibold tracking-normal border-none`;
const SecondaryButton = tw(
  ActionButton
)`mt-6 sm:mt-12 sm:ml-8 bg-gray-300 text-gray-800 hocus:bg-gray-400 hocus:text-gray-900`;
const Container = tw.div`relative w-full`;

const CardTW = tw.div`h-full flex! flex-col sm:border max-w-sm  relative focus:outline-none`;
const CardImage = styled.div((props) => [
  `background-image: url("${props.imageSrc}");height : 100% ; `,
  tw`w-full  bg-cover bg-center rounded sm:rounded-none`,
]);

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-2xl font-bold`;

const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4`;

const IconWithText = tw.div`flex items-center mr-6 my-2 sm:my-0`;

const Text = tw.div`ml-2 text-sm font-semibold text-gray-800`;

function Overview() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Add event listener to update window width when it changes
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [services, setServices] = useState([]);
  const [entreprise, setEntreprise] = useState({});
  const { mutate: mutateEntreprise, isLoading: isLoadingEntreprise } = useMutation(
    (id) => getEntreprisePage(id),
    {
      async onSuccess(data) {
        console.log(data);
        if (data && data.data && data.data.entreprise) {
          let result = data.data.entreprise[0];

          let infos_result = {};
          if (result.tel) infos_result["tel"] = result.tel;
          if (result.fax) infos_result["fax"] = result.fax;
          if (result.email) infos_result["email"] = result.email;
          if (result.localisation) infos_result["location"] = result.localisation;

          let links_result = [];

          if (result.facebook) {
            links_result.push({
              link: result.facebook,
              icon: <FacebookIcon />,
              color: "facebook",
            });
          }
          if (result.twitter) {
            links_result.push({
              link: result.twitter,
              icon: <TwitterIcon />,
              color: "twitter",
            });
          }
          if (result.instagram) {
            links_result.push({
              link: result.instagram,
              icon: <InstagramIcon />,
              color: "instagram",
            });
          }
          if (result.sitweb) {
            links_result.push({
              link: result.sitweb,
              icon: <Icon fontSize="small">language</Icon>,
              color: "portefolio",
            });
          }

          setEntreprise({
            id: result.id,
            nom: result.nom,
            profile_pic: BASE_URL_Image + result.profile_pic,
            cover_pic: BASE_URL_Image + result.cover_pic,
            presentation: result.presentation,
            infos: infos_result,
            links: links_result,
            groupes: data.data.groupe.map((el) => {
              return {
                id: el.id,
                image: BASE_URL_Image + el.profile_pic,
                name: el.nom,
                action: {
                  type: "internal",
                  route: "/groupe/" + el.id,
                  color: "info",
                  label: "Voir",
                },
              };
            }),
          });

          setServices(
            data.data.services.map((el) => {
              return {
                id: el.id,
                imageSrc: BASE_URL_Image + el.image,
                title: el.titre,
                description: el.description,
                locationText: el.localisation,
                entrepriseText: el.entreprise_nom,
                entreprise_id: el.entreprise_id,
                secteur: el.secteur_nom,
                secteur_id: el.secteur_id,
              };
            })
          );
        } else {
        }
      },
      onError(error) {},
    }
  );

  useEffect(() => {
    console.log(id);
    if (id) {
      console.log("loading");
      mutateEntreprise(id);
    }
  }, []);

  useEffect(() => {
    if (document.getElementById("header_smart") != null)
      document.getElementById("header_smart").classList.add("header_smart_solution");
    return () => {
      if (document.getElementById("header_smart") != null)
        document.getElementById("header_smart").classList.remove("header_smart_solution");
    };
  });

  return (
    <div
      className="w-100"
      style={{ padding: windowWidth > 800 ? "40px" : "0px", paddingTop: "80px" }}
    >
      {/* <DashboardNavbar /> */}
      <MDBox mb={2} />
      <Header entreprise={entreprise}>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} xl={8} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              <ProfileInfoCard
                title="Description de l'entreprise"
                description={entreprise && entreprise.presentation ? entreprise.presentation : ""}
                info={entreprise && entreprise.infos ? entreprise.infos : {}}
                social={entreprise && entreprise.links ? entreprise.links : []}
                action={{ route: "", tooltip: "Modifier les informaitons" }}
                shadow={false}
              />
              <Divider orientation="vertical" sx={{ mx: 0 }} />
            </Grid>
            <Grid item xs={12} md={12} xl={4} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              <ProfilesList
                title="Groupe"
                profiles={entreprise && entreprise.groupes ? entreprise.groupes : []}
                shadow={false}
              />
              {/* <PlatformSettings /> */}
            </Grid>
          </Grid>
        </MDBox>
        <MDBox pt={2} px={2} lineHeight={1.25}>
          <MDTypography variant="h6" fontWeight="medium">
            Services
          </MDTypography>
          <Container className="p-4 px-0">
            <PerfectScrollbar>
              <Row style={{ width: "100%" }} className="py-3 px-0">
                {services.map((card, index) => (
                  <Col key={index} xs={12} className="mb-5 px-0">
                    <CardTW
                      style={{ width: "100%", maxWidth: "100%", boxShadow: "0 0 2px 1px #d9d9d9" }}
                    >
                      <Row>
                        <Col xs={12} md={3}>
                          <CardImage imageSrc={card.imageSrc} style={{ minHeight: "150px" }} />
                        </Col>
                        <Col xs={12} md={9} className="pb-3" style={{ padding: "30px" }}>
                          <TextInfo style={{ maxHeight: "220px", overflow: "hidden" }}>
                            <TitleReviewContainer>
                              <Title style={{ fontSize: "16px" }}>{card.title}</Title>
                            </TitleReviewContainer>
                            <IconWithText className="mt-3">
                              <StarIcon style={{ width: "20px", height: "20px" }} />
                              <Text style={{ fontSize: "13px" }}>{card.entrepriseText}</Text>
                            </IconWithText>
                            <IconWithText className="mt-2">
                              <LocationIcon style={{ width: "20px", height: "20px" }} />
                              <Text style={{ fontSize: "13px" }}>{card.locationText}</Text>
                            </IconWithText>
                            <Description style={{ fontSize: "12px" }}>
                              {card.description}
                            </Description>
                          </TextInfo>
                          <div className="w-100 d-flex justify-content-end align-items-center">
                            <SecondaryButton
                              style={{
                                margin: 0,
                                height: "20px",
                                fontSize: "12px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                maxWidth: "150px",
                              }}
                              onClick={() => {
                                navigate("/product/" + card.id);
                              }}
                            >
                              Voir plus
                            </SecondaryButton>
                          </div>
                        </Col>
                      </Row>
                    </CardTW>
                  </Col>
                ))}
              </Row>
            </PerfectScrollbar>
          </Container>
        </MDBox>
      </Header>
      <Modal
        show={isLoadingEntreprise}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <MDBox
            mx={2}
            my={1}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              Loading ...
            </MDTypography>
          </MDBox>
        </Modal.Body>
      </Modal>
      {/* <Footer /> */}
    </div>
  );
}

export default Overview;
