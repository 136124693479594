import PropTypes from "prop-types";
import { useState } from "react";

// material-ui
import { useTheme, styled } from "@mui/material/styles";
import {
  Avatar,
  Box,
  ButtonBase,
  Card,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Popper,
  Select,
  TextField,
  Typography,
} from "@mui/material";

// third-party
import PopupState, { bindPopper, bindToggle } from "material-ui-popup-state";

// project imports
import Transitions from "ui-component/extended/Transitions";

// assets
import {
  IconAdjustmentsHorizontal,
  IconMessage,
  IconPackage,
  IconSearch,
  IconX,
} from "@tabler/icons";
import { shouldForwardProp } from "@mui/system";
import { ShoppingCart } from "@mui/icons-material";

// styles
const PopperStyle = styled(Popper, { shouldForwardProp })(({ theme }) => ({
  zIndex: 1100,
  width: "99%",
  top: "-55px !important",
  padding: "0 12px",
  [theme.breakpoints.down("sm")]: {
    padding: "0 10px",
  },
}));

const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(({ theme }) => ({
  width: 434,
  marginLeft: 16,
  paddingLeft: 16,
  paddingRight: 16,
  "& input": {
    background: "transparent !important",
    paddingLeft: "4px !important",
  },
  [theme.breakpoints.down("lg")]: {
    width: 250,
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    marginLeft: 4,
    background: "#fff",
  },
}));

const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(({ theme }) => ({
  ...theme.typography.commonAvatar,
  ...theme.typography.mediumAvatar,
  background: theme.palette.secondary.light,
  color: theme.palette.secondary.dark,
  "&:hover": {
    background: theme.palette.secondary.dark,
    color: theme.palette.secondary.light,
  },
}));

const MainSelect = styled("div", { shouldForwardProp })(({ theme }) => ({
  "& select": {
    background: "transparent !important",
    padding: "8px 5px !important",
  },
  [theme.breakpoints.down("lg")]: {
    width: 250,
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    marginLeft: 4,
    background: "#fff",
  },
}));

// ==============================|| SEARCH INPUT ||============================== //

const SelectSection = ({ label, value, valueChange, status, valueFilter }) => {
  const theme = useTheme();

  return (
    <>
      <Box sx={{ display: { md: "block", width: "100%" } }}>
        <MainSelect>
          <div className="relative w-full mb-3 p-2">
            <Typography noWrap variant="h6">
              {label}
            </Typography>
            <TextField
              id="outlined-select-currency-native "
              className="p-0"
              select
              fullWidth
              value={value}
              onChange={(e) => valueChange(e.target.value)}
              SelectProps={{
                native: true,
              }}
            >
              {status.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </div>
        </MainSelect>
      </Box>
    </>
  );
};

export default SelectSection;
