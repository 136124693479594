import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { Button, Badge } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import { useTheme } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import AnnonceCard from "ui-component/cards/Skeleton/AnnonceCard";
// Import Swiper React components
// Import Swiper styles
import "swiper/css";

import "./serviceSlider.css";
import { ExpandMore, FavoriteBorder } from "@mui/icons-material";
import { ButtonBase, Menu, MenuItem } from "@mui/material";
import MainCard from "ui-component/cards/MainCard";
import ShareProduct from "components/shareProduct/ShareProduct";
import { useMutation } from "@tanstack/react-query";
import { addAnnonceFav } from "services/adminService";
import { toast } from "react-toastify";
import { deleteAnnonceFav } from "services/adminService";
import { useSelector } from "react-redux";
const ActionButton = tw(
  Button
)`px-8 py-3 font-bold rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300 mt-12 inline-block tracking-wide text-center px-10 py-4 font-semibold tracking-normal border-none`;
const SecondaryButton = tw(
  ActionButton
)`mt-6 sm:mt-12 sm:ml-8 bg-gray-300 text-gray-800 hocus:bg-gray-400 hocus:text-gray-900`;

const IconWithText = tw.div`flex items-center mr-6 my-2 sm:my-0`;

const Text = tw.div`ml-2 text-sm font-semibold text-gray-800`;

const CardWrapper = styled(MainCard)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.primary.light,
  overflow: "hidden",
  position: "relative",
  "&:after": {
    content: '""',
    position: "absolute",
    width: 210,
    height: 210,
    background: `linear-gradient(210.04deg, ${theme.palette.primary[200]} -50.94%, rgba(144, 202, 249, 0) 83.49%)`,
    borderRadius: "50%",
    top: -30,
    right: -180,
  },
  "&:before": {
    content: '""',
    position: "absolute",
    width: 210,
    height: 210,
    background: `linear-gradient(140.9deg, ${theme.palette.primary[200]} -14.02%, rgba(144, 202, 249, 0) 77.58%)`,
    borderRadius: "50%",
    top: -160,
    right: -130,
  },
}));

export default ({ initCard, withActions, updateAction, isLoading }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const lang = useSelector((state) => state.customization.lang);

  const [expanded, setExpanded] = React.useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [card, setCard] = useState(initCard);
  const [product, setProduct] = useState(null);
  const [userId, setUserId] = useState(null);
  const [role, setRole] = useState("");
  const [shareProduct, setShareProduct] = useState(false);
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    let user_id = localStorage.getItem("id");
    let stored_role = localStorage.getItem("role");
    if (user_id != null && user_id != "") {
      setUserId(user_id);
    }
    if (stored_role != null && stored_role != "") {
      setRole(stored_role);
    }
  }, []);

  useEffect(() => {
    console.log(initCard);
    setCard(initCard);
  }, [initCard]);

  const { mutate: mutateAddFav, isLoading: isLoadingAddFav } = useMutation(
    (filters) => addAnnonceFav(filters),
    {
      async onSuccess(data) {
        console.log(data);
        if (data && data.success) {
          setCard((oldValue) => {
            return { ...oldValue, fav: true };
          });
          notify_success(
            lang === "fr"
              ? "Ajoutée à la liste fav"
              : lang === "en"
              ? "Added to fav list"
              : "تمت إضافته إلى قائمة المفضلة"
          );
        } else {
          notify(lang === "fr" ? "Une erreur" : lang === "en" ? "An error" : "هنالك مشكلة");
        }
      },
      onError(error) {},
    }
  );

  const { mutate: mutateDeleteFav, isLoading: isLoadingDeleteFav } = useMutation(
    (filters) => deleteAnnonceFav(filters),
    {
      async onSuccess(data) {
        console.log(data);
        if (data && data.success) {
          setCard((oldValue) => {
            return { ...oldValue, fav: false };
          });
          notify_success(
            lang === "fr"
              ? "Supprimée de la liste fav"
              : lang === "en"
              ? "Removed from fav list"
              : "تمت الإزالة من قائمة المفضلة"
          );
        } else {
          notify(lang === "fr" ? "Une erreur" : lang === "en" ? "An error" : "هنالك مشكلة");
        }
      },
      onError(error) {},
    }
  );

  const addfav = () => {
    let user_id = localStorage.getItem("id");
    mutateAddFav({
      user_id: parseInt(user_id),
      annonce_id: card.id,
    });
  };

  const deletefav = () => {
    let user_id = localStorage.getItem("id");
    mutateDeleteFav({
      user_id: parseInt(user_id),
      annonce_id: card.id,
    });
  };

  const notify = (message) => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 3000,
      fontSize: "12px",
    });
  };

  const notify_success = (message) => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 3000,
    });
  };

  return (
    <>
      {isLoading ? (
        <AnnonceCard />
      ) : (
        <>
          <CardWrapper
            sx={{
              width: "100%",
              maxWidth: "400px",
              // maxWidth: "400px",
              overflow: "hidden",
              border: "1px solid #f5f5f5",
              direction: lang === "ar" ? "rtl" : "",
              background: card.blocked_admin
                ? theme.palette.errorDark
                : (userId == card.entreprise_id || role === "admin") && card.status == false
                ? theme.palette.errorLight
                : "",
            }}
          >
            {(userId == card.entreprise_id || role === "admin") && card.blocked_admin == true && (
              <div>
                <Typography
                  variant="h6"
                  gutterBottom
                  className="px-3 py-2"
                  style={{
                    background: theme.palette.errorDark,
                    color: theme.palette.secondary.light,
                    width: "max-content",
                    borderBottomRightRadius: "12px",
                  }}
                >
                  {lang === "fr"
                    ? "Suspendu par l'admin "
                    : lang === "en"
                    ? "Suspended by admin"
                    : "تم توقيفه من قبل المشرف"}
                </Typography>
              </div>
            )}
            {(userId == card.entreprise_id || role === "admin") && card.status == false && (
              <div>
                <Typography
                  variant="h6"
                  gutterBottom
                  className="px-3 py-2"
                  style={{
                    background: theme.palette.errorDark,
                    color: theme.palette.secondary.light,
                    width: "max-content",
                    borderBottomRightRadius: "12px",
                  }}
                >
                  {lang === "fr" ? "Bloqué" : lang === "en" ? "Blocked" : "محظور"}
                </Typography>
              </div>
            )}

            <CardHeader
              className="p-0 py-2"
              avatar={
                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    overflow: "hidden",
                    borderRadius: "50%",
                    border: "2px solid white",
                  }}
                  className="d-flex align-items-center justify-content-center"
                >
                  <img
                    alt=""
                    src={card.groupe_id != null ? card.groupeImage : card.entrepriseImage}
                    style={{
                      height: "30px",
                      width: "auto",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate("/groupe/" + card.groupe_id);
                    }}
                  />
                </div>
              }
              action={
                withActions && (userId == card.entreprise_id || role === "admin") ? (
                  <>
                    <IconButton aria-label="settings" onClick={handleClick}>
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem
                        onClick={() => {
                          if (role === "admin") {
                            updateAction({ id: card.id, blocked_admin: 1 });
                          } else {
                            updateAction({ id: card.id, status: 0 });
                          }
                          handleClose();
                        }}
                        disabled={
                          (role === "admin" && card.blocked_admin) ||
                          (role != "admin" && card.status == false)
                        }
                      >
                        {role === "admin"
                          ? "" +
                            (lang === "fr" ? "Suspendre " : lang === "en" ? "Suspend" : "توقيف")
                          : "" + (lang === "fr" ? "Bloquer" : lang === "en" ? "block" : "حظر")}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          if (role === "admin") {
                            updateAction({ id: card.id, blocked_admin: 0 });
                          } else {
                            updateAction({ id: card.id, status: 1 });
                          }
                          handleClose();
                        }}
                        disabled={
                          (role === "admin" && card.blocked_admin == false) ||
                          (role != "admin" && card.status == true)
                        }
                      >
                        {role === "admin"
                          ? "" +
                            (lang === "fr"
                              ? "Réactiver "
                              : lang === "en"
                              ? "Reactivate"
                              : "اعادة تفعيل")
                          : "" +
                            (lang === "fr" ? "Débloquer" : lang === "en" ? "Unblock" : "نزع الحظر")}
                      </MenuItem>
                    </Menu>
                  </>
                ) : (
                  <></>
                )
              }
            />

            <CardHeader
              className="p-0 py-2"
              title={
                <strong style={{ fontSize: "14px", overflow: "hidden", color: "white" }}>
                  {lang === "fr" ? card.title : lang === "en" ? card.title_en : card.title_ar}
                </strong>
              }
              subheader={
                <div
                  style={{
                    maxHeight: "20px",
                    fontSize: "11px",
                    overflow: "hidden",
                    wordWrap: "break-word",
                    textOverflow: "ellipsis",
                    color: "white",
                  }}
                >
                  {card.groupeText || card.entrepriseText}
                </div>
              }
              style={{
                borderBottom: "1px solid #f5f5f5",
              }}
            />
            {/* <CardMedia component="img" height="194" image={card.imageSrc} alt="Product Image" /> */}

            {/* <div
          style={{
            width: "300px",
            height: "300px",
            margin: "0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            overflow: "hidden",
            color: "#d7d7d7",
            // borderRadius: "20px",
            border: "1px solid #f5f5f5",
          }}
        >
          <img style={{ height: "100%", width: "auto", maxWidth: "none" }} src={card.imageSrc} />
        </div> */}
            <CardContent>
              <Typography variant="body2" color="white" style={{ color: "white" }} noWrap={true}>
                {lang === "fr"
                  ? card.description
                  : lang === "en"
                  ? card.description_en
                  : card.description_ar}
              </Typography>
            </CardContent>
            <CardActions
              disableSpacing
              className="d-flex w-100 justify-content-between align-items-center px-3 py-1"
              style={{ borderTop: "1px solid #f5f5f5" }}
            >
              <div>
                {userId != card.entreprise_id && role != "admin" ? (
                  <IconButton
                    aria-label="add to favorites"
                    onClick={() => {
                      if (card.fav) {
                        deletefav();
                      } else {
                        addfav();
                      }
                    }}
                  >
                    {!card.fav ? <FavoriteBorder /> : <FavoriteIcon />}
                  </IconButton>
                ) : (
                  <></>
                )}
                <IconButton
                  aria-label="share"
                  onClick={() => {
                    setProduct(card);
                    setShareProduct(true);
                  }}
                >
                  <ShareIcon />
                </IconButton>
              </div>
              <ButtonBase
                className="px-3"
                sx={{
                  ...theme.typography.commonAvatar,
                  ...theme.typography.button,
                  transition: "all .2s ease-in-out",
                  // background: theme.palette.secondary.light,
                  color: "white",
                  "&:hover": {
                    background: "white",
                    color: theme.palette.secondary.dark,
                  },
                  width: "max-content",
                  overflow: "hidden",
                }}
                onClick={() => {
                  navigate("/annonce/" + card.id);
                }}
              >
                {lang === "fr" ? "Voir plus" : lang === "en" ? "See more" : "شاهد المزيد"}
              </ButtonBase>
            </CardActions>
          </CardWrapper>

          <ShareProduct
            product={product}
            shareProduct={shareProduct}
            onHideProduct={() => {
              setProduct(null);
              setShareProduct(false);
            }}
          />
        </>
      )}
    </>
  );
};
